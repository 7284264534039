import getConfigs from './configUtils';
const OPTIONS = ['height', 'readOnly', 'needSaveButton'];

const CONFIGS_FIELDS = {
  height: {
    name: 'height',
    label: 'Высота',
    component: 'DxTextBox',
    options: {
      showClearButton: true
    },
    changeEvent: 'valueChanged'
  },
  readOnly: {
    name: 'readOnly',
    label: 'Только просмотр',
    component: 'DxSelectBox',
    options: {
      dataSource: [
        { text: 'Да', value: 'true' },
        { text: 'Нет', value: 'false' }
      ],
      showClearButton: true,
      displayExpr: 'text',
      valueExpr: 'value'
    },
    changeEvent: 'optionChanged'
  },
  needSaveButton: {
    name: 'needSaveButton',
    label: 'Кнопка сохранения',
    component: 'DxSelectBox',
    options: {
      dataSource: [
        { text: 'Да', value: 'true' },
        { text: 'Нет', value: 'false' }
      ],
      showClearButton: true,
      displayExpr: 'text',
      valueExpr: 'value'
    },
    changeEvent: 'optionChanged'
  }
};

export default getConfigs(OPTIONS, CONFIGS_FIELDS);
