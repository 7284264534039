<template>
  <!--eslint-disable-->
  <div
    class="constructor-selected-element"
    :data-component-id="componentId"
    :style="cssStyle"
  >
    <slot />
    <div
      class="constructor-selected-element__overlay"
      v-if="selected"
      :class="{'constructor-selected-element__overlay_last-selected': lastSelected}"
    ></div>
  </div>
</template>

<script>
export default {
  name: 'ConstructorSelectedElement',
  props: {
    componentId: {
      type: String,
      default: ''
    },
    cssStyle: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  computed: {
    selected() {
      return this.$store.getters['config/selectedComponents'].includes(this.componentId);
    },
    lastSelected() {
      return this.$store.getters['config/selectedComponentId'] === this.componentId;
    }
  }
};
</script>

<style lang="scss">
.constructor-selected-element {
  position: relative;

  &__overlay {
    position: absolute;
    /*background-color: rgba(51, 122, 183, 0.2);*/
    border: 1px solid #337ab7;
    z-index: 3;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    &_last-selected {
      border: 2px solid #337ab7;
    }
  }
}
</style>
