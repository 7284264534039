import getConfigs from './configUtils';
const OPTIONS = ['height'];

const CONFIGS_FIELDS = {
  height: {
    name: 'height',
    label: 'Высота',
    component: 'DxTextBox',
    options: {
      showClearButton: true
    },
    changeEvent: 'valueChanged'
  }
};

export default getConfigs(OPTIONS, CONFIGS_FIELDS);
