<template>
  <!--eslint-disable-->
  <div class="mobile-build-form">
    <DxValidationGroup ref="validationGroup">
      <div class="dx-field">
        <div class="dx-field-label">Наименование</div>
        <div class="dx-field-value">
          <DxTextBox
            v-model="name"
            :readOnly="readOnly"
          >
            <DxValidator>
              <DxValidationRule
                type="required"
                message="Поле обязательно для заполнения"
              />
            </DxValidator>
          </DxTextBox>
        </div>
      </div>
      <div class="dx-field">
        <div class="dx-field-label">Версия</div>
        <div class="dx-field-value">
          <DxTextBox
            v-model="version"
            :readOnly="readOnly"
          >
            <DxValidator>
              <DxValidationRule
                type="required"
                message="Поле обязательно для заполнения"
              />
            </DxValidator>
          </DxTextBox>
        </div>
      </div>
      <div class="dx-field">
        <div class="dx-field-label">ID пакета</div>
        <div class="dx-field-value">
          <DxTextBox
            v-model="code"
            :readOnly="readOnly"
          >
            <DxValidator>
              <DxValidationRule
                type="required"
                message="Поле обязательно для заполнения"
              />
            </DxValidator>
          </DxTextBox>
        </div>
      </div>
      <div class="dx-field">
        <div class="dx-field-label">УРЛ страницы</div>
        <div class="dx-field-value">
          <DxTextBox
            v-model="front"
            :readOnly="readOnly"
          >
            <DxValidator>
              <DxValidationRule
                type="required"
                message="Поле обязательно для заполнения"
              />
            </DxValidator>
          </DxTextBox>
        </div>
      </div>
      <div class="dx-field">
        <div class="dx-field-label">Ключ</div>
        <div class="dx-field-value">
          <DxTextBox
            v-model="key"
            :readOnly="readOnly"
          ></DxTextBox>
        </div>
      </div>
      <div class="dx-field">
        <div class="dx-field-label">Иконка</div>
        <div class="dx-field-value">
          <div class="mobile-build-form__icon">
            <DxGallery
              class="mobile-build-form__gallery"
              :dataSource="galleryDataSource"
              :width="28"
              height="auto"
              :loop="true"
              :showIndicator="false"
              :stretchImages="true"
              :showNavButtons="false"
              ref="gallery"
            >
              <template #item="{ data }">
                <GalleryItem
                  :url="data['url']"
                  :isFullScreen="false"
                />
              </template>
            </DxGallery>
            <FileUploaderBase
              v-if="!readOnly"
              class="mobile-build-form__uploader"
              ref="fileUploader"
              @uploaded="onUploaded"
              @valueChanged="onUploaderChanged"
            />
          </div>
          <div class="mobile-build-form__info">Требования к иконке: размер 96х96, черный цвет, прозрачный фон</div>
        </div>
      </div>
      <div class="mobile-build-form__toolbar">
        <DxButton
          v-if="!readOnly"
          type="success"
          text="Скомпилировать"
          @click="onBuildClick"
        ></DxButton>
      </div>
    </DxValidationGroup>
    <div>
      <DxLoadPanel
        :visible="loadPanelVisible"
        container=".mobile-build-form"
      >
        <DxLoadPanelPosition
          my="center"
          at="center"
          of=".mobile-build-form"
        />
      </DxLoadPanel>
    </div>
  </div>
</template>

<script>
import DxButton from 'devextreme-vue/button';
import DxTextBox from 'devextreme-vue/text-box';
import DxValidator, { DxValidationRule } from 'devextreme-vue/validator';
import DxValidationGroup from 'devextreme-vue/validation-group';
import DxGallery from 'devextreme-vue/gallery';
import { DxLoadPanel, DxPosition as DxLoadPanelPosition } from 'devextreme-vue/load-panel';
import FileUploaderBase from '../Editors/FileUploaderBase';
import GalleryItem from '../Gallery//GalleryItem';
import { jsonRPC } from '@/api/api';

const BUILDS_DATASET = 'CONNECTIONS.DSMVERSION';

const FIELDS = ['name', 'version', 'package', 'key', 'code', 'front'];

const mapFields = (fields) => {
  let result = {};
  fields.forEach((field) => {
    let fieldName;
    let fieldPath;
    if (typeof field === 'string') {
      fieldName = field;
      fieldPath = field;
    } else {
      fieldName = field.name;
      fieldPath = field.path;
    }
    result[fieldName] = {
      get() {
        return this.dataItem && this.dataItem[fieldPath];
      },
      set(value) {
        this.dataItem[fieldPath] = value;
      }
    };
  });
  return result;
};

export default {
  name: 'MobileBuildForm',

  components: {
    DxTextBox,
    DxButton,
    DxValidator,
    DxValidationRule,
    DxValidationGroup,
    FileUploaderBase,
    DxGallery,
    GalleryItem,
    DxLoadPanel,
    DxLoadPanelPosition
  },

  props: {
    dataItemId: Number,
    portalId: Number,
    portalName: String,
    portalUrl: String
  },

  data() {
    return {
      dataItem: { portal_id: this.portalId },
      builds: [],
      loadPanelVisible: false
    };
  },

  computed: {
    ...mapFields(FIELDS),

    galleryDataSource() {
      if (this.dataItem.icon) {
        return [{ url: `${process.env.VUE_APP_DOWNLOAD_FILE_SERVICE}?guid=${this.dataItem.icon}&_config_dataset=PAGES.DSPAGE` }];
      } else {
        return [];
      }
    },

    readOnly() {
      return !!this.dataItemId;
    }
  },

  mounted() {
    this.initForm();
  },

  methods: {
    initForm() {
      if (this.dataItemId) {
        //Режим отображения существующей записи
        //Загрузка записи
        jsonRPC('getData', {
          id: this.dataItemId,
          _config_dataset: BUILDS_DATASET
        }).then((data) => {
          this.dataItem = data[0];
        });
      } else {
        //Режим новой сборки
        //Загрузка версий сборок для портала
        jsonRPC('getData', {
          portal_id: this.portalId,
          _config_dataset: BUILDS_DATASET
        }).then((data) => {
          this.builds = data;
          this.afterBuildLoad();
        });
      }
    },

    afterBuildLoad() {
      this.builds.sort((item1, item2) => {
        return item2.id - item1.id;
      });
      const lastBuild = this.builds[0];
      if (lastBuild) {
        this.dataItem.name = lastBuild.name;
        this.dataItem.version = this.incrementVersion(lastBuild.version);
        this.dataItem.code = lastBuild.code;
        this.dataItem.icon = lastBuild.icon;
        this.dataItem.front = lastBuild.front;
      } else {
        this.dataItem.name = this.portalName;
        this.dataItem.front = this.portalUrl;
        this.dataItem.version = '0.01';
        if (this.dataItem.front && !this.dataItem.front.includes('http')) {
          this.dataItem.front = 'https://' + this.dataItem.front;
        }
      }
      this.dataItem = { ...this.dataItem };
    },

    onUploaderChanged() {},

    onUploaded(data) {
      const response = JSON.parse(data.request.response);
      this.dataItem.icon = response.guid;
      this.dataItem = { ...this.dataItem };
    },

    onBuildClick() {
      const result = this.$refs.validationGroup.instance.validate();
      if (result.isValid) {
        this.loadPanelVisible = true;
        this.$emit('addBuild', this.dataItem);
      }
    },

    incrementVersion(version) {
      const parts = version.split('.');
      if (parts[1]) {
        const lastNum = parseInt(parts[1]);
        parts[1] = (lastNum + 1).toString();
        if (parts[1].length < 2) {
          parts[1] = '0' + parts[1];
        }
      }
      return parts.join('.');
    }
  }
};
</script>

<style lang="scss">
.mobile-build-form {
  .dx-field-label {
    text-align: right;
    width: 25%;
  }
  .dx-field-value {
    width: 75% !important;
  }

  &__info {
    font-size: 10px;
    color: rgba(0, 0, 0, 0.5);
  }

  &__toolbar {
    text-align: center;
    margin-top: 8px;
  }

  &__icon {
    display: flex;
    flex-direction: row;
  }

  &__uploader {
    flex: 1;
  }
}
</style>
