import CustomStore from 'devextreme/data/custom_store';
import { jsonRPC } from '../../api/api';

export default new CustomStore({
  key: 'id',
  async load(loadOptions) {
    if (!loadOptions.searchValue) {
      return [];
    }

    const param = {
      search_value: loadOptions.searchValue,
      _config_dataset: 'CONFIG.DSCOMPONENTSEARCH'
    };
    const components = await jsonRPC('getData', param);
    return components;
  },
  async byKey(key) {
    if (!key) {
      return null;
    }
    const param = {
      id: key,
      _config_dataset: 'CONFIG.DSCOMPONENTSEARCH'
    };
    const components = await jsonRPC('getData', param);
    const result = components[0];

    //Костыль так как в CONFIG.DSCOMPONENTSEARCH идентификаторы типа integer
    if (typeof key === 'string') {
      result.id = result.id.toString();
    }
    return result;
  }
});
