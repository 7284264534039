export default {
  setUserData(state, userData) {
    state.userData = userData;
  },

  setIsAuthenticated(state, { isAuthenticated }) {
    state.isAuthenticated = isAuthenticated;
  },

  setIsTokenInvalid(state, { isInvalid }) {
    state.isTokenInvalid = isInvalid;
  },

  setAuthDate(state) {
    state.authDate = new Date();
  },

  setShowLoginPopup(state, { show }) {
    state.showLoginPopup = show;
  }
};
