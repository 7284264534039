import { INVISIBLE_COMPONENTS } from '@/utils/const';

export default {
  computed: {
    constructorActive() {
      return this.$store.getters['uiConstructor/active'];
    },

    componentName() {
      const itemConfig = this.$store.getters['config/getItem'](this.componentId) || {};
      return itemConfig.componentName;
    },

    parentFormId() {
      const itemConfig = this.$store.getters['config/getItem'](this.componentId) || {};
      return itemConfig.parentFormId || null;
    },

    items() {
      return (this.options.items || [])
        .map((itemId) => {
          return this.$store.getters['config/Component/getItem'](itemId);
        })
        .filter((itemConfig) => {
          return itemConfig;
        })
        .sort((itemConfig1, itemConfig2) => {
          return (itemConfig1.componentPos || 0) - (itemConfig2.componentPos || 0);
        });
    },

    visibleItems() {
      return this.items.filter((itemConfig) => {
        //Если по какой-то причине не получили конфиг (скорей всего это баг)
        if (!itemConfig) {
          return false;
        }

        //Исключение компонентов формы, которые надо спрятать при создании
        const parentFormOptions = this.$store.getters['config/Component/getOptions'](itemConfig.parentFormId);
        if (parentFormOptions.isNew && itemConfig.hideWhenCreate) {
          return false;
        }

        const options = this.$store.getters['config/Component/getOptions'](itemConfig.componentId);

        //Исключение невидимых типов компонентов и невидимых компонентов
        return itemConfig.visible !== false && options.visible !== false && !INVISIBLE_COMPONENTS.includes(itemConfig.componentType);
      });
    },

    invisibleItems() {
      return this.items.filter((itemConfig) => {
        return itemConfig && INVISIBLE_COMPONENTS.includes(itemConfig.componentType);
      });
    },

    lastItemPos() {
      const items = this.items || [];
      if (items.length === 0) {
        return 100;
      }

      return items[items.length - 1].componentPos || 0;
    },

    invisibleItemsTitle() {
      if (this.componentId) {
        return `${this.componentName} (${this.componentId})`;
      }

      return this.url;
    }
  },
  methods: {
    /** Действие установки свойства для группы компонентов в массиве items
     * Если группа не указана, действие выполняется для всех компонентов
     */
    async setGroupOption({ actionGroup, name, value }) {
      for (const item of this.items) {
        const options = this.$store.getters['config/getComponentOptions'](item.componentId) || {};
        if (!actionGroup || options.actionGroup === actionGroup) {
          this.$store.dispatch('config/Component/setOption', {
            componentId: item.componentId,
            name,
            value
          });
        }
      }
      return Promise.resolve();
    }
  }
};
