import { SET_CART_PRODUCTS, SET_CART_PRODUCTS_COUNT } from './mutations';
import { getCartProducts, getCartProductsCount } from './getters';
import { jsonRPC } from '@/api/api';

const PRODUCT_DATASET = 'LAYERS.DSMARKET_ORDER_PRODUCT';

export default {
  setUserProducs({ commit }) {
    const param = {
      is_posted: false,
      _config_dataset: PRODUCT_DATASET
    };

    return jsonRPC('getData', param).then((data) => {
      if (data?.length) {
        let count = 0;
        const products = [];
        data.forEach((item) => {
          count += item.quantity;
          products.push({ ...item });
        });

        commit(SET_CART_PRODUCTS, { products, saveToLocalStorage: false });
        commit(SET_CART_PRODUCTS_COUNT, { count, saveToLocalStorage: false });

        return Promise.resolve({ products, count });
      } else {
        return Promise.resolve(data);
      }
    });
  },
  addCartProduct({ commit, getters, rootGetters }, product) {
    const userIsGuest = rootGetters['session/userIsGuest'];
    const products = getters[getCartProducts];
    const count = parseInt(getters[getCartProductsCount]) + 1;
    let param = null;
    let method = 'updateData';

    products.forEach((item) => {
      if (item.product_id === product.product_id) {
        item.quantity++;
        param = { ...item };
        return;
      }
    });

    if (!param) {
      method = 'insertData';
      param = { ...product, quantity: 1 };
      products.push(param);
    }

    if (userIsGuest) {
      commit(SET_CART_PRODUCTS, { products, saveToLocalStorage: userIsGuest });
      commit(SET_CART_PRODUCTS_COUNT, { count, saveToLocalStorage: userIsGuest });
      return Promise.resolve({ products, count, isNew: method === 'insertData' });
    }

    param._config_dataset = PRODUCT_DATASET;
    return jsonRPC(method, param).then((results) => {
      results.forEach((result) => {
        products.forEach((product) => {
          if (product.product_id === result.product_id) {
            product.id = result.id;
            return;
          }
        });
      });

      commit(SET_CART_PRODUCTS, { products, saveToLocalStorage: userIsGuest });
      commit(SET_CART_PRODUCTS_COUNT, { count, saveToLocalStorage: userIsGuest });
      return Promise.resolve({ products, count, isNew: method === 'insertData' });
    });
  },

  removeCartProduct({ commit, getters, rootGetters }, product) {
    const userIsGuest = rootGetters['session/userIsGuest'];
    let { product_id, product_ids, remove } = product;
    if (!product_ids) {
      product_ids = Array.isArray(product_id) ? product_id : [product_id];
    }

    if (!Array.isArray(product_ids)) {
      if (typeof product_ids === 'number') {
        product_ids = [product_ids];
      } else if (typeof product_ids === 'string') {
        try {
          product_ids = JSON.parse(product_ids);
        } catch {
          console.warn('removeCartProduct: некоректное значение идентификатора/идентификаторов:', product_ids);
          return Promise.reject('Некоректное значение переданного идентификатора/идентификаторов');
        }
      } else {
        console.warn('removeCartProduct: некоректное значение идентификатора/идентификаторов:', product_ids);
        return Promise.reject('Некоректное значение переданного идентификатора/идентификаторов');
      }
    }

    let count = parseInt(getters[getCartProductsCount]);
    let products = getters[getCartProducts];
    let productIndexs = [];
    const promises = [];
    const methods = ['updateData', 'deleteData'];

    product_ids.forEach((product_id) => {
      products.forEach((item, index) => {
        if (item.product_id === product_id) {
          let param = {};
          let method = methods[0];
          if (item.quantity > 1 && !remove) {
            count--;
            item.quantity--;
            param = { ...item };
          } else {
            productIndexs.push(index);
            count -= item.quantity;
            param.id = item.id;
            method = methods[1];
          }

          if (!userIsGuest) {
            param._config_dataset = PRODUCT_DATASET;
            promises.push(jsonRPC(method, param));
          }

          return;
        }
      });
    });

    if (count < parseInt(getters[getCartProductsCount])) {
      productIndexs.forEach((productIndex) => {
        products.splice(productIndex, 1);
      });

      if (userIsGuest) {
        commit(SET_CART_PRODUCTS, { products, saveToLocalStorage: userIsGuest });
        commit(SET_CART_PRODUCTS_COUNT, { count, saveToLocalStorage: userIsGuest });
        return Promise.resolve({ products, count, isRemoved: productIndexs.length > 0 });
      }

      return Promise.all(promises).then(() => {
        commit(SET_CART_PRODUCTS, { products, saveToLocalStorage: userIsGuest });
        commit(SET_CART_PRODUCTS_COUNT, { count, saveToLocalStorage: userIsGuest });
        return Promise.resolve({ products, count, isRemoved: productIndexs.length > 0 });
      });
    }

    return Promise.reject('Не найдены продукты на удаление');
  },

  clearCart({ commit, getters, rootGetters }) {
    const userIsGuest = rootGetters['session/userIsGuest'];

    if (userIsGuest) {
      commit(SET_CART_PRODUCTS, { products: [], saveToLocalStorage: userIsGuest });
      commit(SET_CART_PRODUCTS_COUNT, { count: 0, saveToLocalStorage: userIsGuest });
      return Promise.resolve();
    }

    let products = getters[getCartProducts];
    const promises = products.map((product) => {
      const param = {
        id: product.id,
        _config_dataset: PRODUCT_DATASET
      };

      return jsonRPC('deleteData', param);
    });

    return Promise.all(promises).then(() => {
      commit(SET_CART_PRODUCTS, { products: [], saveToLocalStorage: userIsGuest });
      commit(SET_CART_PRODUCTS_COUNT, { count: 0, saveToLocalStorage: userIsGuest });
      return Promise.resolve();
    });
  }
};
