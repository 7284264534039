import Constructor from '../components/Constructor/Constructor';
//const Constructor = () => import(/* webpackChunkName: "ui-constructor" */ '../components/Constructor/Constructor');
// Возможно проверка this.$route.params в App.vue происходит раньше, чем загружается ui-constructor.js

export default [
  {
    path: '/constructor',
    component: Constructor,
    meta: { requiresAuth: true }
  },
  {
    path: '/constructor/:childUrl',
    component: Constructor,
    meta: { requiresAuth: true }
  },
  {
    path: '/constructor/component/:componentId',
    component: Constructor,
    meta: { requiresAuth: true }
  }
];
