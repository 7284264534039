import { jsonRPC } from '@/api/api';
import dictionaryFactory from './factory';
import { SET_DICTIONARY, SET_DICTIONARY_DATA } from './mutations';

//Локальные названия действий
const PREFIX = 'Dictionaries';

export const loadDictionary = `${PREFIX}/loadDictionary`;

export default {
  [loadDictionary]({ commit, state }, { dictionaryName, key = 'id' }) {
    if (state.dictionaries[dictionaryName]) {
      return Promise.resolve(state.dictionaries[dictionaryName]);
    }
    const dictionaryObj = dictionaryFactory({ name: dictionaryName, key });
    commit(SET_DICTIONARY, { dictionaryName, options: dictionaryObj });
    return jsonRPC('getData', {
      _config_dataset: dictionaryName
    })
      .then((data) => {
        if (data) {
          commit(SET_DICTIONARY_DATA, { dictionaryName, data });
        }
        return dictionaryObj;
      })
      .catch((err) => {
        console.log(err);
      });
  }
};
