<template>
  <!--eslint-disable-->
  <DxPopup
    width="50vw"
    height="auto"
    popupMaxHeight="80vh"
    :animation="null"
    :visible="visible"
    :shading="false"
    :resizeEnabled="true"
    :minWidth="600"
    :toolbarItems="toolbarItems"
    @shown="$emit('shown')"
  >
    <template #titleTemplate>
      <PopupTitleTemplate
        text="Создание шаблона"
        :showCloseButton="true"
        @closeClick="$emit('hidden')"
      />
    </template>
    <template #contentTemplate>
      <div class="popup-new-component-form-content">
        <div class="dx-field">
          <div class="dx-field-label">Компонент</div>
          <div class="dx-field-value">
            <ComponentSearchSelectBox v-model="componentId"></ComponentSearchSelectBox>
          </div>
        </div>
        <div class="dx-field">
          <div class="dx-field-label">Название</div>
          <div class="dx-field-value">
            <DxTextBox v-model="name"> </DxTextBox>
          </div>
        </div>
        <div class="dx-field">
          <div class="dx-field-label">Группа</div>
          <div class="dx-field-value">
            <DxLookup
              v-model="groupId"
              :dataSource="$store.state.uiConstructor.componentTemplateGroups"
              valueExpr="id"
              displayExpr="name"
              :searchExpr="['name']"
              :showClearButton="false"
              :showPopupTitle="false"
            ></DxLookup>
          </div>
        </div>
      </div>
    </template>
  </DxPopup>
</template>

<script>
import DxLookup from 'devextreme-vue/lookup';
import DxTextBox from 'devextreme-vue/text-box';
import DxPopup from 'devextreme-vue/popup';
import PopupTitleTemplate from '../Containers/PopupTitleTemplate';
import ComponentSearchSelectBox from './ComponentSearchSelectBox';

const FIELDS = ['componentId', 'name', 'groupId'];
const mapFields = (fields) => {
  let result = {};
  fields.forEach((fieldName) => {
    result[fieldName] = {
      get() {
        return this.$store.state.uiConstructor.newComponentTemplate[fieldName];
      },
      set(value) {
        this.$store.dispatch('uiConstructor/setNewComponentTemplateField', {
          fieldName,
          fieldValue: value
        });
      }
    };
  });
  return result;
};

export default {
  components: {
    DxLookup,
    DxTextBox,
    DxPopup,
    ComponentSearchSelectBox,
    PopupTitleTemplate
  },
  data() {
    return {
      toolbarItems: [
        {
          toolbar: 'bottom',
          location: 'center',
          widget: 'dxButton',
          options: {
            text: 'Добавить',
            type: 'success',
            onClick: () => {
              this.apply();
            }
          }
        },
        {
          toolbar: 'bottom',
          location: 'center',
          widget: 'dxButton',
          options: {
            text: 'Отменить',
            onClick: () => {
              this.$emit('hidden');
            }
          }
        }
      ]
    };
  },
  props: {
    visible: Boolean
  },
  computed: {
    ...mapFields(FIELDS)
  },
  methods: {
    apply() {
      this.$store.dispatch('uiConstructor/saveComponentTemplate', this.$store.state.uiConstructor.newComponentTemplate).then(() => {
        this.$store.dispatch('uiConstructor/loadComponentTemplates');
        this.$emit('hidden');
      });
    }
  }
};
</script>
