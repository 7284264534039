export default function GisFactory(componentConfig) {
  const options = componentConfig.options || {};

  return {
    ...options,

    /**{Object} Ссылка на карту mapbox */
    mapbox: null,

    /**Показать лого mapbox, по умолчанию показываем */
    showMapboxLogo: options.showMapboxLogo !== false,

    /**Идентификатор компонента */
    componentId: componentConfig.componentId || null,

    /**Идентификатор выбранной базовой карты */
    activeBaseMapLayerComponentId: null,
    activeBaseTextLayerComponentId: null,
    activeTerrainLayerComponentId: null,

    /**Идентификатор выбранного в легенде слоя */
    activeLegendLayerComponentId: null,

    /**Установка инструмента при выборе слоя
     * Делается для дома
     * Объект типа {
		    vector: 'select',
		    pkk: 'objectIdentification',
		    wms: 'objectIdentification'
      }
    */
    selectToolOnActivateLegendLayer: options.selectToolOnActivateLegendLayer,

    /**
     * Включение видимости при выборе слоя
     */
    setVisibleOnActivateLegendLayer: options.setVisibleOnActivateLegendLayer ?? false,

    /**Время последнего клика на слое */
    lastLayerClickTime: 0,

    /**Время последнего клика на базовой карте */
    lastBaseLayerClickTime: 0,

    /**Идентификатор компонента-слоя для которого нужно показать панель дополнительных инструментов в легенде*/
    popupMenuLayerComponentId: null,

    /**DOM-елемент для которого нужно показать дополнительное меню слоя */
    popupMenuLayerTargetElement: null,

    /**Идентификатор компонента слоя, для которого отобразить всплыващее окно с легендой */
    iconTooltipLayerComponentId: null,

    /**Идентификатор компонента слоя, для которого сделали двойной клик и надо открыть форму редактирования */
    dblClickedLayerComponentId: null,

    /**Идентификатор редактируемого слоя */
    editingLayerComponentId: null,

    /**Идентификатор компонента слоя для которого открыли форму настройки стилей*/
    styleFormLayerComponentId: null,

    /**Идентификатор компонента слоя для редактирования настройки всплывающей подсказки*/
    tooltipFormLayerComponentId: null,

    /**Строка поиска слоев в легенде */
    legendLayerSearchValue: '',

    /**Ключ localStorage для сохранения положения карты */
    mapGroupName: options.mapGroupName || null,

    /**Включить или отключить возможность переноса слоев легенды между папками */
    allowDropBetweenGroup: options.allowDropBetweenGroup === false ? false : true,

    /**
     * Можно ли перемещать слои, по-умолчанию undefined
     */
    allowDragChildLayers: options.allowDragChildLayers,

    /**Радиус чувствительности в пикселях при выборе объектов кликом. Нужно для выбора линейных и точечных объектов */
    selectClickTolerance: options.selectClickTolerance || 5,

    /**При выделении буфером исключать уже выделенные объекты */
    bufferSelectExcludeSelected: options.bufferSelectExcludeSelected ?? false,

    /**Позволять дробные числа в величине буфера*/
    useBufferFractionalValue: options.useBufferFractionalValue ?? true,

    /**Масштабировать карту колесиком при нажатой кнопке CTRL*/
    scrollZoomWithCtrl: options.scrollZoomWithCtrl || false,

    /**При включении для выбора объектов слоя надо выбирать слой в легенде*/
    //TODO: реализовать
    enableForSelect: options.enableForSelect || false, //Это Устарело
    selectInActiveLayer: options.selectInActiveLayer || false, //Это новое название свойства

    /**Слои доступные для смены выделения. Необходимо для замораживания выделения в отчете */
    selectableLayerIds: null,

    /**Включить или отключить контекстное меню выбранного объекта */
    //TODO: реализовать
    objectContextMenu: options.objectContextMenu === false ? false : true,

    /**Спрятать кнопку "замок" в легенде слоев */
    hideLayerEnable: options.hideLayerEnable || false,

    /**Отобразить информацию по количеству слоя в легенде слоев */
    layerCountInfoVisible: options.layerCountInfoVisible || false,

    /**Отобразить информацию по площади слоя в легенде слоев */
    layerAreaInfoVisible: options.layerAreaInfoVisible || false,

    /**Отображение площади при добавлении или редактировании полигона */
    showEditPolygonArea: options.showEditPolygonArea || false,

    /**Отображение длины текущей линии при добавлении линии или полигона */
    showLastLineLength: options.showLastLineLength || false,

    /**Отключить проверки для косметического слоя"*/
    disabledСhecks: options.disabledСhecks ?? false,

    /**Массив возможных проверок для слоев"*/
    validationList: [],

    /**Массив выбранных проверок для косметического слоя*/
    selectedValidationsIdsList: [],

    /**Массив выбранных проверок для косметического слоя, сохраненных в базе*/
    selectedValidationsIdsListFromDataset: [],

    /**Спрятать кнопку редактирования ("карандаш") в легенде слоев */
    hideLayerEdit: options.hideLayerEdit || false,

    /**Отключить перерисовку по таймеру, устарело. Актуально там, где карту могу подвинуть соседние элементы страницы */
    //TODO: подумать о необходимости? Рефакторинг
    needResizeInterval: options.needResizeInterval || false,

    /**Положение легенды слоев */
    //TODO: рефакторинг положения?
    legendPos: options.legendPos || null,

    /**Показать или скрыть косметический слой */
    //TODO: реализовать на геттерах?
    showTmpLayer: options.showTmpLayer ?? true,

    /**Количество шагов в истории изменений для сохранения */
    //TODO: рефакторинг когда дело дойдет до редактирования
    historyLength: options.historyLength || null,

    /**Идентификатор слоя, для которого открыта таблица*/
    tablePanelLayerComponentId: null,

    /**Идентификатор слоя, для которого открыта статистика*/
    statsPanelLayerComponentId: null,

    /**Идентификатор слоя, для которого создаем слой по атрибуту с подписями*/
    signLayerPanelLayerComponentId: null,

    /**Идентификатор слоя, для которого создаем привязку для растров*/
    bindingTileLayerId: null,

    /**Флаг для использования filterBuilder в качестве панели фильтрации*/
    useFilterBuilder: options.useFilterBuilder || false,

    /**Настройки неба */
    skyOptions: {
      show: false,
      value: 30
    },

    /**Непрозрачность базовой карты */
    baseMapRasterOpacity: 1,

    /**Непрозрачность базовой тестовой карты */
    baseTextMapRasterOpacity: 1,

    /**Идентификатор слоя, для которого открыто окно фильтрации*/
    filterPanelLayerComponentId: null,

    /**Словарь Promise изображений, для которых начали загрузку информации*/
    loadingImageDataPromises: {},

    /**Словарь Promise изображений, для которых начали загрузку самих изображений для легенды */
    loadingImagePromises: {},

    /**Словарь изображений для легенды */
    images: {},

    /**Ссылка для запуска зугрузки файлов */
    downloadLink: '',

    /**Идентификатор источника mapbox для которого надо отобразить подсветку идентификации */
    identificationMapboxSourceId: null,

    /**source-layer для объекта для которого надо отобразить подсветку идентификации */
    identificationMapboxSourceLayer: null,

    /**geojson объекта для которого надо отобразить подсветку идентификации */
    identificationMapboxGeoJson: null,

    /**geojson объекта для подсветки текущей точки табличного редактора координат */
    coordinateTableMapboxGeoJson: null,

    /**Отобразить табличный редактор координат */
    showCoordinatesEditor: false,

    /**Идентификатор объекта из слоя mapbox для которого надо отобразить подсветку идентификации */
    identificationMapboxFeatureId: null,

    /**Идентификатор объекта с карты ПКК для подсветки идентификации слоя ПКК */
    identificationPkkObjectId: null,

    /**Идентификатор слоя ПКК для подсветки идентификации объекта ПКК */
    identificationPkkLayerId: null,

    /**Режим идентификации объектов: идентификация активного слоя (active), идентификация видимых слоев (visible)*/
    objectIdentificationMode: options.objectIdentificationMode || 'active',

    /**{GeoJson} Данные буферной зоны в которой производим выделение*/
    bufferSelectionGeoJson: null,

    /**{GeoJson} Объект результат оцифровки растра, до сохранения*/
    digitizedGeoJson: null,

    /**{GeoJson} Пересечение выбранного объекта с объектами рядом*/
    selectedObjectIntersectGeoJson: null,

    /**{GeoJson} Геометрия привязки растра*/
    bindingTileLayerGeoJson: null,

    /**Универсальный способ добавить слой оформления на карту
     * Объекты типа {id, source, layers}
     */
    tempCosmeticLayers: [],

    /**{Canvas} Координаты изображения для отоброжения тепловой карты*/
    heatmapOptions: {
      // Целевой атрибут по которому строим карту
      targetField: null,
      // Координаты области для отоброжения сгенерированного канваса
      coordArea: null,
      // Радиус
      radius: 30,
      // Макс. прозрачность
      maxOpacity: 0.8,
      // Мин. прозрачность
      minOpacity: 0.3,
      // Размытие
      blur: 0.85,
      // Цветовая палитра
      gradient: {
        0: '#003617',
        0.05: '#015D29',
        0.1: '#00883D',
        0.2: '#05AC4B',
        0.3: '#5FCC56',
        0.4: '#B0E46E',
        0.5: '#E5F497',
        0.6: '#FFED98',
        0.7: '#FFC76C',
        0.8: '#FF914A',
        0.9: '#FF5631',
        0.95: '#EA2320'
      },
      // Максимальное значение из выделенных данных
      maxValue: null,
      //Настройка для усреднения значения в данных
      absoluteValue: true,
      // Натсройка кастомного цвета
      customColor: false
    },

    /**lngLat координаты точки последнего клика на карте*/
    lastPointClicked: null,

    /**X,Y координаты точки последнего клика на карте*/
    lastPointClickedXY: null,

    /**{GeoJson} Ближайшие организации для инструмента "Доступность в точке"*/
    pointAvailabilityNearOrganisation: null,

    /**{GeoJson} Маршрут до выбранной организации для инструмента "Доступность в точке"*/
    pointAvailabilityObjectPath: null,

    /**Активный маршрут для инструмента "Доступность в точке"*/
    pointAvailabilityActiveRouteObjectId: null,

    /**{GeoJson} Проверка является ли целевой слой слоем "Медицинские организации" для инструмента "Доступность в точке"*/
    pointAvailabilityIsZdravLayer: false,

    /**{GeoJson} Инлекс открытого таба (зависит вид маршрута 1-пеший, 2-транспорт) для инструмента "Доступность в точке"*/
    pointAvailabilityRouteMode: null,

    /**
     * Режим загрузки маршрутов в инструменте доступности: multi - загружать весь список,
     * single - только выбранный маршрут
     */
    pointAvailabilityRouteLoadMode: options.pointAvailabilityRouteLoadMode || 'multi',

    /**{[GeoJson]} Массив точек маршрута, заданных пользователем*/
    routePoints: null,

    /**{GeoJson} Пересечение выбранного объекта с объектами рядом*/
    validationLayerObjectIntersectGeoJson: null,

    /**Свойство для задания видимой области. используется совместно с watch и mapbox.fitBounds */
    bounds: null,
    /**Параметр идет совместно с bounds, определяет масштаб перехода к эксенту */
    boxZoom: null,

    /**Параметры приближения к видимой области*/
    boundsOptions: null,

    /**Идентификаторы компонентов-слоев которые подгружены принудительно в специальных ситуациях, а их родительские
     * компоненты еще не загружены
     */
    legendNoParentLayerIds: [],

    /**Идентификатор компонента слоя для рисования на отчете */
    reportDrawLayerId: null,

    /**Идентификатор компонента слоя в котором делаем отчет */
    reportLayerComponentId: null,

    /**Идентификатор объекта для которого делаем отчет */
    reportFeatureId: null,

    /**Идентификато активного отчета */
    currentReportId: null,

    /**Плоский список всех слоев (название, id, датасет) */
    fullLayerList: [],

    /**Идентификатор компонента Gis, с которого надо взять слои (Возможно это костыль) */
    linkedGisComponentId: options.linkedGisComponentId || null,

    /**Идентификаторы компонентов-слоев, которые надо исключить при загрузке */
    //TODO: возможно это костыль
    excludeLayerComponentIds: options.excludeLayerComponentIds || null,

    /**Идентификатор слоя, открытого на редактирование
     * или одно из ['folder', 'vector', 'raster', 'service'] при добавлении нового слоя */
    editingOptionsLayerComponentId: null,

    /**Создание отчета */
    reportCreatingState: false,

    /**Выбранный инструмент снэппинга */
    activeSnapTool: null,

    /**Включать снепинг принудительно вне зависимсоти какой инструмент и редактируется ли слой
     * (для сервисных слоев проставления маркеров) */
    forceSnapTool: false,

    /**Инструменты показа пересечения выбранных объектов */
    activeSelectedIntersectTool: false,

    /**Отображение панели легенд */
    activeLegendPanel: options.activeLegendPanel ?? false,

    /**Отображение каталога слоев */
    activeFullLayersCatalog: false,

    /**Отображение панели фильров упрощенной карты */
    activeFilterPanelMinimize: false,

    /**Идентификатор слоя для отображения легенды в отдельном окне */
    activeLayerLegendId: null,

    /**координаты снэппинга */
    activeSnapLngLat: null,

    /**Видимые объекты карты*/
    visibleFeatures: [],

    /**Показывать или скрыть таймлайн */
    showTimeline: options.showTimeline ?? false,

    /**Отображать стандартный таймлайн в списке */
    showStandardTimeline: options.showStandardTimeline ?? true,

    /**Состояние кнопки показа/скрытия таймлайна */
    timelineButtonActive: false,

    /**Показывать панель избранных слоев*/
    showFavoritesPanel: options.showFavoritesPanel !== false,

    /**Данные картодиаграмм */
    mapDiagramData: null,

    /**Значение даты выбранной на таймлайне */
    selectedDate: null,

    /**Выбранный режим таймлайна */
    selectedTimelineMode: null,

    /**Выбранный режим отображения таймлайна */
    selectedTimelineViewType: null,

    timeline: {
      /**Вариант отображения временной шкалы */
      timelineViewType: options.timeline?.timelineViewType ?? 'stacked',

      /**Скорость анимации временной шкалы */
      timelineAnimateRate: options.timeline?.timelineAnimateRate ?? 0,

      /**Слои для анимации: массив объектов: {
				layerComponentId: '1368645',
				animateFinish: '2022-07-10T00:00:00',
				animateStart: '2022-05-30T00:00:00',
        filterField: 'code',
        filterFieldCaption: 'Дата снятия с кадастрового учета',
        layerTitle: 'Кадастровые кварталы'
			} */
      timelineAnimateLayers: options.timeline?.timelineAnimateLayers ?? []
    },

    /**{GisLayerFavoriteItem} Избранные слои пользователя*/
    favoriteLayerItems: [],

    /**{GisLayerFavoriteItem} Недавние слои пользователя*/
    recentLayerItems: [],

    /**Промис загрузки избранных слоев*/
    loadFavoriteLayersPromise: null,

    /**Промис загрузки данных избранных*/
    loadFavoriteLayerItemsPromise: null,

    /**Выбранная фильтрация списка слоев*/
    legendLayersFilter: 'all',

    /**Максимальное кол-во недавних слоев */
    maxRecentLayers: options.maxRecentLayers || 10,

    /**Максимальное количество скриншотов при формировании отчета по отфильтрованным объектам*/
    maxAutoScreenshots: options.maxAutoScreenshots || 50,

    /**Отображать масштаб в панели информации*/
    showScale: options.showScale === false ? false : true,

    /**Отображать текущие координаты в панели информации*/
    showCoordinates: options.showCoordinates === false ? false : true,

    /**Отображать копирайт в панели информации*/
    showCopyright: options.showCopyright === false ? false : true,

    /**Показывать панель космоснимков */
    showSpaceImageryPanel: false,

    /**Найденные при поиске снимки */
    spaceImageryFoundFeatures: [],

    /**Подсвеченный среди найденных при поиске снимков */
    spaceImageryHoveredFeatureId: null,

    /**Видимые на карте найденные при поиске снимки */
    spaceImageryVisibleFeatureIds: [],

    /**Ключ доступа к Планет */
    planetApiKey: options.planetApiKey || '7de79fb692a642fd8881fa3d18c2c3dd',

    /**Массовый отчет для не выделенных объектов */
    massReportEnable: options.massReportEnable || false,

    /**Параметры калькулятора для векторного слоя**/
    calcForm: {
      /**Поле, которое будет вычисляться**/
      fieldName: null,

      /**Идентификатор компонента слоя**/
      layerComponentId: null,

      /**Выбранные id**/
      objects: [],

      /**Видимость калькулятора**/
      visible: false
    },

    //Источники данных с ошибками во время загрузки
    sourceErrors: {},

    /**Объект с правами */
    rights: {
      /**Добавление слоев */
      addLayer: true,

      /**Создание папки */
      addFolder: true,

      /**Подключение сервиса */
      addService: true,

      /**Создание векторных слоев*/
      addVectorLayer: true,

      /**Создание слоев по нейросети*/
      addNeuralVectorLayer: true,

      /**Создание слоя вырубки леса по нейросети*/
      addNeuralFellingVectorLayer: true,

      /**Создание слоя доступности*/
      addAvailabilityLayer: true,

      /**Создание растрового слоя*/
      addRasterLayer: true,

      /**Открытие слоя*/
      openLayer: true,

      /**Оверлейные операции */
      overlayOperations: true,

      /**Растровые оверлейные операции */
      rasterOverlayOperations: true,

      /**Векторные операции */
      vectorOperations: true,

      /** */
      viewLayerStatistics: true,

      /**Проверка топологии */
      topologyCheck: true,

      /**Сохранение перемещенных слоев на сервер */
      moveLayer: true,

      /**Удаление слоев */
      removeLayer: true,

      /**Редактирование слоев */
      editLayer: true,

      /**Редактирование стилей слоев */
      editLayerStyle: true,

      /**Редактирование объектов слоя (Карандаш) */
      editLayerObjects: true,

      /**Приближение к слою */
      moveToLayer: true,

      /**Экспорт данных слоя */
      exportLayerData: true,

      /**Создать буферную зону */
      createBufferZoneLayer: true,

      /**Кнопка сворачивания всех папок*/
      collapseAllFolders: true,

      /**Вкладка проверки для косметического слоя*/
      validations: true,

      /**Настройка данных слоя*/
      editLayerData: true,

      /**Настройка класса слоя*/
      editLayerClass: true,

      /**Геокодирование данных (на форме слоя на вкладке "Колонки")*/
      geocodeInLayerForm: true,

      /**Кнопка калькулятора полей на форме слоя на вкладке "Колонки"*/
      calcInLayerForm: true,

      /**Кнопка расчета колонок на форме слоя на вкладке "Колонки"*/
      calcFieldInLayerForm: true,

      //** Выбор систем координат в инструменте "Поиск точки по координатам"*/
      searchPointEpsgListEnabled: true,

      //** Возможность создать растровый слой на геосервере*/
      rastrGeoserver: true,

      //** Отображение карты в упрощенном виде*/
      gisMinimized: false,

      /**Возможность переключение систем геокодирования в верхнем тулбаре карты */
      geocodeSystems: false,

      /**Скачать растер слоя*/
      downloaRaster: true,

      ...(options.rights || {})
    },

    //Кнопки оверлейных операций
    visibleOverlayOperations: {
      //Вырезание
      clip: true,

      //Пересечение
      intersect: true,

      //Стирание
      cut: true,

      //Объединение
      union: true,

      //Идентичность
      identity: true,

      //Симметричная разность
      symmetrical: true,

      //Ближайший объект (Вариант 1)
      nearest_object1: true,

      //Ближайший объект (Вариант 2)
      nearest_object2: true,

      //Сложение слоев
      adding: true,

      //Геометрическое пересечение
      update: true,

      //Разбиение слоя на более мелкие покрытия
      split: true,

      //Пространственное объединение
      spatial_join: true,

      //Объединение в рамках одного слоя
      union_in_layer: true,

      //Корректировка топологии
      correct_topology: true,

      ...(options.visibleOverlayOperations || {})
    },

    //Кнопки растровых оверлейных операций
    visibleRasterOverlayOperations: {
      //Поиск отличий
      change_detection: true,

      //Вырезать по полигону
      cut_by_polygon: true,

      //Паншаперинг
      pan_sharpening: true,

      //Операция сшивания растровых слоев
      merging_raster_layers: true,

      //Операция стирания по маскам
      delete_by_mask: true,

      ...(options.visibleRasterOverlayOperations || {})
    },

    //Доступные векторные операции
    visibleVectorOperations: {
      polygon_smoothing: true,
      union_by_attribute: true,

      ...(options.visibleVectorOperations || {})
    },

    // Дефолтный атрибут для всех объектов в косметическом слое
    defaultTmpLayerProperties: {
      caption: 'Атрибут косметического слоя',
      dataField: 'tmp_properties',
      dataType: 'string',
      ...(options.defaultTmpLayerProperties || {})
    },

    //Кнопки проверки топологии
    visibleTopologyCheck: {
      //Смежность полигонов
      adjacency: true,

      //Пересечение полигонов
      intersect: true,

      //Черезполосица
      stripmess: true,

      //Корректность полигонов
      correctness: true,

      //Вхождение полигонов
      entry: true,

      //Поиск дублей
      doubles: true,

      //Кластерный допуск
      cluster_tolerance: true,

      ...(options.visibleTopologyCheck || {})
    },

    //Режим, в котором кнопка оверлейных операций переносится в тулбар
    overlayOperationsInToolbar: options.overlayOperationsInToolbar || false,

    //Видимость окна оверлейных операций
    overlayOperationsPopupVisible: false,

    //Видимость окна создания слоя доступности
    availabilityLayerPopupVisible: false,

    //Видимость окна создания слоя вырубки леса по нейросети
    neuralFellingVectorLayerVisible: false,

    //Видимость окна растровых оверлейных операций
    rasterOverlayOperationsPopupVisible: false,

    //Видимость окна проверки топологии
    topologyCheckPopupVisible: false,

    //Видимость окна векторных операций
    vectorOperationsPopupVisible: false,

    //Видимость окна проверки изменения системы координат
    coordinateSystemChangePopupVisible: false,

    //Задает стиль курсора
    mapCursorStyle: '',

    //Выбранный инструмент карты
    selectedMouseTool: options.activeMouseTool || 'select',

    //Идентификатор компонента-слоя пересечения с косметическим слоем
    tmpLayerIntersectLayerId: options.tmpLayerIntersectLayerId || null,

    //Минимальный зум при интерполяции слоев
    minInterpolationZoom: options.minInterpolationZoom || 12,

    //Отображение координат в панели идентификации
    getCoordinatesOnClick: options.getCoordinatesOnClick || false,

    //Возможность включения даты из таймлайна в фильтр слоя
    timeLineDateInFilter: options.timeLineDateInFilter || false,

    //Кнопка отображения рельефа
    showTerrainButton: options.showTerrainButton ?? true,

    //Кнопка отображения неба
    showSkyButton: options.showSkyButton ?? true,

    //Копка выбора активного сервиса панорам
    showPanoramasButton: options.showPanoramasButton || false,

    //Развернуть панораму на всю карту
    panoramaFullSize: options.panoramaFullSize || false,

    //TODO Удалить если не попросят вернуть 19.05.2022
    //Отображение таблицы объектов слоя внутри окна (как в старой версии)
    layerTableInPopup: options.layerTableInPopup || false,

    // Слайдер непрозрачности
    showOpacitySlider: options.showOpacitySlider ?? true,

    // Устанавливать положение карты по данным пользователя
    fitBoundsByUserData: options.fitBoundsByUserData ?? true,

    // Настройка прозрачности растровых слоев
    showRasterLayerOpacity: options.showRasterLayerOpacity ?? true,

    // Диаметр преобразования геометрии
    diametreChangedGeometry: options.diametreChangedGeometry || 0.05,

    // Отображать подсказки для слоев
    showTooltipForLayer: options.showTooltipForLayer ?? true,

    // Отображать фильтрацию по пересечению слоев
    //TODO: Это более не нужно? удалить?
    showFilteringByIntersection: options.showFilteringByIntersection ?? true,

    // Отображать тепловую карту в форме стилей
    showHeatMapInStyleLayer: options.showHeatMapInStyleLayer ?? true,

    // Отображать полигон с высотой в форме стилей
    showFillExtrusionInStyleLayer: options.showFillExtrusionInStyleLayer ?? true,

    // Отображать кластеризацию в форме стилей
    showClusterInStyleLayer: options.showClusterInStyleLayer ?? true,

    // Отображать настройку стиля наведения в форме стилей
    showHoverStyleInStyleLayer: options.showHoverStyleInStyleLayer ?? true,

    // Отображать кнопку "Текстовый слой" в нижнем правом углу
    showBaseTextMapButton: options.showBaseTextMapButton ?? true,

    // Отображать кнопку выбора базовой карты в нижнем правом углу
    showBaseMapButton: options.showBaseMapButton ?? true,

    // Отображать настройку стиля выделения в форме стилей
    showSelectionStyleInStyleLayer: options.showSelectionStyleInStyleLayer ?? true,

    //id мультислоя ПКК для работы с поиском по кн
    pkkLayerComponentId: options.pkkLayerComponentId ?? null,

    //ключ для яндекса
    yandexApiKey: options.yandexApiKey || '55fcf5bd-0f38-43be-adfc-930571cf30c9',

    showVisibleLayers: options.rights?.gisMinimized ? false : options.showVisibleLayers ?? true,

    //настройка указывающая, можно ли создавать веторные слои с мультигеометрией
    enableCreatingMultipleGeomTypes: options.enableCreatingMultipleGeomTypes ?? true,

    //настройка указывающая, использовать ли для определения центра объекта centroid или centerOfMass
    isCentroid: options.isCentroid !== false,

    // Подтвердить завершение редактирования объекта (диалоговое окно)
    confirmEndDrawObject: options.confirmEndDrawObject ?? false,

    // Альтернативный вид карты упрощенного вида
    minimizeAlternativeView: options.minimizeAlternativeView ?? false,

    gisMinimizeVisibleLayerIds: options.rights?.gisMinimized ? [] : null,

    openCardsGrid: false,

    selectedObjectId: null,
    // Режим работы инструмента выреза/добавление области к выделеному объекту: если true - значит в вырезаное области добавляем вырезанный отдельный объект, если false соответсвенно делаем только вырез
    partToolMode: false,

    //** Целевой слой для поиска объектов для инвестиционной карты*/
    gisMinimizedSearchLayerId: options.gisMinimizedSearchLayerId || null,

    // Использовать "горячие" клавиши
    useShortcuts: options.useShortcuts ?? true,

    /** Настройка скорости скролла */
    scrollSpeed: options.scrollSpeed || 450,

    // Скрывать окна базовых слоев при выборе элемента
    hideOnCLickBaseMapList: options.hideOnCLickBaseMapList || false,

    // Система геокодирования для геокодера по дефолту 1 (Яндекс)
    geocodeSystem: options.geocodeSystem || 1,

    /**Дополнительные слои для геокодирования кадастрового номера.
     * Структура объектов в массиве: {layerComponentId, ancestorComponentId} */
    geocodeKdLayers: options.geocodeKdLayers ?? [],

    // Место размещение по умолчанию для создания векторных слоев
    connectionCodeDefault: options.connectionCodeDefault || 'LAYERS',

    // Размер указателя инструмента выдавливания
    lineEraserPointerSize: options.lineEraserPointerSize || 40,

    // Размер указателя инструмента стерки
    pointEraserPointerSize: options.pointEraserPointerSize || 40,

    //Инструмент стерка доступен только для выделенных
    eraserToolSelectionOnly: options.eraserToolSelectionOnly ?? false,

    // массив gis-компонентов из альтернативных (десктопной/планшетной/мобильной) версий страницы
    gis_componentIds: options.gis_componentIds || null,

    // объект соответствий  компонентов слоя из альтернативных (десктопной/планшетной/мобильной) версий страницы
    gis_layerComponentIds: options.gis_layerComponentIds || null,

    maxVisibleLayersCount: options.maxVisibleLayersCount || 10,

    // Сдвиг вверх точечного объекта при locate
    shiftPoint: options.shiftPoint || 100,

    // Сдвиг вверх полигона при locate
    shiftPolygon: options.shiftPolygon || 150,

    //id объекта для карточки в косметическом слое
    tmpLayerCardId: null,
    // Видимость карточки в косметическом слое
    tmpCardPopupVisible: false,

    // Слой, в котором должна происходить фильтрация по objectId в url
    urlFilterdlayer: options.urlFilterdlayer || null,

    //Копка "Удалить все правила" в окне стилей слоя
    showDeleteAllStyleRulesButton: options.showDeleteAllStyleRulesButton || false,

    //Маркеры на карте, после клика или поиска import {Markers} from '../utils'
    markers: [],

    //Сохранение видимости, доступности слоев, текста и выбранной подложки
    persistLayerState: options.persistLayerState ?? true,

    //Контейнер попапов, для доступа из конструктора
    popoverContainer: null,

    //Сбрасывать идентификацию ПКК при смене слоя или инструмента
    resetPkkIdentification: options.resetPkkIdentification ?? true,

    //Кнопка "Поделиться слоем" в панели инструментов слоя
    showShareLayerButton: options.showShareLayerButton ?? true
  };
}
