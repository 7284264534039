<template>
  <!--eslint-disable-->
  <div class="add-box">
    <div class="add-box__add-button">
      <DxButton
        type="default"
        icon="plus"
        @click="addItem"
      />
    </div>
    <div
      class="add-box__items"
      v-if="items && items.length"
    >
      <div
        class="add-box__item"
        v-for="(item, index) in items"
        :key="index"
      >
        <DxTextBox
          class="add-box__value"
          :value="item"
          @change="changeValue($event, index)"
        />
        <DxButton
          class="add-box__delete-button"
          type="danger"
          icon="trash"
          @click="deleteItem(index)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import DxTextBox from 'devextreme-vue/text-box';
import { DxButton } from 'devextreme-vue/button';
export default {
  name: 'AddBox',
  components: {
    DxTextBox,
    DxButton
  },
  props: {
    value: Array,
    name: String
  },
  data() {
    return {
      items: this.value ? [...this.value] : []
    };
  },
  methods: {
    addItem() {
      this.items.push('');
      this.$emit('change', this.items);
    },
    deleteItem(index) {
      this.items.splice(index, 1);
      let value = null;
      if (this.items.length) {
        value = this.items;
      }
      this.$emit('change', value);
    },
    changeValue(event, index) {
      this.items[index] = event.component.option('value');
      this.$emit('change', this.items);
    }
  }
};
</script>

<style lang="scss">
.add-box {
  display: flex;
  align-items: flex-end;

  &__items {
    flex-grow: 1;
  }

  &__item:not(:last-child) {
    margin-bottom: 5px;
  }

  &__item {
    display: flex;
  }

  &__value {
    flex-grow: 1;
    margin-right: 10px;
  }

  &__add-button {
    margin-right: 10px;
  }
}
</style>
