<template>
  <!--eslint-disable-->
  <DxPopup
    width="50vw"
    height="auto"
    popupMaxHeight="70vh"
    :minWidth="600"
    maxHeight="100%"
    :animation="null"
    :visible="visible"
    :shading="false"
    :resizeEnabled="true"
    :title="title"
    :toolbarItems="eventActionId === null ? toolbarItems : null"
    @shown="openPopup"
    @hidden="closePopup"
  >
    <template #titleTemplate>
      <PopupTitleTemplate
        :text="title"
        :showCloseButton="true"
        @closeClick="$emit('hidden')"
      />
    </template>
    <template #contentTemplate>
      <div class="popup-action-form-content">
        <DxSwitch
          class="popup-action-form-content__switch"
          :width="100"
          switchedOffText="Включено"
          switchedOnText="Отключено"
          :activeStateEnabled="false"
          :focusStateEnabled="false"
          :value="disable"
          @valueChanged="toggleDisableAction"
        />
        <SearchComponentForm
          v-model="actionComponentId"
          :places="places"
          :selectionPlace="selectionPlace"
          :dropDownDataSource="dropDownComponents"
          @changePlace="onChangePlace"
          @treeViewItemSelectionChanged="handleComponentClick"
          @selectionChanged="actionComponentChanged"
          @dropDownValueChanged="ComponentDropDownBoxChanged"
        />
        <div class="dx-field">
          <div class="dx-field-label">Действие</div>
          <div class="dx-field-value">
            <DxLookup
              :dataSource="componentActionTypes"
              displayExpr="actiontype_name"
              valueExpr="actiontype_id"
              v-model="actionTypeId"
              item-template="lookup-item"
            >
              <DxDropDownOptions
                :closeOnOutsideClick="true"
                :showTitle="false"
              />
              <template #lookup-item="{ data }">
                <div class="popup-new-component-form-content__lookup-item">
                  <i
                    v-if="!actionTypeRegistred(data.actiontype_name)"
                    class="core core-075-attention popup-action-form-content__icon"
                    title="Действие не реализовано"
                  />
                  <div :class="`${actionTypeRegistred(data.actiontype_name) ? 'action-name' : '' }`">
                    {{ data.actiontype_name }}
                  </div>
                </div>
              </template>
            </DxLookup>
          </div>
        </div>
        <div class="dx-field">
          <div class="dx-field-label">Родитель</div>
          <div class="dx-field-value">
            <DxDropDownBox
              v-model="parentId"
              valueExpr="id"
              displayExpr="id"
              :showClearButton="true"
              :dataSource="eventActions"
              @valueChanged="syncTreeViewSelection($event)"
            >
              <template #content>
                <DxTreeView
                  :dataSource="eventActions"
                  data-structure="plain"
                  keyExpr="id"
                  parentIdExpr="parent_id"
                  :focusStateEnabled="false"
                  :selectByClick="true"
                  :searchEnabled="true"
                  :searchExpr="['id','actiontype_comments','action_name','actioncomponent_name','actioncomponent_id']"
                  selectionMode="single"
                  selectedExpr="selectedAsParent"
                  @contentReady="$event.component.selectItem($store.state.uiConstructor.selectedActionParentId)"
                  @itemSelectionChanged="parentActionTreeViewItemSelectionChanged($event)"
                  ref="parentEventActionTreeView"
                >
                  <template #item="{data: item}">
                    <div :key="item.id">{{ getTreeItemText(item) }}</div>
                  </template>
                </DxTreeView>
              </template>
            </DxDropDownBox>
          </div>
        </div>
        <DxToolbar>
          <DxToolbarItem
            v-if="eventActionId !== null"
            widget="dxButton"
            :options="addButtonOptions"
            location="before"
          ></DxToolbarItem>
          <DxToolbarItem
            v-if="eventActionId !== null"
            widget="dxButton"
            :disabled="selectedParamIds.length === 0"
            :options="copyButtonOptions"
            location="before"
          ></DxToolbarItem>
          <DxToolbarItem
            v-if="eventActionId !== null"
            widget="dxButton"
            :options="pasteButtonOptions"
            location="before"
          ></DxToolbarItem>
          <DxToolbarItem
            v-if="eventActionId !== null"
            widget="dxButton"
            :options="deleteButtonOptions"
            location="after"
          ></DxToolbarItem>
        </DxToolbar>
        <div class="popup-action-form-content__params-grid">
          <DxDataGrid
            v-if="eventActionId !== null"
            height="100%"
            :showRowLines="true"
            :showBorders="true"
            :dataSource="eventActionParams"
            keyExpr="id"
            :allowColumnResizing="true"
            :selection="{mode:'single'}"
            @rowDblClick="paramDblClick"
            @selectionChanged="paramSelectionChanged"
          >
            <DxColumn
              data-field="id"
              caption="ID"
            ></DxColumn>
            <DxColumn
              data-field="inputname"
              caption="Входящее название"
            ></DxColumn>
            <DxColumn
              data-field="outputname"
              caption="Исходящее название"
            ></DxColumn>
            <DxColumn
              data-field="outputvalue"
              caption="Исходящее значение"
            ></DxColumn>
            <DxColumn
              data-field="datatype_name"
              caption="Тип параметра"
            ></DxColumn>
            <DxColumn
              data-field="is_private"
              caption="Приватный"
              :width="75"
            ></DxColumn>
            <DxColumn
              data-field="is_array"
              caption="Массив"
              :width="75"
            ></DxColumn>
            <DxColumn
              data-field="component_id"
              caption="Компонент"
            ></DxColumn>
          </DxDataGrid>
        </div>
        <PopupActionParamForm
          :visible="paramFormVisible"
          :title="paramFormTitle"
          :params="eventActionParamNames"
          @hidden="paramFormVisible = false"
          @shown="paramFormVisible = true"
          @saved="paramSaved"
        />
      </div>
    </template>
  </DxPopup>
</template>

<script>
import DxButton from 'devextreme-vue/button';
import { DxDataGrid, DxColumn } from 'devextreme-vue/data-grid';
import DxDropDownBox from 'devextreme-vue/drop-down-box';
import { DxLookup, DxDropDownOptions } from 'devextreme-vue/lookup';
import DxPopup from 'devextreme-vue/popup';
import PopupTitleTemplate from '../Containers/PopupTitleTemplate';
import DxTreeView from 'devextreme-vue/tree-view';
import DxSwitch from 'devextreme-vue/switch';
import { DxToolbar, DxItem as DxToolbarItem } from 'devextreme-vue/toolbar';
import componentsSearchStore from './componentsSearchStore';
import PopupActionParamForm from './PopupActionParamForm';
import SearchComponentForm from './SearchComponentForm';
import { deepCopy } from '../../utils/deep';
import { VUEX_ACTIONS } from '@/utils/actionToVuexMigration';

const serviceComponentName = 'serviceComponent (общие действия)';
const FIELDS = ['actionComponentId', 'actionTypeId', 'parentId'];
const mapFields = (fields) => {
  let result = {};
  fields.forEach((fieldName) => {
    result[fieldName] = {
      get() {
        return this.$store.state.uiConstructor.selectedEventAction[fieldName];
      },
      set(value) {
        this.$store.dispatch('uiConstructor/setSelectedEventActionField', {
          fieldName,
          fieldValue: value
        });
      }
    };
  });
  return result;
};

export default {
  components: {
    DxButton, // eslint-disable-line
    DxDataGrid,
    DxColumn,
    DxDropDownBox,
    DxLookup,
    DxPopup,
    DxDropDownOptions,
    DxToolbar,
    DxToolbarItem,
    DxTreeView,
    PopupActionParamForm,
    SearchComponentForm,
    PopupTitleTemplate,
    DxSwitch
  },
  data() {
    return {
      addButtonOptions: {
        icon: 'plus',
        type: 'default',
        hint: 'Добавить параметр',
        onClick: () => {
          this.addParam();
        }
      },
      copyButtonOptions: {
        icon: 'fas fa-copy',
        type: 'normal',
        hint: 'Копировать параметр',
        onClick: () => {
          this.copyParam();
        }
      },
      pasteButtonOptions: {
        icon: 'fas fa-paste',
        type: 'normal',
        hint: 'Вставить параметр',
        onClick: () => {
          this.pasteParam();
        },
        onInitialized: (eventData) => {
          this.pasteButton = eventData.component;
          window.addEventListener('storage', (event) => {
            if (event.key !== 'copiedEventActionParams' || event.storageArea !== localStorage) {
              return;
            }
            this.refreshPasteButtonOptions();
          });

          this.refreshPasteButtonOptions();
        }
      },
      deleteButtonOptions: {
        icon: 'trash',
        type: 'danger',
        hint: 'Удалить параметр',
        onClick: () => {
          this.deleteParam();
        }
      },
      places: {
        onPage: 'Компонент на странице',
        global: 'Компонент глобальный',
        serviceComponent: serviceComponentName,
        active: 'Компонент выбранный'
      },
      selectionPlace: 'Компонент на странице',
      paramFormVisible: false,
      paramFormTitle: '',
      selectedParamIds: [],
      pasteButton: '',
      isShownPopup: false
    };
  },
  props: {
    visible: Boolean,
    title: {
      type: String,
      default: ''
    }
  },
  computed: {
    selectedEventAction() {
      return this.$store.state.uiConstructor.selectedEventAction;
    },
    eventActionId() {
      return this.selectedEventAction.id;
    },
    componentActionTypes() {
      return this.$store.state.uiConstructor.componentActionTypes;
    },
    disable() {
      return this.selectedEventAction.disable ?? false;
    },
    eventActions() {
      return this.$store.state.uiConstructor.selectedEventTypeActions.filter((eventAction) => {
        return eventAction.id !== this.eventActionId;
      });
    },
    eventActionParams() {
      return this.$store.state.uiConstructor.selectedEventActionParams;
    },
    eventActionParamNames() {
      let set = new Set();
      this.eventActionParams.forEach((param) => {
        if (param.datatype_id !== 17 && param.outputname !== '_config_condition') {
          set.add(param.outputname);
        }
      });

      return Array.from(set);
    },
    pageComponents() {
      return this.$store.getters['config/getComponentsList'];
    },
    globalComponents() {
      return this.$store.getters['uiConstructor/loadedGlobalComponents'];
    },
    dropDownComponents() {
      return [...this.pageComponents, ...this.globalComponents]
        .map((item) => {
          return { ...item, visible: true };
        })
        .sort((item_1, item_2) => {
          return item_1.componentPos - item_2.componentPos;
        });
    },
    isDisabledSaveButton() {
      return this.actionComponentId === null || this.actionComponentId === undefined || this.actionTypeId === null || this.actionTypeId === undefined;
    },
    toolbarItems() {
      return [
        {
          toolbar: 'bottom',
          location: 'center',
          widget: 'dxButton',
          disabled: this.isDisabledSaveButton,
          options: {
            text: 'Сохранить',
            type: 'success',
            onClick: () => {
              this.saveEventAction();
            }
          }
        },
        {
          toolbar: 'bottom',
          location: 'center',
          widget: 'dxButton',
          options: {
            text: 'Отменить',
            onClick: () => {
              this.closePopup();
            }
          }
        }
      ];
    },
    ...mapFields(FIELDS)
  },
  watch: {
    eventActionId() {
      this.loadEventActionParams();
      if (!this.selectedEventAction.eventtype_id || !this.selectedEventAction.eventcomponent_id) {
        this.paramFormVisible = false;
      }
    },
    actionComponentId() {
      if (this.eventActionId !== null && this.isShownPopup) this.saveEventAction();
    },
    actionTypeId() {
      if (this.eventActionId !== null && this.isShownPopup) this.saveEventAction();
    },
    parentId() {
      if (this.eventActionId !== null && this.isShownPopup) this.saveEventAction();
    },
    disable() {
      if (this.disable !== null && this.isShownPopup) this.saveEventAction();
    }
  },
  methods: {
    toggleDisableAction({ value }) {
      this.setSelectedEventActionField('disable', value);
    },
    openPopup() {
      if (this.actionComponentId !== null) {
        const currentComponent = this.$store.getters['config/getItem'](this.actionComponentId);

        if (currentComponent === undefined) {
          this.loadGlobalComponentById(this.actionComponentId);

          if (this.actionComponentId === 0) {
            this.selectionPlace = serviceComponentName;
            this.$store.dispatch('uiConstructor/loadComponentActionTypes', 0);
          } else {
            this.selectionPlace = 'Компонент глобальный';
          }
        } else {
          this.selectionPlace = 'Компонент на странице';
          const componentsType = this.$store.getters['uiConstructor/componentTypes'];
          const currentComponentType = componentsType.find((componentType) => {
            return componentType.name === currentComponent.componentType;
          });
          this.$store.dispatch('uiConstructor/loadComponentActionTypes', currentComponentType.id);
        }
      } else {
        this.selectionPlace = 'Компонент на странице';
      }
      this.isShownPopup = true;
      this.$emit('shown');
    },
    closePopup() {
      this.isShownPopup = false;
      this.$emit('hidden');
    },
    loadGlobalComponentById(componentId) {
      this.$store.dispatch('uiConstructor/loadGlobalComponentById', {
        componentId
      });
    },
    actionTypeRegistred(actionName) {
      // Действие может быть во VueX
      const actionExistInVueX = VUEX_ACTIONS.includes(actionName);

      if (actionExistInVueX) {
        return true;
      }

      if (this.actionComponentId !== null) {
        const { componentType } = this.$store.getters['config/getItem'](this.actionComponentId) || {};

        if (this.componentTypeRegistred(componentType)) {
          const component = this.$options.components[componentType];

          // Если options нет, значит компонент загружен асинхронно
          if (!component.options) {
            return !!component.resolved?.options.methods?.[actionName];
          }

          return !!component.options?.methods?.[actionName];
        }
      }

      return false;
    },
    componentTypeRegistred(name) {
      return !!this.$options.components[name];
    },
    onChangePlace(value) {
      this.selectionPlace = value;
    },
    handleComponentClick(eventData) {
      this.$store.dispatch('uiConstructor/setSelectedEventActionField', {
        fieldName: 'actionComponentId',
        fieldValue: +eventData.itemData.componentId
      });
    },
    ComponentDropDownBoxChanged(eventData) {
      const componentsType = this.$store.getters['uiConstructor/componentTypes'];
      const currentComponent = this.$store.getters['config/getItem'](eventData.value);

      if (eventData.value !== null && !!currentComponent) {
        const currentComponentType = componentsType.find((componentType) => {
          return componentType.name === currentComponent.componentType;
        });
        this.$store.dispatch('uiConstructor/loadComponentActionTypes', currentComponentType.id);
      } else if (+eventData.value === 0) {
        //Если сервисный компонент
        this.$store.dispatch('uiConstructor/loadComponentActionTypes', eventData.value);
      } else {
        this.$store.dispatch('uiConstructor/clearComponentActionTypes');
      }
    },
    componentDisplayExpr(data) {
      return data ? `${data.name} (${data.id} ${data.componenttype_name})` : '';
    },
    componentSearchStore() {
      return componentsSearchStore;
    },
    actionComponentChanged(eventData) {
      if (eventData.selectedItem !== null) {
        const currentComponent = this.$store.getters['config/getItem'](eventData.selectedItem.id);
        if (currentComponent === undefined) {
          this.loadGlobalComponentById(eventData.selectedItem.id);
        }
        this.$store.dispatch('uiConstructor/loadComponentActionTypes', eventData.selectedItem.componenttype_id);
      } else {
        this.$store.dispatch('uiConstructor/clearComponentActionTypes');
      }
    },
    saveEventAction() {
      this.$store.dispatch('uiConstructor/saveEventAction', this.selectedEventAction).then((savedEventAction) => {
        this.$emit('saved', savedEventAction);
      });
    },
    getTreeItemText(item) {
      return `${item.id} ${item.actiontype_comments} (${item.action_name}) ${item.actioncomponent_name} (${item.actioncomponent_id})`;
    },
    syncTreeViewSelection() {
      if (!this.$refs.parentEventActionTreeView) {
        return;
      }

      if (!this.actionParentId) {
        this.$refs.parentEventActionTreeView.instance.unselectAll();
      } else {
        this.$refs.parentEventActionTreeView.instance.selectItem(this.actionParentId);
      }
    },
    setSelectedEventActionField(fieldName, fieldValue) {
      this.$store.dispatch('uiConstructor/setSelectedEventActionField', {
        fieldName: fieldName,
        fieldValue: fieldValue
      });
    },
    parentActionTreeViewItemSelectionChanged(eventData) {
      this.setSelectedEventActionField('parentId', eventData.itemData.id);
    },
    paramDblClick(eventData) {
      this.paramFormVisible = true;
      this.paramFormTitle = `Параметр ${eventData.data.outputname} (${eventData.data.datatype_name})`;
      this.$store.dispatch('uiConstructor/setSelectedEventActionParam', eventData.data);
    },
    addParam() {
      this.paramFormVisible = true;
      this.paramFormTitle = `Добавление параметра: ${this.title}`;
      this.$store.dispatch('uiConstructor/setSelectedEventActionParam', {
        eventaction_id: this.eventActionId
      });
    },
    paramSelectionChanged(eventData) {
      this.selectedParamIds = eventData.selectedRowKeys;
    },
    copyParam() {
      const selectedParams = this.selectedParamIds.map((paramId) => {
        return this.eventActionParams.find((item) => item.id === paramId);
      });
      localStorage.setItem('copiedEventActionParams', JSON.stringify(selectedParams));
      if (this.pasteButton) {
        this.pasteButton.option('disabled', false);
      }
    },
    pasteParam() {
      const eventActionParams = this.getCopiedEventActionParams();
      if (!eventActionParams) {
        return;
      }

      eventActionParams.map((param) => {
        let copiedParam = {};
        deepCopy(param, copiedParam);
        delete copiedParam.id;
        copiedParam.eventaction_id = this.eventActionId;
        this.$store.dispatch('uiConstructor/copyEventActionParam', copiedParam).then((paramData) => {
          this.$store.dispatch('uiConstructor/setSelectedEventActionParam', paramData);
          this.loadEventActionParams();
        });
      });
    },
    deleteParam() {
      Promise.all(
        this.selectedParamIds.map((paramId) => {
          return this.$store.dispatch('uiConstructor/deleteEventActionParam', paramId);
        })
      ).then(() => {
        this.$store.dispatch('uiConstructor/loadEventActionParams', this.eventActionId);
      });
    },
    paramSaved(paramData) {
      this.$store.dispatch('uiConstructor/setSelectedEventActionParam', paramData);
      this.loadEventActionParams();
      this.paramFormVisible = false;
    },
    loadEventActionParams() {
      this.$store.dispatch('uiConstructor/loadEventActionParams', this.eventActionId);
    },
    getCopiedEventActionParams() {
      let copiedEventActionParamsStr = localStorage.getItem('copiedEventActionParams');
      if (!copiedEventActionParamsStr) {
        return null;
      }

      return JSON.parse(copiedEventActionParamsStr);
    },
    refreshPasteButtonOptions() {
      const eventActionParams = this.getCopiedEventActionParams();
      if (eventActionParams) {
        this.pasteButton.option('disabled', false);
      } else {
        this.pasteButton.option('disabled', true);
      }
    }
  }
};
</script>

<style lang="scss">
.action-name {
  margin-left: 17px;
}

.popup-action-form-content {
  display: flex;
  flex-direction: column;

  &__switch {
    margin-left: 80px;
  }

  .dx-switch {
    margin-bottom: 10px;
  }

  .dx-switch-container {
    height: 27px;
  }

  .dx-switch-handle {
    height: 23px;
  }

  .dx-switch-off,
  .dx-switch-on {
    margin: auto;
    font-size: 13px;
  }

  .dx-field {
    display: flex;
    flex: 0 0 auto;

    & > .dx-field-label {
      float: none;
      width: auto;
      flex: 0 0 80px;
    }

    & > .dx-field-value {
      float: none;
      width: auto;
      flex: 1 1 auto;

      & > .dx-checkbox {
        float: none;
      }
    }
  }

  &__icon {
    opacity: 0.5;
    margin-right: 5px;
  }

  &__params-grid {
    flex: 1 1 auto;
    margin-top: 5px;
    min-height: 0;
  }
}
</style>
