<template>
  <!--eslint-disable-->
  <DxTextBox
    v-model="searchString"
    mode="search"
    valueChangeEvent="input"
    placeholder="Поиск..."
    @input="search"
    class="search-box"
  />
</template>

<script>
import DxTextBox from 'devextreme-vue/text-box';

export default {
  name: 'SearchBox',
  components: {
    DxTextBox
  },
  props: {
    dataList: Array,
    searchExpr: Array
  },
  data() {
    return {
      searchString: '',
      timeoutId: null,
      dataListBySearch: null
    };
  },
  methods: {
    getSearchString() {
      return this.searchString;
    },
    search() {
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
        this.timeoutId = null;
      }

      this.timeoutId = setTimeout(() => {
        this.dataListBySearch = this.dataList.filter((item) => {
          return this.searchExpr.some((expr) => {
            const fields = expr.split('.');
            let targetField = item;
            fields.forEach((field) => {
              targetField = targetField[field];
            });
            return targetField.toLowerCase().includes(this.searchString.toLowerCase());
          });
        });
        this.$emit('searching', this.dataListBySearch);
      }, 1000);
    },
    cleanSearch() {
      this.searchString = '';
    }
  }
};
</script>
