import Vue from 'vue';
import Router from 'vue-router';
import store from '../store';

import constructor from './constructor';
import twoLevelPage from './twoLevelPage';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,

  routes: [...constructor, ...twoLevelPage]
});

/*
router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    ifAuthenticated(to, from, next);
  } else {
    next();
  }
});
*/

export function ifNotAuthenticated(to, from, next) {
  if (!store.getters['session/isAuthenticated']) {
    next();
    return;
  }
  next(to.query.redirectTo || '/');
}

export function ifAuthenticated(to, from, next) {
  if (store.getters['session/isAuthenticated']) {
    next();
    return;
  }
  next(`/login?redirectTo=${to.path}`);
}

export default router;
