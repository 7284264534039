import actions from './actions';
import getters from './getters';
import mutations from './mutations';

export default {
  namespaced: true,
  state: {
    /**Объект с Promise, которые при разрешении возвращают источники данных (экземпляры DataSet) */
    dataSets: {},
    /**Конфиги страниц */
    pages: {},

    /**Данные текущей страницы */
    place: {},

    /**УРЛ страницы */
    pageUrl: null,

    /**Выбранные компоненты */
    selectedComponents: [],

    changedComponents: [],
    addedComponents: [],
    deletedComponents: [],
    eventAction: {},

    /**Конфиг активного компонента в конструкторе, чьи свойства в данный момент редактируются
     * Данные каждый раз подтягиваются непосредственно с сервера, из CONFIG.DSCOMPONENTS
     */
    constructorActiveComponentConfig: null,

    //Новая структура

    //Внешние настройки (то что снаружи options)
    items: {},

    //Options для компонентов
    componentOptions: {},

    //Массив идентификаторов компонентов, которые догружены в процессе работы
    //Нужен, чтобы исключить догруженные компоненты из дерева конструктора
    //Чтобы не затереть родителя после дозагрузки компонента
    loadedItemIds: [],

    //Идет процесс сохранения
    savingProcess: false,

    /**Словарь слоев с индикацией загрузки, нужен чтобы при загрузке не срабатывал пересчет стилей mapbox */
    layersLoading: {},

    /**Словарь: ключ - идентификатор слоя, значение - идентификатор компонента-карточи слоя */
    layerCardComponentIds: {},

    /**Словарь: ключ - идентификатор слоя, значение - идентификатор компонента-таблицы слоя */
    layerTableComponentIds: {},

    /**Словарь: ключ - идентификатор слоя, значение - идентификатор компонента-панели статистики слоя */
    layerStatsComponentIds: {},

    /**Словарь: ключ - идентификатор слоя, значение - идентификатор компонента-фильтра слоя */
    layerFilterComponentIds: {},

    /**Словарь: ключ - идентификатор слоя, значение - идентификатор компонента-содержимое всплывающей подсказки слоя */
    layerTooltipComponentIds: {},

    configs: [],

    /**Словарь с customData компонентов */
    customData: {},

    /**Словарь словарей*/
    dictionaries: {},

    /**Словарь наблюдаемых событий
     * key - id компонента
     * value - словарь событий компонента
     *    key - имя события
     *    value - сет подписок
     */
    observers: {}
  },
  getters,
  mutations,
  actions
};
