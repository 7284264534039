import Page from '../components/Containers/Page';

export default [
  {
    path: '/:url',
    component: Page,
    meta: { requiresAuth: true },
    props: (route) => {
      return {
        url: route.params.url
      };
    },
    children: [
      {
        path: ':childUrl',
        props: (route) => {
          return {
            url: route.params.childUrl
          };
        },
        component: Page
      }
    ]
  }
];
