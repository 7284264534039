import { jsonRPC } from '@/api/api';
import { PLACE_DATASET } from '../../../utils/const';

export default {
  toggle(state, active) {
    state.commit('toggle', active);
  },
  toggleDragging(state, dragging) {
    state.commit('toggleDragging', dragging);
  },
  toggleSelectMode(state, selectMode) {
    state.commit('toggleSelectMode', selectMode);
  },
  toggleBlockSelectMode(state, blockSelectMode) {
    state.commit('toggleBlockSelectMode', blockSelectMode);
  },
  setSelectedTemplateId(context, templateId) {
    context.commit('setSelectedTemplateId', templateId);
  },
  loadComponentTypes(context) {
    loadDictionary(context, 'CONFIG.DSCOMPONENTTYPE', 'setComponentTypes');
  },
  loadTemplates(context) {
    loadDictionary(context, 'CONFIG.DSTEMPLATE', 'setTemplates');
  },
  loadTemplateFields(context) {
    loadDictionary(context, 'CONFIG.DSTEMPLATEFIELD', 'setTemplateFields');
  },
  loadConnections(context) {
    loadDictionary(context, 'CONFIG.DSCONNECTIONCODE', 'setConnections');
  },
  loadAllPages(context) {
    loadDictionary(context, PLACE_DATASET, 'setAllPages');
  },
  loadFilters({ commit }, { datasetName }) {
    if (!datasetName) {
      return Promise.resolve([]);
    }
    const params = {
      _config_dataset: 'CONFIG.DSCONFIG_DATASET_FILTER',
      dataset_code: datasetName
    };
    return jsonRPC('getData', params).then((data) => {
      commit('setFilters', data);
    });
  },
  loadDatasets(context, connectionCode) {
    const param = {
      connection_code: connectionCode,
      _config_dataset: 'CONFIG.DSDATASET'
    };

    return jsonRPC('getData', param)
      .then((data) => {
        context.commit('setDatasets', data);
      })
      .catch((err) => {
        window.console.log(err.message);
      });
  },
  loadComponentEventActions(context, componentId) {
    const param = {
      component_id: componentId,
      _config_dataset: 'CONFIG.DSEVENTACTION'
    };

    return jsonRPC('getData', param)
      .then((data) => {
        context.commit('setSelectedEventActions', data);
      })
      .catch((err) => {
        window.console.log(err.message);
      });
  },

  loadComponentEventTypes(context, componentId) {
    const componentType = context.rootGetters['config/getComponentType'](componentId);
    const componentTypes = context.rootGetters['uiConstructor/componentTypes'] || [];
    const componentTypeId = componentTypes.filter((item) => item.name === componentType)[0].id;
    const param = {
      componenttype_id: componentTypeId,
      _config_dataset: 'CONFIG.DSCOMPONENTTYPE_EVENTTYPE'
    };

    return jsonRPC('getData', param)
      .then((data) => {
        context.commit('setComponentEventTypes', data);
      })
      .catch((err) => {
        window.console.log(err.message);
      });
  },
  loadComponentEventActionsByEventType(context, { componentId, eventTypeId }) {
    const param = {
      component_id: componentId,
      eventtype_id: eventTypeId,
      _config_dataset: 'CONFIG.DSEVENTACTION'
    };

    return jsonRPC('getData', param)
      .then((data) => {
        context.commit('setSelectedEventTypeActions', data);
      })
      .catch((err) => {
        window.console.log(err.message);
      });
  },
  loadComponentActionTypes(context, componentTypeId) {
    const param = {
      componenttype_id: componentTypeId,
      _config_dataset: 'CONFIG.DSCOMPONENTTYPE_ACTIONTYPE'
    };

    return jsonRPC('getData', param)
      .then((data) => {
        context.commit('setComponentActionTypes', data);
      })
      .catch((err) => {
        window.console.log(err.message);
      });
  },
  clearComponentActionTypes(context) {
    context.commit('clearComponentActionTypes');
  },
  setSelectedEventType(context, eventTypeId) {
    context.commit('setSelectedEventType', eventTypeId);
  },
  setSelectedEventAction(context, eventActionData) {
    context.commit('setSelectedEventAction', eventActionData);
  },
  setSelectedEventActionField(context, { fieldName, fieldValue }) {
    context.commit('setSelectedEventActionField', { fieldName, fieldValue });
  },
  /**
   * Сохранение объекта eventAction в формате как он хранится в vuex store.constructor
   * С созданием действия, если оно не создано
   */
  async saveEventAction(context, eventAction) {
    const actionComponentId = eventAction.actionComponentId;
    const actionTypeId = eventAction.actionTypeId;

    //Загрузка действий компонента
    const componentActions = await loadComponentActions(actionComponentId);
    //Найдем действия компонента с выбранным типом
    const actionsWithType = componentActions.filter((action) => {
      return action.actiontype_id === actionTypeId;
    });

    let action;
    if (actionsWithType.length === 0) {
      //Если нет действий компонента с выбранным типом, то создадим действие
      action = (
        await createAction({
          component_id: actionComponentId,
          actiontype_id: actionTypeId
        })
      )[0];
    } else {
      //Иначе, выбираем первое найденное действие компонента с нужным типом
      action = actionsWithType[0];
    }

    //Сохранение события-действия
    const eventActionData = {
      id: eventAction.id,
      eventtype_id: eventAction.eventTypeId,
      component_id: eventAction.eventComponentId,
      action_id: action.id,
      parent_id: eventAction.parentId,
      disable: eventAction.disable
    };

    const method = eventActionData.id ? 'updateData' : 'insertData';
    return (await saveEventAction(eventActionData, method))[0];
  },
  /*
  Сохранение объекта eventActionData в формате как он приходит от сервера
  */
  async saveEventActionData(context, eventActionData) {
    return (
      await saveEventAction(
        {
          id: eventActionData.id,
          eventtype_id: eventActionData.eventtype_id,
          component_id: eventActionData.eventcomponent_id,
          action_id: eventActionData.action_id,
          parent_id: eventActionData.parent_id
        },
        'updateData'
      )
    )[0];
  },
  addEventAction(context, eventAction) {
    context.commit('addEventAction', eventAction);
  },
  deleteEventAction(context, eventActionId) {
    const param = {
      id: eventActionId,
      _config_dataset: 'CONFIG.DSEVENTACTION'
    };

    return jsonRPC('deleteData', param).then(() => {
      context.commit('deleteEventAction', eventActionId);
    });
  },
  loadEventActionParams(context, eventActionId) {
    if (eventActionId === null) {
      context.commit('setEventActionParams', []);
      return Promise.resolve([]);
    } else {
      const param = {
        eventaction_id: eventActionId,
        _config_dataset: 'CONFIG.DSPARAMETER'
      };

      return jsonRPC('getData', param).then((eventActionParams) => {
        context.commit('setEventActionParams', eventActionParams);
      });
    }
  },
  loadAllEventActionParams(context, eventActionIds) {
    if (!eventActionIds.length) {
      context.commit('setAllEventActionParams', []);
      return Promise.resolve([]);
    }
    const param = eventActionIds.map((id) => {
      return {
        eventaction_id: id,
        _config_dataset: 'CONFIG.DSPARAMETER'
      };
    });

    if (!param.length) return;

    return jsonRPC('getData', param).then((eventActionParams) => {
      // Обработаем полученный результат для более удобной дальнейшей работы с ним
      const actionParams = eventActionParams.map((param, index) => {
        return {
          id: eventActionIds[index],
          param
        };
      });

      context.commit('setAllEventActionParams', actionParams);
    });
  },
  loadEventActionParamTypes(context) {
    const param = {
      _config_dataset: 'CONFIG.DSDATATYPE'
    };

    return jsonRPC('getData', param).then((eventActionParamTypes) => {
      context.commit('setEventActionParamTypes', eventActionParamTypes);
    });
  },
  setSelectedEventActionParam(context, paramData) {
    context.commit('setSelectedEventActionParam', paramData);
  },
  setSelectedEventActionParamField(context, { fieldName, fieldValue }) {
    context.commit('setSelectedEventActionParamField', { fieldName, fieldValue });
  },
  async saveEventActionParam(context, eventActionParam) {
    const method = eventActionParam.id ? 'updateData' : 'insertData';
    const paramData = {
      id: eventActionParam.id,
      datatype_id: eventActionParam.typeId,
      eventaction_id: eventActionParam.eventActionId,
      component_id: eventActionParam.componentId,
      inputname: eventActionParam.inputName,
      outputname: eventActionParam.outputName,
      outputvalue: eventActionParam.outputValue,
      is_private: eventActionParam.isPrivate,
      is_array: eventActionParam.isArray
    };

    return (await saveEventActionParam(paramData, method))[0];
  },
  async copyEventActionParam(context, eventActionParam) {
    const method = eventActionParam.id ? 'updateData' : 'insertData';

    return (await saveEventActionParam(eventActionParam, method))[0];
  },
  deleteEventActionParam(context, eventActionParamId) {
    const param = {
      id: eventActionParamId,
      _config_dataset: 'CONFIG.DSPARAMETER'
    };

    return jsonRPC('deleteData', param);
  },
  copyEventAction(context, { eventActionId, componentId, eventTypeId }) {
    let param = {
      eventaction_id: eventActionId,
      component_id: componentId,
      eventtype_id: eventTypeId,
      _config_dataset: 'CONFIG.PRCOPYEVENTACTION'
    };

    return jsonRPC('getData', param);
  },
  setNewPageField(context, { fieldName, fieldValue }) {
    context.commit('setNewPageField', { fieldName, fieldValue });
  },
  setNewComponentField(context, { fieldName, fieldValue }) {
    context.commit('setNewComponentField', { fieldName, fieldValue });
  },
  setNewComponentTemplateField(context, { fieldName, fieldValue }) {
    context.commit('setNewComponentTemplateField', { fieldName, fieldValue });
  },
  saveComponentTemplate(context, { componentId, name, groupId }) {
    let param = {
      component_id: parseInt(componentId),
      caption: name,
      componenttemplategroup_id: groupId,
      _config_dataset: 'CONFIG.PRCOMPONENTTOTEMPLATE'
    };

    return jsonRPC('getData', param);
  },
  loadComponentTemplateGroups(context) {
    const param = {
      _config_dataset: 'CONFIG.DSCOMPONENTTEMPLATEGROUP'
    };

    return jsonRPC('getData', param).then((componentTemplateGroups) => {
      context.commit('setComponentTemplateGroups', componentTemplateGroups);
    });
  },
  loadComponentTemplates(context) {
    const param = {
      place_id: 569569, // <--- Это магическое число - идентификатор страницы-контейнера для компонентов-шаблонов
      _config_dataset: 'CONFIG.DSCOMPONENTSEARCH'
    };

    return jsonRPC('getData', param).then((componentTemplates) => {
      context.commit('setComponentTemplates', componentTemplates);
    });
  },
  deleteTemplate(context, templateId) {
    const param = {
      id: templateId,
      _config_dataset: 'CONFIG.DSCOMPONENTS'
    };

    return jsonRPC('deleteData', param);
  },
  setImportedSettingField(context, { fieldName, fieldValue }) {
    context.commit('setImportedSettingField', { fieldName, fieldValue });
  },
  setCopyComponentField(context, { fieldName, fieldValue }) {
    context.commit('setCopyComponentField', { fieldName, fieldValue });
  },
  async createCopyComponent(context, { componentId, url, parentComponentId, componentPos }) {
    let param = {
      component_id: componentId,
      url,
      parent_component_id: parentComponentId,
      pos: componentPos || null,
      _config_dataset: 'CONFIG.PRCLONECOMPONENT'
    };

    let result = await jsonRPC('getData', param);

    if (result[0] && result[0].r_component_id) {
      return result[0].r_component_id.toString();
    }

    return null;
  },
  async createNewPage(context, { name, parentId, url, icon, pos }) {
    if (url.indexOf('/') !== 0) {
      url = `/${url}`;
    }

    let param = {
      parent_id: parentId,
      pos,
      placestyle_id: 1,
      icon,
      url,
      name,
      prop: {},
      _config_dataset: PLACE_DATASET
    };

    let result = await jsonRPC('insertData', param);

    if (result[0] && result[0].url) {
      loadDictionary(context, PLACE_DATASET, 'setAllPages');
      return result[0].url.toString();
    }

    return null;
  },

  saveNewComponentType(context, { name, comments }) {
    const param = {
      name: name,
      comments: comments,
      _config_dataset: 'CONFIG.DSCOMPONENTTYPE'
    };

    return jsonRPC('insertData', param).then((data) => {
      if (Array.isArray(data)) {
        data.forEach((item) => {
          context.commit('addComponentType', item);
        });
      } else {
        context.commit('addComponentType', data);
      }
    });
  },

  loadGlobalComponentById({ state, commit }, { componentId }) {
    const param = {
      id: componentId,
      _config_dataset: 'CONFIG.DSCOMPONENTSEARCH'
    };
    return jsonRPC('getData', param).then((components) => {
      if (
        components[0] &&
        !state.loadedGlobalComponents.find((component) => {
          return component.componentId === components[0].id.toString();
        })
      ) {
        const globalComponent = {
          componentId: components[0].id.toString(),
          componentName: components[0].name,
          componentType: components[0].componenttype_name
        };
        commit('addLoadedGlobalComponent', globalComponent);
      }
    });
  },
  loadComponentAccessRoles(context, componentId) {
    const param = {
      component_id: componentId,
      _config_dataset: 'CONFIG.DSCOMPONENTROLES'
    };

    return jsonRPC('getData', param)
      .then((data) => {
        context.commit('setSelectedAccessRoles', data);
      })
      .catch((err) => {
        window.console.log(err.message);
      });
  }
};

function loadDictionary(context, dataSetName, mutationName) {
  const param = {
    _config_dataset: dataSetName
  };

  return jsonRPC('getData', param)
    .then((data) => {
      context.commit(mutationName, data);
    })
    .catch((err) => {
      window.console.log(err.message);
    });
}

async function loadComponentActions(componentId) {
  const param = {
    component_id: componentId,
    _config_dataset: 'CONFIG.DSACTION'
  };

  return await jsonRPC('getData', param);
}

async function createAction(param) {
  param._config_dataset = 'CONFIG.DSACTION';
  return await jsonRPC('insertData', param);
}

async function saveEventAction(eventActionData, method) {
  const param = {
    ...eventActionData,
    _config_dataset: 'CONFIG.DSEVENTACTION'
  };
  return await jsonRPC(method, param);
}

async function saveEventActionParam(paramData, method) {
  const param = {
    ...paramData,
    _config_dataset: 'CONFIG.DSPARAMETER'
  };
  return await jsonRPC(method, param);
}
