import { jsonRPC, exportData as exportDataApi } from '@/api/api';
import { callEventAction } from '../callEventAction';
//Шина глобальных событий
import EventBus, { GEOJSON_SAVED } from '@/eventBus';
import { SET_REFRESHING, SET_LOADED_DATA, SET_DATA_FIELDS, UPDATE_DATA_FIELD, DELETE_DATA_FIELD, SET_FILTERS, DELETE_FILTER, INSERT_FILTER } from './mutations';
import { getOptions, getDataFields, getFilters, getLoadedData } from './getters';
import { SET_OPTION } from '@/store/modules/config/components/mutations';
import { broadcast } from '@/store/modules/config/actions';

//Локальные названия действий
const PREFIX = 'DataSet';
export const getData = `${PREFIX}/getRequest`;
export const refreshData = `${PREFIX}/refreshData`;
export const resetData = `{${PREFIX}/resetData}`;
export const insertData = `${PREFIX}/insertData`;
export const updateData = `${PREFIX}/updateData`;
export const deleteData = `${PREFIX}/deleteData`;
export const saveData = `${PREFIX}/saveData`;
export const saveGeometry = `${PREFIX}/saveGeometry`;
export const loadDataFields = `${PREFIX}/loadDataFields`;
export const createBuffer = `${PREFIX}/createBuffer`;
export const createBufferZones = `${PREFIX}/createBufferZones`;
export const updateDataField = `${PREFIX}/updateDataField`;
export const deleteDataField = `${PREFIX}/deleteDataField`;
export const exportData = `${PREFIX}/exportData`;
export const loadBounds = `${PREFIX}/loadBounds`;
export const loadDataCount = `${PREFIX}/loadDataCount`;
export const loadMinMaxDataValues = `${PREFIX}/loadMinMaxDataValues`;
export const loadFilters = `${PREFIX}/loadFilters`;
export const loadFiltersById = `${PREFIX}/loadFiltersById`;
export const deleteFilter = `${PREFIX}/deleteFilter`;
export const refreshFilters = `${PREFIX}/refreshFilters`;
export const insertFilter = `${PREFIX}/insertFilter`;
export const getStatistics = `${PREFIX}/getStatistics`;
export const loadObjectLists = `${PREFIX}/loadObjectLists`;

const FEAUTURE_COLLECTION = 'FeatureCollection';

export default {
  /**Возвращает данные с сервера, данные в датасете не меняются */
  [getData]({ getters }, { componentId, abortController, ...param }) {
    const options = getters[getOptions](componentId);

    const requestParams = {
      ...options.defaultParam,
      _config_dataset: options.dataSetName,
      ...param
    };
    if (options.filterId) {
      requestParams._config_dataset_filter_id = options.filterId;
    }
    return jsonRPC('getData', requestParams, abortController || null);
  },

  /**Возвращает min and max  значения для шагов градиента*/
  [loadMinMaxDataValues]({ getters }, { dataSetComponentId, selector, ...params }) {
    const options = getters[getOptions](dataSetComponentId);

    //Если датасет не указан или не найден
    if (!options.dataSetName) {
      return [];
    }

    const requestParams = {
      ...options.defaultParam,
      totalSummary: [
        {
          selector,
          summaryType: 'min'
        },
        {
          selector,
          summaryType: 'max'
        }
      ],
      ...params,
      _config_dataset: options.dataSetName,
      _config_is_count: true,
      _config_serverside: true
    };

    return jsonRPC('getData', requestParams).then((result) => {
      if (!result) {
        return {};
      }

      return result;
    });
  },

  /**Возвращает количество объектов по запросу, данные в датасете не меняются */
  [loadDataCount]({ getters }, { componentId, ...param }) {
    const options = getters[getOptions](componentId);

    //Если датасет не указан или не найден
    if (!options.dataSetName) {
      return [];
    }

    const requestParams = {
      ...options.defaultParam,
      ...param,
      _config_dataset: options.dataSetName,
      _config_is_count: true
    };
    if (options.filterId) {
      requestParams._config_dataset_filter_id = options.filterId;
    }

    return jsonRPC('getData', requestParams).then((result) => {
      if (!result) {
        return null;
      }

      if (Array.isArray(result)) {
        result = result[0] || {};
      }

      const totalCount = result.totalCount || result.totalcount;
      if (typeof totalCount !== 'number') {
        const count = result.count;
        if (count || count === 0) {
          return +count;
        }
        return null;
      }

      return totalCount;
    });
  },

  /**Обновление данных */
  [refreshData]({ commit, getters, dispatch }, { componentId, ...param }) {
    const options = getters[getOptions](componentId);

    //Событие начала обновления данных
    dispatch(broadcast, { componentId, eventType: 'refreshing' });
    commit(SET_REFRESHING, { componentId, isRefreshing: true });

    const requestParams = {
      ...options.defaultParam,
      _config_dataset: options.dataSetName,
      ...param
    };
    if (options.filterId) {
      requestParams._config_dataset_filter_id = options.filterId;
    }

    if (options.refreshAbortController) {
      options.refreshAbortController.abort();
    }
    commit(SET_OPTION, {
      componentId,
      optionName: 'refreshAbortController',
      optionValue: new AbortController()
    });

    return jsonRPC('getData', requestParams, options.refreshAbortController)
      .then((data) => {
        let loadedData;
        //Преобразование данных с помощью функции options.customizeData
        data = customizeData(data, options.customizeData);
        if (Array.isArray(data)) {
          //Преобразования данных с помощью настройки options.customizeDataItem
          loadedData = sortDataItem(customizeDataItem(data, options.customizeDataItem), options.sortFn);
        } else {
          //Возможно придет geoJson-объект
          loadedData = data || {
            features: [],
            type: 'FeatureCollection'
          };
        }
        commit(SET_LOADED_DATA, { componentId, loadedData });

        dispatch(broadcast, { componentId, eventType: 'refreshed', eventData: loadedData });

        //Костыль для обработки GeoJson
        let firstItem = loadedData[0] || {};
        if (loadedData.type === FEAUTURE_COLLECTION) {
          firstItem = loadedData.features[0] || {};
        }

        return {
          data: loadedData,
          firstItem,
          fromSaveAction: false
        };
      })
      .catch((err) => {
        dispatch(broadcast, { componentId, eventType: 'error', eventData: err });
      })
      .finally(() => {
        //Сброс состояния
        commit(SET_REFRESHING, { componentId, isRefreshing: false });
      });
  },

  /**Сброс данных */
  [resetData]({ commit, dispatch }, { componentId }) {
    const loadedData = [];
    dispatch(broadcast, { componentId, eventType: 'refreshed', eventData: loadedData });
    commit(SET_LOADED_DATA, { componentId, loadedData });

    return Promise.resolve({
      data: loadedData
    });
  },

  /**Добавление данных */
  [insertData](context, { componentId, param }) {
    const { getters, dispatch } = context;
    const options = getters[getOptions](componentId);

    const requestParam = {
      ...options.defaultParam,
      _config_dataset: options.dataSetName,
      ...param
    };
    return jsonRPC('insertData', requestParam)
      .then((data) => {
        dispatch(broadcast, { componentId, eventType: 'inserted', eventData: data });
        return data;
      })
      .catch((error) => {
        callEventAction(context, {
          componentId,
          eventName: 'onError',
          eventData: error
        });
      });
  },

  /**Модификация данных */
  [updateData](context, { componentId, param }) {
    const { getters } = context;
    const options = getters[getOptions](componentId);

    const requestParam = {
      ...options.defaultParam,
      _config_dataset: options.dataSetName,
      ...param
    };
    return jsonRPC('updateData', requestParam)
      .then((data) => {
        dispatch(broadcast, { componentId, eventType: 'updated', eventData: data });
        return data;
      })
      .catch((error) => {
        callEventAction(context, {
          componentId,
          eventName: 'onError',
          eventData: error
        });
      });
  },

  /**Удаление данных */
  [deleteData](context, { componentId, row_data, convert_to_array, ...param }) {
    const { getters, dispatch } = context;
    const options = getters[getOptions](componentId);

    let dataForDelete;
    if (row_data) {
      dataForDelete = row_data;

      if (!Array.isArray(dataForDelete) && convert_to_array) {
        dataForDelete = [dataForDelete];
      }

      if (Array.isArray(dataForDelete)) {
        dataForDelete = dataForDelete.map((rowDataItem) => {
          return {
            ...rowDataItem,
            _config_dataset: options.dataSetName,
            ...param
          };
        });
      }
    } else if (Array.isArray(param.id)) {
      // Для пакетного запроса
      dataForDelete = param.id.map((id) => {
        return {
          id: id,
          _config_dataset: options.dataSetName
        };
      });
    } else {
      dataForDelete = param;
      if (convert_to_array) {
        dataForDelete = [dataForDelete];
      }
    }

    if (!Array.isArray(dataForDelete)) {
      dataForDelete._config_dataset = options.dataSetName;
    }

    return jsonRPC('deleteData', dataForDelete)
      .then((data) => {
        dispatch(broadcast, { componentId, eventType: 'deleted', eventData: data });
        callEventAction(context, {
          componentId,
          eventName: 'onAfterDelete',
          eventData: data
        });
        return data;
      })
      .catch((error) => {
        callEventAction(context, {
          componentId,
          eventName: 'onError',
          eventData: error
        });
      });
  },

  /**Добавление и модификация данных */
  [saveData](context, { componentId, row_data, is_new, param_filter, convert_to_array, isBatch = false, ...param }) {
    const { getters, dispatch } = context;
    const options = getters[getOptions](componentId);

    let methodName;
    let eventType;
    let post_row_data = row_data;

    if (is_new) {
      methodName = 'insertData';
      eventType = 'inserted';
      //для новых записей удаляем ИД
      if (row_data) {
        post_row_data = JSON.parse(JSON.stringify(row_data));
        if (Array.isArray(post_row_data)) {
          post_row_data.forEach((row) => {
            delete row.id;
            row.geojson && delete row.geojson.id;
            row.geojson?.properties && delete row.geojson.properties.id;
          });
        } else {
          delete post_row_data.id;
          post_row_data.geojson && delete post_row_data.geojson.id;
          post_row_data.geojson?.properties && delete post_row_data.geojson.properties.id;
        }
      }
    } else {
      methodName = 'updateData';
      eventType = 'updated';
    }

    let dataForSave;
    if (post_row_data) {
      dataForSave = post_row_data;

      if (!Array.isArray(dataForSave) && convert_to_array) {
        dataForSave = [dataForSave];
      }

      if (Array.isArray(dataForSave)) {
        dataForSave = dataForSave.map((rowDataItem) => {
          return {
            ...filterDataObj(rowDataItem, param_filter),
            _config_dataset: options.dataSetName,
            ...param
          };
        });
      } else {
        dataForSave = filterDataObj(dataForSave, param_filter);
      }
    } else {
      dataForSave = param;
      if (convert_to_array) {
        dataForSave = [dataForSave];
      }
    }

    if (!Array.isArray(dataForSave) && !isBatch) {
      dataForSave._config_dataset = options.dataSetName;
    }

    if (isBatch) {
      dataForSave = Object.keys(dataForSave).map((key) => dataForSave[key]);
    }

    return jsonRPC(methodName, dataForSave)
      .then((data) => {
        dispatch(broadcast, { componentId, eventType, eventData: data });
        return data;
      })
      .then((data) => {
        let emitingData = row_data || [param];
        emitingData.forEach((item, index) => {
          //Костыль сохранения GeoJson. Генерируется глобальное событие
          if (item.geojson && item.geojson.id && data[index] && data[index].id) {
            EventBus.$emit(`${GEOJSON_SAVED}.${item.geojson.id}`, data[index]);
          }
        });

        //Передадим в событие только данные объекта
        let geoJsonProperties = null;
        if (data[0] && data[0].features) {
          geoJsonProperties = data[0].features.map((feature) => feature.properties);
        }

        callEventAction(context, {
          componentId,
          eventName: 'onAfterSave',
          eventData: geoJsonProperties || data
        });
        //После сохранения должен вызываться метод "после обновления данных"
        //Костыль для обработки GeoJson
        const loadedData = getters[getLoadedData](componentId) || [];
        let firstItem = loadedData[0] || {};
        if (loadedData.type === FEAUTURE_COLLECTION) {
          firstItem = loadedData.features[0] || {};
        }
        callEventAction(context, {
          componentId,
          eventName: 'onAfterDataRefreshed',
          eventData: {
            data: loadedData,
            firstItem,
            fromSaveAction: true
          }
        });

        return data;
      })
      .catch((error) => {
        callEventAction(context, {
          componentId,
          eventName: 'onError',
          eventData: error
        });
        //Передать ошибку дальше, чтобы не было воспринято как успешный результат
        throw error;
      });
  },

  /**Сохранение геометрии для объекта */
  [saveGeometry]({ dispatch, getters }, { componentId, properties, feature, isBatch = false }) {
    const options = getters[getOptions](componentId);
    const keyExpr = options.keyExpr;
    //Для пакетного запроса
    if (Array.isArray(feature)) {
      let params = [];
      feature.forEach((item) => {
        params.push({
          componentId,
          is_new: false,
          _config_is_geojson: true,
          ...item.properties,
          geojson: item,
          _config_dataset: options.dataSetName
        });
      });

      //Сохраняем новую геометрию со старыми свойствами
      return dispatch(saveData, {
        componentId,
        isBatch,
        ...params
      });
    }

    properties = properties ? properties : feature.properties;
    window.console.warn(`Используйте DataSetGeoJson для работы с geoJson в компоненте ${properties.layerComponentId}`);
    //Сначала запрашиваем свойства объекта
    const key = properties[keyExpr] || feature[keyExpr];
    if (properties.the_geom) {
      delete properties.the_geom;
    }

    return dispatch(getData, { componentId, [keyExpr]: key }).then((propertiesData) => {
      if (propertiesData[0] && propertiesData[0].the_geom) {
        delete propertiesData[0].the_geom;
      }

      //Сохраняем новую геометрию со старыми свойствами
      return dispatch(saveData, {
        componentId,
        is_new: false,
        _config_is_geojson: true,
        ...propertiesData[0],
        geojson: feature
      });
    });
  },

  /**
   * Запрашивает и возвращает массив полей датасета
   * @param {string} componentId - id компонента
   * @param {boolean} refresh - флаг о необходимости перезапросить список
   */
  [loadDataFields]({ commit, getters }, { componentId, additionalParams = {}, refresh = false, setFields = true }) {
    const options = getters[getOptions](componentId);
    const dataFields = getters[getDataFields](componentId);
    if (!refresh && dataFields.length > 0) {
      return Promise.resolve(dataFields);
    } else {
      if (!options.dataSetName) {
        return Promise.resolve([]);
      }
      const params = {
        _config_dataset: 'CONNECTIONS.DSDATASET_FIELD',
        dataset_code: options.dataSetName,
        ...additionalParams
      };

      return jsonRPC('getData', params).then((response) => {
        if (setFields) {
          commit(SET_DATA_FIELDS, { componentId, dataFields: response });
        }
        return response;
      });
    }
  },

  /**Обновляет массив полей датасета */
  [updateDataField]({ commit, getters }, { componentId, fields }) {
    const options = getters[getOptions](componentId);

    const requestParams = fields.map((item) => {
      return { ...item, _config_dataset: 'CONNECTIONS.DSDATASET_FIELD', dataset_code: options.dataSetName };
    });

    return jsonRPC('updateData', requestParams).then((response) => {
      commit(UPDATE_DATA_FIELD, { componentId, fields });
      return response;
    });
  },

  /**Удаляет массив полей данных*/
  [deleteDataField]({ commit, getters }, { componentId, fieldIds }) {
    const options = getters[getOptions](componentId);

    const requestParams = fieldIds.map((id) => {
      return {
        _config_dataset: 'CONNECTIONS.DSDATASET_FIELD',
        dataset_code: options.dataSetName,
        id
      };
    });

    return jsonRPC('deleteData', requestParams).then((response) => {
      const dataField = response ? response[0] || null : null;
      commit(DELETE_DATA_FIELD, { componentId, fieldIds });
      return dataField;
    });
  },

  /**Экспорт данных */
  [exportData]({ getters }, { componentId, url, format, ids, where, filterId, exportUserId, epsg, layer_name, ...params }) {
    const options = getters[getOptions](componentId);
    const userData = getters['session/userData'] || {};
    const userId = exportUserId || userData.userId || null;
    const requestParams = {
      _config_user_id: userId,
      _config_filetype: format,
      _config_filter_id: filterId,
      _config_dataset: options.dataSetName,
      layer_name: layer_name || null,
      epsg,
      where,
      ...params
    };
    if (Array.isArray(ids)) {
      requestParams.ids = ids;
    }

    return exportDataApi(url, options.dataSetName, requestParams);
  },

  /**Создание буферной зоны */
  [createBufferZones]({ getters, dispatch }, { layerComponentId, parentComponentId, bufferZoneValues, additionalParams, datasetName = null }) {
    const options = getters[getOptions](layerComponentId);

    const bufferStyle = {
      side: !bufferZoneValues.sideValue ? 'both' : bufferZoneValues.zoneValue > 0 ? 'left' : 'right',
      join: bufferZoneValues.roundedValue ? 'round' : 'mitre',
      endcap: bufferZoneValues.endcapValue || bufferZoneValues.roundedValue ? 'round' : 'square'
    };

    const requestParams = {
      _config_dataset: 'BPM.PRPLAYBPMN',
      radius_of_buffer: Math.abs(bufferZoneValues.zoneValue),
      buffer_style_parameters: Object.entries(bufferStyle)
        .map(([key, value]) => `${key}=${value}`)
        .join(' '),
      layer_component_id: layerComponentId,
      parent_id: parentComponentId,
      dataset_layer: datasetName,
      bpmn_id: 837,
      is_union: bufferZoneValues.unionValue || false,
      ...(additionalParams || {})
    };

    if (bufferZoneValues.mode === 'selected') {
      requestParams.ids = options.selectedIds;
    }

    return jsonRPC('getData', requestParams);
  },

  /**
   * Получение экстента (bounds) слоя.
   * inputParams - {where: []}
   */
  [loadBounds]({ getters }, { componentId, param }) {
    const options = getters[getOptions](componentId);

    //Из requestParams убраны  ...options.defaultParam
    const requestParams = {
      _config_dataset: 'CONFIG.DSGETBOUND',
      connection_dataset_code: options.dataSetName,
      ...param
    };
    return jsonRPC('getData', requestParams).then((data) => {
      return data && data[0] ? data[0].bounds || null : null;
    });
  },

  /**Запрашивает и возвращает массив фильтров датасета */
  [loadFilters]({ getters, dispatch }, { componentId }) {
    const filters = getters[getFilters](componentId);

    if (filters.length > 0) {
      return Promise.resolve(filters);
    } else {
      return dispatch(refreshFilters, { componentId });
    }
  },

  /**Запрашивает и возвращает по ID фильтр датасета */
  [loadFiltersById](context, { componentId, filterId }) {
    const filters = context.getters[getFilters](componentId);

    if (filters.length > 0) {
      return filters.find((item) => item.id === filterId);
    } else {
      const options = context.getters[getOptions](componentId);
      const userData = context.rootGetters['session/userData'] || {};
      const userId = userData.userId || process.env.VUE_APP_USER_ID || null;

      const params = {
        _config_dataset: 'CONFIG.DSFILTER',
        _config_user_id: userId,
        dataset: options.dataSetName,
        id: filterId
      };

      if (options.dataSetName) {
        return jsonRPC('getData', params).then((response) => {
          context.commit(SET_FILTERS, { componentId: componentId, filters: response[0] });
          return response[0];
        });
      } else {
        return Promise.resolve(null);
      }
    }
  },

  /**Удаляет элемент из массива фильтров датасета */
  [deleteFilter]({ commit, getters }, { componentId, filterId }) {
    const options = getters[getOptions](componentId);
    const userData = getters['session/userData'] || {};
    const userId = userData.userId || process.env.VUE_APP_USER_ID || null;
    const params = {
      _config_user_id: userId,
      id: filterId,
      dataset: options.dataSetName,
      _config_dataset: 'CONFIG.DSFILTER'
    };

    return jsonRPC('deleteData', params).then((response) => {
      commit(DELETE_FILTER, { componentId, filterId: response[0].id });
    });
  },

  /**Вставляет новый фильтр в список */
  [insertFilter]({ commit, getters }, { componentId, ...params }) {
    const options = getters[getOptions](componentId);
    const requestParams = {
      _config_dataset: 'CONFIG.DSFILTER',
      dataset: options.dataSetName,
      ...params
    };

    return jsonRPC('insertData', requestParams).then((data) => {
      commit(INSERT_FILTER, { componentId, filter: data[0] });
    });
  },

  /**Обновление списка фильтров */
  [refreshFilters]({ commit, getters, rootGetters }, { componentId }) {
    const options = getters[getOptions](componentId);
    if (!options.dataSetName) {
      return Promise.resolve([]);
    }

    const userData = rootGetters['session/userData'] || {};
    const userId = userData.userId || process.env.VUE_APP_USER_ID || null;
    const params = {
      _config_dataset: 'CONFIG.DSFILTER',
      _config_user_id: userId,
      dataset: options.dataSetName
    };

    return jsonRPC('getData', params).then((response) => {
      commit(SET_FILTERS, { componentId, filters: response });
      return response;
    });
  },

  /**Построение буферной зоны*/
  [createBuffer]({ getters }, { componentId, param, datasetName }) {
    let _config_dataset = datasetName;
    const options = getters[getOptions](componentId);

    if (!_config_dataset) {
      const DATA_SET_NAME = 'PRBUFFERCREATE';
      const base = options.dataSetName.substring(0, options.dataSetName.indexOf('.'));
      _config_dataset = `${base}.${DATA_SET_NAME}`;
    }

    const requestParams = {
      _config_dataset,
      dataset_layer: options.dataSetName,
      ...param
    };
    return jsonRPC('getData', requestParams);
  },

  /**
   * Запрашивает и возвращает массив полей датасета
   * @param {string} componentId - id компонента
   * @param {boolean} refresh - флаг о необходимости перезапросить список
   */
  [getStatistics]({ getters }, { componentId, where, statisticsRequest }) {
    const options = getters[getOptions](componentId);
    const params = {
      _config_dataset: options.dataSetName,
      _config_is_count: true,
      where,
      totalSummary: statisticsRequest
    };

    return jsonRPC('getData', params).then((response) => {
      return response;
    });
  },

  /**
   * Загрузить списки объектов, доступные для датасета
   * @param {*} param0
   * @param {*} param1
   * @returns
   */
  [loadObjectLists]({ commit, getters }, { componentId, param }) {
    const options = getters[getOptions](componentId);

    const params = {
      _config_dataset: 'BASE.DSLIST',
      connection_dataset_code: options.dataSetName,
      ...param
    };

    return jsonRPC('getData', params);
  }
};

function customizeData(data = [], customizeDataFn = null) {
  if (typeof customizeDataFn === 'function') {
    try {
      return customizeDataFn(data);
    } catch (err) {
      return data;
    }
  } else {
    return data;
  }
}

function customizeDataItem(data = [], customizeDataItemFn = null) {
  if (typeof customizeDataItemFn === 'function') {
    return data.map((dataItem) => {
      try {
        return customizeDataItemFn(dataItem);
      } catch (err) {
        return dataItem;
      }
    });
  } else {
    return data;
  }
}

function sortDataItem(data = [], sortFn = null) {
  if (typeof sortFn === 'function') {
    return data.sort((dataItem1, dataItem2) => {
      try {
        return sortFn(dataItem1, dataItem2);
      } catch (err) {
        return 0;
      }
    });
  } else {
    return data;
  }
}

function filterDataObj(dataObj, filter) {
  let result = {};

  if (!filter) {
    return dataObj;
  }

  filter.forEach((filterItem) => {
    if (typeof filterItem === 'string') {
      if (dataObj[filterItem] !== undefined) {
        result[filterItem] = dataObj[filterItem];
      }
    } else if (typeof filterItem === 'object' && filterItem.inputName !== undefined && filterItem.outputName !== undefined) {
      if (dataObj[filterItem.inputName] !== undefined) {
        result[filterItem.outputName] = dataObj[filterItem.inputName];
      }
    }
  });

  return result;
}
