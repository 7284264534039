<template>
  <!--eslint-disable-->
  <DxPopup
    width="50vw"
    maxHeight="80vh"
    height="auto"
    :animation="null"
    :visible="visible"
    :shading="false"
    :resizeEnabled="false"
    :title="title"
    :minWidth="600"
    :toolbarItems="toolbarItems"
    @shown="openPopup"
  >
    <template #titleTemplate>
      <PopupTitleTemplate
        :text="title"
        :showCloseButton="true"
        @closeClick="$emit('hidden')"
      />
    </template>
    <template #contentTemplate>
      <DxScrollView>
        <div class="popup-action-param-form-content">
          <div class="dx-field">
            <div class="dx-field-label">Тип параметра</div>
            <div class="dx-field-value">
              <DxSelectBox
                v-model="typeId"
                displayExpr="name"
                placeholder="Выбрать..."
                valueExpr="id"
                :dataSource="eventActionParamTypes"
                :showClearButton="false"
                :hint="typeDescriptions"
              />
            </div>
          </div>
          <div
            class="dx-field"
            v-show="inputNameVisible"
          >
            <div class="dx-field-label">Входящее название</div>
            <div class="dx-field-value">
              <DxTextBox v-model="inputName" />
            </div>
          </div>
          <div
            class="dx-field"
            v-show="outputNameVisible"
          >
            <div class="dx-field-label">Исходящее название</div>
            <div class="dx-field-value">
              <DxTextBox
                v-model="outputName"
                :buttons="outputNameButton"
              />
            </div>
          </div>
          <div
            class="dx-field"
            v-show="outputValueVisible"
          >
            <div class="dx-field-label">Исходящее значение</div>
            <div class="dx-field-value">
              <div
                v-if="monacoEditorVisible"
                class="monaco-edito-wrapper"
              >
                <MonacoEditor
                  :options="monacoEditorOptions"
                  ref="monacoEditor"
                  v-model="outputValue"
                />
              </div>
              <div
                v-else-if="filterBuilderVisible"
                class="filter-builder-wrapper"
              >
                <DxFilterBuilder
                  :fields="sortedFields"
                  :value="filterBuilderValue"
                  width="100%"
                  @valueChanged="filterChange"
                >
                  <DxCustomOperation
                    name="hasAny"
                    caption="hasAny"
                    icon="check"
                  />
                  <DxCustomOperation
                    name="in"
                    caption="in"
                    icon="check"
                  />
                  <template #lookupEditor="{ data }">
                    <DxLookup
                      :value="data.value"
                      :width="265"
                      v-bind="data.field.editorOptions"
                    />
                  </template>
                </DxFilterBuilder>
              </div>
              <DxTextBox
                v-else
                v-model="outputValue"
              />
            </div>
          </div>
          <div
            class="dx-field"
            v-show="paramTypesWithComponent"
          >
            <div class="dx-field-label">Компонент</div>
            <div class="dx-field-value">
              <SearchComponentForm
                v-model="componentId"
                :places="places"
                :selectionPlace="selectionPlace"
                :dropDownDataSource="dropDownComponents"
                @changePlace="onChangePlace"
                @treeViewItemSelectionChanged="handleComponentClick"
                @selectionChanged="paramComponentChanged"
              />
            </div>
          </div>
          <div class="dx-field">
            <div class="dx-field-label">Приватный параметр</div>
            <div class="dx-field-value">
              <DxCheckBox v-model:value="isPrivate" />
            </div>
          </div>
          <div class="dx-field">
            <div class="dx-field-label">Массив</div>
            <div class="dx-field-value">
              <DxCheckBox v-model:value="isArray" />
            </div>
          </div>
        </div>
      </DxScrollView>
    </template>
  </DxPopup>
</template>

<script>
import DxPopup from 'devextreme-vue/popup';
import PopupTitleTemplate from '../Containers/PopupTitleTemplate';
import DxSelectBox from 'devextreme-vue/select-box';
import DxScrollView from 'devextreme-vue/scroll-view';
import DxTextBox from 'devextreme-vue/text-box';
import DxFilterBuilder, { DxCustomOperation } from 'devextreme-vue/filter-builder';
import DxLookup from 'devextreme-vue/lookup';
import DxCheckBox from 'devextreme-vue/check-box';
import MonacoEditor from '../MonacoEditorBase';
import SearchComponentForm from './SearchComponentForm';
import { deepCopy } from '../../utils/deep';
import { sortFilterFieldsAlphabetically } from '@/utils/common';
import { PARAM_TYPES_WITH_COMPONENT } from '@/utils/const';
import PARAM_TYPES_DESCRIPTIONS from './paramTypesDescription';

const IDS_TYPES_PARAM = {
  inputData: 1,
  selectedData: 2,
  portletData: 3,
  fixedData: 4,
  eventData: 5,
  componentData: 6,
  globalData: 7,
  focusedData: 8,
  componentDataCount: 11,
  configData: 12,
  componentConfig: 13,
  customData: 14,
  selectedItems: 15,
  expression: 16,
  filterBuilder: 17,
  checkedData: 18,
  cookieData: 19,
  geolocation: 20
};
const FILTER_OPERATIONS = [
  '=',
  '<>',
  '<',
  '<=',
  '>',
  '>=',
  'contains',
  'endswith',
  'isblank',
  'isnotblank',
  'notcontains',
  'startswith',
  'between',
  'hasAny',
  'in'
];
const FIELDS = ['typeId', 'inputName', 'outputName', 'outputValue', 'componentId', 'isPrivate', 'isArray'];
const mapFields = (fields) => {
  let result = {};
  fields.forEach((fieldName) => {
    result[fieldName] = {
      get() {
        return this.getField(fieldName);
      },
      set(value) {
        this.dispatchField(fieldName, value);
      }
    };
  });
  return result;
};

export default {
  components: {
    DxPopup,
    DxSelectBox,
    DxScrollView,
    DxTextBox,
    DxFilterBuilder,
    DxCustomOperation,
    DxLookup,
    DxCheckBox,
    MonacoEditor,
    SearchComponentForm,
    PopupTitleTemplate
  },
  data() {
    return {
      places: {
        onPage: 'Компонент на странице',
        global: 'Компонент глобальный'
      },
      selectionPlace: 'Компонент на странице',
      monacoEditorOptions: {
        automaticLayout: true,
        language: 'javascript',
        minimap: {
          enabled: false
        }
      },
      toolbarItems: [
        {
          toolbar: 'bottom',
          location: 'center',
          widget: 'dxButton',
          options: {
            text: 'Сохранить',
            type: 'success',
            onClick: () => {
              this.save();
            }
          }
        },
        {
          toolbar: 'bottom',
          location: 'center',
          widget: 'dxButton',
          options: {
            text: 'Отменить',
            onClick: () => {
              this.$emit('hidden');
            }
          }
        }
      ],
      outputNameButton: [
        {
          name: 'UseAllParams',
          location: 'after',
          options: {
            text: 'Использовать все поля',
            onClick: () => {
              this.outputName = '_config_all';
            }
          }
        }
      ],
      filterBuilderValue: [],
      //Массив фиктивных полей на случай переименования/удаление параметров, присутствующих в fields в значении filterBuilder
      additionalFields: [],
      //Значение filterBuilder изменено пользователем - true, пришло извне - false
      widgetValueChange: false
    };
  },
  props: {
    visible: Boolean,
    title: {
      type: String,
      default: ''
    },
    params: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  computed: {
    pageComponents() {
      return this.$store.getters['config/getComponentsList'];
    },

    globalComponents() {
      return this.$store.getters['uiConstructor/loadedGlobalComponents'];
    },
    dropDownComponents() {
      return [...this.pageComponents, ...this.globalComponents];
    },
    eventActionParamTypes() {
      window;
      return this.$store.state.uiConstructor.eventActionParamTypes;
    },
    ...mapFields(FIELDS),
    inputNameVisible() {
      const {
        inputData,
        selectedData,
        portletData,
        eventData,
        componentData,
        globalData,
        focusedData,
        configData,
        componentConfig,
        customData,
        selectedItems,
        cookieData,
        geolocation
      } = IDS_TYPES_PARAM;

      return [
        inputData,
        selectedData,
        portletData,
        eventData,
        componentData,
        globalData,
        focusedData,
        configData,
        componentConfig,
        customData,
        selectedItems,
        cookieData,
        geolocation
      ].includes(this.typeId);
    },
    outputNameVisible() {
      return this.typeId !== null;
    },
    outputValueVisible() {
      const { fixedData, expression, filterBuilder } = IDS_TYPES_PARAM;

      return [fixedData, expression, filterBuilder].includes(this.typeId);
    },
    monacoEditorVisible() {
      const { expression } = IDS_TYPES_PARAM;

      return this.typeId === expression || this.outputName === '_config_condition';
    },
    filterBuilderVisible() {
      const { filterBuilder } = IDS_TYPES_PARAM;

      return this.typeId === filterBuilder;
    },
    paramTypesWithComponent() {
      return PARAM_TYPES_WITH_COMPONENT.map((paramType) => IDS_TYPES_PARAM[paramType]).includes(this.typeId);
    },
    typeDescriptions() {
      const { name: eventActionName } = this.eventActionParamTypes.find((eventActions) => eventActions.id === this.typeId) || {};

      //Если для параметра нужен компонет, то ищем описание параметра в соответствии с выбранным компонентом
      if (this.paramTypesWithComponent) {
        if (this.componentId === null) return '';

        const { componentType: currentComponentType } = this.$store.getters['config/getItem'](this.componentId) || {};
        const { descriptions } = PARAM_TYPES_DESCRIPTIONS[eventActionName].find(({ componentType }) => componentType === currentComponentType) || {};

        // Не для всех типов компонентов есть описание
        return descriptions ?? '';
      }

      return PARAM_TYPES_DESCRIPTIONS[eventActionName] ?? '';
    },
    selectedEventActionParam() {
      return this.$store.state.uiConstructor.selectedEventActionParam;
    },
    sortedFields() {
      let filterFields = this.params.map((param) => {
        return {
          caption: param,
          dataField: param,
          filterOperations: FILTER_OPERATIONS,
          lookup: { dataSource: this.lookupDataSource }
        };
      });

      if (this.additionalFields.length) {
        filterFields = filterFields.concat(this.additionalFields);
      }
      return sortFilterFieldsAlphabetically(filterFields);
    },
    lookupDataSource() {
      return this.params.map((param) => `@${param}`);
    }
  },
  watch: {
    outputValue(value) {
      const { filterBuilder } = IDS_TYPES_PARAM;

      if (this.typeId === filterBuilder) {
        try {
          if (!this.widgetValueChange) {
            //Значение получено извне (при открытии окна редактирования параметра)
            const valueArray = JSON.parse(value.replace(/\\\"/g, '"'));
            this.additionalFields = [];
            //проверяем не было ли изменнений исходящих названий параметров
            //При несовпадении fields, составленных из текущих исходящих названий параметров с fields из сохраненного outputValue возникнет ошибка компонента
            //В таком случае в fields добавляем фиктивные поля с заголовком: <имя_параметра> - параметр удален!
            let fields = new Set();
            getFilterBuilderValueFields(valueArray, fields);

            Array.from(fields).forEach((field) => {
              if (!this.params.includes(field)) {
                this.additionalFields.push({
                  caption: `${field} - параметр удален!`,
                  dataField: field,
                  filterOperations: FILTER_OPERATIONS,
                  lookup: { dataSource: this.lookupDataSource }
                });
              }
            });
            this.filterBuilderValue = valueArray;
          } else {
            this.widgetValueChange = false;
          }
        } catch {
          this.additionalFields = [];
          this.filterBuilderValue = [];
          this.widgetValueChange = false;
        }
      } else if (value === null) {
        this.filterBuilderValue = [];
        this.additionalFields = [];
      }
    }
  },
  methods: {
    getField(fieldName) {
      return this.selectedEventActionParam[fieldName];
    },
    dispatchField(fieldName, value) {
      this.$store.dispatch('uiConstructor/setSelectedEventActionParamField', {
        fieldName: fieldName,
        fieldValue: value
      });
    },
    save() {
      this.$store.dispatch('uiConstructor/saveEventActionParam', this.selectedEventActionParam).then((paramData) => {
        this.$emit('saved', paramData);
      });
    },
    handleComponentClick(eventData) {
      this.dispatchField('componentId', +eventData.itemData.componentId);
    },
    loadGlobalComponentById(componentId) {
      this.$store.dispatch('uiConstructor/loadGlobalComponentById', {
        componentId
      });
    },
    openPopup() {
      if (this.componentId !== null) {
        const currentComponent = this.$store.getters['config/getItem'](this.componentId);
        if (currentComponent === undefined) {
          this.loadGlobalComponentById(this.componentId);
          this.selectionPlace = 'Компонент глобальный';
        } else {
          this.selectionPlace = 'Компонент на странице';
        }
      } else {
        this.selectionPlace = 'Компонент на странице';
      }
      this.$emit('shown');
    },
    onChangePlace(value) {
      this.selectionPlace = value;
    },
    paramComponentChanged(eventData) {
      if (eventData.selectedItem === null) {
        return;
      }

      const currentComponent = this.$store.getters['config/getItem'](eventData.selectedItem.id);
      if (currentComponent === undefined) {
        this.loadGlobalComponentById(eventData.selectedItem.id);
      }
    },
    filterChange(event) {
      if (this.outputValue !== JSON.stringify(event.value)) {
        this.widgetValueChange = true;
        this.filterBuilderValue = event.value;
        this.outputValue = JSON.stringify(event.value);
      }
    }
  }
};

function getFilterBuilderValueFields(value, fields) {
  if (!Array.isArray(value)) {
    return;
  }

  if (Array.isArray(value[0])) {
    value.forEach((item) => getFilterBuilderValueFields(item, fields));
  } else {
    fields.add(value[0]);
  }
}
</script>

<style lang="scss">
.popup-action-param-form-content {
  display: flex;
  flex-direction: column;

  .dx-field {
    display: flex;
    flex: 0 0 auto;

    & > .dx-field-label {
      float: none;
      width: auto;
      flex: 0 0 150px;
      text-align: right;
    }
    & > .dx-field-value {
      float: none;
      width: auto;
      flex: 1 1 auto;

      & > .dx-checkbox {
        float: none;
      }
    }
  }

  .monaco-edito-wrapper {
    height: 200px;
    padding: 5px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
}
</style>
