/**Типы невидимых компонентов, отображаемых в конструкторе в отдельной области */
export const INVISIBLE_COMPONENTS = [
  'DataSet',
  'DaraSetGeoJson',
  'ServerSideDataSource',
  'PivotGridDataSource',
  'VectorMapLayer',
  'MapboxGroupLayer',
  'MapboxLayer',
  'MockDataSet',
  'TableauDataSet',
  'Popover',
  'Popup',
  'ContextMenu',
  'Timer'
];

/**Компоненты-колонки таблицы */
export const COLUMN_COMPONENT_TYPES = ['clBoolean', 'clDate', 'clGroup', 'clNumber', 'clText'];

/**Поля configа компоненты */
export const CONFIG_FIELDS = [
  'componentPos',
  'parentFormId',
  'componentId',
  'url',
  'componentName',
  'componentType',
  'dataType',
  'options',
  'cssStyle',
  'parentComponentId',
  'dataField',
  'dataFieldForCompare'
];

/**Типы компонентов-контейнеров */
export const CONTAINER_COMPONENT_TYPES = [
  'Accordion',
  'Frame',
  'CardTabs',
  'ContextMenu',
  'DataGrid',
  'Drawer',
  'DropDownBox',
  'Form',
  'Gantt',
  'Gis',
  'GisGroupLayer',
  'GisLayer',
  'GridLayout',
  'Group',
  'HorizontalLayout',
  'LevelContainer',
  'MainShortcutMenu',
  'MapboxToolbar',
  'Mapbox',
  'MapboxLayer',
  'MapboxGroupLayer',
  'Menu',
  'NumberBox',
  'Popover',
  'Popup',
  'RadioGroup',
  'ScrollView',
  'Splitter',
  'TabPanel',
  'TabPanelItem',
  'TextBox',
  'Toolbar',
  'TreeList',
  'VectorMap',
  'VerticalLayout'
];

/**Типы компонентов-контейнеров с настройкой dragResize */
export const DRAG_RESIZE_CONTAINERS = ['Form', 'Group', 'TabPanelItem'];

/**Типы компонентов с валидацией */
export const VALIDATED_COMPONENT_TYPES = [
  'Calendar',
  'CheckBox',
  'ColorBox',
  'daDataLookup',
  'DateBox',
  'Form',
  'Lookup',
  'NumberBox',
  'SelectBox',
  'TagBox',
  'TextArea',
  'TextBox',
  'GeocoderAutocomplete',
  'GisEmbedded',
  'Autocomplete'
];

export const DX_SVG_COMPONENTS = [
  'dxBarGauge',
  'dxBullet',
  'dxChart',
  'dxCircularGauge',
  'dxFunnel',
  'dxLinearGauge',
  'dxPieChart',
  'dxPolarChart',
  'dxRangeSelector',
  'dxSankey',
  'dxSparkline',
  'dxTreeMap',
  'dxVectorMap'
];

/**Компоненты датасеты */
export const DATASET_COMPONENT_TYPES = ['DataSet', 'TableauDataSet', 'MockDataSet', 'ServerSideDataSource', 'DataSetGeoJson', 'PivotGridDataSource'];

export const PARAM_TYPES_WITH_COMPONENT = [
  'inputData',
  'selectedData',
  'checkedData',
  'focusedData',
  'componentData',
  'componentDataCount',
  'customData',
  'selectedItems',
  'componentConfig'
];

/**Список компонентов диаграмм для tableau */
export const CHART_TYPE_LIST_ITEMS = [
  {
    name: 'Сводная таблица',
    icon: 'table',
    code: 'PivotGrid',
    id: 1
  },
  {
    name: 'Столбиковая диаграмма',
    icon: 'bar-chart',
    code: 'BarChart',
    id: 2
  },
  {
    name: 'Столбиковая с накоплением диаграмма',
    icon: 'stacked-bar-chart',
    code: 'StackedBarChart',
    id: 3
  },
  {
    name: 'Полная столбиковая диаграмма',
    icon: 'full-stacked-bar-chart',
    code: 'FullBarChart',
    id: 4
  },
  {
    name: 'Столбиковая с накоплением перевернутая диаграмма',
    icon: 'stacked-bar-rotated-chart',
    code: 'HorizontalBarChart',
    id: 5
  },
  {
    name: 'Линейная диаграмма',
    icon: 'chart-line',
    code: 'LineChart',
    id: 6
  },
  {
    name: 'Диаграмма с областями',
    icon: 'chart-area',
    code: 'AreaChart',
    id: 7
  },
  {
    name: 'Полная диаграмма с областями',
    icon: 'chart-alt',
    code: 'FullStackedAreaChart',
    id: 8
  },
  {
    name: 'Точечная и пузырьковая диаграмма',
    icon: 'point-chart',
    code: 'BubbleChart',
    id: 9
  },
  {
    name: 'Круговая диаграмма',
    icon: 'chart-pie',
    code: 'PieChart',
    id: 10
  },
  {
    name: 'Кольцевая диаграмма',
    icon: 'circle',
    code: 'DoughnutChart',
    id: 11
  },
  {
    name: 'Таблица',
    icon: 'grid',
    code: 'Grid',
    id: 12
  },
  {
    name: 'Схематичная карта',
    icon: 'surface-area',
    code: 'MapView',
    id: 13
  },
  {
    name: 'Экстремумы',
    icon: 'chart',
    code: 'Extremum',
    id: 14
  },
  {
    name: 'Радар',
    icon: 'polar-chart',
    code: 'RadarChart',
    id: 15
  },
  {
    name: 'Пирамида',
    icon: 'funnel',
    code: 'Funnel',
    id: 16
  },
  {
    name: 'Санки',
    icon: 'sankey',
    code: 'Sankey',
    id: 17
  },
  {
    name: 'Паутина',
    icon: 'polar-chart',
    code: 'SpiderChart',
    id: 18
  },
  {
    name: 'Полярная диаграмма',
    icon: 'polar-chart',
    code: 'PolarChart',
    id: 19
  },
  {
    name: 'Плиточная диаграмма',
    icon: 'tree-map',
    code: 'TreeMap',
    id: 20
  },
  {
    name: 'Карта с пузырьками',
    icon: 'bubble-chart',
    code: 'MapBubble',
    id: 21
  }
];

const X = {
  title: 'X',
  iconCss: 'fas fa-arrow-right',
  code: 'x'
};

const Y = {
  title: 'Y',
  iconCss: 'fas fa-arrow-up',
  code: 'y'
};

const FILTER = {
  title: 'Фильтр',
  iconCss: 'fas fa-filter',
  code: 'filter'
};

const COLOR = {
  title: 'Цвет',
  iconCss: 'fas fa-fill-drip',
  code: 'color'
};

const ORDER = {
  title: 'Сортировка',
  iconCss: 'fas fa-sort',
  code: 'order'
};

const TOOLTIP = {
  title: 'Подсказка',
  iconCss: 'far fa-comment-alt',
  code: 'tooltip'
};

const GEO = {
  title: 'Геометрия',
  iconCss: 'fas fa-map',
  code: 'geo'
};

const WEIGHT = {
  title: 'Значения',
  iconCss: 'fas fa-hashtag',
  code: 'geodata'
};

const INDICATOR = {
  title: 'Показатели',
  iconCss: 'far fa-square',
  code: 'indicator'
};

const SIZE = {
  title: 'Размер',
  iconCss: 'fas fa-ruler-vertical',
  code: 'size'
};

const COLUMN = {
  title: 'Столбцы',
  iconCss: 'fas fa-columns',
  code: 'column'
};

const ROW = {
  title: 'Строки',
  iconCss: 'fas fa-grip-lines',
  code: 'row'
};

/*
const DETAIL = {
  title: 'Таблица',
  iconCss: 'fas fa-table',
  code: 'detail'
};
*/

const TEXT = {
  title: 'Текст',
  iconCss: 'fas fa-font',
  code: 'text'
};

/*
const DOT = {
  title: 'Точки',
  iconCss: 'far fa-dot-circle',
  code: 'dot'
};
*/

const GEOPOINT = {
  title: 'Точки',
  iconCss: 'fas fa-map-marked-alt',
  code: 'geopoint'
};

/**Списки для полей по типам диаграмм для tableau */
export const FIELD_LIST_BY_CHART_TYPE = {
  PivotGrid: [COLUMN, ROW, TEXT, FILTER, COLOR],
  BarChart: [X, Y, FILTER, COLOR, ORDER, TOOLTIP],
  StackedBarChart: [X, Y, FILTER, COLOR, TOOLTIP],
  FullBarChart: [X, Y, FILTER, COLOR, TOOLTIP],
  HorizontalBarChart: [X, Y, FILTER, COLOR, TOOLTIP],
  LineChart: [X, Y, FILTER, COLOR, TOOLTIP],
  AreaChart: [X, Y, FILTER],
  FullStackedAreaChart: [X, Y, FILTER],
  BubbleChart: [X, Y, FILTER, COLOR, SIZE, TOOLTIP],
  PieChart: [INDICATOR, WEIGHT, FILTER, COLOR, TOOLTIP],
  DoughnutChart: [INDICATOR, WEIGHT, FILTER, COLOR, TOOLTIP],
  Grid: [],
  MapView: [GEO, FILTER, COLOR, TOOLTIP],
  Extremum: [WEIGHT, FILTER, COLOR, SIZE],
  RadarChart: [INDICATOR, WEIGHT, FILTER, COLOR, TOOLTIP],
  Funnel: [],
  Sankey: [],
  SpiderChart: [INDICATOR, WEIGHT, FILTER, COLOR, TOOLTIP],
  PolarChart: [INDICATOR, WEIGHT, FILTER, COLOR, TOOLTIP],
  TreeMap: [INDICATOR, FILTER, SIZE],
  MapBubble: [GEOPOINT, FILTER, COLOR, SIZE, TOOLTIP]
};

/**Костыльный словарь иконок по коду из DSELEMENTTYPE */
export const ELEMENT_TYPE_ICONS = {
  PivotGrid: 'table',
  BarChart: 'bar-chart',
  StackedBarChart: 'stacked-bar-chart',
  FullBarChart: 'full-stacked-bar-chart',
  HorizontalBarChart: 'stacked-bar-rotated-chart',
  LineChart: 'chart-line',
  AreaChart: 'chart-area',
  FullStackedAreaChart: 'chart-alt',
  BubbleChart: 'point-chart',
  PieChart: 'chart-pie',
  DoughnutChart: 'circle',
  Grid: 'grid',
  MapView: 'surface-area',
  Extremum: 'chart',
  RadarChart: 'polar-chart',
  Funnel: 'funnel',
  name: 'Санки',
  Sankey: 'sankey',
  SpiderChart: 'polar-chart',
  PolarChart: 'polar-chart',
  TreeMap: 'tree-map',
  MapBubble: 'bubble-chart'
};

/** Словарь иконок для элементов конструктора */
export const ICONS_CSS = {
  serviceComponent: null,
  clData: null,
  clText: null,
  clGroup: null,
  clNumber: null,
  Form: 'core core-form',
  TabPanel: null,
  TabPanelItem: null,
  Toolbar: null,
  Button: 'core core-button',
  Group: null,
  queryBuilder: null,
  qbString: null,
  qbRadio: null,
  qbDouble: null,
  qbSelect: null,
  clBoolean: null,
  RangeSelector: null,
  Gallery: null,
  VerticalGallery: null,
  VectorMap: null,
  Menu: null,
  iMenuItem: null,
  Chart: null,
  PieChart: null,
  TileView: null,
  textWithButton: null,
  Map: null,
  layer: null,
  jsonTextArea: null,
  diagramEditor: null,
  gis: null,
  Tabs: null,
  gannt: null,
  Accordion: null,
  Box: null,
  yandexMap: null,
  divContainer: null,
  progressBar: null,
  cesiumViewer: null,
  daDataLookup: null,
  codeMirror: null,
  importFromFile: null,
  tinyMCE: null,
  treantJS: null,
  htmlRender: null,
  cryptoPro: null,
  cspCertLookup: null,
  audioPlayer: null,
  addressRegister: null,
  openlayersMap: null,
  bpmn: null,
  checkList: null,
  PolarChart: null,
  DataGridComments: null,
  feed: null,
  addressCompare: null,
  addressForm: null,
  breadcrumbs: null,
  HtmlEditor: null,
  PivotGrid: null,
  pgDimension: null,
  pgMeasure: null,
  Drawer: null,
  graph: null,
  directedGraph: null,
  dataMappingGraph: null,
  test: null,
  cube: null,
  Tooltip: null,
  Popover: null,
  GridLayout: null,
  Pager: null,
  Mapbox: null,
  ButtonGroup: null,
  MonacoEditor: null,
  routerView: null,
  Gantt: null,
  Splitter: null,
  BarGauge: null,
  Bullet: null,
  CircularGauge: null,
  Funnel: null,
  LinearGauge: null,
  Sankey: null,
  Sparkline: null,
  TreeMap: null,
  VectorMapLayer: null,
  MapboxLayer: null,
  TableauDataSet: null,
  MapboxGroupLayer: null,
  MapboxLegend: null,
  MapboxToolbar: null,
  GisLayer: 'core core-091-layer',
  GisGroupLayer: 'core core-001-layers',
  DataSet: 'core-data-set',
  DataSetGeoJson: 'core-data-set',
  PivotGridDataSource: 'core-data-set',
  ServerSideDataSource: 'core-data-set',
  Popup: 'core-popup',
  TreeList: 'core-treelist',
  TreeView: 'core-treeview',
  DataGrid: 'core-table',
  Autocomplete: 'core-field',
  Calendar: 'core-field',
  CheckBox: 'core-field',
  CheckList: 'core-field',
  ColorBox: 'core-field',
  DaData: 'core-field',
  DateBox: 'core-field',
  DropDownBox: 'core-field',
  FileUploader: 'core-field',
  FilterBuilder: 'core-field',
  GeocoderAutocomplete: 'core-field',
  Lookup: 'core-field',
  NumberBox: 'core-field',
  RadioGroup: 'core-field',
  RangeSlider: 'core-field',
  Rating: 'core-field',
  Scale: 'core-field',
  SelectBox: 'core-field',
  Slider: 'core-field',
  Switch: 'core-field',
  TagBox: 'core-field',
  TextArea: 'core-field',
  TextBox: 'core-field',
  TimeIntervalGroup: 'core-field'
};

export const PLACE_DATASET = 'CONNECTIONS.DSPLACE';

export const GROUP_NAME = '_validationGroup';

export const REGEXP_GUID = /^(\{{0,1}([0-9a-fA-F]){8}-([0-9a-fA-F]){4}-([0-9a-fA-F]){4}-([0-9a-fA-F]){4}-([0-9a-fA-F]){12}\}{0,1})$/g;

export const REGEXP_GUID_ALTERNATIVE = /^(((?=.*}$){)|((?!.*}$)))((?!.*-.*)|(?=(.*[-].*){4}))[0-9a-fA-F]{8}[-]?([0-9a-fA-F]{4}[-]?){3}[0-9a-fA-F]{12}?[}]?$/gm;

export const HTML_EDITOR_DEFAULT_ITEMS = [
  'undo',
  'redo',
  'separator',
  {
    name: 'size',
    acceptedValues: ['4px', '6px', '8px', '10px', '12px', '14px', '18px', '22px', '26px', '30px', '36pt']
  },
  {
    name: 'font',
    acceptedValues: [
      'Arial',
      'Arimo',
      'Bernard MT',
      'Book Antiqua',
      'Britannic',
      'Calibri',
      'Calisto MT',
      'Century',
      'Consolas',
      'Courier New',
      'David Libre',
      'Elephant',
      'Felix Titling',
      'Georgia',
      'Gloucester MT',
      'Impact',
      'Liberation Mono',
      'Liberation Sans',
      'Liberation Serif',
      'Lucida Bright',
      'Lucida Console',
      'Lucida Fax',
      'Lucida Sans',
      'Montserrat',
      'Noto Mono',
      'Noto Sans',
      'Papyrus',
      'Perpetua',
      'Rockwell',
      'Rubik',
      'Segoe Print',
      'Segoe UI',
      'Sitka',
      'Source Code Pro',
      'Source Sans Pro',
      'Stencil',
      'Sylfaen',
      'Tahoma',
      'Times New Roman',
      'Trebuchet MS',
      'Tw Cen MT',
      'Verdana',
      'RFRufoBlackItalic',
      'RFRufoBlack',
      'RFRufoBoldItalic',
      'RFRufoBold',
      'RFRufoItalic',
      'RFRufoLightItalic',
      'RFRufoLight',
      'RFRufoRegular',
      'RFRufoSemiBoldItalic',
      'RFRufoSemiBold',
      'RFRufoThinItalic',
      'RFRufoThin'
    ]
  },
  'separator',
  'bold',
  'italic',
  'strike',
  'underline',
  'separator',
  'alignLeft',
  'alignCenter',
  'alignRight',
  'alignJustify',
  'separator',
  'orderedList',
  'bulletList',
  'separator',
  {
    name: 'header',
    acceptedValues: [false, 1, 2, 3, 4, 5]
  },
  'separator',
  'color',
  'background',
  'separator',
  'link',
  'image',
  'separator',
  'clear',
  'codeBlock',
  'blockquote',
  'separator',
  'insertTable',
  'deleteTable',
  'insertRowAbove',
  'insertRowBelow',
  'deleteRow',
  'insertColumnLeft',
  'insertColumnRight',
  'deleteColumn'
];

export const FIELD_TYPE_ICONS = {
  boolean: 'core-088-true-false',
  integer: 'core-num-1',
  bigint: 'core-num-1',
  'bigint[]': 'core-num-2',
  'double precision': 'core-num-3',
  json: 'core-json-1',
  jsonb: 'core-json-2',
  text: 'core-054-text',
  'varchar(254)': 'core-054-text',
  'text[]': 'core-text-3',
  timestamp: 'core-timedate',
  geometry: 'core-031-add-point',
  bit: 'core-053-image',
  url: 'core-link'
};

/**
 * Ключ - тип компонента, значение - elementtype_id по умолчанию для этого компонента
 */
export const COMPONENT_TYPES_FOR_TABLEAU = { Chart: 2, PieChart: 10, PivotGrid: 1, DataGrid: 12, GisTablo: 252, InfoBlock: 22, BulletChart: 258 };

export const GROUPING_FUNCTION = {
  Gis: {
    'Панель слоев': ['addLayer', 'removeLayer', 'editLayer', 'editLayerObjects', 'collapseAllFolders', 'showTmpLayer', 'changeType'],
    'Параметры слоя': [
      'editLayerStyle',
      'moveToLayer',
      'exportLayerData',
      'createBufferZoneLayer',
      'validations',
      'editLayerData',
      'editLayerClass',
      'geocodeInLayerForm',
      'calcInLayerForm'
    ],
    'Панель инструментов (Общие)': [
      'select',
      'measure',
      'rectangleSelect',
      'polygonSelect',
      'circleSelect',
      'bufferZoneSelect',
      'circleRadiusSelect',
      'gotoExtent',
      'gotoSelectedObjects',
      'gotoPreviousExtent',
      'gotoNextExtent',
      'perimeterMeasure',
      'toggleLegend',
      'toggleSpaceImageryPanel',
      'snapshotForReport',
      'mapSnapshot',
      'scale',
      'objectIdentification',
      'geocoder',
      'fullScreenMode',
      'coordisearchPointnates',
      'gisSettingsButton',
      'selectedObjectIntersection',
      'searchPoint',
      'showTimeline',
      'swipe'
    ],
    'Панель инструментов (Редактирование)': [
      'buffer',
      'history',
      'save',
      'snapping',
      'addFeature',
      'addPoint',
      'addLine',
      'addPolygon',
      'addBufferZone',
      'addIntersectionObject',
      'addUnionObject',
      'addConvexHull',
      'addSubstractAll',
      'addSubstractIntersectAll',
      'editFeature',
      'editVertexes',
      'editMove',
      'pointEraser',
      'deleteFeature',
      'part',
      'partAddNewObject',
      'removePart',
      'bufferCopy',
      'bufferPaste',
      'coordinates',
      'sliceGeom',
      'sliceGeomBuffer',
      'addArbitraryPolygon',
      'addFreePolygon',
      'addBufferedPolygon',
      'addRectangle',
      'addCircle',
      'addDifferenceObject',
      'addSymmetricDifferenceObject'
    ],
    'Разное ': ['moveLayer', 'zdravPointAvailability', 'routeBuild']
  },
  GisLayer: {
    'Слои (Создание и редактирование)': [
      'create',
      'loadData',
      'editFields',
      'editAccess',
      'clone',
      'move',
      'validations',
      'editLayer',
      'editOptions',
      'options'
    ],
    'Слои (Функции работы со слоем)': ['geocode', 'showCard', 'table', 'moveToLayer', 'export', 'buffer', 'filter', 'showCalc']
  }
};

/**Настройки кнопки сворачиания окна */
export const COLLAPSE_BUTTON_STATES = {
  [false]: {
    icon: 'fas fa-minus',
    hint: 'Свернуть'
  },
  [true]: {
    icon: 'far fa-square',
    hint: 'Развернуть'
  }
};

/**Методы сервиса dataset*/
export const DATA_SET_METHODS = ['getData', 'insertData', 'updateData', 'deleteData'];

/**Параметр для вставки в запрос без имени атрибута. Используется для вставки массива: params: [...]*/
export const CONFIG_ARRAY_PARAMS = '_config_array_params';

/**Значение espg по умолчанию (На этих константах завязан !!!большой функционал!!!, в том числе построение запросов к ПКК, если поменять на другую систему координат) запросы для выделенных объектов ПКК лягут в ошибку 400*/
export const DEFAULT_EPSG = 3857;
export const DEFAULT_EPSG_NAME = 'EPSG: 3857';
export const DEFAULT_EPSG_SRS = 'EPSG:3857';
export const DEFAULT_EPSG_BBOX = '{bbox-epsg-3857}';

// Дефолтные стили для косметического слоя
export const TMP_MAPBOX_LAYERS = [
  {
    type: 'fill',
    paint: {
      'fill-color': '#fff421',
      'fill-opacity': 0.63
    },
    filter: ['all', ['in', ['geometry-type'], ['literal', ['Polygon', 'MultiPolygon']]]],
    layout: {
      visibility: 'visible'
    },
    maxzoom: 24,
    minzoom: 0,
    isDefault: true
  },
  {
    type: 'line',
    paint: {
      'line-color': '#ff0303',
      'line-width': 1,
      'line-opacity': 1,
      'line-translate': [0, 0]
    },
    filter: ['all', ['in', ['geometry-type'], ['literal', ['Polygon', 'MultiPolygon']]]],
    layout: {
      visibility: 'visible'
    },
    maxzoom: 24,
    minzoom: 0,
    isDefault: true
  },
  {
    type: 'line',
    paint: {
      'line-color': '#fff421',
      'line-width': 2,
      'line-opacity': 1,
      'line-translate': [0, 0]
    },
    filter: ['all', ['in', ['geometry-type'], ['literal', ['LineString', 'MultiLineString']]]],
    layout: {
      visibility: 'visible'
    },
    maxzoom: 24,
    minzoom: 0,
    isDefault: true
  },
  {
    type: 'circle',
    paint: {
      'circle-color': '#fff421',
      'circle-radius': 4,
      'circle-opacity': 1,
      'circle-stroke-color': '#ff0303',
      'circle-stroke-width': 2,
      'circle-stroke-opacity': 1
    },
    filter: ['all', ['in', ['geometry-type'], ['literal', ['Point', 'MultiPoint']]]],
    layout: {
      visibility: 'visible'
    },
    maxzoom: 24,
    minzoom: 0,
    isDefault: true
  }
];
// Регулярка для кадастрового номера
export const REGEXP_CNUM = /\d{2}:\d{2}:\d{6,7}:[\dA-Za-яА-Я]*/gm;
