import getConfigs from './configUtils';
const OPTIONS = ['placeholder', 'applyButtonText', 'cancelButtonText', 'stylingMode', 'editAlphaChannel', 'showClearButton', 'disabled'];

const CONFIGS_FIELDS = {
  placeholder: {
    name: 'placeholder',
    label: 'Подсказка',
    component: 'DxTextBox',
    options: {
      showClearButton: true
    },
    changeEvent: 'valueChanged'
  },
  applyButtonText: {
    name: 'applyButtonText',
    label: 'Текст кнопки "Применить"',
    component: 'DxTextBox',
    options: {
      showClearButton: true
    },
    changeEvent: 'valueChanged'
  },
  cancelButtonText: {
    name: 'cancelButtonText',
    label: 'Текст кнопки "Отменить"',
    component: 'DxTextBox',
    options: {
      showClearButton: true
    },
    changeEvent: 'valueChanged'
  },
  stylingMode: {
    name: 'stylingMode',
    label: 'Стиль поля',
    component: 'DxSelectBox',
    options: {
      dataSource: [
        { text: 'С рамкой', value: 'outlined' },
        { text: 'Подчеркнутый', value: 'underlined' },
        { text: 'Закрашенный', value: 'filled' }
      ],
      showClearButton: true,
      displayExpr: 'text',
      valueExpr: 'value'
    },
    changeEvent: 'valueChanged'
  },
  editAlphaChannel: {
    label: 'Прозрачность',
    name: 'editAlphaChannel',
    component: 'DxSelectBox',
    options: {
      dataSource: [
        { text: 'Да', value: 'true' },
        { text: 'Нет', value: 'false' }
      ],
      showClearButton: true,
      displayExpr: 'text',
      valueExpr: 'value'
    },
    changeEvent: 'optionChanged'
  },
  showClearButton: {
    name: 'showClearButton',
    label: 'Кнопка очистки',
    component: 'DxSelectBox',
    options: {
      dataSource: [
        { text: 'Да', value: 'true' },
        { text: 'Нет', value: 'false' }
      ],
      showClearButton: true,
      displayExpr: 'text',
      valueExpr: 'value'
    },
    changeEvent: 'optionChanged'
  },
  disabled: {
    name: 'disabled',
    label: 'Поле заблокировано',
    component: 'DxSelectBox',
    options: {
      dataSource: [
        { text: 'Да', value: 'true' },
        { text: 'Нет', value: 'false' }
      ],
      showClearButton: true,
      displayExpr: 'text',
      valueExpr: 'value'
    },
    changeEvent: 'optionChanged'
  }
};

export default getConfigs(OPTIONS, CONFIGS_FIELDS);
