export const getCartProducts = 'getCartProducts';
export const getCartProductsCount = 'getCartProductsCount';

export default {
  getCartProducts(state) {
    if (!Array.isArray(state.cartProducts)) {
      return JSON.parse(state.cartProducts);
    }

    return state.cartProducts;
  },
  getCartProductsCount(state) {
    const cartProductsCount = parseInt(state.cartProductsCount);
    return !isNaN(cartProductsCount) ? cartProductsCount : 0;
  }
};
