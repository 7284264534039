//Тип действия который ничего не делает
export const NONE = 'none';

//Легенда слоев
export const LEGEND = 'legend';

//Панель космически снимков
export const SPACE_IMAGERY = 'spaceImagery';

//Временная шкала
export const TIMELINE = 'timeline';

//Инструменты выбора
export const SELECT = 'select';
export const LINE_SELECT = 'lineSelect';
export const RECTANGLE_SELECT = 'rectangleSelect';
export const POLYGON_SELECT = 'polygonSelect';
export const CIRCLE_SELECT = 'circleSelect';
export const BUFFER_ZONE_SELECT = 'bufferZoneSelect';
export const BUFFER_ZONE_SELECT_ROUNDED = 'bufferZoneSelectRounded';
export const CIRCLE_RADIUS_SELECT = 'circleRadiusSelect';
export const CLEAR_SELECT = 'clearSelect';

//Инструменты измерения
export const MEASURE_DISTANCE = 'measureDistance';
export const MEASURE_AREA = 'measureArea';
export const MEASURE_PERIMETER = 'measurePerimeter';
export const MEASURE_AREA_FOR_SELECTED = 'measureAreaForSelected';

//Кнопка сохранить
export const SAVE = 'save';

//История изменений
export const UNDO = 'undo';
export const REDO = 'redo';

//Инструменты буфер-обмена
export const COPY = 'copy';
export const PASTE = 'paste';
export const CUT = 'cut';
export const DELETE = 'delete';
export const DELETE_GEOMETRY = 'deleteGeometry';

//Инструменты добавления объектов
export const MARKER = 'marker';
export const PENCIL_LINE = 'pencilLine';
export const PENCIL_POLYGON = 'pencilPolygon';
export const LINE = 'line';
export const POLYGON = 'polygon';
export const BUFFER_POLYGON = 'buffer_polygon';
export const POLYGON_FREE = 'polygon_free';
export const CIRCLE = 'circle';
export const CLICK_RECTANGLE = 'clickRectangle';
export const GEO_JSON = 'geoJson';
export const BUFFER_ZONE = 'bufferZone';
export const BUFFER_ZONE_ROUNDED = 'bufferZoneRounded';
export const BUFFER_ZONE_UNION = 'bufferZoneUnion';
export const COORDINATES = 'coordinates';
export const NEW_INTERSECTION_OBJECT = 'newIntersectionObject';
export const NEW_UNION_OBJECT = 'newUnionObject';
export const NEW_SYMMETRIC_DIFFERENCE_OBJECT = 'newSymmetricDifferenceObject';
export const NEW_DIFFERENCE_OBJECT = 'newDifferenceObject';
export const CONVEX_HULL = 'convexHull';
export const SUBSTRACT_ALL = 'substructAll';
export const SUBSTRACT_ALL_ADD_INTERSECT = 'substructIntersectAll';
export const SLICE_GEOM = 'sliceGeom';
export const SLICE_GEOM_BUFFER = 'sliceGeomBuffer';
export const SLICE_GEOM_SAVE_CUT_OBJ = 'sliceGeomCutObj';
export const SUBTRACT_POLY = 'subtractPoly';

//Инструменты прилипания (snapping)
export const SNAP_TO_POINT = 'snapToPoint';
export const SNAP_TO_LINE = 'snapToLine';

//Инструменты редактирования объекта
export const EDIT = 'edit';
export const MOVE = 'move';
export const CHANGE_PYLYGON = 'changePolygon';
export const PART = 'part';
export const PART_ADD_NEW_OBJECT = 'partAddNewObject';
export const REMOVE_PART = 'removePart';
export const CHANGE_TYPE = 'changeType';
export const CHANGE_TYPE_LINE = 'changeTypeLineString';
export const CHANGE_TYPE_MULTI_LINE = 'changeTypeMultiLineString';
export const CHANGE_TYPE_POINT = 'changeTypePoint';
export const CHANGE_TYPE_MULTI_POINT = 'changeTypeMultiPoint';
export const CHANGE_TYPE_POLY = 'changeTypePolygon';
export const CHANGE_TYPE_MULTI_POLY = 'changeTypeMultiPolygon';
export const POINT_ERASER = 'pointEraser';
export const LINE_ERASER = 'lineEraser';
//Размер стерки
export const ERASER_SIZE = 'eraserSize';
//Флаг применения стерки только к выбранным объектам
export const ERASER_SELECTED_ONLY = 'eraserSelectedOnly';

//Отчет по объекту
export const SNAPSHOT_FOR_REPORT = 'snapshotForReport';

//Снимок карты
export const FULL_MAP_SNAPSHOT = 'fullMapSnapshot';
export const PART_MAP_SNAPSHOT = 'partMapSnapshot';

//Инструмент выбора масштаба
export const SCALE = 'scale';
export const BOX_ZOOM = 'boxZoom';
export const SELECTED_OBJECTS_ZOOM = 'selectedObjectsZoom';
export const EXTENT_PREVIOUS = 'extentPrevious';
export const EXTENT_NEXT = 'extentNext';

//Инструмент поиска адреса по точке
export const FIND_ADDRESS_BY_POINT = 'findAddressByPoint';

//Инструмент идентификации объекта
export const OBJECT_IDENTIFICATION = 'objectIdentification';

//Инструмент обратного геокодирования
export const REVERSE_GEOCODE = 'reverseGeocode';

//Инструмент перехода в полноэкранный режим
export const FULL_SCREEN_TOOL = 'fullScreenTool';
//Инструмент поиска точки по координатам
export const SEARCH_POINT = 'searchPoint';

//Инструменты изменения геометрии
export const UNION_MULTIPOLYGON_INTERSECTION = 'unionMultiPolygonIntersection';

// Массив названий инструментов после взаимодействия с которыми не нужно закрывать окно выбора инструмента.
export const NOT_CLOSE_SELECTION_TOOLS = [
  SAVE,
  UNDO,
  REDO,
  COPY,
  PASTE,
  DELETE,
  DELETE_GEOMETRY,
  FULL_SCREEN_TOOL,
  BUFFER_ZONE,
  COORDINATES,
  SNAPSHOT_FOR_REPORT,
  FULL_MAP_SNAPSHOT
];

//Инструменты показа пересечения выбранных объектов
export const SELECTED_OBJECT_INTERSECTION = 'selectedObjectIntersection';

//Инструмент доступность в точке
export const POINT_AVAILABILITY = 'pointAvailability';

//Инструмент построения маршрутов
export const ROUTE_BUILD = 'routeBuild';

//Окно добавления слоя в граф
export const ADD_GRAPH_LAYER = 'addGraphLayer';

//Инструмент шторка: вертикальная и горизонтальная
export const SWIPE_TOOL_V = 'swipeToolV';
export const SWIPE_TOOL_H = 'swipeToolH';
// Инструмент построения тепловой карты по значению
export const RECTANGLE_HEATMAP = 'rectangleHeatmap';
// Инструмент оцифровки полигона по цвету на клиенте
export const DIGITIZE_POLY_BYCOLOR = 'digitizePolyBycolor';
// Инструмент оцифровки полигона на сервере
export const DIGITIZE_POLY_SERVER = 'digitizePolyServer';
// Инструмент оцифровки линии на клиенте
export const DIGITIZE_LINE_BYCOLOR = 'digitizeLineBycolor';

// Инструмент формирования запроса по выбранным объектам активного слоя
export const REQUEST_FOR_SSELECTED_FFEATURES = 'requestForSelectedFeatures';

// Аппроксимация для геометрии (Активность инструмента, коэффициент, качество)
export const APPROXIMATION = 'approximation';
export const APPROXIMATION_QUALITY = 'approximationQuality';
export const APPROXIMATION_TOLERANCE = 'approximationTolerance';
//Сглаживание геометрии (Активность инструмента, качество (итерации)
export const SMOOTH_GEOMETRY = 'smoothGeometry';
export const SMOOTH_GEOMETRY_QUALITY = 'smoothGeometryQuality';
export const SPLINE_LINE_RESOLUTION = 'splineLineResolution';
export const SPLINE_LINE_SHARPNESS = 'splineLineSharpness';

export const PKK_GEOM_IDENTIFICATION = 'pkkGeomIdentification';

//Инструменты, использующие снэппинг
export const TOOLS_WITH_SNAP = [
  CIRCLE_RADIUS_SELECT,
  MEASURE_DISTANCE,
  MEASURE_AREA,
  MARKER,
  PENCIL_LINE,
  PENCIL_POLYGON,
  LINE,
  POLYGON,
  BUFFER_POLYGON,
  CIRCLE,
  EDIT,
  CHANGE_PYLYGON,
  PART,
  PART_ADD_NEW_OBJECT,
  SLICE_GEOM,
  SLICE_GEOM_BUFFER,
  SLICE_GEOM_SAVE_CUT_OBJ
];
