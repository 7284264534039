import notify from 'devextreme/ui/notify';

export default {
  data() {
    return {
      isInit: false
    };
  },

  methods: {
    goToLogin(redirectToPath) {
      // Новый вариант с провайдером
      switch (process.env.VUE_APP_DEFAULT_PROVIDER) {
        case 'default':
          //Для внутреннего сервиса сессий просто проставляем флаг, что не авторизованы
          this.$store.dispatch('session/setIsAuthenticated', { isAuthenticated: false }).then(() => {
            let loginPath = process.env.VUE_APP_LOGIN_SESSION || '/login';

            //Если есть путь на который надо вернуться после входа
            //Не создаем редирект на страницу авторизации
            if (redirectToPath && redirectToPath.indexOf('login') < 0) {
              loginPath = `${loginPath}?redirectTo=${redirectToPath}`;
            }

            if (this.$route.fullPath !== loginPath) {
              this.$router.push(loginPath);
            }
          });
          break;
        default:
          let providerUrl = `${process.env.VUE_APP_PROVIDERS}?provider=${process.env.VUE_APP_DEFAULT_PROVIDER}`;
          window.location.href = providerUrl;
          break;
      }

      return true;
    },

    createGuestSession() {
      this.isInit = true;

      //Для создания гостевой сессии пока оставим возможность указать логин и пароль в настройках
      const guestUserLogin = process.env.VUE_APP_GUEST_USER_LOGIN;
      const guestUserPassword = process.env.VUE_APP_GUEST_USER_PASSWORD;
      const domain = window.location.host;

      //Пусть это остается для отладки и для проектов где core подключается как субмодуль
      const portal_id = process.env.VUE_APP_PORTAL_ID;

      let param;
      if (guestUserLogin && guestUserPassword) {
        param = {
          isGuest: true,
          _config_login: guestUserLogin,
          _config_password: guestUserPassword
        };
      } else if (portal_id) {
        //Передача portal_id для отладки
        param = {
          isGuest: true,
          portal_id
        };
      } else if (domain) {
        param = {
          isGuest: true,
          domain
        };
      } else {
        const error = 'Недостаточно параметров для создания гостевой сессии';
        window.console.warn(error);
        throw error;
      }

      return this.$store
        .dispatch('session/create', param)
        .then(() => {
          return this.$store.dispatch('session/getUserData');
        })
        .then((userData) => {
          //После создания гостевого пользователя установим информацию о портале
          return this.$store.dispatch('portal/setPortalConfig', {
            portalConfig: userData.portal
          });
        })
        .catch((error) => {
          if (error.message) {
            this.error = error.message;
          } else {
            this.error = 'Системная ошибка. Обратитесь к разработчикам';
          }

          throw error;
        })
        .finally(() => {
          this.isInit = false;
          this.loading = false;
        });
    },

    /**
     * Переход к руоту по умолчанию
     * @param {*} userData
     */
    goToDefaultPath() {
      const defaultPath = this.$store.getters['portal/defaultPath'];

      // Если находимся на странице по умолчанию, то ничего не делать
      if (defaultPath === this.$route.path) {
        return false;
      }

      //Если гостевой пользователь на портале без гостевого доступа
      if (this.$store.getters['session/userIsGuest'] && !defaultPath) {
        //Переходим на авторизацию
        this.goToLogin();
        return true;
      }

      //В остальных случаях переходим по дефолтному пути
      this.$router.push(defaultPath);
      return true;
    },

    goToCurrentPath() {
      //Если есть путь, то обновляем его
      const currentPath = this.$route.path;
      if (currentPath && currentPath !== '/') {
        this.$router.go();
        return true;
      }

      return false;
    },

    guestSessionErrorNotify() {
      notify({ message: 'Не удалось создать гостевую сессию. Обратитесь к разработчикам', width: 'auto' }, 'warning', 3000);
    }
  }
};
