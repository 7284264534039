import getConfigs from './configUtils';
const OPTIONS = ['placeholder', 'mode', 'stylingMode', 'min', 'max', 'step', 'showClearButton', 'showSpinButtons', 'disabled'];

const CONFIGS_FIELDS = {
  placeholder: {
    name: 'placeholder',
    label: 'Подсказка',
    component: 'DxTextBox',
    options: {
      showClearButton: true
    },
    changeEvent: 'valueChanged'
  },
  mode: {
    name: 'mode',
    label: 'Тип',
    component: 'DxSelectBox',
    options: {
      dataSource: [
        { text: 'E-mail', value: 'email' },
        { text: 'Пароль', value: 'password' },
        { text: 'Поиск', value: 'search' },
        { text: 'Телефон', value: 'tel' },
        { text: 'Текст', value: 'text' },
        { text: 'URL', value: 'url' }
      ],
      showClearButton: true,
      displayExpr: 'text',
      valueExpr: 'value'
    },
    changeEvent: 'valueChanged'
  },
  stylingMode: {
    name: 'stylingMode',
    label: 'Стиль поля',
    component: 'DxSelectBox',
    options: {
      dataSource: [
        { text: 'С рамкой', value: 'outlined' },
        { text: 'Подчеркнутый', value: 'underlined' },
        { text: 'Закрашенный', value: 'filled' }
      ],
      showClearButton: true,
      displayExpr: 'text',
      valueExpr: 'value'
    },
    changeEvent: 'valueChanged'
  },
  min: {
    name: 'min',
    label: 'Минимальное значение',
    component: 'DxNumberBox',
    options: {
      showClearButton: true
    },
    changeEvent: 'valueChanged'
  },
  max: {
    name: 'max',
    label: 'Максимальнное значение',
    component: 'DxNumberBox',
    options: {
      showClearButton: true
    },
    changeEvent: 'valueChanged'
  },
  step: {
    name: 'step',
    label: 'Шаг',
    component: 'DxNumberBox',
    options: {
      showClearButton: true
    },
    changeEvent: 'valueChanged'
  },
  showClearButton: {
    name: 'showClearButton',
    label: 'Кнопка очистки',
    component: 'DxSelectBox',
    options: {
      dataSource: [
        { text: 'Да', value: 'true' },
        { text: 'Нет', value: 'false' }
      ],
      showClearButton: true,
      displayExpr: 'text',
      valueExpr: 'value'
    },
    changeEvent: 'optionChanged'
  },
  showSpinButtons: {
    name: 'showSpinButtons',
    label: 'Кнопки управления',
    component: 'DxSelectBox',
    options: {
      dataSource: [
        { text: 'Да', value: 'true' },
        { text: 'Нет', value: 'false' }
      ],
      showClearButton: true,
      displayExpr: 'text',
      valueExpr: 'value'
    },
    changeEvent: 'optionChanged'
  },
  disabled: {
    name: 'disabled',
    label: 'Поле заблокировано',
    component: 'DxSelectBox',
    options: {
      dataSource: [
        { text: 'Да', value: 'true' },
        { text: 'Нет', value: 'false' }
      ],
      showClearButton: true,
      displayExpr: 'text',
      valueExpr: 'value'
    },
    changeEvent: 'optionChanged'
  }
};

export default getConfigs(OPTIONS, CONFIGS_FIELDS);
