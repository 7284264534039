import getConfigs from './configUtils';
const OPTIONS = ['min', 'max', 'step', 'tooltip', 'showRange', 'disabled'];

const CONFIGS_FIELDS = {
  min: {
    name: 'min',
    label: 'Минимальное значение',
    component: 'DxNumberBox',
    options: {
      showClearButton: true
    },
    changeEvent: 'valueChanged'
  },
  max: {
    name: 'max',
    label: 'Максимальнное значение',
    component: 'DxNumberBox',
    options: {
      showClearButton: true
    },
    changeEvent: 'valueChanged'
  },
  step: {
    name: 'step',
    label: 'Шаг',
    component: 'DxNumberBox',
    options: {
      showClearButton: true
    },
    changeEvent: 'valueChanged'
  },
  tooltip: {
    name: 'tooltip',
    label: 'Подсказка',
    component: 'FieldsBox',
    options: {
      fields: ['enabled', 'position', 'showMode'],
      fieldName: 'Подсказка'
    },
    changeEvent: 'change'
  },
  enabled: {
    name: 'enabled',
    label: 'Включить',
    component: 'DxSelectBox',
    options: {
      dataSource: [
        { text: 'Да', value: 'true' },
        { text: 'Нет', value: 'false' }
      ],
      showClearButton: true,
      displayExpr: 'text',
      valueExpr: 'value'
    },
    changeEvent: 'optionChanged'
  },
  position: {
    name: 'position',
    label: 'Позиция',
    component: 'DxSelectBox',
    options: {
      dataSource: [
        { text: 'Внизу', value: 'bottom' },
        { text: 'Вверху', value: 'top' }
      ],
      showClearButton: true,
      displayExpr: 'text',
      valueExpr: 'value'
    },
    changeEvent: 'valueChanged'
  },
  showMode: {
    name: 'showMode',
    label: 'Показывать подсказку',
    component: 'DxSelectBox',
    options: {
      dataSource: [
        { text: 'Всегда', value: 'always' },
        { text: 'По наведению', value: 'onHover' }
      ],
      showClearButton: true,
      displayExpr: 'text',
      valueExpr: 'value'
    },
    changeEvent: 'valueChanged'
  },
  showRange: {
    name: 'showRange',
    label: 'Выделять диапазон',
    component: 'DxSelectBox',
    options: {
      dataSource: [
        { text: 'Да', value: 'true' },
        { text: 'Нет', value: 'false' }
      ],
      showClearButton: true,
      displayExpr: 'text',
      valueExpr: 'value'
    },
    changeEvent: 'optionChanged'
  },
  disabled: {
    name: 'disabled',
    label: 'Поле заблокировано',
    component: 'DxSelectBox',
    options: {
      dataSource: [
        { text: 'Да', value: 'true' },
        { text: 'Нет', value: 'false' }
      ],
      showClearButton: true,
      displayExpr: 'text',
      valueExpr: 'value'
    },
    changeEvent: 'optionChanged'
  }
};

export default getConfigs(OPTIONS, CONFIGS_FIELDS);
