<template>
  <!--eslint-disable-->
  <DxAccordion
    class="components-accordion"
    ref="accordion"
    height="100%"
    :width="250"
    :multiple="true"
    :collapsible="true"
    :selectedItems="[items[0], items[1]]"
    :items="items"
    :focusStateEnabled="false"
    :deferRendering="false"
  >
    <template #componentTypes>
      <div class="templates-wrapper">
        <DxToolbar class="toolbar">
          <DxToolbarItem
            widget="dxButton"
            location="before"
            :options="addNewComponentTypeButtonOptions"
          />
        </DxToolbar>
        <ComponentListPanel />
        <PopupNewComponentTypeForm
          :visible="newComponentTypePopupVisible"
          @hidden="newComponentTypePopupVisible = false"
          @shown="newComponentTypePopupVisible = true"
        />
      </div>
    </template>
    <template #componentTemplates>
      <div class="templates-wrapper">
        <DxToolbar class="toolbar">
          <DxToolbarItem
            widget="dxButton"
            location="before"
            :options="deleteButtonOptions"
            :disabled="!selectedTemplateId"
          />
        </DxToolbar>
        <ComponentTemplatesListPanel />
      </div>
    </template>
  </DxAccordion>
</template>

<script>
import DxButton from 'devextreme-vue/button';
import DxAccordion from 'devextreme-vue/accordion';
import ComponentListPanel from './ComponentListPanel';
import ComponentTemplatesListPanel from './ComponentTemplatesListPanel';
import { DxToolbar, DxItem as DxToolbarItem } from 'devextreme-vue/toolbar';
import { custom } from 'devextreme/ui/dialog';
import PopupNewComponentTypeForm from './PopupNewComponentTypeForm';

export default {
  components: {
    DxAccordion,
    DxButton, // eslint-disable-line
    ComponentListPanel,
    ComponentTemplatesListPanel,
    DxToolbar,
    DxToolbarItem,
    PopupNewComponentTypeForm
  },
  computed: {
    selectedTemplateId() {
      return this.$store.getters['uiConstructor/selectedTemplateId'];
    }
  },
  data() {
    return {
      items: [
        {
          title: 'Компоненты',
          template: 'componentTypes'
        },
        {
          title: 'Шаблоны',
          template: 'componentTemplates'
        }
      ],
      addNewComponentTypeButtonOptions: {
        icon: 'plus',
        type: 'default',
        hint: 'Добавить тип компонента',
        onClick: () => {
          this.newComponentTypePopupVisible = true;
        }
      },
      deleteButtonOptions: {
        icon: 'trash',
        type: 'danger',
        hint: 'Удалить выбранный шаблон',
        onClick: () => {
          let deleteConfirmDialog = custom({
            showTitle: false,
            messageHtml: '<div style="font-size:16px;">Удалить выбранный шаблон?</div>',
            buttons: [
              {
                text: 'Да',
                type: 'danger',
                onClick: () => {
                  this.deleteComponent();
                }
              },
              {
                text: 'Нет'
              }
            ]
          });
          deleteConfirmDialog.show();
        }
      },
      newComponentTypePopupVisible: false
    };
  },
  methods: {
    updateDimensions() {
      this.$refs.accordion.instance.updateDimensions();
    },
    deleteComponent() {
      if (!this.selectedTemplateId) {
        return;
      }

      this.$store.dispatch('uiConstructor/deleteTemplate', this.selectedTemplateId).then(() => {
        this.$store.dispatch('uiConstructor/setSelectedTemplateId', null);
        this.$store.dispatch('uiConstructor/loadComponentTemplates');
      });
      this.$emit('hidden');
    }
  }
};
</script>

<style lang="scss" scoped>
.dx-accordion {
  background-color: #f7f7f7;

  /*Дополнение dx-стилей для того чтобы отображать в аккордеоне treeView со скроллом*/
  .dx-accordion-item {
    overflow: hidden;
    display: flex;
    flex-direction: column;

    .dx-accordion-item-title {
      flex: 0 0 auto;
    }

    .dx-accordion-item-body {
      &.templates-wrapper {
        flex: 1 1 0;
        min-height: 0;
        display: flex;
        flex-direction: column;

        .toolbar {
          flex: 0 0 auto;
          background-color: #f7f7f7;
          margin-bottom: 5px;
        }
      }
    }
  }
}
</style>

<style lang="scss">
.components-accordion {
  &.dx-accordion {
    background-color: #f7f7f7;

    /*Дополнение dx-стилей для того чтобы отображать в аккордеоне treeView со скроллом*/
    .dx-accordion-item {
      overflow: hidden;
      display: flex;
      flex-direction: column;

      .dx-accordion-item-title {
        flex: 0 0 auto;
      }

      .dx-accordion-item-body {
        &.templates-wrapper {
          flex: 1 1 0;
          min-height: 0;
          display: flex;
          flex-direction: column;

          .toolbar {
            flex: 0 0 auto;
            background-color: #f7f7f7;
            margin-bottom: 5px;
          }
        }
      }
    }
  }
}
</style>
