export const SET_CART_PRODUCTS = 'SET_CART_PRODUCTS';
export const SET_CART_PRODUCTS_COUNT = 'SET_CART_PRODUCTS_COUNT';

export default {
  SET_CART_PRODUCTS(state, { products, saveToLocalStorage }) {
    if (saveToLocalStorage) {
      localStorage.setItem('cartProducts', JSON.stringify(products));
    }
    state.cartProducts = products;
  },

  SET_CART_PRODUCTS_COUNT(state, { count, saveToLocalStorage }) {
    if (saveToLocalStorage) {
      localStorage.setItem('cartProductsCount', count);
    }
    state.cartProductsCount = count;
  }
};
