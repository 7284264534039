//Локальные названия мутаций
const PREFIX = 'DataSet';
export const SET_REFRESHING = `${PREFIX}/setVisible`;
export const SET_LOADED_DATA = `${PREFIX}/setLoadedData`;
export const SET_DATA_FIELDS = `${PREFIX}/setDataFields`;
export const UPDATE_DATA_FIELD = `${PREFIX}/updateDataField`;
export const DELETE_DATA_FIELD = `${PREFIX}/deleteDataField`;
export const SET_FILTERS = `${PREFIX}/setFilters`;
export const DELETE_FILTER = `${PREFIX}/deleteFilters`;
export const INSERT_FILTER = `${PREFIX}/insertFilters`;

export default {
  [SET_REFRESHING](state, { componentId, isRefreshing }) {
    const options = state.componentOptions[componentId];
    options.isRefreshing = isRefreshing;
  },

  [SET_LOADED_DATA](state, { componentId, loadedData }) {
    const options = state.componentOptions[componentId];
    options.loadedData = loadedData;
  },

  [SET_DATA_FIELDS](state, { componentId, dataFields }) {
    const options = state.componentOptions[componentId];
    options.dataFields = dataFields;
  },

  [UPDATE_DATA_FIELD](state, { componentId, fields }) {
    const options = state.componentOptions[componentId];
    if (!fields) {
      return;
    }
    for (const dataField of fields) {
      const dataFieldIndex = options.dataFields.findIndex((field) => field.id === dataField.id);
      if (dataFieldIndex === -1) {
        options.dataFields.push(dataField);
      } else {
        options.dataFields.splice(dataFieldIndex, 1, dataField);
      }
    }
    options.dataFields.sort((dataField1, dataField2) => dataField1.pos - dataField2.pos);
  },

  [DELETE_DATA_FIELD](state, { componentId, fieldIds }) {
    const options = state.componentOptions[componentId];
    for (const fieldId of fieldIds) {
      const dataFieldIndex = options.dataFields.findIndex((field) => field.id === fieldId);
      if (dataFieldIndex >= 0) {
        options.dataFields.splice(dataFieldIndex, 1);
      }
    }
  },

  [SET_FILTERS](state, { componentId, filters }) {
    const options = state.componentOptions[componentId];
    options.filters = filters;
  },

  [DELETE_FILTER](state, { componentId, filterId }) {
    const options = state.componentOptions[componentId];
    options.filters = options.filters.filter((filterItem) => filterItem.id !== filterId);
  },

  [INSERT_FILTER](state, { componentId, filter }) {
    const options = state.componentOptions[componentId];
    options.filters.push(filter);
  }
};
