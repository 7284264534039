//TODO: разобраться с этим router
import router from '/src/router';
import { jsonRPC } from '@/api/api';
import { getReportUrl } from '@/utils/common';
import ComponentStore from '@/models/common/ComponentStore';
import dxLoadPanel from 'devextreme/ui/load_panel';
import dxDataGrid from 'devextreme/ui/data_grid';
import dxPopup from 'devextreme/ui/popup';
import { alert as alertDialog } from 'devextreme/ui/dialog';

export default {
  /**Действие группировки других действий в конструкторе */
  async actionGroup() {
    return Promise.resolve();
  },

  setPageUrl(param) {
    let query = { ...param };
    delete query.url;

    //TODO: Router не тот! У него нет корневого компонента App, придумать, как получить доступ к нужному Router
    router.push({
      path: param.url,
      ...query
    });
  },

  async openLink(param) {
    let url = param.url || '';
    let paramStr = '';
    delete param.url;
    for (let key in param) {
      paramStr += key + '=' + encodeURIComponent(param[key]) + '&';
    }
    if (paramStr !== '') {
      url = url + '?' + paramStr.slice(0, -1);
    }

    let win = window.open(url, '_blank');
    win.focus();
  },

  /**
   * Действие открывает url вновой вкладке, вхождения {paramName} заменяется на значение
   * @param {*} param0
   */
  openUrl({ url, ...param }) {
    let parsedUrl = url;

    Object.keys(param || {}).forEach((key) => {
      const regex = new RegExp(`{${key}}`, 'g');
      parsedUrl = url.replace(regex, param[key]);
    });

    let win = window.open(parsedUrl, '_blank');
    win.focus();

    return Promise.resolve();
  },

  async setConstructorMode() {
    router.push('/constructor/' + router.currentRoute.params.childUrl);
  },

  postRequest({
    url,
    credentials = 'same-origin',
    mode = 'cors',
    cache = 'no-cache',
    headers = { 'Content-Type': 'application/json;charset=utf-8' },
    use_token = false,
    hide_loader = true,
    ...param
  }) {
    let postRequestLoadPanel = null;
    let $postRequestPopups = null;

    if (!hide_loader) {
      $postRequestPopups = document.createElement('div');
      $postRequestPopups.setAttribute('class', 'server-operation-popups');
      document.body.prepend($postRequestPopups);
      const $postRequestLoadPanel = document.createElement('div');
      $postRequestPopups.prepend($postRequestLoadPanel);
      postRequestLoadPanel = new dxLoadPanel($postRequestLoadPanel, {
        shadingColor: 'rgba(255, 255, 255, 0.8)',
        visible: false,
        showIndicator: true,
        showPane: true,
        shading: true,
        closeOnOutsideClick: false,
        position: {
          of: document.body
        }
      });
      postRequestLoadPanel.show();
    }

    const fetchOptions = {
      method: 'POST',
      credentials,
      mode,
      cache,
      headers,
      body: JSON.stringify(param)
    };

    if (use_token) {
      const token = localStorage.getItem('token');
      if (token) {
        fetchOptions.headers.Authorization = `Bearer ${token}`;
      }
    }

    return fetch(url, fetchOptions).then((response) => {
      if (postRequestLoadPanel) {
        postRequestLoadPanel.hide();
        postRequestLoadPanel.dispose();
        $postRequestPopups.remove();
      }

      if (!response.ok) {
        throw response;
      }
      return response.json();
    });
  },

  getRequest({
    url,
    credentials = 'same-origin',
    mode = 'cors',
    cache = 'no-cache',
    headers = { 'Content-Type': 'application/json;charset=utf-8' },
    use_token = false,
    ...param
  }) {
    const fetchOptions = {
      method: 'GET',
      credentials,
      mode,
      cache,
      headers
    };

    if (use_token) {
      const token = localStorage.getItem('token');
      if (token) {
        fetchOptions.headers.Authorization = `Bearer ${token}`;
      }
    }
    const paramStr = Object.entries(param)
      .map(([key, value]) => `${key}=${value}`)
      .join('&');
    const requestUrl = `${url}?${paramStr}`;
    return fetch(requestUrl, fetchOptions).then((response) => {
      if (!response.ok) {
        throw response;
      }
      return response.json();
    });
  },

  updateSession(params = {}) {
    return jsonRPC(process.env.VUE_APP_UPDATE_SESSION, 'NULL.NULL.UPDATE_SESSION', params);
  },

  async setLocalStorage(param) {
    localStorage.setItem(param.field, param.value);
  },

  async reloadComponent(param, context) {
    if (!param.componentId) {
      return;
    }

    return context.dispatch('config/loadComponent', param);
  },
  async logout(context) {
    context.dispatch('session/logout');
  },
  async getUserData(context) {
    return context.dispatch('session/getUserData');
  },
  async callComponentAction({ componentId, actionName, ...param }) {
    return ComponentStore.getComponent(componentId).then((actionComponentInstance) => {
      return actionComponentInstance[actionName](param);
    });
  },
  async serverOperationAction({ result_caption_field, result_value_field, hide_loader, hide_result, width, height, service_name, ...param }) {
    const method = service_name || 'getData';
    let serverOperationLoadPanel = null;
    let serverOperationResultPopup = null;
    let serverOperationResultGrid = null;
    let $serverOperationPopups = null;

    if (!hide_loader || !hide_result) {
      $serverOperationPopups = document.createElement('div');
      $serverOperationPopups.setAttribute('class', 'server-operation-popups');
      document.body.prepend($serverOperationPopups);
    }

    if (!hide_loader) {
      const $serverOperationLoadPanel = document.createElement('div');
      $serverOperationPopups.prepend($serverOperationLoadPanel);
      serverOperationLoadPanel = new dxLoadPanel($serverOperationLoadPanel, {
        shadingColor: 'rgba(255, 255, 255, 0.8)',
        visible: false,
        showIndicator: true,
        showPane: true,
        shading: true,
        closeOnOutsideClick: false,
        message: 'Операция выполняется. Пожалуйста подождите.',
        position: {
          of: document.body
        }
      });
      serverOperationLoadPanel.show();
    }

    if (!param._config_dataset) {
      window.console.warn('Не указан источник данных');
      if (serverOperationLoadPanel) {
        serverOperationLoadPanel.hide();
        serverOperationLoadPanel.dispose();
      }
      return;
    }

    if (!hide_result) {
      width = (width ? parseInt(width) : null) || document.body.clientWidth * 0.7;
      height = (height ? parseInt(height) : null) || document.body.clientHeight * 0.7;

      const $serverOperationResultPopup = document.createElement('div');
      $serverOperationResultPopup.setAttribute('id', 'serverOperationResultPopup');
      $serverOperationPopups.prepend($serverOperationResultPopup);
      const title = title || 'Результат операции';
      serverOperationResultPopup = new dxPopup($serverOperationResultPopup, {
        showTitle: true,
        showCloseButton: false,
        shading: true,
        title: title,
        width: width,
        height: height,
        toolbarItems: [
          {
            toolbar: 'bottom',
            location: 'center',
            widget: 'dxButton',
            options: {
              text: 'Закрыть',
              width: '100px',
              onClick: () => {
                serverOperationResultPopup.hide();
                serverOperationResultGrid.dispose();
                serverOperationResultPopup.dispose();
                $serverOperationPopups.remove();
              }
            }
          }
        ]
      });

      const $resultGrid = document.createElement('div');
      $serverOperationResultPopup.querySelector('.dx-popup-content').append($resultGrid);
      serverOperationResultGrid = new dxDataGrid($resultGrid, {
        showColumnHeaders: false,
        columns: [
          {
            dataField: result_caption_field || 'r_caption',
            width: 150
          },
          {
            dataField: result_value_field || 'r_result'
          }
        ]
      });
    }

    return jsonRPC(method, param).then(
      (data) => {
        if (serverOperationLoadPanel) {
          serverOperationLoadPanel.hide();
          serverOperationLoadPanel.dispose();
        }
        if (serverOperationResultGrid) {
          serverOperationResultGrid.option('dataSource', data);
          serverOperationResultPopup.show();
        }
        if (hide_result && $serverOperationPopups) {
          $serverOperationPopups.remove();
        }
        return data;
      },
      (error) => {
        window.console.warn('error: ', error);
        if (serverOperationLoadPanel) {
          serverOperationLoadPanel.hide();
          serverOperationLoadPanel.dispose();
        }
        alertDialog('В процессе работы возникли ошибки.', 'Ошибки');
        if ($serverOperationPopups) {
          $serverOperationPopups.remove();
        }
        return { ...error, error: true };
      }
    );
  },
  async showReport({ _config_url = '/Report_Viewer', name, report_id, ...param }) {
    // Если name передан
    if (!!name) {
      reportCall({ paramName: 'params', _config_url, name, report_id, ...param });
    } else if (!!report_id) {
      // Если name не передан, получаем его по report_id
      // Это необходимо тк некоторые отчеты зависят от территории (ОКТМО) и по report_id мы получаем name отчета с учетом территории пользователя
      const [{ name: nameById }] = await jsonRPC('getData', {
        report_id,
        _config_dataset: 'CONFIG.DSREPORT_ACT_IDS'
      });

      if (!nameById) return;

      reportCall({ paramName: 'params', _config_url, name: nameById, ...param });
    }
  },
  async showDesigner({ _config_url = '/Report_Designer', name, report_id, ...param }) {
    // Если name передан
    if (!!name) {
      reportCall({ paramName: 'param', _config_url, name, ...param });
    } else if (!!report_id) {
      // Если name не передан, получаем его по report_id
      // Это необходимо тк некоторые отчеты зависят от территории (ОКТМО) и по report_id мы получаем name отчета с учетом территории пользователя
      const [{ name: nameById }] = await jsonRPC('getData', {
        report_id,
        _config_dataset: 'CONFIG.DSREPORT_ACT_IDS'
      });

      if (!nameById) return;

      reportCall({ paramName: 'param', _config_url, name: nameById, ...param });
    }
  },

  /**
   * Действие перезагружает страницу
   */
  reloadPage() {
    window.location.reload();
  },

  removeMassReportCnums() {
    if (localStorage.getItem('massReportCnums') !== null) {
      localStorage.removeItem('massReportCnums');
    }
  }
};

function reportCall({ paramName, _config_url, name, ...param }) {
  const url = getReportUrl({ paramName, _config_url, name, ...param });
  if (url) {
    window.open(url, '_blank');
  }
}
