import getConfigs from './configUtils';
const OPTIONS = [
  'cacheEnabled',
  'twoWayBindingEnabled',
  'cellHintEnabled',
  'allowColumnResizing',
  'columnResizingMode',
  'columnMinWidth',
  'columnAutoWidth',
  'columnChooser',
  'columnFixing',
  'selection',
  'hoverStateEnabled',
  'export',
  'editing',
  'highlightChanges',
  'showBorders',
  'showColumnLines',
  'showRowLines',
  'showColumnHeaders',
  'filterPanel',
  'filterRow',
  'headerFilter',
  'sorting',
  'searchPanel',
  'grouping',
  'groupPanel',
  'loadPanel',
  'rowAlternationEnabled',
  'rowDragging',
  'scrolling',
  'pager',
  'showAllColumns'
];

const CONFIGS_FIELDS = {
  cacheEnabled: {
    name: 'cacheEnabled',
    label: 'Кешировать данные',
    component: 'DxSelectBox',
    options: {
      dataSource: [
        { text: 'Да', value: 'true' },
        { text: 'Нет', value: 'false' }
      ],
      showClearButton: true,
      displayExpr: 'text',
      valueExpr: 'value'
    },
    changeEvent: 'optionChanged'
  },
  twoWayBindingEnabled: {
    name: 'twoWayBindingEnabled',
    label: 'Двусторонняя привязка данных',
    component: 'DxSelectBox',
    options: {
      dataSource: [
        { text: 'Да', value: 'true' },
        { text: 'Нет', value: 'false' }
      ],
      showClearButton: true,
      displayExpr: 'text',
      valueExpr: 'value'
    },
    changeEvent: 'optionChanged'
  },
  cellHintEnabled: {
    name: 'cellHintEnabled',
    label: 'Показывать подсказку в ячейках',
    component: 'DxSelectBox',
    options: {
      dataSource: [
        { text: 'Да', value: 'true' },
        { text: 'Нет', value: 'false' }
      ],
      showClearButton: true,
      displayExpr: 'text',
      valueExpr: 'value'
    },
    changeEvent: 'optionChanged'
  },
  allowColumnResizing: {
    name: 'allowColumnResizing',
    label: 'Изменять размер столбцов',
    component: 'dxSelectBox',
    options: {
      dataSource: [
        { text: 'Да', value: 'true' },
        { text: 'Нет', value: 'false' }
      ],
      showClearButton: true,
      displayExpr: 'text',
      valueExpr: 'value'
    },
    changeEvent: 'optionChanged'
  },
  columnResizingMode: {
    name: 'columnResizingMode',
    label: 'Режим изменения размера колонок',
    component: 'DxSelectBox',
    options: {
      dataSource: [
        { text: 'Изменять размер соседней колонки', value: 'nextColumn' },
        { text: 'Изменять размер виджета', value: 'widget' }
      ],
      showClearButton: true,
      displayExpr: 'text',
      valueExpr: 'value'
    },
    changeEvent: 'valueChanged'
  },
  columnMinWidth: {
    name: 'columnMinWidth',
    label: 'Минимальная ширина столбца',
    component: 'DxNumberBox',
    options: {
      showClearButton: true
    },
    changeEvent: 'valueChanged'
  },
  columnAutoWidth: {
    name: 'columnAutoWidth',
    label: 'Авторесайз столбцов',
    component: 'DxSelectBox',
    options: {
      dataSource: [
        { text: 'Да', value: 'true' },
        { text: 'Нет', value: 'false' }
      ],
      showClearButton: true,
      displayExpr: 'text',
      valueExpr: 'value'
    },
    changeEvent: 'optionChanged'
  },
  columnChooser: {
    name: 'columnChooser',
    label: 'Средство выбора столбцов',
    component: 'FieldsBox',
    options: {
      fields: ['enabled', 'title', 'allowSearch', 'modeColumnChooser'],
      fieldName: 'Средство выбора столбцов'
    },
    changeEvent: 'change'
  },
  enabled: {
    name: 'enabled',
    label: 'Включить',
    component: 'DxSelectBox',
    options: {
      dataSource: [
        { text: 'Да', value: 'true' },
        { text: 'Нет', value: 'false' }
      ],
      showClearButton: true,
      displayExpr: 'text',
      valueExpr: 'value'
    },
    changeEvent: 'optionChanged'
  },
  title: {
    name: 'title',
    label: 'Заголовок',
    component: 'DxTextBox',
    options: {
      showClearButton: true
    },
    changeEvent: 'valueChanged'
  },
  allowSearch: {
    name: 'allowSearch',
    label: 'Поиск',
    component: 'DxSelectBox',
    options: {
      dataSource: [
        { text: 'Да', value: 'true' },
        { text: 'Нет', value: 'false' }
      ],
      showClearButton: true,
      displayExpr: 'text',
      valueExpr: 'value'
    },
    changeEvent: 'optionChanged'
  },
  modeColumnChooser: {
    name: 'mode',
    label: 'Режим выбора',
    component: 'DxSelectBox',
    options: {
      dataSource: [
        { text: 'Перетаскивание', value: 'dragAndDrop' },
        { text: 'Выбор', value: 'select' }
      ],
      showClearButton: true,
      displayExpr: 'text',
      valueExpr: 'value'
    },
    changeEvent: 'valueChanged'
  },
  columnFixing: {
    name: 'columnFixing',
    label: 'Средство редактирования столбцов',
    component: 'FieldsBox',
    options: {
      fields: ['enabled'],
      fieldName: 'Средство редактирования столбцов'
    },
    changeEvent: 'change'
  },
  selection: {
    name: 'selection',
    label: 'Выделение',
    component: 'FieldsBox',
    options: {
      fields: ['modeSelection', 'allowSelectAll', 'selectAllMode', 'showCheckBoxesMode'],
      fieldName: 'Выделение'
    },
    changeEvent: 'change'
  },
  modeSelection: {
    name: 'mode',
    label: 'Режим выбора',
    component: 'DxSelectBox',
    options: {
      dataSource: [
        { text: 'Множественный выбор', value: 'multiple' },
        { text: 'Одиночный выбор', value: 'single' },
        { text: 'Запретить выбор', value: 'none' }
      ],
      showClearButton: true,
      displayExpr: 'text',
      valueExpr: 'value'
    },
    changeEvent: 'valueChanged'
  },
  allowSelectAll: {
    name: 'allowSelectAll',
    label: 'Выбирать все строки',
    component: 'DxSelectBox',
    options: {
      dataSource: [
        { text: 'Да', value: 'true' },
        { text: 'Нет', value: 'false' }
      ],
      showClearButton: true,
      displayExpr: 'text',
      valueExpr: 'value'
    },
    changeEvent: 'optionChanged'
  },
  selectAllMode: {
    name: 'selectAllMode',
    label: 'Режим выбора всех строк',
    component: 'DxSelectBox',
    options: {
      dataSource: [
        { text: 'На всех страницах', value: 'allPages' },
        { text: 'На текущей странице', value: 'page' }
      ],
      showClearButton: true,
      displayExpr: 'text',
      valueExpr: 'value'
    },
    changeEvent: 'valueChanged'
  },
  showCheckBoxesMode: {
    name: 'showCheckBoxesMode',
    label: 'Отображение флажков выбора',
    component: 'DxSelectBox',
    options: {
      dataSource: [
        { text: 'Всегда', value: 'always' },
        { text: 'По клику', value: 'onClick' },
        { text: 'По длительному нажатию', value: 'onLongTap' },
        { text: 'Никогда', value: 'none' }
      ],
      showClearButton: true,
      displayExpr: 'text',
      valueExpr: 'value'
    },
    changeEvent: 'valueChanged'
  },
  hoverStateEnabled: {
    name: 'hoverStateEnabled',
    label: 'Выделять ячейки при наведении',
    component: 'DxSelectBox',
    options: {
      dataSource: [
        { text: 'Да', value: 'true' },
        { text: 'Нет', value: 'false' }
      ],
      showClearButton: true,
      displayExpr: 'text',
      valueExpr: 'value'
    },
    changeEvent: 'optionChanged'
  },
  export: {
    name: 'export',
    label: 'Экспорт',
    component: 'FieldsBox',
    options: {
      fields: ['enabled', 'allowExportSelectedData', 'excelFilterEnabled', 'excelWrapTextEnabled', 'fileName', 'ignoreExcelErrors'],
      fieldName: 'Экспорт'
    },
    changeEvent: 'change'
  },
  allowExportSelectedData: {
    name: 'allowExportSelectedData',
    label: 'Экспортировать выбранные строки',
    component: 'DxSelectBox',
    options: {
      dataSource: [
        { text: 'Да', value: 'true' },
        { text: 'Нет', value: 'false' }
      ],
      showClearButton: true,
      displayExpr: 'text',
      valueExpr: 'value'
    },
    changeEvent: 'optionChanged'
  },
  excelFilterEnabled: {
    name: 'excelFilterEnabled',
    label: 'Фильтрация',
    component: 'DxSelectBox',
    options: {
      dataSource: [
        { text: 'Да', value: 'true' },
        { text: 'Нет', value: 'false' }
      ],
      showClearButton: true,
      displayExpr: 'text',
      valueExpr: 'value'
    },
    changeEvent: 'optionChanged'
  },
  excelWrapTextEnabled: {
    name: 'excelWrapTextEnabled',
    label: 'Перенос текста',
    component: 'DxSelectBox',
    options: {
      dataSource: [
        { text: 'Да', value: 'true' },
        { text: 'Нет', value: 'false' }
      ],
      showClearButton: true,
      displayExpr: 'text',
      valueExpr: 'value'
    },
    changeEvent: 'optionChanged'
  },
  fileName: {
    name: 'fileName',
    label: 'Имя файла',
    component: 'DxTextBox',
    options: {
      showClearButton: true
    },
    changeEvent: 'valueChanged'
  },
  ignoreExcelErrors: {
    name: 'ignoreExcelErrors',
    label: 'Скрывать ошибки',
    component: 'DxSelectBox',
    options: {
      dataSource: [
        { text: 'Да', value: 'true' },
        { text: 'Нет', value: 'false' }
      ],
      showClearButton: true,
      displayExpr: 'text',
      valueExpr: 'value'
    },
    changeEvent: 'optionChanged'
  },
  editing: {
    name: 'editing',
    label: 'Параметры редактирования',
    component: 'FieldsBox',
    options: {
      fields: ['modeEditing', 'startEditAction', 'allowAdding', 'allowDeleting', 'selectTextOnEditStart', 'useIcons'],
      fieldName: 'Параметры редактирования'
    },
    changeEvent: 'change'
  },
  modeEditing: {
    name: 'mode',
    label: 'Режим редактирования',
    component: 'DxSelectBox',
    options: {
      dataSource: [
        { text: 'Внутри строки', value: 'row' },
        { text: 'Группой', value: 'batch' },
        { text: 'Внутри ячейки', value: 'cell' },
        { text: 'Внутри формы', value: 'form' },
        { text: 'Внутри попапа', value: 'popup' }
      ],
      showClearButton: true,
      displayExpr: 'text',
      valueExpr: 'value'
    },
    changeEvent: 'optionChanged'
  },
  startEditAction: {
    name: 'startEditAction',
    label: 'Переход в режим редактирования',
    component: 'DxSelectBox',
    options: {
      dataSource: [
        { text: 'По клику', value: 'click' },
        { text: 'По двойному клику', value: 'dblClick' }
      ],
      showClearButton: true,
      displayExpr: 'text',
      valueExpr: 'value'
    },
    changeEvent: 'optionChanged'
  },
  allowAdding: {
    name: 'allowAdding',
    label: 'Добавлять строки',
    component: 'DxSelectBox',
    options: {
      dataSource: [
        { text: 'Да', value: 'true' },
        { text: 'Нет', value: 'false' }
      ],
      showClearButton: true,
      displayExpr: 'text',
      valueExpr: 'value'
    },
    changeEvent: 'optionChanged'
  },
  allowDeleting: {
    name: 'allowDeleting',
    label: 'Удалять строки',
    component: 'DxSelectBox',
    options: {
      dataSource: [
        { text: 'Да', value: 'true' },
        { text: 'Нет', value: 'false' }
      ],
      showClearButton: true,
      displayExpr: 'text',
      valueExpr: 'value'
    },
    changeEvent: 'optionChanged'
  },
  selectTextOnEditStart: {
    name: 'selectTextOnEditStart',
    label: 'Выделять текст',
    component: 'DxSelectBox',
    options: {
      dataSource: [
        { text: 'Да', value: 'true' },
        { text: 'Нет', value: 'false' }
      ],
      showClearButton: true,
      displayExpr: 'text',
      valueExpr: 'value'
    },
    changeEvent: 'optionChanged'
  },
  useIcons: {
    name: 'useIcons',
    label: 'Использовать иконки',
    component: 'DxSelectBox',
    options: {
      dataSource: [
        { text: 'Да', value: 'true' },
        { text: 'Нет', value: 'false' }
      ],
      showClearButton: true,
      displayExpr: 'text',
      valueExpr: 'value'
    },
    changeEvent: 'optionChanged'
  },
  highlightChanges: {
    name: 'highlightChanges',
    label: 'Выделять отредактированные ячейки',
    component: 'DxSelectBox',
    options: {
      dataSource: [
        { text: 'Да', value: 'true' },
        { text: 'Нет', value: 'false' }
      ],
      showClearButton: true,
      displayExpr: 'text',
      valueExpr: 'value'
    },
    changeEvent: 'optionChanged'
  },
  showBorders: {
    name: 'showBorders',
    label: 'Граница виджета',
    component: 'DxSelectBox',
    options: {
      dataSource: [
        { text: 'Да', value: 'true' },
        { text: 'Нет', value: 'false' }
      ],
      showClearButton: true,
      displayExpr: 'text',
      valueExpr: 'value'
    },
    changeEvent: 'optionChanged'
  },
  showColumnLines: {
    name: 'showColumnLines',
    label: 'Граница между столбцами',
    component: 'DxSelectBox',
    options: {
      dataSource: [
        { text: 'Да', value: 'true' },
        { text: 'Нет', value: 'false' }
      ],
      showClearButton: true,
      displayExpr: 'text',
      valueExpr: 'value'
    },
    changeEvent: 'optionChanged'
  },
  showRowLines: {
    name: 'showRowLines',
    label: 'Граница между строками',
    component: 'DxSelectBox',
    options: {
      dataSource: [
        { text: 'Да', value: 'true' },
        { text: 'Нет', value: 'false' }
      ],
      showClearButton: true,
      displayExpr: 'text',
      valueExpr: 'value'
    },
    changeEvent: 'optionChanged'
  },
  showColumnHeaders: {
    name: 'showColumnHeaders',
    label: 'Заголовки столбцов',
    component: 'DxSelectBox',
    options: {
      dataSource: [
        { text: 'Да', value: 'true' },
        { text: 'Нет', value: 'false' }
      ],
      showClearButton: true,
      displayExpr: 'text',
      valueExpr: 'value'
    },
    changeEvent: 'optionChanged'
  },
  filterPanel: {
    name: 'filterPanel',
    label: 'Панель фильтра',
    component: 'FieldsBox',
    options: {
      fields: ['visible'],
      fieldName: 'Панель фильтра'
    },
    changeEvent: 'change'
  },
  visible: {
    name: 'visible',
    label: 'Отобразить',
    component: 'DxSelectBox',
    options: {
      dataSource: [
        { text: 'Да', value: 'true' },
        { text: 'Нет', value: 'false' }
      ],
      showClearButton: true,
      displayExpr: 'text',
      valueExpr: 'value'
    },
    changeEvent: 'optionChanged'
  },
  filterRow: {
    name: 'filterRow',
    label: 'Строка поиска в столбце',
    component: 'FieldsBox',
    options: {
      fields: ['visible', 'applyFilter', 'showOperationChooser'],
      fieldName: 'Строка поиска в столбце'
    },
    changeEvent: 'change'
  },
  applyFilter: {
    name: 'applyFilter',
    label: 'Режим поиска',
    component: 'DxSelectBox',
    options: {
      dataSource: [
        { text: 'По нажатию на кнопку', value: 'onClick' },
        { text: 'Автоматически', value: 'auto' }
      ],
      showClearButton: true,
      displayExpr: 'text',
      valueExpr: 'value'
    },
    changeEvent: 'optionChanged'
  },
  showOperationChooser: {
    name: 'showOperationChooser',
    label: 'Иконка поиска',
    component: 'DxSelectBox',
    options: {
      dataSource: [
        { text: 'Да', value: 'true' },
        { text: 'Нет', value: 'false' }
      ],
      showClearButton: true,
      displayExpr: 'text',
      valueExpr: 'value'
    },
    changeEvent: 'optionChanged'
  },
  headerFilter: {
    name: 'headerFilter',
    label: 'Фильтр заголовка',
    component: 'FieldsBox',
    options: {
      fields: ['visible', 'allowSearch'],
      fieldName: 'Фильтр заголовка'
    },
    changeEvent: 'change'
  },
  sorting: {
    name: 'sorting',
    label: 'Сортировка',
    component: 'FieldsBox',
    options: {
      fields: ['modeSorting', 'showSortIndexes'],
      fieldName: 'Сортировка'
    },
    changeEvent: 'change'
  },
  modeSorting: {
    name: 'mode',
    label: 'Режим сортировки',
    component: 'DxSelectBox',
    options: {
      dataSource: [
        { text: 'Сортировка по нескольким столбцам', value: 'multiple' },
        { text: 'Сортировка по одному столбцу', value: 'single' },
        { text: 'Запретить сортировку', value: 'none' }
      ],
      showClearButton: true,
      displayExpr: 'text',
      valueExpr: 'value'
    },
    changeEvent: 'valueChanged'
  },
  showSortIndexes: {
    name: 'showSortIndexes',
    label: 'Показать приоритет столбцов',
    component: 'DxSelectBox',
    options: {
      dataSource: [
        { text: 'Да', value: 'true' },
        { text: 'Нет', value: 'false' }
      ],
      showClearButton: true,
      displayExpr: 'text',
      valueExpr: 'value'
    },
    changeEvent: 'optionChanged'
  },
  searchPanel: {
    name: 'searchPanel',
    label: 'Панель поиска',
    component: 'FieldsBox',
    options: {
      fields: ['visible', 'placeholder', 'highlightSearchText', 'highlightCaseSensitive', 'searchVisibleColumnsOnly'],
      fieldName: 'Панель поиска'
    },
    changeEvent: 'change'
  },
  placeholder: {
    name: 'placeholder',
    label: 'Подсказка',
    component: 'DxTextBox',
    options: {
      showClearButton: true
    },
    changeEvent: 'valueChanged'
  },
  highlightSearchText: {
    name: 'highlightSearchText',
    label: 'Выделять найденный текст',
    component: 'DxSelectBox',
    options: {
      dataSource: [
        { text: 'Да', value: 'true' },
        { text: 'Нет', value: 'false' }
      ],
      showClearButton: true,
      displayExpr: 'text',
      valueExpr: 'value'
    },
    changeEvent: 'optionChanged'
  },
  highlightCaseSensitive: {
    name: 'highlightCaseSensitive',
    label: 'Учитывать регистр при выделении',
    component: 'DxSelectBox',
    options: {
      dataSource: [
        { text: 'Да', value: 'true' },
        { text: 'Нет', value: 'false' }
      ],
      showClearButton: true,
      displayExpr: 'text',
      valueExpr: 'value'
    },
    changeEvent: 'optionChanged'
  },
  searchVisibleColumnsOnly: {
    name: 'searchVisibleColumnsOnly',
    label: 'Поиск только в видимых столбцах',
    component: 'DxSelectBox',
    options: {
      dataSource: [
        { text: 'Да', value: 'true' },
        { text: 'Нет', value: 'false' }
      ],
      showClearButton: true,
      displayExpr: 'text',
      valueExpr: 'value'
    },
    changeEvent: 'optionChanged'
  },
  grouping: {
    name: 'grouping',
    label: 'Группировка',
    component: 'FieldsBox',
    options: {
      fields: ['allowCollapsing', 'contextMenuEnabled'],
      fieldName: 'Группировка'
    },
    changeEvent: 'change'
  },
  allowCollapsing: {
    name: 'allowCollapsing',
    label: 'Сворачивать группы',
    component: 'DxSelectBox',
    options: {
      dataSource: [
        { text: 'Да', value: 'true' },
        { text: 'Нет', value: 'false' }
      ],
      showClearButton: true,
      displayExpr: 'text',
      valueExpr: 'value'
    },
    changeEvent: 'optionChanged'
  },
  contextMenuEnabled: {
    name: 'contextMenuEnabled',
    label: 'Группировка в контекстном меню',
    component: 'DxSelectBox',
    options: {
      dataSource: [
        { text: 'Да', value: 'true' },
        { text: 'Нет', value: 'false' }
      ],
      showClearButton: true,
      displayExpr: 'text',
      valueExpr: 'value'
    },
    changeEvent: 'optionChanged'
  },
  groupPanel: {
    name: 'groupPanel',
    label: 'Панель группировки',
    component: 'FieldsBox',
    options: {
      fields: ['visible', 'allowColumnDragging'],
      fieldName: 'Панель группировки'
    },
    changeEvent: 'change'
  },
  allowColumnDragging: {
    name: 'allowColumnDragging',
    label: 'Перетаскивать столбцы',
    component: 'DxSelectBox',
    options: {
      dataSource: [
        { text: 'Да', value: 'true' },
        { text: 'Нет', value: 'false' }
      ],
      showClearButton: true,
      displayExpr: 'text',
      valueExpr: 'value'
    },
    changeEvent: 'optionChanged'
  },
  loadPanel: {
    name: 'loadPanel',
    label: 'Панель загрузки',
    component: 'FieldsBox',
    options: {
      fields: ['enabled', 'showIndicator', 'indicatorSrc', 'shading', 'shadingColor'],
      fieldName: 'Панель загрузки'
    },
    changeEvent: 'change'
  },
  showIndicator: {
    name: 'showIndicator',
    label: 'Показать индикатор загрузки',
    component: 'DxSelectBox',
    options: {
      dataSource: [
        { text: 'Да', value: 'true' },
        { text: 'Нет', value: 'false' }
      ],
      showClearButton: true,
      displayExpr: 'text',
      valueExpr: 'value'
    },
    changeEvent: 'optionChanged'
  },
  indicatorSrc: {
    name: 'indicatorSrc',
    label: 'URL иконки индикатора',
    component: 'DxTextBox',
    options: {
      showClearButton: true
    },
    changeEvent: 'valueChanged'
  },
  shading: {
    name: 'shading',
    label: 'Тень',
    component: 'DxSelectBox',
    options: {
      dataSource: [
        { text: 'Да', value: 'true' },
        { text: 'Нет', value: 'false' }
      ],
      showClearButton: true,
      displayExpr: 'text',
      valueExpr: 'value'
    },
    changeEvent: 'optionChanged'
  },
  shadingColor: {
    name: 'shadingColor',
    label: 'Цвет тени',
    component: 'DxColorBox',
    options: {
      showClearButton: true,
      editAlphaChannel: true
    },
    changeEvent: 'valueChanged'
  },
  rowAlternationEnabled: {
    name: 'rowAlternationEnabled',
    label: 'Альтернативная раскрасска строк',
    component: 'DxSelectBox',
    options: {
      dataSource: [
        { text: 'Да', value: 'true' },
        { text: 'Нет', value: 'false' }
      ],
      showClearButton: true,
      displayExpr: 'text',
      valueExpr: 'value'
    },
    changeEvent: 'optionChanged'
  },
  rowDragging: {
    name: 'rowDragging',
    label: 'Перетаскивание строк',
    component: 'FieldsBox',
    options: {
      fields: ['allowReordering', 'autoScroll', 'dragDirection', 'dropFeedbackMode', 'group', 'showDragIcons'],
      fieldName: 'Перетаскивание строк'
    },
    changeEvent: 'change'
  },
  allowReordering: {
    name: 'allowReordering',
    label: 'Упорядочивание',
    component: 'DxSelectBox',
    options: {
      dataSource: [
        { text: 'Да', value: 'true' },
        { text: 'Нет', value: 'false' }
      ],
      showClearButton: true,
      displayExpr: 'text',
      valueExpr: 'value'
    },
    changeEvent: 'optionChanged'
  },
  autoScroll: {
    name: 'autoScroll',
    label: 'Автопрокрутка',
    component: 'DxSelectBox',
    options: {
      dataSource: [
        { text: 'Да', value: 'true' },
        { text: 'Нет', value: 'false' }
      ],
      showClearButton: true,
      displayExpr: 'text',
      valueExpr: 'value'
    },
    changeEvent: 'optionChanged'
  },
  dragDirection: {
    name: 'dragDirection',
    label: 'Направления для перетаскивания',
    component: 'DxSelectBox',
    options: {
      dataSource: [
        { text: 'Любое', value: 'both' },
        { text: 'По горизонтали', value: 'horizontal' },
        { text: 'По вертикали', value: 'vertical' }
      ],
      showClearButton: true,
      displayExpr: 'text',
      valueExpr: 'value'
    },
    changeEvent: 'valueChanged'
  },
  dropFeedbackMode: {
    name: 'dropFeedbackMode',
    label: 'Режим перетаскивания',
    component: 'DxSelectBox',
    options: {
      dataSource: [
        { text: 'С анимацией', value: 'push' },
        { text: 'Без анимации', value: 'indicate' }
      ],
      showClearButton: true,
      displayExpr: 'text',
      valueExpr: 'value'
    },
    changeEvent: 'valueChanged'
  },
  group: {
    name: 'group',
    label: 'Группа',
    component: 'DxTextBox',
    options: {
      showClearButton: true
    },
    changeEvent: 'valueChanged'
  },
  showDragIcons: {
    name: 'showDragIcons',
    label: 'Показать иконки',
    component: 'DxSelectBox',
    options: {
      dataSource: [
        { text: 'Да', value: 'true' },
        { text: 'Нет', value: 'false' }
      ],
      showClearButton: true,
      displayExpr: 'text',
      valueExpr: 'value'
    },
    changeEvent: 'optionChanged'
  },
  scrolling: {
    name: 'scrolling',
    label: 'Прокрутка',
    component: 'FieldsBox',
    options: {
      fields: ['modeScrolling', 'scrollByContent', 'scrollByThumb'],
      fieldName: 'Прокрутка'
    },
    changeEvent: 'change'
  },
  modeScrolling: {
    name: 'mode',
    label: 'Режим прокрутки',
    component: 'DxSelectBox',
    options: {
      dataSource: [
        { text: 'Стандартный', value: 'standard' },
        { text: 'Бесконечный', value: 'infinite' },
        { text: 'Виртуальный', value: 'virtual' }
      ],
      showClearButton: true,
      displayExpr: 'text',
      valueExpr: 'value'
    },
    changeEvent: 'valueChanged'
  },
  scrollByContent: {
    name: 'scrollByContent',
    label: 'Прокрутка по содержимому',
    component: 'DxSelectBox',
    options: {
      dataSource: [
        { text: 'Да', value: 'true' },
        { text: 'Нет', value: 'false' }
      ],
      showClearButton: true,
      displayExpr: 'text',
      valueExpr: 'value'
    },
    changeEvent: 'optionChanged'
  },
  scrollByThumb: {
    name: 'scrollByThumb',
    label: 'Прокрутка по полосе прокрутки',
    component: 'DxSelectBox',
    options: {
      dataSource: [
        { text: 'Да', value: 'true' },
        { text: 'Нет', value: 'false' }
      ],
      showClearButton: true,
      displayExpr: 'text',
      valueExpr: 'value'
    },
    changeEvent: 'optionChanged'
  },
  pager: {
    name: 'pager',
    label: 'Пейджер',
    component: 'FieldsBox',
    options: {
      fields: ['visible', 'showInfo', 'infoText', 'showNavigationButtons', 'showPageSizeSelector'],
      fieldName: 'Пейджер'
    },
    changeEvent: 'change'
  },
  showInfo: {
    name: 'showInfo',
    label: 'Показать информацию',
    component: 'DxSelectBox',
    options: {
      dataSource: [
        { text: 'Да', value: 'true' },
        { text: 'Нет', value: 'false' }
      ],
      showClearButton: true,
      displayExpr: 'text',
      valueExpr: 'value'
    },
    changeEvent: 'optionChanged'
  },
  infoText: {
    name: 'infoText',
    label: 'Текст с информацией',
    component: 'DxTextBox',
    options: {
      showClearButton: true
    },
    changeEvent: 'valueChanged'
  },
  showNavigationButtons: {
    name: 'showNavigationButtons',
    label: 'Пагинация',
    component: 'DxSelectBox',
    options: {
      dataSource: [
        { text: 'Да', value: 'true' },
        { text: 'Нет', value: 'false' }
      ],
      showClearButton: true,
      displayExpr: 'text',
      valueExpr: 'value'
    },
    changeEvent: 'optionChanged'
  },
  showPageSizeSelector: {
    name: 'showPageSizeSelector',
    label: 'Выбор размера страницы',
    component: 'DxSelectBox',
    options: {
      dataSource: [
        { text: 'Да', value: 'true' },
        { text: 'Нет', value: 'false' }
      ],
      showClearButton: true,
      displayExpr: 'text',
      valueExpr: 'value'
    },
    changeEvent: 'optionChanged'
  },
  showAllColumns: {
    name: 'showAllColumns',
    label: 'Отобразить все столбцы',
    component: 'DxSelectBox',
    options: {
      dataSource: [
        { text: 'Да', value: 'true' },
        { text: 'Нет', value: 'false' }
      ],
      showClearButton: true,
      displayExpr: 'text',
      valueExpr: 'value'
    },
    changeEvent: 'optionChanged'
  }
};

export default getConfigs(OPTIONS, CONFIGS_FIELDS);
