export const DEVICE_TYPE = [
  { key: 'desktop', name: 'Desktop' },
  { key: 'tablet', name: 'Tablet' },
  { key: 'phone', name: 'Phone' }
];

export const PHONE_MODELS = [
  {
    name: 'Samsung',
    items: [
      {
        name: 'Galaxy S22 Ultra 5g (1440 x 3088)',
        height: 3088,
        width: 1440
      },
      {
        name: 'Galaxy A52s (1080 x 2400)',
        height: 2400,
        width: 1080
      },
      {
        name: 'Galaxy S22 5g (1080 x 2340)',
        height: 2340,
        width: 1080
      }
    ]
  },
  {
    name: 'Apple',
    items: [
      {
        name: 'iPhone SE (750 x 1334)',
        height: 1334,
        width: 750
      },
      {
        name: 'iPhone 13 Pro Max (1284 x 2778)',
        height: 2778,
        width: 1284
      },
      {
        name: 'iPhone 13 (1170 x 2532)',
        height: 2532,
        width: 1170
      }
    ]
  },
  {
    name: 'Honor',
    items: [
      {
        name: 'X8 (1080 x 2388)',
        height: 2388,
        width: 1080
      }
    ]
  },
  {
    name: 'Huawey',
    items: [
      {
        name: 'P50 Pro (1228 x 2700)',
        height: 2700,
        width: 1228
      },
      {
        name: 'nova 9 SE (1080 x 2388)',
        height: 2388,
        width: 1080
      }
    ]
  },
  {
    name: 'Lenovo',
    items: [
      {
        name: 'Legion Y90 (1080 x 2460)',
        height: 2460,
        width: 1080
      },
      {
        name: 'Legion Y700 (1600 x 2560)',
        height: 2560,
        width: 1600
      }
    ]
  },
  {
    name: 'LG',
    items: [
      {
        name: 'G8 ThinQ (1440 x 3120)',
        height: 3120,
        width: 1440
      }
    ]
  },
  {
    name: 'Meizu',
    items: [
      {
        name: '18s Pro (1440 x 3200)',
        height: 3200,
        width: 1440
      },
      {
        name: 'M6 (720 x 1280)',
        height: 1280,
        width: 720
      }
    ]
  },
  {
    name: 'Xiaomi',
    items: [
      {
        name: 'Redmi Note 11 Pro+ 5G (1080 x 2400)',
        height: 2400,
        width: 1080
      }
    ]
  }
];
