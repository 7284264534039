<template>
  <!--eslint-disable-->
  <DxScrollView class="options-form">
    <div class="options-form__wrapper">
      <OptionsFormItem
        v-for="(field, index) in fields"
        :key="index"
        v-bind="field"
        :allValues="valueObject"
        :value="valueObject[field.name]"
        @change="onChange"
      />
    </div>
  </DxScrollView>
</template>

<script>
import OptionsFormItem from './OptionsFormItem';
import DxScrollView from 'devextreme-vue/scroll-view';

export default {
  name: 'OptionsForm',
  components: {
    OptionsFormItem,
    DxScrollView
  },
  props: {
    fields: Array,
    valueObject: Object
  },
  data() {
    return {
      hoverField: {
        element: null,
        text: 'text',
        defaultVisible: false
      }
    };
  },
  methods: {
    onChange({ fieldName, fieldValue }) {
      let result = {
        ...this.valueObject,
        [fieldName]: fieldValue
      };

      if (fieldValue === null || fieldValue === undefined) {
        delete result[fieldName];
      }

      this.$emit('change', isObjectEmpty(result) ? null : result);
    }
  }
};

function isObjectEmpty(obj) {
  for (var key in obj) {
    if (obj.hasOwnProperty(key)) {
      return false;
    }
  }
  return true;
}
</script>

<style lang="scss">
.options-form {
  &__wrapper {
    display: flex;
    flex-direction: column;
    background-color: #fff;
  }
  .dx-field {
    display: flex;
    flex: 0 0 auto;
    flex-wrap: wrap;

    & > .dx-field-label {
      float: none;
      width: auto;
      flex: 0 0 170px;
      text-align: left;
      z-index: 1;
    }
    & > .dx-field-value {
      float: none;
      width: auto;
      flex: 1 1 auto;
    }
    &_field-box {
      & > .dx-field-value {
        width: 100% !important;
      }
    }
  }
}
</style>
