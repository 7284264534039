<template>
  <!--eslint-disable-->
  <DxSortable
    class="component-add-sortable"
    v-show="$store.state.uiConstructor.dragging"
    height="100%"
    :allowDropInsideItem="true"
    :allowReordering="false"
    filter=".component-list-item"
    dropFeedbackMode="indicate"
    group="constructor"
  >
    <div
      class="component-list-item"
      :data-component-id="componentId"
      :data-last-item-pos="lastItemPos"
    ></div>
  </DxSortable>
</template>

<script>
import DxSortable from 'devextreme-vue/sortable';

export default {
  components: {
    DxSortable
  },
  props: {
    componentId: null,
    lastItemPos: Number
  }
};
</script>

<style lang="scss">
.component-add-sortable {
  position: absolute;
  z-index: 3;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  .component-list-item {
    width: 100%;
    height: 100%;
  }
}
</style>
