import getConfigs from './configUtils';
const OPTIONS = ['text', 'noDataText'];

const CONFIGS_FIELDS = {
  text: {
    name: 'text',
    label: 'Текст',
    component: 'DxTextBox',
    options: {
      showClearButton: true
    },
    changeEvent: 'valueChanged'
  },
  noDataText: {
    name: 'noDataText',
    label: 'Текст при отсутствии значения поля данных',
    component: 'DxTextBox',
    options: {
      showClearButton: true
    },
    changeEvent: 'valueChanged'
  }
};

export default getConfigs(OPTIONS, CONFIGS_FIELDS);
