<template>
  <!--eslint-disable-->
  <div
    :class="cssClass"
    :style="stylePosition"
  >
    <h3
      class="invisible-component-container__title"
      @mousedown="dragStart"
    >
      <i class="fas fa-eye-slash"></i>
      <span> {{title}}</span>
    </h3>
    <div class="invisible-component-container__body">
      <div
        class="invisible-component-container__inner"
        :class="{'invisible-component-container__inner_collapsed': !isOpen}"
        :style="{height, maxHeight}"
      >
        <SearchBox
          class="invisible-component-container__search"
          :dataList="items"
          :searchExpr="['componentName', 'componentId', 'options.text']"
          @searching="itemsBySearch = $event"
        />
        <div class="invisible-component-container__list">
          <DxScrollView class="invisible-component-container__scroll">
            <div class="invisible-component-container__wrapper">
              <InvisibleComponentGroup
                v-for="(group, index) in itemGroups"
                :key="index"
                class="invisible-component-container__group"
                :items="group.items"
                :groupName="group.key"
                @initialized="calcHeight"
                @updated="calcHeight"
              />
            </div>
          </DxScrollView>
        </div>
      </div>
      <button
        class="invisible-component-container__toggle"
        @click="isOpen = !isOpen"
      >
        <i :class="`fas fa-angle-${isOpen ? `down`: `up`}`"></i>
      </button>
    </div>
  </div>
</template>

<script>
import InvisibleComponentGroup from './InvisibleComponentGroup';
import DxScrollView from 'devextreme-vue/scroll-view';
import SearchBox from '@/components/SearchBox';

const TYPES_ICONS = {
  DataSet: 'fas fa-database',
  ServerSideDataSource: 'fas fa-database',
  PivotGridDataSource: 'fas fa-database',
  VectorMapLayer: 'far fa-map',
  MapboxGroupLayer: 'fas fa-layer-group',
  MapboxLayer: 'fas fa-globe-europe',
  MockDataSet: 'far fa-file',
  TableauDataSet: 'fas fa-cube',
  Popup: 'far fa-window-restore',
  Popover: 'far fa-window-restore',
  ContextMenu: 'far fa-list-alt',
  default: 'fas fa-eye-slash'
};

export default {
  name: 'InvisibleComponentContainer',
  components: {
    InvisibleComponentGroup,
    DxScrollView,
    SearchBox
  },
  props: {
    items: Array,
    title: {
      type: String,
      default: ''
    },
    position: {
      type: String,
      default: 'left top'
    }
  },
  data() {
    return {
      isOpen: false,
      searchString: null,
      itemsBySearch: this.items,
      height: '350px',
      maxHeight: '350px',
      TYPES_ICONS
    };
  },
  computed: {
    cssClass() {
      return {
        'invisible-component-container': true,
        'invisible-component-container_hidden': true //TODO: пока скроем на все время this.isHidden
      };
    },
    stylePosition() {
      const LOC = ['top', 'right', 'bottom', 'left'];
      const location = this.position.split(' ');
      let result = {};
      location.forEach((item) => {
        if (LOC.includes(item)) {
          result[item] = 0;
        }
      });
      return result;
    },
    isHidden() {
      return !this.$store.state.uiConstructor.active || this.items.length === 0;
    },
    itemGroups() {
      let types = new Set();
      this.itemsBySearch.forEach((item) => types.add(item.componentType));
      return Array.from(types).map((type) => ({
        key: type,
        items: this.itemsBySearch.filter((item) => item.componentType === type)
      }));
    }
  },
  methods: {
    dragStart(event) {
      document.addEventListener('mousemove', move);
      document.addEventListener('mouseup', dragEnd);
      const container = this.$el.parentElement;
      const containerBox = container.getBoundingClientRect();
      const popup = this.$el;
      const popupBox = popup.getBoundingClientRect();
      container.appendChild(popup);
      const shift = {
        x: event.clientX - popupBox.left,
        y: event.clientY - popupBox.top
      };
      const limits = {
        top: container.offsetTop,
        right: container.offsetWidth + container.offsetLeft - popup.offsetWidth,
        bottom: container.offsetHeight + container.offsetTop - popup.offsetHeight,
        left: container.offsetLeft
      };
      function move(event) {
        let newLocation = {
          x: limits.left,
          y: limits.top
        };
        const position = {
          x: event.pageX - containerBox.left - shift.x,
          y: event.pageY - containerBox.top - shift.y
        };
        if (position.x > limits.right) {
          newLocation.x = limits.right;
        } else if (position.x > limits.left) {
          newLocation.x = position.x;
        }
        if (position.y > limits.bottom) {
          newLocation.y = limits.bottom;
        } else if (position.y > limits.top) {
          newLocation.y = position.y;
        }
        relocate(newLocation);

        function relocate(newLocation) {
          popup.style.left = newLocation.x + 'px';
          popup.style.top = newLocation.y + 'px';
        }
      }

      function dragEnd() {
        document.removeEventListener('mousemove', move);
        document.removeEventListener('mouseup', dragEnd);
      }
    },
    calcHeight() {
      this.height = `${this.$el.querySelector('.invisible-component-container__wrapper').offsetHeight + 54}px`;
      this.maxHeight = `${this.$el.parentElement.offsetHeight - 50}px`;
    }
  },
  watch: {
    items: function() {
      this.itemsBySearch = this.items;
    }
  },
  updated() {
    this.calcHeight();
  }
};
</script>

<style lang="scss">
.invisible-component-container {
  position: absolute;
  z-index: 4;
  width: 170px;
  border-radius: 6px;
  border: 1px solid #dddddd;

  &__body {
    display: flex;
    flex-direction: column;
  }

  &__inner {
    overflow: hidden;
    min-height: 150px;
    padding: 10px;
    background: rgba(255, 255, 255, 0.7);

    &_collapsed {
      min-height: 0;
      height: 0 !important;
      padding: 0;
    }
  }

  &__list {
    height: calc(100% - 30px);
    background: #ffffff;
    border-radius: 6px;
    border: 1px solid #dddddd;
  }

  &__toggle {
    border: none;
    background: rgba(223, 223, 223, 0.7);
    cursor: pointer;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;

    &:focus {
      outline: none;
    }
  }

  &_hidden {
    height: 0;
    width: 0;
    overflow: hidden;
    border-color: transparent;
  }

  &__title {
    margin: 0;
    padding: 5px 10px;
    font-weight: 400;
    font-size: 12px;
    border-bottom: 1px solid #dddddd;
    cursor: move;
    background: rgba(255, 255, 255, 0.7);
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
  }

  &__search {
    margin-bottom: 5px;
  }
}
</style>
