<template>
  <!--eslint-disable-->
  <div class="resolution-panel">
    <DxSelectBox
      class="resolution-panel__device"
      hint="Выбрать устройство"
      width="77"
      height="26"
      displayExpr="name"
      valueExpr="key"
      :value="resolutionDevice"
      :dataSource="DEVICE_TYPE"
      @valueChanged="changeResolutionDevice($event.value)"
    />
    <DxDropDownBox
      v-if="resolutionDevice=='phone'"
      ref="phoneModelsDDL"
      v-model="deviceModel"
      :dataSource="deviceModelSource"
      valueExpr="name"
      displayExpr="name"
      @opened="onPhoneModelsTreeViewOpened"
    >
      <DxDropDownOptions maxHeight="50vh" />

      <template #content="{ data }">
        <DxTreeView
          ref="phoneModelsTreeView"
          :dataSource="PHONE_MODELS"
          :selectByClick="true"
          dataStructure="tree"
          keyExpr="name"
          selectionMode="single"
          :activeStateEnabled="false"
          :expandNodesRecursive="true"
          :focusStateEnabled="false"
          displayExpr="name"
          @itemClick="onPhoneTreeSelectionChanged"
        />
      </template>
    </DxDropDownBox>
    <div class="resolution-panel__wrap">
      <DxNumberBox
        class="resolution-panel__width"
        width="47"
        height="26"
        :value="resolutionFromResizable.width"
        hint="Ширина"
        name="width"
        :min="0"
        :disabled="isDesktop"
        @valueChanged="changeResolutionValue($event.value, resolutionFromResizable.height)"
      />
      <span>x</span>
      <DxNumberBox
        class="resolution-panel__height"
        width="47"
        height="26"
        :value="resolutionFromResizable.height"
        hint="Высота"
        :min="0"
        :disabled="isDesktop"
        @valueChanged="changeResolutionValue(resolutionFromResizable.width, $event.value)"
      />
    </div>
    <DxButton
      class="resolution-panel__rotate"
      hint="Повернуть устройство"
      stylingMode="text"
      :activeStateEnabled="false"
      :disabled="isDesktop"
      @click="changeResolutionValue(resolutionFromResizable.height, resolutionFromResizable.width)"
      icon="core core-device-rotate"
    >
    </DxButton>
  </div>
</template>

<script>
import DxButton from 'devextreme-vue/button';
import DxTreeView from 'devextreme-vue/tree-view';
import { DxDropDownBox, DxDropDownOptions } from 'devextreme-vue/drop-down-box';
import { DEVICE_TYPE, PHONE_MODELS } from './mobilesConst';

export default {
  name: 'ResolutionPanel',
  components: {
    DxButton,
    DxTreeView,
    DxDropDownBox,
    DxDropDownOptions
  },
  props: {
    resolutionFromResizable: Object,
    maxHeight: Number
  },
  data() {
    return {
      deviceModelSource: PHONE_MODELS[0].items,
      deviceModel: PHONE_MODELS[0].items[0].name,
      DEVICE_TYPE,
      PHONE_MODELS
    };
  },
  computed: {
    isDesktop() {
      return this.resolutionDevice === 'desktop';
    },
    resolutionDevice() {
      return this.$store.getters['uiConstructor/resolutionDevice'];
    }
  },
  watch: {
    deviceModel(val) {
      this.setPhoneScreenSize(val);
    }
  },
  methods: {
    onPhoneTreeSelectionChanged(evt) {
      //Установка отображения в выпадающем списке
      this.deviceModelSource = [evt.itemData];
      this.deviceModel = evt.itemData.name;
      this.$refs.phoneModelsDDL.instance.close();
    },
    setPhoneScreenSize(deviceName) {
      const deviceModelItem = this.deviceModelSource.find((item) => item.name == deviceName);
      //приведение размера к высоте окна
      const h = this.maxHeight;
      const w = (h * deviceModelItem.width) / deviceModelItem.height;
      this.resolutionFromResizable.width = Math.round(w);
      this.resolutionFromResizable.height = Math.round(h);
    },
    onPhoneModelsTreeViewOpened() {
      const tree = this.$refs.phoneModelsTreeView.instance;
      tree.beginUpdate();
      //tree.collapseAll();
      tree.selectItem(this.deviceModel);
      tree.expandItem(this.deviceModel);
      tree.endUpdate();
    },
    // Отправляем родителю событие изменения высоты/ширины
    changeResolutionValue(width, height) {
      this.$emit('changeResolution', {
        width,
        height,
        device: this.resolutionDevice,
        model: this.deviceModel
      });
    },
    // Поменять тип устройства
    changeResolutionDevice(event) {
      if (event === 'desktop') {
        this.$emit('resetSize');
      } else {
        this.setPhoneScreenSize(this.deviceModel);
      }
      this.$store.commit('uiConstructor/setResolutionDevice', event);
      this.$store.commit('config/clearSelection');
    }
  }
};
</script>

<style scoped lang="scss">
.resolution-panel {
  margin: 0 auto;
  display: flex;

  &__wrap {
    display: flex;
    align-items: center;
  }

  &__device {
    margin-right: 15px;
  }

  &__width {
    margin-right: 5px;
  }

  &__height {
    margin-left: 5px;
    margin-right: 10px;
  }

  & span {
    opacity: 0.5;
  }
}
</style>
