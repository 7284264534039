export default class FilterUtils {
  static getDefaultFieldValue(fieldInfo) {
    switch (fieldInfo.fieldtype_name) {
      case 'integer':
      case 'bigint':
      case 'numeric':
        return 0;
      case 'text':
        return '';
      default:
        return null;
    }
  }

  static getUsedAttributes(filter, dataFields) {
    const attrsFound = [];
    this._getUsedAttributesRecursive(filter, dataFields, attrsFound);
    return attrsFound;
  }

  static _getUsedAttributesRecursive(items, dataFields, attrsFound) {
    for (const item of items) {
      if (Array.isArray(item)) {
        this._getUsedAttributesRecursive(item, dataFields, attrsFound);
      } else if (typeof item === 'string') {
        const dataField = dataFields.find((fieldInfo) => fieldInfo.name === item);
        if (dataField) {
          attrsFound.push(dataField);
        }
      }
    }
  }
}
