import { arcgisToGeoJSON } from '@esri/arcgis-to-geojson-utils';
import { DEFAULT_EPSG } from '@/utils/const';

export function identify({ layers, url, arcGisToken, geometry, mapExtent, imageDisplay }) {
  let formatUrl;
  if (url.slice(-1) === '/') {
    formatUrl = url.slice(0, -1);
  } else {
    formatUrl = url;
  }

  const params = {
    geometry,
    tolerance: 6, // размер окрестности вокруг клика (в пикселях)
    mapExtent,
    imageDisplay, // размеры канваса и dpi карты
    f: 'json', // формат получаемых данных,
    layers: `all:${layers.replace('show:', '') || ''}`,
    sr: DEFAULT_EPSG
  };

  if (arcGisToken) {
    params.token = arcGisToken;
  }

  // формирование запроса
  const paramsStr = Object.entries(params)
    .map(([paramName, paramValue]) => `${paramName}=${paramValue}`)
    .join('&');

  const requestUrl = `${formatUrl}/identify?${paramsStr}`;

  return fetch(requestUrl)
    .then((response) => {
      if (!response.ok) {
        throw response;
      }
      return response.json();
    })
    .then((data) => {
      if (data && data.results.length && Object.entries(data.results[0].attributes).length) {
        let geoJson = arcgisToGeoJSON(data.results[0]);
        if (geoJson.properties.OBJECTID) {
          geoJson.properties.id = geoJson.properties.OBJECTID;
        }

        return geoJson;
      }

      return null;
    });
}

export function loadLayers(arcGisUrl, token, useProxy) {
  let requestUrl = `${arcGisUrl.replace(/\/$/, '')}/layers?f=json`;
  if (token) {
    requestUrl += `&token=${token}`;
  }
  return fetch(useProxy ? `${process.env.VUE_APP_PROXY_SERVICE}?url=${requestUrl}` : requestUrl).then((response) => {
    if (!response.ok) {
      throw response;
    }

    return response.json();
  });
}

export function loadLegend(arcGisUrl, token, useProxy) {
  let requestUrl = `${arcGisUrl.replace(/\/$/, '')}/legend?f=json`;
  if (token) {
    requestUrl += `&token=${token}`;
  }
  return fetch(useProxy ? `${process.env.VUE_APP_PROXY_SERVICE}?url=${requestUrl}` : requestUrl).then((response) => {
    if (!response.ok) {
      throw response;
    }

    return response.json();
  });
}

export function getToken(url, credentials, useProxy) {
  if (!credentials) {
    return Promise.resolve(null);
  }

  const credentialsArray = atob(credentials).split(':');
  const login = credentialsArray[0];
  const password = credentialsArray[1];

  const body = new URLSearchParams();
  body.append('request', 'getToken');
  body.append('username', login);
  body.append('password', password);
  body.append('expiration', '60');
  body.append('f', 'json');

  let fetchOptions = {
    mode: 'cors',
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    body: body.toString()
  };

  const requestUrl = `${url.slice(0, url.indexOf('/rest/'))}/tokens/`;

  return fetch(useProxy ? `${process.env.VUE_APP_PROXY_SERVICE}?url=${requestUrl}` : requestUrl, fetchOptions)
    .then((response) => {
      if (!response.ok) {
        throw response;
      }

      return response.json();
    })
    .then((result) => result.token);
}
