import Gis from './Gis/factory';
import GisLayer from './GisLayer/factory';
import DataSet from './DataSet/factory';
import TabPanel from './TabPanel/factory';

export default {
  GisEmbedded: Gis,
  GisTablo: Gis,
  Gis,
  GisLayer,
  GisGroupLayer: GisLayer,
  DataSet,
  DataSetGeoJson: DataSet,
  TabPanel
};
