import { bbox, kinks as turfKinks, centroid as turfCentroid } from '@turf/turf';
import { formatTimelineValue } from '@/utils/dateTime';
import mapboxSourceFactory, { sourceHash } from '@/components/Gis/utils/mapboxSourceFactory';
import { mapboxLayerFactory, getClearMapboxLayerConfig } from '@/components/Gis/utils/mapboxLayerFactory';
import { getFilters as getDataSetFilters } from '../DataSet/getters';
import {
  getRights as getGisRights,
  getOptions as getGisOptions,
  isMatchLegendLayersFilter,
  getSkyOptions,
  getTimelineVisible,
  getShowFavoritesPanel
} from '../Gis/getters';
import { SCANEX_HOSTS } from '@/components/Gis/utils/const';
import {
  SELECTION_FILL_PAINT,
  SELECTION_LINE_PAINT,
  SELECTION_CIRCLE_PAINT,
  FILL_FILTER,
  LINE_FILTER,
  CIRCLE_FILTER
} from '@/components/Gis/Selection/GisSelectionConstants';

//Названия геттеров
const PREFIX = 'GisLayer';
export const getOptions = `${PREFIX}/getOptions`;
export const isGroup = `${PREFIX}/isGroup`;
export const isExpanded = `${PREFIX}/isExpanded`;
export const isCollapseOnInit = `${PREFIX}/isCollapseOnInit`;
export const isLegendItemVisible = `${PREFIX}/isLegendItemVisible`;
export const isLegendChildItemsVisible = `${PREFIX}/isLegendChildItemsVisible`;
export const isLegendParentItemVisible = `${PREFIX}/isLegendParentItemVisible`;
export const isLoading = `${PREFIX}/isLoading`;
export const getCardComponentId = `${PREFIX}/getCardComponentId`;
export const getTableComponentId = `${PREFIX}/getTableComponentId`;
export const getStatsComponentId = `${PREFIX}/getStatsComponentId`;
export const getTooltipComponentId = `${PREFIX}/getTooltipComponentId`;
export const getChildLayerComponentConfigs = `${PREFIX}/getChildLayerComponentConfigs`;
export const getChildLayerComponentIds = `${PREFIX}/getChildLayerComponentIds`;
export const getLegendStyleItems = `${PREFIX}/getLegendStyleItems`;
export const getLegendStyleItemIds = `${PREFIX}/getLegendStyleItemIds`;
export const isLegendStyleItemVisible = `${PREFIX}/isLegendStyleItemVisible`;
export const isLegendStyleItemHalfVisible = `${PREFIX}/isLegendStyleItemHalfVisible`;
export const getLegendStyleItem = `${PREFIX}/getLegendStyleItem`;
export const isEnable = `${PREFIX}/isEnable`;
export const isVisible = `${PREFIX}/isVisible`;
export const isHalfVisible = `${PREFIX}/isHalfVisible`;
export const getSelectedIds = `${PREFIX}/getSelectedIds`;
export const getEditingIds = `${PREFIX}/getEditingIds`;
export const getMapboxSourceId = `${PREFIX}/getMapboxSourceId`;
export const getMapboxSourceConfig = `${PREFIX}/getMapboxSourceConfig`;
export const getMapboxLayerConfigs = `${PREFIX}/getMapboxLayerConfigs`;
export const getSelectionMapboxLayerConfigs = `${PREFIX}/getSelectionMapboxLayerConfigs`;
export const getReportMapboxLayerConfigs = `${PREFIX}/getReportMapboxLayerConfigs`;
export const getMapboxHoverLayerConfigs = `${PREFIX}/getMapboxHoverLayerConfigs`;
export const getMapboxLayerIds = `${PREFIX}/getMapboxLayerIds`;
export const getMapbox = `${PREFIX}/getMapbox`;
export const getMapboxSourceHash = `${PREFIX}/getMapboxSourceHash`;
export const getLayerLayersOption = `${PREFIX}/getLayerLayersOption`;
export const getTableComponentConfig = `${PREFIX}/getTableComponentConfig`;
export const getStatsComponentConfig = `${PREFIX}/getStatsComponentConfig`;
export const isTableButtonVisible = `${PREFIX}/isTableButtonVisible`;
export const isStatsButtonVisible = `${PREFIX}/isStatsButtonVisible`;
export const isDownloadRasterButtonVisible = `${PREFIX}/isDownloadRasterButtonVisible`;
export const isRequestButtonVisible = `${PREFIX}/isRequestButtonVisible`;
export const isTableButtonActive = `${PREFIX}/isTableButtonActive`;
export const isStatsButtonActive = `${PREFIX}/isStatsButtonActive`;
export const isOptionsButtonVisible = `${PREFIX}/isOptionsButtonVisible`;
export const isMoveToLayerButtonVisible = `${PREFIX}/isMoveToLayerButtonVisible`;
export const isExportButtonVisible = `${PREFIX}/isExportButtonVisible`;
export const isBufferButtonVisible = `${PREFIX}/isBufferButtonVisible`;
export const isCreateSignLayerVisible = `${PREFIX}/isCreateSignLayerVisible`;
export const isFilterButtonVisible = `${PREFIX}/isFilterButtonVisible`;
export const isCalcInterpolationButtonVisible = `${PREFIX}/isCalcInterpolationButtonVisible`;
export const isBindingTileButtonVisible = `${PREFIX}/isBindingTileButtonVisible`;
export const isCloneButtonVisible = `${PREFIX}/isCloneButtonVisible`;
export const isFavoriteButtonVisible = `${PREFIX}/isFavoriteButtonVisible`;
export const isSearchVisible = `${PREFIX}/isSearchVisible`;
export const getDataSetComponentId = `${PREFIX}/getDataSetComponentId`;
export const getBounds = `${PREFIX}/getBounds`;
export const getImageUrls = `${PREFIX}/getImageUrls`;
export const isScanex = `${PREFIX}/isScanex`;
export const getEnableLayers = `${PREFIX}/getEnableLayers`;
export const getVisibleLayers = `${PREFIX}/getVisibleLayers`;
export const getGltfLayers = `${PREFIX}/getGltfLayers`;
export const getAllSelected = `${PREFIX}/getAllSelected`;
export const getAllFilters = `${PREFIX}/getAllFilters`;
export const getFilters = `${PREFIX}/getFilters`;
export const getAllFeatures = `${PREFIX}/getFeatures`;
export const getFeaturesByIds = `${PREFIX}/getFeaturesByIds`;
export const hasChanges = `${PREFIX}/hasChanges`;
export const getChanges = `${PREFIX}/getChanges`;
export const canUndo = `${PREFIX}/canUndo`;
export const canRedo = `${PREFIX}/canRedo`;
export const getDataSetName = `${PREFIX}/getDataSetName`;
export const getFilterFieldOptions = `${PREFIX}/getFilterFieldOptions`;
export const getEditingObjectId = `${PREFIX}/getEditingObjectId`;
export const getFilterObject = `${PREFIX}/getFilterObject`;
export const getConfigForStyleForm = `${PREFIX}/getConfigForStyleForm`;
export const getRights = `${PREFIX}/getRights`;
export const getValidationList = `${PREFIX}/getValidationList`;
export const getSelectedValidationIdsList = `${PREFIX}/getSelectedValidationIdsList`;
export const getSelectedValidationIdsListFromDataset = `${PREFIX}/getSelectedValidationIdsListFromDataset`;
export const getAllLayerIds = `${PREFIX}/getAllLayerIds`;
export const isTimelineEnable = `${PREFIX}/isTimelineEnable`;
export const isTooltipButtonVisible = `${PREFIX}/isTooltipButtonVisible`;
export const getTooltipPosition = `${PREFIX}/getTooltipPosition`;
export const getTooltipExpression = `${PREFIX}/getTooltipExpression`;
export const getHoverEnable = `${PREFIX}/getHoverEnable`;
export const getHoverFeatureIds = `${PREFIX}/getHoverFeatureIds`;
export const getChildLegendLayerComponentIds = `${PREFIX}/getChildLegendLayerComponentIds`;
export const getFilterId = `${PREFIX}/getFilterId`;
export const isSearchDataLoading = `${PREFIX}/isSearchDataLoading`;
export const getKinksInEditingFeatures = `${PREFIX}/getKinksInEditingFeatures`;
export const isValidatePolygonKinks = `${PREFIX}/isValidatePolygonKinks`;
export const getTimelineValue = `${PREFIX}/getTimelineValue`;
export const getTimelineDateField = `${PREFIX}/getTimelineDateField`;
export const getType = `${PREFIX}/getType`;
export const getFilterCrossLayers = `${PREFIX}/getFilterCrossLayers`;
export const getMinimizeOnceVisible = `${PREFIX}/getMinimizeOnceVisible`;
export const getRefreshFilterTotalDataFlag = `${PREFIX}/getRefreshFilterTotalDataFlag`;
export const getFavoritePosition = `${PREFIX}/getFavoritePosition`;

const GEOJSON_LAYER_TYPE = 'geojson';
const VECTOR_LAYER_TYPE = 'vector';
const WMS_LAYER_TYPE = 'wms';
const WFS_LAYER_TYPE = 'wfs';
const TILE_LAYER_TYPE = 'tile';
const PKK_LAYER_TYPE = 'pkk';
const STYLING_LAYER_TYPES = [GEOJSON_LAYER_TYPE, VECTOR_LAYER_TYPE, WMS_LAYER_TYPE, WFS_LAYER_TYPE, TILE_LAYER_TYPE];
const FILTER_LAYER_TYPES = [VECTOR_LAYER_TYPE, GEOJSON_LAYER_TYPE];
const SEARCH_LAYER_TYPES = [VECTOR_LAYER_TYPE, GEOJSON_LAYER_TYPE];
const RASTER_LAYER_TYPES = [WMS_LAYER_TYPE, WFS_LAYER_TYPE, TILE_LAYER_TYPE, PKK_LAYER_TYPE];

export default {
  [getOptions]: (state) => (componentId) => {
    return state.componentOptions[componentId] || {};
  },

  [isGroup]: (state, getters) => (componentId) => {
    return getters[getOptions](componentId).group || false;
  },

  [isExpanded]: (state, getters) => (componentId) => {
    const { parentGisId, expanded } = getters[getOptions](componentId);
    const { legendLayerSearchValue } = state.componentOptions[parentGisId] || {};
    if (legendLayerSearchValue) {
      //Активен поиск
      return expanded || getters[isLegendChildItemsVisible](componentId, false);
    } else {
      return expanded || false;
    }
  },

  [isCollapseOnInit]: (state, getters) => (componentId) => {
    const { parentGisId, collapsedOnInit } = getters[getOptions](componentId);
    const layerConfig = state.items[componentId] || {};
    const parentLayerComponentId = layerConfig.parentComponentId;

    //Если collapsedOnInit указан явно, то используем его
    if (collapsedOnInit !== null) {
      return collapsedOnInit;
    }

    //Если не указан явно, то используем collapseOnInit родителя
    if (parentLayerComponentId !== parentGisId) {
      return getters[isCollapseOnInit](parentLayerComponentId);
    }

    //В остальных случаях
    return false;
  },

  [getCardComponentId]: (state) => (componentId) => {
    //return getters[getOptions](componentId).cardComponentId || null;
    return state.layerCardComponentIds[componentId] || null;
  },

  [getTableComponentId]: (state) => (componentId) => {
    //return getters[getOptions](componentId).tableComponentId || null;
    return state.layerTableComponentIds[componentId] || null;
  },

  [getStatsComponentId]: (state) => (componentId) => {
    return state.layerStatsComponentIds[componentId] || null;
  },

  [getTooltipComponentId]: (state) => (componentId) => {
    //return getters[getOptions](componentId).tooltipComponentId || null;
    return state.layerTooltipComponentIds[componentId] || null;
  },

  [getChildLayerComponentConfigs]: (state) => (componentId) => {
    const items = state.componentOptions[componentId]?.items || [];

    return items
      .map((childComponentId) => {
        return state.items[childComponentId] || {};
      })
      .filter((componentConfig) => {
        return ['GisLayer', 'GisGroupLayer'].includes(componentConfig.componentType);
      })
      .sort((layerComponentConfig1, layerComponentConfig2) => {
        const pos1 = layerComponentConfig1.componentPos || 0;
        const pos2 = layerComponentConfig2.componentPos || 0;

        return pos1 - pos2;
      });
  },

  [getSelectedIds]: (state, getters) => (componentId) => {
    return getters[getOptions](componentId).selectedIds || [];
  },

  [getEditingIds]: (state, getters) => (componentId) => {
    return getters[getOptions](componentId).editingIds;
  },

  [getChildLayerComponentIds]: (state, getters) => (componentId, desc) => {
    return getters[getChildLayerComponentConfigs](componentId)
      .sort((layerComponentConfig1, layerComponentConfig2) => {
        const pos1 = layerComponentConfig1.componentPos || 0;
        const pos2 = layerComponentConfig2.componentPos || 0;

        if (desc) {
          return pos2 - pos1;
        }

        return pos1 - pos2;
      })
      .map((layerComponentConfig) => {
        return layerComponentConfig.componentId;
      });
  },

  [getLegendStyleItems]: (state, getters) => (componentId) => {
    return Object.values(getters[getOptions](componentId).legendStyleItems || {}).filter((legendStyleItem) => legendStyleItem.parentId === componentId);
  },

  [getLegendStyleItemIds]: (state, getters) => (componentId) => {
    return getters[getLegendStyleItems](componentId)
      .filter((legendStyleItem) => {
        // Является ли правило кластериазцией
        const isCluster = legendStyleItem.mapboxLayerConfigs?.[0]?.metadata?.isCluster;

        if (isCluster) {
          // Для правил кластеризации есть настройка, отображать правило в легенде или нет
          const displayedInLegendForClusterRule = legendStyleItem.mapboxLayerConfigs?.[0]?.displayedInLegend;

          return displayedInLegendForClusterRule;
        }

        return true;
      })
      .map((legendStyleItem) => {
        return legendStyleItem.id;
      });
  },

  [getLegendStyleItem]: (state, getters) => (layerComponentId, legendStyleItemId) => {
    return getters[getOptions](layerComponentId).legendStyleItems[legendStyleItemId];
  },

  [isLegendStyleItemVisible]: (state, getters) => (layerComponentId, legendStyleItemId) => {
    const legendStyleItem = getters[getLegendStyleItem](layerComponentId, legendStyleItemId);

    if (!legendStyleItem) {
      return true;
    }

    if (Array.isArray(legendStyleItem.items) && legendStyleItem.items[0]) {
      return legendStyleItem.items.every((childLegendStyleItemId) => {
        return getters[isLegendStyleItemVisible](layerComponentId, childLegendStyleItemId);
      });
    }

    return legendStyleItem.visible;
  },

  [isLegendStyleItemHalfVisible]: (state, getters) => (layerComponentId, legendStyleItemId) => {
    const legendStyleItem = getters[getLegendStyleItem](layerComponentId, legendStyleItemId);

    if (Array.isArray(legendStyleItem.items)) {
      return legendStyleItem.items.some((childLegendStyleItemId) => {
        return getters[isLegendStyleItemHalfVisible](layerComponentId, childLegendStyleItemId);
      });
    }

    return legendStyleItem.visible;
  },

  [isEnable]: (state, getters) => (layerComponentId) => {
    const layerOptions = getters[getOptions](layerComponentId);

    if (layerOptions.group) {
      return getters[getChildLayerComponentIds](layerComponentId).some((childLayerComponentId) => {
        return getters[isEnable](childLayerComponentId);
      });
    }

    return layerOptions.enable;
  },

  [isVisible]: (state, getters) => (layerComponentId) => {
    const layerOptions = getters[getOptions](layerComponentId);

    if (layerOptions.group) {
      const layerIds = getters[getChildLayerComponentIds](layerComponentId);

      return (
        layerIds.length > 0 &&
        layerIds.every((childLayerComponentId) => {
          return getters[isVisible](childLayerComponentId);
        })
      );
    }

    const legendStyleItemIds = getters[getLegendStyleItemIds](layerComponentId);
    if (legendStyleItemIds.length > 0) {
      return legendStyleItemIds.every((legendStyleItemId) => {
        return getters[isLegendStyleItemVisible](layerComponentId, legendStyleItemId);
      });
    }

    return layerOptions.visible;
  },

  [isHalfVisible]: (state, getters) => (layerComponentId) => {
    const layerOptions = getters[getOptions](layerComponentId);

    if (layerOptions.group) {
      return getters[getChildLayerComponentIds](layerComponentId).some((childLayerComponentId) => {
        return getters[isHalfVisible](childLayerComponentId);
      });
    }

    const legendStyleItemIds = getters[getLegendStyleItemIds](layerComponentId);
    if (legendStyleItemIds.length > 0) {
      return legendStyleItemIds.some((legendStyleItemId) => {
        return getters[isLegendStyleItemHalfVisible](layerComponentId, legendStyleItemId);
      });
    }

    return layerOptions.visible;
  },

  [getMapboxSourceConfig]: (state, getters, dispatch) => (componentId, sourceType, rewriteLayerOptions, sourceId) => {
    const layerConfig = state.items[componentId];
    const layerOptions = getters[getOptions](componentId);
    const parentGisId = layerOptions.parentGisId;
    const gisOptions = state.componentOptions[parentGisId] || {};
    // передаем параметр portal_id для tms-запроса
    const portal_id = dispatch.session.userData.portalId;

    let options = {
      ...layerOptions,
      ...rewriteLayerOptions,
      timelineViewType: gisOptions.selectedTimelineViewType,
      currentReportId: gisOptions.currentReportId
    };

    // Если активна временная шкала или у слоя сброшен флаг "Не передавать при отключении временной шкалы"
    if (!layerOptions?.timeLineNoTransmit || getters[getTimelineVisible](parentGisId)) {
      options.selectedDate = gisOptions.selectedDate ?? (layerOptions?.timelineMode === 'year' ? new Date().getFullYear() : new Date());
    }

    return mapboxSourceFactory[sourceType]
      ? mapboxSourceFactory[sourceType](
          {
            ...layerConfig,
            options,
            sourceId,
            portal_id
          },
          gisOptions.mapbox
        )
      : null;
  },

  [getMapboxSourceId]: () => (componentId, type) => {
    return `${componentId}_${type}`;
  },

  [getMapboxLayerConfigs]:
    (state, getters) =>
    (componentId, ignoreVisibility = false) => {
      const layerConfig = state.items[componentId];
      const layerOptions = getters[getOptions](componentId);
      const { excludeSelectedFeatures } = layerOptions;
      const skyOptions = getters[getSkyOptions](layerConfig.parentGisId);

      if (!mapboxLayerFactory[layerOptions.type]) {
        return [];
      }

      let mapboxLayerConfigs = mapboxLayerFactory[layerOptions.type]({
        ...layerConfig,
        skyOptions,
        options: {
          ...layerOptions,
          mapboxLayers: (layerOptions.mapboxLayers || []).filter((dirtyMapboxLayerConfig) => {
            if (ignoreVisibility) {
              return true;
            }

            const legendStyleItemId = dirtyMapboxLayerConfig.name || dirtyMapboxLayerConfig['source-layer'] || 'default';
            const legendStyleItemVisible = getters[isLegendStyleItemVisible](componentId, legendStyleItemId);

            return legendStyleItemVisible;
          })
        }
      })
        .filter((mapboxLayerConfig) => {
          return layerOptions.textVisible || !mapboxLayerConfig.layout || !mapboxLayerConfig.layout['text-field'];
        })
        .map((mapboxLayerConfig) => {
          return {
            ...mapboxLayerConfig,
            metadata: {
              ...mapboxLayerConfig.metadata,
              layerComponentId: componentId
            }
          };
        });

      //При наличии выделения надо исключить выделенные объекты из основного слоя если у слоя есть такой флаг
      const selectedIds = getters[getSelectedIds](componentId);
      if (excludeSelectedFeatures && selectedIds.length > 0) {
        mapboxLayerConfigs.forEach((mapboxLayerConfig) => {
          const excludeIdsFilter = ['all', ...selectedIds.map((id) => ['!=', ['get', layerOptions.keyField], +id || id])];
          mapboxLayerConfig.filter = ['all', excludeIdsFilter, mapboxLayerConfig.filter || ['all']];
        });
      }

      return mapboxLayerConfigs;
    },

  [getSelectionMapboxLayerConfigs]: (state, getters) => (componentId, templateMapboxLayerConfig) => {
    const layerConfig = state.items[componentId];
    const layerOptions = getters[getOptions](componentId);
    const gisOptions = getters[getOptions](layerConfig.parentGisId);

    //При наличии выделения добавляем слои выделения
    const selectedIds = getters[getSelectedIds](componentId);
    if (selectedIds.length > 0) {
      return getMapboxSelectionLayerConfigs(layerConfig, layerOptions, selectedIds, templateMapboxLayerConfig, gisOptions).map((mapboxStyleConfig) =>
        getClearMapboxLayerConfig(mapboxStyleConfig)
      );
    }

    return [];
  },

  [getReportMapboxLayerConfigs]: (state, getters) => (componentId, featureId, templateMapboxLayerConfig) => {
    const layerOptions = getters[getOptions](componentId);

    return getMapboxReportLayerConfigs(layerOptions, [featureId], templateMapboxLayerConfig);
  },

  [getMapboxHoverLayerConfigs]: (state, getters) => (componentId, templateMapboxLayerConfig) => {
    const layerConfig = state.items[componentId];
    const layerOptions = getters[getOptions](componentId);

    //При наличии выделения добавляем слои выделения
    const hoverIds = getters[getHoverFeatureIds](componentId);
    if (hoverIds.length > 0) {
      return getMapboxHoverLayerConfigsFunc(layerConfig, layerOptions, hoverIds, templateMapboxLayerConfig).map((mapboxStyleConfig) =>
        getClearMapboxLayerConfig(mapboxStyleConfig)
      );
    } else {
      return [];
    }
  },

  [getMapboxLayerIds]: (state, getters) => (layerComponentId) => {
    const ids = [];
    const layerOptions = getters[getOptions](layerComponentId);
    const mapboxLayerConfigs = getters[getMapboxLayerConfigs](layerComponentId);
    mapboxLayerConfigs.forEach((mapboxLayerConfig) => {
      if (layerOptions.type !== GEOJSON_LAYER_TYPE) {
        ids.push(mapboxLayerConfig.id);
      }
      //Если есть geojson
      if (Object.values(layerOptions.featuresDict)[0]) {
        ids.push(mapboxLayerConfig.id + '_geojson');
      }
    });
    //Если есть выделение
    //Если выделенные объекты скрываются в основном стиле,
    //то добавляем стили выделения, чтобы не потерять объекты при поиске
    if (layerOptions.excludeSelectedFeatures && layerOptions.selectedIds[0]) {
      const selectionMapboxLayerConfigs = getters[getSelectionMapboxLayerConfigs](layerComponentId, mapboxLayerConfigs[0]);

      //Для векторных слоев возьмем настройки слоев выделения
      if (layerOptions.type === VECTOR_LAYER_TYPE) {
        selectionMapboxLayerConfigs.forEach((mapboxLayerConfig) => {
          ids.push(mapboxLayerConfig.id);
        });
      }

      //Выделенные объекты могут быть в geojson
      if (Object.values(layerOptions.featuresDict)[0]) {
        selectionMapboxLayerConfigs.forEach((mapboxLayerConfig) => {
          ids.push(mapboxLayerConfig.id + '_geojson');
        });
      }
    }
    return ids;
  },

  [getMapbox]: (state, getters) => (componentId) => {
    const layerOptions = getters[getOptions](componentId);
    return layerOptions.mapbox || null;
  },

  [getMapboxSourceHash]: (state, getters) => (componentId) => {
    const layerConfig = state.items[componentId];
    const layerOptions = getters[getOptions](componentId);
    const layerType = layerOptions.type;

    if (!sourceHash[layerType]) {
      return null;
    }

    return sourceHash[layerType]({
      ...layerConfig,
      options: layerOptions
    });
  },

  [getLayerLayersOption]: (state, getters) => (componentId) => {
    const layerOptions = getters[getOptions](componentId);

    switch (layerOptions.type) {
      case WMS_LAYER_TYPE:
        return (layerOptions.layers || '').split(',');
      case PKK_LAYER_TYPE:
        return (layerOptions.layers || '').replace('show:', '').split(',');
      default:
        return [];
    }
  },

  [isLegendItemVisible]: (state, getters) => (layerComponentId, componentId, skipLegendLayerFilter) => {
    const layerOptions = getters[getOptions](layerComponentId);
    let parentGisId = layerOptions.parentGisId;

    if (!parentGisId || parentGisId === '0') {
      parentGisId = componentId;
    }

    const gisOptions = state.componentOptions[parentGisId] || {};
    const legendLayerSearchValue = (gisOptions.legendLayerSearchValue || '').toUpperCase();

    //Если строка для поиска пустая, то слой видно в легенде
    //Если название слоя содержит строку поиска
    const matchSearch = !legendLayerSearchValue || (layerOptions.text || '').toUpperCase().includes(legendLayerSearchValue);
    //Слой удовлетворяет фильтру панели легенд
    const matchLegendFilter = skipLegendLayerFilter || getters[isMatchLegendLayersFilter](parentGisId, layerComponentId);

    return matchSearch && matchLegendFilter;
  },

  [isLegendChildItemsVisible]: (state, getters) => (layerComponentId, componentId, skipLegendLayerFilter) => {
    const layerOptions = getters[getOptions](layerComponentId);

    let childrenVisible = false;
    if (layerOptions.group) {
      getters[getChildLayerComponentIds](layerComponentId).forEach((childLayerComponentId) => {
        childrenVisible =
          childrenVisible ||
          getters[isLegendItemVisible](childLayerComponentId, componentId, skipLegendLayerFilter) ||
          getters[isLegendChildItemsVisible](childLayerComponentId, componentId, skipLegendLayerFilter);
      });
    }

    return childrenVisible;
  },

  [isLegendParentItemVisible]: (state, getters) => (layerComponentId) => {
    const layerConfig = state.items[layerComponentId] || {};
    const layerOptions = getters[getOptions](layerComponentId);
    const parentLayerComponentId = layerConfig.parentComponentId;

    if (layerOptions.parentGisId === parentLayerComponentId) {
      return false;
    }

    return getters[isLegendItemVisible](parentLayerComponentId) || getters[isLegendParentItemVisible](parentLayerComponentId);
  },

  [isLoading]: (state) => (layerComponentId) => {
    return state.layersLoading[layerComponentId];
  },

  [getTableComponentConfig]: (state, getters) => (layerComponentId) => {
    return getComponentConfig(state, getters[getTableComponentId](layerComponentId));
  },

  [getStatsComponentConfig]: (state, getters) => (layerComponentId) => {
    return getComponentConfig(state, getters[getStatsComponentId](layerComponentId));
  },

  [isStatsButtonVisible]: (state, getters) => (layerComponentId) => {
    const { type, panelButtons, parentGisId } = getters[getOptions](layerComponentId);
    let visible = !RASTER_LAYER_TYPES.includes(type);
    visible = visible && getters[getGisRights](parentGisId).viewLayerStatistics;
    return visible;
  },

  [isDownloadRasterButtonVisible]: (state, getters) => (layerComponentId) => {
    const { type, parentGisId } = getters[getOptions](layerComponentId);
    let visible = RASTER_LAYER_TYPES.includes(type);
    visible = visible && getters[getGisRights](parentGisId).downloaRaster;
    return visible;
  },

  [isTableButtonVisible]: (state, getters) => (layerComponentId) => {
    const { type, panelButtons } = getters[getOptions](layerComponentId);
    if (RASTER_LAYER_TYPES.includes(type)) {
      return false;
    }

    return panelButtons && panelButtons.hasOwnProperty('table') ? panelButtons.table : !!getters[getTableComponentConfig](layerComponentId);
  },

  [isRequestButtonVisible]: (state, getters) => (layerComponentId) => {
    const { requestEnabled } = getters[getRights](layerComponentId);
    return requestEnabled;
  },

  [isTableButtonActive]: (state, getters) => (layerComponentId) => {
    const tableComponentConfig = getters[getTableComponentConfig](layerComponentId);

    if (!tableComponentConfig) {
      return false;
    }

    return getters[getOptions](tableComponentConfig.componentId).visible;
  },

  [isStatsButtonActive]: (state, getters) => (layerComponentId) => {
    const componentConfig = getters[getStatsComponentConfig](layerComponentId);

    if (!componentConfig) {
      return false;
    }

    return getters[getOptions](componentConfig.componentId).visible;
  },

  [isOptionsButtonVisible]: (state, getters) => (layerComponentId) => {
    // if(layerComponentId === )
    const layerOptions = getters[getOptions](layerComponentId);
    const panelButtons = layerOptions.panelButtons;
    const parentGisId = layerOptions.parentGisId;
    const hasStyle =
      layerOptions.type === WMS_LAYER_TYPE || layerOptions.type === TILE_LAYER_TYPE ? layerOptions.hasStyle || layerOptions.raster || false : true;

    let visible = panelButtons && panelButtons.hasOwnProperty('options') ? panelButtons.options : STYLING_LAYER_TYPES.includes(layerOptions.type) && hasStyle;
    //Запрет в правах перезаписывает настройки кнопок
    visible = visible && getters[getGisRights](parentGisId).editLayerStyle;
    return visible;
  },

  [isMoveToLayerButtonVisible]: (state, getters) => (layerComponentId) => {
    const panelButtons = getters[getOptions](layerComponentId).panelButtons;
    return panelButtons && panelButtons.hasOwnProperty('moveToLayer') ? panelButtons.moveToLayer : true;
  },

  [isFavoriteButtonVisible]: (state, getters) => (layerComponentId, parentGisId) => {
    const showFavoritesPanelByGis = getters[getShowFavoritesPanel](parentGisId);

    if (!showFavoritesPanelByGis) {
      return false;
    }

    const { panelButtons } = getters[getOptions](layerComponentId);
    const visible = panelButtons && panelButtons.hasOwnProperty('favorite') ? panelButtons.favorite : true;

    return visible;
  },

  [isExportButtonVisible]: (state, getters) => (layerComponentId) => {
    //Доступность кнопки экспорта определяется настройками достоступа
    const { type, panelButtons, parentGisId } = getters[getOptions](layerComponentId);
    if (RASTER_LAYER_TYPES.includes(type)) {
      return false;
    }

    let visible = panelButtons && panelButtons.hasOwnProperty('export') ? panelButtons.export : !!getters[getDataSetComponentId](layerComponentId);
    //Запрет в правах перезаписывает настройки кнопок
    visible = visible && getters[getGisRights](parentGisId).exportLayerData;
    return visible;
  },

  [isBufferButtonVisible]: (state, getters, rootState, rootGetters) => (layerComponentId) => {
    //Костыль, надо решить вопрос через настройки доступа
    if (rootGetters['session/userIsGuest']) {
      return false;
    }

    const { type, panelButtons, parentGisId } = getters[getOptions](layerComponentId);
    if (RASTER_LAYER_TYPES.includes(type)) {
      return false;
    }

    let visible = panelButtons && panelButtons.hasOwnProperty('buffer') ? panelButtons.buffer : !!getters[getDataSetComponentId](layerComponentId);
    //Запрет в правах перезаписывает настройки кнопок
    visible = visible && getters[getGisRights](parentGisId).createBufferZoneLayer;
    return visible;
  },

  [isCreateSignLayerVisible]: (state, getters) => (layerComponentId) => {
    const layerOptions = getters[getOptions](layerComponentId);
    const { type, panelButtons } = layerOptions;

    if (RASTER_LAYER_TYPES.includes(type)) {
      return false;
    }
    return panelButtons && panelButtons.hasOwnProperty('createSignLayer') ? panelButtons.createSignLayer : true;
  },

  [isFilterButtonVisible]: (state, getters) => (layerComponentId) => {
    const { type, panelButtons } = getters[getOptions](layerComponentId);
    if (RASTER_LAYER_TYPES.includes(type)) {
      return false;
    }

    return panelButtons && panelButtons.hasOwnProperty('filter') ? panelButtons.filter : FILTER_LAYER_TYPES.includes(type);
  },

  [isCalcInterpolationButtonVisible]: (state, getters) => (layerComponentId) => {
    const layerOptions = getters[getOptions](layerComponentId);
    const panelButtons = layerOptions.panelButtons;
    return panelButtons && panelButtons.hasOwnProperty('calcInterpolation') ? panelButtons.calcInterpolation : true;
  },

  [isBindingTileButtonVisible]: (state, getters) => (layerComponentId, parentGisId) => {
    return getters[getGisOptions](parentGisId).showBindingTileButton ?? true;
  },

  [isCloneButtonVisible]: (state, getters, rootState, rootGetters) => (layerComponentId) => {
    //Костыль, надо решить вопрос через настройки доступа
    if (rootGetters['session/userIsGuest']) {
      return false;
    }

    const { type, panelButtons } = getters[getOptions](layerComponentId);
    if (RASTER_LAYER_TYPES.includes(type) && type !== TILE_LAYER_TYPE) {
      return false;
    }

    return (panelButtons && panelButtons.clone) || false;
  },

  [isTooltipButtonVisible]: (state, getters) => (layerComponentId) => {
    if (!layerComponentId) {
      return false;
    }

    // сначала проверяем вкючено ли отображение кнопки через настройки доступа слоя
    const visibleButton = getters[getOptions](layerComponentId).rights.showTooltipButton;

    if (!visibleButton) return false;

    const layerOptions = getters[getOptions](layerComponentId);
    if (RASTER_LAYER_TYPES.includes(layerOptions.type)) {
      return false;
    }

    return getters[getGisRights](layerOptions.parentGisId).editLayerStyle && !!layerOptions.dataSetComponentId;
  },

  [getTooltipPosition]: (state, getters) => (layerComponentId) => {
    const layerOptions = getters[getOptions](layerComponentId);

    return layerOptions.tooltipPosition;
  },

  [getTooltipExpression]: (state, getters) => (layerComponentId) => {
    const layerOptions = getters[getOptions](layerComponentId);

    return layerOptions.tooltipExpression;
  },

  [getHoverEnable]: (state, getters) => (layerComponentId) => {
    const { mapboxHoverLayers } = getters[getOptions](layerComponentId);
    return mapboxHoverLayers.some ? mapboxHoverLayers.some((layer) => layer.enabled) : false;
  },

  [getHoverFeatureIds]: (state, getters) => (layerComponentId) => {
    const layerOptions = getters[getOptions](layerComponentId);
    return layerOptions.hoverFeatureIds;
  },

  [isSearchVisible]: (state, getters) => (layerComponentId) => {
    const layerOptions = getters[getOptions](layerComponentId);
    const panelButtons = layerOptions.panelButtons;
    return panelButtons && panelButtons.hasOwnProperty('search')
      ? panelButtons.search && !!getters[getDataSetComponentId](layerComponentId) && SEARCH_LAYER_TYPES.includes(layerOptions.type)
      : !!getters[getDataSetComponentId](layerComponentId) && SEARCH_LAYER_TYPES.includes(layerOptions.type);
  },

  [getDataSetComponentId]: (state) => (layerComponentId) => {
    const item = state.items[layerComponentId] || {};
    return item.dataSetComponentId || null;
  },

  [getBounds]: (state, getters) => (layerComponentId) => {
    const layerOptions = getters[getOptions](layerComponentId);

    if (layerOptions.bounds) {
      return layerOptions.bounds;
    }

    if ([GEOJSON_LAYER_TYPE, WFS_LAYER_TYPE].includes(layerOptions.type) && layerOptions.geojsonData) {
      return bbox({
        type: 'FeatureCollection',
        features: layerOptions.geojsonData.features.filter((feature) => feature.geometry)
      });
    }

    return null;
  },

  [isScanex]: (state, getters) => (layerComponentId) => {
    const layerOptions = getters[getOptions](layerComponentId);

    return SCANEX_HOSTS.some((host) => {
      return (layerOptions.url && layerOptions.url.includes(host)) || (layerOptions.wmsUrl && layerOptions.wmsUrl.includes(host));
    });
  },

  [getEnableLayers]: (state, getters) => (layerComponentId) => {
    const layerOptions = getters[getOptions](layerComponentId);

    if (layerOptions.group) {
      return getters[getChildLayerComponentIds](layerComponentId).reduce((result, childLayerComponentId) => {
        result.push(...getters[getEnableLayers](childLayerComponentId));
        return result;
      }, []);
    } else if (layerOptions.enable) {
      return [layerComponentId];
    } else {
      return [];
    }
  },

  [getVisibleLayers]: (state, getters) => (layerComponentId) => {
    const layerOptions = getters[getOptions](layerComponentId);

    if (layerOptions.group) {
      return getters[getChildLayerComponentIds](layerComponentId).reduce((result, childLayerComponentId) => {
        result.push(...getters[getVisibleLayers](childLayerComponentId));
        return result;
      }, []);
    }

    if (getters[isVisible](layerComponentId)) {
      return [layerComponentId];
    }

    return [];
  },

  [getGltfLayers]: (state, getters) => (layerComponentId) => {
    const layerOptions = getters[getOptions](layerComponentId);

    if (layerOptions.group) {
      return getters[getChildLayerComponentIds](layerComponentId).reduce((result, childLayerComponentId) => {
        result.push(...getters[getGltfLayers](childLayerComponentId));
        return result;
      }, []);
    }

    return [layerComponentId];
  },

  [getAllSelected]: (state, getters) => (layerComponentId) => {
    const layerOptions = getters[getOptions](layerComponentId);

    if (layerOptions.group) {
      return getters[getChildLayerComponentIds](layerComponentId).reduce((result, childLayerComponentId) => {
        result = {
          ...result,
          ...getters[getAllSelected](childLayerComponentId)
        };
        return result;
      }, {});
    }

    const selectedIds = getters[getSelectedIds](layerComponentId);
    if (selectedIds.length > 0) {
      return {
        [layerComponentId]: selectedIds
      };
    }

    return {};
  },

  [getAllFilters]: (state, getters) => (layerComponentId) => {
    const layerOptions = getters[getOptions](layerComponentId);

    if (layerOptions.group) {
      return getters[getChildLayerComponentIds](layerComponentId).reduce((result, childLayerComponentId) => {
        result = {
          ...result,
          ...getters[getAllFilters](childLayerComponentId)
        };
        return result;
      }, {});
    }

    const filterId = getters[getFilterId](layerComponentId);
    if (filterId) {
      return {
        [layerComponentId]: filterId
      };
    }

    return {};
  },

  [getFilters]: (state, getters) => (layerComponentId) => {
    const layerOptions = getters[getOptions](layerComponentId);
    return getters[getDataSetFilters](layerOptions.dataSetComponentId);
  },

  [getAllFeatures]: (state, getters) => (layerComponentId) => {
    const layerOptions = getters[getOptions](layerComponentId);
    return layerOptions.featuresDict ? Object.values(layerOptions.featuresDict) : [];
  },

  [getFeaturesByIds]: (state, getters) => (layerComponentId, ids) => {
    const featuresDict = getters[getOptions](layerComponentId).featuresDict;
    return ids.map((featureId) => featuresDict[featureId]);
  },

  /**
   * Получение информации о несохраненных изменениях
   * Возвращает объект {added: [], deleted: [], edited: []}
   */
  [getChanges]: (state, getters) => (layerComponentId) => {
    const layerOptions = getters[getOptions](layerComponentId);
    return {
      added: layerOptions.addedIds,
      deleted: layerOptions.deletedIds,
      edited: Object.keys(layerOptions.editedIds),
      permanentDeleted: layerOptions.permanentDeletedIds
    };
  },

  /**
   * Получение признака наличия несохраненных изменений
   * Возвращает True, если несохраненные изменения есть
   */
  [hasChanges]: (state, getters) => (layerComponentId) => {
    const layerOptions = getters[getOptions](layerComponentId);
    if (layerOptions.addedIds) {
      return layerOptions.addedIds.length !== 0 || layerOptions.deletedIds.length !== 0 || Object.keys(layerOptions.editedIds).length !== 0;
    } else {
      return false;
    }
  },

  [canUndo]: (state, getters) => (layerComponentId) => {
    const layerOptions = getters[getOptions](layerComponentId);
    return layerOptions.historyIndex >= 0;
  },

  [canRedo]: (state, getters) => (layerComponentId) => {
    const layerOptions = getters[getOptions](layerComponentId);
    return layerOptions.historyIndex < layerOptions.operationHistory.length - 1;
  },

  [getDataSetName]: (state, getters) => (layerComponentId) => {
    const layerOptions = getters[getOptions](layerComponentId);
    const dataSetOptions = getters[getOptions](layerOptions.dataSetComponentId);
    let datasetName = null;
    if (dataSetOptions?.dataSetName) {
      datasetName = dataSetOptions.dataSetName;
    } else if (layerOptions.dataSetName) {
      datasetName = layerOptions.dataSetName;
    } else {
      const dataSetComponentId = getters[getDataSetComponentId](layerComponentId);
      datasetName = dataSetComponentId ? getters[getOptions](dataSetComponentId).dataSetName : null;
    }
    return datasetName;
  },

  [getFilterFieldOptions]: (state, getters) => (layerComponentId) => {
    const layerOptions = getters[getOptions](layerComponentId);
    return layerOptions.filterFieldOptions || {};
  },

  [getEditingObjectId]: (state, getters) => (layerComponentId) => {
    const layerOptions = getters[getOptions](layerComponentId);

    return layerOptions.editingObjectId;
  },

  [getFilterObject]: (state, getters) => (layerComponentId) => {
    let filter = null;
    let where = null;
    const {
      filterFieldValues,
      complexFilterValue: complexFilter,
      filterListIds,
      statisticFilterKeys,
      constructorFilterValue: constructorFilter,
      constructorFilterParams: params
    } = getters[getOptions](layerComponentId);

    if (!filterFieldValues && !complexFilter && !constructorFilter && !statisticFilterKeys) {
      return {};
    }

    Object.keys(filterFieldValues || []).forEach((filterLayerComponentId) => {
      let filterExpression = [];
      const filterLayerFilter = filterFieldValues[filterLayerComponentId];
      const fieldValues = filterLayerFilter.fieldValues;

      Object.values(fieldValues).forEach((filterFieldValue) => {
        if (filterFieldValue.value === null || !Array.isArray(filterFieldValue.value)) {
          return;
        }

        if (filterFieldValue.value.length === 3 || (Array.isArray(filterFieldValue.value[0]) && typeof filterFieldValue.value[1] === 'string')) {
          filterExpression.push(filterFieldValue.value, 'and');
        }

        if (filterFieldValue.value.every((item) => Array.isArray(item))) {
          filterFieldValue.value.forEach((item) => {
            filterExpression.push(item, 'and');
          });
        }
      });

      if (filterExpression.length) {
        filterExpression = filterExpression.slice(0, -1);
        if (filterExpression.length === 1) {
          filterExpression = filterExpression[0];
        }
      } else {
        filterExpression = null;
      }

      if (filterLayerComponentId === layerComponentId) {
        where = filterExpression;
      } else {
        const dataSetName = getters[getDataSetName](filterLayerComponentId);

        if (!dataSetName) {
          console.error('Имя датасета не найдено для datasetId=' + filterLayerComponentId);
          return;
        }
        if (!filterLayerFilter.on) {
          return;
        }

        if (!filter) {
          filter = {};
        }

        //Если слой с таким датасетом уже присутствует в списке, соединим их фильтры в один
        if (filter[dataSetName]) {
          filter[dataSetName].where = [...filter[dataSetName], 'and', ...filterExpression];
          return;
        }

        filter[dataSetName] = {
          where: filterExpression,
          _config_select_mode: filterLayerFilter.selectMode,
          _config_buffer_size: filterLayerFilter.bufferSize
        };
      }
    });

    return {
      where,
      filter,
      complexFilter,
      filterListIds,
      statisticFilterKeys,
      constructorFilter,
      params
    };
  },

  [getConfigForStyleForm]: (state, getters) => (layerComponentId) => {
    //TODO: как распутать этот макаронный код?

    if (!layerComponentId) {
      return {};
    }

    const layerOptions = getters[getOptions](layerComponentId);
    if (!layerOptions) {
      return {};
    }

    let editLayerConfig = {
      componentId: layerComponentId,
      name: layerOptions.text,
      type: layerOptions.type
    };

    if (layerOptions.type === WMS_LAYER_TYPE) {
      //TODO: разобраться и сделать для wms

      editLayerConfig.raster = layerOptions.raster || false;
      editLayerConfig.server = layerOptions.server || false;
      // Данные wms слоя получаем по его имени
      // Среди wms слоев есть много пользовательских, имена которых начинаются на 'users:', они имеют разную геометрию, но один стиль.
      // Для получения стиля таких слоев необходимо сделать запрос с именем слоя, в котором id пользователя заменен на переменную user_id
      // Например users:v_realty_500139
      editLayerConfig.layerName = !layerOptions.layers.includes('users:')
        ? layerOptions.layers
        : layerOptions.layers
            .split('_')
            .map((piece) => (isNaN(+piece) ? piece : '{user_id}'))
            .join('_');
      return editLayerConfig;
    }

    if (layerOptions.type === TILE_LAYER_TYPE) {
      editLayerConfig.raster = layerOptions.raster || false;
      editLayerConfig.server = layerOptions.server || false;
    }

    // this.loadLayerDataFields({
    //   layerComponentId,
    //   gisComponentId: this.componentId
    // }).then((filterFieldOptions) => {
    // editLayerConfig.filterFields = filterFieldOptions;

    //Возьмем наименование датасета, связанного со слоем для работы со список связанных с этим датасетом фильтров
    editLayerConfig.dataset = getters[getDataSetName](layerComponentId);

    //Возьмем стили из конфига слоя
    if (layerOptions.type !== TILE_LAYER_TYPE) {
      editLayerConfig.styles = layerOptions.mapboxLayers || [];
    }
    if (layerOptions.type === VECTOR_LAYER_TYPE) {
      editLayerConfig.styles = editLayerConfig.styles.map((style) => {
        if (!style['source-layer']) {
          return {
            ...style,
            'source-layer': layerOptions.sourceLayer || null
          };
        }
        return {
          ...style
        };
      });
    }
    editLayerConfig.selectionStyles = layerOptions.mapboxSelectionLayers || [];
    editLayerConfig.hoverStyles = layerOptions.mapboxHoverLayers || [];

    editLayerConfig.geomTypes = layerOptions.geomTypes && layerOptions.geomTypes.length ? layerOptions.geomTypes : null;

    //TODO: Доделать
    // Собираем конфиги используемых картинок
    editLayerConfig.images = [];
    if (Array.isArray(editLayerConfig.styles)) {
      editLayerConfig.styles.forEach((style) => {
        let imageName = null;
        if (style.layout && style.layout['icon-image']) {
          imageName = style.layout['icon-image'];
        }
        if (style.paint && style.paint['fill-pattern']) {
          imageName = style.paint['fill-pattern'];
        }
        if (imageName) {
          const images = getters[getGisOptions](layerOptions.parentGisId).images;
          const image = images[imageName];
          if (image) {
            editLayerConfig.images.push({ name: imageName, url: image.src });
          }
        }
      });
    }

    editLayerConfig.filterFields = layerOptions.filterBuilderFields || layerOptions.styleFilterFields;
    editLayerConfig.dataFields = layerOptions.filterBuilderFields;

    return editLayerConfig;
  },

  [getRights]: (state, getters, rootGetters) => (layerComponentId) => {
    return (
      getters[getOptions](layerComponentId).rights || {
        showCard: true,
        showReport: true,
        loadData: true,
        editFields: true,
        editAccess: true,
        editOptions: true,
        showCalc: rootGetters['session/userIsAdmin'],
        editObjects: true,
        editAttributes: true,
        validations: true,
        reports: true
      }
    );
  },

  [getValidationList]: (state, getters) => (layerComponentId) => {
    return getters[getOptions](layerComponentId).validationList;
  },

  [getSelectedValidationIdsList]: (state, getters) => (layerComponentId) => {
    return getters[getOptions](layerComponentId).selectedValidationIdsList;
  },

  [getSelectedValidationIdsListFromDataset]: (state, getters) => (componentId) => {
    return getters[getOptions](componentId).selectedValidationsIdsListFromDataset;
  },

  [getAllLayerIds]: (state, getters) => (layerComponentId) => {
    if (getters[getOptions](layerComponentId).group) {
      return getters[getChildLayerComponentIds](layerComponentId).map((childComponentId) => {
        return getters[getAllLayerIds](childComponentId);
      });
    } else {
      return layerComponentId;
    }
  },

  [isTimelineEnable]: (state, getters) => (layerComponentId) => {
    if (getters[getOptions](layerComponentId).group) {
      return getters[getChildLayerComponentIds](layerComponentId).some((childComponentId) => {
        return getters[isTimelineEnable](childComponentId);
      });
    }
    return (getters[isVisible](layerComponentId) || getters[isHalfVisible](layerComponentId)) && !!getters[getOptions](layerComponentId).timelineDateField;
  },

  [getChildLegendLayerComponentIds]: (state, getters) => (layerComponentId, skipLegendLayerFilter) => {
    return getters[getChildLayerComponentIds](layerComponentId, true).filter((childLayerComponentId) => {
      return (
        getters[isLegendItemVisible](childLayerComponentId, skipLegendLayerFilter) ||
        getters[isLegendChildItemsVisible](childLayerComponentId, skipLegendLayerFilter) ||
        getters[isLegendParentItemVisible](childLayerComponentId)
      );
    });
  },

  [getFilterId]: (state, getters) => (layerComponentId) => {
    return getters[getOptions](layerComponentId).filterId;
  },

  [isSearchDataLoading]: (state, getters) => (layerComponentId) => {
    return getters[getOptions](layerComponentId).searchDataLoading;
  },

  /**
   * Получение массива токек самопересечений полигонов
   * @param {*} state
   * @param {*} getters
   * @returns {Feature[]}
   */
  [getKinksInEditingFeatures]:
    (state, getters) =>
    (layerComponentId, withId = false) => {
      const { addedIds, editedIds } = getters[getOptions](layerComponentId);

      //Получаем добавленные объекты
      const addedFeatures = getters[getFeaturesByIds](layerComponentId, addedIds || []);

      //Получаем отредактированные объекты
      const editedFeatures = getters[getFeaturesByIds](layerComponentId, Object.keys(editedIds) || []);

      //Объединяем в один массив и оставляем только полигоны и мультиполигоны
      const addedAndEditedPolygons = [...addedFeatures, ...editedFeatures].filter((feature) => {
        return ['Polygon', 'MultiPolygon'].includes(feature.geometry?.type);
      });

      //Получение массива точек самопересечений
      const kinks = addedAndEditedPolygons
        .map((feature) => {
          return {
            ...turfKinks(feature),
            polygonId: feature.id,
            polygonCenterCoordinates: turfCentroid(feature)
          };
        })
        .reduce((result, kinksFeatureCollection) => {
          if (withId) {
            const features = kinksFeatureCollection.features.map((item) => {
              return {
                ...item,
                polygonId: kinksFeatureCollection.polygonId,
                polygonCenterCoordinates: kinksFeatureCollection.polygonCenterCoordinates
              };
            });

            return [...result, ...features];
          }

          return [...result, ...kinksFeatureCollection.features];
        }, []);

      return kinks;
    },

  [isValidatePolygonKinks]: (state, getters) => (layerComponentId) => {
    return getters[getOptions](layerComponentId).validatePolygonKinks || false;
  },

  /**
   * Получение даты из таймлайна для слоя
   * @param {*} state
   * @param {*} getters
   * @returns
   */
  [getTimelineValue]: (state, getters) => (layerComponentId) => {
    const layerOptions = getters[getOptions](layerComponentId);
    const parentGisId = layerOptions.parentGisId;
    const { selectedDate } = state.componentOptions[parentGisId] || {};

    if (layerOptions.timelineDateField && selectedDate) {
      return formatTimelineValue(selectedDate, layerOptions.timelineMode);
    }

    return null;
  },

  /**
   * Получение поля, в которое отправляется значение таймлайна
   * @param {*} state
   * @param {*} getters
   * @returns
   */
  [getTimelineDateField]: (state, getters) => (layerComponentId) => {
    return getters[getOptions](layerComponentId).timelineDateField || null;
  },

  [getType]: (state, getters) => (layerComponentId) => {
    return getters[getOptions](layerComponentId).type || null;
  },

  [getFilterCrossLayers]: (state, getters) => (layerComponentId) => {
    return getters[getOptions](layerComponentId).filterCrossLayers;
  },

  [getMinimizeOnceVisible]: (state, getters) => (layerComponentId) => {
    return getters[getOptions](layerComponentId).minimizeOnceVisible;
  },

  [getRefreshFilterTotalDataFlag]: (state, getters) => (layerComponentId) => {
    return getters[getOptions](layerComponentId).refreshFilterTotalDataFlag;
  },

  [getFavoritePosition]: (state, getters) => (layerComponentId) => {
    return getters[getOptions](layerComponentId).favoritePos;
  }
};

function getComponentConfig(state, componentId) {
  return state.items[componentId] || null;
}

function getMapboxHoverLayerConfigsFunc(layerConfig, layerOptions, featureIds, templateConfig) {
  let mapboxLayerConfigs = [];
  const metadata = {
    layerComponentId: layerOptions.layerComponentId
  };
  if (layerOptions.mapboxHoverLayers && layerOptions.mapboxHoverLayers[0]) {
    const visibleLegendStyleItems = Object.values(layerOptions.legendStyleItems).filter(({ visible }) => visible);

    // Показываем стили наведения только для видимых слоев
    const currentMapboxHoverLayers = layerOptions.mapboxHoverLayers.filter(({ name, enabled }) => {
      return (
        !!visibleLegendStyleItems.find(({ mapboxLayerConfigs }) => {
          // если есть displayNameList, значит слой градиентый, у таких слоев по-другому задается имя для дочерних правил легенды
          if (!!mapboxLayerConfigs[0].displayNameList) {
            // ищем второе вхождение знака "_" с 5ой позиции (пример имени: rule_0_1, где 0 - номер родителя, 1 - номер правила)
            const index = mapboxLayerConfigs[0].name.indexOf('_', 5);
            const parentName = mapboxLayerConfigs[0].name.slice(0, index);

            return parentName === name;
          }

          return mapboxLayerConfigs[0].name === name;
        }) && enabled
      );
    });

    mapboxLayerConfigs = mapboxLayerFactory[layerOptions.type]({
      ...layerConfig,
      options: {
        ...layerOptions,
        mapboxLayers: currentMapboxHoverLayers
      }
    });
  }
  mapboxLayerConfigs = mapboxLayerConfigs
    .filter(
      //Проверка paint что-то рисует
      (mapboxLayerConfig) =>
        mapboxLayerConfig.paint && (mapboxLayerConfig.type !== 'fill' || mapboxLayerConfig.paint['fill-color'] || mapboxLayerConfig.paint['fill-pattern'])
    )
    .map((mapboxLayerConfig, index) => {
      const geomFilter = mapboxLayerConfig.geometry ? ['all', ['in', ['geometry-type'], ['literal', mapboxLayerConfig.geometry]]] : null;
      let commonFilter;
      if (mapboxLayerConfig.filter && geomFilter) {
        commonFilter = ['all', mapboxLayerConfig.filter, geomFilter];
      } else {
        commonFilter = mapboxLayerConfig.filter || geomFilter || ['all'];
      }

      let result = {
        ...mapboxLayerConfig,
        source: templateConfig.source,
        id: `${layerOptions.layerComponentId}_hover_${index}`,
        filter: commonFilter,
        metadata
      };

      if (layerOptions.type === VECTOR_LAYER_TYPE && !mapboxLayerConfig['source-layer']) {
        result['source-layer'] = layerOptions.sourceLayer || templateConfig['source-layer'];
      }

      return result;
    });

  const idsFilter = [
    'any',
    ...featureIds.map((featureId) => {
      return ['==', ['get', layerOptions.keyField], featureId];
    })
  ];
  for (const mapboxLayerConfig of mapboxLayerConfigs) {
    mapboxLayerConfig.filter = ['all', idsFilter, mapboxLayerConfig.filter || ['all']];
  }
  return mapboxLayerConfigs;
}

/**
 * Генерация настроек слоев mapbox для подсветки выделения
 * @param {Object} layerOptions
 */
function getMapboxSelectionLayerConfigs(layerConfig, layerOptions, selectedIds, templateConfig, gisOptions) {
  let mapboxLayerConfigs = [];
  const metadata = {
    layerComponentId: layerOptions.layerComponentId
  };

  const selectedMouseTool = gisOptions.selectedMouseTool;

  if (layerOptions.mapboxSelectionLayers && layerOptions.mapboxSelectionLayers[0]) {
    const visibleLegendStyleItems = Object.values(layerOptions.legendStyleItems).filter(({ visible }) => visible);

    // Показываем стили выделения только для видимых слоев
    const currentMapboxSelectionLayers = layerOptions.mapboxSelectionLayers.filter(({ name }) => {
      return !!visibleLegendStyleItems.find(({ mapboxLayerConfigs }) => {
        // если есть displayNameList, значит слой градиентый, у таких слоев по-другому задается имя для дочерних правил легенды
        if (!!mapboxLayerConfigs[0].displayNameList) {
          // ищем второе вхождение знака "_" с 5ой позиции (пример имени: rule_0_1, где 0 - номер родителя, 1 - номер правила)
          const index = mapboxLayerConfigs[0].name.indexOf('_', 5);
          const parentName = mapboxLayerConfigs[0].name.slice(0, index);

          return parentName === name;
        }

        return mapboxLayerConfigs[0].name === name;
      });
    });

    // Если задали не дефолтное выделение, а свое, есть параметр fill-pattern, при этих инструментах паттерн скрываем, что бы было удобно рисовать
    switch (selectedMouseTool) {
      case 'sliceGeom':
      case 'sliceGeomBuffer':
      case 'part':
      case 'partAddNewObject':
      case 'removePart':
        currentMapboxSelectionLayers.forEach((layer) => {
          if (layer.paint['fill-pattern']) {
            layer.paint['fill-opacity'] = 0;
          }
        });
        break;
      default:
        currentMapboxSelectionLayers.forEach((layer) => {
          if (layer.paint['fill-pattern']) {
            layer.paint['fill-opacity'] = 1;
          }
        });
    }

    mapboxLayerConfigs = mapboxLayerFactory[layerOptions.type]({
      ...layerConfig,
      options: {
        ...layerOptions,
        mapboxLayers: currentMapboxSelectionLayers
      }
    });
  } else {
    // Дефолтное выделение, при этих инструментах скрываем, что бы было удобно рисовать
    switch (selectedMouseTool) {
      case 'sliceGeom':
      case 'sliceGeomBuffer':
      case 'part':
      case 'partAddNewObject':
      case 'removePart':
        SELECTION_FILL_PAINT['fill-opacity'] = 0;
        break;
      default:
        SELECTION_FILL_PAINT['fill-opacity'] = 1;
    }

    const fillLayerConfig = {
      type: 'fill',
      layout: {},
      paint: layerOptions.selectionFillPaint || SELECTION_FILL_PAINT,
      filter: FILL_FILTER,
      metadata
    };

    const lineLayerConfig = {
      type: 'line',
      layout: {},
      paint: layerOptions.selectionLinePaint || SELECTION_LINE_PAINT,
      filter: LINE_FILTER,
      metadata
    };

    const circleLayerConfig = {
      type: 'circle',
      layout: {},
      paint: layerOptions.selectionCirclePaint || SELECTION_CIRCLE_PAINT,
      filter: CIRCLE_FILTER,
      metadata
    };

    mapboxLayerConfigs = [fillLayerConfig, lineLayerConfig, circleLayerConfig];
  }

  mapboxLayerConfigs = mapboxLayerConfigs
    .filter(
      //Проверка paint что-то рисует
      (mapboxLayerConfig) =>
        //layout для выделения изображением
        (mapboxLayerConfig.paint || mapboxLayerConfig.layout) &&
        (mapboxLayerConfig.type !== 'fill' || mapboxLayerConfig.paint['fill-color'] || mapboxLayerConfig.paint['fill-pattern'])
    )
    .map((mapboxLayerConfig, index) => {
      const geomFilter = mapboxLayerConfig.geometry ? ['all', ['in', ['geometry-type'], ['literal', mapboxLayerConfig.geometry]]] : null;
      let commonFilter;
      if (mapboxLayerConfig.filter && geomFilter) {
        commonFilter = ['all', mapboxLayerConfig.filter, geomFilter];
      } else {
        commonFilter = mapboxLayerConfig.filter || geomFilter || ['all'];
      }

      let result = {
        ...mapboxLayerConfig,
        source: templateConfig.source,
        id: `${layerOptions.layerComponentId}_selection_${index}`,
        filter: commonFilter,
        metadata
      };

      if (layerOptions.type === VECTOR_LAYER_TYPE && !mapboxLayerConfig['source-layer']) {
        result['source-layer'] = layerOptions.sourceLayer || templateConfig['source-layer'];
      }

      return result;
    });

  const idsFilter = [
    'any',
    ...selectedIds.map((selectedId) => {
      return ['==', ['get', layerOptions.keyField], selectedId];
    })
  ];
  for (const mapboxLayerConfig of mapboxLayerConfigs) {
    mapboxLayerConfig.filter = ['all', idsFilter, mapboxLayerConfig.filter || ['all']];
  }
  return mapboxLayerConfigs;
}

/**
 * Генерация настроек слоев mapbox для подсветки при формировании отчета
 * @param {Object} layerOptions
 */
function getMapboxReportLayerConfigs(layerOptions, featureIds, templateConfig) {
  let mapboxLayerConfigs = [];
  const metadata = {
    layerComponentId: layerOptions.layerComponentId
  };

  const fillLayerConfig = {
    type: 'fill',
    layout: {},
    paint: layerOptions.selectionFillPaint || SELECTION_FILL_PAINT,
    filter: FILL_FILTER,
    metadata
  };

  const lineLayerConfig = {
    type: 'line',
    layout: {},
    paint: layerOptions.selectionLinePaint || SELECTION_LINE_PAINT,
    filter: LINE_FILTER,
    metadata
  };

  const circleLayerConfig = {
    type: 'circle',
    layout: {},
    paint: layerOptions.selectionCirclePaint || SELECTION_CIRCLE_PAINT,
    filter: CIRCLE_FILTER,
    metadata
  };

  mapboxLayerConfigs = [fillLayerConfig, lineLayerConfig, circleLayerConfig];

  mapboxLayerConfigs = mapboxLayerConfigs.map((mapboxLayerConfig, index) => {
    const geomFilter = mapboxLayerConfig.geometry ? ['all', ['in', ['geometry-type'], ['literal', mapboxLayerConfig.geometry]]] : null;
    let commonFilter;
    if (mapboxLayerConfig.filter && geomFilter) {
      commonFilter = ['all', mapboxLayerConfig.filter, geomFilter];
    } else {
      commonFilter = mapboxLayerConfig.filter || geomFilter || ['all'];
    }

    let result = {
      ...mapboxLayerConfig,
      source: templateConfig.source,
      id: `${layerOptions.layerComponentId}_report_${index}`,
      filter: commonFilter,
      metadata
    };

    if (layerOptions.type === VECTOR_LAYER_TYPE && !mapboxLayerConfig['source-layer']) {
      result['source-layer'] = layerOptions.sourceLayer || templateConfig['source-layer'];
    }

    return result;
  });

  const idsFilter = [
    'any',
    ...featureIds.map((selectedId) => {
      return ['==', ['get', layerOptions.keyField], selectedId];
    })
  ];
  for (const mapboxLayerConfig of mapboxLayerConfigs) {
    mapboxLayerConfig.filter = ['all', idsFilter, mapboxLayerConfig.filter || ['all']];
  }
  return mapboxLayerConfigs;
}
