import Vue from 'vue';
import notify from 'devextreme/ui/notify';
import devices from 'devextreme/core/devices';
import { custom } from 'devextreme/ui/dialog';

/**
 * Транслитерация русской строки
 * @param {*} str
 * @returns
 */
export function translitRuEn(str) {
  var magic = function (lit) {
    var arrayLits = [
      ['а', 'a'],
      ['б', 'b'],
      ['в', 'v'],
      ['г', 'g'],
      ['д', 'd'],
      ['е', 'e'],
      ['ё', 'yo'],
      ['ж', 'zh'],
      ['з', 'z'],
      ['и', 'i'],
      ['й', 'j'],
      ['к', 'k'],
      ['л', 'l'],
      ['м', 'm'],
      ['н', 'n'],
      ['о', 'o'],
      ['п', 'p'],
      ['р', 'r'],
      ['с', 's'],
      ['т', 't'],
      ['у', 'u'],
      ['ф', 'f'],
      ['х', 'h'],
      ['ц', 'c'],
      ['ч', 'ch'],
      ['ш', 'w'],
      ['щ', 'shh'],
      ['ъ', "''"],
      ['ы', 'y'],
      ['ь', "'"],
      ['э', 'e'],
      ['ю', 'yu'],
      ['я', 'ya'],
      ['А', 'A'],
      ['Б', 'B'],
      ['В', 'V'],
      ['Г', 'G'],
      ['Д', 'D'],
      ['Е', 'E'],
      ['Ё', 'YO'],
      ['Ж', 'ZH'],
      ['З', 'Z'],
      ['И', 'I'],
      ['Й', 'J'],
      ['К', 'K'],
      ['Л', 'L'],
      ['М', 'M'],
      ['Н', 'N'],
      ['О', 'O'],
      ['П', 'P'],
      ['Р', 'R'],
      ['С', 'S'],
      ['Т', 'T'],
      ['У', 'U'],
      ['Ф', 'F'],
      ['Х', 'H'],
      ['Ц', 'C'],
      ['Ч', 'CH'],
      ['Ш', 'W'],
      ['Щ', 'SHH'],
      ['Ъ', ''],
      ['Ы', 'Y'],
      ['Ь', ''],
      ['Э', 'E'],
      ['Ю', 'YU'],
      ['Я', 'YA'],
      ['0', '0'],
      ['1', '1'],
      ['2', '2'],
      ['3', '3'],
      ['4', '4'],
      ['5', '5'],
      ['6', '6'],
      ['7', '7'],
      ['8', '8'],
      ['9', '9'],
      ['a', 'a'],
      ['b', 'b'],
      ['c', 'c'],
      ['d', 'd'],
      ['e', 'e'],
      ['f', 'f'],
      ['g', 'g'],
      ['h', 'h'],
      ['i', 'i'],
      ['j', 'j'],
      ['k', 'k'],
      ['l', 'l'],
      ['m', 'm'],
      ['n', 'n'],
      ['o', 'o'],
      ['p', 'p'],
      ['q', 'q'],
      ['r', 'r'],
      ['s', 's'],
      ['t', 't'],
      ['u', 'u'],
      ['v', 'v'],
      ['w', 'w'],
      ['x', 'x'],
      ['y', 'y'],
      ['z', 'z'],
      ['A', 'A'],
      ['B', 'B'],
      ['C', 'C'],
      ['D', 'D'],
      ['E', 'E'],
      ['F', 'F'],
      ['G', 'G'],
      ['H', 'H'],
      ['I', 'I'],
      ['J', 'J'],
      ['K', 'K'],
      ['L', 'L'],
      ['M', 'M'],
      ['N', 'N'],
      ['O', 'O'],
      ['P', 'P'],
      ['Q', 'Q'],
      ['R', 'R'],
      ['S', 'S'],
      ['T', 'T'],
      ['U', 'U'],
      ['V', 'V'],
      ['W', 'W'],
      ['X', 'X'],
      ['Y', 'Y'],
      ['Z', 'Z']
    ];
    var efim360ru = arrayLits
      .map((i) => {
        if (i[0] === lit) {
          return i[1];
        } else {
          return undefined;
        }
      })
      .filter((i) => i != undefined);
    if (efim360ru.length > 0) {
      return efim360ru[0];
    } else {
      return lit;
    }
  };
  return Array.from(str)
    .map((i) => magic(i))
    .join('');
}

export function sortFilterFieldsAlphabetically(fields) {
  if (!Array.isArray(fields)) {
    return [];
  }

  const sortedFields = [...fields].sort((field1, field2) => {
    const f1 = (field1.caption || field1.name || '').toLowerCase();
    const f2 = (field2.caption || field2.name || '').toLowerCase();

    // Если есть атрибут косметического слоя, он всегда наверху
    if (field1.dataField === 'tmp_properties' && field2.dataField !== 'tmp_properties') {
      return -1;
    }

    if (field1.dataField !== 'tmp_properties' && field2.dataField === 'tmp_properties') {
      return 1;
    }

    // Если это cnum (Кадастровый номер), подбрасываем наверх списка (Просьба Анастасии от 18.05.2023)
    if (field1.dataField === 'cnum' && field2.dataField !== 'cnum') {
      return -1;
    }

    if (field1.dataField !== 'cnum' && field2.dataField === 'cnum') {
      return 1;
    }

    if (f1 < f2) {
      return -1;
    }
    if (f1 > f2) {
      return 1;
    }
    return 0;
  });

  return sortedFields;
}

export function sortFilterFieldsByPos(fields) {
  if (!Array.isArray(fields)) {
    return [];
  }

  return [...fields].sort((field1, field2) => field1.pos || 0 - field2.pos || 0);
}

//Возвращает функцию
//callback вызывается если функция не была вызвана по прошествии delay
export function debounce(delay, callback) {
  let handle;
  return (arg) => {
    if (handle) {
      clearTimeout(handle);
      handle = null;
    }
    handle = setTimeout(() => {
      handle = null;
      callback(arg);
    }, delay);
  };
}

//если состояние неактивное, вызывается callback, после этого функция переходит в активное состояние на время задержки delay
//В активном состоянии действий не происходит
export function delayedMutex(delay, callback) {
  let handle;
  return function () {
    if (!handle) {
      callback.apply(null, arguments);
      handle = setTimeout(() => {
        handle = null;
      }, delay);
    }
  };
}

export function getReportUrl({ paramName, _config_url, name, ...param }) {
  if (name) {
    const paramStr = `${paramName}=${encodeURIComponent(
      JSON.stringify({
        ...param,
        host: window.location.host
      })
    )}`;
    return `${_config_url}?name=${encodeURIComponent(name)}&${paramStr}`;
  } else {
    notify({ message: 'Для открытия отчета необходим параметр "name"', width: 'auto' }, 'warning', 3000);
    window.console.error('Для открытия отчета необходим параметр "name"');
    return null;
  }
}

export function setUrlParameter(url, name, value) {
  const regex = new RegExp(`${name}=[^&?]+`);
  const match = regex.exec(url);
  if (match) {
    return url.replace(regex, `${name}=${value}`);
  } else {
    if (url.includes('?')) {
      return url + `&${name}=${value}`;
    } else {
      return url + `?${name}=${value}`;
    }
  }
}

export function getPropByPath(obj, propArr) {
  if (!obj) {
    return null;
  }

  //Для полей с именем типа body_json.c_8378
  const joinName = propArr.join('.');
  if (obj[joinName]) {
    return obj[joinName];
  }

  let propName = propArr.shift();
  if (propName === undefined) {
    return null;
  }

  if (propArr.length > 0) {
    return getPropByPath(obj[propName], propArr);
  } else {
    return obj[propName] === undefined ? null : obj[propName];
  }
}

export function setPropByPath(obj, propArr, value) {
  let propName = propArr.shift();

  if (propName) {
    if (Array.isArray(obj)) {
      propName = parseInt(propName) || 0;
    }
    if (propArr.length) {
      if (obj[propName] === undefined) {
        Vue.set(obj, propName, {});
      }
      setPropByPath(obj[propName], propArr, value);
    } else {
      if (Array.isArray(obj)) {
        if (propName > obj.length - 1) {
          obj.push(value);
        } else {
          obj.splice(propName, 1, value);
        }
      } else if (!obj.hasOwnProperty(propName)) {
        Vue.set(obj, propName, value);
      } else {
        //Условия для массива пока закомментируем, непонятно зачем я так сделал?
        //if (Array.isArray(obj[propName])) {
        //  obj[propName].splice(0, obj[propName].length, ...(value || []));
        //} else {
        obj[propName] = value;
        //}
      }
    }
  }
  return obj;
}

export function isObjectEmpty(obj) {
  for (var key in obj) {
    if (obj.hasOwnProperty(key)) {
      return false;
    }
  }
  return true;
}

export function getDeviceType() {
  return devices.current().deviceType;
}

/**Открытие окна с сообщением о запуске бизнес процесса*/
export function showStartProcessInfo(result, customMessage = null) {
  let success = result?.result === 'OK';
  success = success || !!result?.abpmn_id;
  if (!success) {
    window.console.error('Ошибка запуска бизнес процесса:', result);
  }
  custom({
    showTitle: false,
    messageHtml: `<div style="font-size:16px;text-align:center;max-width:400px">${success ? customMessage || 'Операция запущена' : 'Произошла ошибка'}</div>`,
    buttons: [
      {
        text: 'Ок'
      }
    ]
  }).show();
}
/**Преобразование строки в true/false*/
export function toBoolean(str) {
  if (typeof str == 'string') return str.toLowerCase() == 'true';
  else return str;
}
/**Поиск и получение id Gis компонента*/
export function getParentGisId(componentId, rootGetters) {
  const item = rootGetters['config/getItem'](componentId);
  if (!item) {
    return null;
  }
  const { parentGisId, parentComponentId, componentType } = item;
  if (componentType === 'Gis') {
    return componentId;
  }
  if (parentGisId) {
    return parentGisId;
  }
  if (parentComponentId) {
    return getParentGisId(parentComponentId, rootGetters);
  }

  return 0;
}

export function isCadNum(text) {
  return text ? text.includes(':') : false;
}
