export const MODE_20MINUTES = '20minutes';
export const MODE_HOUR = 'hour';
export const MODE_DATE = 'date';
export const MODE_MONTH = 'month';
export const MODE_YEAR = 'year';

export const STANDARD_TIMELINE_ID = 'standard';

export const ANIMATION_MODE_FILTER = 'filter';
export const ANIMATION_MODE_VISIBILITY = 'visibility';

export const ANIMATION_VIEW_SIMPLE = 'simple';
export const ANIMATION_VIEW_STACKED = 'stacked';

export const INTERVAL_MODES = [
  {
    code: MODE_YEAR,
    name: 'Год'
  },
  {
    code: MODE_MONTH,
    name: 'Месяц'
  },
  {
    code: MODE_DATE,
    name: 'Дата'
  },
  {
    code: MODE_HOUR,
    name: 'Час'
  },
  {
    code: MODE_20MINUTES,
    name: '20 минут'
  }
];

export const ANIMATION_MODES = [
  {
    code: ANIMATION_MODE_VISIBILITY,
    name: 'Анимация видимости'
  },
  {
    code: ANIMATION_MODE_FILTER,
    name: 'Фильтрация'
  }
];
