/**
 * Экземпляр датасета CONFIG.DSLAYERFAVORITE
 */
export default class GisLayerFavoriteItem {
  /**Идентификатор записи, нужен для удаления*/
  id = null;

  /**Идентификатор компонента слоя (GisLayer) */
  componentId = null;

  /**Идентификатор компонента карты (Gis) */
  gisComponentId = null;

  /**true для избранных, false для недавних */
  isFavorite = false;

  /**данные из БД */
  source = null;

  constructor(options) {
    this.id = options.id;
    this.componentId = options.component_id.toString();
    this.gisComponentId = options.giscomponent_id.toString();
    this.isFavorite = !!options.is_favorite;
    this.source = options;
  }
}
