import getConfigs from './configUtils';
const OPTIONS = ['text', 'icon', 'type', 'stylingMode', 'disabled'];

const CONFIGS_FIELDS = {
  text: {
    name: 'text',
    label: 'Текст',
    component: 'DxTextBox',
    options: {
      showClearButton: true
    },
    changeEvent: 'valueChanged'
  },
  icon: {
    name: 'icon',
    label: 'Иконка',
    component: 'DxTextBox',
    options: {
      showClearButton: true
    },
    changeEvent: 'valueChanged'
  },
  type: {
    label: 'Тип',
    name: 'type',
    component: 'DxSelectBox',
    options: {
      dataSource: [
        { text: 'Back', value: 'back' },
        { text: 'Danger', value: 'danger' },
        { text: 'Default', value: 'default' },
        { text: 'Normal', value: 'normal' },
        { text: 'Success', value: 'success' }
      ],
      showClearButton: true,
      displayExpr: 'text',
      valueExpr: 'value'
    },
    changeEvent: 'valueChanged'
  },
  stylingMode: {
    name: 'stylingMode',
    label: 'Стиль поля',
    component: 'DxSelectBox',
    options: {
      dataSource: [
        { text: 'Без рамки', value: 'text' },
        { text: 'С рамкой', value: 'contained' },
        { text: 'Закрашенный', value: 'outlined' }
      ],
      showClearButton: true,
      displayExpr: 'text',
      valueExpr: 'value'
    },
    changeEvent: 'valueChanged'
  },
  disabled: {
    name: 'disabled',
    label: 'Поле заблокировано',
    component: 'DxSelectBox',
    options: {
      dataSource: [
        { text: 'Да', value: 'true' },
        { text: 'Нет', value: 'false' }
      ],
      showClearButton: true,
      displayExpr: 'text',
      valueExpr: 'value'
    },
    changeEvent: 'optionChanged'
  }
};

export default getConfigs(OPTIONS, CONFIGS_FIELDS);
