<template>
  <!--eslint-disable-->
  <div>
    Неизвестный тип компонента {{type}}
  </div>
</template>

<script>
export default {
  name: 'UnknownComponent',
  props: {
    type: String
  }
};
</script>
