<template>
  <!--eslint-disable-->
  <div class="popup-title-template">
    <div
      class="popup-title-template__text"
      :title="text"
    >
      {{text}}
    </div>
    <DxToolbar
      v-if="toolbarItems && toolbarItems.length"
      class="popup-title-template__toolbar"
      :items="toolbarItems"
    >
      <template #itemTemplate="{data: itemConfig}">
        <ContainerItemDx
          :componentId="itemConfig.componentId"
          :componentType="itemConfig.componentType"
          :cssStyle="itemConfig.cssStyle"
        />
      </template>
    </DxToolbar>
    <div
      class="popup-title-template__top-button"
      v-if="showCollapseButton"
      :title="collapseButtonHint"
      @click="onCollapseButtonClick"
    >
      <i :class="collapseButtonClass" />
    </div>
    <div
      class="popup-title-template__top-button"
      v-if="showCloseButton"
      @click="$emit('closeClick')"
    >
      <i class="core core-057-close" />
    </div>
  </div>
</template>

<script>
import ContainerItemDx from './ContainerItemDx';
import DxToolbar from 'devextreme-vue/toolbar';
import popupTitleCollapseMixin from '../mixins/popupTitleCollapse';

export default {
  name: 'PopupTitleTemplate',
  components: {
    DxToolbar,
    ContainerItemDx
  },
  props: {
    text: String,
    showCloseButton: Boolean,
    toolbarItems: Array
  },
  mixins: [popupTitleCollapseMixin]
};
</script>

<style lang="scss">
.popup-title-template {
  height: 40px !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 20px;

  &__text {
    flex: 1;
    font-size: 14px;
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__toolbar {
    background-color: inherit !important;
    flex: 1 1 auto;
  }

  &__top-button {
    flex: 0 0 auto;
    font-size: 14px;
    line-height: 14px;
    cursor: pointer;
    padding: 3px;

    &:hover {
      color: rgba(0, 0, 0, 0.25);
    }
  }
}
</style>
