import * as turf from '@turf/turf';
import { getUnkinkPolygon } from './unkink.js';
import { isSegmentsIntersect } from './intersect';

export function getPolygons(features) {
  const resultPolygons = [];
  for (let index = 0; index < features.length; index++) {
    const polygon = getUnkinkPolygon(features[index]);

    const polygonCollection = turf.featureCollection([polygon]);

    for (const feature of polygonCollection.features) {
      resultPolygons.push(feature);
    }
  }

  return resultPolygons;
}

export function convertToOrdinaryPolygons(polygons) {
  const ordinaryPolygons = [];
  for (const polygon of polygons) {
    if (polygon.geometry.type === 'MultiPolygon') {
      for (const coord of polygon.geometry.coordinates) {
        ordinaryPolygons.push(turf.polygon(coord));
      }
    } else {
      ordinaryPolygons.push(turf.polygon(polygon.geometry.coordinates));
    }
  }

  return ordinaryPolygons;
}

/**Проход по координатам, функция callback возвращает true, если точка была удалена */
export function walkCoordinates(coordinates, callback, vertexPath) {
  for (let i = 0; i < coordinates.length; i++) {
    const part = coordinates[i];
    const newVertextPath = vertexPath ? vertexPath + '.' + i : '' + i;
    if (Array.isArray(part[0])) {
      walkCoordinates(part, callback, newVertextPath);
    } else {
      if (callback(part, i, coordinates, newVertextPath)) {
        i--;
      }
    }
  }
}

/**Проход по контурам геометрии, функция callback возвращает true, если контур был удален */
export function walkContours(coordinates, callback, path) {
  if (!Array.isArray(coordinates[0][0])) {
    callback(coordinates, 0, null, '');
    return;
  }
  for (let i = 0; i < coordinates.length; i++) {
    const part = coordinates[i];
    const newPath = path ? path + '.' + i : '' + i;
    try {
      if (part && part[0] && Array.isArray(part[0][0])) {
        walkContours(part, callback, newPath);
      } else {
        if (callback(part, i, coordinates, newPath)) {
          i--;
        }
      }
    } catch (err) {
      console.error(err);
    }
  }
}

/**
 * Устранение самопересечений и бахромы.
 * Новый объект не создается. Возвращается ссылка на переданный объект
 */
export function smoothPolygon(feature) {
  const poly = feature.geometry.coordinates[0];
  if (feature.geometry.coordinates[1]) {
    throw 'метод принимает только простые полигоны';
  }
  //Вычисление критерия оптимизации для каждой пары точек
  //Размер окрестности точки для оптимизации, влияет на производительность и не дает схлопнуть основной объект
  const SMOOTH_LEN = Math.min(100, Math.ceil(poly.length / 5));
  //Массив объектов {val, row, col}
  const indexes = [];
  let pnt1, pnt2, pntNext1, pntNext2, koef, len, dist, isIntersect, jPoint;
  for (let i = 0; i < poly.length - 1; i++) {
    pnt1 = poly[i];
    pntNext1 = i + 1 < poly.length - 1 ? poly[i + 1] : poly[0];
    len = 0;
    for (let j = i - 1; j > i - SMOOTH_LEN; j--) {
      //Отбрасываем последнюю точку, которая совпадает с первой
      jPoint = j >= 0 ? j : poly.length - 1 + j;
      pnt2 = poly[jPoint];
      pntNext2 = j + 1 >= 0 ? poly[j + 1] : poly[poly.length + j + 1];
      //Расстояние между точками по прямой
      dist = Math.sqrt(Math.pow(pnt1[0] - pnt2[0], 2) + Math.pow(pnt1[1] - pnt2[1], 2));
      //Длина контура между точками
      len += Math.sqrt(Math.pow(pntNext2[0] - pnt2[0], 2) + Math.pow(pntNext2[1] - pnt2[1], 2));
      //Пересечение отрезков
      isIntersect = isSegmentsIntersect(pnt1, pntNext1, pnt2, pntNext2);
      //Длина дуги / расстояние между точками
      koef = dist && !isIntersect ? len / dist : Number.MAX_VALUE;
      if (koef > 1) {
        indexes.push({ val: koef, p1: jPoint, p2: i, isIntersect });
      }
    }
  }
  //Нахождение пар точек с критерием более установленного порога
  //Сортировка индекса
  indexes.sort((o1, o2) => {
    let ret = o2.val - o1.val;
    if (!ret) {
      ret = Math.abs(o2.p1 - o1.p1);
    }
    if (!ret) {
      ret = Math.abs(o1.p2 - o2.p2);
    }
    return ret;
  });
  //Прохождение по индексу от больших значений к меньшим
  //Предел коэффициента, ниже которого оптимизация не проводится
  const limit = 3;
  let obj, minPoint, maxPoint, includesZero, foundInterval;
  //Массив пар точек, между которыми все удаляется
  let deletedIntervals = [];
  for (let i = 0; i < indexes.length; i++) {
    obj = indexes[i];
    if (obj.val < limit) {
      break;
    }
    minPoint = Math.min(obj.p1, obj.p2);
    maxPoint = Math.max(obj.p1, obj.p2);
    includesZero = obj.p1 > obj.p2;
    foundInterval = deletedIntervals.some((part) => {
      if (includesZero && !part.includesZero) {
        return !(minPoint < part.minPoint && minPoint < part.maxPoint && maxPoint > part.minPoint && maxPoint > part.maxPoint);
      } else if (!includesZero && part.includesZero) {
        return !(minPoint > part.minPoint && maxPoint < part.maxPoint);
      } else {
        return !((minPoint <= part.minPoint && maxPoint <= part.minPoint) || (minPoint >= part.maxPoint && maxPoint >= part.maxPoint));
      }
    });
    if (!foundInterval && maxPoint - minPoint > 1) {
      deletedIntervals.push({ minPoint, maxPoint, includesZero });
    }
  }
  /*if (maxIndex - minIndex > poly.length / 2) {
    //Интервал включает начало отсчета точек
    indexes.push({ val: koef, p1: 0, p2: minIndex, isIntersect });
    indexes.push({ val: koef, p1: maxIndex, p2: poly.length - 1, isIntersect });
  }*/
  //Преобразование интервалов около точки 0 в два интервала на удаление
  for (let i = 0; i < deletedIntervals.length; i++) {
    const interval = deletedIntervals[i];
    if (interval.includesZero) {
      deletedIntervals.push({ minPoint: -1, maxPoint: interval.minPoint, includesZero: false });
      interval.includesZero = false;
      interval.minPoint = interval.maxPoint;
      interval.maxPoint = poly.length - 1;
    }
  }

  //Удаляем точки начиная с больших индексов
  deletedIntervals = deletedIntervals.filter((o) => o.maxPoint - o.minPoint > 0).sort((o1, o2) => o2.minPoint - o1.minPoint);
  for (const interval of deletedIntervals) {
    poly.splice(interval.minPoint + 1, interval.maxPoint - interval.minPoint - 1);
  }
  poly[poly.length - 1] = [poly[0][0], poly[0][1]];
  return feature;
}
