<template>
  <!--eslint-disable-->
  <DxPopup
    width="50vw"
    height="50vh"
    :animation="null"
    :visible="visible"
    :shading="false"
    :resizeEnabled="true"
    :title="title"
    :minWidth="500"
    :minHeight="400"
    :toolbarItems="popupToolbarItems()"
    @shown="shownPopup"
  >
    <template #titleTemplate>
      <PopupTitleTemplate
        :text="title"
        :showCloseButton="true"
        @closeClick="$emit('hidden')"
      />
    </template>
    <MonacoEditor
      :options="monacoEditorOptions"
      :value="value"
      ref="monacoEditor"
    />
  </DxPopup>
</template>

<script>
import DxPopup from 'devextreme-vue/popup';
import PopupTitleTemplate from '../Containers/PopupTitleTemplate';
import MonacoEditor from '../MonacoEditorBase';

export default {
  components: {
    MonacoEditor,
    DxPopup,
    PopupTitleTemplate
  },
  data() {
    return {
      monacoEditorOptions: {
        automaticLayout: true,
        language: 'javascript',
        minimap: {
          enabled: false
        },
        formatOnPaste: true
      }
    };
  },
  props: {
    visible: Boolean,
    value: String,
    title: {
      type: String,
      default: ''
    }
  },
  methods: {
    popupToolbarItems() {
      return [
        {
          toolbar: 'bottom',
          location: 'center',
          widget: 'dxButton',
          options: {
            text: 'Применить',
            type: 'success',
            onClick: () => {
              this.$emit('apply', this.$refs.monacoEditor.getValue());
            }
          }
        },
        {
          toolbar: 'bottom',
          location: 'center',
          widget: 'dxButton',
          options: {
            text: 'Отменить',
            onClick: () => {
              this.$emit('hidden');
            }
          }
        }
      ];
    },
    shownPopup() {
      this.$refs.monacoEditor.editor.getAction('editor.action.formatDocument').run();
      this.$emit('shown');
    }
  }
};
</script>
