<template>
  <!--eslint-disable-->
  <DxSelectBox
    valueExpr="id"
    :searchEnabled="true"
    :showClearButton="showClearButton"
    :minSearchLength="4"
    :disabled="disabled"
    :displayExpr="componentDisplayExpr"
    :dataSource="dataSource"
    placeholder="Введите имя или идентификатор..."
    :value="value"
    v-on="$listeners"
  >
    <DxButton
      v-if="showOpenComponentButton"
      :options="openComponentButton"
      name="openComponent"
      location="after"
    />
    <DxButton name="clear" />
    <DxButton name="dropDown" />
  </DxSelectBox>
</template>

<script>
import { DxSelectBox, DxButton } from 'devextreme-vue/select-box';
import componentsSearchStore from './componentsSearchStore';

export default {
  components: {
    DxSelectBox,
    DxButton
  },

  props: {
    value: null,
    showClearButton: {
      type: Boolean,
      default() {
        return true;
      }
    },
    showOpenButton: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      dataSource: null,
      openComponentButton: {
        icon: 'fas fa-external-link-alt',
        stylingMode: 'text',
        onClick: () => {
          this.$emit('openComponent');
        }
      }
    };
  },

  model: { prop: 'value', event: 'update:value' },

  computed: {
    showOpenComponentButton() {
      return this.showOpenButton && this.value;
    }
  },

  mounted() {
    this.dataSource = componentsSearchStore;
  },

  methods: {
    componentDisplayExpr(data) {
      if (this.value === 0) {
        return 'serviceComponent (общие действия)';
      } else {
        return data ? `${data.name} (${data.id} ${data.componenttype_name})` : '';
      }
    }
  }
};
</script>
