import getConfigs from './configUtils';
const OPTIONS = ['height', 'width', 'url', 'videoType', 'playImmediately'];

const CONFIGS_FIELDS = {
  height: {
    name: 'height',
    label: 'Высота',
    component: 'DxTextBox',
    options: {
      showClearButton: true
    },
    changeEvent: 'valueChanged'
  },
  width: {
    name: 'width',
    label: 'Ширина',
    component: 'DxTextBox',
    options: {
      showClearButton: true
    },
    changeEvent: 'valueChanged'
  },
  url: {
    name: 'url',
    label: 'Ссылка',
    component: 'DxTextBox',
    options: {
      showClearButton: true
    },
    changeEvent: 'valueChanged'
  },
  videoType: {
    name: 'videoType',
    label: 'Тип потока',
    component: 'DxSelectBox',
    options: {
      dataSource: [
        { text: 'm3u8', value: 'm3u8' },
        { text: 'mpd', value: 'mpd' }
      ],
      showClearButton: false,
      displayExpr: 'text',
      valueExpr: 'value'
    },
    changeEvent: 'optionChanged'
  },
  playImmediately: {
    name: 'playImmediately',
    label: 'Автоматический запуск видео',
    component: 'DxSelectBox',
    options: {
      dataSource: [
        { text: 'Да', value: 'true' },
        { text: 'Нет', value: 'false' }
      ],
      showClearButton: true,
      displayExpr: 'text',
      valueExpr: 'value'
    },
    changeEvent: 'optionChanged'
  }
};

export default getConfigs(OPTIONS, CONFIGS_FIELDS);
