import Vue from 'vue';
import * as turf from '@turf/turf';
import { deepCopy } from '@/utils/deep';
import FilterUtils from '@/components/Gis/utils/filterUtils';
import GisLegendStyleItemOptions from '@/models/GisLegendStyleItemOptions';
import CustomFilterFieldValue from '@/models/CustomFilterFieldValue';
import { getLegendStyleItems, getLayerSavedOptions } from './factory';

//Названия мутаций
const PREFIX = 'GisLayer';
export const SET_VISIBLE = `${PREFIX}/SET_VISIBLE`;
export const EXCLUDE = `${PREFIX}/EXCLUDE`;
export const SET_ENABLE = `${PREFIX}/SET_ENABLE`;
export const SET_EXPANDED = `${PREFIX}/SET_EXPANDED`;
export const SET_TEXT_VISIBLE = `${PREFIX}/SET_TEXT_VISIBLE`;
export const SET_LOADING = `${PREFIX}/SET_LOADING`;
export const SET_VALIDATION_LIST = `${PREFIX}/SET_VALIDATION_LIST`;
export const SET_SELECTED_VALIDATION_IDS_LIST = `${PREFIX}/SET_SELECTED_VALIDATION_IDS_LIST`;
export const SET_SELECTED_VALIDATION_IDS_LIST_FROM_DATASET = `${PREFIX}/SET_SELECTED_VALIDATION_IDS_LIST_FROM_DATASET`;
export const ADD_MAPBOX_LAYER = `${PREFIX}/ADD_MAPBOX_LAYER`;
export const SET_FILTER = `${PREFIX}/SET_FILTER`;
export const SET_LEGEND_STYLE_ITEM_VISIBLE = `${PREFIX}/SET_LEGEND_STYLE_ITEM_VISIBLE`;
export const SET_CHILD_LAYERS_LOADED = `${PREFIX}/SET_CHILD_LAYERS_LOADED`;
export const ADD_ITEM = `${PREFIX}/ADD_ITEM`;
export const SET_LEGEND_STYLE_ITEM_PROPERTY = `${PREFIX}/SET_LEGEND_STYLE_ITEM_PROPERTY`;
export const PARSE_ARC_GIS_LEGEND = `${PREFIX}/PARSE_ARC_GIS_LEGEND`;
export const PARSE_ARC_GIS_LAYERS = `${PREFIX}/PARSE_ARC_GIS_LAYERS`;
export const SET_WMS_LEGENDS = `${PREFIX}/SET_WMS_LEGENDS`;
export const SET_SELECTION_GEOJSON = `${PREFIX}/SET_SELECTION_GEOJSON`;
export const SET_SELECTED_IDS = `${PREFIX}/SET_SELECTED_IDS`;
export const SET_EDITING_IDS = `${PREFIX}/SET_EDITING_IDS`;
export const CLEAR_SELECTION = `${PREFIX}/CLEAR_SELECTION`;
export const SET_LEGEND_STYLE_ITEM_ID = `${PREFIX}/SET_LEGEND_STYLE_ITEM_ID`;
export const SET_TOOLTIP_POSITION = `${PREFIX}/SET_TOOLTIP_POSITION`;
export const SET_TOOLTIP_EXPRESSION = `${PREFIX}/SET_TOOLTIP_EXPRESSION`;
export const SET_ITEMS = `${PREFIX}/SET_ITEMS`;
export const CLEAR_FEATURES = `${PREFIX}/CLEAR_FEATURES`;
export const SET_FEATURES = `${PREFIX}/SET_FEATURES`;
export const ADD_FEATURES = `${PREFIX}/ADD_FEATURES`;
export const EDIT_FEATURES = `${PREFIX}/EDIT_FEATURES`;
export const DELETE_FEATURES = `${PREFIX}/DELETE_FEATURES`;
export const COMMIT_CHANGE_HISTORY = `${PREFIX}/COMMIT_CHANGE_HISTORY`;
export const UNDO = `${PREFIX}/UNDO`;
export const REDO = `${PREFIX}/REDO`;
export const CLEAR_CHANGES = `${PREFIX}/CLEAR_CHANGES`;
export const REMOVE_CHANGE = `${PREFIX}/REMOVE_CHANGE`;
export const REPLACE_CHANGE_KEY = `${PREFIX}/REPLACE_CHANGE_KEY`;
export const SET_FILTER_ID = `${PREFIX}/SET_FILTER_ID`;
export const SET_RASTER_STYLE = `${PREFIX}/SET_RASTER_STYLE`;
export const INIT_FILTER_FIELD_VALUES = `${PREFIX}/INIT_FILTER_FIELD_VALUES`;
export const SET_FILTER_FIELD_VALUE = `${PREFIX}/SET_FILTER_FIELD_VALUE`;
export const SET_FILTER_FIELD_DISPLAY_VALUE = `${PREFIX}/SET_FILTER_FIELD_DISPLAY_VALUE`;
export const CLEAR_FILTER_FIELD_VALUES = `${PREFIX}/CLEAR_FILTER_FIELD_VALUES`;
export const SET_FILTER_FIELD_VALUES = `${PREFIX}/SET_FILTER_FIELD_VALUES`;
export const SET_FILTER_FIELD_VALUE_OPTION = `${PREFIX}/SET_FILTER_FIELD_VALUE_OPTION`;
export const SET_MAPBOX_LAYERS = `${PREFIX}/SET_MAPBOX_LAYERS`;
export const INIT_LEGEND_STYLE_ITEMS = `${PREFIX}/INIT_LEGEND_STYLE_ITEMS`;
export const SET_FILTER_BUILDER_FIELDS = `${PREFIX}/SET_FILTER_BUILDER_FIELDS`;
export const REPAINT_LAYER = `${PREFIX}/REPAINT_LAYER`;
export const SET_HOVER_IDS = `${PREFIX}/SET_HOVER_IDS`;
export const SET_COMPLEX_FILTER_VALUE = `${PREFIX}/SET_COMPLEX_FILTER_VALUE`;
export const SET_FILTER_LIST_IDS = `${PREFIX}/SET_FILTER_LIST_IDS`;
export const SET_STATISTIC_FILTER_KEYS = `${PREFIX}/SET_STATISTIC_FILTER_KEYS`;
export const SET_CONSTRUCTOR_FILTER_VALUE = `${PREFIX}/SET_CONSTRUCTOR_FILTER_VALUE`;
export const SET_EDIT_LAYER_OBJECT = `${PREFIX}/SET_EDIT_LAYER_OBJECT`;
export const SET_RASTER_OPACITY = `${PREFIX}/SET_RASTER_OPACITY`;
export const SET_FORM_PARAMS = `${PREFIX}/SET_FORM_PARAMS`;
export const SET_RIGHTS = `${PREFIX}/SET_RIGHTS`;
export const RESET_CHILD_COMPONENTS = `${PREFIX}/RESET_CHILD_COMPONENTS`;
export const SET_FILTER_CROSS_LAYERS = `${PREFIX}/SET_FILTER_CROSS_LAYERS`;
export const SET_MINIMIZE_ONCE_VISIBLE = `${PREFIX}/SET_MINIMIZE_ONCE_VISIBLE`;
export const SET_REFRESH_FILTER_TOTAL_COUNT_FLAG = `${PREFIX}/SET_REFRESH_FILTER_TOTAL_COUNT_FLAG`;
export const SET_FAVORITE_POSITION = `${PREFIX}/SET_FAVORITE_POSITION`;

export default {
  [SET_VISIBLE](state, { componentId, visible }) {
    if (!state.componentOptions[componentId]) {
      return;
    }

    state.componentOptions[componentId].visible = visible;
  },

  [EXCLUDE](state, { componentId, excluded }) {
    if (!state.componentOptions[componentId]) {
      return;
    }

    state.componentOptions[componentId].excluded = excluded;
  },

  [SET_ENABLE](state, { componentId, enable }) {
    const parentGisId = (state.componentOptions[componentId] || {}).parentGisId || null;
    setLayerEnable(state, { componentId, enable });
    setLayerSavedOption(componentId, parentGisId, { enable }, state.componentOptions[parentGisId]);
  },

  [SET_EXPANDED](state, { componentId, expanded, saveToLocalStorage = true }) {
    const parentGisId = (state.componentOptions[componentId] || {}).parentGisId || null;
    state.componentOptions[componentId].expanded = expanded;
    if (saveToLocalStorage) {
      setLayerSavedOption(componentId, parentGisId, { expanded }, state.componentOptions[parentGisId]);
    }
  },

  [SET_TEXT_VISIBLE](state, { componentId, textVisible }) {
    const parentGisId = (state.componentOptions[componentId] || {}).parentGisId || null;
    setLayerTextVisible(state, { componentId, textVisible });
    setLayerSavedOption(componentId, parentGisId, { textVisible }, state.componentOptions[parentGisId]);
  },

  [SET_LOADING](state, { componentId, value }) {
    if (!state.layersLoading.hasOwnProperty(componentId)) {
      return;
    }

    state.layersLoading[componentId] = value;
  },

  [SET_VALIDATION_LIST](state, { componentId, validationList }) {
    if (!state.componentOptions[componentId]) {
      return;
    }

    state.componentOptions[componentId].validationList = validationList;
  },

  [SET_SELECTED_VALIDATION_IDS_LIST](state, { componentId, selectedValidationsIdsList }) {
    if (!state.componentOptions[componentId]) {
      return;
    }

    state.componentOptions[componentId].selectedValidationIdsList = selectedValidationsIdsList;
  },

  [SET_SELECTED_VALIDATION_IDS_LIST_FROM_DATASET](state, { componentId, selectedValidationsIdsList }) {
    if (!state.componentOptions[componentId]) {
      return;
    }

    state.componentOptions[componentId].selectedValidationsIdsListFromDataset = selectedValidationsIdsList;
  },

  //Сохранение геометрии, использованной для выделения
  [SET_SELECTION_GEOJSON](state, { componentId, geoJson = [], ids = [], not_ids = [], features = [], shiftKey = false }) {
    let selectionGeoJson = state.componentOptions[componentId].selectionGeoJson;
    const obj = { geoJson, ids, not_ids, features };

    Object.keys(selectionGeoJson).forEach((key) => {
      if (shiftKey) {
        (obj[key] || []).forEach((item) => {
          const index = selectionGeoJson[key].indexOf(item);
          if (index !== -1) {
            selectionGeoJson[key].splice(index, 1);
          } else {
            selectionGeoJson[key].push(item);
          }
        });
      } else {
        state.componentOptions[componentId].selectionGeoJson[key] = obj[key];
      }
    });
    //Для функционирования старой реализации фильтрации выделенных объектов
    let filterValue = null;
    if (selectionGeoJson.ids.length) {
      if (selectionGeoJson.ids.length === 1) {
        filterValue = ['id', '=', selectionGeoJson.ids[0]];
      } else {
        filterValue = selectionGeoJson.ids.reduce((accumulator, id, index) => {
          if (index) {
            accumulator.push('or');
          }
          accumulator.push(['id', '=', id]);
          return accumulator;
        }, []);
      }
    }
    state.componentOptions[componentId].filter_value = filterValue;
  },

  [SET_SELECTED_IDS](state, { componentId, ids, shiftKey = false }) {
    let selectedIds = state.componentOptions[componentId].selectedIds;

    if (shiftKey) {
      ids.forEach((featureId) => {
        const index = selectedIds.indexOf(featureId);
        if (index !== -1) {
          selectedIds.splice(index, 1);
        } else {
          selectedIds.push(featureId);
        }
      });
    } else {
      state.componentOptions[componentId].selectedIds = ids;
    }
  },

  /**
   * Установка идентификаторов редактируемых объектов
   * @param {Array} ids
   */
  [SET_EDITING_IDS](state, { componentId, ids }) {
    if (!(ids && Array.isArray(ids))) {
      throw 'Ids must be array';
    }
    state.componentOptions[componentId].editingIds = ids;
  },

  [CLEAR_SELECTION](state, { layerComponentId }) {
    let layerOptions = state.componentOptions[layerComponentId];
    if (!layerOptions) {
      return;
    }

    layerOptions.selectedIds = [];
  },

  [SET_LEGEND_STYLE_ITEM_ID](state, { layerComponentId, legendStyleItemId, visible, legendStyleItemIds }) {
    let layerOptions = state.componentOptions[layerComponentId];
    let currentRule = layerOptions.legendStyleItemId;
    const ruleHistory = [];

    if (legendStyleItemId === currentRule && visible === false) {
      const index = legendStyleItemIds.indexOf(currentRule);
      let nextIndex = index - 1;

      while (nextIndex >= 0 && !legendStyleItemIds.includes(legendStyleItemIds[nextIndex])) {
        nextIndex--;
      }

      if (nextIndex >= 0) {
        currentRule = legendStyleItemIds[nextIndex];
      } else {
        currentRule = null;
      }
    } else if (legendStyleItemId !== currentRule && visible === true) {
      if (currentRule) {
        ruleHistory.push(currentRule);
      }
      currentRule = legendStyleItemId;
    }

    layerOptions.legendStyleItemId = currentRule;
  },

  [SET_TOOLTIP_POSITION](state, { layerComponentId, position }) {
    let layerOptions = state.componentOptions[layerComponentId];
    if (!layerOptions) {
      return;
    }

    layerOptions.tooltipPosition = position;
  },

  [SET_TOOLTIP_EXPRESSION](state, { layerComponentId, expression }) {
    let layerOptions = state.componentOptions[layerComponentId];
    if (!layerOptions) {
      return;
    }

    layerOptions.tooltipExpression = expression;
  },

  [SET_FILTER](state, { componentId, value }) {
    state.componentOptions[componentId].filterValue = value;
    state.componentOptions[componentId].filter_value = value;
  },

  [ADD_MAPBOX_LAYER](state, { componentId, mapbox }) {
    let layerOptions = state.componentOptions[componentId];
    layerOptions.mapbox = mapbox;
  },

  [SET_LEGEND_STYLE_ITEM_VISIBLE](state, { layerComponentId, legendStyleItemId, visible }) {
    setLegendStyleItemVisible(state, { layerComponentId, legendStyleItemId, visible });
  },

  [SET_CHILD_LAYERS_LOADED](state, { componentId, value }) {
    state.componentOptions[componentId].childLayersLoaded = value;
  },

  [ADD_ITEM](state, { layerComponentId, childComponentId }) {
    let items = state.componentOptions[layerComponentId].items;

    if (items && items.indexOf(childComponentId) === -1) {
      items.push(childComponentId);
    }
  },

  /** Очистка локальных geojson объектов*/
  [CLEAR_FEATURES](state, { layerComponentId }) {
    state.componentOptions[layerComponentId].featuresDict = {};
  },

  /** Добавление локальных geojson объектов, которые должны заменить векторые или wms*/
  [SET_FEATURES](state, { layerComponentId, features, keyField = 'id' }) {
    try {
      for (const feat of features) {
        Vue.set(state.componentOptions[layerComponentId].featuresDict, feat.id || (feat.properties || {})[keyField], feat);
      }
    } catch (err) {
      //TODO: обработка ошибки
      window.console.error('SET_FEATURES: ' + err);
    }
  },

  /* Добавление объектов
   * @param {Array<Object>} features
   */
  [ADD_FEATURES](state, { layerComponentId, features, noHistory = false, ignoreSingleFeature = false, useCommit = false }) {
    const addOperationData = {
      features: []
    };
    const componentOptions = state.componentOptions[layerComponentId];

    if (!ignoreSingleFeature && componentOptions.singleFeature) {
      //Режим замены единственного объекта
      const existingFeatures = Object.values(componentOptions.featuresDict);
      const featureNew = features[0];
      if (existingFeatures[0]) {
        featureNew.id = existingFeatures[0].id;
        featureNew.properties = { ...existingFeatures[0].properties };
      }
      //Костыль для временного слоя
      if (!featureNew.properties[componentOptions.keyField]) {
        featureNew.properties[componentOptions.keyField] = featureNew.id;
      }
      if (existingFeatures[0] && !isNaN(parseInt(featureNew.id.toString()))) {
        //Объект существовал
        addToEditedIds(componentOptions, featureNew.id);
      } else {
        //Новый объект
        addToAddedIds(componentOptions, featureNew.id);
      }
      addOperationData.features.push(featureNew);
      Vue.set(componentOptions.featuresDict, featureNew.id, featureNew);
    } else {
      for (const feature of features) {
        const key = feature.id || feature.properties[componentOptions.keyField];

        //Костыль для временного слоя
        if (!feature.properties[componentOptions.keyField]) {
          feature.properties[componentOptions.keyField] = feature.id;
        }
        addEssentialProperties(componentOptions, feature);

        if (!noHistory) {
          addToAddedIds(componentOptions, key);
        }

        addOperationData.features.push(feature);
        Vue.set(componentOptions.featuresDict, feature.id, feature);
      }
    }

    if (!noHistory) {
      //Сохранение в историю
      saveOperationToHistory(
        componentOptions,
        {
          type: 'add',
          data: addOperationData
        },
        useCommit
      );
      //logHistory(componentOptions);
    }
  },

  /**
   * Изменение объектов
   * @param {Array<Object>} features
   */
  [EDIT_FEATURES](state, { layerComponentId, features, noHistory = false, oldFeatures = null, useCommit = false }) {
    const editOperationData = {
      features: []
    };
    const componentOptions = state.componentOptions[layerComponentId];

    for (const feature of features) {
      const key = feature.id || feature.properties[componentOptions.keyField];

      let oldFeature;
      //Если oldFeature был передан, используем его
      if (oldFeatures && Array.isArray(oldFeatures)) {
        oldFeature = oldFeatures.find((feat) => feat.id === feature.id);
      }
      if (!oldFeature) {
        oldFeature = {};
        deepCopy(componentOptions.featuresDict[key], oldFeature);
      }
      let newFeature = {};
      deepCopy(feature, newFeature);

      editOperationData.features.push({
        oldFeature,
        newFeature
      });

      if (!noHistory) {
        addToEditedIds(componentOptions, key, false);
      }
      Vue.set(componentOptions.featuresDict, feature.id, feature);
    }

    if (!noHistory) {
      //Сохранение в историю
      saveOperationToHistory(
        componentOptions,
        {
          type: 'edit',
          data: editOperationData
        },
        useCommit
      );
      //logHistory(componentOptions);
    }
  },

  /**
   * Удаление объектов
   * @param {*} features
   */
  [DELETE_FEATURES](state, { layerComponentId, features, noHistory = false, useCommit = false }) {
    const deletedFeatures = [];
    const componentOptions = state.componentOptions[layerComponentId];
    const selectedIds = componentOptions.selectedIds || [];

    for (const feature of features) {
      const featureId = feature.id;

      //Удаление из числа выбранных
      const selectedIndex = selectedIds.indexOf(featureId);
      if (selectedIndex !== -1) {
        selectedIds.splice(selectedIndex, 1);
      }

      if (componentOptions.featuresDict.hasOwnProperty(featureId)) {
        deletedFeatures.push(componentOptions.featuresDict[featureId]);
        if (!noHistory) {
          //Добавление в идентификатора в массив идентификаторов на удаление
          addToDeletedIds(componentOptions, featureId);
        }
        Vue.delete(componentOptions.featuresDict, featureId);
      }
    }

    if (!noHistory) {
      //Добавление шага в историю изменений
      //Сохранение в историю
      saveOperationToHistory(
        componentOptions,
        {
          type: 'delete',
          data: { features: deletedFeatures }
        },
        useCommit
      );
      //logHistory(componentOptions);
    }
  },

  [COMMIT_CHANGE_HISTORY](state, { layerComponentId }) {
    const componentOptions = state.componentOptions[layerComponentId];
    for (const op of componentOptions.operationHistory) {
      if (op.waitCommit) {
        delete op.waitCommit;
      }
    }
  },

  /**
   * Откат изменений на шаг назад
   */
  [UNDO](state, { layerComponentId }) {
    const componentOptions = state.componentOptions[layerComponentId];
    const operation = componentOptions.operationHistory[componentOptions.historyIndex];
    if (!operation) {
      return;
    }
    switch (operation.type) {
      case 'add':
        operation.data.features.forEach((feature) => {
          Vue.delete(componentOptions.featuresDict, feature.id);
          addToDeletedIds(componentOptions, feature.id);
          const idx = componentOptions.selectedIds.indexOf(feature.id);
          if (idx >= 0) {
            componentOptions.selectedIds.splice(idx, 1);
          }
          //TODO:
          //triggerFeatureUndo(operation.type, feature);
        });
        break;
      case 'delete':
        operation.data.features.forEach((feature) => {
          Vue.set(componentOptions.featuresDict, feature.id, feature);
          addToAddedIds(componentOptions, feature.id);
          //TODO:
          //triggerFeatureUndo(operation.type, feature);
        });
        break;
      case 'edit':
        operation.data.features.forEach(({ oldFeature, newFeature }) => {
          Vue.set(componentOptions.featuresDict, newFeature.id, oldFeature);
          addToEditedIds(componentOptions, newFeature.id, true);
          //TODO:
          //triggerFeatureUndo(operation.type, oldFeature);
        });
        break;
    }
    componentOptions.historyIndex--;
    //this.triggerHistoryIndexChanged();
  },

  /**
   * Накат изменений на шаг вперед
   */
  [REDO](state, { layerComponentId }) {
    const componentOptions = state.componentOptions[layerComponentId];
    componentOptions.historyIndex++;
    const operation = componentOptions.operationHistory[componentOptions.historyIndex];

    switch (operation.type) {
      case 'add':
        operation.data.features.forEach((feature) => {
          Vue.set(componentOptions.featuresDict, feature.id, feature);
          addToAddedIds(componentOptions, feature.id);
          //TODO:
          //triggerFeatureRedo(operation.type, feature);
        });

        break;
      case 'delete':
        operation.data.features.forEach((feature) => {
          Vue.delete(componentOptions.featuresDict, feature.id);
          addToDeletedIds(componentOptions, feature.id, true);
          //TODO:
          //triggerFeatureRedo(operation.type, feature);
        });
        break;
      case 'edit':
        operation.data.features.forEach(({ oldFeature, newFeature }) => {
          Vue.set(componentOptions.featuresDict, oldFeature.id, newFeature);
          addToEditedIds(componentOptions, oldFeature.id, false);
          //TODO:
          //triggerFeatureRedo(operation.type, newFeature);
        });
        break;
    }
    //this.triggerHistoryIndexChanged();
  },

  /**
   * Удаление объекта из списка измененных
   * @param {String} key - ИД объекта
   */
  [REMOVE_CHANGE](state, { layerComponentId, key }) {
    const componentOptions = state.componentOptions[layerComponentId];
    let index = componentOptions.addedIds.indexOf(key);
    if (index >= 0) {
      componentOptions.addedIds.splice(index, 1);
    }
    index = componentOptions.deletedIds.indexOf(key);
    if (index >= 0) {
      componentOptions.deletedIds.splice(index, 1);
    }
    if (componentOptions.editedIds.hasOwnProperty(key)) {
      Vue.delete(componentOptions.editedIds, key);
    }
  },

  /**
   * Замена старого ИД объекта на новый
   * @param {String} keyOld - старый ИД
   * @param {String} keyNew - новый ИД
   */
  [REPLACE_CHANGE_KEY](state, { layerComponentId, keyOld, keyNew, properties }) {
    const componentOptions = state.componentOptions[layerComponentId];
    let index = componentOptions.addedIds.indexOf(keyOld);
    if (index >= 0) {
      componentOptions.addedIds.splice(index, 1, keyNew);
    }
    index = componentOptions.deletedIds.indexOf(keyOld);
    if (index >= 0) {
      componentOptions.deletedIds.splice(index, 1, keyNew);
      componentOptions.permanentDeletedIds.splice(index, 1, keyNew);
    }
    index = componentOptions.permanentDeletedIds.indexOf(keyOld);
    if (index >= 0) {
      componentOptions.permanentDeletedIds.splice(index, 1, keyNew);
    }
    if (componentOptions.editedIds.hasOwnProperty(keyOld)) {
      Vue.set(componentOptions.editedIds, keyNew, componentOptions.editedIds[keyOld]);
      Vue.delete(componentOptions.editedIds, keyOld);
    }
    if (componentOptions.featuresDict[keyOld]) {
      Vue.set(componentOptions.featuresDict, keyNew, componentOptions.featuresDict[keyOld]);
      componentOptions.featuresDict[keyNew].properties = { ...properties };
      Vue.delete(componentOptions.featuresDict, keyOld);
    }

    //Замена выделенных ИД
    for (let i = 0; i < componentOptions.selectedIds.length; i++) {
      if (componentOptions.selectedIds[i] === keyOld) {
        componentOptions.selectedIds[i] = keyNew;
      }
    }

    //Замена ИД в объектах, сохраненных в истории
    //Надо заменять также атрибуты, чтобы объект не исчезал с карты при наличии правил
    for (const history of componentOptions.operationHistory) {
      for (const feature of history.data.features) {
        replaceFeatureIds(feature, keyOld, keyNew, properties);
        if (feature.oldFeature) {
          replaceFeatureIds(feature.oldFeature, keyOld, keyNew, properties);
        }
        if (feature.newFeature) {
          replaceFeatureIds(feature.newFeature, keyOld, keyNew, properties);
        }
      }
    }
  },

  /**
   * Сброс несохраненных изменений
   */
  [CLEAR_CHANGES](state, { layerComponentId }) {
    const componentOptions = state.componentOptions[layerComponentId];
    componentOptions.addedIds = [];
    componentOptions.deletedIds = [];
    componentOptions.editedIds = {};
  },

  [SET_LEGEND_STYLE_ITEM_PROPERTY](state, { layerComponentId, legendStyleItemId, propertyName, propertyValue }) {
    let legendStyleItem = state.componentOptions[layerComponentId].legendStyleItems[legendStyleItemId];
    legendStyleItem[propertyName] = propertyValue;
  },

  [PARSE_ARC_GIS_LEGEND](state, { layerComponentId, arcGisLegend }) {
    let layerOptions = state.componentOptions[layerComponentId];
    layerOptions.arcGisLegend = arcGisLegend;

    let legendStyleItems = layerOptions.legendStyleItems;
    const totalStyleItemCount = Object.values(legendStyleItems).filter((legendStyleItem) => {
      return legendStyleItem.type === 'layer' && legendStyleItem.parentId === layerComponentId;
    }).length;

    arcGisLegend.layers.forEach((arcGisLegendLayer) => {
      let legendStyleItem = legendStyleItems[arcGisLegendLayer.layerId];
      if (!legendStyleItem) {
        return;
      }

      if (arcGisLegendLayer.legend.length === 1) {
        legendStyleItem.iconUrl = `data:${arcGisLegendLayer.legend[0].contentType};base64,${arcGisLegendLayer.legend[0].imageData}`;
        legendStyleItem.caption = arcGisLegendLayer.legend[0].label || arcGisLegendLayer.layerName;
      } else {
        legendStyleItem.caption = arcGisLegendLayer.layerName;

        if (totalStyleItemCount === 1) {
          legendStyleItem.parentId = null;
        }

        arcGisLegendLayer.legend.forEach((arcGisLegendItem, index) => {
          const newLegendStyleItemId = `${arcGisLegendLayer.layerId}_${index}`;
          const newLegendStyleItem = new GisLegendStyleItemOptions({
            id: newLegendStyleItemId,
            parentId: totalStyleItemCount === 1 ? layerComponentId : arcGisLegendLayer.layerId,
            caption: arcGisLegendItem.label,
            visible: false,
            expanded: true,
            iconCss: null,
            iconExpandedCss: null,
            iconColor: null,
            iconUrl: `data:${arcGisLegendItem.contentType};base64,${arcGisLegendItem.imageData}`,
            type: 'rule',
            mapboxLayerConfigs: []
          });

          Vue.set(legendStyleItems, newLegendStyleItemId, newLegendStyleItem);

          if (totalStyleItemCount > 1) {
            if (!legendStyleItem.items) {
              legendStyleItem.items = null;
            }
            if (!legendStyleItem.items.includes(newLegendStyleItemId)) {
              legendStyleItem.items.push(newLegendStyleItemId);
            }
          }
        });
      }
    });
  },

  [PARSE_ARC_GIS_LAYERS](state, { layerComponentId, arcGisLayers }) {
    let layerOptions = state.componentOptions[layerComponentId];
    layerOptions.arcGisLayers = arcGisLayers;

    let legendStyleItems = layerOptions.legendStyleItems;

    arcGisLayers.layers.forEach((arcGisLayer) => {
      let legendStyleItem = legendStyleItems[arcGisLayer.id];
      if (!legendStyleItem) {
        return;
      }

      if (arcGisLayer.type === 'Group Layer') {
        legendStyleItem.caption = arcGisLayer.name;

        arcGisLayer.subLayers.forEach((subLayer) => {
          let subLayerLegendStyleItem = legendStyleItems[subLayer.id];
          if (subLayerLegendStyleItem) {
            subLayerLegendStyleItem.parentId = arcGisLayer.id;
          }

          if (!legendStyleItem.items) {
            legendStyleItem.items = null;
          }
          //Не включать в потомки слои, не указанные в списке show
          if (!legendStyleItem.items.includes(subLayer.id) && legendStyleItems[subLayer.id]) {
            legendStyleItem.items.push(subLayer.id);
          }
        });
      }
    });
  },

  [SET_ITEMS](state, { layerComponentId, components, items }) {
    let layerOptions = state.componentOptions[layerComponentId];

    if (!layerOptions) {
      return;
    }

    let cardComponentId = null;
    let tableComponentId = null;
    let filterComponentId = null;
    let tooltipComponentId = null;
    let statsComponentId = null;

    items.forEach((componentId) => {
      const componentConfig = components[componentId];

      if (!componentConfig) {
        return;
      }

      switch (componentConfig.layerComponentRole) {
        case 'card':
          cardComponentId = componentId;
          break;
        case 'table':
          tableComponentId = componentId;
          break;
        case 'filter':
          filterComponentId = componentId;
          break;
        case 'tooltip':
          tooltipComponentId = componentId;
        case 'stats':
          statsComponentId = componentId;
      }
    });

    /*Пусть этот комментарий остается, чтобы напоминать как было
    эта переделка - попытка избежать лишних пересчетов
    layerOptions.cardComponentId = cardComponentId;
    layerOptions.tableComponentId = tableComponentId;
    layerOptions.filterComponentId = filterComponentId;
    layerOptions.tooltipComponentId = tooltipComponentId;
    */

    Vue.set(state.layerCardComponentIds, layerComponentId, cardComponentId);
    Vue.set(state.layerTableComponentIds, layerComponentId, tableComponentId);
    Vue.set(state.layerFilterComponentIds, layerComponentId, filterComponentId);
    Vue.set(state.layerTooltipComponentIds, layerComponentId, tooltipComponentId);
    Vue.set(state.layerStatsComponentIds, layerComponentId, statsComponentId);

    //TODO: Выяснить зачем нужна была фильтрация по типу компонента GisLayer и GisGroupLayer
    /*
    layerOptions.items = items.filter((componentId) => {
      const componentConfig = components[componentId];
      return componentConfig && ['GisLayer', 'GisGroupLayer'].includes(componentConfig.componentId);
    });
    */
    layerOptions.items = items;
  },

  [SET_WMS_LEGENDS](state, { layerComponentId, wmsLegends }) {
    const layerOptions = state.componentOptions[layerComponentId];
    Vue.set(layerOptions, 'wmsLegends', []);
    state.componentOptions[layerComponentId].wmsLegends = wmsLegends;
    Vue.set(layerOptions, 'wmsLegends', wmsLegends);

    const legendStyleItems = layerOptions.legendStyleItems;
    for (const wmsLegend of wmsLegends) {
      const newLegendStyleItemId = wmsLegend.layerId;
      const newLegendStyleItem = new GisLegendStyleItemOptions({
        id: newLegendStyleItemId,
        parentId: layerComponentId,
        visible: layerOptions.visible,
        expanded: true,
        iconCss: null,
        iconExpandedCss: null,
        iconColor: null,
        iconUrl: wmsLegend.url,
        wmsSingleRule: wmsLegend.singleRule,
        type: 'rule',
        mapboxLayerConfigs: []
      });

      Vue.set(legendStyleItems, newLegendStyleItemId, newLegendStyleItem);
    }
  },

  [SET_FILTER_ID](state, { layerComponentId, filterId }) {
    state.componentOptions[layerComponentId].filterId = filterId;
  },

  [SET_RASTER_STYLE](state, { layerComponentId, rasterStyle }) {
    state.componentOptions[layerComponentId].rasterStyle = rasterStyle;
  },

  [INIT_FILTER_FIELD_VALUES](state, { layerComponentId, filterLayerComponentId }) {
    let layerOptions = state.componentOptions[layerComponentId];
    if (!layerOptions) {
      return;
    }
    const filterLayerOptions = state.componentOptions[filterLayerComponentId];
    if (!filterLayerOptions) {
      return;
    }

    //TODO: добавить поля where и _config_select_mode, _config_buffer_size
    const EMPTY_FILTER_FIELD_VALUES = {
      on: false,
      fieldValues: {},
      selectMode: 'intersect_contains',
      bufferSize: 0
    };
    let filterFieldValues = layerOptions.filterFieldValues
      ? layerOptions.filterFieldValues[filterLayerComponentId] || EMPTY_FILTER_FIELD_VALUES
      : EMPTY_FILTER_FIELD_VALUES;

    for (let fieldName in filterLayerOptions.filterFieldOptions) {
      if (!filterFieldValues.fieldValues[fieldName]) {
        filterFieldValues.fieldValues[fieldName] = new CustomFilterFieldValue(filterLayerOptions.filterFieldOptions[fieldName]);
      }
    }

    layerOptions.filterFieldValues = {
      ...layerOptions.filterFieldValues,
      [filterLayerComponentId]: filterFieldValues
    };
  },

  [SET_FILTER_FIELD_VALUE](state, { layerComponentId, filterLayerComponentId, fieldName, value }) {
    setFilterField(state, layerComponentId, filterLayerComponentId, fieldName, 'value', value);
  },

  [SET_FILTER_FIELD_DISPLAY_VALUE](state, { layerComponentId, filterLayerComponentId, fieldName, displayValue }) {
    setFilterField(state, layerComponentId, filterLayerComponentId, fieldName, 'displayValue', displayValue);
  },

  [CLEAR_FILTER_FIELD_VALUES](state, { layerComponentId }) {
    let layerOptions = state.componentOptions[layerComponentId];
    if (!layerOptions) {
      return;
    }

    const { filterFieldValues, complexFilterValue, filterListIds, constructorFilterValue, constructorFilterParams, statisticFilterKeys } = layerOptions;
    if (!filterFieldValues && !complexFilterValue && !filterListIds && !constructorFilterValue && !constructorFilterParams && !statisticFilterKeys ) {
      return;
    }

    //Очистка полей фильтрации с вкладок фильтра
    if (filterFieldValues) {
      Object.keys(filterFieldValues).forEach((filterLayerComponentId) => {
        const layerFilterField = filterFieldValues[filterLayerComponentId];
        layerFilterField.on = false;
        layerFilterField.selectMode = 'intersect_contains';
        layerFilterField.bufferSize = 0;

        Object.values(layerFilterField.fieldValues).forEach((filterFieldValue) => {
          filterFieldValue.value = null;
          filterFieldValue.label = null;
        });
      });
    }

    //Очистка поля фильтрации filterBuilder
    if (complexFilterValue) {
      state.componentOptions[layerComponentId].complexFilterValue = null;
    }

    //Очистка поля фильтрации "Реестр объектов"
    if (filterListIds) {
      state.componentOptions[layerComponentId].filterListIds = null;
    }

    //Очистка поля фильтрации "Статистика"
    if (statisticFilterKeys) {
      state.componentOptions[layerComponentId].statisticFilterKeys = null;
    }

    //Очистка полей фильтрации, сделанных на конструкторе
    if (constructorFilterValue) {
      state.componentOptions[layerComponentId].constructorFilterValue = null;
    }

    //Очистка специального блока с параметрами фильтрации (актуально для проекта "zdrav")
    if (constructorFilterParams) {
      state.componentOptions[layerComponentId].constructorFilterParams = null;
    }
  },

  [SET_FILTER_FIELD_VALUES](state, { layerComponentId, filterFieldValues }) {
    if (!state.componentOptions[layerComponentId]) {
      return;
    }

    state.componentOptions[layerComponentId].filterFieldValues = filterFieldValues;
  },

  [SET_FILTER_FIELD_VALUE_OPTION](state, { layerComponentId, filterLayerComponentId, fieldName, value }) {
    if (!state.componentOptions[layerComponentId]) {
      return;
    }

    let filterFieldValues = state.componentOptions[layerComponentId].filterFieldValues;
    if (!filterFieldValues) {
      return;
    }

    let filterLayerFilter = filterFieldValues[filterLayerComponentId];
    if (!filterLayerFilter) {
      return;
    }

    filterLayerFilter[fieldName] = value;
  },

  [SET_MAPBOX_LAYERS](state, { layerComponentId, layerStyles, selectionLayerStyles, hoverLayerStyles }) {
    const layerOptions = state.componentOptions[layerComponentId];

    if (!layerOptions) {
      return;
    }

    layerOptions.mapboxLayers = layerStyles;
    layerOptions.mapboxSelectionLayers = selectionLayerStyles;
    layerOptions.mapboxHoverLayers = hoverLayerStyles;
  },

  [INIT_LEGEND_STYLE_ITEMS](state, { layerComponentId }) {
    //TODO: возможно надо убрать эту мутацию и инициализацию в фабрике и переделать на геттер?
    const layerOptions = state.componentOptions[layerComponentId];

    if (!layerOptions) {
      return;
    }

    //Проверка установленного пользователем свойста visible
    const parentGisId = layerOptions.parentGisId || null;
    const { visible } = getLayerSavedOptions(layerComponentId, parentGisId, state.componentOptions[parentGisId]);
    if (visible !== undefined) {
      state.componentOptions[layerComponentId].visible = visible;
    }

    layerOptions.legendStyleItems = getLegendStyleItems(layerComponentId, layerOptions);
    layerOptions.legendStyleItemIds = Object.keys(layerOptions.legendStyleItems).filter((legendStyleItemKey) => {
      return layerOptions.legendStyleItems[legendStyleItemKey].parentId === layerComponentId;
    });
  },

  [SET_FILTER_BUILDER_FIELDS](state, { layerComponentId, filterBuilderFields }) {
    const layerOptions = state.componentOptions[layerComponentId];

    if (!layerOptions) {
      return;
    }

    layerOptions.filterBuilderFields = filterBuilderFields;
  },

  [REPAINT_LAYER](state, { componentId }) {
    state.componentOptions[componentId].paramForRepaint++;
  },

  [SET_HOVER_IDS](state, { componentId, ids }) {
    state.componentOptions[componentId].hoverFeatureIds = ids;
  },

  [SET_COMPLEX_FILTER_VALUE](state, { layerComponentId, value }) {
    if (!state.componentOptions[layerComponentId]) {
      return;
    }

    state.componentOptions[layerComponentId].complexFilterValue = value;
  },

  /**
   * Мутация устанавливает значение filterListIds
   * это идентификаторы списков объектов, списки для исключения со знаком "-"
   * @param {*} state
   * @param {*} param1
   * @returns
   */
  [SET_FILTER_LIST_IDS](state, { layerComponentId, filterListIds }) {
    if (!state.componentOptions[layerComponentId]) {
      return;
    }

    if (filterListIds && filterListIds.length > 0) {
      state.componentOptions[layerComponentId].filterListIds = filterListIds;
    } else {
      state.componentOptions[layerComponentId].filterListIds = null;
    }
  },

  /**
   * Мутация устанавливает значение statisticFilterKeys
   * Массив значений фильтра статистики слоя
   * @param {*} state
   * @param {*} param1
   * @returns
   */
  [SET_STATISTIC_FILTER_KEYS](state, { layerComponentId, statisticFilterKeys }) {
    if (!state.componentOptions[layerComponentId]) {
      return;
    }

    if (statisticFilterKeys && statisticFilterKeys.length > 0) {
      state.componentOptions[layerComponentId].statisticFilterKeys = statisticFilterKeys;
    } else {
      state.componentOptions[layerComponentId].statisticFilterKeys = null;
    }
  },

  [SET_EDIT_LAYER_OBJECT](state, { componentId, editingObjectId }) {
    if (!state.componentOptions[componentId]) {
      return;
    }

    state.componentOptions[componentId].editingObjectId = editingObjectId;
  },

  [SET_RASTER_OPACITY](state, { componentId, opacity }) {
    if (!state.componentOptions[componentId]) {
      return;
    }

    state.componentOptions[componentId].rasterOpacity = opacity;
  },

  [SET_RIGHTS](state, { componentId, rights = {} }) {
    if (!state.componentOptions[componentId]) {
      return;
    }

    let currentRights = state.componentOptions[componentId].rights;
    currentRights = {
      ...currentRights,
      ...rights
    };
  },

  [SET_CONSTRUCTOR_FILTER_VALUE](state, { layerComponentId, value, params = null }) {
    if (!state.componentOptions[layerComponentId]) {
      return;
    }

    state.componentOptions[layerComponentId].constructorFilterValue = value;

    //Костыль для поля list_ids, убираем его из параметров и добавляем в спец поле
    if (params && Array.isArray(params.list_ids)) {
      if (params.list_ids.length > 0) {
        state.componentOptions[layerComponentId].filterListIds = params.list_ids;
      } else {
        state.componentOptions[layerComponentId].filterListIds = null;
      }
      delete params.list_ids;
    }

    state.componentOptions[layerComponentId].constructorFilterParams = params;
  },

  [SET_FORM_PARAMS](state, { layerComponentId, value }) {
    state.componentOptions[layerComponentId].formParamsValue = value;
  },

  [RESET_CHILD_COMPONENTS](state, { layerComponentId }) {
    state.componentOptions[layerComponentId].loadChildComponentsPromise = null;
    Vue.set(state.layerTableComponentIds, layerComponentId, null);
    Vue.set(state.layerCardComponentIds, layerComponentId, null);
    Vue.set(state.layerStatsComponentIds, layerComponentId, null);
  },

  [SET_FILTER_CROSS_LAYERS](state, { layerComponentId, filterCrossLayers }) {
    state.componentOptions[layerComponentId].filterCrossLayers = filterCrossLayers;
  },

  [SET_MINIMIZE_ONCE_VISIBLE](state, { layerComponentId, value }) {
    if (layerComponentId) {
      state.componentOptions[layerComponentId].minimizeOnceVisible = value;
    }
  },

  [SET_REFRESH_FILTER_TOTAL_COUNT_FLAG](state, { layerComponentId, value }) {
    if (layerComponentId) {
      state.componentOptions[layerComponentId].refreshFilterTotalDataFlag = value;
    }
  },

  [SET_FAVORITE_POSITION](state, { layerComponentId, value }) {
    if (layerComponentId && state.componentOptions[layerComponentId]) {
      state.componentOptions[layerComponentId].favoritePos = value;
    }
  }
};

/**
 * Переключение доступности слоя
 * TODO: Переделать на действие?
 */
function setLayerEnable(state, { componentId, enable }) {
  const layerOptions = state.componentOptions[componentId];

  if (layerOptions.group) {
    layerOptions.items.forEach((childLayerComponentId) => {
      setLayerEnable(state, {
        componentId: childLayerComponentId,
        enable
      });
    });
  } else {
    layerOptions.enable = enable;
  }
}

function setLegendStyleItemVisible(state, { layerComponentId, legendStyleItemId, visible }) {
  let legendStyleItem = state.componentOptions[layerComponentId].legendStyleItems[legendStyleItemId];

  if (Array.isArray(legendStyleItem.items) && legendStyleItem.items.length) {
    legendStyleItem.items.forEach((childLegendStyleItemId) => {
      setLegendStyleItemVisible(state, {
        layerComponentId,
        legendStyleItemId: childLegendStyleItemId,
        visible
      });
    });
  }

  legendStyleItem.visible = visible;
}

/**
 * Преключение видимости слоя
 * @param {*} state
 * @param {*} param1
 */
function setLayerTextVisible(state, { componentId, textVisible }) {
  let layerOptions = state.componentOptions[componentId];

  if (!layerOptions) {
    return;
  }

  layerOptions.textVisible = textVisible;
}

function addEssentialProperties(componentOptions, feature) {
  let filterDataFields = [];
  for (const config of componentOptions.mapboxLayers) {
    if (config.filter && config.filter[0]) {
      filterDataFields = filterDataFields.concat(FilterUtils.getUsedAttributes(config.filter, componentOptions.dataFields));
    }
  }
  for (const fieldInfo of filterDataFields) {
    if (feature.properties[fieldInfo.name] === undefined) {
      feature.properties[fieldInfo.name] = FilterUtils.getDefaultFieldValue(fieldInfo);
    }
  }
}

/**
 * Добавление идентификатора в массив идентификаторов на добавление
 * @param {*} featureId
 */
function addToAddedIds(componentOptions, featureId) {
  const indexInDeleted = componentOptions.deletedIds.indexOf(featureId);
  const indexInPermanentDeleted = componentOptions.permanentDeletedIds.indexOf(featureId);
  if (indexInDeleted !== -1) {
    //Если есть в массиве удаленных,
    componentOptions.deletedIds.splice(indexInDeleted, 1); //То убрать из массива удаленных
  } else {
    //Иначе, если нет в массиве добавленных, то добавить в массив добавленных
    const indexInAdded = componentOptions.addedIds.indexOf(featureId);
    if (indexInAdded < 0) {
      componentOptions.addedIds.push(featureId);
    }
  }

  if (indexInPermanentDeleted !== -1) {
    componentOptions.permanentDeletedIds.splice(indexInPermanentDeleted, 1); //То убрать из массива удаленных
  } else {
    //Иначе, если нет в массиве добавленных, то добавить в массив добавленных
    const indexInAdded = componentOptions.addedIds.indexOf(featureId);
    if (indexInAdded < 0) {
      componentOptions.addedIds.push(featureId);
    }
  }
}

/**
 * Добавление идентификатора объекта в массив идентификаторов на удаление
 * @param {*} featureId
 */
function addToDeletedIds(componentOptions, featureId, addToPermanent = false) {
  const indexInAdded = componentOptions.addedIds.indexOf(featureId);
  if (indexInAdded !== -1) {
    if (addToPermanent) {
      componentOptions.permanentDeletedIds.push(featureId);
    } else {
      //Если есть в массиве добавленных,
      componentOptions.addedIds.splice(indexInAdded, 1); //То убрать из массива добавленных
    }
  } else {
    //Убрать из словаря измененных, если он там есть
    if (componentOptions.editedIds.hasOwnProperty(featureId)) {
      Vue.delete(componentOptions.editedIds, featureId);
    }

    //Добавить в массив удаленных если его там нет
    if (!componentOptions.deletedIds.includes(featureId)) {
      componentOptions.deletedIds.push(featureId);
    }

    if (!componentOptions.permanentDeletedIds.includes(featureId)) {
      componentOptions.permanentDeletedIds.push(featureId);
    }
  }
}

/**
 * Добавление идентификатора объекта в массив идентификаторов измененных объектов
 * @param {*} featureId
 * @param {bool} undo - был ли откат назад (True), или вперед (False)
 */
function addToEditedIds(componentOptions, featureId, undo) {
  //Если нет в массиве добавленных
  if (!componentOptions.addedIds.includes(featureId)) {
    let editFeatureCount;
    if (!componentOptions.editedIds[featureId]) {
      editFeatureCount = 0;
    } else {
      editFeatureCount = componentOptions.editedIds[featureId];
    }

    if (undo) {
      editFeatureCount--;
    } else {
      editFeatureCount++;
    }

    if (!editFeatureCount) {
      Vue.delete(componentOptions.editedIds, featureId);
    } else {
      Vue.set(componentOptions.editedIds, featureId, editFeatureCount);
    }
  }
}

/**
 * Сохранение операции в истории
 * @param {Object} operation
 * @param {Boolean} useCommit - использовать коммиты при записи в историю
 */
function saveOperationToHistory(componentOptions, newOperation, useCommit) {
  const history = componentOptions.operationHistory;
  if (useCommit) {
    const deleteNewOperationIndexes = [];
    for (let iOp = 0; iOp < history.length; iOp++) {
      const op = history[iOp];
      if (op.waitCommit) {
        //Только незакоммиченные изменения
        //Перекрестное сравнение объектов в операциях
        const deleteOldOperationIndexes = [];
        for (let iNewData = 0; iNewData < newOperation.data.features.length; iNewData++) {
          const newHistoryData = newOperation.data.features[iNewData];
          let featureId = getHistoryOperationFeatureId(newHistoryData);
          for (let iData = 0; iData < op.data.features.length; iData++) {
            const historyData = op.data.features[iData];
            const id = getHistoryOperationFeatureId(historyData);
            if (id === featureId) {
              //Один и тот же объект
              if (newOperation.type === 'delete') {
                //Сущестующие операции удаляются
                deleteOldOperationIndexes.push(iData);
              }
              if (newOperation.type === 'edit') {
                if (['edit', 'add'].includes(op.type)) {
                  //Замена объекта в сущестующей записи
                  historyData.newFeature = newHistoryData.newFeature;
                  //Новая запись игнорируется
                  deleteNewOperationIndexes.push(iNewData);
                }
                if (op.type === 'delete') {
                  throw new Error('редактирование объекта, который ранее был удален');
                }
              }
              if (newOperation.type === 'add') {
                if (['edit', 'add', 'delete'].includes(op.type)) {
                  throw new Error('Добавление объекта, который уже добавлен');
                }
              }
            }
          }
        }
        removeItemsByIndexes(op.data.features, deleteOldOperationIndexes);
        if (!op.data.features[0]) {
          history.splice(iOp, 1);
          iOp--;
        }
      }
    }
    removeItemsByIndexes(newOperation.data.features, deleteNewOperationIndexes);
  }

  if (newOperation.data.features[0]) {
    if (useCommit) {
      newOperation.waitCommit = true;
    }
    history.splice(componentOptions.historyIndex + 1, history.length, newOperation);
    //Усекаем историю к максимальному количеству хранимых шагов
    while (componentOptions.historyLength >= 0 && history.length > componentOptions.historyLength) {
      history.shift();
    }
    componentOptions.historyIndex = history.length - 1;
    //this.triggerHistoryIndexChanged();
  }
}

function getHistoryOperationFeatureId(historyData) {
  return historyData.id ?? historyData.oldFeature.id;
}

function removeItemsByIndexes(arr, indexes) {
  const deleteIndexes = [...new Set(indexes)];
  deleteIndexes.sort();
  for (const i of deleteIndexes) {
    arr.splice(i, 1);
  }
}

function logHistory(componentOptions) {
  if (componentOptions.operationHistory.length == 0) {
    return;
  }
  console.log('history');
  for (const op of componentOptions.operationHistory) {
    if (op.data.features[0]) {
      console.log(
        op.type +
          ' ' +
          op.data.features.reduce((accum, f) => {
            return accum + (f.id ?? f.oldFeature.id) + ',';
          }, '')
      );
    }
  }
}

/**
 * Замена ИД в объекте
 * @param {Object} feature - объект
 * @param {String} keyOld - старый ИД
 * @param {String} keyNew - новый ИД
 */
function replaceFeatureIds(feature, keyOld, keyNew, properties) {
  if (feature.id === keyOld) {
    feature.id = keyNew;
    feature.properties = { ...properties };
  }
}

function setFilterField(state, layerComponentId, filterLayerComponentId, layerFieldName, fieldFieldName, value) {
  const layerOptions = state.componentOptions[layerComponentId];

  if (!layerOptions) {
    return;
  }

  const filterFieldValues = layerOptions.filterFieldValues;

  if (!filterFieldValues || !filterFieldValues[filterLayerComponentId] || !filterFieldValues[filterLayerComponentId].fieldValues[layerFieldName]) {
    return;
  }

  const layerFilterFieldValues = filterFieldValues[filterLayerComponentId];
  layerFilterFieldValues.fieldValues[layerFieldName][fieldFieldName] = value;

  setLabel(layerFilterFieldValues.fieldValues[layerFieldName]);

  //Клонирование для срабатывания vuex в сложном объекте
  layerFilterFieldValues.fieldValues[layerFieldName] = deepCopy(layerFilterFieldValues.fieldValues[layerFieldName]);
}

const NUMBER_TYPES = ['bigint', 'integer', 'numeric'];
const DATE_TYPES = ['timestamp without time zone'];
const NUMBER_OPERATORS_MAP = {
  '>': 'От',
  '<': 'До'
};
const DATE_OPERATORS_MAP = {
  '>': 'С',
  '<': 'По'
};

/**
 * Установка значения тега в зависимости от значения фильтра
 */
function setLabel(filterFieldValue) {
  if (filterFieldValue.value === null) {
    filterFieldValue.label = null;
    return;
  }

  if (filterFieldValue.value.length === 2) {
    filterFieldValue.label = filterFieldValue.value
      .map((valueItem) => {
        return getFormatValue(filterFieldValue, valueItem);
      })
      .join(' - ');
    return;
  }

  const preposition = getPreposition(filterFieldValue.type, filterFieldValue.value);
  const value = getFormatValue(filterFieldValue, filterFieldValue.value);

  filterFieldValue.label = preposition ? `${preposition} ${value}` : value;
}

/**
 * Возвращает предлог для значения из диапазона
 */
function getPreposition(type, value) {
  const operator = value[1];

  if (NUMBER_TYPES.includes(type)) {
    return NUMBER_OPERATORS_MAP[operator];
  }

  if (DATE_TYPES.includes(type)) {
    return DATE_OPERATORS_MAP[operator];
  }

  return '';
}

/**
 * Возвращает форматированное значение поля
 */
function getFormatValue(filterFieldValue, valueItem) {
  const { type, displayValue, caption } = filterFieldValue;
  const value = valueItem[2];

  if (NUMBER_TYPES.includes(type)) {
    return value.toLocaleString();
  }

  if (DATE_TYPES.includes(type)) {
    return new Date(value).toLocaleString([], {
      day: '2-digit',
      month: '2-digit',
      year: '2-digit'
    });
  }

  if (type === 'dictionary' || type === '_config_select_mode') {
    return displayValue !== null ? displayValue : value;
  }

  if (type === 'boolean') {
    return `${caption}: ${value ? 'да' : 'нет'}`;
  }

  return value;
}

export function setLayerSavedOption(layerComponentId, parentGisId, options, gisOptions) {
  if (gisOptions.persistLayerState) {
    const savedLayerOptions = { ...getLayerSavedOptions(layerComponentId, parentGisId, gisOptions), ...options };
    const optionsString = JSON.stringify(savedLayerOptions);
    localStorage.setItem(`${parentGisId}_${layerComponentId}`, optionsString);
  }
}
