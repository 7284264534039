import Vue from 'vue';

const PREFIX = 'Dictionaries';

export const SET_DICTIONARY = `${PREFIX}/setDictionary`;
export const SET_DICTIONARY_DATA = `${PREFIX}/setDictionaryData`;

export default {
  [SET_DICTIONARY](state, { dictionaryName, options }) {
    const dictionaries = state.dictionaries;
    if (!dictionaries[dictionaryName]) {
      Vue.set(dictionaries, dictionaryName, options);
      return;
    }
    dictionaries[dictionaryName] = {
      ...options
    };
  },

  [SET_DICTIONARY_DATA](state, { dictionaryName, data }) {
    const dictionaries = state.dictionaries;
    if (!dictionaries[dictionaryName]) {
      return;
    }
    dictionaries[dictionaryName].data = [...data];
  }
};
