import Vue from 'vue';
import notify from 'devextreme/ui/notify';
import { deepCopy } from '@/utils/deep';
import componentMutations from './components/mutations';
import dictionariesMutations from './dictionaries/mutations';
import { setPropByPath, getParentGisId } from '@/utils/common';

export const SET_ITEMS_AND_OPTIONS = 'SET_ITEMS_AND_OPTIONS';
export const SUBSCRIBE = 'SUBSCRIBE';
export const UNSUBSCRIBE = 'UNSUBSCRIBE';
export const SET_COMPONENT_OPTIONS = 'SET_COMPONENT_OPTIONS';
export const SET_CONSTRUCTOR_ACTIVE_COMPONENT_OPTIONS = 'SET_CONSTRUCTOR_ACTIVE_COMPONENT_OPTIONS';
export const SET_CONSTRUCTOR_ACTIVE_COMPONENT_FIELD = 'SET_CONSTRUCTOR_ACTIVE_COMPONENT_FIELD';
export const ADD_COMPONENT_TO_LOADED_ITEMS = 'ADD_COMPONENT_TO_LOADED_ITEMS';

const STATE_FIELDS_ITEMS = ['items'];
const STATE_FIELDS_OPTIONS = ['componentOptions'];

import ComponentFactories from './components/factories';

const mutations = {
  ...componentMutations,
  ...dictionariesMutations,

  /**
   * Устанавливает корневые компоненты страницы
   * @param {Object} state - Объект состояния Vuex
   * @param {Object} payload - Параметры мутации
   * @param {string} payload.url - Url страницы
   * @param {string[]} payload.itemIds - Массив идентификаторов корневых компонентов страницы
   */
  setPageItems(state, { url, itemIds = [] }) {
    setPropByPath(state, ['pages', url, 'items'], itemIds);
  },
  /**
   * Устанавливает стиль страницы
   * @param {Object} state - Объект состояния Vuex
   * @param {Object} payload - Параметры мутации
   * @param {string} payload.url - Url страницы
   * @param {Object} payload.cssStyle - Объект с css-стилем страницы
   */
  setPageCss(state, { url, cssStyle }) {
    setPropByPath(state, ['pages', url, 'cssStyle'], cssStyle);
  },
  /**
   * Добавляет настройки компонента в словарь компонентов конструктора и в словарь компонентов для отображения
   * @param {Object} state - Объект состояния Vuex
   * @param {Object} payload - Параметры мутации
   * @param {Object} payload.componentConfig - Объект с настройками компонента
   * @param {Object} payload.componentOptions - Объект с options компонента
   */
  createComponentConfig(state, { componentConfig, componentOptions }) {
    STATE_FIELDS_ITEMS.forEach((stateField) => {
      setComponentConfig(state, stateField, componentConfig.componentId, componentConfig, true);
    });
    STATE_FIELDS_OPTIONS.forEach((stateField) => {
      setComponentConfig(state, stateField, componentConfig.componentId, {
        ...(componentOptions || {})
      });
    });
  },
  /**
   * Установка родительского компонента и позиции
   * @param {Object} state - Объект состояния Vuex
   * @param {Object} payload - Параметры мутации
   * @param {string} payload.componentId - Идентификатор компонента, для которого меняем родителя
   * @param {number} payload.componentPos - Новая позиция компонента
   * @param {string} payload.oldParentId - Идентификатор старого родителя
   * @param {string} payload.newParentId - Идентификатор нового родителя
   * @param {string} payload.oldUrl - Url старой страницы (если компонент перестает быть корневым)
   * @param {string} payload.newUrl - Url страницы (если компонент становится корневым)
   * @param {string} payload.device - код устройства desktop | phone | tablet
   * @param {Boolean} payload.changeConstructor - изменять словари настроек для констрктора
   * @param {Boolean} payload.changeLoaded - изменять словари загруженных
   */
  setComponentParent(state, { componentId, componentPos, oldParentId, newParentId, oldUrl, newUrl, device, changeConstructor = true, changeLoaded = false }) {
    let itemStateFields = ['items'];
    let optionsStateField = ['componentOptions'];

    if (oldParentId) {
      //Убираем элемент из старого родителя для конструктора
      optionsStateField.forEach((stateField) => {
        let oldParentOptions = state[stateField][oldParentId];
        oldParentOptions.items = (oldParentOptions.items || []).filter((itemComponentId) => {
          return itemComponentId !== componentId;
        });
      });
    } else if (oldUrl) {
      //Убираем элемент из массива корневых элементов страницы
      state.pages[oldUrl].items = state.pages[oldUrl].items.filter((itemComponentId) => {
        return itemComponentId !== componentId;
      });
    }

    if (newParentId) {
      //Добавление элемента в родительский элемент для конструктора
      optionsStateField.forEach((stateField) => {
        let newParentOptions = state[stateField][newParentId];
        if (!newParentOptions.items) {
          Vue.set(newParentOptions, 'items', []);
        }

        if (newParentOptions.items.indexOf(componentId) === -1) {
          newParentOptions.items.push(componentId);
        }
      });

      itemStateFields.forEach((stateField) => {
        state[stateField][componentId].parentComponentId = newParentId;
      });
    } else if (newUrl) {
      //Устанавливаем компоненту поле url для конструктора
      itemStateFields.forEach((stateField) => {
        state[stateField][componentId].parentComponentId = null;
        state[stateField][componentId].url = newUrl;

        //Простановка кода устройства
        state[stateField][componentId].device = device;
      });

      //Добавление компонента в корневые компоненты страницы, чтобы он сразу отобразился
      addComponentToPage(componentId, state, newUrl);
    }

    if (typeof componentPos === 'number') {
      itemStateFields.forEach((stateField) => {
        state[stateField][componentId].componentPos = componentPos;
      });
    }
  },
  /**
   * Устанавливает компоненту и всем его дочерним компонентам id родительского попапа
   * @param state - Объект состояния Vuex
   * @param componentId - Идентификатор компонента
   * @param parentPopupId - Идентификатор родительского popup-компонента
   * @param {Boolean} changeConstructor - изменять словари настроек для констрктора
   * @param {Boolean} changeLoaded - изменять словари загруженных
   */
  setParentPopupId(state, { componentId, parentPopupId, changeConstructor = true, changeLoaded = false }) {
    let itemStateFields = ['items'];
    setParentPopupId(state, itemStateFields, componentId, parentPopupId);
  },

  /**
   * Установка настроек компонента (внутренний объект options).
   * Используется в реальных действиях, поэтому меняем только componentOptions
   * @param {Object} state - Объект состояния Vuex
   * @param {Object} payload - Параметры мутации
   * @param {string} payload.componentId - Идентификатор компонента
   * @param {string} payload.optionName - Название поля или путь через точку
   * @param {*} payload.optionValue - Значение поля
   */
  setComponentOption(state, { componentId, optionName, optionValue }) {
    let componentOptions = state.componentOptions[componentId];
    if (!componentOptions) {
      return;
    }

    setPropByPath(
      componentOptions,
      optionName.split(/\[|\]|\./).filter((item) => {
        return item !== '';
      }),
      optionValue
    );
  },

  /**
   * Установка поля в настройках компонента
   * @param {Object} state - Объект состояния Vuex
   * @param {Object} payload - Параметры мутации
   * @param {string} payload.componentId - Идентификатор компонента
   * @param {string} payload.fieldName - Название или путь к полу через точку
   * @param {*} payload.fieldValue - Значение поля
   * @param {string[]} [payload.stateFields=['items']] - Массив полей state, в которых надо заменить конфиг.
   */
  setItemField(state, { componentId, fieldName, fieldValue, stateFields = STATE_FIELDS_ITEMS }) {
    stateFields.forEach((stateField) => {
      let componentConfig = state[stateField][componentId];
      if (!componentConfig) {
        return;
      }

      setPropByPath(
        componentConfig,
        fieldName.split(/\[|\]|\./).filter((item) => {
          return item !== '';
        }),
        fieldValue
      );
    });
  },

  /**
   * Добавление компонента в массив дочерних компонентов
   * @param {Object} state - Объект состояния Vuex
   * @param {Object} payload - Параметры мутации
   * @param {string} payload.componentId - Идентификатор компонента
   * @param {string} payload.parentComponentId - Идентификатор родительского компонента
   */
  addComponentToParent(state, { componentId, parentComponentId }) {
    STATE_FIELDS_OPTIONS.forEach((stateField) => {
      if (!state[stateField][parentComponentId].items) {
        Vue.set(state[stateField][parentComponentId], 'items', []);
      }
      state[stateField][parentComponentId].items.push(componentId);
    });
  },

  /**
   * Добавление корневого компонента на страницу
   * @param {Object} state - Объект состояния Vuex
   * @param {Object} payload - Параметры мутации
   * @param {string} payload.componentId - Идентификатор компонента
   * @param {string} payload.url - Url страницы
   */
  addComponentToPage(state, { componentId, url }) {
    addComponentToPage(componentId, state, url);
  },

  /**
   * Установка настроек событий-действий
   * @param {Object} state - Объект состояния Vuex
   * @param {Object} payload - Параметры мутации
   * @param {Object[]} payload.eventAction - Массив настроек событий-действий
   */
  setEventAction(state, { eventAction }) {
    eventAction.forEach((eventActionItem) => {
      const eventComponentId = eventActionItem.eventComponentId;
      const eventName = eventActionItem.eventName;

      if (!state.eventAction.hasOwnProperty(eventComponentId)) {
        Vue.set(state.eventAction, eventComponentId, {});
      }

      if (!state.eventAction[eventComponentId].hasOwnProperty(eventName)) {
        Vue.set(state.eventAction[eventComponentId], eventName, []);
      }

      let eventActions = state.eventAction[eventComponentId][eventName];
      if (Array.isArray(eventActionItem.actions)) {
        eventActionItem.actions.forEach((actionConfig) => {
          if (eventActions.filter((item) => item.actionEAID === actionConfig.actionEAID).length === 0) {
            eventActions.push(actionConfig);
          }
        });
      }

      //state.eventAction[eventComponentId][eventName][eventActionItem.eventId] = eventActionItem.actions;
      //Vue.set(state.eventAction[eventComponentId][eventName], eventActionItem.eventId, eventActionItem.actions);
    });
  },
  /**
   * Удаление компонента
   * @param {Object} state - Объект состояния Vuex
   * @param {Object} payload - Параметры мутации
   * @param {string} payload.componentId - Идентификатор компонента
   */
  deleteComponentConfig(state, { componentId }) {
    STATE_FIELDS_ITEMS.concat(STATE_FIELDS_OPTIONS).forEach((stateField) => {
      Vue.delete(state[stateField], componentId);
    });
  },
  /**
   * Удаление комопнента из родителя
   * @param {Object} state - Объект состояния Vuex
   * @param {Object} payload - Параметры мутации
   * @param {string} payload.componentId - Идентификатор компонента
   * @param {string} payload.parentComponentId - Идентификатор родительского компонента
   * @param {string} payload.url - Url страницы, если удаляется корневой компонент
   */
  deleteComponentFromParent(state, { componentId, parentComponentId, url }) {
    if (parentComponentId) {
      //Убираем элемент из старого родителя
      STATE_FIELDS_OPTIONS.forEach((stateField) => {
        let parentOptions = state[stateField][parentComponentId];
        if (!parentOptions || !parentOptions.items) {
          return;
        }
        parentOptions.items = parentOptions.items.filter((itemComponentId) => {
          return itemComponentId !== componentId;
        });
      });
    } else if (url && state.pages[url] && state.pages[url].items) {
      //Убираем элемент из массива корневых элементов страницы
      state.pages[url].items = state.pages[url].items.filter((itemComponentId) => {
        return itemComponentId !== componentId;
      });
    }
  },
  /**
   * Очистка изменений
   * @param {Object} state - Объект состояния Vuex
   */
  clearChanges(state) {
    state.changedComponents.splice(0, state.changedComponents.length);
    state.addedComponents.splice(0, state.addedComponents.length);
    state.deletedComponents.splice(0, state.deletedComponents.length);
  },

  /**Установка в конструкторе активного компонента для редактирования*/
  setConstructorActiveComponentConfig(state, { config }) {
    state.constructorActiveComponentConfig = config;
  },

  setItemConfig(state, { config }) {
    Vue.set(state.items, config.componentId, { ...config });
  },

  setComponentSelected(state, { componentId, keepSelected, notUnselect }) {
    let componentConfig = state.items[componentId];

    //Если просто кликнули на элемент то выбираем только его
    if (!keepSelected) {
      clearSlection(state);
      componentConfig.selected = true;
      expandToComponent(state, componentConfig.parentComponentId);
      state.selectedComponents.push(componentId);
      return;
    }

    if (notUnselect && componentConfig.selected) {
      //Не развыделять
      return;
    }

    if (componentConfig.selected) {
      //Если зажали ctrl, то выбираем или развыбираем сохраняя выделенные
      componentConfig.selected = false;
      state.selectedComponents = state.selectedComponents.filter((itemId) => {
        return itemId !== componentId;
      });
    } else {
      componentConfig.selected = true;
      expandToComponent(state, componentConfig.parentComponentId);
      state.selectedComponents.push(componentId);
    }
  },
  setComponentLast(state, { componentId }) {
    if (!state.selectedComponents.includes(componentId)) {
      return;
    }
    const index = state.selectedComponents.indexOf(componentId);
    const selectedComponentId = state.selectedComponents.splice(index, 1);
    state.selectedComponents.push(selectedComponentId[0]);
  },
  clearSelection(state) {
    clearSlection(state);
  },

  clearComponentSelected(state, { componentId }) {
    let componentConfig = state.items[componentId];
    if (componentConfig) {
      componentConfig.selected = false;
    }

    state.selectedComponents = state.selectedComponents.filter((itemId) => {
      return itemId !== componentId;
    });
  },

  /**
   * Устанавливает настройки компонентов в хранилище Vuex, полученные запросом страницы
   * @param {Object} state - Объект состояния Vuex
   * @param {Object} payload - Параметры мутации
   * @param {Object} payload.components - Объект "ключ-значение". Ключ - идентификатор компонента, значение - настройки компонента
   * @param {Object} payload.url - Адрес страницы для которой был отправлен запрос на получение компонентов
   * @param {Object} payload.constructorActive - true - если активен конструктор
   */
  // eslint-disable-next-line
  [SET_ITEMS_AND_OPTIONS](state, { components, url, constructorActive, keepExisting = false, parentPopupId }) {
    Object.keys(components).forEach((componentId) => {
      //if (['GisLayer', 'GisGroupLayer'].includes(components[componentId].componentType)) {
      //  return;
      //}

      if (state.items[componentId] && keepExisting) {
        return;
      }

      //Костыль - установим для всех компонентов url, чтобы не "потерять" компоненты при сохранении на сервере
      let item = {
        ...components[componentId],
        url,
        selected: false,
        expanded: false
      };

      //Иногда с сервера приходит пустой массив
      if (Array.isArray(item.cssStyle) && !item.cssStyle.length) {
        item.cssStyle = {};
      }
      //Установка попапа, если компонент находится в попапе
      if (parentPopupId) {
        item.parentPopupId = parentPopupId;
      }
      delete item.options;

      let stateFields = ['items'];
      stateFields.forEach((stateField) => {
        if (item.componentType === 'GisLayer' || item.componentType === 'GisGroupLayer') {
          let { parentGisId, parentComponentId } = item;
          let parentItem = this.getters['config/getItem'](parentComponentId);

          if (parentGisId == 0 && parentComponentId && parentItem) {
            let parentGisId = null;
            do {
              parentGisId = parentItem.componentType === 'Gis' ? parentComponentId : getParentGisId(parentItem.parentComponentId, this.getters);
              if (!parentGisId) {
                //Вероятно родительский компонент загружен, но еще не обработан
                //Ищем среди загруженных
                parentItem = components[parentItem.parentComponentId];
              }
            } while (!parentGisId && parentItem);
            components[componentId].parentGisId = item.parentGisId = parentGisId;
          }
        }
        setComponentConfig(state, stateField, item.componentId, item, true);
      });

      let options = components[componentId].options || {};
      let copyOptions = {};

      // Перекладываем в опции слоя данные поля для отображения в легенде
      options.layer_count = components[componentId].layer_count;
      options.layer_area = components[componentId].layer_area;
      if (item.componentType === 'GisLayer' && item.parentGisId && options.visible) {
        const { maxVisibleLayersCount } = this.getters['config/Gis/getOptions'](item.parentGisId);
        const visibleLayers = this.getters['config/Gis/getFullHalfVisibleLayers'](item.parentGisId);
        if (visibleLayers.length >= maxVisibleLayersCount) {
          options.visible = false;
          notify(
            { message: `Число видимых слоев превышает предел ${maxVisibleLayersCount}. Скройте один или несколько ненужных слоев.`, width: 'auto' },
            'error',
            6000
          );
        }
      }
      deepCopy(options, copyOptions);
      copyOptions = stringToFunction(copyOptions);

      let stateOptionsFields = ['componentOptions'];
      //if (constructorActive) {
      stateOptionsFields = ['componentOptions'];
      //}

      //Установка настроек компонентов в словарь components из которого беруться настройки для текущего отображения
      stateOptionsFields.forEach((stateField) => {
        let opts = copyOptions;

        //Фабрику используем только для свойств, которые применяются к компоненту
        if (stateField === 'componentOptions') {
          let factoryFn = ComponentFactories[item.componentType];
          if (factoryFn /*&& stateField === 'componentOptions'*/) {
            // state.items как будто не используется
            opts = stringToFunction(factoryFn(components[componentId], state.items, this.getters));
          }

          if (item.componentType === 'GisLayer' || item.componentType === 'GisGroupLayer') {
            if (!state.layersLoading[componentId]) {
              Vue.set(state.layersLoading, componentId, false);
            }

            Vue.set(state.layerCardComponentIds, componentId, null);
          }

          //Костыль для теста активности кнопки таблицы в меню слоя, заменить на factory
          if (item.componentType === 'Popup') {
            opts = {
              visible: false,
              ...opts
            };
          }
        }

        setComponentConfig(state, stateField, item.componentId, opts, true);
      });
    });
  },

  /**
   * Подписаться на событие компонента
   * @param state - Объект состояния Vuex
   * @param componentId - id компонента, на событие которого хотим подписаться
   * @param eventType - имя события
   * @param handler - callback
   */
  [SUBSCRIBE](state, { componentId, eventType, handler }) {
    if (typeof handler !== 'function') {
      return;
    }
    if (!state.observers[componentId]) {
      Vue.set(state.observers, componentId, {});
    }
    if (!state.observers[componentId][eventType]) {
      Vue.set(state.observers[componentId], eventType, new Set());
    }
    state.observers[componentId][eventType].add(handler);
  },

  /**
   * Отписаться от события компонента
   * @param state - Объект состояния Vuex
   * @param componentId - id компонента, от события которого хотим отписаться
   * @param eventType - имя события
   * @param handler - callback
   */
  [UNSUBSCRIBE](state, { componentId, eventType, handler }) {
    if (!state.observers[componentId] || !state.observers[componentId][eventType]) {
      return;
    }
    state.observers[componentId][eventType].delete(handler);
  },

  setComponentConstructorOption(state, { componentId, optionName, optionValue }) {
    STATE_FIELDS_OPTIONS.forEach((stateField) => {
      setPropByPath(
        state[stateField][componentId],
        optionName.split(/\[|\]|\./).filter((item) => {
          return item !== '';
        }),
        optionValue
      );
    });
  },

  /**
   * Очистка словарей компонентов
   * @param {Object} state - Объект состояния Vuex
   */
  clearComponents(state, { url }) {
    ['items', 'componentOptions'].forEach((stateField) => {
      for (let componentId in state[stateField]) {
        delete state[stateField][componentId];
      }
    });

    Vue.delete(state.pages, url);
  },

  clearComponent(state, { componentId }) {
    clearComponent(state, componentId);
  },

  clearPage(state, { pageUrl }) {
    Vue.delete(state.pages, pageUrl);
  },

  setSavingProcess(state, { saving }) {
    state.savingProcess = saving;
  },

  setPlace(state, { place }) {
    state.place = place;
  },

  setPageUrl(state, { pageUrl }) {
    state.pageUrl = pageUrl;
  },

  setDeviceType(state, { device }) {
    state.deviceType = device;
  },

  /**
   * Установка (применение) настроек к текущему компоненту
   * @param {*} state
   * @param {*} param1
   * @returns
   */
  [SET_COMPONENT_OPTIONS](state, { componentId, options }) {
    const componentConfig = state.items[componentId];
    if (!componentConfig) {
      window.console.warn(`${SET_COMPONENT_OPTIONS}: не найден конфиг компонента: ${componentId}`);
      return;
    }

    let parsedOptions = options;
    const factoryFn = ComponentFactories[componentConfig.componentType];
    if (factoryFn) {
      parsedOptions = factoryFn(
        {
          ...componentConfig,
          options: parsedOptions
        },
        state.items,
        this.getters
      );
    }

    const componentOptions = state.componentOptions[componentId];
    if (!componentOptions) {
      Vue.set(state.componentOptions, componentId, stringToFunction(parsedOptions));
    } else {
      const specialFields = {
        cssStyle: componentOptions.cssStyle,
        items: componentOptions.items,
        validator: componentOptions.validator
      };

      //Установим новый объект parsedOptions
      const newComponentOptions = {
        ...parsedOptions,
        ...specialFields
      };

      Object.keys(componentOptions).forEach((optionField) => {
        if (!newComponentOptions.hasOwnProperty(optionField) && !specialFields.hasOwnProperty(optionField)) {
          newComponentOptions[optionField] = null;
        }
      });

      state.componentOptions[componentId] = newComponentOptions;
    }
  },

  /**
   * Установка настроек для выбранного в конструкторе компонента
   * @param {*} state
   * @param {*} param1
   * @returns
   */
  [SET_CONSTRUCTOR_ACTIVE_COMPONENT_OPTIONS](state, { options }) {
    if (!state.constructorActiveComponentConfig) {
      return;
    }

    if (!state.constructorActiveComponentConfig.options) {
      Vue.set(state.constructorActiveComponentConfig, 'options', functionsToStrings({ ...options }));
    } else {
      const componentOptions = state.constructorActiveComponentConfig.options;
      const specialFields = {
        cssStyle: componentOptions.cssStyle,
        items: componentOptions.items,
        validator: componentOptions.validator
      };

      const newComponentOptions = {
        ...functionsToStrings({ ...options }),
        ...specialFields
      };

      state.constructorActiveComponentConfig.options = newComponentOptions;
    }
  },

  /**
   * Установка поля в настройках компонента
   * @param {Object} state - Объект состояния Vuex
   * @param {string} fieldName - Название или путь к полю через точку
   * @param {*} fieldValue - Значение поля
   */
  [SET_CONSTRUCTOR_ACTIVE_COMPONENT_FIELD](state, { fieldName, fieldValue }) {
    const componentConfig = state.constructorActiveComponentConfig;
    if (!componentConfig) {
      return;
    }

    setPropByPath(
      componentConfig,
      fieldName.split(/\[|\]|\./).filter((item) => {
        return item !== '';
      }),
      functionsToStrings(fieldValue)
    );
  },

  [ADD_COMPONENT_TO_LOADED_ITEMS](state, { componentId }) {
    if (!state.loadedItemIds.includes(componentId)) {
      state.loadedItemIds.push(componentId);
    }
  }
};

function setComponentConfig(state, stateField, componentId, componentConfig, copy) {
  let componentConfigForSet = componentConfig;
  if (copy) {
    componentConfigForSet = {};
    deepCopy(componentConfig, componentConfigForSet);
  }

  if (state[stateField].hasOwnProperty(componentId)) {
    state[stateField][componentId] = componentConfigForSet;
  } else {
    Vue.set(state[stateField], componentId, componentConfigForSet);
  }
}

function addComponentToPage(componentId, state, url) {
  if (!state.pages[url].hasOwnProperty('items')) {
    Vue.set(state.pages[url], 'items', []);
  }

  if (state.pages[url].items.indexOf(componentId) === -1) {
    state.pages[url].items.push(componentId);
  }
}

function clearSlection(state) {
  state.selectedComponents.forEach((itemId) => {
    if (state.items[itemId]) {
      state.items[itemId].selected = false;
    }
  });
  state.selectedComponents = [];
}

function expandToComponent(state, parentComponentId) {
  if (!parentComponentId) {
    return;
  }

  let parentComponentConfig = state.items[parentComponentId];
  if (!parentComponentConfig) {
    return;
  }

  parentComponentConfig.expanded = true;
  expandToComponent(state, parentComponentConfig.parentComponentId);
}

function functionsToStrings(options) {
  if (typeof options !== 'object') {
    return options;
  }

  for (let key in options) {
    if (key === 'deepEach') {
      return;
    }
    if (typeof options[key] === 'object') {
      functionsToStrings(options[key]);
    } else if (typeof options[key] === 'function') {
      options[key] = `*@*${String(options[key])}`;
      options[key] = options[key].replace(/\n/g, '@');
    }
  }
  return options;
}

function stringToFunction(options) {
  for (let key in options) {
    if (typeof options[key] === 'object') {
      stringToFunction(options[key]);
    } else if (typeof options[key] === 'string' && options[key].indexOf('*@*') === 0) {
      options[key] = options[key].slice(3, options[key].length).replace('@)', ')').replace(/@{2,}/g, '@').replace(/@ +@/g, '@').replace(/@/g, '\n');
      const argsFunc = options[key].slice(options[key].indexOf('(') + 1, options[key].indexOf(')'));
      const bodyFunc = options[key].slice(options[key].indexOf('{') + 1, options[key].lastIndexOf('}'));
      options[key] = new Function(argsFunc, bodyFunc);
    }
  }
  return options;
}

export default mutations;

function clearComponent(state, componentId) {
  ['componentOptions'].forEach((stateField) => {
    const componentOptions = state[stateField][componentId];

    if (!componentOptions) {
      return;
    }

    if (Array.isArray(componentOptions.items)) {
      componentOptions.items.forEach((itemId) => {
        clearComponent(state, itemId);
      });
    }

    Vue.delete(state[stateField], componentId);
  });

  ['items'].forEach((stateField) => {
    Vue.delete(state[stateField], componentId);
  });
}

function setParentPopupId(state, itemStateFields, componentId, parentPopupId) {
  itemStateFields.forEach((stateField) => {
    const componentConfig = state[stateField][componentId];
    if (!componentConfig) {
      return;
    }
    componentConfig.parentPopupId = parentPopupId;
  });

  const componentOptions = state.componentOptions[componentId];
  if (!componentOptions) {
    return;
  }
  const componentType = state[itemStateFields[0]][componentId].componentType;
  //Дочерним компонентам popup и popover не меняем родительский элемент попап-контейнера
  if (['Popup', 'Popover'].includes(componentType)) {
    return;
  }
  const items = componentOptions.items || [];
  items.forEach((itemId) => {
    setParentPopupId(state, itemStateFields, itemId, parentPopupId);
  });
}
