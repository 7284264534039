import Vue from 'vue';

export default {
  toggle(state, active) {
    state.active = active;
  },
  toggleDragging(state, dragging) {
    state.dragging = dragging;
  },
  addComponentType(state, componentType) {
    state.componentTypes.push(componentType);
  },
  setComponentTypes(state, componentTypes) {
    state.componentTypes = componentTypes;
  },

  setTemplates(state, templates) {
    state.templates = templates;
  },
  setTemplateFields(state, templateFields) {
    state.templateFields = templateFields;
  },
  setConnections(state, connections) {
    state.connections = connections;
  },
  setDatasets(state, datasets) {
    state.datasets = datasets;
  },
  setFilters(state, filters) {
    state.filters = filters;
  },
  setAllPages(state, allPages) {
    state.allPages = allPages;
  },
  setSelectedTemplateId(state, templateId) {
    state.selectedTemplateId = templateId;
  },
  setSelectedEventActions(state, eventActions) {
    state.selectedEventActions = eventActions;
  },
  setComponentEventTypes(state, componentEventTypes) {
    state.selectedEventTypes = componentEventTypes;
  },
  setSelectedEventType(state, eventTypeId) {
    state.selectedEventTypeId = eventTypeId;
  },

  setSelectedEventTypeActions(state, eventActions) {
    eventActions.forEach((eventAction) => {
      if (eventActions.filter((ea) => ea.id === eventAction.parent_id).length === 0) {
        eventAction.treeParentId = null;
      } else {
        eventAction.treeParentId = eventAction.parent_id;
      }

      if (!eventAction.hasOwnProperty('selected')) {
        Vue.set(eventAction, 'selected', state.selectedEventAction.id === eventAction.id);
      }
      if (!eventAction.hasOwnProperty('expanded')) {
        const oldEventAction = state.selectedEventTypeActions.find((ea) => ea.id === eventAction.id);
        const expanded = oldEventAction ? oldEventAction.expanded : true;
        Vue.set(eventAction, 'expanded', expanded);
      }
    });
    eventActions.sort((a1, a2) => a1.id - a2.id);
    state.selectedEventTypeActions = eventActions;
  },
  setComponentActionTypes(state, componentActionTypes) {
    state.componentActionTypes = componentActionTypes;
  },
  setSelectedAccessRoles(state, selectedAccessRoles) {
    state.selectedAccessRoles = selectedAccessRoles;
  },
  addLoadedGlobalComponent(state, component) {
    state.loadedGlobalComponents.push(component);
  },
  setSelectedEventAction(state, eventActionData) {
    state.selectedEventAction.id = eventActionData.id || null;
    state.selectedEventAction.parentId = eventActionData.parent_id || null;
    state.selectedEventAction.eventComponentId = eventActionData.eventcomponent_id || null;
    state.selectedEventAction.eventTypeId = eventActionData.eventtype_id || null;
    state.selectedEventAction.disable = eventActionData.disable;

    if (eventActionData.actioncomponent_id !== undefined) {
      state.selectedEventAction.actionComponentId = eventActionData.actioncomponent_id;
    } else {
      state.selectedEventAction.actionComponentId = null;
    }
    state.selectedEventAction.actionTypeId = eventActionData.actiontype_id || null;
  },
  setSelectedEventActionField(state, { fieldName, fieldValue }) {
    state.selectedEventAction[fieldName] = fieldValue;
  },
  clearComponentActionTypes(state) {
    state.componentActionTypes.splice(0, state.componentActionTypes.length);
  },
  addEventAction(state, eventAction) {
    eventAction.selected = false;
    eventAction.expanded = true;
    state.selectedEventActions.push(eventAction);
  },
  changeEventAction(state, eventActionData) {
    for (let i = 0; i < state.selectedEventActions.length; ++i) {
      let eventAction = state.selectedEventActions[i];
      if (eventAction.id === eventActionData.id) {
        Object.assign(eventAction, eventActionData);
        break;
      }
    }
  },
  deleteEventAction(state, eventActionId) {
    let deletedIndex = -1;
    for (let i = 0; i < state.selectedEventActions.length; ++i) {
      let eventAction = state.selectedEventActions[i];
      if (eventAction.id === eventActionId) {
        deletedIndex = i;
        break;
      }
    }

    if (deletedIndex > 0) {
      state.selectedEventActions.splice(deletedIndex, 1);
    }
  },
  setEventActionParams(state, eventActionParams) {
    state.selectedEventActionParams = eventActionParams;
  },
  setAllEventActionParams(state, allEventActionParams) {
    state.allEventActionParams = allEventActionParams;
  },
  setEventActionParamTypes(state, eventActionParamTypes) {
    state.eventActionParamTypes = eventActionParamTypes;
  },
  setSelectedEventActionParam(state, paramData) {
    state.selectedEventActionParam.id = paramData.id || null;
    state.selectedEventActionParam.eventActionId = paramData.eventaction_id || null;
    state.selectedEventActionParam.typeId = paramData.datatype_id || null;
    state.selectedEventActionParam.inputName = paramData.inputname || null;
    state.selectedEventActionParam.outputName = paramData.outputname || null;
    state.selectedEventActionParam.outputValue = paramData.outputvalue || null;
    state.selectedEventActionParam.isPrivate = paramData.is_private ?? false;
    state.selectedEventActionParam.isArray = paramData.is_array ?? false;
    state.selectedEventActionParam.componentId = paramData.component_id || null;
  },
  setSelectedEventActionParamField(state, { fieldName, fieldValue }) {
    state.selectedEventActionParam[fieldName] = fieldValue;
  },
  setNewPageField(state, { fieldName, fieldValue }) {
    state.newPage[fieldName] = fieldValue;
  },
  setNewComponentField(state, { fieldName, fieldValue }) {
    state.newComponent[fieldName] = fieldValue;
  },
  setNewComponentTemplateField(state, { fieldName, fieldValue }) {
    state.newComponentTemplate[fieldName] = fieldValue;
  },
  setComponentTemplateGroups(state, componentTemplateGroups) {
    state.componentTemplateGroups = componentTemplateGroups;
  },
  setComponentTemplates(state, componentTemplates) {
    state.componentTemplates = componentTemplates;
  },
  toggleSelectMode(state, selectMode) {
    state.selectMode = selectMode;
  },
  toggleBlockSelectMode(state, blockSelectMode) {
    state.blockSelectMode = blockSelectMode;
  },
  setImportedSettingField(state, { fieldName, fieldValue }) {
    state.importedSettings[fieldName] = fieldValue;
  },
  setCopyComponentField(state, { fieldName, fieldValue }) {
    state.copyComponent[fieldName] = fieldValue;
  },
  setResolutionDevice(state, deviceValue) {
    state.resolutionDevice = deviceValue;
  },
  setSelectImageVisible(state, { visible }) {
    state.showSelectImage = visible;
  },
  setSelectIconVisible(state, { visible }) {
    state.showSelectIcon = visible;
  },
  setPopupTypeComponentVisible(state, { visible }) {
    state.popupTypeComponentVisible = visible;
  }
};
