export default class GisLegendStyleItemOptions {
  /**{*} Идентификатор*/
  id = null;

  /**{*} Идентификатор родителя*/
  parentId = null;

  /**{String} Название*/
  caption = '';

  /**{Boolean} Видимость слоя*/
  visible = false;

  /**{Boolean} Раскрытия*/
  expanded = false;

  /**{String} Css-класс иконки*/
  iconCss = null;

  /**{String} Css-класс иконки в состоянии раскрытия*/
  iconExpandedCss = null;

  /**{String} Цвет иконки*/
  iconColor = null;

  /**{String} Ссылка на иконку*/
  iconUrl = null;
  initialUrl = null;

  /**(Boolean) для слоя WMS признак одиночной легенды без надписи */
  wmsSingleRule = null;

  /**{[*]} Массив идентификаторов дочерних элементов*/
  items = null;

  /**{Object[]} Массив с настройками слоев mapbox*/
  mapboxLayerConfigs = [];

  /**Тип слой или правило */
  type = 'layer';

  /**Спец параметр для перезагрузки изображения */
  timeParam = null;

  constructor(options) {
    this.id = options.id;
    this.parentId = options.parentId || null;
    this.caption = options.caption || '';
    this.visible = options.visible || false;
    this.expanded = true;
    this.iconCss = options.iconCss || null;
    this.iconExpandedCss = options.iconExpandedCss || null;
    this.iconColor = options.iconColor || null;
    this.iconUrl = options.iconUrl || null;
    this.initialUrl = options.iconUrl || null;
    this.items = options.items || null;
    if (['layer', 'rule'].includes(options.type)) {
      this.type = options.type;
    }
    this.wmsSingleRule = options.wmsSingleRule;
    this.timeParam = null;
  }

  /**
   * Добавить конфиг слоя mapbox
   * @param {*} mapboxLayerConfig
   */
  addMapboxLayerConfig(mapboxLayerConfig) {
    this.mapboxLayerConfigs.push(mapboxLayerConfig);
  }

  /**
   * Добавление дочернего элемента
   * @param {*} itemId
   */
  addItem(itemId) {
    if (!this.items) {
      this.items = [];
    }
    this.items.push(itemId);
  }
}
