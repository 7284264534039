//Локальные названия геттеров
const PREFIX = 'DataSet';
export const getOptions = `${PREFIX}/options`;
export const getLoadedData = `${PREFIX}/loadedData`;
export const getDataFields = `${PREFIX}/dataFields`;
export const isRefreshing = `${PREFIX}/isRefreshing`;
export const getFilters = `${PREFIX}/getFilters`;
export const getDataSetName = `${PREFIX}/getDataSetName`;

export default {
  [getOptions]: (state) => (componentId) => {
    return state.componentOptions[componentId] || {};
  },

  [isRefreshing]: (state) => (componentId) => {
    return state.componentOptions[componentId].isRereshing;
  },

  [getLoadedData]: (state) => (componentId) => {
    return state.componentOptions[componentId].loadedData;
  },

  [getDataFields]: (state) => (componentId) => {
    return state.componentOptions[componentId]?.dataFields || [];
  },

  [getFilters]: (state, getters) => (componentId) => {
    return getters[getOptions](componentId).filters || [];
  },

  [getDataSetName]: (state, getters) => (componentId) => {
    return getters[getOptions](componentId).dataSetName || null;
  }
};
