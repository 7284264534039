<template>
  <!--eslint-disable-->
  <DxSortable
    :allowDropInsideItem="false"
    :allowReordering="false"
    filter=".component-list-item"
    dropFeedbackMode="indicate"
    group="constructor"
    @dragStart="dragStart"
    @dragEnd="dragEnd"
  >
    <DxList
      height="100%"
      :focusStateEnabled="false"
      :activeStateEnabled="false"
      :searchEnabled="true"
      :dataSource="componentTemplates"
      :searchExpr="['caption']"
      :grouped="true"
      :collapsibleGroups="true"
      selectionMode="single"
      @itemClick="handleItemClick"
    >
      <template #item="{data}">
        <div
          class="component-list-item constructor-element"
          :data-component-template-id="data.id"
        >
          {{ data.caption }}
        </div>
      </template>
    </DxList>
  </DxSortable>
</template>

<script>
import { DxList } from 'devextreme-vue/list';
import DxSortable from 'devextreme-vue/sortable';

export default {
  components: {
    DxList,
    DxSortable
  },
  computed: {
    componentTemplates() {
      return this.$store.state.uiConstructor.componentTemplateGroups.map((item) => ({
        key: item.name,
        items: this.$store.state.uiConstructor.componentTemplates.filter((f) => f.componenttemplategroup_name === item.name)
      }));
    }
  },
  methods: {
    dragStart() {
      this.$store.dispatch('uiConstructor/toggleDragging', true);
    },
    dragEnd(eventData) {
      this.$store.dispatch('uiConstructor/toggleDragging', false);

      if (eventData.toComponent === eventData.fromComponent) {
        return;
      }

      const componentTemplateId = eventData.itemElement.dataset.componentTemplateId;
      //const componentName = '<New component>';
      const toSortable = eventData.toComponent;
      const toSortableElement = toSortable.element();
      const toNodes = toSortableElement.querySelectorAll('.component-list-item');
      const toElement = toNodes[eventData.toIndex] || toNodes[0];

      if (!toElement) {
        //TODO: может нужна какая-то обработка?
        return;
      }

      const parentComponentId = toElement.dataset.componentId;
      const componentPos = (parseInt(toElement.dataset.lastItemPos) || 0) + 100;
      let actionPayload = {
        componentId: componentTemplateId,
        componentPos
      };

      if (parentComponentId) {
        actionPayload.parentComponentId = parentComponentId;
      } else {
        actionPayload.url = this.$route.params.childUrl;

        //Если компонент перемещаем в корень, то надо установить устройство
        actionPayload.device = this.$store.getters['uiConstructor/resolutionDevice'];
      }

      this.$store.dispatch('uiConstructor/createCopyComponent', actionPayload).then((componentId) => {
        window.console.log(componentId);
        if (!componentId) {
          //TODO: какая обработка тут нужна?
          return;
        }

        this.$store.dispatch('config/loadComponent', { componentId }).then(() => {
          let setParentPayload = {
            componentId,
            componentPos,
            newParentId: parentComponentId,
            changeLoaded: true
          };

          if (parentComponentId) {
            setParentPayload.newParentId = parentComponentId;
          } else {
            setParentPayload.newUrl = this.$route.params.childUrl;
          }

          this.$store.dispatch('config/setComponentParent', setParentPayload);
        });
        this.$emit('hidden');
      });
    },
    handleItemClick(eventData) {
      const templateId = eventData.itemData.id;
      this.$store.dispatch('uiConstructor/setSelectedTemplateId', templateId);
    }
  }
};
</script>
