import getConfigs from './configUtils';
const OPTIONS = ['flex'];

const CONFIGS_FIELDS = {
  flex: {
    name: 'flex',
    label: 'Flex-параметры',
    component: 'DxTextBox',
    options: {
      showClearButton: true
    },
    changeEvent: 'valueChanged'
  }
};

export default getConfigs(OPTIONS, CONFIGS_FIELDS);
