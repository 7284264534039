<template>
  <!--eslint-disable-->
  <DxPopup
    width="50vh"
    height="auto"
    popupMaxHeight="80vh"
    :animation="null"
    :visible="visible"
    :shading="false"
    :resizeEnabled="true"
    :minWidth="600"
    :toolbarItems="toolbarItems"
    @shown="$emit('shown')"
  >
    <template #titleTemplate>
      <PopupTitleTemplate
        text="Полная копия компонента"
        :showCloseButton="true"
        @closeClick="$emit('hidden')"
      />
    </template>
    <template #contentTemplate>
      <div class="popup-copy-component-form-content">
        <div class="dx-field">
          <div class="dx-field-label">Компонент для копирования</div>
          <div class="dx-field-value">
            <ComponentSearchSelectBox v-model="componentId"></ComponentSearchSelectBox>
          </div>
        </div>
        <div class="dx-field">
          <div class="dx-field-label">Добавить</div>
          <div class="dx-field-value">
            <DxRadioGroup
              :items="Object.values(copyComponentLocation)"
              :value="selectionLocation"
              layout="horizontal"
              @valueChanged="changeSelectionLocation"
            />
          </div>
        </div>
        <div class="dx-field">
          <div class="dx-field-label">Родитель</div>
          <div class="dx-field-value">
            <ComponentSearchSelectBox
              v-if="selectionLocation === copyComponentLocation.toComponent"
              v-model="parentId"
            />
            <DxLookup
              v-else
              v-model="url"
              :dataSource="dataSource"
              valueExpr="url"
              :displayExpr="getDisplayExpr"
              :searchExpr="['name', 'url']"
              :showClearButton="false"
              :showPopupTitle="false"
            />
          </div>
        </div>
      </div>
    </template>
  </DxPopup>
</template>

<script>
import DxLookup from 'devextreme-vue/lookup';
import DxPopup from 'devextreme-vue/popup';
import PopupTitleTemplate from '../Containers/PopupTitleTemplate';
import DxRadioGroup from 'devextreme-vue/radio-group';
import ComponentSearchSelectBox from './ComponentSearchSelectBox';
import { jsonRPC } from '../../api/api';
import { PLACE_DATASET } from '../../utils/const';

const FIELDS = ['componentId', 'parentId', 'url'];
const mapFields = (fields) => {
  let result = {};
  fields.forEach((fieldName) => {
    result[fieldName] = {
      get() {
        return this.$store.state.uiConstructor.copyComponent[fieldName];
      },
      set(value) {
        this.$store.dispatch('uiConstructor/setCopyComponentField', {
          fieldName,
          fieldValue: value
        });
      }
    };
  });
  return result;
};

export default {
  components: {
    DxLookup,
    DxPopup,
    DxRadioGroup,
    ComponentSearchSelectBox,
    PopupTitleTemplate
  },
  data() {
    return {
      toolbarItems: [
        {
          toolbar: 'bottom',
          location: 'center',
          widget: 'dxButton',
          options: {
            text: 'Копировать',
            type: 'success',
            onClick: () => {
              this.apply();
            }
          }
        },
        {
          toolbar: 'bottom',
          location: 'center',
          widget: 'dxButton',
          options: {
            text: 'Отменить',
            onClick: () => {
              this.$emit('hidden');
            }
          }
        }
      ],

      copyComponentLocation: {
        toComponent: 'В компонент',
        onPage: 'На страницу'
      },
      selectionLocation: 'В компонент',
      dataSource: []
    };
  },
  mounted() {
    jsonRPC('getData', { _config_dataset: PLACE_DATASET }).then((data) => {
      this.dataSource = data;
    });
  },
  props: {
    visible: Boolean
  },
  computed: {
    ...mapFields(FIELDS)
  },
  methods: {
    changeSelectionLocation(e) {
      this.selectionLocation = e.value;
    },
    getDisplayExpr(data) {
      let result = '';
      if (data) {
        result = `${data.name} (${data.url})`;
      }
      return result;
    },
    apply() {
      const copyComponent = this.$store.state.uiConstructor.copyComponent;
      this.$store
        .dispatch('uiConstructor/createCopyComponent', {
          componentId: copyComponent.componentId,
          parentComponentId: copyComponent.parentId,
          url: copyComponent.url ? copyComponent.url.slice(1) : null
        })
        .then((componentId) => {
          this.$store.dispatch('config/loadComponent', { componentId }).then(() => {
            let setParentPayload = {
              componentId,
              changeLoaded: true
            };
            if (copyComponent.parentId) {
              setParentPayload.newParentId = copyComponent.parentId;
              setParentPayload.componentPos = this.lastItemPos(copyComponent.parentId);
            } else {
              setParentPayload.newUrl = copyComponent.url.slice(1);
              setParentPayload.componentPos = this.lastPageItemPos(setParentPayload.newUrl);
            }
            this.$store.dispatch('config/setComponentParent', setParentPayload);
          });
          this.$emit('hidden');
        });
    },
    lastItemPos(componentId) {
      return this.$store.getters['config/lastItemPos'](componentId);
    },
    lastPageItemPos(url) {
      return this.$store.getters['config/getPageLastChildPosition'](url);
    }
  }
};
</script>

<style lang="scss">
.popup-copy-component-form-content {
  display: flex;
  flex-direction: column;

  .dx-field {
    display: flex;
    flex: 0 0 auto;

    & > .dx-field-label {
      float: none;
      width: auto;
      flex: 0 0 180px;
    }
    & > .dx-field-value {
      float: none;
      width: auto;
      flex: 1 1 auto;

      & > .dx-checkbox {
        float: none;
      }
    }
  }
}
</style>
