export default {
  cubeInterface(state) {
    return state.cubeInterface;
  },
  cubes(state) {
    return state.cubes;
  },
  workspaces(state) {
    return state.workspaces;
  },
  cubeInterfaceField: (state) => (fieldName) => {
    return state.cubeInterface[fieldName];
  },
  dimensions(state) {
    return state.dimensions.filter((dimension) => {
      return dimension.name.includes(state.searchString) || dimension.caption.includes(state.searchString);
    });
  },
  measures(state) {
    return state.measures.filter((measure) => {
      return measure.name.includes(state.searchString) || measure.caption.includes(state.searchString);
    });
  },
  searchString(state) {
    return state.searchString;
  },
  elementTypes(state) {
    return state.elementTypes;
  },
  places(state) {
    return state.places;
  },
  selectedElementType(state) {
    return state.selectedElementType;
  }
};
