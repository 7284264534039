const getConfigs = function (optionsList, fieldsList) {
  return optionsList.map((option) => {
    if (fieldsList[option]?.component === 'FieldsBox') {
      fieldsList[option].options.fields = getConfigs(fieldsList[option].options.fields, fieldsList);
    }
    return fieldsList[option];
  });
};

export default getConfigs;
