<template>
  <!--eslint-disable-->
  <div class="popup-action-tree">
    <DxPopup
      width="50vw"
      height="50vwh"
      :animation="null"
      :visible="visible"
      :shading="false"
      :resizeEnabled="true"
      :title="title"
      :focusStateEnabled="false"
      :deferRendering="false"
      :minWidth="600"
      :minHeight="200"
      @shown="$emit('shown')"
    >
      <template #titleTemplate>
        <PopupTitleTemplate
          :text="title"
          :showCloseButton="true"
          @closeClick="$emit('hidden')"
        />
      </template>
      <template #contentTemplate>
        <div class="popup-content">
          <DxToolbar class="toolbar">
            <DxToolbarItem
              widget="dxButton"
              :options="addButtonOptions"
              location="before"
            />
            <DxToolbarItem
              widget="dxButton"
              :options="copyButtonOptions"
              location="before"
            />
            <DxToolbarItem
              widget="dxButton"
              :options="pasteButtonOptions"
              location="before"
            />
            <DxToolbarItem
              widget="dxButton"
              :options="deleteButtonOptions"
              location="after"
            />
          </DxToolbar>
          <DxSortable
            filter=".dx-treeview-item"
            :allowDropInsideItem="true"
            :allowReordering="false"
            :data="eventActions"
            @reorder="handleReorder"
          >
            <DxTreeView
              class="tree-view"
              :dataSource="eventActions"
              data-structure="plain"
              keyExpr="id"
              parentIdExpr="treeParentId"
              displayExpr="actiontype_comments"
              :focusStateEnabled="false"
              :selectByClick="false"
              :searchEnabled="true"
              :searchExpr="['id', 'actiontype_comments', 'action_name', 'actioncomponent_name', 'actioncomponent_id']"
              selectionMode="single"
              @itemClick="itemClick"
            >
              <template #item="{ data: item }">
                <div
                  :class="treeViewItemClasses(item)"
                  :title="treeViewItemTitle(item)"
                >
                  <i
                    v-if="showIcon(item)"
                    :title="getTitle(item)"
                    class="fas fa-filter"
                  >
                  </i>
                  <div :key="item.id">{{ getItemText(item) }}</div>
                </div>
              </template>
            </DxTreeView>
          </DxSortable>
        </div>
      </template>
    </DxPopup>
    <PopupActionForm
      :visible="popupActionFormVisible"
      :title="popupActionFormTitle"
      @hidden="popupActionFormVisible = false"
      @shown="popupActionFormVisible = true"
      @saved="eventActionSaved"
    />
  </div>
</template>

<script>
import DxButton from 'devextreme-vue/button';
import DxPopup from 'devextreme-vue/popup';
import PopupTitleTemplate from '../Containers/PopupTitleTemplate';
import DxTreeView from 'devextreme-vue/tree-view';
import PopupActionForm from './PopupActionForm';
import { DxToolbar, DxItem as DxToolbarItem } from 'devextreme-vue/toolbar';
import DxSortable from 'devextreme-vue/sortable';
import { confirm } from 'devextreme/ui/dialog';

export default {
  components: {
    DxButton, // eslint-disable-line
    DxPopup,
    DxTreeView,
    PopupActionForm,
    DxToolbar,
    DxToolbarItem,
    DxSortable,
    PopupTitleTemplate
  },
  data() {
    return {
      timeout: null,
      lastComponent: {},
      popupActionFormVisible: false,
      popupActionFormTitle: '',
      selectedItem: null,
      pasteButton: null,
      addButtonOptions: {
        icon: 'plus',
        type: 'default',
        hint: 'Добавить действие',
        onClick: () => {
          this.addButtonClick();
        }
      },
      copyButtonOptions: {
        icon: 'fas fa-copy',
        type: 'normal',
        hint: 'Копировать действие',
        onClick: () => {
          this.copyClick();
        }
      },
      pasteButtonOptions: {
        icon: 'fas fa-paste',
        type: 'normal',
        hint: 'Вставить действие',
        onClick: () => {
          this.pasteClick();
        },
        onInitialized: (eventData) => {
          this.pasteButton = eventData.component;
          window.addEventListener('storage', (event) => {
            if (event.key !== 'copiedEventAction' || event.storageArea !== localStorage) {
              return;
            }
            this.refreshPasteButtonOptions();
          });

          this.refreshPasteButtonOptions();
        }
      },
      deleteButtonOptions: {
        icon: 'trash',
        type: 'danger',
        hint: 'Удалить действие',
        onClick: () => {
          this.deleteButtonClick();
        }
      }
    };
  },
  props: {
    visible: Boolean,
    title: {
      type: String,
      default: ''
    }
  },
  computed: {
    eventActions() {
      return this.$store.state.uiConstructor.selectedEventTypeActions || [];
    },
    selectedComponentId() {
      return this.$store.getters['config/selectedComponentId'];
    },
    eventTypeId() {
      return this.$store.state.uiConstructor.selectedEventTypeId;
    },
    allEventActionParams() {
      return this.$store.state.uiConstructor.allEventActionParams;
    }
  },
  watch: {
    selectedComponentId() {
      this.popupActionFormVisible = false;
      this.$store.dispatch('uiConstructor/setSelectedEventType', null);
      this.$store.dispatch('uiConstructor/setSelectedEventAction', {});
    },
    async eventTypeId() {
      this.selectedItem = null;
      await this.loadComponentEventActionsByEventType();
      this.loadBatchByActionsParameters();
    }
  },
  methods: {
    treeViewItemClasses(item) {
      return [
        'tree-view__item',
        {
          'tree-view__item-disable': item.disable
        }
      ];
    },
    treeViewItemTitle(item) {
      return item.disable ? 'Действие отключено' : 'Действие включено';
    },
    searhConfigConditionParam(item) {
      if (!this.allEventActionParams.length) return;

      const currentEventActionParams = this.allEventActionParams.find((param) => +param.id === +item.id);

      return currentEventActionParams?.param.outputname === '_config_condition' ? currentEventActionParams.param : undefined;
    },
    showIcon(item) {
      return !!this.searhConfigConditionParam(item);
    },
    getTitle(item) {
      const configCondition = this.searhConfigConditionParam(item);

      return configCondition?.outputvalue;
    },
    loadBatchByActionsParameters() {
      const eventIds = this.eventActions.map((event) => event.id);

      this.$store.dispatch('uiConstructor/loadAllEventActionParams', eventIds);
    },
    async loadComponentEventActionsByEventType() {
      if (this.eventTypeId === null) {
        return;
      }

      return this.$store
        .dispatch('uiConstructor/loadComponentEventActionsByEventType', {
          componentId: this.selectedComponentId,
          eventTypeId: this.eventTypeId
        })
        .then(() => {
          this.$store.dispatch('uiConstructor/loadComponentEventActions', this.selectedComponentId);
        });
    },
    itemClick(eventData) {
      if (!this.timeout) {
        eventData.component.selectItem(eventData.itemData.id);
        this.selectedItem = eventData.itemData;
        this.lastComponent = eventData.itemData;
        this.timeout = setTimeout(() => {
          this.timeout = null;
        }, 300);
      } else if (eventData.itemData === this.lastComponent) {
        //Двойной клик
        this.popupActionFormVisible = true;
        this.popupActionFormTitle = 'Действие: ' + this.getItemText(eventData.itemData);
        this.$store.dispatch('uiConstructor/setSelectedEventAction', eventData.itemData);
        this.$store.dispatch('uiConstructor/loadEventActionParams', eventData.itemData.id);
      }
    },
    getItemText(item) {
      let wrongParent = '';
      if (item.parent_id !== item.treeParentId) {
        wrongParent = ` - родитель ${item.parent_id} не найден!`;
      }
      return `${item.id} ${item.actiontype_comments} (${item.action_name}) ${item.actioncomponent_name} (${item.actioncomponent_id})${wrongParent}`;
    },
    handleReorder(eventData) {
      const nodeList = eventData.component.element().querySelectorAll('.dx-treeview-item');
      const eventActionId = parseInt(nodeList[eventData.fromIndex].parentElement.dataset.itemId);
      const parentId = eventData.dropInsideItem ? parseInt(nodeList[eventData.toIndex].parentElement.dataset.itemId) : null;
      const eventActionData = this.$store.getters['uiConstructor/getEventActionDataById'](eventActionId);

      if (!eventActionData) {
        return;
      }

      eventActionData.parent_id = parentId;
      this.$store.dispatch('uiConstructor/saveEventActionData', eventActionData).then(() => {
        this.loadComponentEventActionsByEventType();
      });
    },
    addButtonClick() {
      this.popupActionFormVisible = true;
      this.popupActionFormTitle = 'Добавление действия';
      this.$store.dispatch('uiConstructor/setSelectedEventAction', {
        eventtype_id: this.eventTypeId,
        eventcomponent_id: this.selectedComponentId
      });
    },
    eventActionSaved(eventActionData) {
      this.loadComponentEventActionsByEventType();
      this.popupActionFormTitle = 'Действие: ' + this.getItemText(eventActionData);
      this.$store.dispatch('uiConstructor/setSelectedEventAction', eventActionData);
    },
    deleteButtonClick() {
      confirm('Удалить выбранное событие действие?', 'Подтвердите удаление').then((dialogResult) => {
        if (!dialogResult) {
          return;
        }

        this.$store.dispatch('uiConstructor/deleteEventAction', this.selectedItem.id).then(() => {
          this.loadComponentEventActionsByEventType();
        });
      });
    },
    copyClick() {
      if (!this.selectedItem && !this.selectedItem.id) {
        return;
      }

      const eventActionData = this.eventActions.filter((eventAction) => {
        return eventAction.id === this.selectedItem.id;
      })[0];

      if (eventActionData) {
        localStorage.setItem('copiedEventAction', JSON.stringify(Object.assign({}, eventActionData)));
        if (this.pasteButton) {
          this.pasteButton.option('disabled', false);
          this.setPasteButtonHint(eventActionData);
        }
      }
    },
    pasteClick() {
      const eventAction = this.getCopiedEventAction();
      if (!eventAction) {
        return;
      }

      this.$store
        .dispatch('uiConstructor/copyEventAction', {
          eventActionId: eventAction.id,
          componentId: this.selectedComponentId,
          eventTypeId: this.eventTypeId
        })
        .then(() => {
          this.loadComponentEventActionsByEventType();
          this.loadBatchByActionsParameters();
        });
    },
    getCopiedEventAction() {
      let copiedEventActionStr = localStorage.getItem('copiedEventAction');
      if (!copiedEventActionStr) {
        return null;
      }

      return JSON.parse(copiedEventActionStr);
    },
    setPasteButtonHint(eventAction) {
      const pasteHint =
        `В буфере обмена событие-действие:` +
        `\nid ${eventAction.id}` +
        `\nСобытие: ${eventAction.eventtype_name}` +
        `\nДействие: ${eventAction.action_caption}`;

      this.pasteButton.option('hint', pasteHint);
    },
    refreshPasteButtonOptions() {
      const eventAction = this.getCopiedEventAction();
      if (eventAction) {
        this.pasteButton.option('disabled', false);
        this.setPasteButtonHint(eventAction);
      } else {
        this.pasteButton.option('disabled', true);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.popup-content {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.toolbar {
  flex: 0 0 auto;
}

.tree-view {
  margin-top: 5px;
  flex: 1 1 auto;

  &__item {
    display: flex;
  }

  &__item-disable {
    opacity: 0.3;
  }

  .fa-filter {
    color: var(--base-accent);
    margin: auto 5px auto 0;
  }
}
</style>
