export function formatDateTime(value) {
  return value < 10 ? '0' + value : value;
}

/**
 * Форматирует дату от GisTimelineSlider в соответствии с выбранным типом
 * @param date - дата
 * @param mode - режим даты (hour, date, month, year, 20minutes)
 * @return {string|*|string} - строка с представлением даты
 */
export function formatTimelineValue(date, mode, fieldType) {
  switch (mode) {
    case 'year':
      if (fieldType === 'timestamp') {
        //Поле даты
        return getISODateWithoutTime(date);
      } else {
        //Целое поле
        return date.getFullYear ? date.getFullYear() : date.toString();
      }
    case 'month':
    case 'date':
      return getISODateWithoutTime(date);
    case 'hour':
    case '20minutes':
      return getISODate(date);
  }
}

/**Очистка даты от лишних разрядов: минут, секунд ... */
export function clearDate(date, mode) {
  let ret = new Date(date.getTime());
  ret.setSeconds(0);
  ret.setMilliseconds(0);
  switch (mode) {
    case 'year':
      ret.setMonth(0);
    case 'month':
      ret.setDate(1);
    case 'date':
      ret.setHours(0);
    case 'hour':
      ret.setMinutes(0);
  }
  return ret;
}

export function getISODate(date) {
  return `${date.getFullYear()}-${getTwoDigitNumber(date.getMonth() + 1)}-${getTwoDigitNumber(date.getDate())}T${getTwoDigitNumber(
    date.getHours()
  )}:${getTwoDigitNumber(date.getMinutes())}:${getTwoDigitNumber(date.getSeconds())}`;
}

/**
 * Возвращает строку с датой ISO без времени
 * @param {*} date
 * @returns
 */
export function getISODateWithoutTime(date) {
  return `${date.getFullYear()}-${getTwoDigitNumber(date.getMonth() + 1)}-${getTwoDigitNumber(date.getDate())}`;
}

function getTwoDigitNumber(number) {
  return number.toLocaleString('ru-RU', { minimumIntegerDigits: 2 });
}

const DAYS_IN_MONTH = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

export function getDaysInMonth(year, month) {
  let dayCount = DAYS_IN_MONTH[month];

  if (month === 1 && ((year % 100 !== 0 && year % 4 === 0) || (year % 100 === 0 && year % 400 === 0))) {
    dayCount = 29;
  }

  return dayCount;
}

export const MONTH_NAMES = ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'];
