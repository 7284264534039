import getConfigs from './configUtils';
const OPTIONS = [
  'type',
  'count',
  'deferRequestBy',
  'floating',
  'hint',
  'minChars',
  'noCache',
  'noSuggestionsHint',
  'triggerSelectOnSpace',
  'chooseSuggestionValue',
  'placeholder'
];

const CONFIGS_FIELDS = {
  type: {
    name: 'type',
    label: 'Тип',
    component: 'DxSelectBox',
    options: {
      dataSource: [
        { text: 'Список адресов', value: 'ADDRESS' },
        { text: 'Организации', value: 'PARTY' },
        { text: 'Банки', value: 'BANK' },
        { text: 'ФИО', value: 'NAME' },
        { text: 'email', value: 'EMAIL' }
      ],
      showClearButton: true,
      displayExpr: 'text',
      valueExpr: 'value'
    },
    changeEvent: 'optionChanged'
  },
  count: {
    name: 'count',
    label: 'Макс. кол-во подсказок в выпадающем списке',
    component: 'DxTextBox',
    options: {
      hint: 'Макс. кол-во подсказок в выпадающем списке',
      showClearButton: true
    },
    changeEvent: 'valueChanged'
  },
  deferRequestBy: {
    name: 'deferRequestBy',
    label: 'Период ожидания перед отправкой запроса (мс)',
    component: 'DxTextBox',
    options: {
      hint: 'Период ожидания перед отправкой запроса (мс)',
      showClearButton: true
    },
    changeEvent: 'valueChanged'
  },
  floating: {
    name: 'floating',
    label: 'Отображать подсказки повер всех элементов',
    component: 'DxSelectBox',
    options: {
      hint: 'Отображать подсказки повер всех элементов',
      dataSource: [
        { text: 'Да', value: 'true' },
        { text: 'Нет', value: 'false' }
      ],
      showClearButton: true,
      displayExpr: 'text',
      valueExpr: 'value'
    },
    changeEvent: 'optionChanged'
  },
  hint: {
    name: 'hint',
    label: 'Поясняющий текст в выпадающем списке',
    component: 'DxTextBox',
    options: {
      hint: 'Поясняющий текст в выпадающем списке',
      showClearButton: true
    },
    changeEvent: 'valueChanged'
  },
  minChars: {
    name: 'minChars',
    label: 'Мин. кол-во символов для вызова подсказок',
    component: 'DxTextBox',
    options: {
      hint: 'Мин. кол-во символов для вызова подсказок',
      showClearButton: true
    },
    changeEvent: 'valueChanged'
  },
  noCache: {
    name: 'noCache',
    label: 'Кэширование ответов сервера',
    component: 'DxSelectBox',
    options: {
      hint: 'Кэширование ответов сервера',
      dataSource: [
        { text: 'Да', value: 'true' },
        { text: 'Нет', value: 'false' }
      ],
      showClearButton: true,
      displayExpr: 'text',
      valueExpr: 'value'
    },
    changeEvent: 'optionChanged'
  },
  noSuggestionsHint: {
    name: 'noSuggestionsHint',
    label: 'Сообщение при отсутствии результата',
    component: 'DxTextBox',
    options: {
      hint: 'Сообщение при отсутствии результата',
      showClearButton: true
    },
    changeEvent: 'valueChanged'
  },
  triggerSelectOnSpace: {
    name: 'triggerSelectOnSpace',
    label: 'Нажатие пробела для выбора подсказки',
    component: 'DxSelectBox',
    options: {
      hint: 'Нажатие пробела для выбора подсказки',
      dataSource: [
        { text: 'Да', value: 'true' },
        { text: 'Нет', value: 'false' }
      ],
      showClearButton: true,
      displayExpr: 'text',
      valueExpr: 'value'
    },
    changeEvent: 'optionChanged'
  },
  chooseSuggestionValue: {
    name: 'chooseSuggestionValue',
    label: 'Установить значение из данных DaData',
    component: 'DxSelectBox',
    options: {
      hint: 'Установить значение из данных DaData',
      dataSource: [
        { text: 'Да', value: 'true' },
        { text: 'Нет', value: 'false' }
      ],
      showClearButton: true,
      displayExpr: 'text',
      valueExpr: 'value'
    },
    changeEvent: 'optionChanged'
  },
  placeholder: {
    name: 'placeholder',
    label: 'Подсказка (placeholder)',
    component: 'DxTextBox',
    options: {
      showClearButton: true
    },
    changeEvent: 'valueChanged'
  }
};

export default getConfigs(OPTIONS, CONFIGS_FIELDS);
