import Vue from 'vue';

import DxTextBox from 'devextreme-vue/text-box';
Vue.component('DxTextBox', DxTextBox);

import DxRadioGroup from 'devextreme-vue/radio-group';
Vue.component('DxRadioGroup', DxRadioGroup);

import DxSelectBox from 'devextreme-vue/select-box';
Vue.component('DxSelectBox', DxSelectBox);

import DxNumberBox from 'devextreme-vue/number-box';
Vue.component('DxNumberBox', DxNumberBox);

import DxDateBox from 'devextreme-vue/date-box';
Vue.component('DxDateBox', DxDateBox);

import FieldsBox from './FieldsBox';
Vue.component('FieldsBox', FieldsBox);

import DxColorBox from 'devextreme-vue/color-box';
Vue.component('DxColorBox', DxColorBox);

import AddBox from './AddBox';
Vue.component('AddBox', AddBox);

import DxLookup from 'devextreme-vue/lookup';
Vue.component('DxLookup', DxLookup);

import DxHtmlEditor from 'devextreme-vue/html-editor';
Vue.component('DxHtmlEditor', DxHtmlEditor);
