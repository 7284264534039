import getConfigs from './configUtils';
const OPTIONS = ['text', 'type', 'mapBox', 'paint', 'is_visible', 'is_edit', 'is_select', 'options_request'];

const CONFIGS_FIELDS = {
  text: {
    name: 'text',
    label: 'Наименование слоя',
    component: 'DxTextBox',
    options: {
      showClearButton: true
    },
    changeEvent: 'valueChanged'
  },
  type: {
    name: 'type',
    label: 'Тип данных',
    component: 'DxTextBox',
    options: {
      showClearButton: true
    },
    changeEvent: 'valueChanged'
  },
  paint: {
    name: 'paint',
    label: 'Настройка раскраски',
    component: 'FieldsBox',
    options: {
      fields: ['fill', 'line', 'circle'],
      fieldName: 'Настройка раскраски'
    },
    changeEvent: 'change'
  },
  fill: {
    name: 'fill',
    label: 'Полигон',
    component: 'FieldsBox',
    options: {
      fields: ['fill-color', 'fill-opacity'],
      fieldName: 'Полигон'
    },
    changeEvent: 'change'
  },
  'fill-color': {
    name: 'fill-color',
    label: 'Цвет',
    component: 'DxColorBox',
    options: {
      showClearButton: true
    },
    changeEvent: 'valueChanged'
  },
  'fill-opacity': {
    name: 'fill-opacity',
    label: 'Прозрачность',
    component: 'DxNumberBox',
    options: {
      showClearButton: true
    },
    changeEvent: 'valueChanged'
  },
  line: {
    name: 'line',
    label: 'Линия',
    component: 'FieldsBox',
    options: {
      fields: ['line-color', 'line-opacity', 'line-width'],
      fieldName: 'Линия'
    },
    changeEvent: 'change'
  },
  'line-color': {
    name: 'line-color',
    label: 'Цвет',
    component: 'DxColorBox',
    options: {
      showClearButton: true
    },
    changeEvent: 'valueChanged'
  },
  'line-opacity': {
    name: 'line-opacity',
    label: 'Прозрачность',
    component: 'DxNumberBox',
    options: {
      showClearButton: true
    },
    changeEvent: 'valueChanged'
  },
  'line-width': {
    name: 'line-width',
    label: 'Толщина',
    component: 'DxNumberBox',
    options: {
      showClearButton: true
    },
    changeEvent: 'valueChanged'
  },
  circle: {
    name: 'circle',
    label: 'Точка',
    component: 'FieldsBox',
    options: {
      fields: ['circle-color', 'circle-opacity', 'circle-radius', 'circle-stroke-width', 'circle-stroke-color'],
      fieldName: 'Точка'
    },
    changeEvent: 'change'
  },
  'circle-color': {
    name: 'circle-color',
    label: 'Цвет',
    component: 'DxColorBox',
    options: {
      showClearButton: true
    },
    changeEvent: 'valueChanged'
  },
  'circle-opacity': {
    name: 'circle-opacity',
    label: 'Прозрачность',
    component: 'DxNumberBox',
    options: {
      showClearButton: true
    },
    changeEvent: 'valueChanged'
  },
  'circle-radius': {
    name: 'circle-radius',
    label: 'Радиус',
    component: 'DxNumberBox',
    options: {
      showClearButton: true
    },
    changeEvent: 'valueChanged'
  },
  'circle-stroke-width': {
    name: 'circle-stroke-width',
    label: 'Толщина обводки',
    component: 'DxNumberBox',
    options: {
      showClearButton: true
    },
    changeEvent: 'valueChanged'
  },
  'circle-stroke-color': {
    name: 'circle-stroke-color',
    label: 'Цвет обводки',
    component: 'DxColorBox',
    options: {
      showClearButton: true
    },
    changeEvent: 'valueChanged'
  },
  mapBox: {
    name: 'mapBox',
    label: 'Настройка слоя для Mapbox',
    component: 'FieldsBox',
    options: {
      fields: ['layerBefore', 'layerConfig'],
      fieldName: 'Настройка слоя для Mapbox'
    },
    changeEvent: 'change'
  },
  layerBefore: {
    name: 'layerBefore',
    label: 'Расположить текущий слой перед',
    component: 'DxTextBox',
    options: {
      showClearButton: true
    },
    changeEvent: 'valueChanged'
  },
  layerConfig: {
    name: 'layerConfig',
    label: 'Настройка слоя',
    component: 'FieldsBox',
    options: {
      fields: ['layerConfig_type', 'layerConfig_source', 'layerConfig_source-layer'],
      fieldName: 'Настройка слоя'
    },
    changeEvent: 'change'
  },
  layerConfig_type: {
    name: 'type',
    label: 'Тип данных',
    component: 'DxSelectBox',
    options: {
      dataSource: [
        { text: 'Растровые данные', value: 'raster' },
        { text: 'Векторные данные Geojson', value: 'geojson' },
        { text: 'Векторные данные Pbf', value: 'vector' }
      ],
      showClearButton: true,
      displayExpr: 'text',
      valueExpr: 'value'
    },
    changeEvent: 'optionChanged'
  },
  'layerConfig_source-layer': {
    name: 'source-layer',
    label: 'Датасет из векторного слоя',
    component: 'DxTextBox',
    options: {
      showClearButton: true
    },
    changeEvent: 'valueChanged'
  },
  layerConfig_source: {
    name: 'source',
    label: 'Настройка слоя',
    component: 'FieldsBox',
    options: {
      fields: ['layerConfig_type', 'layerConfig_source_tileSize', 'layerConfig_source_domain', 'layerConfig_source_url'],
      // 'layerConfig_source_data'
      fieldName: 'Настройка слоя'
    },
    changeEvent: 'change'
  },
  layerConfig_source_domain: {
    name: 'domain',
    label: 'Поддомены',
    component: 'DxTextBox',
    options: {
      showClearButton: true
    },
    changeEvent: 'valueChanged'
  },
  layerConfig_source_url: {
    name: 'url',
    label: 'Ссылка подключения сервиса',
    component: 'DxTextBox',
    options: {
      showClearButton: true
    },
    changeEvent: 'valueChanged'
  },
  layerConfig_source_tileSize: {
    name: 'tileSize',
    label: 'Размер тайла',
    component: 'DxNumberBox',
    options: {
      showClearButton: true
    },
    changeEvent: 'valueChanged'
  },
  // layerConfig_source_data: {
  //   name: 'data',
  //   label: 'Данные Geojson',
  //   component: 'DxTextBox',
  //   options: {
  //     showClearButton: true
  //   },
  //   changeEvent: 'valueChanged'
  // },
  is_visible: {
    name: 'is_visible',
    label: 'Показать слой',
    component: 'DxSelectBox',
    options: {
      dataSource: [
        { text: 'Да', value: 'true' },
        { text: 'Нет', value: 'false' }
      ],
      displayExpr: 'text',
      valueExpr: 'value'
    },
    changeEvent: 'optionChanged'
  },
  is_edit: {
    name: 'is_edit',
    label: 'Режим редактирование включен',
    component: 'DxSelectBox',
    options: {
      dataSource: [
        { text: 'Да', value: 'true' },
        { text: 'Нет', value: 'false' }
      ],
      showClearButton: true,
      displayExpr: 'text',
      valueExpr: 'value'
    },
    changeEvent: 'optionChanged'
  },
  is_select: {
    name: 'is_select',
    label: 'Режим выбора включен',
    component: 'DxSelectBox',
    options: {
      dataSource: [
        { text: 'Да', value: 'true' },
        { text: 'Нет', value: 'false' }
      ],
      showClearButton: true,
      displayExpr: 'text',
      valueExpr: 'value'
    },
    changeEvent: 'optionChanged'
  },
  options_request: {
    name: 'options_request',
    label: 'Настройка запроса данных',
    component: 'FieldsBox',
    options: {
      fields: ['_config_epsg', '_config_dataset', '_config_is_geojson', '_config_select_mode'],
      fieldName: 'Сортировка'
    },
    changeEvent: 'change'
  },
  _config_epsg: {
    name: '_config_epsg',
    label: 'Проекция (EPSG Code)',
    component: 'DxNumberBox',
    options: {
      showClearButton: true
    },
    changeEvent: 'valueChanged'
  },
  _config_dataset: {
    name: '_config_dataset',
    label: 'Имя датасета',
    component: 'DxTextBox',
    options: {
      showClearButton: true
    },
    changeEvent: 'valueChanged'
  },
  _config_is_geojson: {
    name: '_config_is_geojson',
    label: 'Формат ответа Geojson',
    component: 'DxSelectBox',
    options: {
      dataSource: [
        { text: 'Да', value: 'true' },
        { text: 'Нет', value: 'false' }
      ],
      showClearButton: true,
      displayExpr: 'text',
      valueExpr: 'value'
    },
    changeEvent: 'optionChanged'
  },
  _config_select_mode: {
    name: '_config_select_mode',
    label: 'Тип поиска данных',
    component: 'DxSelectBox',
    options: {
      dataSource: [
        { text: 'Пересечение', value: 'intersect' }
        //{ text: 'Нет', value: 'false' } // дописать остальные
      ],
      showClearButton: true,
      displayExpr: 'text',
      valueExpr: 'value'
    },
    changeEvent: 'optionChanged'
  }
};

export default getConfigs(OPTIONS, CONFIGS_FIELDS);
