import { getOptions, getItem } from '../getters';

//Локальные названия геттеров
const PREFIX = 'Form';
export const getFilter = `${PREFIX}/getFilter`;

export default {
  [getFilter]: (state, getters) => (componentId) => {
    const options = getters[getOptions](componentId);
    const selectedData = getters[getItem](componentId).selectedData || {};

    if (!options.filter || !Object.values(options.filter).length) {
      return {
        expression: null,
        params: selectedData
      };
    }

    const constructorFilter = Object.values(options.filter);
    let constructorFilterValue = [];
    constructorFilter
      .filter((item) => item)
      .forEach((item, index) => {
        if (index) {
          constructorFilterValue.push('and');
        }
        constructorFilterValue.push(item);
      });
    if (constructorFilterValue.length === 1) {
      return {
        expression: constructorFilterValue[0],
        params: selectedData
      };
    }
    if (!constructorFilterValue.length) {
      return {
        expression: null,
        params: selectedData
      };
    }

    return {
      expression: constructorFilterValue,
      params: selectedData
    };
  }
};
