//Default
export const ERR_PARSE_ERROR = -32700; // Код ошибки для ошибки "Parse error"
export const ERR_INVALID_REQUEST = -32600; // Код ошибки "Неверный запрос"
export const ERR_METHOD_NOT_FOUND = -32601; // Код ошибки для ошибки "Метод не найден"
export const ERR_INVALID_PARAMS = -32602; // Код ошибки "Неверные параметры"
export const ERR_INTERNAL_ERROR = -32603; // Код ошибки для ошибки "Внутренняя ошибка"
export const ERR_SERVER_ERROR = -32000; // Код ошибки "Ошибка сервера"
export const ERR_EMPTY_REQUEST = -32097; // Код ошибки "Пустой запрос"

// Из HTTP-CONTROL
export const ACCESS_DENIED = -37000; // Доступ запрещен

export const NO_DATA_METHOD = -37001; // Метод запроса не полноценный, эталон - CODE_SYSTEM.SOURCE_DATA.METHOD_NAME или NULL.NULL.METHOD_NAME

export const REDIS_CONNECTION_ERROR = -37002; // Ошибка подключения к redis

export const NO_TOKEN = -37003; // Нет токена или токен не имеет значения
export const ERROR_IN_RIGHTS = -37004; // Ошибка в данных прав

export const ERROR_RECEIVING_DATA_FROM_SESSION_SERVICE = -37005; // Ошибка получения данных из сервиса сессий
export const NO_DATA_IN_SERVICE_SESSION_BY_TOKEN = -37006; // Нет данных в сервисе сессий по полученному токену
export const SESSION_EXPIRED = -37007; // Сессия токена истекла

export const RIGHTS_NO_SERVICE_RIGHT = -37008; // Нет доступ к сервису
export const RIGHTS_NO_DATASETS = -37009; // В правах пользователя нет датасетов
export const RIGHTS_NO_DATASET = -37010; // В правах пользователя нет указанного датасета

export const UNKNOWN_ERROR = -37099; // Неизвестная ошибка

// Другие
export const DB_CONNECTION_ERROR = -37011; // Ошибка подключения к БД
export const DB_QUERY_EXECUTION_ERROR = -37012; // Ошибка выполнения запроса в БД
export const RESTY_COOKIE_ERROR = -37013; // Ошибка получения resty.cookie
export const REQUEST_ERROR = -37014; // Ошибка выполнения запроса (POST, GET, etc.)

export const RIGHTS_NO_CONFIG_DATASET = -37015; //Нет доступа к датасету (config_dataset не имеет значения)

//Коды, которые сообщают о том, что требуется авторизация
export const NEED_LOGIN_CODES = [NO_TOKEN, NO_DATA_IN_SERVICE_SESSION_BY_TOKEN, SESSION_EXPIRED, ERROR_IN_RIGHTS];
