<template>
  <!--eslint-disable-->
  <DxPopup
    class="access-creator-change-popup"
    :container="popupContainer"
    :visible="visible"
    :shading="false"
    :resizeEnabled="true"
    :toolbarItems="popupToolbarItems"
    :minWidth="deviceType === 'desktop' ? 600 : '90%'"
    :width="deviceType === 'desktop' ? 600 : '90%'"
    :minHeight="280"
    :dragEnabled="true"
    @hidden="close"
  >
    <template #titleTemplate>
      <PopupTitleTemplate
        text="Реестр пользователей"
        :showCloseButton="true"
        @closeClick="close"
      />
    </template>
    <template #contentTemplate>
      <div class="access-creator-change-popup__content">
        <DxDataGrid
          class="data-grid"
          v-if="!loadPanelVisible"
          height="100%"
          width="100%"
          keyExpr="id"
          displayExpr="caption"
          :showRowLines="false"
          :showColumnLines="false"
          :dataSource="dataSource"
          :allowColumnResizing="false"
          :columnAutoWidth="true"
          :rowAlternationEnabled="true"
          :hoverStateEnabled="true"
          @selectionChanged="onSelectionChanged"
        >
          <DxSelection mode="single" />
          <DxSearchPanel :visible="true" />
          <DxPager
            infoText="Страница {0} из {1} • Всего {2} элементов"
            :visible="true"
            :showInfo="true"
          />
          <DxColumn
            dataType="number"
            dataField="userid"
            caption="ID"
          />
          <DxColumn
            dataType="string"
            dataField="emailaddress"
            caption="Логин"
          />
          <DxColumn
            dataType="string"
            dataField="name"
            caption="ФИО"
          />
          <DxColumn
            dataType="date"
            dataField="createdate"
            caption="Дата создания"
          />
        </DxDataGrid>
        <DxLoadPanel
          :visible="loadPanelVisible"
          container=".access-creator-change-popup__content"
        >
          <DxLoadPanelPosition
            my="center"
            at="center"
            of=".access-creator-change-popup__content"
          />
        </DxLoadPanel>
      </div>
    </template>
  </DxPopup>
</template>

<script>
import DxPopup from 'devextreme-vue/popup';
import PopupTitleTemplate from './Containers/PopupTitleTemplate';
import { DxDataGrid, DxSearchPanel, DxPager, DxColumn, DxSelection } from 'devextreme-vue/data-grid';
import { DxLoadPanel, DxPosition as DxLoadPanelPosition } from 'devextreme-vue/load-panel';
import { jsonRPC } from '@/api/api';

const USERS_DATASET = 'CONNECTIONS.DSUSER_';
const COMPONENT_DATASET = 'CONFIG.DSCOMPONENT';

export default {
  name: 'AccessCreatorChangePopup',
  props: {
    visible: Boolean,
    creatorId: Number,
    popupContainer: null
  },
  components: {
    DxPager,
    DxLoadPanel,
    DxLoadPanelPosition,
    DxPopup,
    PopupTitleTemplate,
    DxDataGrid,
    DxSearchPanel,
    DxColumn,
    DxSelection
  },
  data() {
    return {
      loadPanelVisible: false,
      dataSource: [],
      newCreatorId: null,
      applyBtnDisabled: true
    };
  },
  computed: {
    popupToolbarItems: function () {
      return [
        {
          toolbar: 'bottom',
          location: 'center',
          widget: 'dxButton',
          options: {
            text: 'Применить',
            type: 'success',
            disabled: this.applyBtnDisabled,
            onClick: () => {
              this.apply();
            }
          }
        }
      ];
    },
    deviceType() {
      return this.$store.getters['config/getDeviceType'];
    }
  },
  methods: {
    getData() {
      this.loadPanelVisible = true;
      jsonRPC('getData', {
        _config_dataset: USERS_DATASET
      })
        .then((data) => {
          this.dataSource = data;
        })
        .finally(() => (this.loadPanelVisible = false));
    },
    onSelectionChanged({ selectedRowsData }) {
      this.newCreatorId = selectedRowsData[0].userid || null;
      this.applyBtnDisabled = false;
    },
    apply() {
      if (this.newCreatorId) {
        this.$emit('changeCreatorId', this.newCreatorId);
        this.close();
      }
    },
    close() {
      this.$emit('close');
    }
  },
  mounted() {
    this.getData();
  }
};
</script>

<style lang="scss"></style>
