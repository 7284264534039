<template>
  <!--eslint-disable-->
  <DxPopup
    width="50vw"
    height="auto"
    popupMaxHeight="80vh"
    :animation="null"
    :visible="visible"
    :shading="false"
    :resizeEnabled="true"
    :minWidth="600"
    :toolbarItems="toolbarItems"
    @shown="$emit('shown')"
  >
    <template #titleTemplate>
      <PopupTitleTemplate
        text="Создание компонента"
        :showCloseButton="true"
        @closeClick="close"
      />
    </template>
    <template #contentTemplate>
      <div class="popup-new-component-form-content">
        <div class="dx-field">
          <div class="dx-field-label">Тип</div>
          <div class="dx-field-value">
            <DxLookup
              :key="lookupKey"
              v-model="type"
              :dataSource="sortTypeComponents"
              valueExpr="name"
              displayExpr="name"
              :searchEnabled="true"
              :cleanSearchOnOpening="true"
              :searchExpr="['name']"
              :showClearButton="false"
              :showPopupTitle="false"
              item-template="lookup-item"
            >
              <DxDropDownOptions
                :closeOnOutsideClick="true"
                :showTitle="false"
              />
              <template #lookup-item="{ data }">
                <div class="popup-new-component-form-content__lookup-item">
                  <i
                    class="popup-new-component-form-content__icon"
                    :title="getTitle(data)"
                    :class="getIcon(data)"
                  />
                  <div>{{ data.name }}</div>
                </div>
              </template>
            </DxLookup>
          </div>
        </div>
        <div class="dx-field">
          <div class="dx-field-label">Название</div>
          <div class="dx-field-value">
            <DxTextBox v-model="name"> </DxTextBox>
          </div>
        </div>
        <div class="dx-field">
          <div class="dx-field-label">Родитель</div>
          <div class="dx-field-value">
            <ComponentSearchSelectBox
              v-model="parentId"
              :showClearButton="pageMode"
            />
          </div>
        </div>
        <div class="dx-field">
          <div class="dx-field-label">Позиция</div>
          <div class="dx-field-value">
            <DxNumberBox v-model="pos"> </DxNumberBox>
          </div>
        </div>
      </div>
    </template>
  </DxPopup>
</template>

<script>
import { mapActions } from 'vuex';
import { DxLookup, DxDropDownOptions } from 'devextreme-vue/lookup';
import DxNumberBox from 'devextreme-vue/number-box';
import DxTextBox from 'devextreme-vue/text-box';
import DxPopup from 'devextreme-vue/popup';
import PopupTitleTemplate from '../Containers/PopupTitleTemplate';
import ComponentSearchSelectBox from './ComponentSearchSelectBox';
import { ICONS_CSS } from '@/utils/const';
import { insertComponent } from '@/store/modules/config/actions';

const FIELDS = ['type', 'name', 'parentId', 'pos'];
const EMPTY_COMPONENT = {
  type: null,
  name: '<New component>',
  parentId: null,
  pos: 0
};
const mapFields = (fields) => {
  let result = {};
  fields.forEach((fieldName) => {
    result[fieldName] = {
      get() {
        return this.$store.state.uiConstructor.newComponent[fieldName];
      },
      set(value) {
        this.$store.dispatch('uiConstructor/setNewComponentField', {
          fieldName,
          fieldValue: value
        });
      }
    };
  });
  return result;
};

export default {
  components: {
    DxLookup,
    DxDropDownOptions,
    DxNumberBox,
    DxTextBox,
    DxPopup,
    ComponentSearchSelectBox,
    PopupTitleTemplate
  },
  data() {
    return {
      lookupKey: 0,
      toolbarItems: [
        {
          toolbar: 'bottom',
          location: 'center',
          widget: 'dxButton',
          options: {
            text: 'Добавить',
            type: 'success',
            onClick: () => {
              this.apply();
            }
          }
        },
        {
          toolbar: 'bottom',
          location: 'center',
          widget: 'dxButton',
          options: {
            text: 'Отменить',
            onClick: () => {
              this.close();
            }
          }
        }
      ]
    };
  },
  props: {
    visible: Boolean
  },
  computed: {
    sortTypeComponents() {
      const typeComponents = this.$store.getters['uiConstructor/componentTypes'];

      return typeComponents.sort(({ name: nameComponentOne }, { name: nameComponentTwo }) => {
        return nameComponentOne.localeCompare(nameComponentTwo);
      });
    },
    pageMode() {
      return !!this.$route.params.childUrl;
    },
    ...mapFields(FIELDS)
  },

  methods: {
    ...mapActions('config', {
      insertComponent
    }),

    getTitle({ name }) {
      const componentTypeRegistred = this.componentTypeRegistred(name);

      return componentTypeRegistred ? '' : 'Компонент не реализован';
    },
    getIcon({ name }) {
      const componentTypeRegistred = this.componentTypeRegistred(name);

      if (!componentTypeRegistred) {
        return 'core core-075-attention';
      }

      // Берем иконки из словаря ICONS_CSS, если значения нет, ставим дефолтную
      return ICONS_CSS[name] || 'core core-default-component';
    },
    componentTypeRegistred(name) {
      return !!this.$options.components[name];
    },
    apply() {
      const newComponent = this.$store.state.uiConstructor.newComponent;

      //Не работает вызов действия через mapActions
      this.$store
        .dispatch('config/insertComponent', {
          componentType: newComponent.type,
          componentName: newComponent.name,
          parentComponentId: newComponent.parentId,
          componentPos: newComponent.pos,
          url: this.$route.params.childUrl,
          device: this.$store.getters['uiConstructor/resolutionDevice'],
          options: {}
        })
        .then((componentId) => {
          this.$store.dispatch('config/setConstructorActiveComponent', {
            componentId,
            keepSelected: false
          });
          this.lookupKey++;
          this.close();
        });
    },
    close() {
      FIELDS.map((field) => {
        this.$store.dispatch('uiConstructor/setNewComponentField', {
          fieldName: field,
          fieldValue: EMPTY_COMPONENT[field]
        });
      });
      this.lookupKey++;
      this.$emit('hidden');
    }
  }
};
</script>

<style lang="scss">
.popup-new-component-form-content {
  display: flex;
  flex-direction: column;

  .core-075-attention {
    color: var(--base-warning);
  }

  .dx-field {
    display: flex;
    flex: 0 0 auto;

    & > .dx-field-label {
      float: none;
      width: auto;
      flex: 0 0 80px;
    }

    & > .dx-field-value {
      float: none;
      width: auto;
      flex: 1 1 auto;

      & > .dx-checkbox {
        float: none;
      }
    }
  }

  &__lookup-item {
    display: flex !important;
  }

  &__icon {
    opacity: 0.5;
    margin-right: 5px;
  }
}
</style>
