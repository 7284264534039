const TMP_LAYER_OPTIONS = {
  tmpLayer: true,
  type: 'geojson',
  enable: false,
  visible: true,
  geomTypes: ['Point', 'Line', 'Polygon', 'GeometryCollection'],
  //keyField: null,
  sourceOptions: {
    tolerance: 0.001
  },
  legendStyleItems: {},
  showInsertCard: false,
  selectionMapboxLayers: [
    {
      type: 'fill',
      paint: {
        'fill-color': '#072833',
        'fill-opacity': 0.25
      },
      geometry: ['Polygon', 'MultiPolygon'],
      layout: {
        visibility: 'visible'
      },
      maxzoom: 24,
      minzoom: 0
    },
    {
      type: 'line',
      paint: {
        'line-color': '#072833',
        'line-width': 2,
        'line-dasharray': [1, 1]
      },
      geometry: ['LineString', 'MultiLineString', 'Polygon', 'MultiPolygon'],
      layout: {
        visibility: 'visible'
      },
      maxzoom: 24,
      minzoom: 0
    },
    {
      type: 'circle',
      paint: {
        'circle-color': '#fff',
        'circle-radius': 4,
        'circle-opacity': 1,
        'circle-stroke-color': '#072833',
        'circle-stroke-width': 2,
        'circle-stroke-opacity': 1
      },
      geometry: ['Point', 'MultiPoint'],
      layout: {
        visibility: 'visible'
      },
      maxzoom: 24,
      minzoom: 0
    }
  ],
  excludeSelectedFeatures: true
};

export default {
  componentId: 'tmpLayer',
  componentName: 'tmpLayer',
  componentType: 'GisLayer',
  options: TMP_LAYER_OPTIONS
};
