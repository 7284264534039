<template>
  <div class="monaco-editor-base-container" />
</template>

<script>
import { setLocaleData } from 'monaco-editor-nls';
import ru from 'monaco-editor-nls/locale/ru';

setLocaleData(ru);

export default {
  name: 'MonacoEditorBase',
  data() {
    return {
      editor: null
    };
  },
  props: {
    options: {
      type: Object,
      default() {
        return {
          minimap: {
            enabled: false
          },
          language: 'json',
          automaticLayout: true
        };
      }
    },
    value: {
      type: String,
      default: ''
    }
  },
  model: {
    prop: 'value',
    event: 'valueChanged'
  },
  watch: {
    options: {
      deep: true,
      handler(newOptions) {
        this.editor && this.editor.updateOptions(newOptions);
      }
    },
    value(newValue) {
      if (!this.editor) {
        return;
      }

      if (newValue !== this.editor.getValue()) {
        this.editor.setValue(newValue || '');
      }

      if (newValue && this.options.documentFormat) {
        this.format();
      }
    }
  },
  mounted() {
    this.initEditor(this.options);
    this.$emit('mounted', this.$el);
  },
  beforeDestroy() {
    this.editor && this.editor.dispose();
  },
  methods: {
    getValue() {
      return this.editor.getValue();
    },
    initEditor(options) {
      import('monaco-editor/esm/vs/editor/editor.api').then(({ editor }) => {
        this.editor = editor.create(this.$el, options);
        this.editor.getModel().updateOptions({ tabSize: 2 });

        if (this.value) {
          this.editor.setValue(this.value);

          if (this.options.documentFormat) {
            window.setTimeout(() => {
              this.format();
            }, 1000);
          }
        }

        this.editor.onDidBlurEditorText(() => {
          const newValue = this.editor.getValue();

          if (this.value !== newValue) {
            this.$emit('valueChanged', newValue);
          }
        });
      });
    },
    format() {
      this.editor.getAction('editor.action.formatDocument').run();
    }
  }
};
</script>

<style lang="scss" scoped>
.monaco-editor-base-container {
  height: 100%;
  position: relative;
}
</style>
