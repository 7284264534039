<template>
  <!--eslint-disable-->
  <div
    class="constructor-button"
    @mousedown="activate"
    @mouseup="deactivate"
    @mouseleave="deactivate"
    @click="$listeners.click"
    :class="{'constructor-button_active':active}"
  >
    <i :class="icon" />
  </div>
</template>

<script>
export default {
  name: 'ConstructorButton',
  data() {
    return {
      active: false
    };
  },
  props: {
    icon: String
  },
  methods: {
    activate() {
      this.active = true;
    },
    deactivate() {
      this.active = false;
    }
  }
};
</script>

<style lang="scss">
.constructor-button {
  color: #333;
  background-color: #fff;
  border: solid 1px #cdd5e5;
  height: 18px;
  width: 18px;
  line-height: 18px;
  cursor: pointer;
  text-align: center;
  font-size: 12px;
  transition: transform 0.1s;

  &:hover {
    background-color: #cdd5e5;
  }

  &_active {
    transform: scale(0.85);
  }
}
</style>
