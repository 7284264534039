export function findLineEquation(p1, p2) {
  let k = -(p1.y - p2.y) / (p2.x - p1.x);
  let b = -(p1.x * p2.y - p2.x * p1.y) / (p2.x - p1.x);
  return {
    k,
    b
  };
}

export function getUsedAttributes(layerOptions) {
  let attributes = [];
  //Атрибуты для стиля
  const style = layerOptions.mapboxLayers;
  style.forEach((styleItem) => {
    if (styleItem.type === 'chart' && styleItem.layout) {
      if (styleItem.filter) {
        getAttributes(styleItem.filter, attributes);
      }
      if (styleItem.layout['chart-attrs']) {
        styleItem.layout['chart-attrs'].forEach((attr) => {
          if (!attributes.includes(attr.name)) {
            attributes.push(attr.name);
          }
        });
      }
      if (styleItem.layout['chart-size-attribute'] && !attributes.includes(styleItem.layout['chart-size-attribute'])) {
        const attrs = parseAttributes(styleItem.layout['chart-size-attribute']);
        attributes = attributes.concat(attrs);
      }
    } else {
      getAttributes(styleItem, attributes);
    }
  });
  //Атрибуты для подсказок слоя
  if (layerOptions.tooltipPosition !== 'none' && layerOptions.tooltipExpression) {
    const attrs = parseAttributes(layerOptions.tooltipExpression);
    attributes = attributes.concat(attrs);
  }
  return attributes;
}

function getAttributes(item, attributes) {
  if (Array.isArray(item)) {
    if (item.length === 2 && item[0] === 'get' && !attributes.includes(item[1])) {
      attributes.push(item[1]);
      return;
    }
    item.forEach((piece) => getAttributes(piece, attributes));
    return;
  }
  if (typeof item === 'object' && item !== null) {
    Object.values(item).forEach((piece) => getAttributes(piece, attributes));
  }
}

const attributeTooltipRegex = new RegExp(/>\[(\w+)\]</g);
export function parseLayerTooltipAttributes(expression) {
  const ret = [];
  let match;
  while ((match = attributeTooltipRegex.exec(expression)) != null) {
    ret.push(match[1]);
  }
  return ret;
}

//выражение для парсинга выражения размера диаграммы
const attributeRegex = new RegExp(/\[(\w+)\]/g);
//Извлечение списка атрибутов из выражения
export function parseAttributes(expression) {
  const ret = [];
  let match;
  while ((match = attributeRegex.exec(expression)) != null) {
    ret.push(match[1]);
  }
  return ret;
}

export function generateId() {
  return Math.random().toString(36).substr(2, 9);
}
