<template>
  <!--eslint-disable-->
  <DxScrollView>
    <div class="component-form-content">
      <div class="dx-field">
        <div class="dx-field-label">Идентификатор</div>
        <div class="dx-field-value">
          <DxTextBox
            :value="componentId"
            mode="text"
            :readOnly="true"
          />
        </div>
      </div>
      <div class="dx-field">
        <div class="dx-field-label">Тип</div>
        <div class="dx-field-value">
          <DxLookup
            v-model="componentType"
            :dataSource="$store.getters['uiConstructor/componentTypes']"
            valueExpr="name"
            displayExpr="name"
            :searchExpr="['name']"
            :showClearButton="false"
            :showPopupTitle="false"
          >
          </DxLookup>
        </div>
      </div>
      <div class="dx-field">
        <div class="dx-field-label">Название</div>
        <div class="dx-field-value">
          <DxTextBox v-model="componentName" />
        </div>
      </div>
      <div
        class="component-form-content__group"
        v-if="['TextBlock', 'InfoBlock', 'Button', 'gisTimeline', 'ShortcutMenuItem'].includes(componentType)"
      >
        <div class="dx-field">
          <div class="dx-field-label">Текст</div>
          <div class="dx-field-value">
            <DxTextBox v-model="optionsText" />
          </div>
        </div>
        <div class="dx-field">
          <div class="dx-field-label">Название шрифта</div>
          <div class="dx-field-value">
            <DxLookup
              v-model="fontFamily"
              :dataSource="fontFamilies"
              valueExpr="value"
              displayExpr="value"
              :showClearButton="true"
              :showPopupTitle="false"
            >
            </DxLookup>
          </div>
        </div>
        <div class="dx-field">
          <div class="dx-field-label">Размер шрифта</div>
          <div class="dx-field-value">
            <DxLookup
              v-model="fontSize"
              :dataSource="fontSizes"
              valueExpr="value"
              displayExpr="value"
              :showClearButton="true"
              :showPopupTitle="false"
            >
            </DxLookup>
          </div>
        </div>
        <div class="dx-field">
          <div class="dx-field-label">Жирность шрифта</div>
          <div class="dx-field-value">
            <DxSelectBox
              v-model="fontWeight"
              :dataSource="fontWeights"
              valueExpr="value"
              displayExpr="value"
            >
            </DxSelectBox>
          </div>
        </div>
        <div class="dx-field">
          <div class="dx-field-label">Цвет текста</div>
          <div class="dx-field-value">
            <DxColorBox v-model="fontColor" />
          </div>
        </div>
      </div>

      <div
        v-if="['ImageBlock', 'ShortcutMenuItem'].includes(componentType)"
        class="dx-field"
      >
        <div class="dx-field-label">Изображение</div>
        <div class="component-form-content__url-field dx-field-value">
          <DxTextBox v-model="optionsSrc" />
          <DxButton
            icon="more"
            class="component-form-content__open-button"
            @click="onSelectSrc"
          />
        </div>
      </div>
      <div
        v-if="['ShortcutMenuItem'].includes(componentType)"
        class="dx-field"
      >
        <div class="dx-field-label">Иконка</div>
        <div class="component-form-content__url-field dx-field-value">
          <DxTextBox v-model="iconSrc" />
          <DxButton
            icon="more"
            class="component-form-content__open-button"
            @click="onSelectIcon"
          />
        </div>
      </div>
      <div
        v-if="['Button'].includes(componentType)"
        class="dx-field"
      >
        <div class="dx-field-label">Иконка</div>
        <div class="component-form-content__url-field dx-field-value">
          <DxTextBox v-model="iconSrc" />
          <DxButton
            icon="more"
            class="component-form-content__open-button"
            @click="onSelectIconSrc"
          />
        </div>
      </div>

      <!--Для дочерних элементов контейнеров с настройкой dragResize-->
      <template v-if="isParentDragResize">
        <div class="dx-field">
          <div class="dx-field-double">
            <div class="dx-field-label">X</div>
            <div class="dx-field-value">
              <DxNumberBox
                v-model="drLeft"
                :buttons="drLeftButtons"
              />
            </div>
          </div>
          <div class="component-form-content__column-space"></div>
          <div class="dx-field-double">
            <div class="dx-field-label">Y</div>
            <div class="dx-field-value">
              <DxNumberBox
                v-model="drTop"
                :buttons="drTopButtons"
              />
            </div>
          </div>
        </div>
        <div class="dx-field">
          <div class="dx-field-double">
            <div class="dx-field-label">ширина</div>
            <div class="dx-field-value">
              <DxNumberBox
                v-model="drWidth"
                @valueChanged="onDragSizeWidthChange"
              />
            </div>
          </div>
          <div
            class="component-form-content__fix-ratio"
            @click="onChangeAspectRatio"
          >
            <i :class="fixRatioClass" />
          </div>
          <div class="dx-field-double">
            <div class="dx-field-label">высота</div>
            <div class="dx-field-value">
              <DxNumberBox
                v-model="drHeight"
                @valueChanged="onDragSizeHeightChange"
              />
            </div>
          </div>
        </div>
        <div class="dx-field">
          <div class="dx-field-double">
            <div class="dx-field-label">мин ширина</div>
            <div class="dx-field-value">
              <DxNumberBox v-model="drMinw" />
            </div>
          </div>
          <div class="component-form-content__column-space"></div>
          <div class="dx-field-double">
            <div class="dx-field-label">мин высота</div>
            <div class="dx-field-value">
              <DxNumberBox v-model="drMinh" />
            </div>
          </div>
        </div>
        <div class="dx-field">
          <div class="dx-field-double">
            <div class="dx-field-value component-form-content__arrow">
              <i
                class="core core-114-arrow-top"
                @click="onZindex(1)"
              />
            </div>
          </div>
          <div class="component-form-content__column-space"></div>
          <div class="dx-field-double">
            <div class="dx-field-value component-form-content__arrow">
              <i
                class="core core-116-arrow-down"
                @click="onZindex(-1)"
              />
            </div>
          </div>
        </div>
        <div class="dx-field">
          <div class="dx-field-label">Перемещать</div>
          <div class="dx-field-value">
            <DxCheckBox v-model="isDrDraggable" />
          </div>
        </div>
        <div class="dx-field">
          <div class="dx-field-label">Изменять размер</div>
          <div class="dx-field-value">
            <DxCheckBox v-model="isDrResizable" />
          </div>
        </div>
        <div class="dx-field">
          <div class="dx-field-label">Ограничить родителем</div>
          <div class="dx-field-value">
            <DxCheckBox v-model="drParentLimitation" />
          </div>
        </div>
        <div class="dx-field">
          <div class="dx-field-label">Перемещать по сетке</div>
          <div class="dx-field-value">
            <DxCheckBox v-model="drSnapToGrid" />
          </div>
        </div>
      </template>

      <div class="dx-field">
        <div class="dx-field-label">Позиция</div>
        <div class="dx-field-value">
          <DxNumberBox v-model="componentPos" />
        </div>
      </div>
      <div
        class="dx-field"
        v-if="isVisual"
      >
        <div class="dx-field-label">Поле данных</div>
        <div class="dx-field-value">
          <DxTextBox v-model="dataField" />
        </div>
      </div>
      <div
        class="dx-field"
        v-if="isVisual"
      >
        <div class="dx-field-label">Сравнить с полем</div>
        <div class="dx-field-value">
          <DxTextBox v-model="dataFieldForCompare" />
        </div>
      </div>
      <div
        class="dx-field"
        v-if="isVisual"
      >
        <div class="dx-field-label">Поле фильтра</div>
        <div class="dx-field-value">
          <DxTextBox v-model="filterGroup" />
        </div>
      </div>
      <div
        v-if="showDataSource"
        class="dx-field"
      >
        <div class="dx-field-label">Источник данных</div>
        <div class="dx-field-value">
          <DxLookup
            v-model="dataSetComponentId"
            :dataSource="$store.getters['config/dataSetComponents']"
            valueExpr="componentId"
            displayExpr="displayExpr"
            :useItemTextAsTitle="true"
            :searchExpr="['options.dataSetName', 'options.text', 'displayExpr']"
            :showClearButton="true"
            :showPopupTitle="false"
          >
          </DxLookup>
        </div>
      </div>
      <div
        v-if="isVisual"
        class="dx-field"
      >
        <div class="dx-field-label">Видимость</div>
        <div class="dx-field-value">
          <DxCheckBox v-model="visible" />
        </div>
      </div>
      <div
        v-if="isVisual"
        class="dx-field"
      >
        <div class="dx-field-label">Спрятать на форме при создании</div>
        <div class="dx-field-value">
          <DxCheckBox v-model="hideWhenCreate" />
        </div>
      </div>

      <!--Для компонента Gantt можно выбрать временную шкалу -->
      <template v-if="componentType === ganttComponentType">
        <div
          class="dx-field"
          v-if="componentType === ganttComponentType"
        >
          <div class="dx-field-label">Временная шкала</div>
          <div class="dx-field-value">
            <DxSelectBox
              v-model="scaleType"
              valueExpr="code"
              displayExpr="name"
              :dataSource="scaleTypes"
              :show-clear-button="true"
            />
          </div>
        </div>
      </template>

      <!--Источник данных. Скрыт так как не удалось обратится к CONFIG.DSCONNECTIONCODE-->
      <template v-if="componentType === 'DataSet'">
        <div class="dx-field">
          <div class="dx-field-label">Данные</div>
          <div class="dx-field-value">
            <DxLookup
              :dataSource="$store.getters['uiConstructor/connections']"
              :value="getDatasetName(0)"
              valueExpr="code"
              displayExpr="code"
              :searchExpr="['code']"
              :showClearButton="true"
              :showPopupTitle="false"
              @valueChanged="onDatasetNameChanged($event, 0)"
            >
            </DxLookup>
          </div>
        </div>
        <div class="dx-field">
          <div class="dx-field-label"> </div>
          <div class="dx-field-value">
            <DxLookup
              :dataSource="$store.getters['uiConstructor/datasets']"
              :value="getDatasetName(1)"
              valueExpr="code"
              displayExpr="code"
              :searchExpr="['name', 'code']"
              :showClearButton="true"
              :showPopupTitle="false"
              @valueChanged="onDatasetNameChanged($event, 1)"
            >
            </DxLookup>
          </div>
        </div>
        <div class="dx-field">
          <div class="dx-field-label">Фильтр</div>
          <div class="dx-field-value">
            <DxLookup
              :dataSource="$store.getters['uiConstructor/filters']"
              v-model="filterId"
              valueExpr="id"
              displayExpr="name"
              :searchExpr="['name', 'code']"
              :showClearButton="true"
              :showPopupTitle="false"
            >
            </DxLookup>
          </div>
        </div>
      </template>

      <!--Для дочерних элементов Form, Group, TabPanelItem, но не dragResize -->
      <template v-if="!isParentDragResize && ['Form', 'Group', 'TabPanelItem'].includes(parentComponentType) && isVisual">
        <div class="dx-field">
          <div class="dx-field-label">Занимает колонок</div>
          <div class="dx-field-value">
            <DxNumberBox v-model="colSpan" />
          </div>
        </div>
        <div class="dx-field">
          <div class="dx-field-label">Показать подпись</div>
          <div class="dx-field-value">
            <DxCheckBox v-model="isLabelVisible" />
          </div>
        </div>
        <div class="dx-field">
          <div class="dx-field-label">Подпись</div>
          <div class="dx-field-value">
            <DxTextBox
              v-model="labelText"
              @change="changeLabelText"
            />
          </div>
        </div>
        <div class="dx-field">
          <div class="dx-field-label">Расположение подписи</div>
          <div class="dx-field-value">
            <DxSelectBox
              v-model="labelLocation"
              valueExpr="code"
              displayExpr="name"
              :dataSource="labelLocations"
            />
          </div>
        </div>
        <div class="dx-field">
          <div class="dx-field-label">Выравнивание подписи</div>
          <div class="dx-field-value">
            <DxSelectBox
              v-model="labelAlignment"
              valueExpr="code"
              displayExpr="name"
              :dataSource="labelAlignments"
            />
          </div>
        </div>
        <div class="dx-field">
          <div class="dx-field-label">Показать ":"</div>
          <div class="dx-field-value">
            <DxCheckBox
              v-model="labelShowColon"
              :value="true"
            />
          </div>
        </div>
      </template>

      <!--Для дочерних элементов Toolbar и кнопок внутри DataGrid -->
      <template v-if="['Toolbar', 'DataGrid', 'clDate', 'clText', 'clGroup', 'clNumber', 'clBoolean'].includes(parentComponentType) && isVisual">
        <div class="dx-field">
          <div class="dx-field-label">Расположение</div>
          <div class="dx-field-value">
            <DxSelectBox
              v-model="location"
              valueExpr="code"
              displayExpr="name"
              :dataSource="toolbarLocationItems"
            />
          </div>
        </div>
        <div class="dx-field">
          <div class="dx-field-label">Расположить в меню</div>
          <div class="dx-field-value">
            <DxSelectBox :dataSource="toolbarLocateInMenuItems" />
          </div>
        </div>
      </template>

      <!--Для дочерних элементов полей редактирования, если это кнопки -->
      <template v-if="['Autocomplete', 'ColorBox', 'DateBox', 'NumberBox', 'SelectBox', 'TagBox', 'TextBox'].includes(parentComponentType) && componentConfig.componentType === 'Button'">
        <div class="dx-field">
          <div class="dx-field-label">Расположение</div>
          <div class="dx-field-value">
            <DxSelectBox
              v-model="location"
              valueExpr="code"
              displayExpr="name"
              :dataSource="editorButtonLocationItems"
            />
          </div>
        </div>
      </template>

      <!--Для дочерних элементов Accordion, TabPanel, CardTabs-->
      <template v-if="['Accordion', 'TabPanel', 'CardTabs'].includes(parentComponentType) && isVisual">
        <div>
          <div class="dx-field">
            <div class="dx-field-label">Заголовок</div>
            <div class="dx-field-value">
              <DxTextBox v-model="title" />
            </div>
          </div>
          <div class="dx-field">
            <div class="dx-field-label">Отключить вкладку</div>
            <div class="dx-field-value">
              <DxCheckBox v-model="disabled" />
            </div>
          </div>
        </div>
      </template>

      <!--Для дочерних элементов GridLayout-->
      <template v-if="['GridLayout'].includes(parentComponentType) && isVisual">
        <div class="dx-field">
          <div class="dx-field-label">Колонка</div>
          <div class="dx-field-value">
            <DxNumberBox v-model="col" />
          </div>
        </div>
        <div class="dx-field">
          <div class="dx-field-label">Строка</div>
          <div class="dx-field-value">
            <DxNumberBox v-model="row" />
          </div>
        </div>
        <div class="dx-field">
          <div class="dx-field-label">Занимает колонок</div>
          <div class="dx-field-value">
            <DxNumberBox v-model="colSpan" />
          </div>
        </div>
        <div class="dx-field">
          <div class="dx-field-label">Занимает строк</div>
          <div class="dx-field-value">
            <DxNumberBox v-model="rowSpan" />
          </div>
        </div>
        <div class="dx-field">
          <div class="dx-field-label">Минимум колонок</div>
          <div class="dx-field-value">
            <DxNumberBox v-model="minColSpan" />
          </div>
        </div>
        <div class="dx-field">
          <div class="dx-field-label">Минимум строк</div>
          <div class="dx-field-value">
            <DxNumberBox v-model="minRowSpan" />
          </div>
        </div>
        <div class="dx-field">
          <div class="dx-field-label">Максимум колонок</div>
          <div class="dx-field-value">
            <DxNumberBox v-model="maxColSpan" />
          </div>
        </div>
        <div class="dx-field">
          <div class="dx-field-label">Максимум строк</div>
          <div class="dx-field-value">
            <DxNumberBox v-model="maxRowSpan" />
          </div>
        </div>
        <div class="dx-field">
          <div class="dx-field-label">Не перемещать</div>
          <div class="dx-field-value">
            <DxCheckBox v-model="isNotDraggable" />
          </div>
        </div>
        <div class="dx-field">
          <div class="dx-field-label">Не изменять размер</div>
          <div class="dx-field-value">
            <DxCheckBox v-model="isNotResizable" />
          </div>
        </div>
        <div class="dx-field">
          <div class="dx-field-label">Показать подпись</div>
          <div class="dx-field-value">
            <DxCheckBox v-model="isLabelVisible" />
          </div>
        </div>
        <div class="dx-field">
          <div class="dx-field-label">Подпись</div>
          <div class="dx-field-value">
            <DxTextBox
              v-model="labelText"
              @change="changeLabelText"
            />
          </div>
        </div>
        <div class="dx-field">
          <div class="dx-field-label">Расположение подписи</div>
          <div class="dx-field-value">
            <DxSelectBox
              v-model="labelLocation"
              valueExpr="code"
              displayExpr="name"
              :dataSource="labelLocations"
            />
          </div>
        </div>
        <div class="dx-field">
          <div class="dx-field-label">Выравнивание подписи</div>
          <div class="dx-field-value">
            <DxSelectBox
              v-model="labelAlignment"
              valueExpr="code"
              displayExpr="name"
              :dataSource="labelAlignments"
            />
          </div>
        </div>
        <div class="dx-field">
          <div class="dx-field-label">Показать ":"</div>
          <div class="dx-field-value">
            <DxCheckBox
              v-model="labelShowColon"
              :value="true"
            />
          </div>
        </div>
        <div class="dx-field">
          <div class="dx-field-label">Расположение ":"</div>
          <div class="dx-field-value">
            <DxSelectBox
              v-model="labelColonLocation"
              valueExpr="code"
              displayExpr="name"
              :dataSource="colonLocations"
            />
          </div>
        </div>
      </template>

      <!--Для дочерних элементов Popup-->
      <template v-if="['Popup', 'Popover'].includes(parentComponentType) && isVisual">
        <div class="dx-field">
          <div class="dx-field-label">Расположить в</div>
          <div class="dx-field-value">
            <DxSelectBox
              v-model="toolbar"
              :dataSource="popupToolbarItems"
              valueExpr="code"
              displayExpr="name"
              :showClearButton="true"
            />
          </div>
        </div>
        <div class="dx-field">
          <div class="dx-field-label">Расположение</div>
          <div class="dx-field-value">
            <DxSelectBox
              v-model="location"
              valueExpr="code"
              displayExpr="name"
              :dataSource="toolbarLocationItems"
              :showClearButton="true"
            />
          </div>
        </div>
      </template>

      <!--Для дочерних элементов MapboxLayer-->
      <template v-if="['MapboxLayer', 'GisLayer'].includes(parentComponentType)">
        <div class="dx-field">
          <div class="dx-field-label">Роль</div>
          <div class="dx-field-value">
            <DxSelectBox
              v-model="layerComponentRole"
              valueExpr="code"
              displayExpr="name"
              :dataSource="mapboxLayerComponentRoles"
              :show-clear-button="true"
            />
          </div>
        </div>
      </template>

      <!--Для дочерних элементов Gis-->
      <template v-if="['Gis'].includes(parentComponentType)">
        <div class="dx-field">
          <div class="dx-field-label">Панель</div>
          <div class="dx-field-value">
            <DxSelectBox
              v-model="panel"
              valueExpr="code"
              displayExpr="name"
              :dataSource="gisPanelLocations"
              :show-clear-button="true"
            />
          </div>
        </div>
      </template>

      <template>
        <div
          v-if="this.$route.params.componentId !== componentId"
          class="dx-field"
        >
          <div class="dx-field-label">Родитель {{ parentComponentId }}</div>
          <div class="dx-field-value">
            <DropDownComponentsBox
              :value="parentComponentId"
              :dataSource="dropDownComponents"
              @itemSelectionChanged="parentSelectionChanged"
              @valueChanged="parentComponentChanged"
            />
          </div>
        </div>
      </template>

    </div>
    <!--Попап выбора изображения-->
    <DxPopup
      ref="popupSelectImage"
      v-if="showSelectImage"
      :animation="null"
      :minWidth="300"
      maxHeight="80%"
      :visible="true"
      width="80%"
      height="80%"
      container="#app"
      @initialized="onPopupSelectImageInit"
    >
      <template #titleTemplate>
        <PopupTitleTemplate
          text="Выбор изображения"
          :showCloseButton="true"
          @closeClick="hideSelectImage"
        />
      </template>
      <template #content>
        <div class="component-form-content__popup-content">
          <ConstructorElement
            :componentId="$options.SELECT_IMAGE_COMPONENT_ID"
            componentType="HorizontalLayout"
          />
        </div>
      </template>
      <DxToolbarItem
        widget="dxButton"
        toolbar="bottom"
        location="center"
        :options="selectButtonOptions"
        :disabled="false"
      ></DxToolbarItem>
    </DxPopup>
    <!--Попап выбора иконки-->
    <DxPopup
      ref="popupSelectIcon"
      v-if="showSelectIcon"
      :animation="null"
      :minWidth="300"
      maxHeight="80%"
      :visible="true"
      width="80%"
      height="80%"
      container="#app"
      @initialized="onPopupSelectIconInit"
    >
      <template #titleTemplate>
        <PopupTitleTemplate
          text="Выбор иконки"
          :showCloseButton="true"
          @closeClick="hideSelectImage"
        />
      </template>
      <template #content>
        <div class="component-form-content__popup-content">
          <ConstructorElement
            :componentId="$options.SELECT_ICON_COMPONENT_ID"
            componentType="VerticalLayout"
          />
        </div>
      </template>
      <DxToolbarItem
        widget="dxButton"
        toolbar="bottom"
        location="center"
        :options="selectIconOptions"
        :disabled="false"
      ></DxToolbarItem>
    </DxPopup>
  </DxScrollView>
</template>

<script>
import { mapMutations } from 'vuex';
import DxCheckBox from 'devextreme-vue/check-box';
import DxLookup from 'devextreme-vue/lookup';
import DxButton from 'devextreme-vue/button';
import { DxPopup, DxToolbarItem } from 'devextreme-vue/popup';
import DxNumberBox from 'devextreme-vue/number-box';
import DxSelectBox from 'devextreme-vue/select-box';
import DxScrollView from 'devextreme-vue/scroll-view';
import DxTextBox from 'devextreme-vue/text-box';
import PopupTitleTemplate from '../Containers/PopupTitleTemplate';
import ConstructorElement from '../Containers/ConstructorElement';
import DropDownComponentsBox from './DropDownComponentsBox';
import { getAvailableFonts } from '../../utils/fontDetector';
import { INVISIBLE_COMPONENTS, DRAG_RESIZE_CONTAINERS } from '../../utils/const';
import { SET_COMPONENT_OPTIONS } from '@/store/modules/config/mutations';
import { SET_OPTION_BY_PATH } from '@/store/modules/config/components/mutations';

const FIELDS = [
  'componentId',
  'parentComponentId',
  'componentName',
  'componentType',
  'componentPos',
  {
    name: 'optionsText',
    path: 'options.text'
  },
  {
    name: 'optionsSrc',
    path: 'options.src'
  },
  {
    name: 'optionsDataSetName',
    path: 'options.dataSetName'
  },
  {
    name: 'filterId',
    path: 'options.filterId'
  },
  {
    name: 'iconSrc',
    path: 'options.icon'
  },
  {
    name: 'fontFamily',
    path: 'options.cssStyle.fontFamily'
  },
  {
    name: 'fontSize',
    path: 'options.cssStyle.fontSize'
  },
  {
    name: 'fontWeight',
    path: 'options.cssStyle.fontWeight'
  },
  {
    name: 'fontColor',
    path: 'options.cssStyle.color'
  },
  'drLeft',
  'drTop',
  'drWidth',
  'drHeight',
  'drMinw',
  'drMinh',
  'drDraggable',
  'drResizable',
  'drParentLimitation',
  'drSnapToGrid',
  'drZindex',
  'colSpan',
  'dataField',
  'dataFieldForCompare',
  'filterGroup',
  'dataSetComponentId',
  'hideWhenCreate',
  {
    name: 'labelVisible',
    path: 'label.visible'
  },
  {
    name: 'labelText',
    path: 'label.text'
  },
  {
    name: 'labelLocation',
    path: 'label.location'
  },
  {
    name: 'labelAlignment',
    path: 'label.alignment'
  },
  {
    name: 'labelShowColon',
    path: 'label.showColon'
  },
  {
    name: 'labelColonLocation',
    path: 'label.colonLocation'
  },
  'location',
  'locateInMenu',
  'title',
  'disabled',
  'col',
  'row',
  'rowSpan',
  'isNotDraggable',
  'isNotResizable',
  'maxRowSpan',
  'maxColSpan',
  'minRowSpan',
  'minColSpan',
  'style',
  'scaleType',
  'layerComponentRole',
  'toolbar',
  'panel'
];
const mapFields = (fields) => {
  let result = {};
  fields.forEach((field) => {
    let fieldName;
    let fieldPath;
    if (typeof field === 'string') {
      fieldName = field;
      fieldPath = field;
    } else {
      fieldName = field.name;
      fieldPath = field.path;
    }
    result[fieldName] = {
      get() {
        return this.getComponentField(fieldPath);
      },
      set(value) {
        this.setConstructorItemField(fieldPath, value);
      }
    };
  });
  return result;
};

const LOCK_BUTTON_ICONS = ['core core-077-unlock', 'core core-076-lock'];

const LOCK_BUTTON_HINTS = {
  left: ['Закрепить на оси Х', 'Открепить от оси Х'],
  top: ['Закрепить на оси Y', 'Открепить от оси Y'],
  size: ['Закрепить пропорциональный размер', 'Убрать пропорциональный размер']
};

const SELECT_IMAGE_COMPONENT_ID = '131662';
const SELECT_ICON_COMPONENT_ID = '105043';
const SELECT_IMAGE_GRID_COMPONENT_ID = '131667';
const SELECT_ICON_TREE_COMPONENT_ID = '105044';

export default {
  components: {
    DxCheckBox,
    DxLookup,
    DxButton,
    DxPopup,
    DxToolbarItem,
    DxNumberBox,
    DxSelectBox,
    DxScrollView,
    DxTextBox,
    DropDownComponentsBox,
    PopupTitleTemplate,
    ConstructorElement
  },
  SELECT_IMAGE_COMPONENT_ID,
  SELECT_ICON_COMPONENT_ID,
  data() {
    return {
      formData: null,
      fixAspectRatio: false,
      aspectRatio: 0,
      //Поле, для заполнения которого открыто окно выбора изображений
      currentImageField: null,
      activeComponentIdBackup: null,
      containerSpecificFields: [],
      monacoEditorOptions: {
        automaticLayout: true,
        language: 'json',
        minimap: {
          enabled: false
        }
      },
      toolbarLocationItems: [
        { code: 'before', name: 'слева' },
        { code: 'center', name: 'по центру' },
        { code: 'after', name: 'справа' }
      ],
      editorButtonLocationItems: [
        { code: 'before', name: 'слева' },
        { code: 'after', name: 'справа' }
      ],
      toolbarLocateInMenuItems: ['always', 'auto', 'never'],
      labelLocations: [
        { code: 'left', name: 'слева' },
        { code: 'right', name: 'справа' },
        { code: 'top', name: 'сверху' },
        { code: 'bottom', name: 'снизу' }
      ],
      labelAlignments: [
        { code: 'left', name: 'слева' },
        { code: 'center', name: 'по центру' },
        { code: 'right', name: 'справа' }
      ],
      colonLocations: [
        { code: 'left', name: 'слева' },
        { code: 'right', name: 'справа' }
      ],
      popupToolbarItems: [
        { code: 'top', name: 'Верхняя панель' },
        { code: 'bottom', name: 'Нижняя панель' }
      ],
      scaleTypes: [
        { code: 'minutes', name: 'минуты' },
        { code: 'hours', name: 'часы' },
        { code: 'days', name: 'дни' },
        { code: 'weeks', name: 'недели' },
        { code: 'months', name: 'месяцы' },
        { code: 'quarters', name: 'кварталы' },
        { code: 'years', name: 'годы' }
      ],
      ganttComponentType: 'Gantt',
      vectorMapComponentType: 'VectorMap',
      mapboxLayerComponentRoles: [
        { code: 'filter', name: 'фильтр' },
        { code: 'table', name: 'таблица' },
        { code: 'card', name: 'карточка' },
        { code: 'stats', name: 'статистика' },
        { code: 'chart', name: 'картограмма' },
        { code: 'tooltip', name: 'подсказка' }
      ],
      mapboxLegendComponentType: 'MapboxLegend',
      mapBoxGroupLayerComponentType: 'MapboxGroupLayer',
      mapBoxComponentType: 'Mapbox',
      gisPanelLocations: [
        { code: 'topLeft', name: 'сверху слева' },
        { code: 'topRight', name: 'сверху справа' },
        { code: 'filter', name: 'панель фильтрации' },
        { code: 'toolbar', name: 'панель инструментов' },
        { code: 'overlay', name: 'компоненты на карте' },
        { code: 'hint', name: 'обучающие подсказки' }
      ],
      dxLeftButtonComponent: null,
      dxTopButtonComponent: null,
      drLeftButtons: [
        {
          location: 'after',
          name: 'drLeftButton',
          options: {
            icon: LOCK_BUTTON_ICONS[0],
            stylingMode: 'text',
            hint: LOCK_BUTTON_HINTS.left[0],
            onInitialized: (eventData) => {
              this.dxLeftButtonComponent = eventData.component;
            },
            onClick: (eventData) => {
              this.toggleLockButton(eventData, 'left');
            }
          }
        }
      ],
      drTopButtons: [
        {
          location: 'after',
          name: 'drLeftButton',
          options: {
            icon: LOCK_BUTTON_ICONS[0],
            stylingMode: 'text',
            hint: LOCK_BUTTON_HINTS.top[0],
            onInitialized: (eventData) => {
              window.console.log();
              this.dxTopButtonComponent = eventData.component;
            },
            onClick: (eventData) => {
              this.toggleLockButton(eventData, 'top');
            }
          }
        }
      ],
      /**Кнопка выбора изображения в попапе */
      selectButtonOptions: {
        icon: 'core core-016-save',
        type: 'default',
        text: 'Выбрать',
        onClick: () => {
          this.hideSelectImage();
          //Установка урл изображения
          const selectedData = this.$store.getters['config/getItemField'](SELECT_IMAGE_GRID_COMPONENT_ID, 'selectedData');
          const url = selectedData.url + '&_config_dataset=PAGES.DSPAGE';
          //Восстановить активный компонент
          this.$store
            .dispatch('config/setConstructorActiveComponent', {
              componentId: this.activeComponentIdBackup,
              keepSelected: false,
              notUnselect: false
            })
            .then(() => {
              //Установить изображение для активного компонента
              this.setConstructorItemField(this.currentImageField, url);
            });
        }
      },
      selectIconOptions: {
        icon: 'core core-016-save',
        type: 'default',
        text: 'Выбрать',
        onClick: () => {
          this.hideSelectImage();
          //Установка урл изображения
          const selectedIconRecord = this.$store.getters['config/getItemField'](SELECT_ICON_TREE_COMPONENT_ID, 'selectedData');
          //Восстановить активный компонент
          this.$store
            .dispatch('config/setConstructorActiveComponent', {
              componentId: this.activeComponentIdBackup,
              keepSelected: false,
              notUnselect: false
            })
            .then(() => {
              //Установить изображение для активного компонента
              this.setConstructorItemField(this.currentImageField, selectedIconRecord[0].icon);
            });
        }
      },
      fontFamilies: [],
      fontWeights: [{ value: 'normal' }, { value: 'bold' }],
      /**Размеры шрифта */
      fontSizes: [
        { value: '5px' },
        { value: '6px' },
        { value: '7px' },
        { value: '8px' },
        { value: '9px' },
        { value: '10px' },
        { value: '11px' },
        { value: '12px' },
        { value: '14px' },
        { value: '16px' },
        { value: '18px' },
        { value: '20px' },
        { value: '24px' },
        { value: '28px' },
        { value: '32px' },
        { value: '36px' },
        { value: '40px' },
        { value: '46px' },
        { value: '52px' },
        { value: '58px' },
        { value: '64px' },
        { value: '72px' }
      ]
    };
  },
  computed: {
    showDataSource() {
      return !['DataSet'].includes(this.componentType);
    },
    isVisual() {
      return !INVISIBLE_COMPONENTS.includes(this.componentType);
    },
    fixRatioClass() {
      return { core: true, 'core-076-lock': this.fixAspectRatio, 'core-077-unlock': !this.fixAspectRatio };
    },
    componentConfig() {
      return this.$store.getters['config/getConstructorActiveComponentConfig'] || {};
    },
    parentComponentType() {
      return this.$store.getters['config/getComponentType'](this.componentConfig.parentComponentId) || '';
    },
    isParentDragResize() {
      if (!DRAG_RESIZE_CONTAINERS.includes(this.parentComponentType) || INVISIBLE_COMPONENTS.includes(this.componentType)) {
        return false;
      }

      const { dragResize } = this.$store.getters['config/getComponentOptions'](this.parentComponentId) || {};
      return !!dragResize;
    },
    isDrDraggable: {
      get() {
        let drDraggable = this.getComponentField('drDraggable');
        return drDraggable !== false;
      },
      set(value) {
        this.setConstructorItemField('drDraggable', value);
      }
    },
    isDrResizable: {
      get() {
        let drResizable = this.getComponentField('drResizable');
        return drResizable !== false;
      },
      set(value) {
        this.setConstructorItemField('drResizable', value);
      }
    },
    isLabelVisible: {
      get() {
        let visible = this.getComponentField('label.visible');
        return visible === null && this.labelText ? true : visible;
      },
      set(value) {
        this.setConstructorItemField('label.visible', value);
      }
    },
    visible: {
      get() {
        //если false не задан явно то true, поэтому строгое сравнение
        return this.getComponentField('visible') !== false;
      },
      set(value) {
        //если false не задан явно то true, поэтому строгое сравнение
        this.setConstructorItemField('visible', value);
      }
    },

    dropDownComponents() {
      return this.$store.getters['config/getComponentsList'].filter((item) => {
        return item.componentId !== this.componentId;
      });
    },

    showSelectImage() {
      return this.$store.getters['uiConstructor/getOption']('showSelectImage');
    },

    showSelectIcon() {
      return this.$store.getters['uiConstructor/getOption']('showSelectIcon');
    },

    ...mapFields(FIELDS)
  },
  watch: {
    componentId() {
      if (!this.isParentDragResize) {
        this.dxLeftButtonComponent = null;
        this.dxTopButtonComponent = null;
        return;
      }

      const axis = this.getComponentField('drAxis') || 'both';

      let interval = setInterval(() => {
        if (this.dxLeftButtonComponent && this.dxTopButtonComponent) {
          clearInterval(interval);
          this.setAxisButtonOptions(axis);
        }
      }, 500);
    }
  },

  mounted() {
    this.fontFamilies = getAvailableFonts();
  },

  methods: {
    ...mapMutations('config', {
      /**Установка текущих настроек компонента, чтобы они применились к компоненту */
      setComponentOptions: SET_COMPONENT_OPTIONS,
      setComponentOptionsByPath: SET_OPTION_BY_PATH
    }),

    onZindex(direction) {
      this.drZindex = direction > 0 ? 999 : 1;
    },

    onPopupSelectImageInit() {
      //Загрузка содержимого попапа из страницы выбора изображения /reestr-image
      this.$store
        .dispatch('config/loadComponent', {
          componentId: SELECT_IMAGE_COMPONENT_ID
        })
        .then(() => {
          //После загрузки содержимого Popup надо добавить id компонента в список догруженных,
          //чтобы исключить из из дерева компонентов
          this.$store.commit('config/ADD_COMPONENT_TO_LOADED_ITEMS', {
            componentId: SELECT_IMAGE_COMPONENT_ID
          });
        });
    },

    onPopupSelectIconInit() {
      //Загрузка содержимого попапа из страницы выбора изображения /reestr-image
      this.$store
        .dispatch('config/loadComponent', {
          componentId: SELECT_ICON_COMPONENT_ID
        })
        .then(() => {
          //После загрузки содержимого Popup надо добавить id компонента в список догруженных,
          //чтобы исключить из из дерева компонентов
          this.$store.commit('config/ADD_COMPONENT_TO_LOADED_ITEMS', {
            componentId: SELECT_ICON_COMPONENT_ID
          });
        });
    },

    onChangeAspectRatio() {
      this.fixAspectRatio = !this.fixAspectRatio;
      this.aspectRatio = this.drHeight / this.drWidth;
    },
    onDragSizeWidthChange(event) {
      if (event.event && this.fixAspectRatio) {
        this.drHeight = Math.round(this.aspectRatio * this.drWidth);
      }
    },
    onDragSizeHeightChange(event) {
      if (event.event && this.fixAspectRatio) {
        this.drWidth = Math.round(this.drHeight / this.aspectRatio);
      }
    },
    parentSelectionChanged({ itemData }) {
      const actionPayload = {
        componentId: this.componentId,
        oldParentId: this.componentConfig.parentComponentId,
        newParentId: itemData.componentId
      };

      const url = this.$route.params.childUrl;
      if (!actionPayload.oldParentId) {
        actionPayload.oldUrl = url;
      }

      if (!actionPayload.newParentId) {
        actionPayload.newUrl = url;

        //Если компонент перемещаем в корень, то надо установить устройство
        actionPayload.device = this.$store.getters['uiConstructor/resolutionDevice'];
      }

      this.$store.dispatch('config/setComponentParent', actionPayload).then(() => {
        this.parentComponentId = actionPayload.newParentId;
      });
    },
    parentComponentChanged(eventData) {
      let actionPayload = {
        componentId: this.componentId,
        oldParentId: this.componentConfig.parentComponentId
      };

      const value = eventData.value ? eventData.value.toString() : null;
      const previousValue = eventData.previousValue ? eventData.previousValue.toString() : null;
      if (value !== previousValue) {
        actionPayload.newParentId = value;
      } else {
        return;
      }

      const url = this.$route.params.childUrl;
      if (!actionPayload.oldParentId) {
        actionPayload.oldUrl = url;
      }
      if (!actionPayload.newParentId) {
        actionPayload.newUrl = url;

        //Если компонент перемещаем в корень, то надо установить устройство
        actionPayload.device = this.$store.getters['uiConstructor/resolutionDevice'];
      }

      if ((actionPayload.oldParentId || actionPayload.newParentId) && actionPayload.oldParentId !== actionPayload.newParentId) {
        this.$store.dispatch('config/setComponentParent', actionPayload).then(() => {
          this.parentComponentId = actionPayload.newParentId;
        });
      }
    },

    getDatasetName(partIndex) {
      const parts = this.optionsDataSetName?.split('.');
      return parts ? parts[partIndex] : null;
    },

    onDatasetNameChanged(event, partIndex) {
      const parts = this.optionsDataSetName.split('.');
      parts[partIndex] = event.value;
      this.optionsDataSetName = parts.join('.');
    },

    getComponentField(fieldPath) {
      return this.$store.getters['config/getConstructorActiveComponentField'](fieldPath);
    },

    setConstructorItemField(fieldName, fieldValue) {
      this.$store.dispatch('config/setConstructorItemFields', {
        componentId: this.componentId,
        fields: [
          {
            name: fieldName,
            value: fieldValue
          }
        ]
      });

      //Применение настроек опций к компоненту конструктора для отображения
      if (fieldName.startsWith('options.')) {
        const fieldPath = fieldName.split('.');
        const options = this.getComponentField('options');
        this.setComponentOptions({
          componentId: this.componentId,
          options
        });
        if (fieldName.startsWith('options.cssStyle.')) {
          //Применение стилей
          this.setComponentOptionsByPath({
            componentId: this.componentId,
            optionName: 'cssStyle',
            optionValue: options.cssStyle
          });
        } else if (fieldPath.length == 2) {
          this.setComponentOptionsByPath({
            componentId: this.componentId,
            optionName: fieldPath[1],
            optionValue: fieldValue
          });
        }
      }

      this.$store
        .dispatch('config/setConstructorActiveComponentFields', {
          fields: [
            {
              name: fieldName,
              value: fieldValue
            }
          ]
        })
        .then(() => {
          const url = this.$route.params.childUrl;
          return this.$store.dispatch('config/saveConstructorActiveComponent', { url });
        });
    },

    setData(data) {
      this.formData = data;
    },
    connenctionCodeChanged(eventData) {
      this.$store.dispatch('uiConstructor/loadDatasets', eventData.value);
    },

    changeLabelText() {
      if (this.labelText) {
        this.setConstructorItemField('label.visible', true);
        if (!this.labelLocation) {
          this.labelLocation = 'left';
        }
        if (!this.labelAlignment) {
          this.labelAlignment = 'right';
        }
      } else {
        this.setConstructorItemField('label.visible', false);
      }
    },
    dataSetItemText(item) {
      window.console.log(item);
      return '';
    },
    toggleLockButton(eventData, button) {
      const icon = eventData.component.option('icon');
      eventData.component.option('icon', icon === LOCK_BUTTON_ICONS[0] ? LOCK_BUTTON_ICONS[1] : LOCK_BUTTON_ICONS[0]);
      eventData.component.option('hint', icon === LOCK_BUTTON_ICONS[0] ? LOCK_BUTTON_HINTS[button][1] : LOCK_BUTTON_HINTS[button][0]);

      if (button === 'size') {
        window.console.log('eventData:', eventData.component.option('icon'));
        window.console.log('button:', button);
      } else {
        let value = '';
        const axis = this.getComponentField('drAxis') || 'both';
        switch (axis) {
          case 'both':
            value = button === 'left' ? 'y' : 'x';
            break;
          case 'none':
            value = button === 'left' ? 'x' : 'y';
            break;
          case 'x':
            value = button === 'left' ? 'none' : 'both';
            break;
          case 'y':
            value = button === 'left' ? 'both' : 'none';
            break;
        }

        this.setConstructorItemField('drAxis', value);
      }
    },
    setAxisButtonOptions(axis) {
      switch (axis) {
        case 'both':
          this.dxLeftButtonComponent.option('icon', LOCK_BUTTON_ICONS[0]);
          this.dxTopButtonComponent.option('icon', LOCK_BUTTON_ICONS[0]);
          this.dxLeftButtonComponent.option('hint', LOCK_BUTTON_HINTS.left[0]);
          this.dxTopButtonComponent.option('hint', LOCK_BUTTON_HINTS.top[0]);
          break;
        case 'none':
          this.dxLeftButtonComponent.option('icon', LOCK_BUTTON_ICONS[1]);
          this.dxTopButtonComponent.option('icon', LOCK_BUTTON_ICONS[1]);
          this.dxLeftButtonComponent.option('hint', LOCK_BUTTON_HINTS.left[1]);
          this.dxTopButtonComponent.option('hint', LOCK_BUTTON_HINTS.top[1]);
          break;
        case 'x':
          this.dxLeftButtonComponent.option('icon', LOCK_BUTTON_ICONS[0]);
          this.dxTopButtonComponent.option('icon', LOCK_BUTTON_ICONS[1]);
          this.dxLeftButtonComponent.option('hint', LOCK_BUTTON_HINTS.left[0]);
          this.dxTopButtonComponent.option('hint', LOCK_BUTTON_HINTS.top[1]);
          break;
        case 'y':
          this.dxLeftButtonComponent.option('icon', LOCK_BUTTON_ICONS[1]);
          this.dxTopButtonComponent.option('icon', LOCK_BUTTON_ICONS[0]);
          this.dxLeftButtonComponent.option('hint', LOCK_BUTTON_HINTS.left[1]);
          this.dxTopButtonComponent.option('hint', LOCK_BUTTON_HINTS.top[0]);
          break;
      }
    },

    onSelectIcon() {
      this.$store.commit('uiConstructor/setSelectIconVisible', { visible: true });
      this.currentImageField = 'options.icon';
      this.activeComponentIdBackup = this.componentId;
    },

    onSelectIconSrc() {
      this.$store.commit('uiConstructor/setSelectImageVisible', { visible: true });
      this.currentImageField = 'options.icon';
      this.activeComponentIdBackup = this.componentId;
    },

    onSelectSrc() {
      this.$store.commit('uiConstructor/setSelectImageVisible', { visible: true });
      this.currentImageField = 'options.src';
      this.activeComponentIdBackup = this.componentId;
    },

    hideSelectImage() {
      this.$store.commit('uiConstructor/setSelectImageVisible', { visible: false });
      this.$store.commit('uiConstructor/setSelectIconVisible', { visible: false });
    }
  }
};
</script>

<style lang="scss">
.component-form-content {
  padding: 5px;
  display: flex;
  flex-direction: column;
  background-color: #fff;

  &__group {
    margin-bottom: 5px;
  }

  &__url-field {
    position: relative;

    .dx-textbox {
      padding-right: 30px;
    }
  }

  &__popup-content {
    height: 100%;
  }

  &__open-button {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
  }

  &__arrow {
    font-size: 30px;
    text-align: center;
    i {
      cursor: pointer;
    }
  }

  &__column-space {
    width: 20px;
  }

  &__fix-ratio {
    cursor: pointer;
    width: 20px;
    align-self: end;
    margin-bottom: 6px;
    text-align: center;
  }

  .dx-field {
    display: flex;
    flex: 0 0 auto;

    & > .dx-field-label {
      float: none;
      width: auto;
      flex: 0 0 138px;
      text-align: left;
    }
    & > .dx-field-value {
      float: none;
      width: auto;
      flex: 1 1 auto;

      & > .dx-checkbox {
        float: none;
      }
    }

    & > .dx-field-double {
      display: flex;
      flex-direction: column;
      flex: 1;

      & > .dx-field-label {
        padding-left: 5px;
        width: 100%;
      }

      & > .dx-field-value {
        width: 100% !important;
      }
    }
  }

  & > .dx-field-title {
    padding-bottom: 5px;
  }
}
</style>
