<template>
  <!--eslint-disable-->
  <div class="mobile-build-grid">
    <div class="mobile-build-grid__caption dx-accordion-item-title">Мобильные сборки</div>
    <div class="dx-field">
      <div class="dx-field-label">Портал</div>
      <div class="dx-field-value">
        <DxLookup
          v-model="portalId"
          :dataSource="portalsDataSource"
          valueExpr="id"
          displayExpr="name"
          :searchExpr="['name']"
          :showClearButton="false"
          :showPopupTitle="false"
          @valueChanged="refreshGrid"
        >
        </DxLookup>
      </div>
    </div>
    <div class="mobile-build-grid__accordion">
      <DxAccordion
        height="100%"
        width="100%"
        :collapsible="true"
        :items="accordionItems"
        :focusStateEnabled="false"
        :deferRendering="false"
      >
        <template #buildAndroid>
          <DxDataGrid
            class="data-grid"
            height="100%"
            width="100%"
            keyExpr="id"
            :showRowLines="false"
            :showColumnLines="false"
            :dataSource="androidBuildsDataSource"
            :allowColumnResizing="false"
            :columnAutoWidth="true"
            :rowAlternationEnabled="true"
            :hoverStateEnabled="true"
            @selectionChanged="selectedItem = $event.selectedRowsData[0]"
            @rowDblClick="onRowDblClick"
          >
            <DxToolbar>
              <DxToolbarItem
                :options="refreshButtonOptions"
                location="before"
                widget="dxButton"
              />
              <DxToolbarItem
                :disabled="!deleteButtonEnable"
                :options="deleteButtonOptions"
                location="after"
                widget="dxButton"
              />
              <DxToolbarItem
                :options="addButtonOptions"
                location="before"
                widget="dxButton"
              />
            </DxToolbar>
            <DxSelection mode="single" />
            <DxSearchPanel
              :visible="true"
              :width="240"
            />
            <DxPager
              infoText="Страница {0} из {1} • Всего {2} элементов"
              :visible="false"
              :showInfo="true"
            />
            <DxColumn
              :visible="false"
              dataType="number"
              dataField="id"
              caption="ID"
            />
            <DxColumn
              dataType="string"
              dataField="version"
              caption="Версия"
              sortOrder="desc"
            />
            <DxColumn
              dataType="date"
              dataField="createdate"
              caption="Дата"
            />
            <DxColumn
              dataField="state_name"
              caption="Статус"
            />
            <DxColumn
              dataType="string"
              dataField="path"
              caption=""
              cell-template="iconCellTemplate"
            />
            <template #iconCellTemplate="{ data }">
              <a
                class="mobile-build-grid__link"
                :href="getFileUrl(data.value)"
              >
                <i
                  class="core core-071-download"
                  title="Загрузить"
                />
              </a>
            </template>
          </DxDataGrid>
        </template>
        <template #buildIos> </template>
      </DxAccordion>
    </div>
    <DxPopup
      v-if="showForm"
      :visible="true"
      class="access-popup"
      :shading="true"
      :resizeEnabled="false"
      :width="470"
      height="auto"
      :minHeight="270"
      :dragEnabled="true"
      container="#app"
    >
      <template #titleTemplate>
        <PopupTitleTemplate
          text="Создание новой версии мобильного приложения (Android)"
          :showCloseButton="true"
          @closeClick="onPopupClose"
        />
      </template>
      <template #contentTemplate>
        <div class="mobile-build-grid__content">
          <MobileBuildForm
            :dataItemId="formDataItemId"
            :portalId="portalId"
            :portalName="portalName"
            :portalUrl="portalUrl"
            @addBuild="onAddBuild"
          >
          </MobileBuildForm>
        </div>
      </template>
    </DxPopup>
  </div>
</template>

<script>
import { DxLookup } from 'devextreme-vue/lookup';
import DxAccordion from 'devextreme-vue/accordion';
import { DxDataGrid, DxSearchPanel, DxPager, DxColumn, DxSelection, DxToolbar, DxItem as DxToolbarItem } from 'devextreme-vue/data-grid';
import DxPopup from 'devextreme-vue/popup';
import PopupTitleTemplate from '../Containers/PopupTitleTemplate';
import MobileBuildForm from './MobileBuildForm';
import { jsonRPC } from '@/api/api';
const PORTAL_DATASET = 'CONNECTIONS.DSPORTAL';
const BUILDS_DATASET = 'CONNECTIONS.DSMVERSION';

export default {
  name: 'MobileBuildGrid',

  components: {
    DxLookup,
    DxAccordion,
    DxDataGrid,
    DxSearchPanel,
    DxPager,
    DxColumn,
    DxSelection,
    DxToolbar,
    DxToolbarItem,
    DxPopup,
    PopupTitleTemplate,
    MobileBuildForm
  },

  data() {
    return {
      selectedItem: null,
      portalId: null,
      formDataItemId: null,
      showForm: false,
      portalsDataSource: [],
      androidBuildsDataSource: [],
      accordionItems: [
        {
          title: 'Android',
          template: 'buildAndroid'
        },
        {
          title: 'IOS',
          template: 'buildIos'
        }
      ],
      refreshButtonOptions: {
        icon: 'refresh',
        type: 'normal',
        hint: 'Обновить',
        onClick: () => {
          this.refreshGrid();
        }
      },
      deleteButtonOptions: {
        icon: 'trash',
        type: 'normal',
        hint: 'Удалить',
        onClick: () => {
          this.deleteItem();
        }
      },
      addButtonOptions: {
        type: 'success',
        icon: 'plus',
        text: 'Новая сборка',
        onClick: () => {
          this.formDataItemId = null;
          this.showForm = true;
        }
      }
    };
  },

  computed: {
    deleteButtonEnable() {
      return !!this.selectedItem;
    },

    portalName() {
      const portalItem = this.portalsDataSource.find((item) => item.id == this.portalId);
      return portalItem && portalItem.name;
    },

    portalUrl() {
      const portalItem = this.portalsDataSource.find((item) => item.id == this.portalId);
      return portalItem && portalItem.url;
    }
  },

  mounted() {
    jsonRPC('getData', {
      _config_dataset: PORTAL_DATASET
    }).then((data) => {
      this.portalsDataSource = data;
      this.portalId = data[0] && data[0].id;
    });
  },

  methods: {
    onDownloadClick(dataItem) {},

    onPopupClose() {
      this.showForm = false;
    },

    onRowDblClick(eventData) {
      this.formDataItemId = eventData.data.id;
      this.showForm = true;
    },

    deleteItem() {
      const id = this.selectedItem.id;
      if (id) {
        jsonRPC('deleteData', {
          id,
          _config_dataset: BUILDS_DATASET
        }).then(() => {
          this.refreshGrid();
        });
      }
    },

    onAddBuild(dataItem) {
      const param = {
        ...dataItem,
        _config_dataset: BUILDS_DATASET
      };
      jsonRPC('insertData', param)
        .then((response) => {
          dataItem.id = response[0].id;
          return this.build(dataItem);
        })
        .then(() => {
          this.showForm = false;
          this.refreshGrid();
        });
    },

    build(dataItem) {
      let url = process.env.VUE_APP_MOBILE_BUILD_SERVICE;
      const param = {
        method: 'NULL.NULL.BUILD',
        ...dataItem
      };
      return jsonRPC(url, 'NULL.NULL.BUILD', param);
    },

    refreshGrid() {
      if (this.portalId) {
        jsonRPC('getData', {
          portal_id: this.portalId,
          _config_dataset: BUILDS_DATASET
        }).then((data) => {
          this.androidBuildsDataSource = data;
        });
      }
    },

    getFileUrl(guid) {
      return guid && `${process.env.VUE_APP_DOWNLOAD_FILE_SERVICE}?guid=${guid}&_config_dataset=PAGES.DSPAGE`;
    }
  }
};
</script>

<style lang="scss">
.mobile-build-grid {
  background-color: var(--tabs-tab-bg);
  display: flex;
  flex-direction: column;

  &__caption::before {
    content: none;
  }
  .dx-field {
    padding: 6px;
  }
  &__accordion {
    margin: 0px 6px 14px 6px;
    flex: 1;
  }
  &__icon-cell {
    cursor: pointer;
  }
  &__link {
    color: var(--base-link-color);
  }
}
</style>
