<template>
  <!--eslint-disable-->
  <div class="not-found__container">
    <img
      class="not-found__image"
      alt="Страница не найдена"
      src="@/assets/warning.svg"
    />
    <p class="not-found__title">Страница не найдена</p>
    <p class="not-found__text">
      <span
        class="not-found__link"
        @click="refreshPage"
      >
        Обновите страницу
      </span>
      или вернитесь
      <span
        class="not-found__link"
        @click="toMain"
      >на Главную
      </span>
    </p>
  </div>
</template>

<script>
import loginMixin from '../mixins/login';

export default {
  name: 'PageNotFound',
  mixins: [loginMixin],
  methods: {
    toMain() {
      this.$emit('setPageNotFoundStatus', false);
      this.goToDefaultPath();
    },
    refreshPage() {
      this.$emit('setPageNotFoundStatus', false);
      if (this.$store.getters['session/userIsGuest']) {
        if (process.env.VUE_APP_GUEST_SESSION === true || process.env.VUE_APP_GUEST_SESSION === 'true') {
          //Создание гостевой сессии
          this.createGuestSession()
            .then(() => {
              return this.goToCurrentPath() || this.goToDefaultPath();
            })
            .catch(() => {
              this.guestSessionErrorNotify();
            });
        } else {
          this.goToLogin();
        }
      } else {
        // Для залогиненых, что бы сессия не сбрасывалась
        return this.$router.go();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.not-found {
  &__container {
    display: grid;
    place-content: center;
    height: 100vh;
    text-align: center;
  }

  &__image {
    margin-bottom: 35px;
    justify-self: center;
  }

  &__title {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: #072833;
    margin-bottom: 10px;
    margin-top: 0;
  }

  &__text {
    margin-top: 0;
  }

  &__link {
    margin: 0 0 10px 0;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: #2e6ae3;
    text-decoration: none;
    cursor: pointer;
  }
}
</style>
