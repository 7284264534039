<template>
  <!--eslint-disable-->
  <div>
    <div
      v-if="!isFullScreen"
      :class="isFileImage ? 'gallery-item' : 'gallery-item gallery-item_file'"
      @click.self="$emit('itemClick', index)"
    >
      <a
          v-if="downloadImg"
          :href="resultUrl"
          download
          class="gallery-item__download-icon"
      >
        <i
            class="core core-071-download"
            title="Скачать"
        />
      </a>
      <div
        v-if="removableImg"
        class="gallery-item__remove-icon"
      >
        <i
          class="dx-icon dx-icon-trash"
          title="Удалить"
          @click="deleteItem"
        />
      </div>
      <img
        v-if="isFileImage"
        class="gallery-item__img"
        :src="resultUrl"
      />
      <div
          v-else
          class="gallery-item__file"
      >
        <i
            :extension="fileExtension"
            class="gallery-item__file-icon"
            :class="fileIcon"
        />
        <span class="gallery-item__file-name">{{this.itemData.name+'.'+this.itemData.extension}}</span>
      </div>
    </div>
    <div
      v-else
      class="gallery-full-screen-item"
    >
      <img
        v-if="isFileImage"
        class="gallery-full-screen-item__img"
        :src="resultUrl"
      />
      <div
          v-else
          class="gallery-item__file"
      >
        <i
            :extension="fileExtension"
            class="gallery-item__file-icon"
            :class="fileIcon"
        />
        <span class="gallery-item__file-name">{{this.itemData.name+'.'+this.itemData.extension}}</span>
      </div>
      <DxToolbar
        v-if="deviceType === 'phone'"
        class="gallery-full-screen-item__bottom-toolbar"
      >
        <DxToolbarItem
          widget="dxButton"
          location="center"
          :options="closeButtonOptions"
        />
      </DxToolbar>
    </div>
  </div>
</template>

<script>
import { DxToolbar, DxItem as DxToolbarItem } from 'devextreme-vue/toolbar';

export default {
  components: {
    DxToolbar,
    DxToolbarItem
  },
  props: {
    id: Number,
    index: Number,
    url: String,
    name: String,
    dataSetName: String,
    itemData: {
      type: Object,
      default: () => {
        return {};
      }
    },
    isFullScreen: Boolean,
    removableImg: Boolean,
    downloadImg: Boolean,
    deviceType: {
      type: String,
      default: 'desktop'
    },
    itemDataLocal: null
  },
  data() {
    return {
      closeButtonOptions: {
        text: 'Закрыть',
        onClick: () => {
          this.$emit('closeClick');
        }
      }
    };
  },
  computed: {
    resultUrl() {
      let result = this.url;

      if (this.dataSetName) {
        result += '&_config_dataset=' + this.dataSetName;
      }

      return result;
    },
    fileExtension() {
      return this.itemData.extension || '';
    },
    isFileImage() {
      return ['png', 'jpg', 'jpeg', 'tiff', 'tif', 'bmp'].includes(this.fileExtension.toLowerCase());
    },
    fileIcon() {
      let fileIconName;

      switch (this.fileExtension.toLowerCase()) {
        case 'doc':
        case 'docx':
          fileIconName = 'word';
          break;
        case 'xls':
        case 'xlsx':
          fileIconName = 'excel';
          break;
        case 'ppt':
        case 'pptx':
          fileIconName = 'powerpoint';
          break;
        case 'png':
        case 'jpg':
        case 'jpeg':
        case 'tiff':
        case 'tif':
        case 'bmp':
          fileIconName = 'image';
          break;
        case 'rar':
        case 'zip':
          fileIconName = 'archive';
          break;
        case 'pdf':
          fileIconName = 'pdf';
          break;
        case 'txt':
          fileIconName = 'text';
          break;
        case 'mp3':
          fileIconName = 'audio';
          break;
      }

      return `far fa-file-${fileIconName}`;
    }
  },
  methods: {
    deleteItem() {
      this.$emit('deleteItem', this.id);
    }
  }
};
</script>

<style lang="scss">
.gallery-item {
  display: inline-block;
  height: 100%;
  cursor: pointer;
  position: relative;

  &_file {
    display: flex;

    & .gallery-item__file > i {
      font-size: 55px;
      margin: auto;
    }
  }

  &__img {
    max-height: 100%;
    max-width: 100%;
  }

  &__remove-icon {
    position: absolute;
    display: none;
    opacity: 0.7;
    right: 0;
    cursor: pointer;
    color: #fff;
    z-index: 5;
    margin: 4px;
    text-align: right;

    &:hover,
    &:focus {
      opacity: 1;
    }
  }

  &__download-icon {
    position: absolute;
    display: none;
    opacity: 0.7;
    top: 15px;
    right: 1px;
    cursor: pointer;
    color: #fff;
    z-index: 5;
    margin: 4px;
    text-align: right;

    &:hover,
    &:focus {
      opacity: 1;
    }
  }

  &__file {
    margin: auto;
    display: flex;
    flex-direction: column;

    &-name {
      white-space: break-spaces;
      margin-top: 5px;
    }
  }
}

.gallery-item:after {
  content: '\A';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.2);
  opacity: 0;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
}

.gallery-item:hover .gallery-item__remove-icon {
  display: block;
}

.gallery-item:hover .gallery-item__download-icon {
  display: block;
}

.gallery-item:hover:after {
  opacity: 1;
}

.gallery-full-screen-item {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  position: relative;

  &__img {
    max-height: 100%;
    max-width: 100%;
    cursor: pointer;
    vertical-align: middle;
    object-fit: contain;
  }

  &__bottom-toolbar {
    position: absolute;
    bottom: 2px;
    left: 0;
    z-index: 5;

    &.dx-toolbar {
      background-color: rgba(0, 0, 0, 0);
    }
  }
}
</style>
