<template>
  <!--eslint-disable-->
  <div :class="uploaderClass">
    <DxFileUploader
      ref="dxFileUploader"
      v-bind="options"
      v-on="$listeners"
    />
  </div>
</template>

<script>
import DxFileUploader from 'devextreme-vue/file-uploader';

const DEFAULT_DATASET = 'PAGES.DSPAGE';

export default {
  name: 'FileUploaderBase',
  props: {
    accept: {
      type: String,
      default: '*.*'
    },
    extraData: {
      type: Object,
      default() {
        return {};
      }
    },
    guidLink: {
      type: String
    },
    hideUploadButtons: {
      type: Boolean,
      default: false
    },
    labelText: {
      type: String,
      default: 'Для загрузки перетащите файл'
    },
    multiple: {
      type: Boolean,
      default: false
    },
    uploadMode: {
      type: String,
      default: 'useButtons'
    },
    dataSetName: String
  },
  data() {
    return {};
  },
  components: {
    DxFileUploader
  },
  computed: {
    options() {
      return {
        uploadUrl: process.env.VUE_APP_UPLOAD_FILE_SERVICE,
        accept: this.accept,
        multiple: this.multiple,
        uploadMode: this.uploadMode,
        labelText: this.labelText,
        ...this.$props,
        ...this.$attrs
      };
    },
    uploaderClass() {
      return [
        'file-uploader',
        {
          'file-uploader_no-buttons': this.hideUploadButtons
        }
      ];
    }
  },
  mounted() {
    this.$refs.dxFileUploader.instance._uploadStrategy._createFormData = (fieldName, fieldValue) => {
      return this.createFormData(fieldName, fieldValue);
    };
  },
  methods: {
    createFormData(fieldName, fieldValue) {
      const { userId } = this.$store.getters['session/userData'];
      let formData = new FormData();
      let fileName = '';
      let fileExtension = '';
      let lastDotIndex = fieldValue.name.lastIndexOf('.');

      if (lastDotIndex !== -1) {
        fileName = fieldValue.name.substring(0, lastDotIndex);
        fileExtension = fieldValue.name.substring(lastDotIndex + 1);
      } else {
        fileName = fieldValue.name;
        fileExtension = '';
      }

      const params = {
        _config_dataset: this.dataSetName || DEFAULT_DATASET,
        _config_user_id: userId,
        name: fileName,
        extension: fileExtension,
        ...this.extraData
      };

      if (this.guidLink) {
        params.guid_link = this.guidLink;
      }

      formData.append(fieldName, fieldValue);
      formData.append('userid', userId);
      formData.append('param', JSON.stringify(params));
      return formData;
    },
    uploadFiles() {
      this.$refs.dxFileUploader.instance.upload();
    },
    resetData() {
      this.$refs.dxFileUploader.instance.option('value', []);
    }
  }
};
</script>

<style lang="scss">
.file-uploader {
  .dx-fileuploader-input-wrapper::before {
    margin-top: -5px;
    padding-top: 0;
  }

  .dx-fileuploader-input-wrapper::after {
    margin-bottom: -10px;
    padding-bottom: 0;
  }

  &_no-buttons {
    .dx-fileuploader-upload-button {
      display: none;
    }
  }
}
</style>
