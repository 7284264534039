<template>
  <!--eslint-disable-->
  <DxPopup
    width="30vw"
    height="auto"
    popupMaxHeight="80vh"
    :animation="null"
    :visible="visible"
    :shading="false"
    :resizeEnabled="true"
    :minWidth="600"
    :toolbarItems="toolbarItems"
    @shown="$emit('shown')"
  >
    <template #titleTemplate>
      <PopupTitleTemplate
        text="Создание страницы"
        :showCloseButton="true"
        @closeClick="close"
      />
    </template>
    <template #contentTemplate>
      <div class="popup-new-page-form-content">
        <div class="dx-field">
          <div class="dx-field-label">Название страницы</div>
          <div class="dx-field-value">
            <DxTextBox v-model="name" />
          </div>
        </div>
        <div class="dx-field">
          <div class="dx-field-label">Предок</div>
          <div class="dx-field-value">
            <dxDropDownBox
              v-model="parentId"
              valueExpr="id"
              displayExpr="name"
              :showClearButton="true"
              :dataSource="dataSource"
              ref="dropDownBox"
              @valueChanged="closeDropDownBox"
            >
              <template>
                <DxTreeView
                  keyExpr="id"
                  :rootValue="null"
                  displayExpr="name"
                  parentIdExpr="parent_id"
                  dataStructure="plain"
                  :selectByClick="true"
                  selectionMode="single"
                  :focusStateEnabled="false"
                  :searchEnabled="true"
                  :searchExpr="['url', 'name']"
                  :dataSource="dataSource"
                  itemTemplate="itemTemplate"
                  @itemClick="handleItemClick($event)"
                >
                  <template #itemTemplate="{data: itemData}">
                    <div class="item">
                      <div
                        class="icon"
                        v-if="itemData.icon"
                      >
                        <i :class="itemData.icon"></i>
                      </div>
                      <div class="text">
                        {{itemData.name}}
                      </div>
                    </div>
                  </template>
                </DxTreeView>
              </template>
            </dxDropDownBox>
          </div>
        </div>
        <div class="dx-field">
          <div class="dx-field-label">URL</div>
          <div class="dx-field-value">
            <DxTextBox v-model="url" />
          </div>
        </div>
        <div class="dx-field">
          <div class="dx-field-label">Иконка</div>
          <div class="dx-field-value">
            <DxTextBox v-model="icon" />
          </div>
        </div>
        <div class="dx-field">
          <div class="dx-field-label">Позиция</div>
          <div class="dx-field-value">
            <DxNumberBox v-model="pos" />
          </div>
        </div>
        <DxLoadPanel
          :visible="isProcessing"
          container=".popup-new-page-form-content"
        >
          <DxLoadPanelPosition
            at="center"
            my="center"
            of=".popup-new-page-form-content"
          />
        </DxLoadPanel>
      </div>
    </template>
  </DxPopup>
</template>

<script>
import DxDropDownBox from 'devextreme-vue/drop-down-box';
import DxNumberBox from 'devextreme-vue/number-box';
import DxPopup from 'devextreme-vue/popup';
import DxTextBox from 'devextreme-vue/text-box';
import DxTreeView from 'devextreme-vue/tree-view';
import PopupTitleTemplate from '../Containers/PopupTitleTemplate';
import commonActions from '@/store/modules/config/components/commonActions';
import { DxLoadPanel, DxPosition as DxLoadPanelPosition } from 'devextreme-vue/load-panel';

const FIELDS = ['name', 'parentId', 'url', 'icon', 'pos'];
const EMPTY_PAGE = {
  name: '<New page>',
  parentId: null,
  url: '/',
  icon: null,
  pos: 0
};

const mapFields = (fields) => {
  let result = {};
  fields.forEach((fieldName) => {
    result[fieldName] = {
      get() {
        return this.$store.state.uiConstructor.newPage[fieldName];
      },
      set(value) {
        this.$store.dispatch('uiConstructor/setNewPageField', {
          fieldName,
          fieldValue: value
        });
      }
    };
  });
  return result;
};

export default {
  components: {
    DxDropDownBox,
    DxLoadPanel,
    DxLoadPanelPosition,
    DxNumberBox,
    DxPopup,
    DxTextBox,
    DxTreeView,
    PopupTitleTemplate
  },
  data() {
    return {
      isProcessing: false,
      toolbarItems: [
        {
          toolbar: 'bottom',
          location: 'center',
          widget: 'dxButton',
          options: {
            text: 'Создать',
            type: 'success',
            onClick: () => {
              this.apply();
            }
          }
        },
        {
          toolbar: 'bottom',
          location: 'center',
          widget: 'dxButton',
          options: {
            text: 'Отменить',
            onClick: () => {
              this.close();
            }
          }
        }
      ]
    };
  },
  props: {
    visible: Boolean
  },
  computed: {
    dataSource() {
      return this.$store.state.uiConstructor.allPages;
    },
    ...mapFields(FIELDS)
  },
  methods: {
    closeDropDownBox() {
      this.$refs.dropDownBox.instance.close();
    },
    handleItemClick(eventData) {
      this.$store.dispatch('uiConstructor/setNewPageField', {
        fieldName: 'parentId',
        fieldValue: eventData.itemData.id
      });
    },
    // prettier-ignore
    async apply() {
      const newPage = this.$store.state.uiConstructor.newPage;
      this.isProcessing = true;
      const url = await this.$store
        .dispatch('uiConstructor/createNewPage', {
          name: newPage.name,
          parentId: newPage.parentId,
          url: newPage.url,
          icon: newPage.icon,
          pos: newPage.pos
        });
      await commonActions.updateSession();
      await this.$router.push('/constructor' + url);
      this.isProcessing = false;
      this.close();
    },
    close() {
      FIELDS.map((field) => {
        this.$store.dispatch('uiConstructor/setNewPageField', {
          fieldName: field,
          fieldValue: EMPTY_PAGE[field]
        });
      });
      this.$emit('hidden');
    }
  }
};
</script>

<style lang="scss">
.popup-new-page-form-content {
  display: flex;
  flex-direction: column;

  .dx-field {
    display: flex;
    flex: 0 0 auto;

    & > .dx-field-label {
      float: none;
      width: auto;
      flex: 0 0 180px;
    }
    & > .dx-field-value {
      float: none;
      width: auto;
      flex: 1 1 auto;

      & > .dx-checkbox {
        float: none;
      }
    }
  }
}
.item {
  display: flex;
}

.icon {
  flex: 0 0 16px;
  line-height: 16px;
  font-size: 14px;
  vertical-align: middle;
  padding-right: 5px;
}

.text {
  flex: 1 1 auto;
}
</style>
