import { getCookie } from '@/utils/cookie';

export default {
  isAuthenticated(state) {
    return state.isAuthenticated;
  },
  authStatus(state) {
    return state.status;
  },
  isTokenInvalid(state) {
    return state.isTokenInvalid;
  },
  getAuthDate(state) {
    return state.authDate;
  },
  getShowLoginPopup(state) {
    return state.showLoginPopup;
  },
  userData(state) {
    if (state.userData) {
      const token = getCookie('token');
      return { ...state.userData, token };
    } else {
      return state.userData;
    }
  },
  userIsAdmin(state) {
    if (!state.userData) {
      return false;
    }

    return state.userData.userIsAdmin;
  },
  userIsGuest(state) {
    if (!state.userData) {
      return false;
    }

    return state.userData.userIsGuest;
  }
};
