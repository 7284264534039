import { deepCopy } from '@/utils/deep';

const BASIC_OPERATIONS = {
  '<': '<',
  '<=': '<=',
  '<>': '!=',
  '=': '==',
  '>': '>',
  '>=': '>='
};

const OTHER_OPERATIONS = {
  contains: 'in',
  notcontains: 'notin'
};

function getKeywordsMap(swap = false) {
  function swapObject(obj) {
    const ret = {};
    Object.keys(obj).forEach((key) => {
      ret[obj[key]] = key;
    });
    return ret;
  }

  // devextreme: mapbox
  const KEYWORDS_MAP = {
    ...BASIC_OPERATIONS,
    ...OTHER_OPERATIONS
  };
  return swap ? swapObject(KEYWORDS_MAP) : KEYWORDS_MAP;
}

export function devExtremeToMapbox(filters) {
  function transform(filters) {
    const KEYWORDS_MAP = getKeywordsMap();

    let result = '';
    let isSingle = false;
    let tag = false;

    // ex: ['test', 'between', [1, 2]]
    if (!Array.isArray(filters[0])) {
      filters = [filters];
      isSingle = true;
    }

    for (let i = 0; i < filters.length; i++) {
      let keyword = null;
      let field = null;
      let value = null;

      const currentItem = filters[i];
      const nextItem = filters[i + 1];

      switch (currentItem) {
        case 'and':
          result = `${!tag ? '["all", ' : ''}${result}`;
          tag = true;
          break;
        case 'or':
          result = `${!tag ? '["any", ' : ''}${result}`;
          tag = true;
          break;
        case '!':
          result = `${!tag ? '["!", ' : ''}${result}`;
          tag = true;
          break;
        default:
          if (Array.isArray(currentItem[0])) {
            result += `${transform(currentItem)}`;
            if (nextItem) {
              result += ', ';
            }
          } else if (currentItem[0] === '!') {
            result += '["!", ';
            result += `${transform(currentItem[1])}`;
            result += ']';
            if (nextItem) {
              result += ', ';
            }
          } else {
            [field, keyword, value] = currentItem;
          }
          break;
      }

      if (keyword) {
        // для startswith и endswith
        const valueLength = value === null ? null : value.length;
        if (typeof value === 'string') {
          value = `"${value.replace(/"/g, '\\"')}"`;
          //TODO: Это мешает работе в ситуации, когда текстовое поле действительно содержит число, и преобразовывать его НЕ НУЖНО
          // если проскочило число в виде строки
          // value = (
          //   isNaN(value) ?
          //     `"${value}"` :
          //     (
          //       Object.values(BASIC_OPERATIONS).includes(keyword)
          //         ? +value
          //         : `"${value}"`
          //     )
          // );
        }
        switch (keyword) {
          case '<':
          case '<=':
          case '<>':
          case '=':
          case '>':
          case '>=':
            result += `["${KEYWORDS_MAP[keyword]}", ["get", "${field}"], ${value}]`;
            break;
          case 'contains':
            result += `["in", ${value}, ["get", "${field}"]]`;
            break;
          case 'notcontains':
            result += `["!", ["in", ${value}, ["get", "${field}"]]]`;
            break;
          case 'startswith':
            result += `["==", ["slice", ["get", "${field}"], 0, ${valueLength}], ${value}]`;
            break;
          case 'endswith':
            result += `["==", ["slice", ["get", "${field}"], ["-", ${valueLength}]], ${value}]`;
            break;
          case 'between':
            result += '["all", ';
            for (let j = 0; j <= 1; j++) {
              if (j === 0) {
                result += `[">=", ["get", "${field}"], ${value[0]}], `;
              } else {
                result += `["<=", ["get", "${field}"], ${value[1]}]`;
              }
            }
            result += ']';
            break;
          default:
            result += `["${keyword}", ["get", "${field}"], ${value}]`;
            break;
        }
      }
      result += nextItem ? (result.endsWith(', ') ? '' : ', ') : ']p ';
    }
    result = result.replace(']p ', isSingle ? '' : ']');
    result = result.replace(/\n/g, '\\n');

    return result;
  }

  return JSON.parse(transform(filters));
}

export function mapboxToDevExtreme(filters) {
  const copyFilters = deepCopy(filters);
  function transform(filters) {
    const KEYWORDS_MAP = getKeywordsMap(true);

    let result = '';
    let tag = '';
    let isSingle = false;

    if (KEYWORDS_MAP[filters[0]]) {
      filters = [filters];
      isSingle = true;
    }

    filters.forEach((item, index) => {
      let keyword = null;
      let field = null;
      let value = null;

      const nextItem = filters[index + 1];

      switch (item) {
        case 'all':
          tag = '"and"';
          break;
        case 'any':
          tag = '"or"';
          break;
        case '!':
          // костыль для notcontains
          if (nextItem[0] === 'in') {
            nextItem[0] = 'notin';
          } else {
            result += `"!"${nextItem ? ', ' : ''}`;
          }
          break;
        default:
          [keyword, field, value] = item;
          break;
      }
      if (keyword) {
        if (['in', 'notin'].includes(keyword)) {
          //для данных операторов порядок field и value обратный
          [field, value] = [value, field];
        }
        if (typeof value === 'string') {
          value = `"${value.replace(/"/g, '\\"')}"`;
          //TODO: Это мешает работе в ситуации, когда текстовое поле действительно содержит число, и преобразовывать его НЕ НУЖНО
          //   // если проскочило число в виде строки
          //   value = (
          //     isNaN(value) ?
          //       `"${value}"` :
          //       (
          //         Object.values(BASIC_OPERATIONS).includes(keyword)
          //           ? +value
          //           : `"${value}"`
          //       )
          //   );
        }
        switch (keyword) {
          case '!':
          case 'all':
          case 'any':
            result += `[${transform(item)}]`;
            result += tag && nextItem ? `, ${tag}, ` : '';
            break;
          case '!=':
          case '<':
          case '<=':
          case '>':
          case '>=':
          case 'in':
          case 'notin':
            let str = `"${field[1]}", "${KEYWORDS_MAP[keyword]}", ${value}`;
            if (keyword !== 'notin') {
              str = `[${str}]`;
            }
            if (keyword === 'in' && Array.isArray(value)) {
              result += '[';
              result += value.map((v) => `["${field}", "=", "${v}"]`).join(', "or", ');
              result += ']';
            } else {
              result += str;
            }
            result += tag && nextItem ? `, ${tag}, ` : '';
            break;
          case '==':
            // TODO: не очень-то красиво, подумать
            if (field && field[0] === 'slice') {
              // если startswith или endswith
              // если было число, то обратно в строку
              value = typeof value === 'string' ? value : `"${value}"`;
              if (field.length === 4) {
                result += `["${field[1][1]}", "startswith", ${value}]`;
              } else if (field.length === 3) {
                result += `["${field[1][1]}", "endswith", ${value}]`;
              }
            } else {
              // если обычное равенство
              result += `["${field[1]}", "${KEYWORDS_MAP[keyword]}", ${value}]`;
            }
            result += tag && nextItem ? `, ${tag}, ` : '';
            break;
          default:
            break;
        }
      }
    });
    result = isSingle ? result.substring(1).slice(0, -1) : result;
    result = result.replace(/\n/g, '\\n');

    return result;
  }

  return JSON.parse(`[${transform(copyFilters)}]`);
}
