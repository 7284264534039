import getConfigs from './configUtils';
const OPTIONS = ['text', 'color', 'backgroundColor', 'lineColor'];

const CONFIGS_FIELDS = {
  text: {
    name: 'text',
    label: 'Текст',
    component: 'DxTextBox',
    options: {
      showClearButton: true
    },
    changeEvent: 'valueChanged'
  },
  color: {
    name: 'color',
    label: 'Цвет текста',
    component: 'DxTextBox',
    options: {
      showClearButton: true
    },
    changeEvent: 'valueChanged'
  },
  backgroundColor: {
    name: 'backgroundColor',
    label: 'Цвет фона',
    component: 'DxTextBox',
    options: {
      showClearButton: true
    },
    changeEvent: 'valueChanged'
  },
  lineColor: {
    name: 'lineColor',
    label: 'Цвет линии',
    component: 'DxTextBox',
    options: {
      showClearButton: true
    },
    changeEvent: 'valueChanged'
  }
};

export default getConfigs(OPTIONS, CONFIGS_FIELDS);
