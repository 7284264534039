<template>
  <!--eslint-disable-->
  <div class="access-form-template">
    <div
      class="access-form-template__creator"
      v-if="creatorVisible"
    >
      <div class="access-form-template__img">
        <img
          v-show="!showDefaultIcon"
          :src="avatarSrc"
          alt="Аватар"
          @error="imgError"
        />
        <i
          v-show="showDefaultIcon"
          class="core core-user"
        />
      </div>
      <div
        class="access-form-template__user-name"
        @click="toggleChangeCreatorPopupVisible(true)"
      >
        {{ creatorName || 'Отсутствует' }}
      </div>
      <div class="access-form-template__user-label">
        Владелец
      </div>
    </div>
    <div class="access-form-template__grid">
      <div class="access-form-template__header">
        <DxLookup
          :dataSource="propDataSource"
          :hint="selectedProp.caption"
          displayExpr="caption"
          v-model="propId"
          :searchExpr="['caption', 'path']"
          valueExpr="id"
          :width="200"
          @valueChanged="refreshData"
        >
          <DxDropDownOptions maxHeight="300" />
          <template #itemTemplate="{ data }">
            <span :title="data.caption">{{ data.caption }}</span>
          </template>
        </DxLookup>
        <DxButton
          class="access-form-template__table-access"
          type="normal"
          text="Таблица доступа"
          :disabled="disabledTableAccessButton"
          @click="toggleTableAccessVisible(true)"
        />
      </div>
      <DxDataGrid
        class="data-grid"
        :dataSource="gridDataSource"
        :height="260"
        :columnAutoWidth="false"
        columnResizingMode="widget"
        :allowColumnResizing="true"
        :allowColumnReordering="true"
        keyExpr="role_id"
        :importExcel="false"
        :hoverStateEnabled="true"
        :rowAlternationEnabled="true"
        :showColumnLines="false"
        @rowUpdated="saveRightRole([$event.data])"
      >
        <DxEditing
          :allowUpdating="true"
          mode="cell"
        />
        <DxPager
          infoText="Страница {0} из {1} • Всего {2} элементов"
          :showInfo="true"
        />
        <DxDataGridLoadPanel :enabled="false" />
        <DxColumn
          dataType="string"
          dataField="role_name"
          caption="Роль"
          :allowEditing="false"
          :visibleIndex="101"
        />
        <DxColumn
          dataType="boolean"
          dataField="is_right"
          caption="Доступ"
          :width="150"
          :visibleIndex="200"
          :allowSorting="false"
          headerCellTemplate="headerTemplate"
        />
        <template #headerTemplate="{ data }">
          <DxCheckBox v-model:value="checkAllRightsValue" />
        </template>
      </DxDataGrid>
    </div>
    <div class="dx-field">
      <div class="dx-field-label">
        Пользователи
      </div>
      <DxTagBox
        class="dx-field-value access-form-template__tag-box"
        :dataSource="usersDataSource"
        displayExpr="caption"
        valueExpr="id"
        :value="propByUser"
        :searchEnabled="true"
        :showSelectionControls="true"
        applyValueMode="useButtons"
        @valueChanged="savePropByUser"
      />
    </div>
    <div
      v-if="needPartnerDivision"
      class="dx-field"
    >
      <div class="dx-field-label">
        Подразделения
      </div>
      <DxTagBox
        class="dx-field-value access-form-template__tag-box"
        :dataSource="partnerDivisionDataSource"
        displayExpr="name"
        valueExpr="id"
        :value="propByPartnerDivision"
        :searchEnabled="true"
        :showSelectionControls="true"
        applyValueMode="useButtons"
        @valueChanged="savePropByPartnerDivision"
      />
    </div>
    <AccessPopup
      :visible="tableAccessVisible"
      :actionDataSource="propDataSource"
      :componentId="componentId"
      :dataSetName="dataSetName"
      @close="toggleTableAccessVisible(false)"
    />
    <AccessCreatorChangePopup
      :visible="changeCreatorPopupVisible"
      :componentId="componentId"
      :creatorId="creatorId"
      :popupContainer="popupContainer"
      @changeCreatorId="$emit('changeCreatorId', $event)"
      @close="toggleChangeCreatorPopupVisible(false)"
    />
    <DxLoadPanel
      :visible="loadPanelVisible"
      container=".access-form-template"
    >
      <DxLoadPanelPosition
        my="center"
        at="center"
        of=".access-form-template"
      />
    </DxLoadPanel>
  </div>
</template>

<script>
import { DxDataGrid, DxEditing, DxPager, DxColumn, DxLoadPanel as DxDataGridLoadPanel } from 'devextreme-vue/data-grid';
import { DxLookup, DxDropDownOptions } from 'devextreme-vue/lookup';
import DxTagBox from 'devextreme-vue/tag-box';
import { DxLoadPanel, DxPosition as DxLoadPanelPosition } from 'devextreme-vue/load-panel';
import DxCheckBox from 'devextreme-vue/check-box';
import { jsonRPC } from '@/api/api';
import DataSource from 'devextreme/data/data_source';
import CustomStore from 'devextreme/data/custom_store';
import commonActions from '@/store/modules/config/components/commonActions';
import DxButton from 'devextreme-vue/button';
import AccessPopup from './AccessPopup';
import AccessCreatorChangePopup from '@/components/AccessCreatorChangePopup';
import { REGEXP_GUID } from '../utils/const';

const USER_DATA_SET = 'CONNECTIONS.DSUSER_';
const PROP_DATA_SET = 'CONFIG.DSCOMPONENTTYPE_PROP';
const PROP_RIGHT_ROLE_DATA_SET = 'CONFIG.DSCOMPONENTPROP_ROLE';
const PROP_BY_USER_DATA_SET = 'CONFIG.DSCOMPONENTPROP_USER';
const DOM_PORTAL_ID = '27';
const DOM_PARTNER_ID = '7477';
const PARTNER_DIVISION_DATA_SET = 'BASE.DSPARTNERDIVISION';
const PROPP_BY_PARTNER_DIVISION_DATA_SET = 'CONFIG.DSCOMPONENTPROP_PARTNERDIVISION';

export default {
  name: 'AccessFormTemplate',
  components: {
    AccessCreatorChangePopup,
    DxDataGrid,
    DxButton,
    AccessPopup,
    DxEditing,
    DxPager,
    DxColumn,
    DxDataGridLoadPanel,
    DxLookup,
    DxTagBox,
    DxLoadPanel,
    DxLoadPanelPosition,
    DxDropDownOptions,
    DxCheckBox
  },
  props: {
    creatorId: Number,
    componentTypeId: [Number, String],
    componentId: [Number, String],
    dataSetName: String,
    creatorVisible: Boolean,
    popupContainer: null
  },
  data() {
    return {
      tableAccessVisible: false,
      showDefaultIcon: true,
      creatorName: null,
      propDataSource: null,
      propId: null,
      gridDataSource: null,
      usersDataSource: null,
      propByUser: [],
      partnerDivisionDataSource: null,
      propByPartnerDivision: [],
      loadPanelVisible: false,
      lastComponentTypeId: null,
      changeCreatorPopupVisible: false
    };
  },
  watch: {
    creatorId: {
      handler() {
        if (!this.creatorId) {
          this.creatorName = null;
          this.showDefaultIcon = true;
          return;
        }
        this.loadCreatorData();
      },
      immediate: true
    },
    componentTypeId() {
      const currentPropId = this.propId;
      this.loadProp().then(() => {
        if (currentPropId === this.propId) {
          this.refreshData();
        }
      });
    },
    componentId() {
      if (this.lastComponentTypeId === this.componentTypeId) {
        this.refreshData();
      }
      this.lastComponentTypeId = this.componentTypeId;
    }
  },

  computed: {
    disabledTableAccessButton() {
      if (this.propDataSource) {
        return !this.componentId || !this.propDataSource.length;
      }
      return !this.componentId;
    },
    avatarSrc() {
      if (!this.creatorName) {
        return null;
      }
      return `${process.env.VUE_APP_GET_PORTRAIT_IMAGE_SERVICE}?userId=${this.creatorId}`;
    },
    selectedProp() {
      return this.propDataSource && this.propDataSource.length ? this.propDataSource.filter((item) => item.id === this.propId)[0] : {};
    },

    checkAllRightsValue: {
      get() {
        if (!this.gridDataSource || !this.gridDataSource.length) {
          return false;
        }
        //Если объекты имеют разное значение свойства is_right,
        //значение чекбокса неопределенно
        //иначе значение равно значению всех свойств
        let value = this.gridDataSource[0].is_right;
        for (let item of this.gridDataSource) {
          if (item.is_right !== value) {
            value = undefined;
            break;
          }
        }
        return value;
      },
      set(value) {
        if (!this.gridDataSource || !this.gridDataSource.length) {
          return;
        }
        this.gridDataSource = this.gridDataSource.map((item) => {
          return {
            ...item,
            is_right: value
          };
        });
        this.saveRightRole(this.gridDataSource);
      }
    },
    needPartnerDivision() {
      console.log('VUE_APP_PORTAL_ID:', process.env.VUE_APP_PORTAL_ID);
      return process.env.VUE_APP_PORTAL_ID === DOM_PORTAL_ID;
    }
  },

  mounted() {
    if (!this.propDataSource) {
      this.loadProp();
    }
    this.initUsersSource();
    if (this.needPartnerDivision) {
      this.initPartnerDivisionSource();
    }
  },
  methods: {
    initUsersSource() {
      this.usersDataSource = new DataSource({
        paginate: true,
        store: new CustomStore({
          key: 'id',
          load: (loadOptions) => {
            if (!this.propId && this.propId !== 0) {
              return Promise.resolve(null);
            }
            const params = {
              _config_dataset: USER_DATA_SET,
              _config_serverside: true,
              limit: loadOptions.take,
              offset: loadOptions.skip,
              orderby: {
                selector: 'caption',
                desc: false
              }
            };
            if (loadOptions.searchValue) {
              params.where = [loadOptions.searchExpr, loadOptions.searchOperation, loadOptions.searchValue];
            }
            if (loadOptions.filter) {
              params.where = loadOptions.filter;
            }
            return jsonRPC('getData', params);
          }
        })
      });
    },
    initPartnerDivisionSource() {
      this.partnerDivisionDataSource = new DataSource({
        paginate: true,
        store: new CustomStore({
          key: 'id',
          load: (loadOptions) => {
            if (!this.propId && this.propId !== 0) {
              return Promise.resolve(null);
            }
            const params = {
              _config_dataset: PARTNER_DIVISION_DATA_SET,
              _config_serverside: true,
              partner_id: DOM_PARTNER_ID,
              limit: loadOptions.take,
              offset: loadOptions.skip,
              orderby: {
                selector: 'name',
                desc: false
              }
            };
            if (loadOptions.searchValue) {
              params.where = [loadOptions.searchExpr, loadOptions.searchOperation, loadOptions.searchValue];
            }
            if (loadOptions.filter) {
              params.where = loadOptions.filter;
            }
            return jsonRPC('getData', params);
          }
        })
      });
    },
    toggleTableAccessVisible(value) {
      this.tableAccessVisible = value;
    },
    loadProp() {
      return jsonRPC('getData', {
        _config_dataset: PROP_DATA_SET,
        componenttype_id: this.componentTypeId
      }).then((data) => {
        this.propDataSource = data;
        this.propId = (data[0] || {}).id || null;
        return data;
      });
    },
    refreshData() {
      let promises = [];
      this.loadPanelVisible = true;
      promises.push(this.refreshDataGrid(), this.loadPropByUserValue());
      if (this.needPartnerDivision) {
        promises.push(this.loadPropByPartnerDivisionValue());
      }
      Promise.all(promises).finally(() => {
        this.loadPanelVisible = false;
      });
    },
    refreshDataGrid() {
      if ((!this.propId && this.propId !== 0) || String(this.componentId)?.match(REGEXP_GUID)) {
        this.gridDataSource = null;
        return Promise.resolve(null);
      }
      return jsonRPC('getData', {
        _config_dataset: PROP_RIGHT_ROLE_DATA_SET,
        componenttype_prop_id: this.propId,
        component_id: this.componentId
      }).then((data) => {
        // Данные с ролями
        this.gridDataSource = data;
        return data;
      });
    },
    loadPropByUserValue() {
      if ((!this.propId && this.propId !== 0) || String(this.componentId)?.match(REGEXP_GUID)) {
        this.propByUser = [];
        return Promise.resolve(null);
      }
      return jsonRPC('getData', {
        _config_dataset: PROP_BY_USER_DATA_SET,
        componenttype_prop_id: this.propId,
        component_id: this.componentId
      }).then((data) => {
        this.propByUser = data.map((item) => item.user_id);
        return data;
      });
    },
    loadPropByPartnerDivisionValue() {
      if ((!this.propId && this.propId !== 0) || String(this.componentId)?.match(REGEXP_GUID)) {
        this.propByPartnerDivision = [];
        return Promise.resolve(null);
      }
      return jsonRPC('getData', {
        _config_dataset: PROPP_BY_PARTNER_DIVISION_DATA_SET,
        componenttype_prop_id: this.propId,
        component_id: this.componentId
      }).then((data) => {
        this.propByPartnerDivision = data.map((item) => item.user_id);
        return data;
      });
    },
    loadCreatorData() {
      return jsonRPC('getData', {
        _config_dataset: USER_DATA_SET,
        id: this.creatorId
      }).then((result) => {
        const data = result ? result[0] || {} : {};
        if (data.name) {
          this.creatorName = data.name;
          this.showDefaultIcon = false;
        } else {
          this.creatorName = null;
          this.showDefaultIcon = true;
        }
        return data;
      });
    },
    imgError() {
      this.showDefaultIcon = true;
    },
    saveRightRole(data) {
      this.loadPanelVisible = true;
      const params = data.map((item) => {
        return {
          _config_dataset: PROP_RIGHT_ROLE_DATA_SET,
          componenttype_prop_id: this.propId,
          component_id: this.componentId,
          ...item
        };
      });

      jsonRPC('updateData', params)
        .then(() => {
          if (this.dataSetName) {
            //Обновление данных сессии
            return commonActions.updateSession({ dataset: this.dataSetName });
          }
        })
        .finally(() => {
          this.loadPanelVisible = false;
        });
    },
    savePropByUser({ value }) {
      if (this.propByUser.length === value.length && this.propByUser.every((userId) => value.includes(userId))) {
        return;
      }
      this.loadPanelVisible = true;
      jsonRPC('updateData', {
        _config_dataset: PROP_BY_USER_DATA_SET,
        componenttype_prop_id: this.propId,
        component_id: this.componentId,
        user_ids: value
      })
        .then(() => {
          if (this.dataSetName) {
            //Обновление данных сессии
            return commonActions.updateSession({ dataset: this.dataSetName });
          }
        })
        .finally(() => {
          this.propByUser = value;
          this.loadPanelVisible = false;
        });
    },
    savePropByPartnerDivision({ value }) {
      if (this.propByPartnerDivision.length === value.length && this.propByPartnerDivision.every((divisionId) => value.includes(divisionId))) {
        return;
      }
      this.loadPanelVisible = true;
      jsonRPC('updateData', {
        _config_dataset: PROPP_BY_PARTNER_DIVISION_DATA_SET,
        componenttype_prop_id: this.propId,
        component_id: this.componentId,
        partnerdivision_ids: value
      })
        .then(() => {
          if (this.dataSetName) {
            //Обновление данных сессии
            return commonActions.updateSession({ dataset: this.dataSetName });
          }
        })
        .finally(() => {
          this.propByPartnerDivision = value;
          this.loadPanelVisible = false;
        });
    },
    toggleChangeCreatorPopupVisible(value) {
      this.changeCreatorPopupVisible = value;
    }
  }
};
</script>

<style lang="scss">
.access-form-template {
  &__creator {
    padding-bottom: 10px;
    border-bottom: 1px solid #e6eaeb;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }

  &__header {
    display: flex;
  }

  &__table-access {
    margin-left: 10px;
  }

  &__img {
    width: 26px;
    height: 26px;
    overflow: hidden;
    border-radius: 50%;
    background-color: #f6f8fb;
    text-align: center;
    box-sizing: content-box;
    line-height: 22px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }

    i {
      font-size: 12px;
      vertical-align: middle;
      line-height: 26px;
    }
  }

  &__user-name {
    margin: 0 10px;
    flex: 1 0 auto;
    white-space: normal;

    cursor: pointer;
  }

  &__user-label {
    opacity: 0.5;
  }

  &__grid {
    padding-bottom: 10px;
    border-bottom: 1px solid #e6eaeb;
    margin-bottom: 10px;
  }

  &__users {
    display: flex;
  }

  .dx-field {
    display: flex;
    flex: 0 0 auto;
    margin: 0 0 5px;

    &:last-child {
      margin: 0;
    }

    & > .dx-field-label {
      float: none;
      width: auto;
      flex: 0 0 105px;
      text-align: right;
    }
    & > .dx-field-value {
      float: none;
      width: auto;
      flex: 1 1 auto;

      & > .dx-checkbox {
        float: none;
      }
    }
  }

  &__label {
    padding: 5px 8px 5px 0;
  }
  &__tag-box {
    width: 100%;

    .dx-texteditor-input-container.dx-tag-container {
      max-height: 120px;
      overflow: auto;
    }
  }
}
</style>
