import getConfigs from './configUtils';
const OPTIONS = ['min', 'max', 'step'];

const CONFIGS_FIELDS = {
  min: {
    name: 'min',
    label: 'Минимальное значение',
    component: 'DxNumberBox',
    options: {
      showClearButton: true
    },
    changeEvent: 'valueChanged'
  },
  max: {
    name: 'max',
    label: 'Максимальнное значение',
    component: 'DxNumberBox',
    options: {
      showClearButton: true
    },
    changeEvent: 'valueChanged'
  },
  step: {
    name: 'step',
    label: 'Шаг',
    component: 'DxNumberBox',
    options: {
      showClearButton: true
    },
    changeEvent: 'valueChanged'
  }
};

export default getConfigs(OPTIONS, CONFIGS_FIELDS);
