<template>
  <!--eslint-disable-->
  <div class="event-action-grid">
    <DxDataGrid
      height="100%"
      :showColumnHeaders="false"
      :showRowLines="true"
      :showBorders="false"
      :dataSource="dataSource"
    >
      <DxColumn dataField="eventTypeName"> </DxColumn>
      <DxColumn
        dataField="actionCount"
        width="auto"
      > </DxColumn>
      <DxColumn
        width="auto"
        cellTemplate="buttonCelltemplate"
      > </DxColumn>
      <template #buttonCelltemplate="{ data }">
        <div class="edit-event-button">
          <DxButton
            icon="more"
            stylingMode="text"
            @click="showPopupActionTree(data)"
          ></DxButton>
        </div>
      </template>
    </DxDataGrid>
    <PopupActionTree
      :visible="actionTreeVisible"
      :title="actionTreeTitle"
      @hidden="actionTreeVisible = false"
      @shown="actionTreeVisible = true"
    ></PopupActionTree>
  </div>
</template>

<script>
import { DxDataGrid, DxColumn } from 'devextreme-vue/data-grid';
import { DxButton } from 'devextreme-vue/button';
import PopupActionTree from './PopupActionTree';
import { REGEXP_GUID } from '../../utils/const';

export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxButton,
    PopupActionTree
  },
  data() {
    return {
      actionTreeVisible: false,
      actionTreeTitle: ''
    };
  },
  computed: {
    selectedComponentId() {
      return this.$store.getters['config/selectedComponentId'];
    },
    eventActions() {
      return this.$store.state.uiConstructor.selectedEventActions;
    },
    eventTypes() {
      return this.$store.state.uiConstructor.selectedEventTypes || [];
    },
    dataSource() {
      return this.eventTypes.map((eventType) => {
        return {
          eventTypeId: eventType.eventtype_id,
          eventTypeName: eventType.eventtype_name,
          actionCount: this.eventActions.filter((eventAction) => {
            return eventAction.eventtype_id === eventType.eventtype_id;
          }).length
        };
      });
    }
  },
  watch: {
    selectedComponentId(componentId) {
      this.actionTreeVisible = false;

      if (!componentId || componentId.match(REGEXP_GUID)) {
        return;
      }

      this.$store.dispatch('uiConstructor/loadComponentEventActions', componentId).then(() => {
        return this.$store.dispatch('uiConstructor/loadComponentEventTypes', componentId);
      });
    }
  },
  methods: {
    showPopupActionTree(cellTemplateData) {
      this.actionTreeVisible = true;
      this.actionTreeTitle = `Действия на событии "${cellTemplateData.data.eventTypeName}"`;
      this.$store.dispatch('uiConstructor/setSelectedEventType', cellTemplateData.data.eventTypeId);
    }
  }
};
</script>

<style lang="scss">
.edit-event-button {
  /*Уменьшение стандартного отступа для "красоты"*/
  .dx-button-has-icon .dx-button-content,
  .dx-button .dx-button-content {
    padding: 2px;
  }
}
</style>

<style lang="scss" scoped>
.event-action-grid {
  height: 100%;
}
</style>
