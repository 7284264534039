<template>
  <!--eslint-disable-->
  <div class="constructor-component">
    <DxLoadPanel :visible="isLoading" />
    <div
      v-if="!isLoading"
      :id="componentId"
    >
      <!--Скрытый блок с невидимыми компонентами (Датасеты, слои и т.п.) -->
      <InvisibleComponentContainer
        class="constructor-component__invisible-items"
        :items="invisibleItems"
        :title="invisibleItemsTitle"
        position="left top"
      />

      <!--Основной блок для видимых компонентов -->
      <ContainerItem
        v-for="(itemConfig, itemIndex) in visibleItems"
        :key="itemIndex"
        :cssStyle="itemConfig.cssStyle"
        :componentId="itemConfig.componentId"
        :componentType="itemConfig.componentType"
      />
    </div>
  </div>
</template>

<script>
import itemsMixin from '../mixins/items';
import DxLoadPanel from 'devextreme-vue/load-panel';
import InvisibleComponentContainer from './InvisibleComponentContainer';
import ContainerItem from './ContainerItem';
export default {
  name: 'ConstructorComponent',

  mixins: [itemsMixin],

  components: {
    DxLoadPanel,
    InvisibleComponentContainer,
    ContainerItem
  },

  props: {
    componentId: String
  },

  data() {
    return {
      isLoading: false
    };
  },

  computed: {
    items() {
      const pageStore = this.$store.state.config.pages[this.componentId] || {};
      return (pageStore.items || [])
        .map((itemId) => {
          return this.$store.getters['config/getItem'](itemId);
        })
        .sort((itemConfig1, itemConfig2) => {
          return (itemConfig1.componentPos || 0) - (itemConfig2.componentPos || 0);
        });
    }
  },

  watch: {
    componentId: {
      handler(newComponentId, oldComponentId) {
        this.dispatchLoadComponentConfig(newComponentId, oldComponentId);
      },
      immediate: true
    }
  },

  methods: {
    dispatchLoadComponentConfig(newComponentId, oldComponentId) {
      if (!newComponentId) {
        return;
      }

      this.isLoading = true;
      this.pageNotFoundStatus = false;
      this.$store
        .dispatch('config/clearPage', {
          pageUrl: oldComponentId
        })
        .then(() => {
          return this.$store.dispatch('config/loadComponent', {
            componentId: newComponentId
          });
        })
        .then(() => {
          this.$store.commit('config/setPageItems', { url: newComponentId, itemIds: [newComponentId] });
        })
        .finally(() => {
          this.isLoading = false;
        });
    }
  }
};
</script>
