<template>
  <!--eslint-disable-->
  <DxLoadPanel
    v-if="isLoading"
    :visible="true"
  />
  <div
    v-else
    :style="style"
    :id="url"
  >
    <ComponentAddSortable
      :componentId="null"
      :lastItemPos="lastItemPos"
    />

    <!--Скрытый блок с невидимыми компонентами (Датасеты, слои и т.п.) -->
    <InvisibleComponentContainer
      class="layout__invisible-items"
      :items="invisibleItems"
      :title="invisibleItemsTitle"
      position="left top"
    />

    <!--Основной блок для видимых компонентов -->
    <template v-if="!pageNotFoundStatus">
      <ContainerItem
        v-for="(itemConfig, itemIndex) in visibleItems"
        :key="itemIndex"
        :cssStyle="itemConfig.cssStyle"
        :componentId="itemConfig.componentId"
        :componentType="itemConfig.componentType"
      />
    </template>
    <!--Компонент отображается если страница не найдена (ошибка -37007)-->
    <PageNotFound
      v-if="pageNotFoundStatus"
      @setPageNotFoundStatus="setPageNotFoundStatus"
    />
  </div>

</template>

<script>
import devices from 'devextreme/core/devices';
import ContainerItem from './ContainerItem';
import ComponentAddSortable from './ComponentAddSortable';
import InvisibleComponentContainer from './InvisibleComponentContainer';
import loginMixin from '../mixins/login';
import itemsMixin from '../mixins/items';
import DxLoadPanel from 'devextreme-vue/load-panel';
import { deepCopy } from '../../utils/deep';
import PageNotFound from '../PageNotFound/PageNotFound';
import { RIGHTS_NO_SERVICE_RIGHT } from '@/api/jsonRpcCodes';

const DEFAULT_STYLE = {
  display: 'grid',
  alignContent: 'start',
  gridTemplateColumns: 'repeat(1, auto)'
};

export default {
  name: 'Page',
  mixins: [itemsMixin, loginMixin],
  components: {
    PageNotFound,
    ContainerItem,
    ComponentAddSortable,
    InvisibleComponentContainer,
    DxLoadPanel
  },

  data() {
    return {
      isLoading: false,
      pageNotFoundStatus: false
    };
  },

  props: {
    url: String,
    device: String
  },

  computed: {
    statePath() {
      return this.url;
    },

    items() {
      const pageStore = this.$store.state.config.pages[this.url] || {};
      return (pageStore.items || [])
        .map((itemId) => {
          return this.$store.getters['config/getItem'](itemId);
        })
        .sort((itemConfig1, itemConfig2) => {
          return (itemConfig1.componentPos || 0) - (itemConfig2.componentPos || 0);
        });
    },

    components() {
      return Object.values(this.$store.getters['config/getAllComponents']);
    },

    style() {
      let style = DEFAULT_STYLE;
      let pageStore = this.$store.state.config.pages[this.url];
      if (pageStore && pageStore.cssStyle) {
        style = pageStore.cssStyle;
      }
      return style;
    },

    deviceForLoadConfig() {
      return this.device || devices.current().deviceType;
    }
  },

  watch: {
    url: {
      handler(newUrl, oldUrl) {
        this.dispatchLoadPageConfig(newUrl, oldUrl);
      },
      immediate: true
    },

    device() {
      this.dispatchLoadPageConfig(this.url, this.url);
    }
  },

  beforeDestroy() {
    this.$store.dispatch('config/clearPage', {
      pageUrl: this.url
    });
  },

  methods: {
    dispatchLoadPageConfig(newUrl, oldUrl) {
      if (!newUrl) {
        return;
      }
      const domain = window.location.host;

      this.isLoading = true;
      this.pageNotFoundStatus = false;
      this.$store
        .dispatch('config/clearPage', {
          pageUrl: oldUrl
        })
        .then(() => {
          //Фильтрация УРЛ параметров для фронта, на которые нервно реагирует бэк
          const urlParams = this.filterUrlParams(this.$route.query);
          return this.$store.dispatch('config/loadPageConfig', {
            pageUrl: newUrl,
            oldPageUrl: oldUrl,
            domain,
            device: this.deviceForLoadConfig,
            urlParams
          });
        })
        .then((data) => {
          data;
          this.$emit('loaded');
        })
        .catch((error) => {
          // Проверка только на отсутствие доступа к сервису
          if (error.code === RIGHTS_NO_SERVICE_RIGHT) {
            this.pageNotFoundStatus = true;

            //Если пользователь - гость и нет прав на страницу, то надо перейти на страницу входа
            const userIsGuest = this.$store.getters['session/userIsGuest'];
            if (userIsGuest) {
              this.goToLogin(this.$route.path);
            }
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    setPageNotFoundStatus(data) {
      this.pageNotFoundStatus = data;
    },
    filterUrlParams(urlParams) {
      const paramsCopy = {};
      deepCopy(urlParams, paramsCopy);
      Object.keys(urlParams).forEach((attr) => {
        if (attr.startsWith('Gis_')) {
          delete paramsCopy[attr];
        }
      });
      return paramsCopy;
    }
  }
};
</script>
