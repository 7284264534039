<template>
  <!--eslint-disable-->
  <DxPopup
    class="access-popup"
    :visible="visible"
    :shading="false"
    :resizeEnabled="false"
    :minWidth="600"
    :minHeight="280"
    :dragEnabled="true"
    container="#app"
    @hidden="close"
  >
    <template #titleTemplate>
      <PopupTitleTemplate
        text="Настройка доступа"
        :showCloseButton="true"
        @closeClick="close"
      />
    </template>
    <template #contentTemplate>
      <div class="access-popup__content">
        <DxDataGrid
          v-if="!loadPanelVisible"
          class="data-grid"
          height="100%"
          width="100%"
          keyExpr="id"
          displayExpr="caption"
          :showRowLines="false"
          :showColumnLines="false"
          :dataSource="dataSource"
          :allowColumnResizing="true"
          :columnAutoWidth="true"
          :rowAlternationEnabled="true"
          @rowUpdating="onRowUpdating"
          @rowUpdated="onRowUpdated"
        >
          <DxEditing
            :allowUpdating="true"
            mode="cell"
          />
          <DxSearchPanel :visible="true" />
          <DxColumnChooser :enabled="true" />
          <DxColumnFixing :enabled="true" />
          <DxScrolling mode="virtual" />
          <!--Группировка всегда развернута, иначе баг при редактировании-->
          <DxGrouping
            :autoExpandAll="true"
            :allowCollapsing="false"
          />
          <DxGroupPanel :visible="true" />
          <DxColumn
            v-if="groupingIsNeed"
            :groupIndex="0"
            dataField="type"
            groupCellTemplate="groupCellTemplate"
          />
          <template #groupCellTemplate="{ data }">
            {{ data.text }}
          </template>
          <DxPager :visible="false" />
          <DxColumn
            dataField="caption"
            caption="Функция"
            :allowEditing="false"
            :fixed="true"
            :width="250"
          />
          <DxColumn
            v-for="role in roles"
            :key="role.role_id"
            dataType="boolean"
            :dataField="`${role.role_id}`"
            :caption="role.role_name"
            :allowSorting="false"
          />
        </DxDataGrid>
        <DxLoadPanel
          :visible="loadPanelVisible"
          container=".access-popup__content"
        >
          <DxLoadPanelPosition
            my="center"
            at="center"
            of=".access-popup__content"
          />
        </DxLoadPanel>
      </div>
    </template>
  </DxPopup>
</template>

<script>
import DxPopup from 'devextreme-vue/popup';
import PopupTitleTemplate from './Containers/PopupTitleTemplate';
import {
  DxDataGrid,
  DxColumn,
  DxEditing,
  DxSearchPanel,
  DxGroupPanel,
  DxColumnChooser,
  DxPager,
  DxScrolling,
  DxColumnFixing,
  DxGrouping
} from 'devextreme-vue/data-grid';
import { DxLoadPanel, DxPosition as DxLoadPanelPosition } from 'devextreme-vue/load-panel';
import commonActions from '@/store/modules/config/components/commonActions';
import { jsonRPC } from '@/api/api';
import { GROUPING_FUNCTION } from '@/utils/const';

const PROP_RIGHT_ROLE_DATA_SET = 'CONFIG.DSCOMPONENTPROP_ROLE';
const GROUP_COMPONENT = ['Gis', 'GisLayer'];

export default {
  props: {
    visible: Boolean,
    actionDataSource: Array,
    dataSetName: String,
    componentId: [Number, String]
  },
  components: {
    DxScrolling,
    DxGroupPanel,
    DxPager,
    DxEditing,
    DxLoadPanel,
    DxLoadPanelPosition,
    DxPopup,
    PopupTitleTemplate,
    DxDataGrid,
    DxGrouping,
    DxColumnChooser,
    DxColumnFixing,
    DxSearchPanel,
    DxColumn
  },
  data() {
    return {
      loadPanelVisible: false,
      rolesDataSource: null,
      updatedData: null
    };
  },
  computed: {
    groupingIsNeed() {
      return GROUP_COMPONENT.includes(this.сomponentType);
    },
    сomponentType() {
      return this.$store.getters['config/getComponentType'](this.componentId);
    },
    dataSource() {
      if (!this.actionDataSource.length || !this.rolesDataSource.length) return [];

      return this.actionDataSource.map((item, index) => {
        // Если функция с правами всего одна, то данные в массиве rolesDataSource располагаются немного по-другому, в формате [{}, {}]
        // Если функций больше 1, то rolesDataSource выглядит как [[{}, {}], [{}, {}]]
        const rolesByAction = this.actionDataSource.length === 1 ? this.rolesDataSource : this.rolesDataSource[index];

        const roles = rolesByAction.reduce((accumulator, { role_id, is_right }) => {
          return {
            ...accumulator,
            [role_id]: is_right
          };
        }, {});

        const result = {
          ...item,
          ...roles
        };

        if (this.groupingIsNeed) {
          const splitPath = item.path.slice(1, item.path.length - 1).split(',');
          const functionName = splitPath[splitPath.length - 1];
          const type = Object.entries(GROUPING_FUNCTION[this.сomponentType])?.find(([key, value]) => {
            return value.includes(functionName);
          })?.[0];

          result.type = type;
        }

        return result;
      });
    },
    roles() {
      // список ролей одинаковый для всех функций, поэтому берем первый
      return this.actionDataSource.length === 1 ? this.rolesDataSource : this.rolesDataSource?.[0] ?? [];
    }
  },
  mounted() {
    this.loadPanelVisible = true;
  },
  watch: {
    actionDataSource() {
      if (!!this.componentId && !!this.actionDataSource.length) {
        this.refreshData();
      }
    }
  },
  methods: {
    onRowUpdating(event) {
      this.updatedData = event.newData;
    },
    onRowUpdated(event) {
      const { value_false, value_true, id: propId, caption, code, comments, type, componenttype_id, matchstring, ...rest } = event.data;
      const allRolesIds = this.roles.map(({ role_id }) => role_id);
      //Фильтрация только ролей, которые изменились
      const filterRoles = Object.entries(rest).filter(([key]) => {
        return allRolesIds.includes(+key) && this.updatedData[key] !== undefined;
      });
      const filterRest = Object.entries(rest)
        .filter(([key]) => {
          return !allRolesIds.includes(+key);
        })
        .reduce((accumulator, [key, value]) => {
          return {
            ...accumulator,
            [key]: value
          };
        }, {});

      this.updatedData = null;

      const params = filterRoles.map(([roleId, isRight]) => {
        const roleName = this.roles.find(({ role_id }) => role_id === +roleId).role_name;

        return {
          _config_dataset: PROP_RIGHT_ROLE_DATA_SET,
          componenttype_prop_id: propId,
          component_id: this.componentId,
          role_id: roleId,
          role_name: roleName,
          is_right: isRight,
          ...filterRest
        };
      });

      jsonRPC('updateData', params).then(() => {
        if (this.dataSetName) {
          //Обновление данных сессии
          return commonActions.updateSession({ dataset: this.dataSetName });
        }
      });
    },
    refreshData() {
      this.loadPanelVisible = true;

      Promise.all([this.refreshDataGrid()]).finally(() => {
        this.loadPanelVisible = false;
      });
    },
    refreshDataGrid() {
      const params = this.actionDataSource.map(({ id }) => {
        return {
          _config_dataset: PROP_RIGHT_ROLE_DATA_SET,
          componenttype_prop_id: id,
          component_id: this.componentId
        };
      });

      return jsonRPC('getData', params).then((data) => {
        this.rolesDataSource = data;

        return data;
      });
    },
    close() {
      this.$emit('close');
    }
  }
};
</script>

<style lang="scss"></style>
