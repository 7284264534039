import { custom } from 'devextreme/ui/dialog';

export function getDefaultOptions(componentType) {
  switch (componentType) {
    case 'TextBlock':
      return {
        text: 'Text block'
      };
    case 'Button':
      return {
        text: 'Button'
      };
    case 'ImageBlock':
      return {
        src: '/downloadFile?guid=9480D592-CE09-4D70-BD59-7A40C7490693&_config_dataset=PAGES.DSPAGE'
      };
    default:
      return {};
  }
}

export function confirmDeleteComponents() {
  return new Promise((resolve) => {
    const deleteConfirmDialog = custom({
      showTitle: false,
      messageHtml: '<div style="font-size:16px;">Удалить выбранные компоненты?</div>',
      buttons: [
        {
          text: 'Да',
          type: 'danger',
          onClick: () => {
            resolve();
          }
        },
        {
          text: 'Нет'
        }
      ]
    });
    deleteConfirmDialog.show();
  });
}
