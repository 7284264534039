export default {
  setCubeInterface(state, data) {
    state.cubeInterface = data[0] || {};
  },
  setCubes(state, data) {
    state.cubes = data;
  },
  setWorkspaces(state, data) {
    state.workspaces = data;
  },
  setCubeInterfaceField(state, { fieldName, fieldValue }) {
    state.cubeInterface[fieldName] = fieldValue;
  },
  setDimensions(state, data) {
    state.dimensions = data;
  },
  setMeasures(state, data) {
    state.measures = data;
  },
  setSearchString(state, searchString) {
    state.searchString = searchString;
  },
  setSelectedElementType(state, elementType) {
    state.selectedElementType = elementType;
  },
  setElementTypes(state, elementTypes) {
    state.elementTypes = elementTypes;
  },
  setPlaces(state, places) {
    state.places = places;
  }
};
