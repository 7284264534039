import { COLLAPSE_BUTTON_STATES } from '@/utils/const';

export default {
  props: {
    showCollapseButton: Boolean
  },

  data() {
    return {
      isCollapse: false
    };
  },

  computed: {
    collapseButtonClass() {
      return COLLAPSE_BUTTON_STATES[this.isCollapse].icon;
    },
    collapseButtonHint() {
      return COLLAPSE_BUTTON_STATES[this.isCollapse].hint;
    }
  },

  methods: {
    onCollapseButtonClick() {
      this.isCollapse = !this.isCollapse;
      this.$emit('collapseClick', this.isCollapse);
    }
  }
};
