import getConfigs from './configUtils';
const OPTIONS = [
  'initiate',
  'viewer',
  'height',
  'needScale',
  'needBorder',
  'needExpand',
  'saveImg',
  'needPrint',
  'closeAfterPrint',
  'needReset',
  'needTokenSimulation'
];

const CONFIGS_FIELDS = {
  initiate: {
    name: 'initiate',
    label: 'Инициализировать как',
    component: 'DxSelectBox',
    options: {
      dataSource: [
        { text: 'start', value: 'start' },
        { text: 'empty', value: 'empty' },
        { text: 'role', value: 'role' },
        { text: 'none', value: 'none' }
      ],
      showClearButton: true,
      displayExpr: 'text',
      valueExpr: 'value'
    },
    changeEvent: 'optionChanged'
  },
  viewer: {
    name: 'viewer',
    label: 'Только просмотр',
    component: 'DxSelectBox',
    options: {
      dataSource: [
        { text: 'Да', value: 'true' },
        { text: 'Нет', value: 'false' }
      ],
      showClearButton: true,
      displayExpr: 'text',
      valueExpr: 'value'
    },
    changeEvent: 'optionChanged'
  },
  height: {
    name: 'height',
    label: 'Высота',
    component: 'DxTextBox',
    options: {
      showClearButton: true
    },
    changeEvent: 'valueChanged'
  },
  needScale: {
    name: 'needScale',
    label: 'Масштабировать',
    component: 'DxSelectBox',
    options: {
      dataSource: [
        { text: 'Да', value: 'true' },
        { text: 'Нет', value: 'false' }
      ],
      showClearButton: true,
      displayExpr: 'text',
      valueExpr: 'value'
    },
    changeEvent: 'optionChanged'
  },
  needBorder: {
    name: 'needBorder',
    label: 'Отображать рамку',
    component: 'DxSelectBox',
    options: {
      dataSource: [
        { text: 'Да', value: 'true' },
        { text: 'Нет', value: 'false' }
      ],
      showClearButton: true,
      displayExpr: 'text',
      valueExpr: 'value'
    },
    changeEvent: 'optionChanged'
  },
  needExpand: {
    name: 'needExpand',
    label: 'Кнопка разворачивания на весь экран',
    component: 'DxSelectBox',
    options: {
      dataSource: [
        { text: 'Да', value: 'true' },
        { text: 'Нет', value: 'false' }
      ],
      showClearButton: true,
      displayExpr: 'text',
      valueExpr: 'value'
    },
    changeEvent: 'optionChanged'
  },
  saveImg: {
    name: 'saveImg',
    label: 'Кнопка сохранения изображения',
    component: 'DxSelectBox',
    options: {
      dataSource: [
        { text: 'Да', value: 'true' },
        { text: 'Нет', value: 'false' }
      ],
      showClearButton: true,
      displayExpr: 'text',
      valueExpr: 'value'
    },
    changeEvent: 'optionChanged'
  },
  needPrint: {
    name: 'needPrint',
    label: 'Кнопка печати изображения',
    component: 'DxSelectBox',
    options: {
      dataSource: [
        { text: 'Да', value: 'true' },
        { text: 'Нет', value: 'false' }
      ],
      showClearButton: true,
      displayExpr: 'text',
      valueExpr: 'value'
    },
    changeEvent: 'optionChanged'
  },
  closeAfterPrint: {
    name: 'closeAfterPrint',
    label: 'Закрыть кно после печати',
    component: 'DxSelectBox',
    options: {
      dataSource: [
        { text: 'Да', value: 'true' },
        { text: 'Нет', value: 'false' }
      ],
      showClearButton: true,
      displayExpr: 'text',
      valueExpr: 'value'
    },
    changeEvent: 'optionChanged'
  },
  needReset: {
    name: 'needReset',
    label: 'Кнопка очистки поля',
    component: 'DxSelectBox',
    options: {
      dataSource: [
        { text: 'Да', value: 'true' },
        { text: 'Нет', value: 'false' }
      ],
      showClearButton: true,
      displayExpr: 'text',
      valueExpr: 'value'
    },
    changeEvent: 'optionChanged'
  },
  needTokenSimulation: {
    name: 'needTokenSimulation',
    label: 'Интерактивных обход диаграммы',
    component: 'DxSelectBox',
    options: {
      dataSource: [
        { text: 'Да', value: 'true' },
        { text: 'Нет', value: 'false' }
      ],
      showClearButton: true,
      displayExpr: 'text',
      valueExpr: 'value'
    },
    changeEvent: 'optionChanged'
  }
};

export default getConfigs(OPTIONS, CONFIGS_FIELDS);
