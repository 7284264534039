import * as turf from '@turf/turf';
import { jsonRPC } from '@/api/api';
import { debounce } from '@/utils/common';
import { deepCopy } from '@/utils/deep';

const DEFAULT_LOAD_INTERVAL = 60000;
const DATA_INTERVAL = 120000;

function animate(context) {
  if (context._animateStarted) {
    context._mapbox.getSource(context._sourceId).setData(context.getPointsGeojson());    
    window.requestAnimationFrame(function () {
      animate(context);
    });
  }
}

export class AnimatedCarsLayer {
  //Экземпляры объектов класса
  static _instances = {};

  static getExistingInstance(layerComponentId) {
    return this._instances[layerComponentId];
  }

  static getInstance(layerComponentId, options, mapbox) {
    if (this._instances[layerComponentId]) {
      return AnimatedCarsLayer.getExistingInstance(layerComponentId);
    } else {
      const instance = new AnimatedCarsLayer(layerComponentId, options, mapbox);
      this._instances[layerComponentId] = instance;
      return instance;
    }
  }

  constructor(layerComponentId, options, mapbox) {
    if (!options) throw new Error('parameters missing for AnimatedCarsLayer');
    if (!layerComponentId) throw new Error('id parameter missing for AnimatedCarsLayer');

    this._options = options;
    this._layerComponentId = layerComponentId;
    //Ид источника данных
    this._sourceId = null;
    //Треки загруженные с сервера
    this._geojsonTracks = null;
    //Слой функционирует
    this._started = false;
    //Идентификатор таймера на запуск загрузки
    this._nextLoadTimeoutHandle = null;
    //Локальное время получения треков
    this._localStartTime = null;

    //Запущен цикл анимации
    this._animateStarted = false;
    this._mapbox = mapbox;
    this.debounceZoom = debounce(1001, () => this.loadTracks());

    this._mapbox.on('zoomend', () => {
      this.debounceZoom();
    });
  }

  start(sourceId) {
    if (!this._started) {
      if (!this._nextLoadTimeoutHandle) {
        this._nextLoadTimeoutHandle = setTimeout(() => this.loadTracks(), 10);
      }
    }
    this._sourceId = sourceId;
    this._started = true;
  }

  stop() {
    this._started = false;
    this._animateStarted = false;
    this.clearLoad();
  }

  clearLoad() {
    if (this._nextLoadTimeoutHandle) {
      clearTimeout(this._nextLoadTimeoutHandle);
      this._nextLoadTimeoutHandle = null;
    }
  }

  /**
   * Загрузка треков
   * @returns
   */
  loadTracks() {
    //Не давать запросам на загрузку разможаться
    this.clearLoad();
    if (!this._started) {
      return;
    }
    const zoom = this._mapbox.getZoom();
    const extent = turf.bboxPolygon(this._mapbox.getBounds().toArray().flat());

    //Время начала треков для запроса с сервера
    const serverStartTime = new Date().getTime() - DATA_INTERVAL;
    console.log('Start load ' + new Date(serverStartTime));
    const param = {
      _config_dataset: 'RECEIVER.DSGETPOINTSPATH',
      _config_is_geojson: true,
      previous_time: new Date(serverStartTime),
      zoom_target: 13,
      zoom_current: zoom,
      extent: extent
    };
    jsonRPC('getData', param).then((data) => {
      this.onTracksLoaded(data);
    }, (err) => {
      if (!this._nextLoadTimeoutHandle && this._started) {
        this._nextLoadTimeoutHandle = setTimeout(() => this.loadTracks(), DEFAULT_LOAD_INTERVAL);
      }
    });
  }

  onTracksLoaded(data) {
    //Сохранение последних положений точек
    const lastPositions = {};
    this.getPointsGeojson().features.forEach((point) => {
      if (point.geometry && point.geometry.coordinates[0] && point.geometry.coordinates[1]) {
        lastPositions[point.properties.id] = deepCopy(point);
      }
    });
    this._geojsonTracks = data;
    this._localStartTime = new Date().getTime();
    this.correctTracks(lastPositions);
    if (!this._nextLoadTimeoutHandle && this._started) {
      this._nextLoadTimeoutHandle = setTimeout(() => this.loadTracks(), DEFAULT_LOAD_INTERVAL);
    }
    if (!this._animateStarted) {
      this._animateStarted = true;
      animate(this);
    }
  }

  /**
   * Исправление треков, чтобы не было скачков
   */
  correctTracks(lastPositions) {
    this._geojsonTracks.features.forEach((feat) => {
      if (feat.geometry.type === 'LineString') {
        const prevPoint = lastPositions[feat.properties.id];
        if (prevPoint) {
          const found = turf.nearestPointOnLine(feat, prevPoint);
          if (found) {
            //Удаление части линии перед найденной точкой
            feat.geometry.coordinates = feat.geometry.coordinates.slice(found.properties.index);
          }
        }
      }
    });
  }

  /**Получение текущих точек из треков */
  getPointsGeojson() {
    if (this._geojsonTracks) {
      return {
        type: 'FeatureCollection',
        features: this._geojsonTracks.features.map((lineFeature) => this.calculateTrackPoint(lineFeature)).filter((point) => !!point)
      };
    } else {
      //Надо хоть что-то вернуть для getMapboxStyle
      return { type: 'FeatureCollection', features: [{ type: 'Feature', properties: { id: 0 } }] };
    }
  }

  calculateTrackPoint(trackFeature) {
    let point;
    if (trackFeature.geometry.type === 'Point') {
      point = trackFeature;
    } else {
      //Вычисление положения маркера на треке
      const fullLen = trackFeature.properties.len;
      const fullTime = (new Date(trackFeature.properties.finish_time).getTime() - new Date(trackFeature.properties.start_time).getTime()) / 1000;
      //Скорость в м/с
      const v = fullLen / fullTime;
      //Текущее время движения
      const t = (new Date().getTime() - this._localStartTime) / 1000;
      //Пройденное расстояние по треку
      const s = t * v;
      //Положение точки.
      if (s > fullLen) {
        const coords = trackFeature.geometry.coordinates;
        point = { type: 'Feature', geometry: { type: 'Point', coordinates: [coords[coords.length - 1][0], coords[coords.length - 1][1]] } };
      } else {
        point = turf.along(trackFeature, s, { units: 'meters' });
      }
      point.properties = { ...trackFeature.properties };
    }
    return point;
  }

  getSource() {
    return {
      type: 'geojson',
      data: this.getPointsGeojson()
    };
  }
}
