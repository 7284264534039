const styleSortRange = {
  fill: 2,
  line: 1,
  circle: 0,
  symbol: -1
};

const widthLegendImg = 16;
export const heightLegendImg = 16;
const widthPreviewImg = 74;
const heightPreviewImg = 74;

/**
 * Получение изображения для условного обозначения на основе конфигурации слоев mapbox
 * @param {*} mapboxLayerConfigs массив с настройками слоев mapbox
 * @param {*} images
 */
export function getIconDataUrl(mapboxLayerConfigs, images, substrate = false) {
  //TODO: доработать передачу изображений

  const canvas = document.createElement('canvas');
  canvas.width = widthLegendImg;
  canvas.height = heightLegendImg;
  const ctx = canvas.getContext('2d');

  let hasDrawing = false;
  [...mapboxLayerConfigs]
    .sort((mapboxLayerConfig1, mapboxLayerConfig2) => {
      return styleSortRange[mapboxLayerConfig2.type] - styleSortRange[mapboxLayerConfig1.type];
    })
    .forEach((mapboxLayerConfig, index) => {
      hasDrawing ||= drawLayer(ctx, mapboxLayerConfig, images, substrate, index);
    });

  //Если изображение не было нарисовано, возвращается нулл
  return hasDrawing && canvas.toDataURL('image/png');
}

/**
 * Получение изображения для градиента
 * @param {*} mapboxLayerConfig
 * @param {*} existKey
 * @param {'legend', 'preview'} type
 */
export function getGradientDataUrl(mapboxLayerConfig, existKey, type) {
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  drawGradient(ctx, mapboxLayerConfig, existKey, type);

  return canvas.toDataURL('image/png');
}

export function getHeatMapDataUrl(mapboxLayerConfig, existKey, type) {
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  drawColorPalette(ctx, mapboxLayerConfig, existKey, type);

  return canvas.toDataURL('image/png');
}

/**Преобразование шестнадцатеричного цвета в rgb */
export function HEXtoRGB(hex) {
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  hex = hex.replace(shorthandRegex, function (m, r, g, b) {
    return r + r + g + g + b + b;
  });
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? [parseInt(result[1], 16), parseInt(result[2], 16), parseInt(result[3], 16)] : null;
}

/**
 * Расчет цвета градиента
 * @param {*} value - от 0 до 1
 * @param {*} color0
 * @param {*} color1
 * @returns
 */
export function interpolateGradient(value, color0, color1) {
  const c0 = color0
    .replace('#', '')
    .match(/.{1,2}/g)
    .map((oct) => parseInt(oct, 16) * (1 - value));
  const c1 = color1
    .replace('#', '')
    .match(/.{1,2}/g)
    .map((oct) => parseInt(oct, 16) * value);
  const ci = [0, 1, 2].map((i) => Math.min(Math.round(c0[i] + c1[i]), 255));
  const color = ci
    .reduce((a, v) => (a << 8) + v, 0)
    .toString(16)
    .padStart(6, '0');
  return '#' + color;
}

/**
 * Рисование в canvas градиента на основе конфигурации слоя mapbox
 * @param {*} ctx
 * @param {*} mapboxLayerConfig
 * @param {*} existKey
 */
function drawGradient(ctx, mapboxLayerConfig, existKey, type) {
  const paint = mapboxLayerConfig.paint;
  const colorsMapbox = paint[existKey].slice(3);
  const colorsMapboxLength = colorsMapbox.length;
  const countColors = colorsMapboxLength / 2;
  const lineWidth = 1;
  const strokeColor = paint['line-color'] || paint['circle-stroke-color'];
  const minValueStep = colorsMapbox[0];
  const maxValueStep = colorsMapbox[colorsMapboxLength - 2];
  let height;
  let width;

  if (type === 'legend') {
    width = widthLegendImg;
    height = heightLegendImg * countColors;
  } else if (type === 'preview') {
    width = widthPreviewImg;
    height = heightPreviewImg;
  }

  const gradient = ctx.createLinearGradient(0, 0, 0, height); // Рисуем градиент по всей высоте канваса(y1 = 0, y2 = высоте канваса)

  colorsMapbox.forEach((item, index) => {
    if (index % 2 !== 0) return;

    const color = colorsMapbox[index + 1];

    // первый шаг
    if (index === 0) {
      gradient.addColorStop(0, color);
    } else {
      const stepValue = (item - minValueStep) / (maxValueStep - minValueStep);

      gradient.addColorStop(stepValue, color);
    }
  });

  if (type === 'legend') {
    ctx.canvas.width = width;
    ctx.canvas.height = height;

    ctx.fillStyle = gradient;
    ctx.fillRect(1, 1, width, height);

    ctx.strokeStyle = strokeColor;
    ctx.strokeRect(1, 1, width - 2, height - 2);
  } else if (type === 'preview') {
    if (existKey === 'fill-color') {
      ctx.canvas.width = width;
      ctx.canvas.height = height;

      ctx.fillStyle = gradient;
      ctx.fillRect(5, 5, width - 10, height - 10);

      ctx.strokeStyle = paint['line-color'];
      ctx.strokeRect(5, 5, width - 10, height - 10);
    } else if (existKey === 'circle-color') {
      ctx.canvas.width = width;
      ctx.canvas.height = height;

      ctx.fillStyle = gradient;
      ctx.strokeStyle = strokeColor;

      ctx.beginPath();
      ctx.arc(width / 2, width / 2, width / 2.25 - lineWidth, 0, 2 * Math.PI);
      ctx.stroke();
      ctx.fill();
    }
  }
}

function drawColorPalette(ctx, mapboxLayerConfig, existKey, type) {
  const paint = mapboxLayerConfig.paint;
  let collorsArr = paint[existKey].slice(3);
  let onlyColors = [];

  collorsArr.forEach((item, index) => {
    if (index % 2 === 0) return;

    onlyColors.push(collorsArr[index]);
  });

  const rows = onlyColors.length;
  const columns = 1;
  let colorindex = 0;
  let width = null;
  let height = null;

  if (type === 'legend') {
    width = widthLegendImg;
    height = heightLegendImg * rows;
  }

  if (type === 'preview') {
    width = widthPreviewImg;
    height = heightPreviewImg * rows;
  }

  if (type === 'legend') {
    ctx.canvas.width = width;
    ctx.canvas.height = height;

    for (let i = 0; i < rows; i++) {
      for (let j = 0; j < columns; j++) {
        ctx.save();
        ctx.beginPath();
        ctx.rect(width * j, heightLegendImg * i, width, heightLegendImg);
        ctx.fillStyle = onlyColors[colorindex];
        ctx.fill();
        ctx.closePath();
        ctx.restore();
        colorindex++;
      }
    }
  }
  if (type === 'preview') {
    ctx.canvas.width = width;
    ctx.canvas.height = height;

    for (let i = 0; i < rows; i++) {
      for (let j = 0; j < columns; j++) {
        ctx.save();
        ctx.beginPath();
        ctx.rect(width * j, heightPreviewImg * i, width, heightPreviewImg);
        ctx.fillStyle = onlyColors[colorindex];
        ctx.fill();
        ctx.closePath();
        ctx.restore();
        colorindex++;
      }
    }
  }
}

/**
 * Рисование в canvas отдельного элемента на основе конфигурации слоя mapbox
 * @param {*} ctx
 * @param {*} mapboxLayerConfig
 * @param {*} images
 * @returns {Boolean} - была ли нарисована легенда?
 */
function drawLayer(ctx, mapboxLayerConfig, images, substrate, index) {
  const paint = mapboxLayerConfig.paint;
  switch (mapboxLayerConfig.type) {
    case 'line':
      paintLine(ctx, paint, substrate, index);
      return true;
    case 'fill':
      paintSquare(ctx, 1, paint['line-color'], paint['fill-color'], paint['fill-opacity'], substrate);
      return true;
    case 'circle':
      paintCircle(ctx, 1, paint['circle-stroke-color'], paint['circle-color'], paint['circle-opacity'], substrate, index);
      return true;
    case 'symbol':
      if (images && images[mapboxLayerConfig.layout['icon-image']]) {
        drawImage(ctx, images[mapboxLayerConfig.layout['icon-image']], substrate);
      }
      return true;
    case 'fill-extrusion':
      paintSquare(ctx, 1, paint['line-color'], paint['fill-extrusion-color'], paint['fill-extrusion-opacity'], substrate);
      return true;
    case 'raster':
      return drawRasterLegend(ctx, mapboxLayerConfig, substrate);
    // Из-за нехватки параметров ошибка, пока отключил
    // case 'heatmap':
    //   drawColorPalette(ctx, paint['heatmap-color']);
    //   break;
  }
}

/**Рисование легенды растрового слоя, возвращает true если легенда была нарисована */
function drawRasterLegend(ctx, mapboxLayerConfig, substrate, index) {
  let ret = false;
  for (let i = 0; i < mapboxLayerConfig.channelsSettings.length; i++) {
    const rasterLegend = mapboxLayerConfig.channelsSettings[i];
    const paint = rasterLegend?.options?.paint;
    if (rasterLegend.type === 'fill') {
      if (paint['fill-pattern']) {
        paintHatchPattern(ctx, paint['fill-pattern']);
        ret = true;
      } else {
        paintSquare(ctx, 1, paint['line-color'], paint['fill-color'], paint['fill-opacity'], substrate);
        ret = true;
      }
    } else if (rasterLegend.type === 'line') {
      paintLine(ctx, paint, substrate, index);
      ret = true;
    } else if (rasterLegend.type === 'circle') {
      paintCircle(ctx, 1, paint['circle-stroke-color'], paint['circle-color'], paint['circle-opacity'], substrate, index);
      ret = true;
    }
  }
  return ret;
}

/**Рисованиие штриховки */
function paintHatchPattern(ctx, patternName, interpolation = 1) {
  let name, color, size;

  const keyValuePairs = patternName.split('_');

  keyValuePairs.forEach((pair) => {
    const [key, value] = pair.split('=');

    if (key === 'name') {
      name = value;
    } else if (key === 'color') {
      color = value;
    } else if (key === 'size') {
      size = value;
    }
  });

  switch (name) {
    case 'obliqueLine':
      paintObliqueLines(ctx, color, size);
      break;
    case 'intersectingLines':
      paintIntersectingLines(ctx, color, size);
      break;
    case 'straightLine':
      paintHorizontalLines(ctx, color, size);
      break;
    case 'dote':
      paintDots(ctx, color, size);
      break;
    case 'cell':
      paintCells(ctx, color, size);
      break;
  }
}

/**Штриховка прямая сетка */
function paintCells(ctx, color, size) {
  const linesCount = 20;
  const width = ctx.canvas.width;
  const height = ctx.canvas.height;
  const stepX = size / linesCount;
  const stepY = size / linesCount;
  const steps = height / stepX;

  ctx.strokeStyle = color;
  //ctx.lineWidth = 2;
  ctx.beginPath();
  for (let i = 0; i < steps; i++) {
    ctx.moveTo(0, stepY * i);
    ctx.lineTo(width, stepY * i);
  }
  for (let i = 0; i < steps; i++) {
    ctx.moveTo(stepX * i, 0);
    ctx.lineTo(stepX * i, height);
  }
  ctx.stroke();
}

/**Штриховка точками */
function paintDots(ctx, color, size) {
  const linesCount = 20;
  const width = ctx.canvas.width;
  const height = ctx.canvas.height;
  const stepX = size / linesCount;
  const stepY = size / linesCount;
  const steps = width / stepX;

  ctx.fillStyle = color;
  ctx.beginPath();
  for (let ix = 0; ix < steps; ix++) {
    for (let iy = 0; iy < steps; iy++) {
      ctx.fillRect(ix * stepX, iy * stepY, 2, 2);
    }
  }
  ctx.stroke();
}

/**Штриховка косая сетка */
function paintIntersectingLines(ctx, color, size) {
  const linesCount = 20;
  const width = ctx.canvas.width;
  const height = ctx.canvas.height;
  const stepX = size / linesCount;
  const stepY = size / linesCount;
  const steps = width / stepX;

  ctx.strokeStyle = color;
  //ctx.lineWidth = 2;
  ctx.beginPath();
  for (let i = 0; i < steps / 2 + 1; i++) {
    ctx.moveTo(stepX * i, 0);
    ctx.lineTo(width, height - stepY * i);
  }
  for (let i = 1; i < steps / 2 + 1; i++) {
    ctx.moveTo(0, stepY * i);
    ctx.lineTo(width - stepX * i, height);
  }
  for (let i = 0; i < steps / 2 + 1; i++) {
    ctx.moveTo(width - stepX * i, 0);
    ctx.lineTo(0, height - stepY * i);
  }
  for (let i = 0; i < steps / 2 + 1; i++) {
    ctx.moveTo(width, stepY * i);
    ctx.lineTo(stepX * i, height);
  }
  ctx.stroke();
}

/**Штриховка косые линии */
function paintObliqueLines(ctx, color, size) {
  const linesCount = 20;
  const width = ctx.canvas.width;
  const height = ctx.canvas.height;
  const stepX = size / linesCount;
  const stepY = size / linesCount;
  const steps = width / stepX;

  ctx.strokeStyle = color;
  //ctx.lineWidth = 2;
  ctx.beginPath();
  for (let i = 0; i < steps / 2 + 1; i++) {
    ctx.moveTo(stepX * i, 0);
    ctx.lineTo(width, height - stepY * i);
  }
  for (let i = 1; i < steps / 2 + 1; i++) {
    ctx.moveTo(0, stepY * i);
    ctx.lineTo(width - stepX * i, height);
  }
  ctx.stroke();
}

/**Штриховка горизонтальные линии */
function paintHorizontalLines(ctx, color, size) {
  const linesCount = 20;
  const width = ctx.canvas.width;
  const height = ctx.canvas.height;
  const stepX = size / linesCount;
  const stepY = size / linesCount;
  const steps = height / stepX;

  ctx.strokeStyle = color;
  //ctx.lineWidth = 2;
  ctx.beginPath();
  for (let i = 0; i < steps; i++) {
    ctx.moveTo(0, stepY * i);
    ctx.lineTo(width, stepY * i);
  }
  ctx.stroke();
}

function paintLine(ctx, params, substrate, index) {
  const width = ctx.canvas.width;
  const isDash = !!params['line-dasharray'];

  if (substrate && index === 0) {
    drawSubstrate(ctx, width, width);
  }

  ctx.strokeStyle = params['line-color'];
  ctx.lineWidth = 1;

  ctx.beginPath();
  ctx.moveTo(0, width);
  if (isDash) {
    ctx.lineTo(width / 2 - 1, width / 2 + 1);
    ctx.moveTo(width / 2 + 1, width / 2 - 1);
    ctx.lineTo(width, 0);
  } else {
    ctx.lineTo(width, 0);
  }
  ctx.stroke();
}

function paintSquare(ctx, lineWidth, strokeColor, fillColor, opacity, substrate) {
  const width = ctx.canvas.width;

  if (substrate) {
    drawSubstrate(ctx, width - 2, width - 2);
  }

  ctx.strokeStyle = strokeColor || fillColor;
  ctx.lineWidth = lineWidth;
  ctx.fillStyle = fillColor;
  ctx.globalAlpha = opacity;
  ctx.fillRect(1, 1, width - 2, width - 2);
  ctx.globalAlpha = 1;
  ctx.strokeRect(1, 1, width - 2, width - 2);
}

function paintCircle(ctx, lineWidth, lineColor, fillColor, opacity, substrate, index) {
  const width = ctx.canvas.width;

  if (substrate && index === 0) {
    drawSubstrate(ctx, width, width);
  }

  ctx.strokeStyle = lineColor;
  ctx.lineWidth = lineWidth;
  ctx.fillStyle = fillColor;

  ctx.beginPath();
  ctx.arc(width / 2, width / 2, width / 3 - lineWidth, 0, 2 * Math.PI);
  ctx.stroke();

  ctx.globalAlpha = opacity;
  ctx.fill();
}

function drawImage(ctx, image, substrate) {
  const width = image.width;
  const height = image.height;

  //Это side-effect, будем увеличивать канвас под размер изображения
  //Если использовать drawImage и уменьшать изображение, то идет потеря качества
  //Потеря качества меньше, если рисовать в оригинальном размере, а в разметке для img задать размеры
  if (ctx.canvas.width < width) {
    ctx.canvas.width = width;
  }
  if (ctx.canvas.height < height) {
    ctx.canvas.height = height;
  }

  if (substrate) {
    drawSubstrate(ctx, width, height);
  }

  ctx.imageSmoothingEnabled = true;
  ctx.drawImage(image, 0, 0);
}

/**
 * Метод добавляет подложку с белым фоном для отоброжения иконок при экспорте отчетов в pdf
 * @param {*} ctx
 * @param {*} width
 * @param {*} height
 */
function drawSubstrate(ctx, width, height) {
  ctx.fillStyle = '#fff';
  ctx.globalAlpha = 1;
  ctx.fillRect(1, 1, width, height);
}

export function saveCanvas(fileName, canvas) {
  const downloadEl = document.createElement('a');
  document.body.appendChild(downloadEl);
  downloadEl.style.display = 'none';
  downloadEl.download = fileName; //'snapshot.jpg';
  downloadEl.href = canvas.toDataURL('image/jpg');
  downloadEl.click();
}

/**
 * генерация изображения курсора в виде окружности
 */
export function getCircleCursorUrl(color, radius) {
  // repect devicePixelRatio
  radius *= window.devicePixelRatio;
  const padding = 2;

  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  canvas.width = radius * 2 + padding;
  canvas.height = radius * 2 + padding;

  ctx.lineWidth = 2;
  ctx.beginPath();
  ctx.strokeStyle = 'white';
  ctx.arc(canvas.width / 2, canvas.height / 2, radius - 2, 0, Math.PI * 2);
  ctx.stroke();
  ctx.closePath();
  ctx.beginPath();
  ctx.strokeStyle = color;
  ctx.arc(canvas.width / 2, canvas.height / 2, radius, 0, Math.PI * 2);
  ctx.stroke();
  ctx.closePath();
  // use canvas.toBlob or canvas.toDataURL to create image uri
  // - canvas.toDataURL is faster to rerender
  // - canvas.toBlob is a smaller uri
  const url = canvas.toDataURL();
  return `url(${url}) ${radius + padding / 2} ${radius + padding / 2}`;
}
