export default function DictionaryFactory(options) {
  return {
    /**Имя словаря*/
    name: options.name,

    /**Данные словаря*/
    data: null,

    /**Ключ*/
    key: options.key || 'id'
  };
}
