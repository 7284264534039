<template>
  <!--eslint-disable-->
  <div class="search-component-form">
    <div class="dx-field">
      <div class="dx-field-label"></div>
      <div class="dx-field-value">
        <DxRadioGroup
          :items="Object.values(places)"
          :value="selectionPlace"
          layout="horizontal"
          @valueChanged="changedDxRadioGroup($event)"
        />
      </div>
    </div>
    <div class="dx-field">
      <div class="dx-field-label">Компонент</div>
      <div class="dx-field-value">
        <ComponentSearchSelectBox
          v-show="isGlobal || isServiceComponent || isActiveComponent"
          :value="value"
          :showOpenButton="true"
          :disabled="isServiceComponent"
          v-on="$listeners"
          @selectionChanged="$emit('selectionChanged', $event)"
          @openComponent="openComponent"
        />
        <DropDownComponentsBox
          v-show="isOnPage"
          :value="value"
          :dataSource="dropDownDataSource"
          :showOpenButton="true"
          @valueChanged="$emit('dropDownValueChanged', $event)"
          @itemSelectionChanged="$emit('treeViewItemSelectionChanged', $event)"
          @openComponent="openComponent"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ComponentSearchSelectBox from './ComponentSearchSelectBox';
import DropDownComponentsBox from './DropDownComponentsBox';
export default {
  name: 'SearchComponentForm',
  components: {
    ComponentSearchSelectBox,
    DropDownComponentsBox
  },
  props: {
    value: null,
    selectionPlace: String,
    places: Object,
    dropDownDataSource: Array
  },
  model: { prop: 'value', event: 'update:value' },
  computed: {
    isGlobal() {
      return this.selectionPlace === this.places.global;
    },
    isServiceComponent() {
      return this.selectionPlace === this.places.serviceComponent;
    },
    isActiveComponent() {
      return this.selectionPlace === this.places.active;
    },
    isOnPage() {
      return this.selectionPlace === this.places.onPage;
    }
  },
  methods: {
    openComponent() {
      window.open(`/constructor/component/${this.value}`);
    },
    setServiceComponentValue(value = 0) {
      this.$emit('update:value', value);
    },
    changedDxRadioGroup(event) {
      if (event.value === this.places.serviceComponent) {
        this.setServiceComponentValue(0);
      } else if (event.value === this.places.active) {
        this.setServiceComponentValue(+this.$store.getters['config/selectedComponentId']);
      }

      this.$emit('changePlace', event.value);
    }
  }
};
</script>
<style lang="scss">
.search-component-form {
  &:not(:last-child) {
    margin-bottom: 5px;
  }

  .dx-field {
    display: flex;
    flex: 0 0 auto;

    & > .dx-field-label {
      float: none;
      width: auto;
      flex: 0 0 150px;
    }

    & > .dx-field-value {
      float: none;
      width: auto;
      flex: 1 1 auto;

      & > .dx-checkbox {
        float: none;
      }
    }
  }
}
</style>
