import getConfigs from './configUtils';
const OPTIONS = ['valueExpr', 'displayExpr', 'placeholder', 'hint', 'disabled', 'showClearButton', 'showPopupTitle'];

const CONFIGS_FIELDS = {
  valueExpr: {
    name: 'valueExpr',
    label: 'Поле значения',
    component: 'DxTextBox',
    options: {
      showClearButton: true
    },
    changeEvent: 'valueChanged'
  },
  displayExpr: {
    name: 'displayExpr',
    label: 'Поле отображения',
    component: 'DxTextBox',
    options: {
      showClearButton: true
    },
    changeEvent: 'valueChanged'
  },
  placeholder: {
    name: 'placeholder',
    label: 'Подсказка',
    component: 'DxTextBox',
    options: {
      showClearButton: true
    },
    changeEvent: 'valueChanged'
  },
  hint: {
    name: 'hint',
    label: 'Всплывающая подсказка',
    component: 'DxTextBox',
    options: {
      showClearButton: true
    },
    changeEvent: 'valueChanged'
  },
  disabled: {
    name: 'disabled',
    label: 'Поле заблокировано',
    component: 'DxSelectBox',
    options: {
      dataSource: [
        { text: 'Да', value: 'true' },
        { text: 'Нет', value: 'false' }
      ],
      showClearButton: true,
      displayExpr: 'text',
      valueExpr: 'value'
    },
    changeEvent: 'optionChanged'
  },
  showClearButton: {
    name: 'showClearButton',
    label: 'Отображать кнопку сброса',
    component: 'DxSelectBox',
    options: {
      dataSource: [
        { text: 'Да', value: 'true' },
        { text: 'Нет', value: 'false' }
      ],
      showClearButton: true,
      displayExpr: 'text',
      valueExpr: 'value'
    },
    changeEvent: 'optionChanged'
  },
  showPopupTitle: {
    name: 'showPopupTitle',
    label: 'Отображать заголовок всплывающего окна',
    component: 'DxSelectBox',
    options: {
      dataSource: [
        { text: 'Да', value: 'true' },
        { text: 'Нет', value: 'false' }
      ],
      showClearButton: true,
      displayExpr: 'text',
      valueExpr: 'value'
    },
    changeEvent: 'optionChanged'
  }
};

export default getConfigs(OPTIONS, CONFIGS_FIELDS);
