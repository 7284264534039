<template>
  <!--eslint-disable-->
  <DxPopup
    width="60vw"
    height="60vh"
    popupMaxHeight="80vh"
    :animation="null"
    :visible="true"
    :shading="false"
    :resizeEnabled="true"
    :minWidth="450"
    :toolbarItems="toolbarItems"
    @shown="onShown"
    @hidden="onHidden"
  >
    <template #titleTemplate>
      <PopupTitleTemplate
        text="Импорт компонентов"
        :showCloseButton="true"
        @closeClick="$emit('hidden')"
      />
    </template>
    <template>
      <div class="popup-import-settings-form-content">
        <div class="dx-field">
          <div class="dx-field-label">Родитель</div>
          <div class="dx-field-value">
            <ComponentSearchSelectBox v-model="parentComponentId" />
          </div>
        </div>
        <div class="dx-field dx-field-json">
          <div class="dx-field-label dx-field-label-file">JSON</div>
          <div class="dx-field-value dx-field-value-json">
            <DxFileUploader
              class="popup-import-settings-form-content__file-uploader"
              height="100%"
              accept=".txt, .json"
              :uploadUrl="uploadUrl"
              uploadMode="useButtons"
              ref="fileUploader"
              @valueChanged="onFileUploaderValueChanged"
              @uploaded="onUploaded"
            />
          </div>
        </div>
      </div>
    </template>
  </DxPopup>
</template>

<script>
import DxPopup from 'devextreme-vue/popup';
import PopupTitleTemplate from '../Containers/PopupTitleTemplate';
import ComponentSearchSelectBox from './ComponentSearchSelectBox';
import DxFileUploader from 'devextreme-vue/file-uploader';
import { jsonRPC } from '@/api/api';
import notify from 'devextreme/ui/notify';

const UPLOAD_FILE_DATA_SET = 'PAGES.DSPAGE';
const DEFAULT_OPTIONS = {
  encoding_name: 'UTF-8',
  documenttype_id: 5
};

export default {
  components: {
    DxPopup,
    ComponentSearchSelectBox,
    PopupTitleTemplate,
    DxFileUploader
  },
  data() {
    return {
      parentComponentId: '',
      componentJson: '',
      toolbarItems: [
        {
          toolbar: 'bottom',
          location: 'center',
          widget: 'dxButton',
          options: {
            text: 'Загрузить файл',
            type: 'success',
            onClick: () => {
              this.uploadFile();
            }
          }
        },
        {
          toolbar: 'bottom',
          location: 'center',
          widget: 'dxButton',
          options: {
            text: 'Импортировать',
            icon: 'check',
            type: 'success',
            onClick: () => {
              this.apply();
            }
          }
        },
        {
          toolbar: 'bottom',
          location: 'center',
          widget: 'dxButton',
          options: {
            text: 'Отменить',
            onClick: () => {
              this.$emit('hidden');
            }
          }
        }
      ],
      documentOptions: {
        ...DEFAULT_OPTIONS
      },
      uploadUrl: process.env.VUE_APP_UPLOAD_FILE_SERVICE,
      isFileChoosen: false,
      pageGuid: null
    };
  },
  methods: {
    apply() {
      if (!this.pageGuid) {
        notify({ message: 'Пожалуйста, загрузите файл с данными', width: 'auto' }, 'warning', 3000);
        return;
      }
      this.$store.commit('setLoading', true);
      const url = this.$store.getters['config/getPageUrl'];
      return jsonRPC('getData', {
        page_guid: this.pageGuid,
        component_parent_id: this.parentComponentId,
        device: this.$store.getters['uiConstructor/resolutionDevice'],
        url,
        _config_dataset: 'CONFIG.PRCLONECOMPONENTBYAPI'
      })
        .then(() => {
          this.$emit('hidden');
          if (this.parentComponentId) {
            //Обновление компонента вставки
            const parentConfig = this.$store.getters['config/getItem'](this.parentComponentId);
            const grandParentComponentId = parentConfig.parentComponentId;
            return this.$store.dispatch('config/loadComponent', {
              componentId: this.parentComponentId,
              parentComponentId: grandParentComponentId
            });
          } else {
            //Обновление всей страницы
            return this.$store.dispatch('config/loadPageConfig', {
              pageUrl: url,
              oldPageUrl: url,
              domain: window.location.host,
              device: this.$store.getters['uiConstructor/resolutionDevice'] || 'desktop',
              urlParams: this.$route.query
            });
          }
        })
        .finally(() => {
          this.$store.commit('setLoading', false);
        });
    },
    onShown() {
      this.parentComponentId = this.$store.getters['config/selectedComponentId'];
      this.$emit('shown');
    },
    onHidden() {
      this.$refs.fileUploader.instance.reset();
      this.pageGuid = null;
      this.isFileChoosen = false;
      this.$emit('hidden');
    },
    onFileUploaderValueChanged(eventData) {
      this.isFileChoosen = !!eventData.value.length;
    },
    uploadFile() {
      if (!this.isFileChoosen) {
        notify({ message: 'Пожалуйста, выберите файл с данными', width: 'auto' }, 'warning', 3000);
        return;
      }
      this.isFileChoosen = false;
      this.$refs.fileUploader.instance._uploadStrategy._createFormData = (fieldName, fieldValue) => {
        return this.createFormData(fieldName, fieldValue);
      };
      this.$refs.fileUploader.instance.upload();
    },
    createFormData(fieldName, fieldValue) {
      const { userId } = this.$store.getters['session/userData'];
      let formData = new FormData();
      let fileName = '';
      let fileExtension = '';

      let lastDotIndex = fieldValue.name.lastIndexOf('.');
      if (lastDotIndex !== -1) {
        fileName = fieldValue.name.substring(0, lastDotIndex);
        fileExtension = fieldValue.name.substring(lastDotIndex + 1);
      } else {
        fileName = fieldValue.name;
        fileExtension = '';
      }

      const param = {
        _config_dataset: UPLOAD_FILE_DATA_SET,
        _config_user_id: userId,
        name: fileName,
        extension: fileExtension
      };

      formData.append(fieldName, fieldValue);
      formData.append('userid', userId);
      formData.append('param', JSON.stringify(param));
      return formData;
    },
    onUploaded(eventData) {
      let response = {};
      if (eventData.request && eventData.request.response) {
        try {
          response = JSON.parse(eventData.request.response) || {};
        } catch (e) {
          window.console.error(e);
        }
      }
      this.pageGuid = response?.guid || null;
      console.log('response:', response);
      console.log('pageGuid:', this.pageGuid);
    }
  }
};
</script>

<style lang="scss">
.popup-import-settings-form-content {
  height: 100%;
  display: flex;
  flex-direction: column;

  &__json {
    height: 100%;
  }

  .dx-field {
    display: flex;
    flex: 0 0 auto;

    & > .dx-field-label {
      float: none;
      width: auto;
      flex: 0 0 90px;
    }
    & > .dx-field-value {
      float: none;
      width: auto;
      flex: 1 1 auto;

      & > .dx-checkbox {
        float: none;
      }
    }
    .dx-field-value-json {
      flex: 1;
    }
  }
  .dx-field-json {
    flex: 1;
  }

  &__file-uploader {
    border: 1px dashed #c5cbcd;
    background-color: #f5f8fa;

    .dx-fileuploader-upload-button {
      display: none;
    }
  }
}
</style>
