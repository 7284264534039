import { DATASET_COMPONENT_TYPES } from '@/utils/const';
import componentGetters from './components/getters';
import dictionariesGetters from './dictionaries/getters';
import devices from 'devextreme/core/devices';
import { getPropByPath } from '@/utils/common';

export const getConstructorActiveComponentConfig = 'getConstructorActiveComponentConfig';
export const getComponentType = 'getComponentType';
export const getComponentsList = 'getComponentsList';

const getters = {
  ...componentGetters,
  ...dictionariesGetters,

  /**
   * Получение типа компонента
   */
  [getComponentType]: (state) => (componentId) => {
    const componentConfig = state.items[componentId] || {};
    return componentConfig.componentType || null;
  },

  /**
   * Получение настроек всех компонентов, находящихся на странице
   */
  getAllComponents: (state) => {
    return state.items;
  },

  /**
   * Получение УРЛ страницы
   */
  getPageUrl: (state) => {
    return state.pageUrl;
  },

  loadedItemIds: (state) => {
    return state.loadedItemIds;
  },

  [getComponentsList]: (state) => {
    return Object.values(state.items)
      .map((componentConfig) => {
        return {
          componentId: componentConfig.componentId,
          parentComponentId: componentConfig.parentComponentId,
          componentType: componentConfig.componentType,
          componentName: componentConfig.componentName,
          componentPos: componentConfig.componentPos
        };
      })
      .sort((item1, item2) => {
        return item1.componentPos - item2.componentPos;
      });
  },

  getDeviceType: (state) => {
    return devices.current().deviceType;
  },

  /**
   * Проверка нзменений в конструкторе
   * @param {Object} state
   * @returns {Boolean} true, если были изменения, иначе false
   */
  changed(state) {
    return state.changedComponents.length > 0 || state.addedComponents.length > 0 || state.deletedComponents.length > 0;
  },

  /**
   * Получение настроек событий-действий компонента
   */
  getEventAction: (state) => (componentId) => {
    return state.eventAction[componentId] || [];
  },

  dataSetComponents(state) {
    return Object.values(state.items)
      .filter((componentConfig) => {
        return DATASET_COMPONENT_TYPES.includes(componentConfig.componentType);
      })
      .map((dataSetConfig) => {
        const dataSetOptions = state.componentOptions[dataSetConfig.componentId];

        return {
          ...dataSetConfig,
          options: dataSetOptions,
          displayExpr: `${dataSetConfig.componentName} (${dataSetConfig.componentId})`
        };
      });
  },

  selectedComponents(state) {
    return state.selectedComponents || [];
  },

  getConstructorActiveComponentField: (state) => (fieldPath) => {
    return getField(state.constructorActiveComponentConfig, fieldPath);
  },

  [getConstructorActiveComponentConfig](state) {
    return state.constructorActiveComponentConfig;
  },

  getConstructorActiveComponentId(state) {
    return state.selectedComponents[state.selectedComponents.length - 1] || null;
  },
  selectedComponentId(state) {
    return state.selectedComponents[state.selectedComponents.length - 1] || null;
  },

  getPageLastChildPosition: (state) => (url) => {
    let children = state.pages[url].items;
    let pos = 0;
    if (children && children.length) {
      children.forEach((child) => {
        const itemConfig = state.items[child] || {};
        const itemPos = itemConfig.componentPos || 0;
        if (itemPos > pos) {
          pos = itemPos;
        }
      });
    }
    return pos;
  },

  lastItemPos: (state) => (componentId) => {
    const componentOptions = state.componentOptions[componentId] || {};
    const items = componentOptions.items || [];
    let maxPos = 0;

    items.forEach((itemId) => {
      const itemConfig = state.items[itemId] || {};
      const itemPos = itemConfig.componentPos || 0;

      if (itemPos > maxPos) {
        maxPos = itemPos;
      }
    });

    return maxPos;
  },

  getItem: (state) => (componentId) => {
    return state.items[componentId];
  },

  componentTypeId: (state, getters, rootState, rooGetters) => (componentId) => {
    if (!componentId || !state.items[componentId]) {
      return null;
    }
    const typeName = state.items[componentId].componentType;
    const componentType = rooGetters['uiConstructor/componentTypes'].find((type) => type.name === typeName);
    if (!componentType) {
      return null;
    }
    return componentType.id;
  },

  getComponentOptions: (state) => (componentId) => {
    return state.componentOptions[componentId];
  },

  getItemField: (state) => (componentId, fieldPath) => {
    const itemConfig = state.items[componentId] || {};
    return getField(itemConfig, fieldPath);
  },

  /**
   * Для получения значения параметра действия
   */
  getParamValue: (state) => (componentId, paramType) => {
    const itemConfig = state.items[componentId] || {};

    if (paramType === 'componentConfig') {
      const componentConfig = state.componentOptions[componentId] || {};
      return { ...componentConfig, componentId };
    }

    if (paramType === 'customData') {
      return state.customData[componentId] || {};
    }

    return itemConfig[paramType] !== undefined ? itemConfig[paramType] : null;
  },

  savingProcess(state) {
    return state.savingProcess;
  },

  getPageItemIds: (state) => (pageUrl) => {
    const pageStore = state.pages[pageUrl] || {};
    const itemIds = pageStore.items || [];
    return itemIds;
  },

  getStateField: (state) => (filedName) => {
    return getField(state, filedName);
  },

  getGisLayers(state) {
    return Object.values(state.items).filter((item) => {
      return item.componentType === 'GisLayer';
    });
  }
};

function getField(obj, fieldPath) {
  if (!obj) {
    return null;
  }

  return getPropByPath(
    obj,
    fieldPath.split(/\[|\]|\./).filter((item) => {
      return item !== '';
    })
  );
}

export default getters;
