import Vue from 'vue';
export const SET_PORTAL_CONFIG = 'SET_PORTAL_CONFIG';
export const SET_PORTAL_IS_LOADING = 'SET_PORTAL_IS_LOADING';

const mutations = {
  [SET_PORTAL_CONFIG](state, portalData) {
    Object.keys(portalData).forEach((key) => {
      if (state.hasOwnProperty(key)) {
        state[key] = portalData[key];
      } else {
        //Это нужно чтобы предусмотреть добавление дополнительных параметров
        //Возможно это костыль
        Vue.set(state, key, portalData[key]);
      }
    });
  },

  [SET_PORTAL_IS_LOADING](state, isPortalConfigLoading) {
    state.isPortalConfigLoading = isPortalConfigLoading;
  }
};

export default mutations;
