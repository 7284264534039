<template>
  <!--eslint-disable-->
  <div class="invisible-component-group">
    <h4 class="invisible-component-group__title">
      <i
        class="dx-icon"
        :class="TYPES_ICONS[groupName] || TYPES_ICONS.default"
      ></i>
      <span class="invisible-component-group__text">{{ groupName }}</span>
      <button
        class="invisible-component-group__toggle"
        @click="isOpen = !isOpen"
      >
        <i :class="`fas fa-angle-${isOpen ? `down` : `up`}`"></i>
      </button>
    </h4>
    <div
      class="invisible-component-group__list"
      :class="{ 'invisible-component-group__list_collapsed': !isOpen }"
    >
      <div
        class="invisible-component-group__item"
        :key="index"
        v-for="(item, index) in items"
        @click="itemClick($event, item.componentId)"
      >
        <ConstructorSelectedElement
          :key="index"
          :componentId="item.componentId"
          class="invisible-component-group__component"
        >
          <ConstructorElement
            :componentId="item.componentId"
            :componentType="item.componentType"
          />
        </ConstructorSelectedElement>
      </div>
    </div>
  </div>
</template>

<script>
import ConstructorElement from './ConstructorElement';
import ConstructorSelectedElement from './ConstructorSelectedElement';
const TYPES_ICONS = {
  DataSet: 'fas fa-database',
  ServerSideDataSource: 'fas fa-database',
  PivotGridDataSource: 'fas fa-database',
  VectorMapLayer: 'far fa-map',
  MapboxGroupLayer: 'fas fa-layer-group',
  MapboxLayer: 'fas fa-globe-europe',
  MockDataSet: 'far fa-file',
  TableauDataSet: 'fas fa-cube',
  Popup: 'far fa-window-restore',
  Popover: 'far fa-window-restore',
  ContextMenu: 'far fa-list-alt',
  default: 'fas fa-eye-slash'
};
export default {
  name: 'InvisibleComponentGroup',
  props: {
    items: Array,
    groupName: String
  },
  components: {
    ConstructorElement,
    ConstructorSelectedElement
  },
  data() {
    return {
      TYPES_ICONS,
      isOpen: true
    };
  },
  methods: {
    itemClick(event, componentId) {
      if (!event.target.closest('.mapbox-layer__toggle') && !event.target.closest('.simple-invisible-component-container__item')) {
        this.$store.dispatch('config/setConstructorActiveComponent', {
          componentId: componentId,
          keepSelected: false
        });
      }
    }
  },
  mounted() {
    this.$emit('initialized');
  },
  updated() {
    this.$emit('updated');
  }
};
</script>

<style lang="scss">
.invisible-component-group {
  &__title {
    margin: 0;
    padding: 10px 5px 5px;
    border-bottom: 2px solid #ddd;
    display: flex;
    align-items: center;
  }

  &__text {
    flex-grow: 1;
    margin: 0 5px;
  }

  &__toggle {
    border: none;
    background: transparent;
    cursor: pointer;

    &:focus {
      outline: none;
    }
  }

  &__list {
    overflow: hidden;

    &_collapsed {
      height: 0;
    }
  }

  &__item:hover {
    background: #f7f7f7;
  }

  &__component {
    padding: 5px;
  }
}
</style>
