<template>
  <!--eslint-disable-->
  <DxSortable
    filter=".component-tree-item__inner"
    :allowDropInsideItem="true"
    :allowReordering="true"
    @reorder="handleReorder"
    @dragStart="dragStart"
    height="100%"
  >
    <div class="components-tree">
      <div class="components-tree__toolbar">
        <DxToolbar>
          <DxToolbarItem
            widget="dxButton"
            :options="createButtonOptions"
            location="before"
            :disabled="!selectedComponentId && !pageMode"
          />
          <DxToolbarItem
            widget="dxButton"
            location="before"
            :options="deleteButtonOptions"
            :disabled="!selectedComponentId"
          />
          <DxToolbarItem
            widget="dxButton"
            :options="importSettingsButtonOptions"
            :disabled="!selectedComponentId"
            location="before"
          />
        </DxToolbar>
        <SearchBox
          class="components-tree__search"
          :dataList="Object.values(allComponents)"
          :searchExpr="['componentId', 'componentName', 'componentType']"
          @searching="onSearching"
        />
      </div>
      <div class="components-tree__tree">
        <DxScrollView ref="scrollView">
          <div>
            <ComponentTreeItem
              v-for="itemConfig in itemsBySearch"
              :componentConfig="itemConfig"
              :key="itemConfig.componentId"
              :parents="[]"
              :dxScrollView="$refs.scrollView.instance"
              :pageComponents="componentsBySearch"
            />
          </div>
        </DxScrollView>
      </div>
      <PopupNewComponentForm
        :visible="newComponentPopupVisible"
        @hidden="newComponentPopupVisible = false"
        @shown="newComponentPopupVisible = true"
      />
      <PopupImportSettingsForm
        v-if="importSettingsPopupVisible"
        @hidden="importSettingsPopupVisible = false"
        @shown="importSettingsPopupVisible = true"
      />
    </div>
  </DxSortable>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import DxButton from 'devextreme-vue/button';
import { DxToolbar, DxItem as DxToolbarItem } from 'devextreme-vue/toolbar';
import PopupNewComponentForm from './PopupNewComponentForm';
import PopupImportSettingsForm from './PopupImportSettingsForm';
import DxScrollView from 'devextreme-vue/scroll-view';
import DxSortable from 'devextreme-vue/sortable';
import ComponentTreeItem from './ComponentTreeItem';
import SearchBox from '@/components/SearchBox';
import { confirmDeleteComponents } from './Utility';
import { updateComponent, deleteComponents } from '@/store/modules/config/actions';
import { DRAG_RESIZE_CONTAINERS, INVISIBLE_COMPONENTS } from '@/utils/const';
import { getOptions } from '@/store/modules/config/components/getters';

const TREE_ITEM_SELECTOR = '.component-tree-item';

export default {
  components: {
    DxButton, // eslint-disable-line
    DxScrollView,
    DxSortable,
    DxToolbar,
    DxToolbarItem,
    PopupNewComponentForm,
    PopupImportSettingsForm,
    ComponentTreeItem,
    SearchBox
  },
  data() {
    return {
      createButtonOptions: {
        icon: 'plus',
        type: 'default',
        hint: 'Добавить компонент',
        onClick: () => {
          this.$store.dispatch('uiConstructor/setNewComponentField', {
            fieldName: 'parentId',
            fieldValue: this.selectedComponentId
          });
          this.newComponentPopupVisible = true;
        }
      },
      importSettingsButtonOptions: {
        icon: 'export',
        type: 'normal',
        text: 'Импорт',
        hint: 'Импортировать настройки из файла',
        onClick: () => {
          this.$store.dispatch('uiConstructor/setImportedSettingField', {
            fieldName: 'componentId',
            fieldValue: this.selectedComponentId
          });
          this.importSettingsPopupVisible = true;
        }
      },
      deleteButtonOptions: {
        icon: 'trash',
        type: 'danger',
        hint: 'Удалить все выбранные компоненты',
        onClick: () => {
          confirmDeleteComponents().then(() => this.deleteComponentsClick());
        }
      },
      newComponentPopupVisible: false,
      importSettingsPopupVisible: false,
      templatePopupVisible: false,
      componentsBySearch: {},
      itemsBySearch: []
    };
  },
  computed: {
    ...mapGetters('config', {
      getOptions
    }),
    pageMode() {
      return !!this.$route.params.childUrl;
    },
    url() {
      return this.$route.params.childUrl || this.$route.params.componentId;
    },
    items() {
      return this.$store.getters['config/getPageItemIds'](this.url)
        .filter((itemId) => {
          //Эта фильтрация нужна, чтобы в конструктор не попадали дозагруженные элементы
          return !this.$store.getters['config/loadedItemIds'].includes(itemId);
        })
        .map((itemId) => {
          return this.$store.getters['config/getItem'](itemId);
        })
        .sort((item1, item2) => {
          return (item1.componentPos || 0) - (item2.componentPos || 0);
        });
    },
    allComponents() {
      return this.$store.getters['config/getAllComponents'];
    },
    selectedComponentId() {
      return this.$store.getters['config/selectedComponentId'];
    },
    selectedComponents() {
      return this.$store.getters['config/selectedComponents'];
    }
  },
  watch: {
    items: {
      handler() {
        this.itemsBySearch = this.items;
      },
      immediate: true
    },
    allComponents: {
      handler() {
        this.componentsBySearch = this.allComponents;
      },
      immediate: true
    }
  },
  methods: {
    ...mapActions('config', {
      updateComponent,
      deleteComponents
    }),

    onSearching(list) {
      this.componentsBySearch = {};
      list.forEach((item) => {
        this.expandItem(item);
      });
      this.itemsBySearch = this.items.filter((item) => !!this.componentsBySearch[item.componentId]);
    },
    expandItem(item) {
      if (!item) {
        return;
      }

      this.componentsBySearch[item.componentId] = item;
      this.$store.dispatch('config/setConstructorItemFields', {
        componentId: item.componentId,
        fields: [
          {
            name: 'expanded',
            value: true
          }
        ]
      });
      if (item.parentComponentId && !this.componentsBySearch[item.parentComponentId]) {
        this.expandItem(this.allComponents[item.parentComponentId]);
      }
    },
    handleReorder(eventData) {
      const fromIndex = eventData.fromIndex;
      const toIndex = eventData.toIndex;
      const nodeList = eventData.component.element().querySelectorAll(TREE_ITEM_SELECTOR);
      const dropInsideItem = eventData.dropInsideItem;

      //Данные перетаскиваемого элемента
      const fromElement = nodeList[fromIndex];
      const componentId = fromElement.dataset.componentId;
      const parentComponentId = fromElement.dataset.parentComponentId;

      //В режиме показа компонента запрещено менять родителя корневого компонента
      if (!this.pageMode && componentId === this.$route.params.componentId) {
        return;
      }
      //Данные элемента в котором размещаем
      const toElement = nodeList[toIndex];

      const parents = toElement.dataset.parents.split(',');
      if (parents.includes(componentId)) {
        return;
      }

      let actionPayload = {
        componentId,
        oldParentId: parentComponentId,
        updateComponent: true
      };
      if (dropInsideItem) {
        //Задать родителя
        actionPayload.newParentId = toElement.dataset.componentId || null;
      } else {
        //Задать родителя и позицию
        const toPos = parseInt(toElement.dataset.componentPos);
        if (fromIndex < toIndex) {
          if (toElement === toElement.parentElement.lastChild) {
            actionPayload.componentPos = toPos + 100;
          } else if (toElement.nextSibling) {
            const nextElement = toElement.nextSibling;
            const nextPos = parseInt(nextElement.dataset.componentPos);
            actionPayload.componentPos = Math.round((toPos + nextPos) / 2);
          }
        } else {
          if (toElement === toElement.parentElement.firstChild) {
            actionPayload.componentPos = toPos - 100;
          } else if (toElement.previousSibling) {
            const prevElement = toElement.previousSibling;
            const prevPos = parseInt(prevElement.dataset.componentPos);
            actionPayload.componentPos = Math.round((toPos + prevPos) / 2);
          }
        }
        actionPayload.newParentId = toElement.dataset.parentComponentId || null;
      }

      //В режиме показа компонента запрещено переносить компоненты в корень
      if (!this.pageMode && !actionPayload.newParentId) {
        return;
      }

      let fieldsForSet = null;
      if (toElement.dataset.fieldsForSet) {
        try {
          fieldsForSet = JSON.parse(toElement.dataset.fieldsForSet);
        } catch (err) {
          window.console.warn('Неправильно передается fieldsForSet в ComponentTreeItem');
        }
      }

      if (actionPayload) {
        const url = this.$route.params.childUrl;
        if (!actionPayload.oldParentId) {
          actionPayload.oldUrl = url;
        }
        if (!actionPayload.newParentId) {
          actionPayload.newUrl = url;

          //Если компонент перемещаем в корень, то надо установить устройство
          actionPayload.device = this.$store.getters['uiConstructor/resolutionDevice'];
        }

        if (actionPayload.newParentId) {
          const parentComponentType = this.$store.getters['config/getComponentType'](actionPayload.newParentId) || '';
          const componentType = this.$store.getters['config/getComponentType'](componentId) || '';

          if (
            !INVISIBLE_COMPONENTS.includes(componentType) &&
            DRAG_RESIZE_CONTAINERS.includes(parentComponentType) &&
            this.getOptions(actionPayload.newParentId).dragResize
          ) {
            actionPayload.updateComponent = false; //Блокируем повторный запрос на сохранинение
            actionPayload.drWidth = '';
            actionPayload.drHeight = '';

            if (!Array.isArray(fieldsForSet)) {
              fieldsForSet = [];
            }

            //Сбрасываем drWidth и drHeight, чтобы вызвать сохранение в DragResize,
            //если у компонента устанавливались значения в прежднем DragResize-родителе
            fieldsForSet.push({ name: 'drWidth', value: '' });
            fieldsForSet.push({ name: 'drHeight', value: '' });
          }
        }

        this.$store
          .dispatch('config/setComponentParent', actionPayload)
          .then(() => {
            if (Array.isArray(fieldsForSet)) {
              return this.$store.dispatch('config/setConstructorItemFields', {
                componentId,
                fields: fieldsForSet
              });
            }

            return Promise.resolve();
          })
          .then(() => {
            let componentConfigChanges = {
              componentPos: actionPayload.componentPos
            };

            if (actionPayload.newUrl) {
              componentConfigChanges.url = actionPayload.newUrl;
              componentConfigChanges.parentComponentId = null;
            }
            if (actionPayload.newParentId) {
              if (!actionPayload.updateComponent) {
                return;
              }

              componentConfigChanges.parentComponentId = actionPayload.newParentId;
            }

            /*this.updateComponent({
              componentId,
              url,
              componentConfigChanges,
              componentOptionChanges: {}
            });*/
            //Не работает вызов действия через mapActions
            this.$store.dispatch('config/updateComponent', {
              componentId,
              url,
              componentConfigChanges,
              componentOptionChanges: {}
            });
          });
      }
    },
    dragStart(eventData) {
      const nodeList = eventData.component.element().querySelectorAll(TREE_ITEM_SELECTOR);
      const fromIndex = eventData.fromIndex;
      const fromElement = nodeList[fromIndex];

      if (fromElement && fromElement.dataset && fromElement.dataset.fixed === 'fixed') {
        eventData.cancel = true;
      }
    },
    deleteComponentsClick() {
      if (!this.selectedComponentId) {
        return;
      }

      //TODO: Индикация загрузки, так как удаление может занять время
      /*this.deleteComponents({
        componentsId: this.selectedComponents
      }).then(() => {
        this.$emit('hidden');
      });*/
      //Не работает вызов действия через mapActions
      this.$store
        .dispatch('config/deleteComponents', {
          componentsId: this.selectedComponents
        })
        .then(() => {
          this.$emit('hidden');
        });
    }
  }
};
</script>

<style lang="scss">
.components-tree {
  display: flex;
  flex-direction: column;
  min-height: 0;
  height: 100%;

  &__toolbar {
    flex: 0 0 auto;
    margin-bottom: 5px;
    display: flex;

    .dx-toolbar {
      background-color: #f7f7f7;
    }
  }

  &__search {
    flex-basis: 100%;
  }

  &__tree {
    flex: 1 1 auto;
    min-height: 0;
  }
}
</style>
