import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

const defaultLocale = process.env.VUE_APP_I18N_LOCALE || 'ru';

const i18n = new VueI18n({
  silentTranslationWarn: true,
  locale: defaultLocale,
  fallbackLocale: defaultLocale,
  messages: {
    [defaultLocale]: require(`./locales/${defaultLocale}.json`)
  }
});

export default i18n;

let loadedLocales = [defaultLocale]; // список локализаций, которые пред-загружены

/**
 * Функция асинхронной загрузки и установки нужного перевода
 * @param {String} locale
 * @returns Promise
 */
export function loadLanguageAsync(locale) {
  // Если локализация та же
  if (i18n.locale === locale) {
    return Promise.resolve(setI18nLanguage(locale));
  }

  // Если локализация уже была загружена
  if (loadedLocales.includes(locale)) {
    return Promise.resolve(setI18nLanguage(locale));
  }

  // Если локализация ещё не была загружена
  return import(/* webpackChunkName: "lang-[request]" */ `./locales/${locale}.json`).then((messages) => {
    i18n.setLocaleMessage(locale, messages.default);
    loadedLocales.push(locale);
    return setI18nLanguage(locale);
  });
}

/**
 * Функция установки нужного языка
 * @param {String} locale
 * @returns
 */
function setI18nLanguage(locale) {
  i18n.locale = locale;
  document.querySelector('html').setAttribute('lang', locale);
  return locale;
}
