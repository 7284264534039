//import { NO_PROXY_HOSTS } from './const';
import { SCANEX_HOSTS } from './const';

export function getWmsLegendGraphicUrl(layerOptions, rule, layerId) {
  const url = layerOptions.wmsUrl || '';
  const isScanex = SCANEX_HOSTS.some((scanexHost) => {
    return url && url.indexOf(scanexHost) === 0;
  });

  //Для сканекса специальная картинка
  if (isScanex) {
    return require('@/assets/geomix.png');
  }

  const format = 'image/png';
  const transparent = true;
  //Дополнительный параметр для обновления иконки без перезагрузки страницы
  const time = +new Date();
  const legend_options = {
    fontName: 'Helvetica Neue',
    fontAntiAliasing: true,
    fontColor: '0x072833',
    fontSize: 11,
    bgColor: '0xffffff',
    dpi: 90,
    wrap: true
  };

  const params = {
    format,
    transparent,
    layer: layerId || layerOptions.layers || '',
    time,
    legend_options: Object.entries(legend_options)
      .map(([optionName, optionValue]) => {
        return `${optionName}:${encodeURIComponent(optionValue)}`;
      })
      .join(';'),
    version: '1.0.0'
  };

  if (rule) {
    params.rule = rule;
  }

  const paramsStr = Object.entries(params)
    .map(([paramName, paramValue]) => {
      return `${paramName}=${paramValue}`;
    })
    .join('&');

  //Костыль, если в строке уже есть параметры
  let delimiter = '?';
  if (url.indexOf('?') !== -1) {
    delimiter = '&';
  }

  const urlStr = `${url}${delimiter}service=WMS&request=GetLegendGraphic&${paramsStr}`;
  let proxyUrl = urlStr;
  //if (NO_PROXY_HOSTS.every((host) => !urlStr.includes(host))) {
  //proxyUrl = `${process.env.VUE_APP_PROXY_SERVICE}?url=${urlStr}`;
  //}
  return proxyUrl;
}
