const DEFAULT_KEY_EXPR = 'id';

export default function DatasetFactory(componentConfig) {
  const options = componentConfig.options || {};

  return {
    dataSetName: options.dataSetName || null,
    keyExpr: options.keyExpr || DEFAULT_KEY_EXPR,
    defaultParam: options.defaultParam || {},
    selectedData: null,
    loadedData: null,
    dataFields: [],
    isRefreshing: false,
    /**Список фильтров, связанных с датасетом */
    filters: [],
    /**Фильтр заданный в настройках датасета */
    filterId: options.filterId || null,
    /**Контроллер для прерывания обновления датасета */
    refreshAbortController: null,
    /**Функция преобразования данных */
    customizeData: options.customizeData || null,
    /**Функция преобразования элементов данных */
    customizeDataItem: options.customizeDataItem || null,
    /**Функция сортировки данных */
    sortFn: options.sortFn || null
  };
}
