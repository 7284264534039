import actions from './actions';
import getters from './getters';
import mutations from './mutations';

export default {
  namespaced: true,
  state: {
    /**Информация о выбранном кубе и рабочей области */
    cubeInterface: {},

    /**Кубы CONFIG.DSCUBE */
    cubes: [],

    /**CONFIG.DSWORKSPACE */
    workspaces: [],

    /**Измерения CONFIG.DSFIELDCUBE place_code === 'dimension' */
    dimensions: [],

    /**Меры CONFIG.DSFIELDCUBE place_code === 'measure' */
    measures: [],

    /**строка для поиска dimensions и measures */
    searchString: '',

    /**Выбранный тип диаграммы */
    selectedElementType: null,

    /**Список доступных элементов */
    elementTypes: [],

    /**Список доступных мест для полей для выбранного типа элемента */
    places: []
  },
  getters,
  mutations,
  actions
};
