<template>
  <!--eslint-disable-->
  <DxPopup
    width="50vw"
    height="auto"
    popupMaxHeight="80vh"
    :animation="null"
    :visible="visible"
    :shading="false"
    :resizeEnabled="true"
    :minWidth="600"
    :toolbarItems="toolbarItems"
    @shown="$emit('shown')"
  >
    <template #titleTemplate>
      <PopupTitleTemplate
        text="Добавление нового типа компонента"
        :showCloseButton="true"
        @closeClick="close"
      />
    </template>
    <template #contentTemplate>
      <div class="popup-new-component-type-form-content">
        <div class="dx-field">
          <div class="dx-field-label">Название</div>
          <div class="dx-field-value">
            <DxTextBox v-model="name"></DxTextBox>
          </div>
        </div>
        <div class="dx-field">
          <div class="dx-field-label">Описание</div>
          <div class="dx-field-value">
            <DxTextBox v-model="comments"></DxTextBox>
          </div>
        </div>
      </div>
    </template>
  </DxPopup>
</template>

<script>
import DxPopup from 'devextreme-vue/popup';
import PopupTitleTemplate from '../Containers/PopupTitleTemplate';
import DxTextBox from 'devextreme-vue/text-box';
import { custom } from 'devextreme/ui/dialog';

export default {
  components: {
    DxPopup,
    DxTextBox,
    PopupTitleTemplate
  },
  props: {
    visible: Boolean
  },
  data() {
    return {
      name: '',
      comments: '',
      toolbarItems: [
        {
          toolbar: 'bottom',
          location: 'center',
          widget: 'dxButton',
          options: {
            text: 'Добавить',
            type: 'success',
            onClick: () => {
              this.checkFieldsValue();
            }
          }
        },
        {
          toolbar: 'bottom',
          location: 'center',
          widget: 'dxButton',
          options: {
            text: 'Отменить',
            onClick: () => {
              this.close();
            }
          }
        }
      ]
    };
  },
  methods: {
    checkFieldsValue() {
      if (!this.name || !this.comments) {
        let warningMessage =
          'Введите значение ' + (!this.name && !this.comments ? 'полей Название и Описание' : !this.name ? 'поля Название' : 'поля Описание');
        let warningDialog = custom({
          showTitle: false,
          messageHtml: '<div style="font-size:16px;">' + warningMessage + '</div>',
          buttons: [
            {
              text: ' Закрыть'
            }
          ]
        });
        warningDialog.show();
      } else {
        this.apply();
      }
    },
    apply() {
      this.$store.dispatch('uiConstructor/saveNewComponentType', {
        name: this.name,
        comments: this.comments
      });
      this.close();
    },
    close() {
      this.name = '';
      this.comments = '';
      this.$emit('hidden');
    }
  }
};
</script>

<style lang="scss">
.popup-new-component-type-form-content {
  display: flex;
  flex-direction: column;

  .dx-field {
    display: flex;
    flex: 0 0 auto;

    & > .dx-field-label {
      float: none;
      width: auto;
      flex: 0 0 80px;
    }
    & > .dx-field-value {
      float: none;
      width: auto;
      flex: 1 1 auto;

      & > .dx-checkbox {
        float: none;
      }
    }
  }
}
</style>
