import getConfigs from './configUtils';
import { HTML_EDITOR_DEFAULT_ITEMS } from '@/utils/const';

const OPTIONS = ['content'];

const CONFIGS_FIELDS = {
  content: {
    name: 'content',
    label: 'Содержимое',
    component: 'DxHtmlEditor',
    options: {
      toolbar: {
        multiline: true,
        items: HTML_EDITOR_DEFAULT_ITEMS
      },
      height: 300
    },
    changeEvent: 'valueChanged'
  }
};

export default getConfigs(OPTIONS, CONFIGS_FIELDS);
