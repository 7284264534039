<template>
  <!--eslint-disable-->
  <div class="options-accordion">
    <DxAccordion
      height="100%"
      :width="355"
      :multiple="true"
      :collapsible="true"
      :items="accordionItems"
      keyExpr="id"
      :selectedItemKeys="accordionSelectedItemKeys"
      :focusStateEnabled="false"
      :deferRendering="false"
    >
      <template #componentsTree>
        <div>
          <ComponentsTree />
        </div>
      </template>
      <template #componentTabPanel>
        <div class="form-wrapper">
          <DxToolbar class="toolbar">
            <DxToolbarItem
              widget="dxDropDownButton"
              location="before"
              :options="createTemplateDropDownOptions"
            />
            <DxToolbarItem
              v-if="!visibleComponentSelected"
              widget="dxButton"
              location="before"
              :disabled="!selectedComponentId"
              :options="componentSettingsButtonOptions"
            />
            <DxToolbarItem
              v-else-if="validatedComponentSelected"
              widget="dxDropDownButton"
              location="before"
              :options="componentValidatedSettingsDropDownOptions"
            />
            <DxToolbarItem
              v-else
              widget="dxDropDownButton"
              location="before"
              :options="componentSettingsDropDownOptions"
            />
            <DxToolbarItem
              widget="dxButton"
              location="after"
              :options="deleteButtonOptions"
              :disabled="!selectedComponentId"
            />
          </DxToolbar>
          <PopupCopyComponentForm
            :visible="copyComponentPopupVisible"
            @hidden="copyComponentPopupVisible = false"
            @shown="copyComponentPopupVisible = true"
          />
          <DxTabPanel
            class="component-form-wrapper"
            :items="tabPanelItems"
            :deferRendering="false"
            :focusStateEnabled="false"
          >
            <template #componentForm>
              <DxScrollView class="options-accordion__tab-item-form">
                <ComponentForm />
              </DxScrollView>
            </template>
            <template #componentEventAction>
              <EventActionGrid />
            </template>
            <template
              #componentAccessRole
              class="access-form"
            >
              <DxScrollView class="options-accordion__tab-item-form">
                <AccessFormTemplate
                  class="access-form__grid"
                  :creatorId="null"
                  :componentId="selectedComponentId"
                  :componentTypeId="selectedComponentTypeId"
                  :creatorVisible="false"
                />
              </DxScrollView>
            </template>
          </DxTabPanel>
          <PopupTemplateForm
            :visible="templatePopupVisible"
            @hidden="templatePopupVisible = false"
            @shown="templatePopupVisible = true"
          />
        </div>
      </template>
    </DxAccordion>
    <PopupUnitedForm
      :visible="popupVisble.unitedForm"
      :componentConfig="componentConfig"
      :currentTub="currentTub"
      :title="`Свойства компонента ${componentConfig.componentName} (${componentConfig.componentType} ${componentConfig.componentId})`"
      @hidden="popupVisble.unitedForm = false"
      @shown="popupVisble.unitedForm = true"
      @apply="applyUnitedOptions($event)"
      @applyAndHidden="applyAndHiddenUnitedOptions($event)"
    />
    <PopupMonacoEditor
      :visible="popupVisble.labelCssStyle"
      :value="labelCssStyle"
      title="Стиль подписи"
      @hidden="popupVisble.labelCssStyle = false"
      @shown="popupVisble.labelCssStyle = true"
      @apply="applyLabelCssStyle($event)"
    />
    <PopupMonacoEditor
      :visible="popupVisble.optionsValidator"
      :value="optionsValidator"
      title="Проверка ввода"
      @hidden="popupVisble.optionsValidator = false"
      @shown="popupVisble.optionsValidator = true"
      @apply="applyOptionsValidator($event)"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { getConstructorActiveComponentConfig } from '@/store/modules/config/getters';
import { SET_CONSTRUCTOR_ACTIVE_COMPONENT_FIELD, SET_CONSTRUCTOR_ACTIVE_COMPONENT_OPTIONS, SET_COMPONENT_OPTIONS } from '@/store/modules/config/mutations';
import { saveConstructorActiveComponent, deleteComponents } from '@/store/modules/config/actions';
import { getOptions } from '@/store/modules/config/components/getters';
import { SET_OPTION_BY_PATH, SET_CONFIG_FIELD_BY_PATH } from '@/store/modules/config/components/mutations';
import DxAccordion from 'devextreme-vue/accordion';
import DxScrollView from 'devextreme-vue/scroll-view';
import DxTabPanel from 'devextreme-vue/tab-panel';
import { custom } from 'devextreme/ui/dialog';
import { DxToolbar, DxItem as DxToolbarItem } from 'devextreme-vue/toolbar';
import ComponentForm from './ComponentForm';
import EventActionGrid from './EventActionGrid';
import PopupTemplateForm from './PopupTemplateForm';
import ComponentsTree from './ComponentsTree';
import PopupMonacoEditor from './PopupMonacoEditor';
import PopupCopyComponentForm from './PopupCopyComponentForm';
import { INVISIBLE_COMPONENTS, VALIDATED_COMPONENT_TYPES } from '../../utils/const';
import stringifyObject from 'stringify-object';
import PopupUnitedForm from './PopupUnitedForm';
import AccessFormTemplate from '@/components/AccessFormTemplate';
import DxButton from 'devextreme-vue/button';
import DxDropDownButton from 'devextreme-vue/drop-down-button';

const settingsDropDownButtons = [
  { value: 'cssStyle', text: 'Положение компонента' },
  { value: 'optionsCssStyle', text: 'Стиль компонента' },
  { value: 'labelCssStyle', text: 'Стиль подписи' }
];

const createDropDownButtons = [
  { value: 'cloneComponent', text: 'Клонировать компонент' },
  { value: 'fullCopyComponent', text: 'Полная копия компонента' }
];
const ACCORDION_ITEMS = [
  {
    id: 1,
    title: 'Иерархия компонентов',
    template: 'componentsTree',
    selected: true
  },
  {
    id: 2,
    title: 'Инспектор',
    template: 'componentTabPanel',
    selected: true
  }
];
export default {
  components: {
    AccessFormTemplate,
    PopupUnitedForm,
    DxAccordion,
    DxScrollView,
    DxTabPanel,
    DxToolbar,
    DxToolbarItem,
    DxButton, // eslint-disable-line
    DxDropDownButton, // eslint-disable-line
    ComponentForm,
    EventActionGrid,
    PopupTemplateForm,
    ComponentsTree,
    PopupMonacoEditor,
    PopupCopyComponentForm
  },

  data() {
    let componentSettingsDropDownOptions = {
      text: 'Настройки компонента',
      items: settingsDropDownButtons,
      splitButton: true,
      useSelectMode: false,
      stylingMode: 'contained',
      displayExpr: 'text',
      keyExpr: 'value',
      onButtonClick: () => {
        this.popupVisble.unitedForm = true;
        this.currentTub = 'options';
      },
      onItemClick: (e) => {
        if (e.itemData.value === 'labelCssStyle') {
          this.popupVisble.labelCssStyle = true;
        } else if (e.itemData.value === 'optionsValidator') {
          this.popupVisble.optionsValidator = true;
        } else {
          this.popupVisble.unitedForm = true;
          this.currentTub = e.itemData.value;
        }
      }
    };
    return {
      popupVisble: {
        labelCssStyle: false,
        optionsValidator: false,
        unitedForm: false
      },
      accordionItems: ACCORDION_ITEMS,
      accordionSelectedItemKeys: [1, 2],
      tabPanelItems: [
        {
          title: 'Свойства',
          template: 'componentForm'
        },
        {
          title: 'События',
          template: 'componentEventAction'
        },
        {
          title: 'Доступ',
          template: 'componentAccessRole'
        }
      ],
      createTemplateButtonOptions: {
        text: 'Создать шаблон',
        onClick: () => {
          this.$store.dispatch('uiConstructor/setNewComponentTemplateField', {
            fieldName: 'componentId',
            fieldValue: this.selectedComponentId
          });
          this.templatePopupVisible = true;
        }
      },
      createTemplateDropDownOptions: {
        text: 'Создать шаблон',
        items: createDropDownButtons,
        splitButton: true,
        useSelectMode: false,
        stylingMode: 'contained',
        displayExpr: 'text',
        keyExpr: 'value',
        onButtonClick: () => {
          this.$store.dispatch('uiConstructor/setNewComponentTemplateField', {
            fieldName: 'componentId',
            fieldValue: this.selectedComponentId
          });
          this.templatePopupVisible = true;
        },
        onItemClick: (e) => {
          const componentId = this.selectedComponentId;
          if (!componentId) {
            return;
          }
          switch (e.itemData.value) {
            case 'cloneComponent':
              this.$store.dispatch('config/cloneComponent', { device: this.$store.getters['uiConstructor/resolutionDevice'] }).then((componentId) => {
                this.$store.dispatch('config/setConstructorActiveComponent', {
                  componentId,
                  keepSelected: false
                });
              });
              break;
            case 'fullCopyComponent':
              this.$store.dispatch('uiConstructor/setCopyComponentField', {
                fieldName: 'componentId',
                fieldValue: this.selectedComponentId
              });
              this.copyComponentPopupVisible = true;
              break;
          }
        }
      },
      componentSettingsButtonOptions: {
        text: 'Настройки компонента',
        onClick: () => {
          this.popupVisble.unitedForm = true;
          this.currentTub = 'options';
        }
      },
      componentSettingsDropDownOptions: componentSettingsDropDownOptions,
      componentValidatedSettingsDropDownOptions: Object.assign({}, componentSettingsDropDownOptions, {
        items: settingsDropDownButtons.concat([{ value: 'optionsValidator', text: 'Проверка ввода' }])
      }),
      deleteButtonOptions: {
        icon: 'trash',
        type: 'danger',
        hint: 'Удалить выбранный компонент',
        onClick: () => {
          let deleteConfirmDialog = custom({
            showTitle: false,
            messageHtml: '<div style="font-size:16px;">Удалить выбранный компонент?</div>',
            buttons: [
              {
                text: 'Да',
                type: 'danger',
                onClick: () => {
                  this.deleteComponent();
                }
              },
              {
                text: 'Нет'
              }
            ]
          });
          deleteConfirmDialog.show();
        }
      },
      pageStyleEditorOptions: {
        automaticLayout: true,
        language: 'json',
        minimap: {
          enabled: false
        }
      },
      applyPageStyleTimeout: null,
      templatePopupVisible: false,
      copyComponentPopupVisible: false,
      currentTub: null
    };
  },

  computed: {
    ...mapGetters('config', {
      /**Получение выбранного в конструкторе конфига компонента, который был загружен отдельно */
      getConstructorActiveComponentConfig,

      /**Получение настроек компонента по идентификатору компонента */
      getComponentOptions: getOptions
    }),

    selectedComponentId() {
      return this.$store.getters['config/selectedComponentId'];
    },

    componentConfig() {
      return this.getConstructorActiveComponentConfig || {};
    },

    validatedComponentSelected() {
      return this.selectedComponentId && VALIDATED_COMPONENT_TYPES.includes(this.componentConfig.componentType);
    },

    visibleComponentSelected() {
      return this.selectedComponentId && !INVISIBLE_COMPONENTS.includes(this.componentConfig.componentType);
    },

    labelCssStyle() {
      return `(${stringifyObject({ ...this.componentConfig?.label?.style })})`;
    },

    optionsValidator() {
      return `(${stringifyObject(this.componentConfig?.options?.validator ? this.functionToString(this.componentConfig.options.validator) : {})})`;
    },

    componentType() {
      return this.componentConfig.componentType;
    },

    selectedComponentTypeId() {
      return this.$store.getters['config/componentTypeId'](this.selectedComponentId);
    },

    pageUrl() {
      return this.$route.params.childUrl;
    },

    pageStyle() {
      const url = this.pageUrl;
      if (url && this.$store.state.config.pages[url]) {
        const cssStyle = this.$store.state.config.pages[url].cssStyle || {};
        return JSON.stringify(cssStyle, null, 2);
      }
      return '{}';
    }
  },

  methods: {
    ...mapActions('config', {
      /**Вызов сохранения настроек компонента */
      saveConstructorActiveComponent,

      /**Удаление компонентов */
      deleteComponents
    }),

    ...mapMutations('config', {
      /**Установка поля для конфига активного компонента в конструкторе */
      setConstructorActiveComponentField: SET_CONSTRUCTOR_ACTIVE_COMPONENT_FIELD,

      /**Установка настроек для конфига активного компонента в конструкторе */
      setConstructorActiveComponentOptions: SET_CONSTRUCTOR_ACTIVE_COMPONENT_OPTIONS,

      /**Установка текущих настроек компонента, чтобы они применились к компоненту */
      setComponentOptions: SET_COMPONENT_OPTIONS,

      /**Установка настройки компонента */
      setComponentOptionsByPath: SET_OPTION_BY_PATH,
      /**Установка поля конфига компонента */
      setComponentConfigFieldByPath: SET_CONFIG_FIELD_BY_PATH
    }),

    deleteComponent() {
      if (!this.selectedComponentId) {
        return;
      }

      //TODO: Индикация загрузки, так как удаление может занять время
      /*this.deleteComponents({
        componentsId: [this.selectedComponentId]
      }).then(() => {
        this.$emit('hidden');
      });*/
      //Не работает вызов действия через mapActions
      return this.$store
        .dispatch('config/deleteComponents', {
          componentsId: [this.selectedComponentId]
        })
        .then(() => {
          this.$emit('hidden');
        });
    },

    setConstructorItemField(fieldName, fieldValue) {
      return this.$store.dispatch('config/setConstructorItemFields', {
        componentId: this.selectedComponentId,
        fields: [
          {
            name: fieldName,
            value: fieldValue
          }
        ]
      });
    },

    /**
     * Сохранение настроек компонентов из общей формы
     */
    applyUnitedOptions({ options, cssStyle, optionsCssStyle }) {
      //Применение стилей компонента
      //1. Применение для активного конфига для сохранения
      this.setConstructorActiveComponentField({
        fieldName: 'options.cssStyle',
        fieldValue: optionsCssStyle
      });
      //2. Применение для автоматического отображения
      this.setComponentOptionsByPath({
        componentId: this.componentConfig.componentId,
        optionName: 'cssStyle',
        optionValue: optionsCssStyle
      });

      //Применение стилей "Положения"
      //1. Применение для активного конфига для сохранения
      this.setConstructorActiveComponentField({
        fieldName: 'cssStyle',
        fieldValue: cssStyle
      });
      //2. Применение для автоматического отображения
      this.setComponentConfigFieldByPath({
        componentId: this.componentConfig.componentId,
        fieldName: 'cssStyle',
        fieldValue: cssStyle
      });

      //Применение настроек к автивному компоненту конструктора для сохранения
      this.setConstructorActiveComponentOptions({
        options
      });

      //Применение настроек к текущему компоненту, чтобы компонент изменился
      this.setComponentOptions({
        componentId: this.componentConfig.componentId,
        options
      });

      //Сохранение
      const url = this.$route.params.childUrl;
      //return this.saveConstructorActiveComponent({ url });
      //Не работает вызов действия через mapActions
      return this.$store.dispatch('config/saveConstructorActiveComponent', {
        url
      });
    },

    applyAndHiddenUnitedOptions(unitedOptions) {
      this.applyUnitedOptions(unitedOptions);
      this.popupVisble.unitedForm = false;
    },

    /**
     * Применение стилей подписи
     */
    applyLabelCssStyle(labelCssStyleString) {
      const labelCssStyle = labelCssStyleString || 'null';

      //Применение стилей подписи
      //1. Применение для активного конфига для сохранения
      this.setConstructorActiveComponentField({
        fieldName: 'label.style',
        fieldValue: eval(labelCssStyle)
      });
      //2. Применение для автоматического отображения
      this.setComponentConfigFieldByPath({
        componentId: this.componentConfig.componentId,
        fieldName: 'label.style',
        fieldValue: eval(labelCssStyle)
      });

      //Сохранение
      const url = this.$route.params.childUrl;
      /*return this.saveConstructorActiveComponent({ url }).then(() => {
        this.popupVisble.labelCssStyle = false;
      });*/
      //Не работает вызов действия через mapActions
      return this.$store
        .dispatch('config/saveConstructorActiveComponent', {
          url
        })
        .then(() => {
          this.popupVisble.labelCssStyle = false;
        });
    },

    /**
     * Применение настроек валидации
     */
    applyOptionsValidator(optionsValidatorString) {
      const optionsValidator = optionsValidatorString || 'null';

      //Применение настроек валидации компонента
      //1. Применение для активного конфига для сохранения
      this.setConstructorActiveComponentField({
        fieldName: 'options.validator',
        fieldValue: eval(optionsValidator)
      });
      //2. Применение для автоматического отображения
      this.setComponentOptionsByPath({
        componentId: this.componentConfig.componentId,
        optionName: 'validator',
        optionValue: eval(optionsValidator)
      });

      //Сохранение
      const url = this.$route.params.childUrl;
      /*return this.saveConstructorActiveComponent({ url }).then(() => {
        this.popupVisble.optionsValidator = false;
      });*/
      //Не работает вызов действия через mapActions
      return this.$store
        .dispatch('config/saveConstructorActiveComponent', {
          url
        })
        .then(() => {
          this.popupVisble.optionsValidator = false;
        });
    },

    functionToString(options) {
      for (let key in options) {
        if (typeof options[key] === 'object') {
          this.functionToString(options[key]);
        } else if (typeof options[key] === 'string' && options[key].indexOf('*@*') === 0) {
          options[key] = options[key].slice(3, options[key].length).replace('@)', ')').replace(/@{2,}/g, '@').replace(/@ +@/g, '@').replace(/@/g, '\n');
          const argsFunc = options[key].slice(options[key].indexOf('(') + 1, options[key].indexOf(')'));
          const bodyFunc = options[key].slice(options[key].indexOf('{') + 2, options[key].lastIndexOf('}'));
          options[key] = new Function(argsFunc, bodyFunc);
        }
      }
      return options;
    }
  }
};
</script>

<style lang="scss" scoped>
.options-accordion {
  height: 100%;

  &__tab-item-form {
    background-color: #fff;
  }
}

.form-wrapper {
  display: flex;
  flex-direction: column;

  .toolbar {
    flex: 0 0 auto;
    background-color: #f7f7f7;
    margin-bottom: 5px;
  }

  .component-form-wrapper {
    flex: 1 1 auto;
    min-height: 0;
  }
}

//Отступы вкладки доступа
.access-form__grid {
  padding: 10px;
}
</style>

<style lang="scss">
.options-accordion {
  & > .dx-accordion {
    background-color: #f7f7f7;
  }

  /*Дополнение dx-стилей для того чтобы отображать в аккордеоне treeView со скроллом*/
  .dx-accordion-item {
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }

  .dx-accordion-item-title {
    flex: 0 0 auto;
  }

  .dx-accordion-item-body {
    flex: 1 1 auto;
  }
}
</style>
