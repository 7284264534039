/**
 * Статический класс для хранения словаря экземпляров компонентов
 */
export default class ComponentStore {
  /**
   * Словарь экземпляров компонентов
   */
  static instanceMap = new Map();

  /**
   * Словарь компонентов для различных попапов
   */
  static parentReferenceMap = new Map();

  /**
   * Словарь promise коотрые разрешаются при создании компонента
   */
  static resolveMap = new Map();

  /**
   * Метод получения экземпляра компонента
   * @param {*} componentId
   * @param {*} parentComponentId - ИД попапа, к которому привязан компонент
   * @returns {Promise} возвращает Promise который разрешается когда компонента инициализирован
   */
  static getComponent(componentId, parentComponentId = null) {
    let componentInstance;
    if (parentComponentId) {
      //Получаем для каждого родителя свой компонент
      const references = ComponentStore.parentReferenceMap.get(componentId);
      if (references && references[parentComponentId]) {
        componentInstance = references[parentComponentId];
      }
    }
    if (!componentInstance) {
      componentInstance = ComponentStore.instanceMap.get(componentId);
    }

    if (componentInstance) {
      return Promise.resolve(componentInstance);
    } else {
      return new Promise((resolve) => {
        ComponentStore.resolveMap.set(componentId, resolve);
      });
    }
  }

  /**
   * Метод установки экземпляра комопнента в словарь
   * @param {*} componentId
   * @param {*} componentInstance
   * * @param {*} parentComponentId - ИД попапа, к которому привязан компонент
   */
  static setComponent(componentId, componentInstance, parentComponentId) {
    if (parentComponentId) {
      //Для каждого родителя добавляем свой экземпляр
      const references = ComponentStore.parentReferenceMap.get(componentId);
      if (references) {
        references[parentComponentId] = componentInstance;
      } else {
        ComponentStore.parentReferenceMap.set(componentId, { [parentComponentId]: componentInstance });
      }
    }
    ComponentStore.instanceMap.set(componentId, componentInstance);
    const resolve = ComponentStore.resolveMap.get(componentId);

    if (resolve) {
      resolve(componentInstance);
      ComponentStore.resolveMap.delete(componentId);
    }
  }

  /**
   * Метод удаления компонента из словарей
   * @param {*} componentId
   * @param {*} parentComponentId - ИД попапа, к которому привязан компонент
   * @param {*} uid - внутренний ИД компонента Vue
   */
  static deleteComponent(componentId, parentComponentId, uid) {
    const references = ComponentStore.parentReferenceMap.get(componentId);
    if (parentComponentId && references) {
      //Проверка, что удаляется правильный экземпляр
      const instance = references[parentComponentId];
      if (instance && instance._uid === uid) {
        delete references[parentComponentId];
      }
      //Удаляем из общего списка, когда удален для всех родителей
      if (!Object.keys(references)[0]) {
        this._deleteComponentFromBaseMap(componentId, uid);
      }
    } else {
      this._deleteComponentFromBaseMap(componentId, uid);
    }
  }

  static _deleteComponentFromBaseMap(componentId, uid) {
    //Проверка, что удаляется правильный экземпляр
    const instance = ComponentStore.instanceMap.get(componentId);
    if (instance && instance._uid === uid) {
      //экземпляр не был заменен другим, после события удаления
      ComponentStore.instanceMap.delete(componentId);
      ComponentStore.resolveMap.delete(componentId);
    }
  }
}
