<template>
  <!--eslint-disable-->
  <DxPopup
    class="popup-united-form"
    width="40vw"
    height="95vh"
    position="left"
    :animation="null"
    :visible="visible"
    :shading="false"
    :resizeEnabled="true"
    :title="title"
    :minWidth="500"
    :minHeight="400"
    @shown="shownPopup"
  >
    <!--Кнопка "Ок" в нижней панели инструментов-->
    <DxToolbarItem
      toolbar="bottom"
      location="center"
      template="okButtonTemplate"
    />
    <template #okButtonTemplate>
      <DxButton
        text="Ок"
        type="success"
        @click="okButtonClick"
      />
    </template>

    <!--Кнопка "Применить" в нижней панели инструментов-->
    <DxToolbarItem
      toolbar="bottom"
      location="center"
      template="applyButtonTemplate"
    />
    <template #applyButtonTemplate>
      <DxButton
        text="Применить"
        type="default"
        @click="applyButtonClick"
      />
    </template>

    <!--Кнопка "Отменить" в нижней панели инструментов -->
    <DxToolbarItem
      toolbar="bottom"
      location="center"
      template="cancelButtonTemplate"
    />
    <template #cancelButtonTemplate>
      <DxButton
        text="Отменить"
        @click="close"
      />
    </template>

    <!--Заголовок dxPopup -->
    <template #titleTemplate>
      <PopupTitleTemplate
        :text="title"
        :showCloseButton="true"
        @closeClick="close"
      />
    </template>

    <!--Содержимое dxPopup -->
    <div class="popup-united-form__wrapper">
      <DxTextBox
        v-model="searchString"
        mode="search"
        valueChangeEvent="input"
        placeholder="Поиск..."
        @input="search"
        class="popup-united-form__search"
      />
      <div class="popup-united-form__accordion">
        <DxAccordion
          height="100%"
          width="100%"
          :multiple="true"
          :collapsible="true"
          :items="accordionItems"
          :focusStateEnabled="false"
          :deferRendering="false"
          :selectedItem="accordionSelectedItem"
        >
          <template #options>
            <ComponentSettings
              :fields="optionsFieldsBySearch"
              :valueObject="editingOptions"
              :componentId="componentConfig.componentId"
              @change="onOptionsChanged"
              @monacoValueChanged="onOptionsMonacoValueChanged"
              ref="componentOptions"
            />
          </template>
          <template #cssStyle>
            <DxScrollView>
              <div class="cssStyle">
                <!--Для дочерних элементов Form, Group, TabPanelItem -->
                <template v-if="['Form', 'Group', 'TabPanelItem'].includes(parentComponentType) && !INVISIBLE_COMPONENTS.includes(componentConfig.componentType)">
                  <div class="dx-field">
                    <div class="dx-field-label">Занимает колонок</div>
                    <div class="dx-field-value">
                      <DxNumberBox v-model="colSpan" />
                    </div>
                  </div>
                  <div class="dx-field">
                    <div class="dx-field-label">Показать подпись</div>
                    <div class="dx-field-value">
                      <DxCheckBox v-model="labelVisible" />
                    </div>
                  </div>
                  <div class="dx-field">
                    <div class="dx-field-label">Подпись</div>
                    <div class="dx-field-value">
                      <DxTextBox v-model="labelText" />
                    </div>
                  </div>
                </template>
                <!--Для дочерних элементов Toolbar -->
                <template v-if="['Toolbar'].includes(parentComponentType) && !INVISIBLE_COMPONENTS.includes(componentConfig.componentType)">
                  <div class="dx-field">
                    <div class="dx-field-label">Расположение</div>
                    <div class="dx-field-value">
                      <DxSelectBox
                        v-model="location"
                        valueExpr="code"
                        displayExpr="name"
                        :dataSource="toolbarLocationItems"
                      />
                    </div>
                  </div>
                  <div class="dx-field">
                    <div class="dx-field-label">Расположить в меню</div>
                    <div class="dx-field-value">
                      <DxSelectBox :dataSource="toolbarLocateInMenuItems" />
                    </div>
                  </div>
                </template>
                <!--Для дочерних элементов полей редактирования, если это кнопки -->
                <template v-if="['Autocomplete', 'ColorBox', 'DateBox', 'NumberBox', 'SelectBox', 'TagBox', 'TextBox'].includes(parentComponentType) && componentConfig.componentType === 'Button'">
                  <div class="dx-field">
                    <div class="dx-field-label">Расположение</div>
                    <div class="dx-field-value">
                      <DxSelectBox
                        v-model="location"
                        valueExpr="code"
                        displayExpr="name"
                        :dataSource="editorButtonLocationItems"
                      />
                    </div>
                  </div>
                </template>
                <!--Для дочерних элементов GridLayout-->
                <template v-if="['GridLayout'].includes(parentComponentType) && !INVISIBLE_COMPONENTS.includes(componentConfig.componentType)">
                  <div class="dx-field">
                    <div class="dx-field-label">Колонка</div>
                    <div class="dx-field-value">
                      <DxNumberBox v-model="col" />
                    </div>
                  </div>
                  <div class="dx-field">
                    <div class="dx-field-label">Строка</div>
                    <div class="dx-field-value">
                      <DxNumberBox v-model="row" />
                    </div>
                  </div>
                  <div class="dx-field">
                    <div class="dx-field-label">Занимает колонок</div>
                    <div class="dx-field-value">
                      <DxNumberBox v-model="colSpan" />
                    </div>
                  </div>
                  <div class="dx-field">
                    <div class="dx-field-label">Занимает строк</div>
                    <div class="dx-field-value">
                      <DxNumberBox v-model="rowSpan" />
                    </div>
                  </div>
                  <div class="dx-field">
                    <div class="dx-field-label">Минимум колонок</div>
                    <div class="dx-field-value">
                      <DxNumberBox v-model="minColSpan" />
                    </div>
                  </div>
                  <div class="dx-field">
                    <div class="dx-field-label">Минимум строк</div>
                    <div class="dx-field-value">
                      <DxNumberBox v-model="minRowSpan" />
                    </div>
                  </div>
                  <div class="dx-field">
                    <div class="dx-field-label">Максимум колонок</div>
                    <div class="dx-field-value">
                      <DxNumberBox v-model="maxColSpan" />
                    </div>
                  </div>
                  <div class="dx-field">
                    <div class="dx-field-label">Максимум строк</div>
                    <div class="dx-field-value">
                      <DxNumberBox v-model="maxRowSpan" />
                    </div>
                  </div>
                  <div class="dx-field">
                    <div class="dx-field-label">Не перемещать</div>
                    <div class="dx-field-value">
                      <DxCheckBox v-model="isNotDraggable" />
                    </div>
                  </div>
                  <div class="dx-field">
                    <div class="dx-field-label">Не изменять размер</div>
                    <div class="dx-field-value">
                      <DxCheckBox v-model="isNotResizable" />
                    </div>
                  </div>
                  <div class="dx-field">
                    <div class="dx-field-label">Показать подпись</div>
                    <div class="dx-field-value">
                      <DxCheckBox v-model="labelVisible" />
                    </div>
                  </div>
                  <div class="dx-field">
                    <div class="dx-field-label">Подпись</div>
                    <div class="dx-field-value">
                      <DxTextBox
                        v-model="labelText"
                        @change="changeLabelText"
                      />
                    </div>
                  </div>
                  <div class="dx-field">
                    <div class="dx-field-label">Расположение подписи</div>
                    <div class="dx-field-value">
                      <DxSelectBox
                        v-model="labelLocation"
                        valueExpr="code"
                        displayExpr="name"
                        :dataSource="labelLocations"
                      />
                    </div>
                  </div>
                  <div class="dx-field">
                    <div class="dx-field-label">Выравнивание подписи</div>
                    <div class="dx-field-value">
                      <DxSelectBox
                        v-model="labelAlignment"
                        valueExpr="code"
                        displayExpr="name"
                        :dataSource="labelAlignments"
                      />
                    </div>
                  </div>
                  <div class="dx-field">
                    <div class="dx-field-label">Показать ":"</div>
                    <div class="dx-field-value">
                      <DxCheckBox
                        v-model="labelShowColon"
                        :value="true"
                      />
                    </div>
                  </div>
                  <div class="dx-field">
                    <div class="dx-field-label">Расположение ":"</div>
                    <div class="dx-field-value">
                      <DxSelectBox
                        v-model="labelColonLocation"
                        valueExpr="code"
                        displayExpr="name"
                        :dataSource="colonLocations"
                      />
                    </div>
                  </div>
                </template>
                <!--Для дочерних элементов Popup-->
                <template v-if="['Popup', 'Popover'].includes(parentComponentType) && !INVISIBLE_COMPONENTS.includes(componentConfig.componentType)">
                  <div class="dx-field">
                    <div class="dx-field-label">Расположить в</div>
                    <div class="dx-field-value">
                      <DxSelectBox
                        v-model="toolbar"
                        :dataSource="popupToolbarItems"
                        valueExpr="code"
                        displayExpr="name"
                        :showClearButton="true"
                      />
                    </div>
                  </div>
                  <div class="dx-field">
                    <div class="dx-field-label">Расположение</div>
                    <div class="dx-field-value">
                      <DxSelectBox
                        v-model="location"
                        valueExpr="code"
                        displayExpr="name"
                        :dataSource="toolbarLocationItems"
                        :showClearButton="true"
                      />
                    </div>
                  </div>
                </template>

                <ComponentSettings
                  class="cssStyle__styles"
                  :fields="cssStylesFieldsBySearch"
                  :valueObject="editingCssStyle"
                  :componentId="componentConfig.componentId"
                  @change="onCssStyleChanged"
                  @monacoValueChanged="onCssStyleMonacoValueChanged"
                  ref="componentCssStyle"
                />
              </div>
            </DxScrollView>
          </template>
          <template #optionsCssStyle>
            <ComponentSettings
              :fields="cssStylesFieldsBySearch"
              :valueObject="editingOptionsCssStyle"
              :componentId="componentConfig.componentId"
              @change="onOptionsCssStyleChanged"
              @monacoValueChanged="onOptionsCssStyleMonacoValueChanged"
              ref="componentOptionsCssStyle"
            />
          </template>
        </DxAccordion>
      </div>
    </div>
  </DxPopup>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import { getComponentType } from '@/store/modules/config/getters';
import DxPopup, { DxToolbarItem } from 'devextreme-vue/popup';
import PopupTitleTemplate from '../Containers/PopupTitleTemplate';
import DxAccordion from 'devextreme-vue/accordion';
import DxButton from 'devextreme-vue/button';
import DxNumberBox from 'devextreme-vue/number-box';
import DxCheckBox from 'devextreme-vue/check-box';
import DxTextBox from 'devextreme-vue/text-box';
import DxSelectBox from 'devextreme-vue/select-box';
import DxScrollView from 'devextreme-vue/scroll-view';
import ComponentSettings from './ComponentSettings';
import CONFIGS_TYPES from './OptionFormConfigs';
import { deepCopy } from '../../utils/deep';
import { COLUMN_COMPONENT_TYPES, CONFIG_FIELDS, INVISIBLE_COMPONENTS } from '../../utils/const';
import { SET_CONSTRUCTOR_ACTIVE_COMPONENT_FIELD } from '@/store/modules/config/mutations';
import { SET_CONFIG_FIELD_BY_PATH } from '@/store/modules/config/components/mutations';

const FIELDS = [
  'colSpan',
  {
    name: 'labelVisible',
    path: 'label.visible'
  },
  {
    name: 'labelText',
    path: 'label.text'
  },
  {
    name: 'labelLocation',
    path: 'label.location'
  },
  {
    name: 'labelAlignment',
    path: 'label.alignment'
  },
  {
    name: 'labelShowColon',
    path: 'label.showColon'
  },
  {
    name: 'labelColonLocation',
    path: 'label.colonLocation'
  },
  'location',
  'col',
  'row',
  'rowSpan',
  'isNotDraggable',
  'isNotResizable',
  'maxRowSpan',
  'maxColSpan',
  'minRowSpan',
  'minColSpan',
  'toolbar'
];
const mapFields = (fields) => {
  let result = {};
  fields.forEach((field) => {
    let fieldName;
    let fieldPath;
    if (typeof field === 'string') {
      fieldName = field;
      fieldPath = field;
    } else {
      fieldName = field.name;
      fieldPath = field.path;
    }

    result[fieldName] = {
      get() {
        return this.getComponentField(fieldPath);
      },
      set(value) {
        this.setConstructorItemField(fieldPath, value);
      }
    };
  });
  return result;
};

export default {
  name: 'PopupUnitedForm',

  components: {
    DxAccordion,
    ComponentSettings,
    DxPopup,
    DxNumberBox,
    DxCheckBox,
    DxTextBox,
    DxSelectBox,
    DxScrollView,
    PopupTitleTemplate,
    DxButton,
    DxToolbarItem
  },

  data() {
    return {
      accordionItems: [
        {
          title: 'Настройки',
          template: 'options'
        },
        {
          title: 'Положение',
          template: 'cssStyle'
        },
        {
          title: 'Стиль',
          template: 'optionsCssStyle'
        }
      ],
      INVISIBLE_COMPONENTS,
      toolbarLocationItems: [
        { code: 'before', name: 'слева' },
        { code: 'center', name: 'по центру' },
        { code: 'after', name: 'справа' }
      ],
      editorButtonLocationItems: [
        { code: 'before', name: 'слева' },
        { code: 'after', name: 'справа' }
      ],
      toolbarLocateInMenuItems: ['always', 'auto', 'never'],
      labelLocations: [
        { code: 'left', name: 'слева' },
        { code: 'right', name: 'справа' },
        { code: 'top', name: 'сверху' },
        { code: 'bottom', name: 'снизу' }
      ],
      labelAlignments: [
        { code: 'left', name: 'слева' },
        { code: 'center', name: 'по центру' },
        { code: 'right', name: 'справа' }
      ],
      colonLocations: [
        { code: 'left', name: 'слева' },
        { code: 'right', name: 'справа' }
      ],
      popupToolbarItems: [
        { code: 'top', name: 'Верхняя панель' },
        { code: 'bottom', name: 'Нижняя панель' }
      ],
      searchString: null,
      timeoutId: null,
      optionsFieldsBySearch: null,
      cssStylesFieldsBySearch: null,

      editingOptions: {},
      editingOptionsCssStyle: {},
      editingCssStyle: {}
    };
  },

  props: {
    visible: Boolean,
    componentConfig: Object,
    currentTub: String,
    title: {
      type: String,
      default: ''
    }
  },

  computed: {
    ...mapGetters('config', {
      /**Получение типа компонента по идентификатору */
      getComponentType
    }),

    accordionSelectedItem() {
      return this.accordionItems.find((item) => item.template === this.currentTub);
    },

    optionsFields() {
      return CONFIGS_TYPES[this.componentConfig.componentType];
    },

    cssStylesFields() {
      if (this.parentComponentType === 'VerticalLayout' || this.parentComponentType === 'HorizontalLayout') {
        return CONFIGS_TYPES.CssStyle.concat(CONFIGS_TYPES.CssStyleLayouts);
      }
      return CONFIGS_TYPES.CssStyle;
    },

    parentComponentType() {
      return (this.componentConfig && this.getComponentType(this.componentConfig.parentComponentId)) || '';
    },

    ...mapFields(FIELDS)
  },

  watch: {
    componentConfig() {
      this.optionsFieldsBySearch = this.optionsFields;
      this.cssStylesFieldsBySearch = this.cssStylesFields;

      //Это копии настроек из vuex
      this.editingOptions = this.getEditingOptions();
      this.editingOptionsCssStyle = this.getEditingOptionsCssStyle();
      this.editingCssStyle = this.getEditingCssStyle();
    }
  },

  methods: {
    ...mapMutations('config', {
      /**Установка поля для конфига активного компонента в конструкторе */
      setConstructorActiveComponentField: SET_CONSTRUCTOR_ACTIVE_COMPONENT_FIELD,

      /**Установка поля конфига компонента */
      setComponentConfigFieldByPath: SET_CONFIG_FIELD_BY_PATH
    }),

    getEditingOptions() {
      //Возьмем из config.options все кроме cssStyle
      let options = {
        ...(this.componentConfig?.options || {})
      };
      delete options.cssStyle;
      delete options.validator;
      let copyOptions = {};
      deepCopy(options, copyOptions);
      options = copyOptions;

      //Удаление options.items для всех компонентов, items настраивать через дочерние компоненты
      delete options.items;

      //Формирование options для компонентов-колонок, БД в компонентах колонках нет config.options, все в корне config
      if (COLUMN_COMPONENT_TYPES.includes(this.componentConfig.componentType)) {
        for (let key in this.componentConfig) {
          if (!CONFIG_FIELDS.includes(key)) {
            options[key] = this.componentConfig[key];
          }
        }
      }
      return options;
    },
    getEditingOptionsCssStyle() {
      return {
        ...(this.componentConfig?.options?.cssStyle || {})
      };
    },
    getEditingCssStyle() {
      return {
        ...(this.componentConfig?.cssStyle || {})
      };
    },
    popupToolbarItemsSettings() {
      return [];
    },

    close() {
      for (let key in this.result) {
        this.result[key] = null;
      }
      this.$emit('hidden');
    },
    shownPopup() {
      //for (let key in this.$refs) {
      //  this.$refs[key].resetFields();
      //  this.$refs[key].formatMonacoEditor();
      //}
      this.$emit('shown');
    },
    changeLabelText() {
      if (this.labelText) {
        this.labelVisible = true;
        if (!this.labelLocation) {
          this.labelLocation = 'left';
        }
        if (!this.labelAlignment) {
          this.labelAlignment = 'right';
        }
      } else {
        this.labelVisible = false;
      }
    },

    getComponentField(fieldPath) {
      return this.$store.getters['config/getItemField'](this.componentConfig.componentId, fieldPath);
    },

    setConstructorItemField(fieldName, fieldValue) {
      this.setConstructorActiveComponentField({
        fieldName,
        fieldValue
      });
      this.setComponentConfigFieldByPath({
        componentId: this.componentConfig.componentId,
        fieldName,
        fieldValue
      });
    },

    search() {
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
        this.timeoutId = null;
      }

      this.timeoutId = setTimeout(() => {
        this.optionsFieldsBySearch = this.optionsFields.filter(
          (field) => field.name.toLowerCase().includes(this.searchString.toLowerCase()) || field.label.toLowerCase().includes(this.searchString.toLowerCase())
        );
        this.cssStylesFieldsBySearch = this.cssStylesFields.filter(
          (field) => field.name.toLowerCase().includes(this.searchString.toLowerCase()) || field.label.toLowerCase().includes(this.searchString.toLowerCase())
        );
      }, 1000);
    },

    onOptionsChanged(eventData) {
      this.changeSettingsFormField('editingOptions', eventData);
    },
    onOptionsMonacoValueChanged(stringValue) {
      this.applyValueFromMonaco('editingOptions', stringValue);
    },

    onCssStyleChanged(eventData) {
      this.changeSettingsFormField('editingCssStyle', eventData);
    },
    onCssStyleMonacoValueChanged(stringValue) {
      this.applyValueFromMonaco('editingCssStyle', stringValue);
    },

    onOptionsCssStyleChanged(eventData) {
      this.changeSettingsFormField('editingOptionsCssStyle', eventData);
    },
    onOptionsCssStyleMonacoValueChanged(stringValue) {
      this.applyValueFromMonaco('editingOptionsCssStyle', stringValue);
    },

    changeSettingsFormField(valueObjectName, valueObject) {
      this[valueObjectName] = {
        ...valueObject
      };
    },

    applyValueFromMonaco(valueObjectName, stringValue) {
      try {
        const newValue = eval(stringValue);
        this[valueObjectName] = newValue;
      } catch {
        //TODO: не делать ничего
      }
    },

    okButtonClick() {
      this.$emit('applyAndHidden', {
        options: this.editingOptions,
        cssStyle: this.editingCssStyle,
        optionsCssStyle: this.editingOptionsCssStyle
      });
    },

    applyButtonClick() {
      this.$emit('apply', {
        options: this.editingOptions,
        cssStyle: this.editingCssStyle,
        optionsCssStyle: this.editingOptionsCssStyle
      });
    }
  }
};
</script>
<style lang="scss">
.popup-united-form {
  &__wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    position: relative;
  }

  &__search {
    margin-bottom: 5px;
  }

  &__accordion {
    height: calc(100% - 31px);
  }

  .cssStyle {
    display: flex;
    flex-direction: column;

    &__styles {
      height: auto;
      flex-grow: 1;
    }

    &__position {
      margin-bottom: 10px;
    }

    .dx-field {
      display: flex;
      flex: 0 0 auto;

      & > .dx-field-label {
        float: none;
        width: auto;
        flex: 0 0 170px;
        text-align: left;
      }
      & > .dx-field-value {
        float: none;
        width: auto;
        flex: 1 1 auto;
        & > .dx-checkbox {
          float: none;
        }
      }
    }
  }
}
</style>
