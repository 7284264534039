// Описание параметров действий
export default {
  inputData: [
    {
      componentType: '',
      descriptions: 'Последние входные данные, которые пришли в компонент'
    }
  ],
  selectedData: [
    {
      componentType: 'DataGrid',
      descriptions:
        'Для одиночного выделения в таблице - объект c данными выбранной строки. Для множественного выделения в таблице - массив с данными выбранных строк.'
    },
    {
      componentType: 'Form',
      descriptions: 'Выбранные данные в форме'
    }
  ],
  checkedData: [
    {
      componentType: 'DataGrid',
      descriptions: 'Отмеченные данные в таблице с помощью ячеек переключателей'
    }
  ],
  focusedData: [
    {
      componentType: 'DataGrid',
      descriptions: 'Данные, которые в компоненте выбранны последними'
    }
  ],
  componentData: [
    {
      componentType: '',
      descriptions: ''
    }
  ],
  componentDataCount: [
    {
      componentType: '',
      descriptions: ''
    }
  ],
  customData: [
    {
      componentType: 'DataGrid',
      descriptions: 'Пользовательские данные компонента'
    }
  ],
  selectedItems: [
    {
      componentType: 'DataGrid',
      descriptions: 'Выбранные элементы компонента'
    }
  ],
  componentConfig: [
    {
      componentType: 'DataGrid',
      descriptions: 'Настройки компонента'
    },
    {
      componentType: 'ComponentTreeItem',
      descriptions: 'Настройки компонента'
    },
    {
      componentType: 'ComponentForm',
      descriptions: 'Настройки компонента'
    }
  ],
  portletData: 'Данные портлета',
  eventData: 'данные из компонента на котором произошло событие',
  fixedData: 'Фиксированные параметры со вшитыми значениями',
  globalData: 'Глобальные данные страницы',
  configData: 'Конфигурационные данные',
  expression: 'Выражение на основе других параметров',
  filterBuilder: 'Запрос'
};
