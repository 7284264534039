import getConfigs from './configUtils';
const OPTIONS = ['expandable', 'expandInit', 'captionExpand'];

const CONFIGS_FIELDS = {
  expandable: {
    name: 'expandable',
    label: 'Сворачивание группы',
    component: 'DxSelectBox',
    options: {
      dataSource: [
        { text: 'Да', value: 'true' },
        { text: 'Нет', value: 'false' }
      ],
      showClearButton: true,
      displayExpr: 'text',
      valueExpr: 'value'
    },
    changeEvent: 'optionChanged'
  },
  expandInit: {
    name: 'expandInit',
    label: 'Начальное состояние сворачиваемой группы',
    component: 'DxSelectBox',
    options: {
      hint: 'Начальное состояние сворачиваемой группы',
      dataSource: [
        { text: 'Развёрнута', value: true },
        { text: 'Свёрнута', value: false }
      ],
      showClearButton: true,
      displayExpr: 'text',
      valueExpr: 'value'
    },
    changeEvent: 'valueChanged'
  },
  captionExpand: {
    name: 'captionExpand',
    label: 'Заголовок развернутого состояния группы',
    component: 'DxTextBox',
    options: {
      hint: 'Заголовок развернутого состояния группы',
      showClearButton: true
    },
    changeEvent: 'valueChanged'
  }
};

export default getConfigs(OPTIONS, CONFIGS_FIELDS);
