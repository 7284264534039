<template>
  <!--eslint-disable-->
  <div
    class="component-tree-item"
    :class="{'component-tree-item_fixed': fixed}"
    :data-component-id="componentConfig.componentId"
    :data-component-pos="componentConfig.componentPos"
    :data-parent-component-id="componentConfig.parentComponentId"
    :data-parents="parents.join(',')"
    :data-fixed="fixed ? 'fixed': ''"
    :data-fields-for-set="fixedFieldsForSet ? JSON.stringify(fixedFieldsForSet) : ''"
  >
    <div class="component-tree-item__content">
      <div
        class="component-tree-item__toggle"
        :class="{'component-tree-item__toggle_transparent': expandButtonHidden }"
        @click="toggleExpandClick"
      >
        <i
          class="dx-icon"
          :class="{'dx-icon-spinright': !expanded, 'dx-icon-spindown': expanded}"
        />
      </div>
      <div
        class="component-tree-item__inner"
        :class="{
          'component-tree-item__inner_selected': !fixed && componentConfig.selected,
          'component-tree-item__inner_selected-last': !fixed && selectedComponentId === componentConfig.componentId,
        }"
        @click="textClick"
        @mousedown="onConstructorElementMouseDown"
        ref="text"
      >
        <i
          v-if="icon"
          class="component-tree-item__icon"
          :class="icon"
        />
        <div class="component-tree-item__wrapper">
          <div>{{ title }}</div>
          <div
            v-if="!fixed"
            class="component-tree-item__text"
          >
            <div
              v-if="subtitle"
              class="component-tree-item__subtitle"
            >
              {{ subtitle }}
            </div>
            <div class="component-tree-item__id">
              {{ componentId }}
            </div>
            <div class="component-tree-item__type">
              {{ componentConfig.componentType }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--Для контейнера DataGrid нужны фиксированные дочерние узлы -->
    <template v-if="!fixed && componentConfig.componentType === 'DataGrid'">
      <div
        class="component-tree-item__children"
        v-if="expanded"
      >
        <ComponentTreeItem
          :componentConfig="componentConfig"
          :fixed="true"
          fixedText="Панель инструментов"
          :fixedItems="dataGridToolbarItems"
          :parents="[...parents, componentConfig.componentId]"
          :fixedFieldsForSet="[{name: 'toolbar', value: 'top'}]"
          :dxScrollView="dxScrollView"
          :pageComponents="pageComponents"
        />
        <ComponentTreeItem
          :componentConfig="componentConfig"
          :fixed="true"
          fixedText="Колонки"
          :fixedItems="dataGridColItems"
          :parents="[...parents, componentConfig.componentId]"
          :fixedFieldsForSet="[{name: 'cols', value: null}]"
          :dxScrollView="dxScrollView"
          :pageComponents="pageComponents"
        />
      </div>
    </template>

    <!--Для контейнера Popup нужны фиксированные дочерние узлы -->
    <template v-else-if="!fixed && (componentConfig.componentType === 'Popup' || componentConfig.componentType === 'Popover')">
      <div
        class="component-tree-item__children"
        v-if="expanded"
      >
        <ComponentTreeItem
          :componentConfig="componentConfig"
          :fixed="true"
          fixedText="Верхняя панель инструментов"
          :fixedItems="popupTopToolbarItems"
          :parents="[...parents, componentConfig.componentId]"
          :fixedFieldsForSet="[{name: 'toolbar', value: 'top'}]"
          :dxScrollView="dxScrollView"
          :pageComponents="pageComponents"
        />
        <ComponentTreeItem
          :componentConfig="componentConfig"
          :fixed="true"
          fixedText="Содержимое"
          :fixedItems="popupContentItems"
          :parents="[...parents, componentConfig.componentId]"
          :fixedFieldsForSet="[{name: 'toolbar', value: null}]"
          :dxScrollView="dxScrollView"
          :pageComponents="pageComponents"
        />
        <ComponentTreeItem
          :componentConfig="componentConfig"
          :fixed="true"
          fixedText="Нижняя панель инструментов"
          :fixedItems="popupBottomToolbarItems"
          :parents="[...parents, componentConfig.componentId]"
          :fixedFieldsForSet="[{name: 'toolbar', value: 'bottom'}]"
          :dxScrollView="dxScrollView"
          :pageComponents="pageComponents"
        />
      </div>
    </template>

    <!--Для контейнера Drawer нужны фиксированные дочерние узлы -->
    <template v-else-if="!fixed && componentConfig.componentType === 'Drawer'">
      <div
        class="component-tree-item__children"
        v-if="expanded"
      >
        <ComponentTreeItem
          :componentConfig="componentConfig"
          :fixed="true"
          fixedText="Панель"
          :fixedItems="drawerContentItems"
          :parents="[...parents, componentConfig.componentId]"
          :fixedFieldsForSet="[{name: 'inPanel', value: true}]"
          :dxScrollView="dxScrollView"
          :pageComponents="pageComponents"
        />
        <ComponentTreeItem
          :componentConfig="componentConfig"
          :fixed="true"
          fixedText="Содержимое"
          :fixedItems="drawerBodyItems"
          :parents="[...parents, componentConfig.componentId]"
          :fixedFieldsForSet="[{name: 'inPanel', value: false}]"
          :dxScrollView="dxScrollView"
          :pageComponents="pageComponents"
        />
      </div>
    </template>

    <!--Для контейнера Gis нужны фиксированные дочерние узлы -->
    <template v-else-if="!fixed && ['Gis', 'GisEmbedded', 'GisTablo'].includes(componentConfig.componentType)">
      <div
        class="component-tree-item__children"
        v-if="expanded"
      >
        <ComponentTreeItem
          :componentConfig="componentConfig"
          :fixed="true"
          fixedText="Верхняя левая панель"
          :fixedItems="gisTopLeftPanelItems"
          :parents="[...parents, componentConfig.componentId]"
          :fixedFieldsForSet="[{name: 'panel', value: 'topLeft'}]"
          :dxScrollView="dxScrollView"
          :pageComponents="pageComponents"
        />

        <ComponentTreeItem
          :componentConfig="componentConfig"
          :fixed="true"
          fixedText="Верхняя правая панель"
          :fixedItems="gisTopRightPanelItems"
          :parents="[...parents, componentConfig.componentId]"
          :fixedFieldsForSet="[{name: 'panel', value: 'topRight'}]"
          :dxScrollView="dxScrollView"
          :pageComponents="pageComponents"
        />

        <ComponentTreeItem
          :componentConfig="componentConfig"
          :fixed="true"
          fixedText="Панель фильтра"
          :fixedItems="gisFilterPanelItems"
          :parents="[...parents, componentConfig.componentId]"
          :fixedFieldsForSet="[{name: 'panel', value: 'filter'}]"
          :dxScrollView="dxScrollView"
          :pageComponents="pageComponents"
        />

        <ComponentTreeItem
          :componentConfig="componentConfig"
          :fixed="true"
          fixedText="Панель над таблицей"
          :fixedItems="gisAboveTablePanelItems"
          :parents="[...parents, componentConfig.componentId]"
          :fixedFieldsForSet="[{name: 'panel', value: 'aboveTable'}]"
          :dxScrollView="dxScrollView"
          :pageComponents="pageComponents"
        />

        <ComponentTreeItem
          :componentConfig="componentConfig"
          :fixed="true"
          fixedText="Дополнительные кнопки"
          :fixedItems="gisCustomButtons"
          :parents="[...parents, componentConfig.componentId]"
          :fixedFieldsForSet="[{name: 'panel', value: 'toolbar'}]"
          :dxScrollView="dxScrollView"
          :pageComponents="pageComponents"
        />

        <ComponentTreeItem
          :componentConfig="componentConfig"
          :fixed="true"
          fixedText="Компоненты на карте"
          :fixedItems="gisOverlayItems"
          :parents="[...parents, componentConfig.componentId]"
          :fixedFieldsForSet="[{name: 'panel', value: 'overlay'}]"
          :dxScrollView="dxScrollView"
          :pageComponents="pageComponents"
        />

        <ComponentTreeItem
          :componentConfig="componentConfig"
          :fixed="true"
          fixedText="Обучающие подсказки"
          :fixedItems="gisHintItems"
          :parents="[...parents, componentConfig.componentId]"
          :fixedFieldsForSet="[{name: 'panel', value: 'hint'}]"
          :dxScrollView="dxScrollView"
          :pageComponents="pageComponents"
        />

        <ComponentTreeItem
          :componentConfig="componentConfig"
          :fixed="true"
          fixedText="Временные шкалы"
          :fixedItems="gisTimelines"
          :parents="[...parents, componentConfig.componentId]"
          :fixedFieldsForSet="[{name: 'panel', value: null}]"
          :dxScrollView="dxScrollView"
          :pageComponents="pageComponents"
        />

        <ComponentTreeItem
          :componentConfig="componentConfig"
          :fixed="true"
          fixedText="Слои"
          :fixedItems="gisLayers"
          :parents="[...parents, componentConfig.componentId]"
          :fixedFieldsForSet="[{name: 'panel', value: null}]"
          :dxScrollView="dxScrollView"
          :pageComponents="pageComponents"
        />
      </div>
    </template>

    <template v-else>
      <div
        class="component-tree-item__children"
        v-if="items.length > 0 && expanded"
      >
        <ComponentTreeItem
          v-for="itemConfig in items"
          :key="itemConfig.componentId"
          :componentConfig="itemConfig"
          :parents="[...parents, componentConfig.componentId]"
          :pageComponents="pageComponents"
          :dxScrollView="dxScrollView"
        />
      </div>
    </template>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import constructorSelectedMixin from '../mixins/constructorSelected';
import { gisLayerLoadChildLayerComponents, loadChildComponents } from '../../store/modules/config/components/GisLayer/actions';
import { getOptions } from '@/store/modules/config/components/getters';
import { ICONS_CSS } from '@/utils/const';

export default {
  name: 'ComponentTreeItem',

  mixins: [constructorSelectedMixin],

  props: {
    parents: Array,
    componentConfig: Object,
    fixed: Boolean,
    fixedText: String,
    fixedItems: Array,
    fixedFieldsForSet: Array,
    dxScrollView: null,
    pageComponents: Object
  },

  data() {
    return {
      fixedExpanded: true
    };
  },

  computed: {
    ...mapGetters('config', {
      getComponentOptions: getOptions
    }),

    selectMode() {
      return true;
    },

    componentId() {
      return this.componentConfig.componentId;
    },

    items() {
      if (this.fixed) {
        return this.fixedItems;
      }

      const componentOptions = this.getComponentOptions(this.componentConfig.componentId);

      return (componentOptions.items || [])
        .filter((itemId) => {
          //Эта фильтрация нужна, чтобы в конструктор не попадали дозагруженные элементы
          return !this.$store.getters['config/loadedItemIds'].includes(itemId);
        })
        .filter((itemId) => !!this.pageComponents[itemId])
        .map((itemId) => {
          return this.pageComponents[itemId];
        })
        .sort((itemConfig1, itemConfig2) => {
          return (itemConfig1.componentPos || 0) - (itemConfig2.componentPos || 0);
        });
    },

    title() {
      if (this.fixed) {
        return this.fixedText;
      }
      let title = this.componentConfig.componentName;
      //Для GisLayer и GisGroupLayer выводить название как в легенде
      if (['GisLayer', 'GisGroupLayer'].includes(this.componentConfig.componentType)) {
        title = this.getComponentOptions(this.componentId).text || title;
      }
      //Для DataSet выводить наименование датасета
      if (['DataSet', 'DataSetGeoJson', 'PivotGridDataSource', 'ServerSideDataSource'].includes(this.componentConfig.componentType)) {
        title = this.getComponentOptions(this.componentId).dataSetName || title;
      }
      return title;
    },

    subtitle() {
      //Подзаголовок есть только у слоев и датасетов - название компоненте, не совпадающее с заголовком
      let subtitle = null;
      if (
        ['GisLayer', 'GisGroupLayer', 'DataSet', 'DataSetGeoJson', 'PivotGridDataSource', 'ServerSideDataSource'].includes(this.componentConfig.componentType)
      ) {
        subtitle = this.componentConfig.componentName;
      }
      if (subtitle === this.title || this.fixed) {
        return null;
      }
      return subtitle;
    },

    icon() {
      // Для фиксированных узлов меняем иконку при открытии и закрытии
      if (this.fixed === true) {
        if (this.fixedExpanded === false) {
          return 'core core-092-folder';
        } else {
          return 'core core-093-folder-opened';
        }
      }

      // Для элементов берем иконки из словаря ICONS_CSS, если значения нет, ставим дефолтную
      if (this.componentConfig.componentType) {
        return ICONS_CSS[this.componentConfig.componentType] || 'core core-default-component';
      }

      return null;
    },

    text() {
      if (this.fixed) {
        return this.fixedText;
      } else {
        //Для GisLayer и GisGroupLayer выводить название как в легенде
        let name = this.componentConfig.componentName;
        if (['GisLayer', 'GisGroupLayer'].includes(this.componentConfig.componentType)) {
          const text = this.getComponentOptions(this.componentId).text;
          if (text) {
            name = `${text} ${name}`;
          }
        }

        return `${name} (${this.componentConfig.componentType} ${this.componentConfig.componentId})`;
      }
    },

    expanded() {
      if (this.fixed) {
        return this.fixedExpanded;
      } else {
        return this.componentConfig.expanded;
      }
    },

    selectedComponentId() {
      return this.$store.getters['config/selectedComponentId'];
    },

    selectedComponentConfig() {
      return this.$store.getters['config/getConstructorActiveComponentConfig'];
    },

    //Для контейнера DataGrid
    dataGridToolbarItems() {
      return this.items.filter((itemConfig) => {
        return itemConfig.componentType.split(/(?=[A-Z])/)[0] !== 'cl';
      });
    },
    dataGridColItems() {
      return this.items.filter((itemConfig) => {
        return itemConfig.componentType.split(/(?=[A-Z])/)[0] === 'cl';
      });
    },

    //Для контейнера Popup
    popupTopToolbarItems() {
      return this.items.filter((itemConfig) => {
        return itemConfig.toolbar === 'top';
      });
    },
    popupContentItems() {
      return this.items.filter((itemConfig) => {
        return !itemConfig.toolbar;
      });
    },
    popupBottomToolbarItems() {
      return this.items.filter((itemConfig) => {
        return itemConfig.toolbar === 'bottom';
      });
    },

    //Для контейнера Drawer
    drawerContentItems() {
      return this.items.filter((itemConfig) => {
        return itemConfig.inPanel;
      });
    },
    drawerBodyItems() {
      return this.items.filter((itemConfig) => {
        return !itemConfig.inPanel;
      });
    },

    //Для контейнера Gis
    gisTopLeftPanelItems() {
      return this.items.filter((itemConfig) => {
        return itemConfig.panel === 'topLeft';
      });
    },
    gisTopRightPanelItems() {
      return this.items.filter((itemConfig) => {
        return itemConfig.panel === 'topRight';
      });
    },
    gisFilterPanelItems() {
      return this.items.filter((itemConfig) => {
        return itemConfig.panel === 'filter';
      });
    },
    gisAboveTablePanelItems() {
      return this.items.filter((itemConfig) => {
        return itemConfig.panel === 'aboveTable';
      });
    },
    gisOverlayItems() {
      return this.items.filter((itemConfig) => {
        return itemConfig.panel === 'overlay';
      });
    },
    gisHintItems() {
      return this.items.filter((itemConfig) => {
        return itemConfig.panel === 'hint';
      });
    },
    gisCustomButtons() {
      return this.items.filter((itemConfig) => {
        return itemConfig.componentType === 'Button';
      });
    },
    gisTimelines() {
      return this.items.filter((itemConfig) => {
        return itemConfig.componentType === 'gisTimeline';
      });
    },
    gisLayers() {
      return this.items.filter((itemConfig) => {
        return !(itemConfig.panel || ['Button', 'gisTimeline'].includes(itemConfig.componentType) || itemConfig.componentId == 'tmpLayer');
      });
    },

    expandButtonHidden() {
      let result = this.items.length === 0;

      //Для слоев надо показывать кнопку раскрытия, пока не нажмем ее хотя бы 1 раз
      const layerOptions = this.getComponentOptions(this.componentId);
      switch (this.componentConfig.componentType) {
        case 'GisGroupLayer':
          result = result && layerOptions.childLayersLoaded;
          break;
        case 'GisLayer':
          result = result && layerOptions.loadChildComponentsPromise;
          break;
      }

      return result;
    }
  },

  watch: {
    selectedComponentConfig() {
      this.$nextTick(() => {
        this.scrollToItem();
      });
    }
  },

  methods: {
    ...mapActions('config', {
      gisLayerLoadChildLayerComponents,
      loadChildComponents
    }),

    toggleExpandClick() {
      //Для GisLayer и GisGroupLayer осуществим загрузку дочерних компонентов
      let loadPromise = null;
      switch (this.componentConfig.componentType) {
        case 'GisGroupLayer':
          loadPromise = this.$store.dispatch('config/GisLayer/gisLayerLoadChildLayerComponents', {
            componentId: this.componentConfig.componentId,
            gisComponentId: this.componentConfig.parentGisId
          });
          break;
        case 'GisLayer':
          loadPromise = this.$store.dispatch('config/GisLayer/loadChildComponents', {
            layerComponentId: this.componentConfig.componentId
          });
          break;
      }

      if (loadPromise) {
        loadPromise.then(() => {
          this.dispatchFieldToggleAction('expanded');
        });
        return;
      }

      if (this.items.length === 0) {
        return;
      }

      if (this.fixed) {
        this.fixedExpanded = !this.fixedExpanded;
        return;
      }

      this.dispatchFieldToggleAction('expanded');
    },

    textClick(event) {
      if (this.fixed) {
        return;
      }

      this.onConstructorElemenClick(event);
    },

    dispatchFieldToggleAction(fieldName) {
      this.$store.dispatch('config/setConstructorItemFields', {
        componentId: this.componentConfig.componentId,
        fields: [
          {
            name: fieldName,
            value: !this.componentConfig[fieldName]
          }
        ]
      });
    },

    scrollToItem() {
      if (this.dxScrollView && !this.fixed && this.selectedComponentId === this.componentConfig.componentId) {
        this.dxScrollView.scrollToElement(this.$refs.text);
      }
    }
  }
};
</script>

<style lang="scss">
.component-tree-item {
  font-weight: normal;
  color: #000;
  font-size: 12px;
  line-height: 14px;

  &_fixed {
    font-weight: 500;
  }

  &__content {
    display: flex;
    min-height: 28px;
    cursor: pointer;
  }

  &__toggle {
    padding: 6px 0 6px 4px;
    flex: 0 0 auto;
    i {
      font-size: 18px;
    }

    &_transparent {
      i {
        color: transparent;
      }
    }
  }

  &__inner {
    padding: 5px;
    border: 1px solid transparent;
    line-height: 18px;
    flex: 1 1 auto;
    display: flex;

    &:hover {
      background-color: rgba(0, 0, 0, 0.064);
    }

    &_selected {
      background-color: rgba(51, 122, 183, 0.2);
    }

    &_selected-last {
      border: 1px solid #337ab7;
      background-color: rgba(51, 122, 183, 0.2);
    }
  }

  &__icon {
    opacity: 0.5;
  }

  &__wrapper {
    margin-left: 5px;
  }

  &__text {
    display: flex;
    flex-wrap: wrap;
    font-size: 10px;
    line-height: 12px;
    color: rgba(0, 0, 0, 0.5);

    & > *:not(:last-child) {
      margin-right: 5px;
    }
  }

  &__subtitle {
    color: #000;
    display: flex;
    align-items: center;

    &::after {
      content: '';
      display: block;
      width: 3px;
      height: 3px;
      border-radius: 50%;
      background: #000;
      opacity: 0.2;
      margin-left: 5px;
    }
  }

  &__children {
    margin-left: 10px;
  }
}
</style>
