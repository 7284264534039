<template>
  <!--eslint-disable-->
  <DxPopup
    :animation="null"
    :visible="visible"
    :shading="false"
    :resizeEnabled="true"
    :minWidth="800"
    :toolbarItems="toolbarItems"
    @shown="$emit('shown')"
    @hidden="$emit('hidden')"
  >
    <template #titleTemplate>
      <PopupTitleTemplate
        :text="title"
        :showCloseButton="true"
        @closeClick="$emit('hidden')"
      />
    </template>
    <template #contentTemplate>
      <div>
        <Page
          :url="url"
          :device="$store.getters['uiConstructor/resolutionDevice']"
          @loaded="$emit('loaded')"
        />
      </div>
    </template>
  </DxPopup>
</template>

<script>
import DxPopup from 'devextreme-vue/popup';
import PopupTitleTemplate from '../Containers/PopupTitleTemplate';
import Page from '../Containers/Page';

export default {
  name: 'PopupTypeComponent',
  components: {
    DxPopup,
    PopupTitleTemplate,
    Page
  },
  data() {
    return {};
  },
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    url: {
      type: String,
      required: true
    },
    toolbarItems: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  computed: {},
  methods: {}
};
</script>

<style lang="scss">
.popup-new-page-form-content {
  display: flex;
  flex-direction: column;
}
</style>
