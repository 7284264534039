import getConfigs from './configUtils';
const OPTIONS = [
  'placeholder',
  'type',
  'pickerType',
  'stylingMode',
  'applyButtonText',
  'cancelButtonText',
  'min',
  'max',
  'showAnalogClock',
  'showClearButton',
  'disabled'
];

const CONFIGS_FIELDS = {
  placeholder: {
    name: 'placeholder',
    label: 'Подсказка',
    component: 'DxTextBox',
    options: {
      showClearButton: true
    },
    changeEvent: 'valueChanged'
  },
  type: {
    name: 'type',
    label: 'Режим',
    component: 'DxSelectBox',
    options: {
      dataSource: [
        { text: 'Дата', value: 'date' },
        { text: 'Время', value: 'time' },
        { text: 'Дата и время', value: 'datetime' }
      ],
      showClearButton: true,
      displayExpr: 'text',
      valueExpr: 'value'
    },
    changeEvent: 'valueChanged'
  },
  pickerType: {
    name: 'pickerType',
    label: 'Формат',
    component: 'DxSelectBox',
    options: {
      dataSource: [
        { text: 'Кастомный', value: 'calendar' },
        { text: 'Список', value: 'list' },
        { text: 'Стандартный', value: 'native' },
        { text: 'Карусель', value: 'rollers' }
      ],
      showClearButton: true,
      displayExpr: 'text',
      valueExpr: 'value'
    },
    changeEvent: 'valueChanged'
  },
  stylingMode: {
    name: 'stylingMode',
    label: 'Стиль поля',
    component: 'DxSelectBox',
    options: {
      dataSource: [
        { text: 'С рамкой', value: 'outlined' },
        { text: 'Подчеркнутый', value: 'underlined' },
        { text: 'Закрашенный', value: 'filled' }
      ],
      showClearButton: true,
      displayExpr: 'text',
      valueExpr: 'value'
    },
    changeEvent: 'valueChanged'
  },
  applyButtonText: {
    name: 'applyButtonText',
    label: 'Текст кнопки "Применить"',
    component: 'DxTextBox',
    options: {
      showClearButton: true
    },
    changeEvent: 'valueChanged'
  },
  cancelButtonText: {
    name: 'cancelButtonText',
    label: 'Текст кнопки "Отменить"',
    component: 'DxTextBox',
    options: {
      showClearButton: true
    },
    changeEvent: 'valueChanged'
  },
  min: {
    name: 'min',
    label: 'Минимальное значение',
    component: 'DxDateBox',
    options: {
      type: 'datetime',
      showAnalogClock: false,
      showClearButton: true
    },
    changeEvent: 'valueChanged'
  },
  max: {
    name: 'max',
    label: 'Максимальнное значение',
    component: 'DxDateBox',
    options: {
      type: 'datetime',
      showAnalogClock: false,
      showClearButton: true
    },
    changeEvent: 'valueChanged'
  },
  showAnalogClock: {
    name: 'showAnalogClock',
    label: 'Аналоговые часы',
    component: 'DxSelectBox',
    options: {
      dataSource: [
        { text: 'Да', value: 'true' },
        { text: 'Нет', value: 'false' }
      ],
      showClearButton: true,
      displayExpr: 'text',
      valueExpr: 'value'
    },
    changeEvent: 'optionChanged'
  },
  showClearButton: {
    name: 'showClearButton',
    label: 'Кнопка очистки',
    component: 'DxSelectBox',
    options: {
      dataSource: [
        { text: 'Да', value: 'true' },
        { text: 'Нет', value: 'false' }
      ],
      showClearButton: true,
      displayExpr: 'text',
      valueExpr: 'value'
    },
    changeEvent: 'optionChanged'
  },
  disabled: {
    name: 'disabled',
    label: 'Поле заблокировано',
    component: 'DxSelectBox',
    options: {
      dataSource: [
        { text: 'Да', value: 'true' },
        { text: 'Нет', value: 'false' }
      ],
      showClearButton: true,
      displayExpr: 'text',
      valueExpr: 'value'
    },
    changeEvent: 'optionChanged'
  }
};

export default getConfigs(OPTIONS, CONFIGS_FIELDS);
