<template>
  <!--eslint-disable-->
  <ConstructorElement
    :style="cssStyle"
    :componentId="componentId"
    :componentType="componentType"
    @click.native="onConstructorElemenClick"
    @mousedown.native="onConstructorElementMouseDown"
  />
</template>

<script>
import constructorSelectedMixin, { SELECTED_CSS_CLASS, LAST_SELECTED_CSS_CLASS } from '../mixins/constructorSelected';

export default {
  mixins: [constructorSelectedMixin],
  props: {
    componentId: String,
    componentType: String,
    cssStyle: Object
  },
  watch: {
    constructorElementSelected() {
      this.$el.classList.toggle(SELECTED_CSS_CLASS);
    },
    constructorElementLastSelected() {
      this.$el.classList.toggle(LAST_SELECTED_CSS_CLASS);
    }
  },
  updated() {
    if (this.constructorElementSelected && !this.$el.classList.contains(SELECTED_CSS_CLASS)) {
      this.$el.classList.add(SELECTED_CSS_CLASS);
    }
    if (this.constructorElementLastSelected && !this.$el.classList.contains(LAST_SELECTED_CSS_CLASS)) {
      this.$el.classList.add(LAST_SELECTED_CSS_CLASS);
    }
  }
};
</script>
