import { jsonRPC } from '@/api/api';
import { SET_PORTAL_CONFIG, SET_PORTAL_IS_LOADING } from './mutations';

export default {
  setPortalConfig(context, { portalConfig }) {
    if (!portalConfig) {
      const error = 'Нет информации о портале';
      window.console.warn(error);
      throw error;
    }

    //Сохранить данные портала в хранилище
    context.commit(SET_PORTAL_CONFIG, portalConfig);

    //Установить заголовок страницы
    if (portalConfig.title) {
      document.title = portalConfig.title;
    }

    //Установить favicon
    if (portalConfig.faviconurl) {
      let link = document.querySelector("link[rel~='icon']");
      if (!link) {
        link = document.createElement('link');
        link.rel = 'icon';
        document.getElementsByTagName('head')[0].appendChild(link);
      }
      link.href = portalConfig.faviconurl;
    }

    context.commit(SET_PORTAL_IS_LOADING, false);

    return Promise.resolve(portalConfig);
  }
};
