<template>
  <!--eslint-disable-->
  <DxTabPanel
    class="component-settings"
    :items="tabPanelItems"
    :deferRendering="false"
    :focusStateEnabled="false"
    v-on="$listeners"
  >
    <template #basicOptions>
      <div
        v-if="fields"
        class="component-settings__basic"
      >
        <OptionsForm
          :fields="optionsFields"
          :valueObject="optionsValues"
          @change="onChange"
          ref="optionsForm"
        />
      </div>
      <div
        v-else
        class="component-settings__error"
      >Извините, форма для этого компонента еще не создана.</div>
    </template>
    <template #fullOptions>
      <MonacoEditor
        class="component-settings__full"
        :options="monacoEditorOptions"
        :value="monacoValue"
        @valueChanged="onMonacoValueChanged"
        ref="monacoEditor"
      />
    </template>
  </DxTabPanel>
</template>

<script>
import DxTabPanel from 'devextreme-vue/tab-panel';
import MonacoEditor from '../MonacoEditorBase';
import OptionsForm from './OptionsForm';
import stringifyObject from 'stringify-object';

const BOOLEAN_FIELDS = {
  true: true,
  false: false
};

export default {
  name: 'ComponentSettings',
  components: {
    MonacoEditor,
    DxTabPanel,
    OptionsForm
  },
  props: {
    fields: Array,
    valueObject: Object,
    componentId: String
  },
  data() {
    return {
      monacoEditorOptions: {
        automaticLayout: true,
        language: 'javascript',
        minimap: {
          enabled: false
        },
        formatOnPaste: true
      },
      tabPanelItems: [
        {
          title: 'Быстрая настройка',
          template: 'basicOptions'
        },
        {
          title: 'Расширенная настройка',
          template: 'fullOptions'
        }
      ],
      component: null,
      monacoValue: '',
      multiFieldsValues: {}
    };
  },
  computed: {
    multiFields() {
      if (!this.fields) {
        return [];
      }
      return this.fields.filter((field) => field.fieldsPart);
    },
    optionsFields() {
      if (!this.fields) {
        return [];
      }
      return this.fields.filter((field) => !field.fieldsPart);
    },
    optionsValues() {
      const result = {};
      Object.entries(this.valueObject).forEach(([fieldName, fieldValue]) => {
        const multiField = this.multiFields.find((multiField) => multiField.name === fieldName);
        if (!multiField) {
          if (Object.values(BOOLEAN_FIELDS).includes(fieldValue)) {
            fieldValue = fieldValue.toString();
          }
          result[fieldName] = fieldValue;
        } else {
          multiField.fieldsPart.forEach((part, index) => {
            result[part] = this.multiFieldsValues[multiField.name][index];
          });
        }
      });
      return result;
    }
  },
  watch: {
    valueObject() {
      this.monacoValue = `(${stringifyObject(this.valueObject ? this.functionToString(this.valueObject) : {})})`;

      this.multiFieldsValues = {};
      this.multiFields.forEach((field) => {
        const value = this.valueObject[field.name];
        this.multiFieldsValues[field.name] = value ? this.valueObject[field.name].split(field.separator) : field.fieldsPart.map(() => null);
      });
      //this.formatMonacoEditor();
    }
  },
  methods: {
    onChange(valueObject) {
      let result = {};
      //Исключим поля, которые являются частью другого поля
      if (valueObject) {
        Object.entries(valueObject).forEach(([fieldName, fieldValue]) => {
          const multiField = this.multiFields.find((multiField) => multiField.fieldsPart.includes(fieldName));
          if (multiField) {
            const index = multiField.fieldsPart.findIndex((fieldPart) => fieldPart === fieldName);
            this.multiFieldsValues[multiField.name][index] = fieldValue;
          } else {
            if (Object.keys(BOOLEAN_FIELDS).includes(fieldValue)) {
              fieldValue = BOOLEAN_FIELDS[fieldValue];
            }
            result[fieldName] = fieldValue;
          }
        });
      }
      //Добавим в результат мультиполя, если все их элементы не null
      this.multiFields.forEach((multiField) => {
        result[multiField.name] = this.multiFieldsValues[multiField.name].reduce((accum, value) => {
          accum += accum && value ? '.' : '';
          accum += value ?? '';
          return accum;
        }, '');
      });
      this.$emit('change', result);
    },
    formatMonacoEditor() {
      this.$refs.monacoEditor.format();
    },
    functionToString(options) {
      for (let key in options) {
        if (typeof options[key] === 'object') {
          this.functionToString(options[key]);
        } else if (typeof options[key] === 'string' && options[key].indexOf('*@*') === 0) {
          options[key] = options[key].slice(3, options[key].length).replace('@)', ')').replace(/@{2,}/g, '@').replace(/@ +@/g, '@').replace(/@/g, '\n');
          const argsFunc = options[key].slice(options[key].indexOf('(') + 1, options[key].indexOf(')'));
          const bodyFunc = options[key].slice(options[key].indexOf('{') + 2, options[key].lastIndexOf('}'));
          options[key] = new Function(argsFunc, bodyFunc);
        }
      }
      return options;
    },
    onMonacoValueChanged(value) {
      this.$emit('monacoValueChanged', value);
    }
  }
};
</script>

<style lang="scss">
.component-settings {
  flex: 1 1 auto;
  height: 100%;

  &__basic {
    padding: 5px;
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  &__full {
    min-height: 59vh;
  }

  &__error {
    padding: 17px;
  }
}
</style>
