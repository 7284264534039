<template>
  <!--eslint-disable-->
  <div class="fields-box">
    <button
      v-if="isInput"
      type="button"
      class="fields-box__button fields-box__button_clear"
      @click="clearField"
    >
      <i class="fo-cancel-circled"></i>
    </button>
    <button
      type="button"
      class="fields-box__button fields-box__button_toggle"
      @click="toggleField"
    >
      <i
        class="fa"
        :class="`fa-angle-${isOpen ? `up` : `down`}`"
      ></i>
    </button>
    <div
      class="fields-box__options"
      :class="{ 'fields-box__options_open': isOpen }"
    >
      <OptionsForm
        v-if="fields"
        :fields="fields"
        :valueObject="value"
        @change="onFieldChanged"
        ref="optionsForm"
      />
    </div>
  </div>
</template>

<script>
import OptionsForm from './OptionsForm';

export default {
  name: 'FieldsBox',
  components: {
    OptionsForm
  },
  props: {
    value: Object,
    fields: Array,
    fieldName: String,
    name: String
  },
  data() {
    return {
      popupOptionsVisible: false,
      isOpen: false,
      isInput: false
    };
  },
  methods: {
    onFieldChanged(eventData) {
      this.$emit('change', eventData);
    },
    toggleField() {
      this.isOpen = !this.isOpen;
    },
    clearField() {
      this.$emit('change', {});
    }
  }
};
</script>
<style lang="scss">
.fields-box {
  width: 100%;
  padding-left: 15px;
  padding-top: 25px;
  margin-top: -25px;
  position: relative;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;

  &__button {
    border: none;
    background-color: transparent;
    padding: 0;
    cursor: pointer;
    width: 15px;
    height: 15px;
    position: absolute;
    top: 5px;

    &_toggle {
      right: 6px;
    }

    &_clear {
      right: 32px;
    }

    &:focus {
      outline: none;
    }

    i {
      width: 100%;
      font-size: 15px;
      color: #999;
    }
  }

  &__options {
    height: 0;
    overflow: hidden;

    &_open {
      height: auto;
    }
  }
}
</style>
