<template>
  <!--eslint-disable-->
  <DxSortable
    height="100%"
    :allowDropInsideItem="false"
    :allowReordering="false"
    filter=".component-list-item"
    dropFeedbackMode="indicate"
    group="constructor"
    @dragStart="dragStart"
    @dragEnd="dragEnd"
  >
    <DxList
      height="100%"
      :focusStateEnabled="false"
      :activeStateEnabled="false"
      :searchEnabled="true"
      :dataSource="sortTypeComponents"
      :searchExpr="['name']"
    >
      <template #item="{ data }">
        <div
          class="component-list-item constructor-element"
          :data-component-type="data.name"
        >
          <i
            class="component-list-item__icon"
            :title="getTitle(data)"
            :class="getIcon(data)"
          />
          <div>
            {{ data.name }}
          </div>
        </div>
      </template>
    </DxList>
  </DxSortable>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { DxList } from 'devextreme-vue/list';
import DxSortable from 'devextreme-vue/sortable';
import { ICONS_CSS } from '../../utils/const';
import { insertComponent } from '@/store/modules/config/actions';
import { getOptions } from '@/store/modules/config/components/getters';
import ComponentStore from '@/models/common/ComponentStore';
import { getDefaultOptions } from './Utility';

export default {
  components: {
    DxList,
    DxSortable
  },

  computed: {
    ...mapGetters('config', {
      getOptions
    }),
    sortTypeComponents() {
      const typeComponents = this.$store.getters['uiConstructor/componentTypes'];

      return typeComponents.sort(({ name: nameComponentOne }, { name: nameComponentTwo }) => {
        return nameComponentOne.localeCompare(nameComponentTwo);
      });
    }
  },

  methods: {
    ...mapActions('config', {
      insertComponent
    }),

    getTitle({ name }) {
      const componentTypeRegistred = this.componentTypeRegistred(name);

      return componentTypeRegistred ? '' : 'Компонент не реализован';
    },
    getIcon({ name }) {
      const componentTypeRegistred = this.componentTypeRegistred(name);

      if (!componentTypeRegistred) {
        return 'core core-075-attention';
      }

      // Берем иконки из словаря ICONS_CSS, если значения нет, ставим дефолтную
      return ICONS_CSS[name] || 'core core-default-component';
    },
    componentTypeRegistred(name) {
      return !!this.$options.components[name];
    },
    dragStart() {
      this.$store.dispatch('uiConstructor/toggleDragging', true);
    },
    dragEnd(eventData) {
      this.$store.dispatch('uiConstructor/toggleDragging', false);

      if (eventData.toComponent === eventData.fromComponent) {
        return;
      }

      const componentType = eventData.itemElement.dataset.componentType;
      const componentName = componentType;
      const toSortable = eventData.toComponent;
      const toSortableElement = toSortable.element();
      const toNodes = toSortableElement.querySelectorAll('.component-list-item');
      const toElement = toNodes[eventData.toIndex] || toNodes[0];

      if (!toElement) {
        //TODO: может нужна какая-то обработка?
        return;
      }

      const componentConfig = {};
      const parentComponentId = toElement.dataset.componentId;
      //Для контейнера dragResize передаем относительное положение для размещения компонента
      const parentConfig = this.getOptions(parentComponentId);
      if (parentConfig.dragResize) {
        const dragResizeContainer = ComponentStore.instanceMap.get(parentComponentId);
        const absolutePos = this.getRelativePosition(dragResizeContainer.$el, { x: eventData.event.clientX, y: eventData.event.clientY });
        componentConfig.drTop = absolutePos.y;
        componentConfig.drLeft = absolutePos.x;
        componentConfig.drSnapToGrid = true;
      }
      const componentPos = (parseInt(toElement.dataset.lastItemPos) || 0) + 100;

      //Не работает вызов действия через mapActions
      this.$store
        .dispatch('config/insertComponent', {
          componentConfig,
          componentType,
          componentName,
          parentComponentId,
          componentPos,
          url: this.$route.params.childUrl,
          device: this.$store.getters['uiConstructor/resolutionDevice'],
          options: getDefaultOptions(componentType)
        })
        .then((componentId) => {
          this.$store.dispatch('config/setConstructorActiveComponent', {
            componentId,
            keepSelected: false
          });
          this.$emit('hidden');
        });
    },

    getRelativePosition(element, { x, y }) {
      const rect = element.getBoundingClientRect();
      const scrollLeft = rect.left + window.pageXOffset || document.documentElement.scrollLeft;
      const scrollTop = rect.top + window.pageYOffset || document.documentElement.scrollTop;

      return { x: x - scrollLeft, y: y - scrollTop };
    }
  }
};
</script>
<style lang="scss">
.component-list-item {
  display: flex !important;

  .core-075-attention {
    color: var(--base-warning);
  }

  &.constructor-element {
    position: relative;
    padding-left: 30px;
  }

  &__icon {
    opacity: 0.5;
    margin-right: 5px;
  }
}
</style>
