import Vue from 'vue';
import Vuex from 'vuex';
import actions from './actions';
import getters from './getters';
import mutations from './mutations';
import config from './modules/config';
import constructor from './modules/constructor';
import session from './modules/session';
import tableau from './modules/tableau';
import dataset from './modules/dataset';
import portal from './modules/portal';
import cartService from './modules/cartService';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    //Идет процесс длительной операции
    isLoading: false
  },
  getters,
  mutations,
  actions,
  modules: {
    config,
    uiConstructor: constructor,
    session,
    tableau,
    dataset,
    portal,
    cartService
  }
});
