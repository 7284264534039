import Vue from 'vue';
import App from './components/App.vue';
import store from './store';
import router from './router';

import VueCompositionApi from '@vue/composition-api';
Vue.use(VueCompositionApi);

//Общие стили
import './styles';

//Общая часть библиотеки devextreme
import './devextreme';

//Стили анимации wowjs
import 'wowjs/css/libs/animate.css';

//Регистрация компонентов
import './componentsRegistrationAsync';

import '@/styles/themes/core/core-theme.scss';
import '@/styles/themes/iust/iust-theme.scss';
import '@/styles/core-scheme-vars.css';

Vue.config.productionTip = false;

//Плагин для локализации
import i18n from '@/i18n';

function changeTheme(theme) {
  return new Promise((resolve) => {
    document.documentElement.classList.remove('core-theme', 'iust-theme');
    document.documentElement.classList.add(theme);
    resolve();
  });
}

function loadTheme(themeName) {
  return new Promise((resolve) => {
    const existingLink = document.getElementById('dx-theme');

    if (existingLink) {
      existingLink.href = `/styles/dx.generic.${themeName}.css`;
      existingLink.onload = () => {
        resolve();
      };
    } else {
      const link = document.createElement('link');
      link.id = 'dx-theme';
      link.rel = 'stylesheet';
      link.href = `/styles/dx.generic.${themeName}.css`;
      document.head.appendChild(link);
      link.onload = () => {
        resolve();
      };
    }
  });
}

// Выполнение действия и изменение темы
store
  .dispatch('session/getUserData')
  .then((userData) => {
    let themePromise;

    if (userData && userData.portalId == 119) {
      themePromise = Promise.all([changeTheme('iust-theme'), loadTheme('iust')]);
    } else {
      themePromise = Promise.all([changeTheme('core-theme'), loadTheme('core-scheme')]);
    }
    return themePromise;
  })
  .catch((error) => {
    console.error('Ошибка при получении данных пользователя', error);
    return Promise.all([changeTheme('core-theme'), loadTheme('core-scheme')]);
  })
  .finally(() => {
    new Vue({
      i18n,
      router,
      store,
      render: (h) => h(App)
    }).$mount('#app');
  });
