import actions from './actions';
import getters from './getters';
import mutations from './mutations';

export default {
  namespaced: true,
  state: {
    status: '',
    userData: null,

    /**
     * Специальный промис, который разрешен в момент получения данных пользователей
     */
    userDataPromise: null,

    isAuthenticated: true,

    /**Токен авторизации недействителен или отсутствует */
    isTokenInvalid: false,

    /**Отображение попапа логина */
    showLoginPopup: false,

    /**Время последней повторной аутентификации, нужно для отбрасывания результатов запросов, которые начались до аутентификации */
    authDate: null
  },
  getters,
  mutations,
  actions
};
