import { jsonRPC } from '@/api/api';
import { setCookie, getCookie } from '../../../utils/cookie';

export default {
  create(context, { isGuest, ...param }) {
    const params = {
      domain: window.location.host,
      ...param
    };
    return jsonRPC(process.env.VUE_APP_CREATE_SESSION, 'NULL.NULL.CREATESESSION', params).then((result) => {
      setCookie('token', result.token, {
        expires: new Date(result.ostime * 1000).toUTCString()
      });

      context.commit('setAuthDate');
      context.commit('setUserData', result.userData);
      context.commit('setIsAuthenticated', { isAuthenticated: true });
    });
  },

  logout() {
    //Специальная настройка включает очистку localStorage при выходе из системы
    if (process.env.VUE_APP_CLEAR_STORAGE_ON_LOGOUT === true || process.env.VUE_APP_CLEAR_STORAGE_ON_LOGOUT === 'true') {
      //const cartProducts = localStorage.getItem('cartProducts');
      //const cartProductsCount = localStorage.getItem('cartProductsCount');

      localStorage.clear();

      /*if (cartProducts) {
        //Сохраняем в localStorage продукты из корзины, если они есть
        localStorage.setItem('cartProducts', cartProducts);
        localStorage.setItem('cartProductsCount', cartProductsCount);
      }*/
    }

    let redirectTo = window.location.href;
    //Для внутреннего сервиса сессий, идем на сервис, redirectTo используем
    redirectTo = `${window.location.protocol}//${window.location.host}`;
    window.location.href = `${process.env.VUE_APP_LOGOUT_SESSION}?apiKey=${getCookie('token')}&redirectTo=${redirectTo}`;
  },

  getUserData({ commit, state }) {
    const userDataPromise = jsonRPC(process.env.VUE_APP_API_SERVICE, 'NULL.NULL.GETUSERDATA', {})
      .then((userData) => {
        commit('setUserData', userData);

        //Если не получили данные пользователя - сессия истекла, сбросим token
        if (!userData) {
          localStorage.removeItem('token');
        }

        return userData;
      })
      .catch((err) => {
        throw err;
      });
    state.userDataPromise = userDataPromise;
    return userDataPromise;
  },

  setIsAuthenticated({ commit }, { isAuthenticated }) {
    commit('setIsAuthenticated', { isAuthenticated });
  }
};
