const FontDetector = function () {
  // a font will be compared against all the three default fonts.
  // and if it doesn't match all 3 then that font is not available.
  var baseFonts = ['monospace', 'sans-serif', 'serif'];

  //we use m or w because these two characters take up the maximum width.
  // And we use a LLi so that the same matching fonts can get separated
  var testString = 'mmmmmmmmmmlli';

  //we test using 72px font size, we may use any size. I guess larger the better.
  var testSize = '72px';

  var h = document.getElementsByTagName('body')[0];

  // create a SPAN in the document to get the width of the text we use to test
  var s = document.createElement('span');
  s.style.fontSize = testSize;
  s.innerHTML = testString;
  var defaultWidth = {};
  var defaultHeight = {};
  for (var index in baseFonts) {
    //get the default width for the three base fonts
    s.style.fontFamily = baseFonts[index];
    h.appendChild(s);
    defaultWidth[baseFonts[index]] = s.offsetWidth; //width for the default font
    defaultHeight[baseFonts[index]] = s.offsetHeight; //height for the defualt font
    h.removeChild(s);
  }

  function detect(font) {
    var detected = false;
    for (var index in baseFonts) {
      s.style.fontFamily = '"' + font + '"' + ',' + baseFonts[index]; // name of the font along with the base font for fallback.
      h.appendChild(s);
      var matched = s.offsetWidth != defaultWidth[baseFonts[index]] || s.offsetHeight != defaultHeight[baseFonts[index]];
      h.removeChild(s);
      detected = detected || matched;
    }
    return detected;
  }

  this.detect = detect;
};

export default FontDetector;

export function getAvailableFonts() {
  const fontsAvailable = [];
  const fontDetector = new FontDetector();
  for (const font of FONT_FAMILIES) {
    if (fontDetector.detect(font)) {
      fontsAvailable.push({ value: font });
    }
  }
  return fontsAvailable;
}

export const FONT_FAMILIES = [
  'Algerian',
  'Arial',
  'Asap',
  'Bell MT',
  'Book Antiqua',
  'Calibri',
  'Calisto MT',
  'Cambria',
  'Centaur',
  'Century',
  'Consolas',
  'Courier New',
  'Crimson',
  'David Libre',
  'DejaVu Sans',
  'DejaVu Sans Mono',
  'DejaVu Serif',
  'Droid Serif',
  'Elephant',
  'EB Garamond',
  'Eras ITC',
  'Faustina',
  'Felix Titling',
  'Frank Ruhl Libre',
  'Garamond',
  'Gentium Basic',
  'Georgia',
  'Goudy Old Style',
  'Helvetica Neue',
  'Impact',
  'Lato',
  'League Mono',
  'Liberation Mono',
  'Liberation Sans',
  'Liberation Serif',
  'Lucida Bright',
  'Lucida Calligraphy',
  'Lucida Console',
  'Lucida Sans',
  'Maiandra GD',
  'Merriweather',
  'Microsoft Sans Serif',
  'Montserrat',
  'Niagara Solid',
  'Noto Mono',
  'Noto Sans',
  'Noto Serif',
  'Open Sans',
  'Perpetua',
  'Playfair Display SC',
  'Playfair Display',
  'PT Sans',
  'PT Serif',
  'Raleway',
  'Roboto Condensed',
  'Roboto Mono',
  'Roboto',
  'Rockwell',
  'Rubik',
  'Script MT',
  'Segoe UI',
  'Sitka',
  'Source Code Pro',
  'Source Sans Pro',
  'Source Serif Pro',
  'Spectral',
  'Stencil',
  'Sylfaen',
  'Tahoma',
  'Times New Roman',
  'Trebuchet MS',
  'Verdana',
  'Ubuntu Mono',
  'Ubuntu',
  'RFRufoBlackItalic',
  'RFRufoBlack',
  'RFRufoBoldItalic',
  'RFRufoBold',
  'RFRufoItalic',
  'RFRufoLightItalic',
  'RFRufoLight',
  'RFRufoRegular',
  'RFRufoSemiBoldItalic',
  'RFRufoSemiBold',
  'RFRufoThinItalic',
  'RFRufoThin'
];
