import { jsonRPC } from '@/api/api';
import { SET_DATASET_DATA } from './mutations';

export default {
  loadDataset({ commit, getters }, { datasetName }) {
    const data = getters.getData(datasetName);
    if (Object.keys(data).length) {
      return Promise.resolve();
    }
    const requestParams = {
      _config_dataset: datasetName
    };
    return jsonRPC('getData', requestParams).then((data) => commit(SET_DATASET_DATA, { datasetName, data }));
  }
};
