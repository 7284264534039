import getConfigs from './configUtils';
const OPTIONS = ['border', 'width', 'height', 'minWidth', 'minHeight', 'padding', 'margin', 'color', 'backgroundColor', 'fontSize', 'fontWeight'];

const CONFIGS_FIELDS = {
  border: {
    name: 'border',
    label: 'Граница',
    component: 'DxTextBox',
    options: {
      showClearButton: true
    },
    changeEvent: 'valueChanged'
  },
  width: {
    name: 'width',
    label: 'Ширина',
    component: 'DxTextBox',
    options: {
      showClearButton: true,
      buttons: [
        'clear',
        {
          name: 'checkFullSize',
          options: {
            text: '100%'
          },
          location: 'after'
        }
      ]
    },
    changeEvent: 'valueChanged'
  },
  height: {
    name: 'height',
    label: 'Высота',
    component: 'DxTextBox',
    options: {
      showClearButton: true,
      buttons: [
        'clear',
        {
          name: 'checkFullSize',
          options: {
            text: '100%'
          },
          location: 'after'
        }
      ]
    },
    changeEvent: 'valueChanged'
  },
  minWidth: {
    name: 'minWidth',
    label: 'Минимальная ширина',
    component: 'DxTextBox',
    options: {
      showClearButton: true
    },
    changeEvent: 'valueChanged'
  },
  minHeight: {
    name: 'minHeight',
    label: 'Минимальная высота',
    component: 'DxTextBox',
    options: {
      showClearButton: true
    },
    changeEvent: 'valueChanged'
  },
  padding: {
    name: 'padding',
    label: 'Внутренние отступы',
    component: 'DxTextBox',
    options: {
      showClearButton: true
    },
    changeEvent: 'valueChanged'
  },
  margin: {
    name: 'margin',
    label: 'Внешние отступы',
    component: 'DxTextBox',
    options: {
      showClearButton: true
    },
    changeEvent: 'valueChanged'
  },
  color: {
    name: 'color',
    label: 'Цвет текста',
    component: 'DxColorBox',
    options: {
      showClearButton: true,
      editAlphaChannel: true
    },
    changeEvent: 'valueChanged'
  },
  backgroundColor: {
    name: 'backgroundColor',
    label: 'Цвет фона',
    component: 'DxColorBox',
    options: {
      showClearButton: true,
      editAlphaChannel: true
    },
    changeEvent: 'valueChanged'
  },
  fontSize: {
    name: 'fontSize',
    label: 'Размер шрифта',
    component: 'DxTextBox',
    options: {
      showClearButton: true
    },
    changeEvent: 'valueChanged'
  },
  fontWeight: {
    name: 'fontWeight',
    label: 'Жирность шрифта',
    component: 'DxTextBox',
    options: {
      showClearButton: true
    },
    changeEvent: 'valueChanged'
  }
};

export default getConfigs(OPTIONS, CONFIGS_FIELDS);
