import { PARAM_TYPES_WITH_COMPONENT } from '@/utils/const';
//TODO: разобраться с этим router
import router from '/src/router';
import ComponentStore from '@/models/common/ComponentStore';
import commonActions from './commonActions';

import { VUEX_COMPONENTS_MAP, VUEX_ACTIONS, NON_VUEX_ACTIONS } from '@/utils/actionToVuexMigration';
import { getCookie } from '@/utils/cookie';

const EXTENDED_VUEX_COMPONENTS_MAP = {
  ...VUEX_COMPONENTS_MAP,

  //Такой костыль нужен чтобы пока не ломать поведение датасетов
  DataSet: 'DataSet',
  DataSetGeoJson: 'DataSet'
};

const EXPRESSION = 'expression';

export function callEventAction(context, { componentId, eventName, eventData }) {
  const eventActions = context.getters['getEventAction'](componentId);

  if (Array.isArray(eventActions[eventName])) {
    const enabledEventActions = eventActions[eventName].filter((item) => !item.disable);

    callActions(context, {
      actions: enabledEventActions,
      eventData
    });

    //Вернем true, если вызывали события-действия
    return true;
  }

  //Если событий-действий не было, то вернем false
  return false;
}

function callActions(context, { actions, eventData, actionParentEAID }) {
  actions
    .filter((actionConfig) => {
      return actionConfig.actionParentEAID === actionParentEAID;
    })
    //Для определенности порядка выполнения
    .sort((a1, a2) => a1.actionEAID - a2.actionEAID)
    .forEach((actionConfig) => {
      try {
        callAction(context, {
          actionConfig,
          actions,
          eventData
        });
      } catch (err) {
        window.console.log(err);
      }
    });
}

function callAction(context, { actionConfig, actions, eventData }) {
  getActionParam(context, actionConfig, eventData).then((param) => {
    if (!param.hasOwnProperty('_config_condition') || param._config_condition) {
      if (Array.isArray(param) && param.hasOwnProperty('_config_condition')) {
        delete param._config_condition;
      }

      if (commonActions[actionConfig.actionName]) {
        //Вызов общего действия
        const actionMethodResult = commonActions[actionConfig.actionName](param, context);
        callChildActions(context, actionMethodResult, actionConfig, actions, eventData);
      } else {
        const actionComponentId = actionConfig.actionComponentId;
        let actionComponentType = actionConfig.actionComponentType;

        //Костыль, поправить на сервере и убрать
        if (actionComponentType && actionComponentType.indexOf('dx') === 0) {
          actionComponentType = actionComponentType.substring(2);
        }

        //Временный костыль для компонентов, которые не перенесены во vuex
        const callVuexAction =
          !NON_VUEX_ACTIONS.includes(actionConfig.actionName) &&
          (VUEX_ACTIONS.includes(actionConfig.actionName) || EXTENDED_VUEX_COMPONENTS_MAP[actionConfig.actionComponentType]);
        if (!callVuexAction) {
          ComponentStore.getComponent(actionComponentId).then((actionComponentInstance) => {
            callActionMethod(context, actionConfig, actionComponentInstance, param, actions, eventData);
          });
          return;
        }

        //Вызов действия компонента
        callStoreAction(context, actionConfig, param, actions, eventData);
      }
    }
  });
}

function callStoreAction(context, actionConfig, param, actions, eventData) {
  const { actionComponentType, actionName, actionComponentId } = actionConfig;

  //Проверка реализации действия в vuex
  let vuexActionName = `${actionComponentType}/${actionName}`;

  //Параметры действия vuex
  const actionPayload = {
    componentId: actionComponentId,
    ...param
  };

  //Первая попытка вызвать действие
  let actionMethodResult = context.dispatch(vuexActionName, actionPayload);

  //Если нет результата, то вторая попытка вызвать реализацию для всех компонентов
  if (!actionMethodResult) {
    vuexActionName = `Component/${actionName}`;
    actionMethodResult = context.dispatch(vuexActionName, actionPayload);
  }

  if (actionMethodResult) {
    callChildActions(context, actionMethodResult, actionConfig, actions, eventData);
  } else {
    window.console.warn(`Не реализовано действие ${actionComponentId}.${actionName}`);
  }
}

function callActionMethod(context, actionConfig, actionComponentInstance, param, actions, eventData) {
  if (!actionComponentInstance[actionConfig.actionName]) {
    window.console.warn(`Не реализовано действие ${actionConfig.actionComponentId}.${actionConfig.actionName}`);
    return;
  }

  const actionMethodResult = actionComponentInstance[actionConfig.actionName](param);

  callChildActions(context, actionMethodResult, actionConfig, actions, eventData);
}

function callChildActions(context, actionMethodResult, actionConfig, actions, eventData) {
  if (actionMethodResult) {
    actionMethodResult
      .then((afterActionEventData) => {
        callActions(context, {
          actions,
          eventData: afterActionEventData || eventData, //Берем данные из последнего действия или данные из прошлого события
          actionParentEAID: actionConfig.actionEAID
        });
        return afterActionEventData || {};
      })
      .then((afterActionEventData) => {
        emitSpecialAfterActionEvents(context, actionConfig, afterActionEventData || eventData);
        return afterActionEventData || {};
      })
      .catch((err) => {
        window.console.warn('Ошибка при выполнении действия', err);
      });
  } else {
    window.console.log(actionConfig);
  }
}

function emitSpecialAfterActionEvents(context, actionConfig, eventData) {
  switch (actionConfig.actionName) {
    case 'refreshData':
      callEventAction(context, {
        componentId: actionConfig.actionComponentId,
        eventName: 'onAfterDataRefreshed',
        eventData
      });
      break;
  }
}

function getActionParam(context, actionConfig, eventData) {
  const paramConfigs = actionConfig.parameters || [];
  let param = {};
  let expressionParams = [];
  let promises = [];

  paramConfigs
    .sort((paramConfig1, paramConfig2) => {
      //Сортировка параметров expression. Параметры expression обрабатываются в конце и если они зависят друг от друга,
      //то зависимые попадают в конец
      if (paramConfig1.dataType === EXPRESSION && paramConfig2.dataType === EXPRESSION) {
        let outputValue = paramConfig1.outputValue || '';
        if (outputValue.indexOf(`@${paramConfig2.outputName}`) !== -1) {
          return 1;
        }

        outputValue = paramConfig2.outputValue || '';
        if (paramConfig2.outputValue.indexOf(`@${paramConfig1.outputName}`) !== -1) {
          return -1;
        }
      } else if (paramConfig1.dataType === EXPRESSION) {
        return 1;
      } else if (paramConfig2.dataType === EXPRESSION) {
        return -1;
      }

      return 0;
    })
    .forEach((paramConfig) => {
      let paramFieldValue = null;
      let dataObjectForParam = null;
      let configParam = null;
      let cookieParam = null;

      //Костыль для сохранения фильтра из формы
      const paramComponentType = (context.getters['getItem'](paramConfig.componentId) || {}).componentType;

      if (paramComponentType === 'Form' && paramConfig.inputName === 'filter' && paramConfig.dataType === 'componentConfig') {
        dataObjectForParam = context.getters['getParamValue'](paramConfig.componentId, paramConfig.dataType);

        const values = Object.values(dataObjectForParam['filter'] || {}).filter((item) => item);
        param[paramConfig.outputName] = values.reduce((result, item, index) => {
          if (values.length === 1) {
            result.push(...item);
          } else {
            result.push(item);
          }

          if (index < values.length - 1) {
            result.push('and');
          }
          return result;
        }, []);
        return param;
      }

      if (PARAM_TYPES_WITH_COMPONENT.includes(paramConfig.dataType)) {
        dataObjectForParam = context.getters['getParamValue'](paramConfig.componentId, paramConfig.dataType);
      } else {
        switch (paramConfig.dataType) {
          case 'eventData':
            dataObjectForParam = eventData;
            break;
          case EXPRESSION:
            expressionParams.push(paramConfig);
            //Переход к следующему параметру
            return;
          case 'fixedData':
            dataObjectForParam = paramConfig.outputValue;
            break;
          case 'globalData':
            dataObjectForParam = router.currentRoute.query;
            break;
          case 'configData':
            configParam = context.rootGetters['session/userData'];
            break;
          case 'cookieData':
            cookieParam = paramConfig.inputName ? getCookie(paramConfig.inputName) : null;
            break;
          case 'geolocation':
            promises.push(defineCurrentUserLocation(paramConfig, param));
            break;
        }
      }

      if (paramConfig.inputName && paramConfig.dataType !== 'componentDataCount') {
        if (Array.isArray(dataObjectForParam)) {
          paramFieldValue = formArrayDataParam(dataObjectForParam, paramConfig.inputName);
        } else {
          paramFieldValue = getParamValueFromObject(paramConfig.inputName, dataObjectForParam);
        }
      } else {
        paramFieldValue = dataObjectForParam;
      }

      if (paramFieldValue !== undefined) {
        if (paramConfig.outputName && paramConfig.outputName !== '_config_all') {
          param[paramConfig.outputName] = paramFieldValue;
        } else if (typeof paramFieldValue === 'object') {
          Object.assign(param, paramFieldValue);
        }
      }

      if (configParam) {
        param[paramConfig.outputName] = configParam[paramConfig.inputName];
      }
    });

  return Promise.all(promises).then(() => {
    //Применение вычисляемых парметров (expression)
    for (let currentParam of expressionParams) {
      // Получаем
      let paramValue = getExpressionParam(param, currentParam);
      // Сохраняем
      if (paramValue !== undefined) {
        if (currentParam.outputName) {
          param[currentParam.outputName] = paramValue;
        } else if (typeof paramValue == 'object') {
          Object.assign(param, paramValue);
        }
      }
    }

    if (paramConfigs.some((param) => param.isArray)) {
      //Представление param в виде массива
      const paramArr = [];
      paramConfigs
        .filter((param) => param.isArray)
        .map((param) => param.outputName)
        .forEach((outputName) => {
          if (Array.isArray(param[outputName])) {
            param[outputName].forEach((item) => {
              paramArr.push(item);
            });
          } else {
            paramArr.push(param[outputName]);
          }
        });

      if (param.hasOwnProperty('_config_condition')) {
        paramArr._config_condition = param._config_condition;
      }

      param = paramArr;
    } else {
      //Удаление приватных параметров
      paramConfigs
        .filter((param) => param.isPrivate)
        .map((param) => param.outputName)
        .forEach((outputName) => {
          delete param[outputName];
        });
    }

    return param;
  });
}

function getExpressionParam(params, param) {
  let expressionResult = undefined;

  if (param.outputValue || typeof param.outputValue !== 'string') {
    let expression = param.outputValue.replace(/@/g, 'params.');
    try {
      const expressionFn = new Function('params', 'return ' + expression);
      expressionResult = expressionFn(params);
    } catch (err) {
      window.console.log('Выражение к действию написано неверно', param);
    }
  }
  return expressionResult;
}

function formArrayDataParam(arr, fieldName) {
  let result = [];
  for (let arrItem of arr) {
    let arrItemValue = getParamValueFromObject(fieldName, arrItem);
    if (arrItemValue !== undefined) {
      result.push(arrItemValue);
    }
  }
  return result;
}

function getParamValueFromObject(fieldNameStr, valueObj) {
  if (!valueObj) {
    return null;
  }

  let fieldNames = fieldNameStr.split('.');
  return getPramValue(fieldNames, valueObj);
}

function getPramValue(fieldNameArr, valueObj) {
  let arrLen = fieldNameArr.length;

  if (arrLen === 0) {
    return null;
  } else if (arrLen === 1) {
    return valueObj[fieldNameArr[0]];
  } else {
    let fieldName = fieldNameArr.shift(0),
      fieldValue = valueObj[fieldName];

    if (typeof fieldValue === 'object') {
      return getPramValue(fieldNameArr, fieldValue);
    } else {
      return null;
    }
  }
}

function defineCurrentUserLocation(paramConfig, param) {
  return new Promise((resolve) => {
    navigator.geolocation.getCurrentPosition(
      (pos) => {
        const result = {
          coords: {
            accuracy: pos.coords ? pos.coords.accuracy : null,
            altitude: pos.coords ? pos.coords.altitude : null,
            altitudeAccuracy: pos.coords ? pos.coords.altitudeAccuracy : null,
            heading: pos.coords ? pos.coords.heading : null,
            latitude: pos.coords ? pos.coords.latitude : null,
            longitude: pos.coords ? pos.coords.longitude : null,
            speed: pos.coords ? pos.coords.speed : null
          },
          timestamp: pos.timestamp
        };
        if (paramConfig.outputName && paramConfig.outputName !== '_config_all') {
          param[paramConfig.outputName] = paramConfig.inputName ? getParamValueFromObject(paramConfig.inputName, result) : result;
        } else {
          const val = paramConfig.inputName ? result[paramConfig.inputName] : result;
          if (typeof val === 'object') {
            Object.assign(param, val);
          }
        }
        resolve(param);
      },
      (err) => {
        window.console.warn(`ERROR(${err.code}): ${err.message}`);
        if (paramConfig.outputName) {
          param[paramConfig.outputName] = null;
        }
        resolve(param);
      }
    );
  });
}
