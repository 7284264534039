export default class CustomFilterFieldOptions {
  /**{String} Имя поля, используемое в фильтре */
  name = null;

  /**{String} Заголовок поля, отображаемый в форме */
  caption = null;

  /**{String} Тип данных поля */
  type = null;

  /**{String} Код датасета для полей-справочников */
  dataSet = null;

  /**{String} Имя поля, отображаемое пользователю как значение из справочника */
  joinFieldName = null;

  /**{String} Имя поля, используемое как значение справочника в фильтре */
  joinFieldKey = null;

  /**{Number} Имя поля, используемое для настройки выражения с оператором hasAny */
  foreignfieldtype_id = null;

  constructor(options) {
    this.name = options.name;
    this.caption = options.caption || options.name;
    this.type = options.fieldtype_name;
    this.dataSet = options.dictionary_dataset_code;
    this.joinFieldName = options.join_fieldname;
    this.joinFieldKey = options.join_fieldkey;
    this.foreignfieldtype_id = options.is_dictionary && !options.sql ? options.foreignfieldtype_id : null;

    if (options.is_dictionary && this.dataSet && this.joinFieldName && this.joinFieldKey && options.fieldforeignkey) {
      this.name = options.fieldforeignkey;
      this.type = 'dictionary';
    }
  }
}
