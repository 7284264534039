import { setPropByPath } from '@/utils/common';
import Gis from './Gis/mutations';
import GisLayer from './GisLayer/mutations';
import DataSet from './DataSet/mutations';
import Vue from 'vue';

//Названия мутаций
const PREFIX = 'Component';
export const SET_OPTION = `${PREFIX}/SET_OPTION`;
export const SET_OPTION_BY_PATH = `${PREFIX}/SET_OPTION_BY_PATH`;
export const SET_CONFIG_FIELD_BY_PATH = `${PREFIX}/SET_CONFIG_FIELD_BY_PATH`;
export const SET_CUSTOM_DATA = `${PREFIX}/SET_CUSTOM_DATA`;
export const RESET_CUSTOM_DATA = `${PREFIX}/RESET_CUSTOM_DATA`;

const mutations = {
  [SET_OPTION](state, { componentId, optionName, optionValue }) {
    const options = state.componentOptions[componentId];
    if (!options) {
      return;
    }
    options[optionName] = optionValue;
  },

  [SET_OPTION_BY_PATH](state, { componentId, optionName, optionValue }) {
    let componentOptions = state.componentOptions[componentId];
    if (!componentOptions || !optionName) {
      return;
    }

    setPropByPath(
      componentOptions,
      optionName.split(/\[|\]|\./).filter((item) => {
        return item !== '';
      }),
      optionValue
    );
  },

  [SET_CONFIG_FIELD_BY_PATH](state, { componentId, fieldName, fieldValue }) {
    let componentConfig = state.items[componentId];
    if (!componentConfig) {
      return;
    }

    setPropByPath(
      componentConfig,
      fieldName.split(/\[|\]|\./).filter((item) => {
        return item !== '';
      }),
      fieldValue
    );
  },

  [SET_CUSTOM_DATA](state, { componentId, customData }) {
    if (!state.customData[componentId]) {
      Vue.set(state.customData, componentId, customData);
    } else {
      state.customData[componentId] = {
        ...state.customData[componentId],
        ...customData
      };
    }
  },

  [RESET_CUSTOM_DATA](state, { componentId }) {
    if (state.customData[componentId]) {
      state.customData[componentId] = null;
    }
  },

  ...Gis,
  ...GisLayer,
  ...DataSet
};

export default mutations;
