import * as turf from '@turf/turf';

//Получение полигонов без самопересечений
export function getUnkinkPolygon(geoJson) {
  let resultCoordinates = [];

  switch (geoJson.geometry.type) {
    case 'MultiPolygon':
      unkinkMultiPolygon(geoJson.geometry.coordinates, resultCoordinates);
      break;
    case 'Polygon':
      geoJson.geometry.type = unkinkPolygon(geoJson.geometry.coordinates, resultCoordinates);
      break;
    default:
      resultCoordinates = geoJson.geometry.coordinates;
  }
  geoJson.geometry.coordinates = resultCoordinates;

  return geoJson;
}

//Устранение самопересечений полигонов мультиполигона
function unkinkMultiPolygon(coordinates, resultCoordinates) {
  for (let polygonCoordinates of coordinates) {
    let polygonResultCoordinates = [];
    let unkinkGeomType = unkinkPolygon(polygonCoordinates, polygonResultCoordinates);
    switch (unkinkGeomType) {
      case 'Polygon':
        resultCoordinates.push(polygonResultCoordinates);
        break;
      case 'MultiPolygon':
        Array.prototype.push.apply(resultCoordinates, polygonResultCoordinates);
        break;
    }
  }
}

//Устранение самопересечений полигона
function unkinkPolygon(coordinates, resultCoordinates) {
  let result = 'Polygon';

  let ringIndex = 0;
  for (let ring of coordinates) {
    //Устранение координат дублей
    removeDuplicates(ring);

    //Получаем из кольца FeatureCollection полигонов без самопересечений
    let ringPolygon = null;
    try {
      ringPolygon = turf.polygon([ring]);
    } catch (err) {
      continue; //Если кольцо "плохое", то игнорируем, и продолжаем работать со следующим кольцом
    }
    let unkinkedPolygons = turf.unkinkPolygon(ringPolygon);

    if (ringIndex === 0) {
      //Если кольцо основное (первое)
      if (unkinkedPolygons.features.length > 1) {
        //Если самопересечения были, то результат поменяет тип на MultuPoligon
        result = 'MultiPolygon';

        //Полученный части сортируются по возрастанию плоощади
        sortGeoJsonByArea(unkinkedPolygons.features);

        //Координаты добавляются в результирующий массив
        for (let feature of unkinkedPolygons.features) {
          resultCoordinates.push(feature.geometry.coordinates);
        }
      } else {
        //Если самопересечений не было, то кольцо добавляется как есть
        resultCoordinates.push(ring);
      }
    } else {
      //Если кольцо не основное
      if (result === 'MultiPolygon') {
        //Если после рассмотрения основного кольца результат стал мультиполигоном
        for (let feature of unkinkedPolygons.features) {
          //Проходим по каждому полигону после ликвидации самопересечений
          for (let resultCoordinate of resultCoordinates) {
            //Проходим по результирующим частям мультиполигона
            if (turf.booleanContains(turf.polygon(resultCoordinate), turf.polygon(feature.geometry.coordinates))) {
              //Добавляем кольцо в часть, если текущая результирующая часть содержит это кольцо
              Array.prototype.push.apply(resultCoordinate, feature.geometry.coordinates);
              break;
            }
          }
        }
      } else {
        //Если после рассмотрения основного кольца результат остался полигоном
        for (let feature of unkinkedPolygons.features) {
          //Добавляем кольца в результат
          Array.prototype.push.apply(resultCoordinates, feature.geometry.coordinates);
        }
      }
    }

    ringIndex++;
  }

  return result;
}

//Устранение координат дублей
function removeDuplicates(coordinates) {
  let i = 0;

  while (i <= coordinates.length - 3) {
    let j = i + 1;
    while (j <= coordinates.length - 2) {
      if (coordinates[i][0] === coordinates[j][0] && coordinates[i][1] === coordinates[j][1]) {
        coordinates.splice(j, 1);
      } else {
        j++;
      }
    }
    i++;
  }
}

//Сортировка частей по возрастанию площади
function sortGeoJsonByArea(geoJsons, desc) {
  geoJsons.sort((part1, part2) => {
    let polygon1 = turf.polygon(part1),
      polygon2 = turf.polygon(part2),
      area1 = turf.area(polygon1),
      area2 = turf.area(polygon2);
    return desc ? area2 - area1 : area1 - area2;
  });
}
