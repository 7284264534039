<template>
  <!--eslint-disable-->
  <div
    class="page-tree"
    :style="{width: '400px', height: '100%'}"
  >
    <DxToolbar class="page-tree__toolbar">
      <DxToolbarItem
        widget="dxButton"
        location="before"
        :options="toolbarButtons.createPage"
      />
      <DxToolbarItem
        widget="dxButton"
        location="before"
        :options="toolbarButtons.update"
      />
    </DxToolbar>
    <DxTreeView
      :key="treeViewKey"
      class="page-tree__tree"
      keyExpr="id"
      :rootValue="null"
      displayExpr="name"
      hasItemsExpr="hasitems"
      parentIdExpr="parent_id"
      dataStructure="plain"
      :selectByClick="true"
      selectionMode="single"
      :focusStateEnabled="false"
      :searchEnabled="true"
      :searchExpr="['url', 'name']"
      @itemClick="handleItemClick"
      :dataSource="dataSource"
      itemTemplate="itemTemplate"
    >
      <template #itemTemplate="{data: itemData}">
        <div class="item">
          <div
            class="icon"
            v-if="itemData.icon"
          >
            <i :class="itemData.icon"></i>
          </div>
          <div class="text">
            {{itemData.name}}
          </div>
        </div>
      </template>
    </DxTreeView>
  </div>
</template>

<script>
import DxButton from 'devextreme-vue/button';
import { DxTreeView } from 'devextreme-vue/tree-view';
import { DxToolbar, DxItem as DxToolbarItem } from 'devextreme-vue/toolbar';

export default {
  components: {
    DxButton, // eslint-disable-line
    DxTreeView,
    DxToolbar,
    DxToolbarItem
  },
  data() {
    return {
      treeViewKey: 0,
      toolbarButtons: {
        createPage: {
          icon: 'plus',
          type: 'default',
          hint: 'Создать страницу',
          onClick: () => {
            this.$emit('create-page');
          }
        },
        update: {
          icon: 'refresh',
          type: 'normal',
          hint: 'Обновить список страниц',
          onClick: () => {
            this.$store.dispatch('uiConstructor/loadAllPages');
          }
        }
      }
    };
  },
  computed: {
    dataSource() {
      return this.$store.state.uiConstructor.allPages;
    }
  },
  methods: {
    handleItemClick(eventData) {
      this.$router.push('/constructor' + eventData.itemData.url);
    }
  }
};
</script>

<style lang="scss" scoped>
.page-tree {
  background-color: #fff;
  padding: 5px;
  display: flex;
  flex-direction: column;

  &__toolbar {
    margin-bottom: 5px;
    flex: 0 0 auto;
  }

  &__tree {
    flex: 1 1 0;
    min-height: 0;
  }
}

.item {
  display: flex;
}

.icon {
  flex: 0 0 16px;
  line-height: 16px;
  font-size: 14px;
  vertical-align: middle;
  padding-right: 5px;
}

.text {
  flex: 1 1 auto;
}
</style>
