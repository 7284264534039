export const SELECTION_SOURCE = 'selectionSource';

const SELECTION_FILL_PATTERN = 'selectionFillPattern';
//const SELECTION_CONTEXT_MENU_FILL_PATTERN = 'selectionContextMenuFillPattern';

export const SELECTION_FILL_PAINT = {
  'fill-pattern': SELECTION_FILL_PATTERN
};
export const SELECTION_LINE_PAINT = {
  'line-color': '#3388ff',
  'line-width': 3
};
export const SELECTION_CIRCLE_PAINT = {
  'circle-color': 'transparent',
  'circle-radius': 12,
  'circle-stroke-width': 3,
  'circle-stroke-color': '#3388ff'
};

export const HOVER_FILL_PAINT = {
  'line-color': '#000',
  'line-width': 2
};
export const HOVER_LINE_PAINT = {
  'line-color': '#000',
  'line-width': 2
};
export const HOVER_CIRCLE_PAINT = {
  'circle-color': 'transparent',
  'circle-radius': 12,
  'circle-stroke-width': 2,
  'circle-stroke-color': '#000'
};

export const FILL_FILTER = ['match', ['geometry-type'], ['Polygon', 'MultiPolygon'], true, false];
export const LINE_FILTER = ['match', ['geometry-type'], ['LineString', 'MultiLineString', 'Polygon', 'MultiPolygon'], true, false];
export const CIRCLE_FILTER = ['match', ['geometry-type'], ['Point', 'MultiPoint'], true, false];

export const DEFAULT_SELECTION_LAYER_CONFIGS = {
  fill: {
    type: 'fill',
    layout: {},
    paint: { ...SELECTION_FILL_PAINT, ...SELECTION_LINE_PAINT },
    filter: FILL_FILTER,
    metadata: {
      layerComponentId: null
    }
  },
  'fill-extrusion': {
    type: 'fill',
    layout: {},
    paint: { ...SELECTION_FILL_PAINT, ...SELECTION_LINE_PAINT },
    filter: FILL_FILTER,
    metadata: {
      layerComponentId: null
    }
  },
  line: {
    type: 'line',
    layout: {},
    paint: SELECTION_LINE_PAINT,
    filter: LINE_FILTER,
    metadata: {
      layerComponentId: null
    }
  },
  circle: {
    type: 'circle',
    layout: {},
    paint: SELECTION_CIRCLE_PAINT,
    filter: CIRCLE_FILTER,
    metadata: {
      layerComponentId: null
    }
  }
};

export const SELECTION_PAINT = {
  fill: SELECTION_FILL_PAINT,
  line: SELECTION_LINE_PAINT,
  circle: SELECTION_CIRCLE_PAINT,
  symbol: SELECTION_CIRCLE_PAINT,
  heatmap: SELECTION_CIRCLE_PAINT
};

export const DEFAULT_HOVER_LAYER_CONFIGS = {
  fill: {
    type: 'fill',
    layout: {},
    paint: HOVER_FILL_PAINT,
    filter: FILL_FILTER,
    metadata: {
      layerComponentId: null
    }
  },
  'fill-extrusion': {
    type: 'fill',
    layout: {},
    paint: HOVER_FILL_PAINT,
    filter: FILL_FILTER,
    metadata: {
      layerComponentId: null
    }
  },
  line: {
    type: 'line',
    layout: {},
    paint: HOVER_LINE_PAINT,
    filter: LINE_FILTER,
    metadata: {
      layerComponentId: null
    }
  },
  circle: {
    type: 'circle',
    layout: {},
    paint: HOVER_CIRCLE_PAINT,
    filter: CIRCLE_FILTER,
    metadata: {
      layerComponentId: null
    }
  }
};
