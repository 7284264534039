const DRAGGABLE_CLASS = 'draggable';
const DRAGGABLE_DRAG_STARTED_CLASS = 'draggable_drag-started';
const DRAGGABLE_CONTAINER_CLASS = 'draggable-container';
const DROPPABLE_CLASS = 'droppable';
const DRAG_HELPER_CLASS = 'drag-helper';
const DRAGGABLE_NO_HIDE = 'draggable-no-hide';

export const CssClasses = {
  DRAGGABLE_CLASS,
  DRAGGABLE_DRAG_STARTED_CLASS,
  DRAGGABLE_CONTAINER_CLASS,
  DROPPABLE_CLASS,
  DRAG_HELPER_CLASS
};

export default {
  data() {
    return {
      dragObject: {}
    };
  },
  methods: {
    handleMouseDown(event) {
      if (event.which != 1) {
        return;
      }

      let draggableElement = event.target.closest('.' + DRAGGABLE_CLASS);
      if (!draggableElement) {
        return;
      }

      if (draggableElement.classList.contains(DRAGGABLE_NO_HIDE)) {
        this.dragObject.elem = draggableElement.cloneNode(true);
      } else {
        this.dragObject.elem = draggableElement;
      }

      this.dragObject.downX = event.pageX;
      this.dragObject.downY = event.pageY;
    },
    handleMouseMove(event) {
      let dragObject = this.dragObject;
      if (!dragObject.elem) {
        return;
      }

      if (!dragObject.helper) {
        // посчитать дистанцию, на которую переместился курсор мыши
        let moveX = event.pageX - dragObject.downX;
        let moveY = event.pageY - dragObject.downY;
        if (Math.abs(moveX) < 3 && Math.abs(moveY) < 3) {
          return; // ничего не делать, мышь не передвинулась достаточно далеко
        }

        //Спрятать переносимый элемент
        dragObject.elem.classList.add(DRAGGABLE_DRAG_STARTED_CLASS);

        dragObject.helper = createDragHelper(dragObject.elem); // захватить элемент
        this.$el.appendChild(dragObject.helper);
        if (!dragObject.helper) {
          dragObject = {}; // аватар создать не удалось, отмена переноса
          return; // возможно, нельзя захватить за эту часть элемента
        }

        this.$el.appendChild(dragObject.helper);
        this.$store.dispatch('uiConstructor/toggleDragging', true);
      }

      // отобразить перенос объекта при каждом движении мыши
      dragObject.helper.style.left = event.pageX - dragObject.helper.offsetWidth / 2 + 'px';
      dragObject.helper.style.top = event.pageY - dragObject.helper.offsetHeight / 2 + 'px';

      dragObject.helper.hidden = true;
      let elemFromPoint = document.elementFromPoint(event.clientX, event.clientY);
      dragObject.helper.hidden = false;

      if (elemFromPoint) {
        let draggableSibling = elemFromPoint.closest('.' + DRAGGABLE_CLASS);
        let draggableContainer = elemFromPoint.closest('.' + DRAGGABLE_CONTAINER_CLASS);

        if (draggableContainer && draggableContainer.handleDragging) {
          draggableContainer.handleDragging(dragObject, draggableContainer, draggableSibling, DRAGGABLE_DRAG_STARTED_CLASS, DROPPABLE_CLASS);
        } else {
          if (dragObject.draggablePlaceholder) {
            dragObject.draggablePlaceholder.remove();
          }
        }
      }

      return false;
    },
    handleMouseUp(event) {
      if (this.dragObject.helper) {
        this.finishDrag(event);
      }
      this.dragObject = {};
    },
    finishDrag() {
      const draggablePlaceholder = this.dragObject.draggablePlaceholder;
      const dragElem = this.dragObject.elem;

      if (draggablePlaceholder && draggablePlaceholder.parentElement) {
        if (draggablePlaceholder.parentElement.finishDrag) {
          draggablePlaceholder.parentElement.finishDrag(dragElem, draggablePlaceholder);
        }
        draggablePlaceholder.remove();
      }

      if (this.dragObject.helper) {
        this.dragObject.helper.remove();
      }

      if (this.dragObject.elem) {
        this.dragObject.elem.classList.remove(DRAGGABLE_DRAG_STARTED_CLASS);
      }

      this.$store.dispatch('uiConstructor/toggleDragging', false);
    }
  }
};

function createDragHelper() {
  let helper = document.createElement('div');
  helper.className = DRAG_HELPER_CLASS;
  return helper;
}
