import getConfigs from './configUtils';
const OPTIONS = [
  'placeholder',
  'stylingMode',
  'wrapItemText',
  'showClearButton',
  'showDropDownButton',
  'showSelectionControls',
  'disabled',
  'searchEnabled',
  'searchMode',
  'applyValueMode',
  'hideSelectedItems',
  'maxDisplayedTags',
  'multiline',
  'showMultiTagOnly'
];

const CONFIGS_FIELDS = {
  placeholder: {
    name: 'placeholder',
    label: 'Подсказка',
    component: 'DxTextBox',
    options: {
      showClearButton: true
    },
    changeEvent: 'valueChanged'
  },
  stylingMode: {
    name: 'stylingMode',
    label: 'Стиль поля',
    component: 'DxSelectBox',
    options: {
      dataSource: [
        { text: 'С рамкой', value: 'outlined' },
        { text: 'Подчеркнутый', value: 'underlined' },
        { text: 'Закрашенный', value: 'filled' }
      ],
      showClearButton: true,
      displayExpr: 'text',
      valueExpr: 'value'
    },
    changeEvent: 'valueChanged'
  },
  wrapItemText: {
    name: 'wrapItemText',
    label: 'Переносить текст',
    component: 'DxSelectBox',
    options: {
      dataSource: [
        { text: 'Да', value: 'true' },
        { text: 'Нет', value: 'false' }
      ],
      showClearButton: true,
      displayExpr: 'text',
      valueExpr: 'value'
    },
    changeEvent: 'optionChanged'
  },
  showClearButton: {
    name: 'showClearButton',
    label: 'Кнопка очистки',
    component: 'DxSelectBox',
    options: {
      dataSource: [
        { text: 'Да', value: 'true' },
        { text: 'Нет', value: 'false' }
      ],
      showClearButton: true,
      displayExpr: 'text',
      valueExpr: 'value'
    },
    changeEvent: 'optionChanged'
  },
  showDropDownButton: {
    name: 'showDropDownButton',
    label: 'Кнопка открытия',
    component: 'DxSelectBox',
    options: {
      dataSource: [
        { text: 'Да', value: 'true' },
        { text: 'Нет', value: 'false' }
      ],
      showClearButton: true,
      displayExpr: 'text',
      valueExpr: 'value'
    },
    changeEvent: 'optionChanged'
  },
  showSelectionControls: {
    name: 'showSelectionControls',
    label: 'Выделение выбора',
    component: 'DxSelectBox',
    options: {
      dataSource: [
        { text: 'Да', value: 'true' },
        { text: 'Нет', value: 'false' }
      ],
      showClearButton: true,
      displayExpr: 'text',
      valueExpr: 'value'
    },
    changeEvent: 'optionChanged'
  },
  disabled: {
    name: 'disabled',
    label: 'Поле заблокировано',
    component: 'DxSelectBox',
    options: {
      dataSource: [
        { text: 'Да', value: 'true' },
        { text: 'Нет', value: 'false' }
      ],
      showClearButton: true,
      displayExpr: 'text',
      valueExpr: 'value'
    },
    changeEvent: 'optionChanged'
  },
  searchEnabled: {
    name: 'searchEnabled',
    label: 'Поиск по полям',
    component: 'DxSelectBox',
    options: {
      dataSource: [
        { text: 'Да', value: 'true' },
        { text: 'Нет', value: 'false' }
      ],
      showClearButton: true,
      displayExpr: 'text',
      valueExpr: 'value'
    },
    changeEvent: 'optionChanged'
  },
  searchMode: {
    name: 'searchMode',
    label: 'Режим поиска',
    component: 'DxSelectBox',
    options: {
      dataSource: [
        { text: 'Содержит', value: 'contains' },
        { text: 'Начинается', value: 'startswith' }
      ],
      showClearButton: true,
      displayExpr: 'text',
      valueExpr: 'value'
    },
    changeEvent: 'valueChanged'
  },
  applyValueMode: {
    name: 'applyValueMode',
    label: 'Режим выбора',
    component: 'DxSelectBox',
    options: {
      dataSource: [
        { text: 'Мгновенно', value: 'instantly' },
        { text: 'По кнопке', value: 'useButtons' }
      ],
      showClearButton: true,
      displayExpr: 'text',
      valueExpr: 'value'
    },
    changeEvent: 'valueChanged'
  },
  hideSelectedItems: {
    name: 'hideSelectedItems',
    label: 'Скрывать выбранные варианты',
    component: 'DxSelectBox',
    options: {
      dataSource: [
        { text: 'Да', value: 'true' },
        { text: 'Нет', value: 'false' }
      ],
      showClearButton: true,
      displayExpr: 'text',
      valueExpr: 'value'
    },
    changeEvent: 'optionChanged'
  },
  maxDisplayedTags: {
    name: 'maxDisplayedTags',
    label: 'Максимальнное количество отображаемых тегов',
    component: 'DxNumberBox',
    options: {
      showClearButton: true
    },
    changeEvent: 'valueChanged'
  },
  multiline: {
    name: 'multiline',
    label: 'Многострочный',
    component: 'DxSelectBox',
    options: {
      dataSource: [
        { text: 'Да', value: 'true' },
        { text: 'Нет', value: 'false' }
      ],
      showClearButton: true,
      displayExpr: 'text',
      valueExpr: 'value'
    },
    changeEvent: 'optionChanged'
  },
  showMultiTagOnly: {
    name: 'showMultiTagOnly',
    label: 'Только мультитег',
    component: 'DxSelectBox',
    options: {
      dataSource: [
        { text: 'Да', value: 'true' },
        { text: 'Нет', value: 'false' }
      ],
      showClearButton: true,
      displayExpr: 'text',
      valueExpr: 'value'
    },
    changeEvent: 'optionChanged'
  }
};

export default getConfigs(OPTIONS, CONFIGS_FIELDS);
