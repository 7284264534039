import actions from './actions';
import getters from './getters';
import mutations from './mutations';

export default {
  namespaced: true,

  state: {
    /**Флаг, говорит о том что данные портала еще загружаются */
    isPortalConfigLoading: true,

    /**Заголовок портала */
    title: '',

    /**Дополнительный заголовок портала */
    subtitle: '',

    /**Название портала */
    name: '',

    /**Путь к файлу с логотипом */
    logourl: null,

    /**Путь к favicon */
    faviconurl: null,

    /**Мастер-страница */
    masterplace_name: null,

    /**Домашня страница */
    defaultplace_name: null
  },

  getters,
  mutations,
  actions
};
