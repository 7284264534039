<template>
  <!--eslint-disable-->
  <div
    class="dx-field"
    :class="{'dx-field_field-box': component==='FieldsBox', 'column': component==='DxHtmlEditor'}"
  >
    <div
      class="dx-field-label"
      :class="'tooltip-' + name"
      :title="label"
    >{{ label }}
    </div>

    <div class="dx-field-value">
      <component
        :key="name"
        :name="name"
        ref="fields"
        :is="component"
        v-bind="computedOptions"
        :value="computedValue"
        @[changeEvent]="onChange($event, name)"
      />
    </div>
  </div>
</template>

<script>
const ADD_BOX_TYPE = 'AddBox';
const FIELDS_BOX_TYPE = 'FieldsBox';
const COMPLEX_COMPONENT_TYPES = [ADD_BOX_TYPE, FIELDS_BOX_TYPE];

export default {
  name: 'OptionsFormItem',

  props: {
    name: String,
    label: String,
    component: String,
    changeEvent: String,
    options: Object,
    events: Object,
    allValues: Object,
    value: null
  },

  computed: {
    computedValue() {
      //TODO: исправить null после очистки записи

      let emptyValue = null;
      if (this.component === FIELDS_BOX_TYPE) {
        emptyValue = {};
      }

      return this.value || emptyValue;
    },
    computedOptions() {
      let result = {
        ...this.options
      };

      if (Array.isArray(result.buttons)) {
        result.buttons.forEach((buttonConfig) => {
          switch (buttonConfig.name) {
            case 'checkFullSize':
              buttonConfig.options.onClick = () => {
                this.$emit('change', {
                  fieldName: this.name,
                  fieldValue: '100%'
                });
              };
              break;
          }
        });
      }

      if (result.dataSource && typeof result.dataSource === 'string') {
        try {
          result.dataSource = eval(result.dataSource);
        } catch (err) {
          window.console.log(err);
        }
      }

      if (this.events) {
        Object.entries(this.events).forEach(([eventName, callback]) => {
          if (typeof callback === 'function') {
            result[eventName] = callback.bind(this);
          }
        });
      }

      return result;
    }
  },
  methods: {
    onChange(eventData) {
      if (!eventData) {
        return;
      }

      let value;
      if (COMPLEX_COMPONENT_TYPES.includes(this.component)) {
        value = eventData;
      } else {
        value = eventData.component.option('value');
      }
      //Для редактора HTML
      if (eventData.component?.NAME === 'dxHtmlEditor') {
        //Замена перевода строки
        value = value.replaceAll('\n', '<br/>');
      }

      this.$emit('change', {
        fieldName: this.name,
        fieldValue: value
      });
    }
  }
};
</script>

<style lang="scss">
.options-form {
  &__wrapper {
    display: flex;
    flex-direction: column;
    background-color: #fff;
  }
  .dx-field {
    display: flex;
    flex: 0 0 auto;
    flex-wrap: wrap;

    &.column {
      flex-direction: column;

      & > .dx-field-label {
        flex: 0 0 auto;
      }
      & > .dx-field-value {
        width: 100% !important;
      }
    }

    & > .dx-field-label {
      float: none;
      width: auto;
      flex: 0 0 170px;
      text-align: left;
      z-index: 1;
    }
    & > .dx-field-value {
      float: none;
      width: auto;
      flex: 1 1 auto;
    }
    &_field-box {
      & > .dx-field-value {
        width: 100% !important;
      }
    }
  }
}
</style>
