import { jsonRPC } from '@/api/api';
import { PLACE_DATASET } from '../../../utils/const';

const CUBE_INTERFACE_DATASET = 'CONFIG.DSCUBEINTERFACE';
const CUBE_DATASET = 'CONFIG.DSCUBE';
const WORKSPACE_DATASET = 'CONFIG.DSWORKSPACE';
const FIELD_CUBE_DATASET = 'CONFIG.DSFIELDCUBE';
const ELEMENT_TYPE_DATASET = 'CONFIG.DSELEMENTTYPE';

export default {
  /**
   * Загрузка записи в котороый сохранены выбранный куб и рабочая область (cube_id и workspae_id)
   * @param {Object} context
   */
  loadCubeInterface(context) {
    return loadData(context, CUBE_INTERFACE_DATASET, 'setCubeInterface');
  },
  /**
   * Загрузка справочника кубов
   * @param {Object} context
   */
  loadCubes(context) {
    return loadData(context, CUBE_DATASET, 'setCubes');
  },
  /**
   * Загрузка справочника рабочих областей
   * @param {Object} context
   */
  loadWorkspaces(context) {
    return loadData(context, WORKSPACE_DATASET, 'setWorkspaces');
  },
  loadDimensions(context, cube_id) {
    return loadData(context, FIELD_CUBE_DATASET, 'setDimensions', { cube_id, place_code: 'dimension' });
  },
  loadMeasures(context, cube_id) {
    return loadData(context, FIELD_CUBE_DATASET, 'setMeasures', { cube_id, place_code: 'measure' });
  },
  loadElementTypes(context) {
    return loadData(context, ELEMENT_TYPE_DATASET, 'setElementTypes');
  },
  loadPlaces(context, payload) {
    return loadData(context, PLACE_DATASET, 'setPlaces', payload);
  },
  loadFieldCube(context, payload) {
    const param = {
      ...payload,
      _config_dataset: FIELD_CUBE_DATASET
    };

    return jsonRPC('getData', param)
      .then((data) => {
        //context.commit(mutationName, data);
        window.console.log(data);
      })
      .catch((err) => {
        window.console.log(err.message);
      });
  },
  /**
   * Установка поля cubeInterface
   * @param {Object} context
   * @param {Object} payload
   * @param {String} payload.fieldName
   * @param {String} payload.fieldValue
   */
  setCubeInterfaceField(context, { fieldName, fieldValue }) {
    context.commit('setCubeInterfaceField', { fieldName, fieldValue });
  },
  /**
   * Установка строки для фильтрации измерений и мер
   * @param {Object} context
   * @param {String} searchString
   */
  setSearchString(context, searchString) {
    context.commit('setSearchString', searchString);
  },
  /**
   * Установка выбранного типа диаграммы
   * @param {Object} context
   * @param {String} elementType
   */
  setSelectedElementType(context, elementType) {
    context.commit('setSelectedElementType', elementType);
  }
};

function loadData(context, dataSetName, mutationName, payload = {}) {
  const param = {
    ...payload,
    _config_dataset: dataSetName
  };

  return jsonRPC('getData', param)
    .then((data) => {
      context.commit(mutationName, data);
    })
    .catch((err) => {
      window.console.log(err.message);
    });
}
