import ConstructorElement from '../Containers/ConstructorElement';
import { DRAG_RESIZE_CONTAINERS } from '../../utils/const';

export const SELECTED_CSS_CLASS = 'constructor-element_selected';
export const LAST_SELECTED_CSS_CLASS = 'constructor-element_last-selected';

export default {
  components: {
    ConstructorElement
  },
  computed: {
    selectedComponentId() {
      return this.$store.getters['config/selectedComponentId'];
    },
    selectedComponents() {
      return this.$store.getters['config/selectedComponents'];
    },
    constructorElementSelected() {
      return this.selectedComponents.includes(this.componentId);
    },
    constructorElementLastSelected() {
      return this.selectedComponentId === this.componentId;
    },

    constructorActive() {
      return this.$store.getters['uiConstructor/active'];
    },
    selectMode() {
      return this.$store.getters['uiConstructor/selectMode'];
    },

    parentComponentId() {
      return (this.$store.getters['config/getItem'](this.componentId) || {}).parentComponentId || '';
    },
    parentComponentType() {
      return this.$store.getters['config/getComponentType'](this.parentComponentId) || '';
    },
    isParentDragResize() {
      if (!DRAG_RESIZE_CONTAINERS.includes(this.parentComponentType)) {
        return false;
      }

      const { dragResize } = this.$store.getters['config/getComponentOptions'](this.parentComponentId) || {};
      return !!dragResize;
    }
  },

  watch: {
    selectMode() {
      this.$store.dispatch('config/clearSelection');
    }
  },

  methods: {
    onConstructorElemenClick(event) {
      if (!(this.constructorActive && this.selectMode)) {
        return;
      }

      if (!event) {
        this.$store.dispatch('config/clearSelection');
        return;
      }

      if (!this.selectMode && !this.selectedComponentId && !event.ctrlKey) {
        return;
      }

      if (this.selectMode || event.ctrlKey) {
        //не сбрасываем выделение при действиях с компонентами в обетке vue-drag-resize
        if (this.componentId === this.selectedComponentId && this.isParentDragResize) {
          return;
        }

        this.$store.dispatch('config/setConstructorActiveComponent', {
          componentId: this.componentId,
          keepSelected: event.ctrlKey || this.componentId === this.selectedComponentId,
          notUnselect: event.button == 2
        });
        event.stopPropagation();
        event.stopImmediatePropagation();
      } else if (this.selectedComponents.includes(this.componentId)) {
        this.$store.dispatch('config/clearSelection');
        event.stopPropagation();
        event.stopImmediatePropagation();
      }
    },

    onConstructorElementMouseDown(event) {
      //Выделение правым кликом отключено
      /*if (!this.constructorActive) {
        return;
      }

      if (event.button !== 2) {
        return;
      }
      this.onConstructorElemenClick(event);
      // Если клик по компоненту, который уже выделен, переместить его в конец списка
      if (this.selectedComponents.includes(this.componentId)) {
        this.$store.dispatch('config/setComponentLast', { componentId: this.componentId });
        event.stopPropagation();
        return;
      }*/
      // Если клик по компоненту, которого нет среди уже выделенных, выделить только его
      /*if (!this.selectedComponentId || !this.selectedComponents.includes(this.componentId)) {
        //Не выделять правой кнопкой мыши
        this.$store.dispatch('config/setConstructorActiveComponent', {
          componentId: this.componentId,
          keepSelected: false
        });
        event.stopPropagation();
        return;
      }*/
    }
  }
};
