//Локальные названия геттеров
const PREFIX = 'Dictionaries';

export const getItemById = `${PREFIX}/getItemById`;

export default {
  [getItemById]: (state) => (dictionaryName, key) => {
    if (!state.dictionaries[dictionaryName] || !state.dictionaries[dictionaryName].data) {
      return null;
    }
    const keyExpr = state.dictionaries[dictionaryName].key;
    return state.dictionaries[dictionaryName].data.find((item) => item[keyExpr] === key) || null;
  }
};
