/**Классы кнопок видимости слоев */
export const VISIBLE_BUTTON_ICONS = ['core core-193_halfeye', 'core core-081-no-visible', 'core core-082-visible'];

export const VISIBLE_ICON_CSS = 'core core-082-visible';
export const NO_VISIBLE_ICON_CSS = 'core core-081-no-visible';
export const HALF_VISIBLE_ICON_CSS = 'core core-193_halfeye';

export const EXPANDED_ICON_CSS = 'core core-093-folder-opened';
export const NO_EXPANDED_ICON_CSS = 'core core-092-folder';

export const DBL_CLICK_INTERVAL = 500;

export const WFS_VERSION = '2.0.1';

export const PROTOCOLS = ['http://', 'https://'];

export const NO_PROXY_HOSTS = ['https://xn--c1ak1a.xn--d1aqf.xn--p1ai/'];

export const SCANEX_HOSTS = process.env.VUE_APP_SCANEX_HOSTS
  ? process.env.VUE_APP_SCANEX_HOSTS.split(',')
  : ['http://maps.kosmosnimki.ru', 'https://maps.kosmosnimki.ru', 'http://geomixer.scanex.ru', 'https://geomixer.scanex.ru'];

/**Поле датасета для определения прозрачности маркеров слоя */
export const OPACITY_MONTH_DIFF_ATTRIBUTE = 'date_diff';

export const PKK_LAYER_TITLE = 'Публичная кадастровая карта';
/**Маркер при идентификации, поиске*/
export const IDENT_MARKER_ID = 'identification-marker';
/**Маркер для панорамы */
export const PANORAMA_MARKER_ID = 'panorama-marker';
