export default class ReportImageLegend {
  /**Гуид изображения для отчета */
  reportimage_guid = null;

  /**Идентификатор DSREPORTIMAGE */
  reportimage_id = null;

  /**Идентификатор компонента-слоя */
  component_id = null;

  /**Название */
  component_name = '';

  /**Изображение легенды в формате base64 */
  legend_image = '';

  /**Идентификатор правила */
  rule_id = null;

  /**Название правила */
  rule_name = null;

  constructor(options) {
    this.reportimage_guid = options.reportimage_guid;
    this.reportimage_id = options.reportimage_id;
    this.component_id = options.component_id;
    this.component_name = options.component_name;
    this.legend_image = options.legend_image;
    this.rule_id = options.rule_id;
    this.rule_name = options.rule_name;
  }
}
