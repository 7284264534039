import getConfigs from './configUtils';
const OPTIONS = ['connection', 'dataset', 'filterId', 'dataSetName'];

const CONFIGS_FIELDS = {
  connection: {
    name: 'connection',
    label: 'Данные',
    component: 'DxLookup',
    options: {
      dataSource: `this.$store.getters['uiConstructor/connections']`,
      valueExpr: 'code',
      displayExpr: 'code',
      searchExpr: ['code'],
      showClearButton: true,
      showPopupTitle: false,
      dropDownOptions: {
        closeOnOutsideClick: true
      }
    },
    events: {
      onValueChanged: function ({ value }) {
        const datasetName = (value ?? '') + '.' + (this.allValues['dataset'] ?? '');
        this.$store.dispatch('uiConstructor/loadDatasets', value);
        this.$store.dispatch('uiConstructor/loadFilters', { datasetName });
      },
      onInitialized: function ({ component }) {
        const value = component.instance().option('value');
        if (value) {
          const datasetName = (value ?? '') + '.' + (this.allValues['dataset'] ?? '');
          this.$store.dispatch('uiConstructor/loadDatasets', value);
          this.$store.dispatch('uiConstructor/loadFilters', { datasetName });
        }
      }
    },
    changeEvent: 'valueChanged'
  },
  dataset: {
    name: 'dataset',
    label: '',
    component: 'DxLookup',
    options: {
      dataSource: `this.$store.getters['uiConstructor/datasets']`,
      valueExpr: 'code',
      displayExpr: 'code',
      searchExpr: ['name', 'code'],
      showClearButton: true,
      showPopupTitle: false,
      dropDownOptions: {
        closeOnOutsideClick: true
      }
    },
    events: {
      onValueChanged: function ({ value }) {
        const datasetName = (this.allValues['connection'] ?? '') + '.' + (value ?? '');
        this.$store.dispatch('uiConstructor/loadFilters', { datasetName });
      },
      onInitialized: function ({ component }) {
        const value = component.instance().option('value');
        if (value) {
          const datasetName = (this.allValues['connection'] ?? '') + '.' + (value ?? '');
          this.$store.dispatch('uiConstructor/loadFilters', { datasetName });
        }
      }
    },
    changeEvent: 'valueChanged'
  },
  filterId: {
    name: 'filterId',
    label: 'Фильтр',
    component: 'DxLookup',
    options: {
      dataSource: `this.$store.getters['uiConstructor/filters']`,
      valueExpr: 'id',
      displayExpr: 'name',
      searchExpr: ['name', 'code'],
      showClearButton: true,
      showPopupTitle: false,
      dropDownOptions: {
        closeOnOutsideClick: true
      }
    },
    changeEvent: 'valueChanged'
  },
  dataSetName: {
    name: 'dataSetName',
    fieldsPart: ['connection', 'dataset'],
    separator: '.'
  }
};

export default getConfigs(OPTIONS, CONFIGS_FIELDS);
