//Hазвания мутаций
const PREFIX = 'Gis';
export const SET_VISIBLE_LAYER_COMPONENTS = `${PREFIX}/SET_VISIBLE_LAYER_COMPONENTS`;
export const SET_ACTIVE_BASE_MAP_LAYER_COMPONENT_ID = `${PREFIX}/SET_ACTIVE_BASE_MAP_LAYER_COMPONENT_ID`;
export const SET_ACTIVE_BASE_TEXT_LAYER_COMPONENT_ID = `${PREFIX}/SET_ACTIVE_BASE_TEXT_LAYER_COMPONENT_ID`;
export const SET_ACTIVE_TERRAIN_LAYER_COMPONENT_ID = `${PREFIX}/SET_ACTIVE_TERRAIN_LAYER_COMPONENT_ID`;
export const INIT_OPTIONS = `${PREFIX}/INIT_OPTIONS`;
export const SET_LEGEND_LAYER_SEARCH_VALUE = `${PREFIX}/SET_LEGEND_LAYER_SEARCH_VALUE`;
export const SET_ACTIVE_LEGEND_LAYER_COMPONENT_ID = `${PREFIX}/SET_ACTIVE_LEGEND_LAYER_COMPONENT_ID`;
export const SET_LAST_LAYER_CLICK = `${PREFIX}/SET_LAST_LAYER_CLICK`;
export const SET_LAST_BASE_LAYER_CLICK = `${PREFIX}/SET_LAST_BASE_LAYER_CLICK`;
export const SET_POPUP_MENU_LAYER_COMPONENT_ID = `${PREFIX}/SET_POPUP_MENU_LAYER_COMPONENT_ID`;
export const SET_ICON_TOOLTIP_LAYER_COMPONENT_ID = `${PREFIX}/SET_ICON_TOOLTIP_LAYER_COMPONENT_ID`;
export const SET_DBL_CLICKED_LAYER_COMPONENT_ID = `${PREFIX}/SET_DBL_CLICKED_LAYER_COMPONENT_ID`;
export const SET_EDITING_LAYER_COMPONENT_ID = `${PREFIX}/SET_EDITING_LAYER_COMPONENT_ID`;
export const SET_POPUP_MENU_LAYER_TARGET_ELEMENT = `${PREFIX}/SET_POPUP_MENU_LAYER_TARGET_ELEMENT`;
export const SET_TABLE_PANEL_LAYER_COMPONENT_ID = `${PREFIX}/SET_TABLE_PANEL_LAYER_COMPONENT_ID`;
export const SET_STATS_PANEL_LAYER_COMPONENT_ID = `${PREFIX}/SET_STATS_PANEL_LAYER_COMPONENT_ID`;
export const SET_STYLE_FORM_LAYER_COMPONENT_ID = `${PREFIX}/SET_STYLE_FORM_LAYER_COMPONENT_ID`;
export const SET_TOOLTIP_FORM_LAYER_COMPONENT_ID = `${PREFIX}/SET_TOOLTIP_FORM_LAYER_COMPONENT_ID`;
export const SET_FILTER_PANEL_LAYER_COMPONENT_ID = `${PREFIX}/SET_FILTER_PANEL_LAYER_COMPONENT_ID`;
export const SET_SIGN_LAYER_COMPONENT_ID = `${PREFIX}/SET_SIGN_LAYER_COMPONENT_ID`;
export const SET_BINDING_TILE_LAYER_ID = `${PREFIX}/SET_BINDING_TILE_LAYER_ID`;
export const SET_LOADING_IMAGE_DATA_PROMISE = `${PREFIX}/SET_LOADING_IMAGE_DATA_PROMISE`;
export const SET_VALIDATION_LIST = `${PREFIX}/SET_VALIDATION_LIST`;
export const SET_SELECTED_VALIDATION_IDS_LIST = `${PREFIX}/SET_SELECTED_VALIDATION_IDS_LIST`;
export const SET_SELECTED_VALIDATION_IDS_LIST_FROM_DATASET = `${PREFIX}/SET_SELECTED_VALIDATION_IDS_LIST_FROM_DATASET`;
export const SET_LOADING_IMAGE_PROMISE = `${PREFIX}/SET_LOADING_IMAGE_PROMISE`;
export const ADD_IMAGE = `${PREFIX}/ADD_IMAGE`;
export const SET_DOWNLOAD_LINK = `${PREFIX}/SET_DOWNLOAD_LINK`;
export const SET_IDENTIFICATION_MAPBOX_SOURCE_ID = `${PREFIX}/SET_IDENTIFICATION_MAPBOX_SOURCE_ID`;
export const SET_IDENTIFICATION_MAPBOX_SOURCE_LAYER = `${PREFIX}/SET_IDENTIFICATION_MAPBOX_SOURCE_LAYER`;
export const SET_IDENTIFICATION_MAPBOX_GEOJSON = `${PREFIX}/SET_IDENTIFICATION_MAPBOX_GEOJSON`;
export const SET_IDENTIFICATION_MAPBOX_FEATURE_ID = `${PREFIX}/SET_IDENTIFICATION_MAPBOX_FEATURE_ID`;
export const CLEAR_MAPBOX_IDENTIFICATION = `${PREFIX}/CLEAR_MAPBOX_IDENTIFICATION`;
export const SET_IDENTIFICATION_PKK_OBJECT_ID = `${PREFIX}/SET_IDENTIFICATION_PKK_OBJECT_ID`;
export const SET_COORDINATE_TABLE_MAPBOX_GEOJSON = `${PREFIX}/SET_COORDINATE_TABLE_MAPBOX_GEOJSON`;
export const SET_COORDINATE_TABLE_VISIBLE = `${PREFIX}/SET_COORDINATE_TABLE_VISIBLE`;
export const SET_BOUNDS = `${PREFIX}/SET_BOUNDS`;
export const SET_ITEMS = `${PREFIX}/SET_ITEMS`;
export const ADD_LEGEND_NO_PARENT_LAYER = `${PREFIX}/ADD_LEGEND_NO_PARENT_LAYER`;
export const ADD_COSMETIC_LAYER = `${PREFIX}/ADD_COSMETIC_LAYER`;
export const REMOVE_COSMETIC_LAYER = `${PREFIX}/REMOVE_COSMETIC_LAYER`;
export const SET_REPORT_DRAW_LAYER = `${PREFIX}/SET_REPORT_DRAW_LAYER`;
export const REMOVE_LEGEND_NO_PARENT_LAYER = `${PREFIX}/REMOVE_LEGEND_NO_PARENT_LAYER`;
export const SET_REPORT_LAYER_COMPONENT_ID = `${PREFIX}/SET_REPORT_LAYER_COMPONENT_ID`;
export const SET_REPORT_FEATURE_ID = `${PREFIX}/REPORT_FEATURE_ID`;
export const SET_REPORT_CREATING_STATE = `${PREFIX}/SET_REPORT_CREATING_STATE`;
export const SET_VISIBLE_FEATURES = `${PREFIX}/SET_VISIBLE_FEATURES`;
export const SET_SNAPPING_TOOL = `${PREFIX}/SET_SNAPPING_TOOL`;
export const SET_SELECTED_INTERSECT_TOOL = `${PREFIX}/SET_SELECTED_INTERSECT_TOOL`;
export const SET_ACTIVE_LEGEND_PANEL = `${PREFIX}/SET_ACTIVE_LEGEND_PANEL`;
export const SET_ACTIVE_FULL_LAYERS_CATALOG = `${PREFIX}/SET_ACTIVE_FULL_LAYERS_CATALOG`;
export const SET_ACTIVE_LAYER_LEGEND_ID = `${PREFIX}/SET_LAYER_LEGEND_ID`;
export const SET_ACTIVE_FILTER_PANEL_MINIMIZE = `${PREFIX}/SET_ACTIVE_FILTER_PANEL_MINIMIZE`;
export const SET_SNAPPING_POINT = `${PREFIX}/SET_SNAPPING_POINT`;
export const SET_SELECTION_GEOJSON = `${PREFIX}/SET_SELECTION_GEOJSON`;
export const SET_SELECTED_OBJECT_INTERSECT_GEOJSON = `${PREFIX}/SET_SELECTED_OBJECT_INTERSECT_GEOJSON`;
export const SET_BINDING_TILE_GEOJSON = `${PREFIX}/SET_BINDING_TILE_GEOJSON`;
export const SET_HEATMAP_OPTION = `${PREFIX}/SET_HEATMAP_OPTION`;
export const SET_VALIDATION_LAYER_OBJECT_INTERSECT_GEOJSON = `${PREFIX}/SET_VALIDATION_LAYER_OBJECT_INTERSECT_GEOJSON`;
export const SET_MAP_DIAGRAMM = `${PREFIX}/SET_MAP_DIAGRAMM`;
export const SET_SELECTED_DATE = `${PREFIX}/SET_SELECTED_DATE`;
export const SET_SELECTED_MOUSE_TOOL = `${PREFIX}/SET_SELECTED_MOUSE_TOOL`;
export const SET_SELECTED_TIMELINE_MODE = `${PREFIX}/SET_SELECTED_TIMELINE_MODE`;
export const SET_SELECTABLE_LAYER_IDS = `${PREFIX}/SET_SELECTABLE_LAYER_IDS`;
export const SET_FAVORITE_LAYER_ITEMS = `${PREFIX}/SET_FAVORITE_LAYER_ITEMS`;
export const ADD_FAVORITE_LAYER_ITEM = `${PREFIX}/ADD_FAVORITE_LAYER_ITEM`;
export const REMOVE_FAVORITE_LAYER_ITEM = `${PREFIX}/REMOVE_FAVORITE_LAYER_ITEM`;
export const SET_RECENT_LAYER_ITEMS = `${PREFIX}/SET_RECENT_LAYER_ITEMS`;
export const ADD_RECENT_LAYER_ITEM = `${PREFIX}/ADD_RECENT_LAYER_ITEM`;
export const SET_LEGEND_LAYERS_FILTER = `${PREFIX}/SET_LEGEND_LAYERS_FILTER`;
export const SET_SPACE_IMAGERY_PANEL_VISIBLE = `${PREFIX}/SET_SPACE_IMAGERY_PANEL_VISIBLE`;
export const SET_SPACE_IMAGERY_FOUND_FEATURES = `${PREFIX}/SET_SPACE_IMAGERY_FOUND_FEATURES`;
export const SET_SPACE_IMAGERY_HOVERED_FEATURE_ID = `${PREFIX}/SET_SPACE_IMAGERY_HOVERED_FEATURE_ID`;
export const SET_SPACE_IMAGERY_VISIBLE_FEATURE_IDS = `${PREFIX}/SET_SPACE_IMAGERY_VISIBLE_FEATURE_IDS`;
export const SET_CALC_FORM_OPTION = `${PREFIX}/SET_CALC_FORM_OPTION`;
export const SET_MAP_CURSOR_STYLE = `${PREFIX}/SET_MAP_CURSOR_STYLE`;
export const SET_SKY_OPTIONS = `${PREFIX}/SET_SKY_OPTIONS`;
export const SET_BASE_MAP_RASTER_OPACITY = `${PREFIX}/SET_BASE_MAP_RASTER_OPACITY`;
export const SET_BASE_TEXT_MAP_RASTER_OPACITY = `${PREFIX}/SET_BASE_TEXT_MAP_RASTER_OPACITY`;
export const SET_POINT_AVAILABILITY_NEAR_ORGANISATION_GEOJSON = `${PREFIX}/SET_POINT_AVAILABILITY_NEAR_ORGANISATION_GEOJSON`;
export const SET_POINT_AVAILABILITY_OBJECT_PATH_GEOJSON = `${PREFIX}/SET_POINT_AVAILABILITY_OBJECT_PATH_GEOJSON`;
export const SET_POINT_AVAILABILITY_IS_ZDRAV_LAYER = `${PREFIX}/SET_POINT_AVAILABILITY_IS_ZDRAV_LAYER`;
export const SET_POINT_AVAILABILITY_ROUTE_MODE = `${PREFIX}/SET_POINT_AVAILABILITY_ROUTE_MODE`;
export const SET_POINT_AVAILABILITY_ACTIVE_ROUTE_OBJECT_ID = `${PREFIX}/SET_POINT_AVAILABILITY_ACTIVE_ROUTE_OBJECT_ID`;
export const SET_MAPBOX = `${PREFIX}/SET_MAPBOX`;
export const SET_OPEN_CARDS_GRID = `${PREFIX}/SET_OPEN_CARDS_GRID`;
export const SET_GEOCODE_SYSTEM = `${PREFIX}/SET_GEOCODE_SYSTEM`;
export const SET_SELECTED_OBJECT_ID = `${PREFIX}/SET_SELECTED_OBJECT_ID`;
export const SET_PART_TOOL_MODE = `${PREFIX}/SET_PART_TOOL_MODE`;
export const SET_TMP_LAYER_CARD_ID = `${PREFIX}/SET_TMP_LAYER_CARD_ID`;
export const SET_TMP_LAYER_CARD_POPUP_VISIBLE = `${PREFIX}/SET_TMP_LAYER_CARD_POPUP_VISIBLE`;

const BASEMAP_LOCAL_STORAGE = 'activeBaseMap';
const TEXTMAP_LOCAL_STORAGE = 'activeTextMap';
const TERRAINMAP_LOCAL_STORAGE = 'activeTerrainMap';
const BASE_MAP_EMPTY_ID = 'NO_BASE_MAP';

export default {
  [SET_VISIBLE_LAYER_COMPONENTS](state, { gisComponentId, gisMinimizeVisibleLayerIds }) {
    setGisOption(state, gisComponentId, 'gisMinimizeVisibleLayerIds', gisMinimizeVisibleLayerIds);
  },

  [SET_ACTIVE_BASE_MAP_LAYER_COMPONENT_ID](state, { gisComponentId, layerComponentId }) {
    setGisOption(state, gisComponentId, 'activeBaseMapLayerComponentId', layerComponentId);
    if (state.componentOptions[gisComponentId].persistLayerState) {
      if (layerComponentId) {
        localStorage.setItem(`${BASEMAP_LOCAL_STORAGE}_${gisComponentId}`, layerComponentId);
      } else {
        localStorage.removeItem(`${BASEMAP_LOCAL_STORAGE}_${gisComponentId}`);
      }
    }
  },

  [SET_ACTIVE_BASE_TEXT_LAYER_COMPONENT_ID](state, { gisComponentId, layerComponentId }) {
    setGisOption(state, gisComponentId, 'activeBaseTextLayerComponentId', layerComponentId);
    if (state.componentOptions[gisComponentId].persistLayerState) {
      if (layerComponentId) {
        localStorage.setItem(`${TEXTMAP_LOCAL_STORAGE}_${gisComponentId}`, layerComponentId);
      } else {
        localStorage.removeItem(`${TEXTMAP_LOCAL_STORAGE}_${gisComponentId}`);
      }
    }
  },

  [SET_ACTIVE_TERRAIN_LAYER_COMPONENT_ID](state, { gisComponentId, layerComponentId }) {
    setGisOption(state, gisComponentId, 'activeTerrainLayerComponentId', layerComponentId);
    if (state.componentOptions[gisComponentId].persistLayerState) {
      if (layerComponentId) {
        localStorage.setItem(`${TERRAINMAP_LOCAL_STORAGE}_${gisComponentId}`, layerComponentId);
      } else {
        localStorage.removeItem(`${TERRAINMAP_LOCAL_STORAGE}_${gisComponentId}`);
      }
    }
  },

  [INIT_OPTIONS](state, { componentId }) {
    const gisOptions = state.componentOptions[componentId];
    prepareBaseMaps(state, gisOptions);
  },

  [SET_LEGEND_LAYER_SEARCH_VALUE](state, { componentId, legendLayerSearchValue }) {
    setGisOption(state, componentId, 'legendLayerSearchValue', legendLayerSearchValue);
  },

  [SET_ACTIVE_LEGEND_LAYER_COMPONENT_ID](state, { gisComponentId, layerComponentId }) {
    setGisOption(state, gisComponentId, 'activeLegendLayerComponentId', layerComponentId);
  },

  [SET_LAST_LAYER_CLICK](state, { gisComponentId }) {
    setGisOption(state, gisComponentId, 'lastLayerClickTime', new Date().getTime());
  },

  [SET_LAST_BASE_LAYER_CLICK](state, { gisComponentId }) {
    setGisOption(state, gisComponentId, 'lastBaseLayerClickTime', new Date().getTime());
  },

  [SET_POPUP_MENU_LAYER_COMPONENT_ID](state, { gisComponentId, layerComponentId }) {
    setGisOption(state, gisComponentId, 'popupMenuLayerComponentId', layerComponentId);
  },

  [SET_ICON_TOOLTIP_LAYER_COMPONENT_ID](state, { gisComponentId, layerComponentId }) {
    setGisOption(state, gisComponentId, 'iconTooltipLayerComponentId', layerComponentId);
  },

  [SET_DBL_CLICKED_LAYER_COMPONENT_ID](state, { gisComponentId, layerComponentId }) {
    setGisOption(state, gisComponentId, 'dblClickedLayerComponentId', layerComponentId);
  },

  [SET_EDITING_LAYER_COMPONENT_ID](state, { gisComponentId, layerComponentId }) {
    setGisOption(state, gisComponentId, 'editingLayerComponentId', layerComponentId);
  },

  [SET_POPUP_MENU_LAYER_TARGET_ELEMENT](state, { gisComponentId, targetElement }) {
    setGisOption(state, gisComponentId, 'popupMenuLayerTargetElement', targetElement);
  },

  [SET_TABLE_PANEL_LAYER_COMPONENT_ID](state, { gisComponentId, layerComponentId }) {
    setGisOption(state, gisComponentId, 'tablePanelLayerComponentId', layerComponentId);
  },

  [SET_STATS_PANEL_LAYER_COMPONENT_ID](state, { gisComponentId, layerComponentId }) {
    setGisOption(state, gisComponentId, 'statsPanelLayerComponentId', layerComponentId);
  },

  [SET_STYLE_FORM_LAYER_COMPONENT_ID](state, { gisComponentId, layerComponentId }) {
    setGisOption(state, gisComponentId, 'styleFormLayerComponentId', layerComponentId);
  },

  [SET_TOOLTIP_FORM_LAYER_COMPONENT_ID](state, { gisComponentId, layerComponentId }) {
    setGisOption(state, gisComponentId, 'tooltipFormLayerComponentId', layerComponentId);
  },

  [SET_FILTER_PANEL_LAYER_COMPONENT_ID](state, { gisComponentId, layerComponentId }) {
    setGisOption(state, gisComponentId, 'filterPanelLayerComponentId', layerComponentId);
  },

  [SET_SIGN_LAYER_COMPONENT_ID](state, { gisComponentId, layerComponentId }) {
    setGisOption(state, gisComponentId, 'signLayerPanelLayerComponentId', layerComponentId);
  },

  [SET_BINDING_TILE_LAYER_ID](state, { gisComponentId, layerComponentId }) {
    setGisOption(state, gisComponentId, 'bindingTileLayerId', layerComponentId);
  },

  [SET_VALIDATION_LIST](state, { gisComponentId, validationList }) {
    setGisOption(state, gisComponentId, 'validationList', validationList);
  },

  [SET_SELECTED_VALIDATION_IDS_LIST](state, { gisComponentId, selectedValidationsIdsList }) {
    setGisOption(state, gisComponentId, 'selectedValidationsIdsList', selectedValidationsIdsList);
  },

  [SET_SELECTED_VALIDATION_IDS_LIST_FROM_DATASET](state, { gisComponentId, selectedValidationsIdsList }) {
    setGisOption(state, gisComponentId, 'selectedValidationsIdsListFromDataset', selectedValidationsIdsList);
  },

  [SET_CALC_FORM_OPTION](state, { gisComponentId, option, value }) {
    const gisOptions = state.componentOptions[gisComponentId];

    if (!gisOptions) {
      return;
    }

    gisOptions.calcForm[option] = value;
  },

  [SET_LOADING_IMAGE_DATA_PROMISE](state, { gisComponentId, imageName, promise }) {
    const gisOptions = state.componentOptions[gisComponentId];

    if (!gisOptions) {
      return;
    }

    gisOptions.loadingImageDataPromises[imageName] = promise;
  },

  [SET_LOADING_IMAGE_PROMISE](state, { gisComponentId, imageName, promise }) {
    const gisOptions = state.componentOptions[gisComponentId];

    if (!gisOptions) {
      return;
    }

    gisOptions.loadingImagePromises[imageName] = promise;
  },

  [ADD_IMAGE](state, { gisComponentId, imageName, image }) {
    state.componentOptions[gisComponentId].images = {
      ...state.componentOptions[gisComponentId].images,
      [imageName]: image
    };
  },

  [SET_DOWNLOAD_LINK](state, { gisComponentId, downloadLink }) {
    setGisOption(state, gisComponentId, 'downloadLink', downloadLink);
  },

  [SET_IDENTIFICATION_MAPBOX_SOURCE_ID](state, { gisComponentId, identificationMapboxSourceId }) {
    setGisOption(state, gisComponentId, 'identificationMapboxSourceId', identificationMapboxSourceId);
  },

  [SET_IDENTIFICATION_MAPBOX_SOURCE_LAYER](state, { gisComponentId, identificationMapboxSourceLayer }) {
    setGisOption(state, gisComponentId, 'identificationMapboxSourceLayer', identificationMapboxSourceLayer);
  },

  [SET_IDENTIFICATION_MAPBOX_GEOJSON](state, { gisComponentId, identificationMapboxGeoJson }) {
    setGisOption(state, gisComponentId, 'identificationMapboxGeoJson', identificationMapboxGeoJson);
  },

  [SET_IDENTIFICATION_MAPBOX_FEATURE_ID](state, { gisComponentId, identificationMapboxFeatureId }) {
    setGisOption(state, gisComponentId, 'identificationMapboxFeatureId', identificationMapboxFeatureId);
  },

  [CLEAR_MAPBOX_IDENTIFICATION](state, { gisComponentId }) {
    setGisOption(state, gisComponentId, 'identificationMapboxSourceId', null);
    setGisOption(state, gisComponentId, 'identificationMapboxSourceLayer', null);
    setGisOption(state, gisComponentId, 'identificationMapboxFeatureId', null);
    setGisOption(state, gisComponentId, 'identificationMapboxGeoJson', null);
  },

  [SET_IDENTIFICATION_PKK_OBJECT_ID](state, { gisComponentId, identificationPkkObjectId }) {
    setGisOption(state, gisComponentId, 'identificationPkkObjectId', identificationPkkObjectId);
  },

  [SET_COORDINATE_TABLE_MAPBOX_GEOJSON](state, { gisComponentId, geojson }) {
    setGisOption(state, gisComponentId, 'coordinateTableMapboxGeoJson', geojson);
  },

  [SET_COORDINATE_TABLE_VISIBLE](state, { gisComponentId, visible }) {
    setGisOption(state, gisComponentId, 'showCoordinatesEditor', visible);
  },

  [SET_BOUNDS](state, { gisComponentId, bounds, zoom }) {
    setGisOption(state, gisComponentId, 'boxZoom', zoom);
    setGisOption(state, gisComponentId, 'bounds', bounds);
  },

  [SET_ITEMS](state, { gisComponentId, items }) {
    setGisOption(state, gisComponentId, 'items', items);
  },

  [SET_REPORT_DRAW_LAYER](state, { gisComponentId, layerComponentId }) {
    const gisOptions = state.componentOptions[gisComponentId];
    if (!gisOptions) {
      return;
    }

    gisOptions.reportDrawLayerId = layerComponentId;
  },

  [ADD_LEGEND_NO_PARENT_LAYER](state, { gisComponentId, layerComponentId }) {
    const gisOptions = state.componentOptions[gisComponentId];
    if (!gisOptions) {
      return;
    }

    gisOptions.legendNoParentLayerIds.push(layerComponentId);
  },

  [REMOVE_LEGEND_NO_PARENT_LAYER](state, { gisComponentId, layerComponentId }) {
    const gisOptions = state.componentOptions[gisComponentId];
    if (!gisOptions) {
      return;
    }

    if (!Array.isArray(gisOptions.legendNoParentLayerIds)) {
      return;
    }

    const index = gisOptions.legendNoParentLayerIds.indexOf(layerComponentId);
    if (index !== -1) {
      gisOptions.legendNoParentLayerIds.splice(index, 1);
    }
  },

  [ADD_COSMETIC_LAYER](state, { gisComponentId, id, source, layers }) {
    //console.log('change ' + source.data.geometry.coordinates);
    const gisOptions = state.componentOptions[gisComponentId];
    if (gisOptions) {
      const layerIndex = gisOptions.tempCosmeticLayers.findIndex((item) => item.id === id);
      if (layerIndex >= 0) {
        //Замена существующего слоя
        gisOptions.tempCosmeticLayers.splice(layerIndex, 1);
        gisOptions.tempCosmeticLayers.push({ id, source, layers });
      } else {
        //Добавление нового
        gisOptions.tempCosmeticLayers.push({ id, source, layers });
      }
    }
  },

  [REMOVE_COSMETIC_LAYER](state, { gisComponentId, id }) {
    const gisOptions = state.componentOptions[gisComponentId];
    if (gisOptions) {
      const index = gisOptions.tempCosmeticLayers.findIndex((item) => item.id == id);
      if (index >= 0) {
        gisOptions.tempCosmeticLayers.splice(index, 1);
      }
    }
  },

  [SET_REPORT_LAYER_COMPONENT_ID](state, { gisComponentId, reportLayerComponentId }) {
    setGisOption(state, gisComponentId, 'reportLayerComponentId', reportLayerComponentId);
  },

  [SET_REPORT_FEATURE_ID](state, { gisComponentId, reportFeatureId }) {
    setGisOption(state, gisComponentId, 'reportFeatureId', reportFeatureId);
  },

  [SET_REPORT_CREATING_STATE](state, { gisComponentId, reportCreatingState }) {
    setGisOption(state, gisComponentId, 'reportCreatingState', reportCreatingState);
  },

  [SET_SNAPPING_TOOL](state, { gisComponentId, snappingTool }) {
    setGisOption(state, gisComponentId, 'activeSnapTool', snappingTool);
  },

  [SET_SELECTED_INTERSECT_TOOL](state, { gisComponentId, value }) {
    setGisOption(state, gisComponentId, 'activeSelectedIntersectTool', value);
  },

  [SET_ACTIVE_LEGEND_PANEL](state, { gisComponentId, value }) {
    setGisOption(state, gisComponentId, 'activeLegendPanel', value);
  },

  [SET_ACTIVE_FULL_LAYERS_CATALOG](state, { gisComponentId, value }) {
    setGisOption(state, gisComponentId, 'activeFullLayersCatalog', value);
  },

  [SET_ACTIVE_LAYER_LEGEND_ID](state, { gisComponentId, value }) {
    setGisOption(state, gisComponentId, 'activeLayerLegendId', value);
  },

  [SET_ACTIVE_FILTER_PANEL_MINIMIZE](state, { gisComponentId, value }) {
    setGisOption(state, gisComponentId, 'activeFilterPanelMinimize', value);
  },

  [SET_VISIBLE_FEATURES](state, { gisComponentId, visibleFeatures }) {
    setGisOption(state, gisComponentId, 'visibleFeatures', visibleFeatures);
  },

  [SET_SNAPPING_POINT](state, { gisComponentId, lngLat }) {
    setGisOption(state, gisComponentId, 'activeSnapLngLat', lngLat);
  },

  [SET_SELECTION_GEOJSON](state, { gisComponentId, geoJson }) {
    setGisOption(state, gisComponentId, 'bufferSelectionGeoJson', geoJson);
  },

  [SET_SELECTED_OBJECT_INTERSECT_GEOJSON](state, { gisComponentId, geoJson }) {
    setGisOption(state, gisComponentId, 'selectedObjectIntersectGeoJson', geoJson);
  },

  [SET_BINDING_TILE_GEOJSON](state, { gisComponentId, geoJson }) {
    setGisOption(state, gisComponentId, 'bindingTileLayerGeoJson', geoJson);
  },

  [SET_HEATMAP_OPTION](state, { gisComponentId, option, value }) {
    const gisOptions = state.componentOptions[gisComponentId];

    if (!gisOptions) {
      return;
    }

    gisOptions.heatmapOptions[option] = value;
  },

  [SET_POINT_AVAILABILITY_NEAR_ORGANISATION_GEOJSON](state, { gisComponentId, geoJson }) {
    setGisOption(state, gisComponentId, 'pointAvailabilityNearOrganisation', geoJson);
  },

  [SET_POINT_AVAILABILITY_OBJECT_PATH_GEOJSON](state, { gisComponentId, geoJson }) {
    setGisOption(state, gisComponentId, 'pointAvailabilityObjectPath', geoJson);
  },

  [SET_POINT_AVAILABILITY_ACTIVE_ROUTE_OBJECT_ID](state, { gisComponentId, objectId }) {
    setGisOption(state, gisComponentId, 'pointAvailabilityActiveRouteObjectId', objectId);
  },

  [SET_POINT_AVAILABILITY_IS_ZDRAV_LAYER](state, { gisComponentId, isZdrav }) {
    setGisOption(state, gisComponentId, 'pointAvailabilityIsZdravLayer', isZdrav);
  },

  [SET_POINT_AVAILABILITY_ROUTE_MODE](state, { gisComponentId, indexTab }) {
    setGisOption(state, gisComponentId, 'pointAvailabilityRouteMode', indexTab);
  },

  [SET_VALIDATION_LAYER_OBJECT_INTERSECT_GEOJSON](state, { gisComponentId, geoJson }) {
    setGisOption(state, gisComponentId, 'validationLayerObjectIntersectGeoJson', geoJson);
  },

  [SET_MAP_DIAGRAMM](state, { gisComponentId, mapDiagramData }) {
    setGisOption(state, gisComponentId, 'mapDiagramData', mapDiagramData);
  },

  [SET_SELECTED_DATE](state, { gisComponentId, selectedDate }) {
    setGisOption(state, gisComponentId, 'selectedDate', selectedDate);
  },

  [SET_SELECTED_TIMELINE_MODE](state, { gisComponentId, selectedTimelineMode }) {
    setGisOption(state, gisComponentId, 'selectedTimelineMode', selectedTimelineMode);
  },

  [SET_SELECTABLE_LAYER_IDS](state, { gisComponentId, selectableLayerIds }) {
    setGisOption(state, gisComponentId, 'selectableLayerIds', selectableLayerIds);
  },

  [SET_FAVORITE_LAYER_ITEMS](state, { gisComponentId, favoriteLayerItems }) {
    setGisOption(state, gisComponentId, 'favoriteLayerItems', favoriteLayerItems);
  },

  [ADD_FAVORITE_LAYER_ITEM](state, { gisComponentId, gisLayerFavoriteItem }) {
    const gisOptions = state.componentOptions[gisComponentId];
    if (!gisOptions) {
      return;
    }

    gisOptions.favoriteLayerItems.push(gisLayerFavoriteItem);
  },

  [REMOVE_FAVORITE_LAYER_ITEM](state, { gisComponentId, layerComponentId }) {
    const gisOptions = state.componentOptions[gisComponentId];
    if (!gisOptions) {
      return;
    }

    const indexForRemove = gisOptions.favoriteLayerItems.findIndex((item) => {
      return item.componentId === layerComponentId;
    });

    if (indexForRemove !== -1) {
      gisOptions.favoriteLayerItems.splice(indexForRemove, 1);
    }
  },

  [SET_RECENT_LAYER_ITEMS](state, { gisComponentId, recentLayerItems }) {
    setGisOption(state, gisComponentId, 'recentLayerItems', recentLayerItems);
  },

  [ADD_RECENT_LAYER_ITEM](state, { gisComponentId, gisLayerRecentItem }) {
    const gisOptions = state.componentOptions[gisComponentId];
    if (!gisOptions) {
      return;
    }

    const { maxRecentLayers } = gisOptions;

    if (gisOptions.recentLayerItems.length >= maxRecentLayers) {
      gisOptions.recentLayerItems = [gisLayerRecentItem, ...gisOptions.recentLayerItems.slice(0, maxRecentLayers - 1)];
    } else {
      gisOptions.recentLayerItems.unshift(gisLayerRecentItem);
    }
  },

  [SET_LEGEND_LAYERS_FILTER](state, { gisComponentId, filter }) {
    setGisOption(state, gisComponentId, 'legendLayersFilter', filter);
  },

  [SET_MAP_CURSOR_STYLE](state, { gisComponentId, value }) {
    setGisOption(state, gisComponentId, 'mapCursorStyle', value);
  },

  [SET_SKY_OPTIONS](state, { gisComponentId, options }) {
    setGisOption(state, gisComponentId, 'skyOptions', options);
  },

  [SET_BASE_MAP_RASTER_OPACITY](state, { gisComponentId, opacity }) {
    setGisOption(state, gisComponentId, 'baseMapRasterOpacity', opacity);
  },

  [SET_BASE_TEXT_MAP_RASTER_OPACITY](state, { gisComponentId, opacity }) {
    setGisOption(state, gisComponentId, 'baseTextMapRasterOpacity', opacity);
  },

  [SET_SPACE_IMAGERY_PANEL_VISIBLE](state, { gisComponentId, visible }) {
    setGisOption(state, gisComponentId, 'showSpaceImageryPanel', visible);
  },

  [SET_SPACE_IMAGERY_FOUND_FEATURES](state, { gisComponentId, features }) {
    setGisOption(state, gisComponentId, 'spaceImageryFoundFeatures', features);
  },

  [SET_SPACE_IMAGERY_HOVERED_FEATURE_ID](state, { gisComponentId, featureId }) {
    setGisOption(state, gisComponentId, 'spaceImageryHoveredFeatureId', featureId);
  },

  [SET_SPACE_IMAGERY_VISIBLE_FEATURE_IDS](state, { gisComponentId, featureIds }) {
    setGisOption(state, gisComponentId, 'spaceImageryVisibleFeatureIds', featureIds || []);
  },

  [SET_SELECTED_MOUSE_TOOL](state, { gisComponentId, buttonName }) {
    setGisOption(state, gisComponentId, 'selectedMouseTool', buttonName);
  },

  [SET_MAPBOX](state, { gisComponentId, mapbox }) {
    setGisOption(state, gisComponentId, 'mapbox', mapbox);
  },

  [SET_OPEN_CARDS_GRID](state, { gisComponentId, value }) {
    setGisOption(state, gisComponentId, 'openCardsGrid', value);
  },

  [SET_GEOCODE_SYSTEM](state, { gisComponentId, value }) {
    setGisOption(state, gisComponentId, 'geocodeSystem', value);
  },

  [SET_SELECTED_OBJECT_ID](state, { gisComponentId, value }) {
    setGisOption(state, gisComponentId, 'selectedObjectId', value);
  },

  [SET_PART_TOOL_MODE](state, { gisComponentId, value }) {
    setGisOption(state, gisComponentId, 'partToolMode', value);
  },

  [SET_TMP_LAYER_CARD_ID](state, { gisComponentId, value }) {
    setGisOption(state, gisComponentId, 'tmpLayerCardId', value);
  },

  [SET_TMP_LAYER_CARD_POPUP_VISIBLE](state, { gisComponentId, value }) {
    setGisOption(state, gisComponentId, 'tmpCardPopupVisible', value);
  }
};

/** Установка активных подложек*/
function prepareBaseMaps(state, gisOptions) {
  let visibleBaseMapId = localStorage.getItem(`${BASEMAP_LOCAL_STORAGE}_${gisOptions.componentId}`);
  let visibleBaseTextId = localStorage.getItem(`${TEXTMAP_LOCAL_STORAGE}_${gisOptions.componentId}`);
  let visibleTerrainLayerComponentId = localStorage.getItem(`${TERRAINMAP_LOCAL_STORAGE}_${gisOptions.componentId}`);
  let lastBaseMapId;

  if (visibleBaseMapId === 'null') {
    visibleBaseMapId = null;
  }

  if (visibleBaseTextId === 'null') {
    visibleBaseTextId = null;
  }

  gisOptions.items.forEach((layerId) => {
    const layerOption = state.componentOptions[layerId];

    if (!layerOption) {
      return;
    }

    if (layerOption.baseMap) {
      const selected = visibleBaseMapId ? false : layerOption.visible;
      if (selected) {
        visibleBaseMapId = layerId;
      }
      lastBaseMapId = layerId;
    }

    if (layerOption.baseTextMap) {
      const selected = visibleBaseTextId ? false : layerOption.visible;
      if (selected) {
        visibleBaseTextId = layerId;
      }
    }
  });
  //Проверка на существование подложки
  //По умолчанию последняя подложка в списке
  const layerOption = state.componentOptions[visibleBaseMapId];
  if (!((layerOption && layerOption.layerComponentId) || visibleBaseMapId === BASE_MAP_EMPTY_ID)) {
    visibleBaseMapId = lastBaseMapId;
  }
  gisOptions.activeBaseMapLayerComponentId = visibleBaseMapId;
  //По умолчанию без текстового слоя
  gisOptions.activeBaseTextLayerComponentId = visibleBaseTextId || BASE_MAP_EMPTY_ID;
  //По умолчанию без рельефа
  gisOptions.activeTerrainLayerComponentId = visibleTerrainLayerComponentId || BASE_MAP_EMPTY_ID;
}

function setGisOption(state, gisComponentId, optionName, optionValue) {
  const gisOptions = state.componentOptions[gisComponentId];
  if (!gisOptions) {
    return;
  }
  gisOptions[optionName] = optionValue;
}
