import actions from './actions';
import getters from './getters';
import mutations from './mutations';

export default {
  namespaced: true,
  state: {
    active: false,
    selectMode: true,

    /**Блокировка выбора по клику для некоторых ситуаций
     * Например dragAndDrop в GridLayout
     */
    blockSelectMode: false,

    dragging: false,
    componentTypes: null,
    templates: null,
    templateFields: null,
    connections: [],
    datasets: [],
    //Фильтры выбранного датасета
    filters: [],

    // Массив всех страниц
    allPages: [],

    //Идентификатор выбранного шаблона
    selectedTemplateId: null,
    //События-действия выбранного компонента
    selectedEventActions: [],
    //Типы событий выбранного компонента
    selectedEventTypes: [],

    //Доступ ролей выбранного компонента
    selectedAccessRoles: [],

    // Новая страница
    newPage: {
      name: '<New page>',
      parentId: null,
      url: '/',
      icon: null,
      pos: 0
    },

    //Новый компонент
    newComponent: {
      type: null,
      name: '<New component>',
      parentId: null,
      pos: 0
    },

    //Новый шаблон компонентов
    newComponentTemplate: {
      componentId: null,
      name: '',
      groupId: null
    },
    //Группы для шаблонов компонентов
    componentTemplateGroups: [],
    //Список шаблонов компонентво
    componentTemplates: [],

    //Выбранный тип события
    selectedEventTypeId: null,
    //Выбранные события-действия по типу события
    selectedEventTypeActions: [],

    //Выбранное событие-действие
    selectedEventAction: {
      id: null,
      parentId: null,
      eventComponentId: null,
      eventTypeId: null,
      actionComponentId: null,
      actionTypeId: null,
      disable: null
    },
    //Список параметров выбранного события-действия
    selectedEventActionParams: [],

    //Список параметров всех событий-действий
    allEventActionParams: [],

    componentActionTypes: [],
    eventActionParamTypes: [],

    //Параметр действия
    selectedEventActionParam: {
      id: null,
      eventActionId: null,
      typeId: null,
      inputName: '',
      outputName: '',
      outputValue: '',
      isPrivate: false,
      isArray: false,
      componentId: null
    },

    // Глобальные компоненты, для которых добавлялись или редактировались дествия или параметры
    loadedGlobalComponents: [],

    // Импорт настроек
    importedSettings: {
      componentId: null,
      isRewrite: true
    },

    // Параметры копирования компонента
    copyComponent: {
      componentId: null,
      parentId: null,
      url: null
    },

    // Устройство для отображения разрешения (desktop, tablet, phone)
    resolutionDevice: 'desktop',
    //Отображение окна выбора изображения
    showSelectImage: false,
    //Отображение окна выбора иконки
    showSelectIcon: false,
    //Отображение попап окна со справочной информацией
    popupTypeComponentVisible: false
  },
  getters,
  mutations,
  actions
};
