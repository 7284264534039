import actions from './actions';
import getters from './getters';
import mutations from './mutations';

export default {
  namespaced: true,
  state: {
    cartProducts: localStorage.getItem('cartProducts') || [],
    cartProductsCount: localStorage.getItem('cartProductsCount') || 0
  },
  getters,
  mutations,
  actions
};
