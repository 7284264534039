<template>
  <!--eslint-disable-->
  <DxDropDownBox
    :value="currentComponentId"
    :dataSource="dataSource"
    valueExpr="componentId"
    :displayExpr="DropDownBoxDisplayExpr"
    :showClearButton="true"
    ref="dropDownBox"
    @valueChanged="closeComponentsDropDownBox"
  >
    <template>
      <DxTreeView
        keyExpr="componentId"
        :rootValue="null"
        displayExpr="componentName"
        :dataSource="dataSource"
        parentIdExpr="parentComponentId"
        dataStructure="plain"
        :focusStateEnabled="false"
        :selectByClick="true"
        :searchEnabled="true"
        :searchExpr="['componentId', 'componentName', 'componentType']"
        itemTemplate="itemTemplate"
        selectionMode="single"
        @itemSelectionChanged="itemSelectionChanged"
      >
        <template #itemTemplate="{ data: item }">
          <div :key="item.componentId">
            {{ item.componentName }} ({{ item.componentType }} {{ item.componentId }})
          </div>
        </template>
      </DxTreeView>
      <DxButton
        v-if="showOpenComponentButton"
        :options="openComponentButton"
        name="openComponent"
        location="after"
      />
      <DxButton name="clear" />
      <DxButton name="dropDown" />
    </template>
  </DxDropDownBox>
</template>

<script>
import { DxDropDownBox, DxButton } from 'devextreme-vue/drop-down-box';
import DxTreeView from 'devextreme-vue/tree-view';

export default {
  name: 'DropDownComponentsBox',
  components: {
    DxDropDownBox,
    DxTreeView,
    DxButton
  },
  props: {
    value: null,
    dataSource: Array,
    showOpenButton: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      openComponentButton: {
        icon: 'fas fa-external-link-alt',
        stylingMode: 'text',
        onClick: () => {
          this.$emit('openComponent');
        }
      }
    };
  },
  model: { prop: 'value', event: 'update:value' },
  computed: {
    currentComponentId() {
      return this.value !== undefined && this.value !== null ? this.value.toString() : null;
    },
    showOpenComponentButton() {
      return this.showOpenButton && this.value;
    }
  },
  methods: {
    itemSelectionChanged(event) {
      this.$emit('itemSelectionChanged', event);
    },
    DropDownBoxDisplayExpr(data) {
      return data && `${data.componentName} (${data.componentId} ${data.componentType})`;
    },
    closeComponentsDropDownBox(eventData) {
      this.$emit('valueChanged', eventData);
      this.$refs.dropDownBox.instance.close();
    }
  }
};
</script>
