export default {
  active(state) {
    return state.active;
  },
  loadedGlobalComponents(state) {
    return state.loadedGlobalComponents;
  },
  selectedTemplateId(state) {
    return state.selectedTemplateId;
  },
  componentTypes(state) {
    if (!state.componentTypes) {
      return [];
    }

    return state.componentTypes.map((type) => {
      let name = type.name;
      if (name.indexOf('dx') === 0) {
        name = name[2].toUpperCase() + name.substring(3);
      }

      return {
        id: type.id,
        name
      };
    });
  },

  eventActionsByEventType: (state) => (eventTypeId) => {
    return state.selectedEventActions.filter((eventAction) => {
      return eventAction.eventtype_id === eventTypeId;
    });
  },
  connections(state) {
    return state.connections;
  },
  datasets(state) {
    return state.datasets;
  },
  filters(state) {
    return state.filters;
  },
  getEventActionDataById: (state) => (eventActionId) => {
    return state.selectedEventTypeActions.filter((eventAction) => {
      return eventAction.id === eventActionId;
    })[0];
  },
  selectMode(state) {
    return state.active && state.selectMode && !state.showSelectImage && !state.popupTypeComponentVisible;
  },
  resolutionDevice(state) {
    return state.resolutionDevice;
  },
  getOption: (state) => (optionName) => {
    return state[optionName];
  }
};
