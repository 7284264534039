/**
 * Карта компонентов, для которых есть действия во vuex
 */
export const VUEX_COMPONENTS_MAP = {
  Gis: 'Gis',
  GisLayer: 'GisLayer',
  GisGroupLayer: 'GisLayer'

  //Такой костыль нужен чтобы пока не ломать поведение датасетов
  //DataSet: 'DataSet',
  //DataSetGeoJson: 'DataSet'
};

/**
 * Отдельные действия, которые перенесены во vuex
 */
export const VUEX_ACTIONS = ['setOption', 'setCustomData', 'resetCustomData', 'showComponent', 'hideComponent', 'toggleComponent'];

/**
 * Действия остающиеся в компоненте
 */
export const NON_VUEX_ACTIONS = [
  'redraw',
  'setMapPosition',
  'setMarker',
  'clearMarkers',
  'makeMapScreenshot',
  'fitFeatures',
  'generateLayerReports',
  'getGeocoderObject',
  'openPanorama',
  'setPanoramaMarker',
  'saveGisState',
  'restoreGisState',
  'getGisState',
  'resetGisState'
];
