export default class CustomFilterFieldValue {
  /**{String} Имя поля, используемое в фильтре */
  name = null;

  /**{String} Заголовок поля, отображаемый в форме */
  caption = null;

  /**{String} Тип данных поля */
  type = null;

  /**{Array} Значение фильтра поля */
  value = null;

  /**{Array} Значение поля-словаря, отображаемое пользователю */
  displayValue = null;

  /**{String} Текст тега фильтра */
  label = null;

  constructor(options) {
    this.name = options.name;
    this.caption = options.caption || options.name;
    this.type = options.type;
  }
}
