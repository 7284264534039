import Gis from './Gis/getters';
import GisLayer from './GisLayer/getters';
import DataSet from './DataSet/getters';
import Form from './Form/getters';

//Названия геттеров
const PREFIX = 'Component';
export const getItem = `${PREFIX}/getItem`;
export const getOptions = `${PREFIX}/getOptions`;

export const componentOptions = `${PREFIX}/componentOptions`;
export const itemConfigById = `${PREFIX}/itemConfigById`;
export const itemsById = `${PREFIX}/itemsById`;
export const getCustomData = `${PREFIX}/getCustomData`;
export const getParentComponentId = `${PREFIX}/getParentComponentId`;
export const getParentLayerComponentId = `${PREFIX}/getParentLayerComponentId`;
export const hasEventAction = `${PREFIX}/hasEventAction`;
export const getLayerFilterComponentId = `${PREFIX}/getLayerFilterComponentId`;

const getters = {
  [getItem]: (state) => (componentId) => {
    return state.items[componentId] || {};
  },

  [getOptions]: (state) => (componentId) => {
    return state.componentOptions[componentId] || {};
  },

  [componentOptions]: (state) => {
    return state.componentOptions || {};
  },

  [itemConfigById]: (state) => {
    return state.items;
  },

  [itemsById]: (state) => {
    return state.componentItems;
    /*
    return Object.entries(state.componentOptions).reduce((result, [componentId, componentOptions]) => {
      result[componentId] = (componentOptions.items || [])
        .map((itemId) => {
          return state.items[itemId];
        })
        .filter((itemConfig) => {
          return itemConfig;
        })
        .sort((itemConfig1, itemConfig2) => {
          return (itemConfig1.componentPos || 0) - (itemConfig2.componentPos || 0);
        });

      return result;
    }, {});
    //*/
  },

  /**
   * Возвращает customData компонента, установленный или сброшенный через действия setCustomData и resetCustomData
   * @param {*} state
   * @returns
   */
  [getCustomData]: (state) => (componentId) => {
    return state.customData[componentId] || null;
  },

  /**
   * Получение родительского компонента
   * возвращает родителя, если не передан тип
   * возвращает ближайшего родителя с указанным типом, если тип передан
   * @param {*} state
   * @param {*} getters
   * @returns
   */
  [getParentComponentId]: (state, getters) => (componentId, parentComponentType) => {
    if (!componentId) {
      return null;
    }

    const parentComponentId = state.items[componentId]?.parentComponentId || null;
    if (!parentComponentId) {
      return null;
    }

    if (!parentComponentType || state.items[parentComponentId]?.componentType === parentComponentType) {
      return parentComponentId;
    }

    return getters[getParentComponentId](parentComponentId, parentComponentType);
  },

  /**
   * Попытка понять, что датасет связан с таблицей слоя
   * Это мегакостыль, чтобы не синхронизировать фильтрацию для слоев вручную через конструктор
   */
  [getParentLayerComponentId]: (state, getters) => (componentId) => {
    const parentLayerComponentId = getters[getParentComponentId](componentId, 'GisLayer');

    if (parentLayerComponentId) {
      //Получение датасета слоя
      //const gisLayerDatasetName = this.gisLayerGetDatasetName(parentLayerComponentId);

      //Попытка получить родительский попап с ролью "Таблица"
      const parentPopupComponentId = getters[getParentComponentId](componentId, 'Popup');
      const parentPopupComponentConfig = getters[getItem](parentPopupComponentId);

      //Убедимся что
      // - найден родительский попап (айди и конфиг)
      // - у родительского попапа роль - таблица
      // - родительский попап лежит внутри слоя
      if (
        //gisLayerGetDatasetName === this.dataSetName &&
        parentPopupComponentId &&
        parentPopupComponentConfig &&
        parentPopupComponentConfig.layerComponentRole === 'table' &&
        parentPopupComponentConfig.parentComponentId === parentLayerComponentId
      ) {
        return parentLayerComponentId;
      }
    }

    return null;
  },

  [hasEventAction]:
    (state) =>
    ({ eventComponentId, actionComponentId, eventName, actionName }) => {
      const eventAction = state.eventAction[eventComponentId];
      const eventActionConfig = eventAction?.[eventName];

      if (!Array.isArray(eventActionConfig)) {
        return null;
      }

      const actionConfig = eventActionConfig.find((actionConfig) => {
        return actionConfig.actionComponentId === actionComponentId && actionConfig.actionName === actionName;
      });

      return !!actionConfig;
    },

  [getLayerFilterComponentId]: (state) => (componentId) => {
    return state.layerFilterComponentIds[componentId] || null;
  },

  ...DataSet,
  ...Form,
  ...Gis,
  ...GisLayer
};
export default getters;
