import * as turf from '@turf/turf';
import { getPolygons, convertToOrdinaryPolygons } from './polygons';

/**Возвращает центр простой линии (LineString)*/
export function getLineCenter(geometry) {
  const options = { units: 'meters' };
  const len = turf.length(geometry, options);
  const point = turf.along(geometry, len / 2, options);
  return point.geometry;
}
/**Нахождение ближайшей точки к клику */
export function getNearestPoint(features, point) {
  let nearestPoint;
  let pointFeature;
  let minDist = Number.MAX_VALUE;
  for (const feature of features) {
    if (feature.geometry.type === 'Polygon') {
      const featureLine = turf.polygonToLine(feature.geometry);
      pointFeature = turf.nearestPointOnLine(featureLine.geometry, point);
    } else if (feature.geometry.type === 'MultiPolygon') {
      const featureCollection = turf.polygonToLine(feature.geometry);
      let minDistPoly = Number.MAX_VALUE;
      for (const featureLine of featureCollection.features) {
        const pointFeaturePoly = turf.nearestPointOnLine(featureLine.geometry, point);
        if (pointFeaturePoly.properties.dist < minDistPoly) {
          pointFeature = pointFeaturePoly;
        }
      }
    } else if (feature.geometry.type === 'Point') {
      nearestPoint = feature.geometry;
    } else if (feature.geometry.type === 'MultiPoint') {
      const points = feature.geometry.coordinates.map((coord) => turf.point(coord));
      pointFeature = turf.nearestPoint(point, turf.multiPoint(points));
    } else {
      pointFeature = turf.nearestPointOnLine(feature.geometry, point);
    }
    if (pointFeature && pointFeature.properties.dist < minDist) {
      nearestPoint = pointFeature.geometry;
    }
  }
  return nearestPoint;
}

/**Возвращет ближайшую часть линии LineString из MultiLineString */
export function getNearestLineString(multiLine, point) {
  let lineFound = null;
  const pointOfMulti = turf.nearestPointOnLine(multiLine, point);
  for (const lineCoords of multiLine.coordinates) {
    //Для всех частей проверяем ближайшую точку
    const line = { type: 'LineString', coordinates: lineCoords };
    const point1 = turf.nearestPointOnLine(line, point);
    if (pointOfMulti.properties.dist === point1.properties.dist) {
      //точка та же что для мультилинии
      lineFound = line;
    }
  }
  return lineFound;
}

export function createIntersections(features) {
  let polygons = getPolygons(features);
  let intersections = getIntersections(polygons);

  return intersections;
}

function getIntersections(polygons) {
  let ordinaryPolygons = convertToOrdinaryPolygons(polygons);
  let intersections = findIntersections(ordinaryPolygons);

  return convertToMultipolygonFeatures(intersections);
}

function findIntersections(ordinaryPolygons) {
  let intersections = [];
  for (let i = 0; i < ordinaryPolygons.length; i++) {
    for (let j = i + 1; j < ordinaryPolygons.length; j++) {
      let intersection = turf.intersect(ordinaryPolygons[i], ordinaryPolygons[j]);
      if (intersection) {
        intersections.push(intersection);
      }
    }
  }

  return intersections;
}

function convertToMultipolygonFeatures(intersections) {
  let fcIntersections = turf.featureCollection([...intersections]);
  let multipolygonFeatures = turf.combine(fcIntersections);

  return multipolygonFeatures;
}

/**
 * Тест на пересечение отрезков
 */
export function isSegmentsIntersect(pnt11, pnt12, pnt21, pnt22) {
  //https://stackoverflow.com/questions/563198/how-do-you-detect-where-two-line-segments-intersect
  // Get the orientation of points p3 and p4 in relation to the line segment (p1, p2)
  const o1 = orientation(pnt11, pnt12, pnt21);
  const o2 = orientation(pnt11, pnt12, pnt22);
  const o3 = orientation(pnt21, pnt22, pnt11);
  const o4 = orientation(pnt21, pnt22, pnt12);

  // Если точки p11, p12 лежат на противоположных сторонах
  // линни (p21, p22) и p21, p22 на противоположных сторонах
  // линии (p11, p12) тогда это пересечение.
  //Исключаем случай стыковки отрезков в точке
  if (o1 * o2 == -1 && o3 * o4 == -1 /*&& Math.abs(o1 + o2) != Math.abs(o3 + o4)*/) return true;

  // Коллинеарность - специальный случай
  if (o1 == 0 && pointOnLine(pnt11, pnt12, pnt21)) return true;
  if (o2 == 0 && pointOnLine(pnt11, pnt12, pnt22)) return true;
  if (o3 == 0 && pointOnLine(pnt21, pnt22, pnt11)) return true;
  if (o4 == 0 && pointOnLine(pnt21, pnt22, pnt12)) return true;

  return false;
}

/**
 * Находит ориентацию точки pnt2 относительно отрезка (pnt11, pnt12)
 * @param {*} pnt11
 * @param {*} pnt12
 * @param {*} pnt2
 * @returns 0 если все три точки коллинеарны, -1 точка справа, +1 - точка слева.
 */
function orientation(pnt11, pnt12, pnt2) {
  const EPS = 0.00000001;
  const value = (pnt12[1] - pnt11[1]) * (pnt2[0] - pnt12[0]) - (pnt12[0] - pnt11[0]) * (pnt2[1] - pnt12[1]);
  if (Math.abs(value) < EPS) return 0;
  return value > 0 ? -1 : +1;
}

function pointOnLine(pnt11, pnt12, pnt2) {
  return (
    orientation(pnt11, pnt12, pnt2) == 0 &&
    Math.min(pnt11[0], pnt12[0]) < pnt2[0] &&
    pnt2[0] < Math.max(pnt11[0], pnt12[0]) &&
    Math.min(pnt11[1], pnt12[1]) < pnt2[1] &&
    pnt2[1] < Math.max(pnt11[1], pnt12[1])
  );
}
