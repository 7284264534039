const getters = {
  name: (state) => {
    return state.name;
  },

  logourl: (state) => {
    return state.logourl;
  },

  title: (state) => {
    return state.title;
  },

  subtitle: (state) => {
    return state.subtitle;
  },

  defaultPath: (state, getters, rootGetters) => {
    //Путь из настроек портала
    let path = `${state.defaultplace_name || ''}`;
    if (state.masterplace_name) {
      path = `${state.masterplace_name}${path}`;
    }

    //Путь из настроек пользователя
    const userData = rootGetters.session.userData || {};
    let userPath = `${userData.defaultplace_name || ''}`;
    if (userData.masterplace_name) {
      userPath = `${userData.masterplace_name}${userPath}`;
    }

    //Путь из окружения
    const envPath = userData.userIsGuest ? process.env.VUE_APP_GUEST_DEFAULT_PATH : process.env.VUE_APP_DEFAULT_PATH;

    return envPath || userPath || path || null;
  },

  isPortalConfigLoading: (state) => {
    return state.isPortalConfigLoading;
  }
};

export default getters;
