<template>
  <!--eslint-disable-->
  <router-view v-if="currentType === 'RouterView'" />
  <component
    v-else-if="componentTypeRegistred"
    ref="component"
    :is="currentType"
    :componentId="componentId"
    :key="componentId"
    @callEventAction="$emit('callEventAction', $event);"
    @changeVisibility="$emit('changeVisibility', $event);"
  />
  <UnknownComponent
    v-else
    :type="currentType || ''"
  ></UnknownComponent>
</template>

<script>
import Vue from 'vue';
import UnknownComponent from './UnknownComponent';

export default {
  name: 'ConstructorElement',

  components: {
    UnknownComponent
  },

  props: {
    componentId: String,
    componentType: String
  },

  computed: {
    componentTypeRegistred() {
      return !!Vue.options.components[this.currentType];
    },
    currentType() {
      //Vue не позволяет регестрировать Switch как тип компонента
      return this.componentType === 'Switch' ? 'Switcher' : this.componentType;
    }
  },

  methods: {
    callMethod(name, params) {
      this.$refs.component[name](params);
    }
  }
};
</script>
